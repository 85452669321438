import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as CommonJsFunc from '../../../../assets/js/common/common';
import { PanelistService } from '../../shared/panelist.service';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../shared/globals';

@Component({
  selector: 'app-postback',
  templateUrl: './postback.component.html',
  styleUrls: ['./postback.component.css']
})
export class PostbackComponent implements OnInit {

  
  public PostbackSuccessMsg:string;
  public PostbackSuccessPoint:number;
  public ShowDashboardBanner:number=0;

  PostbackPageLoader:boolean = true;
  TemplateMsg:string = "";
  homepagebutton:boolean;
  dashboardbutton:boolean;
  getUrlPanelistId:string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private _panelistService: PanelistService, private toastr: ToastrService) {
    
    
    /*var resp = {"auth":true,"success":true,"message":"Transaction created successfully!","fail_count":2,"points":20};

    if(resp.auth == true && resp.success == true){
      

      var postBackVar = [];
      postBackVar['success_msg'] = resp.message;
      postBackVar['show_dashboard_earn_point_banner'] = true;

      this._panelistService.setPostbackVar(postBackVar);

      this.router.navigate(['dashboard']);
    }*/

   }

  ngOnInit() {
    let get_Postback_encodeurl = this.activatedRoute.snapshot.url[1].path;
    //console.log(get_Postback_encodeurl);

    let decode_Postback_url = CommonJsFunc.decode64(get_Postback_encodeurl);
    //console.log(decode_Postback_url);

    var res = decode_Postback_url.split('/');
    //console.log(res);
      
    let status = res[0].trim();
    let Panelist_SurveyId = res[1].trim();
    let get_url_token = res[2].trim();

      //console.log(status); console.log(Panelist_SurveyId); console.log(get_url_token); return false;
    
      var myArr = Panelist_SurveyId.split('_');
    let panelist_id = myArr[0];
    let survey_id = myArr[1];

    let decode_survey_id = CommonJsFunc.decode64(survey_id);
    this.getUrlPanelistId = panelist_id;


    this._panelistService.ResetPostbackPanelistPoints(); // reset panelist points

    var postData = []; 

    postData['panelistId'] = panelist_id;
    postData['survey_id'] = decode_survey_id;
    postData['status'] = status;
    postData['token'] = get_url_token;
	
	localStorage.removeItem('PostbackPanelistId');    

    this._panelistService.getPostBackCall(postData).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true){ 
        this._panelistService.SetPostbackPanelistPoints(resp.points);
		
		localStorage.setItem('PostbackPanelistId', panelist_id);

        if(status == 2){

          if(myGlobals.SurveySuccessMsg1.length > 0){ 
            let randomNumber = Math.floor((Math.random() * myGlobals.SurveySuccessMsg1.length) + 1);
            
            let getMsgString = myGlobals.SurveySuccessMsg1[randomNumber];
            
            if(getMsgString != "" && getMsgString != undefined){
              //this.TemplateMsg = getMsgString.replace("response_points", resp.points);
              this.TemplateMsg = getMsgString;
            } else {
              this.TemplateMsg = "Survey_success_msg1";   
            }
          } else {
            this.TemplateMsg = "Survey_success_msg1";
          }

        } else if (status != 2 && resp.fail_count <= 5){ 

          if(myGlobals.SurveySuccessMsg2.length > 0){

            let randomNumber = Math.floor((Math.random() * myGlobals.SurveySuccessMsg2.length) + 1);
            
            let getMsgString = myGlobals.SurveySuccessMsg2[randomNumber];
            
            if(getMsgString != "" && getMsgString != undefined){
              //this.TemplateMsg = getMsgString.replace("response_points", resp.points);
              this.TemplateMsg = getMsgString;

            } else {
              this.TemplateMsg = "Survey_terminate_pnt_msg1";  
            }

          } else { 
            this.TemplateMsg = "Survey_terminate_pnt_msg1";
          }

        } else if(status != 2 && resp.fail_count >5) {

          if(myGlobals.SurveySuccessMsg3.length > 0){

            let randomNumber = Math.floor((Math.random() * myGlobals.SurveySuccessMsg3.length) + 1);
            
            let getMsgString = myGlobals.SurveySuccessMsg3[randomNumber];
            
            if(getMsgString != "" && getMsgString != undefined){
              //this.TemplateMsg = getMsgString.replace("response_points", resp.points);
              this.TemplateMsg = getMsgString;

            } else {
              this.TemplateMsg = "Survey_terminate_nopnt_msg1";  
            }

          } else {
            this.TemplateMsg = "Survey_terminate_nopnt_msg1";
          }
          
        }


        this._panelistService.setPostbackVar(this.TemplateMsg);

        let system_exists_panelist = localStorage.getItem('currentLoginPanelist');

        if(system_exists_panelist != panelist_id.trim()){
          localStorage.removeItem('currentLoginPanelist');
          localStorage.setItem('currentLoginPanelist',panelist_id.trim());
        }

        this.router.navigate(['/dashboard']);
        
        /* Below Code find redirection to the panelist, Start Here */

        /*if(localStorage.getItem('currentLoginPanelist') !==null){
          
          let getpanelistId = localStorage.getItem('currentLoginPanelist');

          if(panelist_id.trim() != getpanelistId.trim()){
            localStorage.removeItem('currentLoginPanelist');
            this.dashboardbutton = false;
            this.homepagebutton = true;
            this.StopLoader();
          } else {
            this.homepagebutton = false;
            this.dashboardbutton = true;
            this.StopLoader();
          }

        } else {
          this.dashboardbutton = false;
          this.homepagebutton = true;
          this.StopLoader();
        }*/
        /* Above Code find redirection to the panelist, End Here */

      }
    },(err:any) =>{
      
      if(localStorage.getItem('currentLoginPanelist') !==null){
        let getpanelistId = localStorage.getItem('currentLoginPanelist');

        if(panelist_id.trim() != getpanelistId.trim()){

          localStorage.removeItem('currentLoginPanelist');
          
          this.router.navigate(['']).then(()=>{
            this.showNewFlashError(err,'WisePerks');
          });

        } else {

          let getPanelistStepValue = this.GetStepVal(panelist_id);
          
          if(getPanelistStepValue >= 1){
            this.router.navigate(['/dashboard']).then(()=>{
              this.showNewFlashError(err,'WisePerks');
            });
          } else if(getPanelistStepValue == 0){
            this.router.navigate(['/demographics']).then(()=>{
              this.showNewFlashError(err,'WisePerks');
            });
          } else {
            this.router.navigate(['']).then(()=>{
              this.showNewFlashError(err,'WisePerks');
            });
          }

        }

      } else {
        this.router.navigate(['']).then(()=>{
          this.showNewFlashError(err,'WisePerks');
        });
      }

      
    });

  }

  GetStepVal(getPanelist_Id){
    let stepval:number;
    this._panelistService.getPanelistInfo(getPanelist_Id).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true){
        stepval = resp.data['step'];
      }
    },(err:any) => {
      this.router.navigate(['']).then(()=>{
        this.showNewFlashError(err,'WisePerks');
      });
    });
    return stepval;
  }


  Gotohomepage(){
    this.router.navigate(['']);
  }

  Gotodashboard(){
    this.PostbackPageLoader = true;

    let getPanelistStepVal = this.GetStepVal(this.getUrlPanelistId);

    if(getPanelistStepVal >= 1){
      this.router.navigate(['/dashboard']);
    } else if(getPanelistStepVal == 0) {
      this.router.navigate(['/demographics']);
    } else {
      this.router.navigate(['']);
    }
  }

  StopLoader(){
    this.PostbackPageLoader = false;
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}
