import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from '../shared/home.service';
import { PanelistService } from '../../shared/panelist.service';
import * as CommonJsFunc from '../../../../assets/js/common/common';

@Component({
  selector: 'app-home-verify-email',
  templateUrl: './home-verify-email.component.html',
  styleUrls: ['./home-verify-email.component.css']
})
export class HomeVerifyEmailComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private _homeService: HomeService,
    private _panelistService: PanelistService) {
     }

  ngOnInit() {
    let getemail = this.activatedRoute.snapshot.queryParams.email;
    let getauth = this.activatedRoute.snapshot.queryParams.auth;

    if(getemail != null || getauth != null){
      
      let check_correct_email = CommonJsFunc.decode64(getemail);
      let check_correct_auth = CommonJsFunc.decode64(getauth);
      
      if((check_correct_email != null && check_correct_email != "") && (check_correct_auth !=null && check_correct_auth != "")){
      
        this._homeService.VerifyPanelistApi(check_correct_email,check_correct_auth).subscribe((resp:any) =>{
         if(resp.auth == true && resp.success == true){
           //console.log(resp);

           this._homeService.PanelistGetDataViaEmail(check_correct_email).subscribe((response:any) => {
              if(response.auth == true && response.success == true){
                
                let system_exists_panelist = localStorage.getItem('currentLoginPanelist');
                let email_verify_panelistId = response.data[0]._id;

                if(system_exists_panelist != email_verify_panelistId){
                  localStorage.removeItem('currentLoginPanelist');
                  localStorage.setItem('currentLoginPanelist',response.data[0]._id);
                }

                let getStep = response.data[0].step;
                //console.log(getStep);
                if(getStep == 0){
                  this.router.navigate(['/demographics']).then(()=>{
                    this.toastr.clear();
                    this.toastr.success("You have successfuly Verified your Email", "WisePerks");
                  });
                  
                } else if(getStep > 0){
                  this.router.navigate(['/dashboard']).then(()=>{
                    this.toastr.clear();
                    this.toastr.success("You have successfuly Verified your Email", "WisePerks");
                  });
                } else {
                  this.router.navigate(['']).then(()=>{
                    this.toastr.clear();
                    this.toastr.success("You have successfuly Verified your Email", "WisePerks");
                  });
                  
                }
                
              }
           },(error:any) => { //console.log("here");
              this.router.navigate(['']);
              this.showNewFlashError(error,"WisePerks");
           });

            /*this.router.navigate(['']);
            this.toastr.clear();
            this.toastr.success("You have successfuly Verified your Email", "WisePerks");*/
         }
        }, (err:any)=>{
            this.router.navigate(['']);
            this.showNewFlashError(err,"WisePerks");
        });
      } else {
        this.router.navigate(['']);
        this.toastr.clear();
        this.toastr.error("You have not a authenticate panelists","WisePerks");
        return false;
      }

    } else {
      this.router.navigate(['']);
      this.toastr.clear();
      this.toastr.error("You have not a authenticate panelists","WisePerks");
      return false;
    }

  }

  

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}
