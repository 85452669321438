import { Pipe, PipeTransform } from '@angular/core';
import { PanelistService } from './panelist.service';

@Pipe({
  name: 'replcePoint'
})
export class ReplcePointPipe implements PipeTransform {

  constructor(private panelistService: PanelistService){}

// used to replace response points of user dynamically 
  transform(value: any, args?: any): any {
    let panelist_point = this.panelistService.GetPostbackPanelistPoints();
    return value  ? value.replace('response_points', panelist_point) : value;
  }

}
