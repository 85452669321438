import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicYear'
})
export class DynamicYearPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    //return null;
    let currentYear = (new Date()).getFullYear();
    return value  ? value.replace('current_year', currentYear) : value;
  }

}
