import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as CommonJsFunc from '../../../../assets/js/common/common';
import { PanelistService } from '../../shared/panelist.service';
import { HomeService } from '../../home/shared/home.service';
import * as myParamfunc from '../../../../assets/js/common/sv';
import * as myLangGlobal from '../../shared/globals';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-survey-link',
  templateUrl: './survey-link.component.html',
  styleUrls: ['./survey-link.component.css']
})
export class SurveyLinkComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private _panelistService: PanelistService, private toastr: ToastrService,
    private _homeService: HomeService) {
      
    }

  ngOnInit() {
    let get_survey_link_encodeurl = this.activatedRoute.snapshot.url[1].path;
    
    let get_survey_link_decodeurl = CommonJsFunc.decode64(get_survey_link_encodeurl);

    var res = get_survey_link_decodeurl.split('/');

    if(res.length == 4){    // variables for sell API link (this survey from Sell API)
      var survey_id = res[0].trim();
      var getPanelistId = res[1].trim();
      var newPanelistId = getPanelistId.replace(/\0/g, '');
      var getSurveyPoint = res[2].trim();
      var client_id = res[3].trim();
    } else {      // generate samplicity url  (this survey from samplicity)
      var supplierRelationShipId = res[0].trim();
      var survey_id = res[1].trim();
      var quota_id = res[2].trim();
      var getSurveyPoint = res[3].trim();
      var getPanelistId = res[4].trim();
      var newPanelistId = getPanelistId.replace(/\0/g, '');
    }
    
    var system_exists_panelist = localStorage.getItem('currentLoginPanelist');

    if(system_exists_panelist != newPanelistId){
      localStorage.removeItem('currentLoginPanelist');
      localStorage.setItem('currentLoginPanelist',newPanelistId);
    }
    //console.log(getPanelistId);

    /*console.log("SurveyLink = "+supplierRelationShipId);
    console.log("SurveyLink = "+survey_id);
    console.log("SurveyLink = "+quota_id);
    console.log("SurveyLink = "+getSurveyPoint);*/

    /*Below Code is for Check LinkSurvey is Active or not, Start Here */
      var postData = [];
      var panelistDevice = "";
      var IspanelistDevice = myParamfunc.isMobile();

      if(IspanelistDevice[0] == 1){
        panelistDevice = "Mobile";
      } else {
        panelistDevice = "Desktop";
      }


        postData['panelist_id'] = newPanelistId;
        postData['survey_id'] = survey_id;
        postData['points'] = getSurveyPoint;
        postData['user_device'] = panelistDevice;

        if(res.length == 6){    // panelists came from email (only samplicity surveys come from email, not Sell API)
          if(parseInt(res[5]) == 1){
            postData['survey_source'] = "email";
          } else {
            postData['survey_source'] = "dashboard";
          }
        } else {
          postData['survey_source'] = "dashboard";
        }
        
        //console.log(postData); return false;
        this._panelistService.SurveyStart(postData).subscribe((response:any) => {
            if(response.auth == true && response.success == true){

              if(res.length == 4){    // Create link for Sell API
                var encoded_survey_id = CommonJsFunc.encode64(survey_id);
                var encoded_panelist_id = CommonJsFunc.encode64(newPanelistId);
                var encoded_supplier_id = CommonJsFunc.encode64(1);

                var SellAPILink = environment.sell_api_survey_link+"survey_id="+encoded_survey_id+"&panelist_id="+encoded_panelist_id+"&supplier_id="+encoded_supplier_id;

                window.location.assign(SellAPILink);

              } else {      // Create link for Samplicity
                var screlid  = CommonJsFunc.encode64(supplierRelationShipId+'-'+myLangGlobal.SamplicitySupplierId+'-'+survey_id+'-'+quota_id);
                //console.log(supplierRelationShipId+'-'+myLangGlobal.SamplicitySupplierId+'-'+survey_id+'-'+quota_id);
                //return false;
                //var screlid  = CommonJsFunc.encode64(supplierRelationShipId+'-'+myLangGlobal.SamplicitySupplierId+'-'+survey_id);
                //console.log("screlid = "+screlid);
                var encoded_survey_id = CommonJsFunc.encode64(survey_id);
                var wpuid = newPanelistId+'_'+encoded_survey_id;

                // var SamplicityLink = "http://samplicity.wisesample.com/return/callback.php?screlid="+screlid+"&st=0&wpuid="+wpuid; // live url

                var SamplicityLink = environment.sc_survey_link+"screlid="+screlid+"&st=0&wpuid="+wpuid;
                //console.log(SamplicityLink);
                //return false;
                window.location.assign(SamplicityLink);
              }
            }
          }, (error:any) => {
            this.router.navigate(['/dashboard']).then(()=>{
              this.showNewFlashError(error,"WisePerks");
            });
          });

       
    /*Above Code is for Check LinkSurvey is Active or not, End Here */
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }
}
