import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PanelistService } from '../../shared/panelist.service';
import { PasswordValidation } from '../../home/shared/password-validation';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HomeService } from '../../home/shared/home.service';
import { TranslateService } from '../../shared/translate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as myLangGlobal from '../../shared/globals';
import {ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-dashboard-left-panel',
  templateUrl: './dashboard-left-panel.component.html',
  styleUrls: ['./dashboard-left-panel.component.css']
})
export class DashboardLeftPanelComponent implements OnInit {
  form: FormGroup;

  @Output() DashboardPanelistInfoEvent = new EventEmitter();
  @Output() DashboardPanelistChangeNameEvent = new EventEmitter();
  @Output() StopLeftLoader = new EventEmitter();
  @Output() BannerShowHide = new EventEmitter();
  @Output() SearchAnimationShowHide = new EventEmitter();
  @Output() ShowHideResendVerfiyBox = new EventEmitter();

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('EmailcloseBtn') EmailcloseBtn: ElementRef;
  @ViewChild('DeactivatecloseBtn') DeactivatecloseBtn: ElementRef;

  PanelistName:string = "";
  PanelistLastName:string = "";
  LedgerBalance:number;
  @Input() MainBalance:number;
  @Input() PendingBalance:number;
  ProfileImgPath:any = "";
  Panelistdob:string;
  PanelistGender:string;
  PanelistPostalCode:string;
  PanelistsCountryId:number;
  PanelistlifetimePoints:number;
  PanelistSurveyAttend:number;
  PanelistSurveyCompleted:number;
  PanelistEmail:string;
  PanelistAuthToken:string;
  PanelistJoined:string;
  PanelistEmailOptOut:boolean;
  IsOldPanelist:string;
  ProfilingPts:number;
  DOIPts:number;

  //language
  PanelistsCountryCode:string;
  PanelistPreferedLang:string;

  openSlidebar:boolean = false;
  isLeftSliderOpen:boolean = true;
  showOverlay:boolean = false;

  member_form:boolean = true;
  password_form:boolean = false;

  languages = Array<Language>();

  fileToUpload : File = null;

  dynamicProfileMenu:string;

  showDashboardTutorial:boolean = false;
  showProfileTutorial:boolean = false;
  showSVTutorial:boolean = false;
  showTransactionTutorial:boolean = false;
  showPassbookTutorial:boolean = false;
  showRedeemTutorial:boolean = false;
  showReferFriendTutorial:boolean = false;
  showProfileTutorialUpdate:boolean = false;

  modal_email_optoutmsg:boolean;
  modal_email_optinmsg:boolean;
  email_opt_out_setStatus:boolean;

  //responsive,start here
  ShowHideSidebar:boolean;
  
  public buttonloading: boolean = false;

  HistoryMenushow: boolean = true; // show transaction & survey history for new user or not
  passbook_live_date:string = myLangGlobal.PassbookLiveDate;
  
  constructor( private _panelistService: PanelistService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private _sanitizer: DomSanitizer,
    private _homeservice: HomeService,
    private router: Router) {

      let currentPreferLang = localStorage.getItem('PreferredLanguage');

      if(currentPreferLang !== null && myLangGlobal.LangArr.indexOf(currentPreferLang) !== -1){
        this.translateService.setLanguage(currentPreferLang);
      }
   }


  ngOnInit() {

    this.form = this.formBuilder.group({
      first_name : [null,[Validators.required,Validators.maxLength(15)]],
      last_name : [null],
      gender:[null],
      dob:[null]
    });

    let currentPreferLang = localStorage.getItem('PreferredLanguage');

    let resp = this._panelistService.GetGlobalPanelistInfo();
    
    /* below code to show survey & transaction history, start here*/
      let register_date = (resp.data.created_at).substring(0,10);
      var g1 = new Date(register_date);
      var g2 = new Date(this.passbook_live_date);

      if(g1.getTime() < g2.getTime())
        this.HistoryMenushow = true;
      else if(g1.getTime() >= g2.getTime())
        this.HistoryMenushow = false;
    /* below code to show survey & transaction history, end here*/

    if(resp.success == true && resp.auth == true){
      var panelist_fname = resp.data['first_name'];
      this.PanelistName = panelist_fname.charAt(0).toUpperCase() + panelist_fname.substr(1);
      this.LedgerBalance = resp.data['ledger_points'];
      this.MainBalance = resp.data['points'];
      this.PendingBalance = resp.data['pending'];

      this.PanelistLastName = resp.data['last_name'];
      this.Panelistdob = resp.data['dob'];
      var panelist_gender = resp.data['gender'];
      this.PanelistGender = panelist_gender.charAt(0).toUpperCase() + panelist_gender.substr(1);
      this.PanelistPostalCode = resp.data['zipcode'];
      this.PanelistlifetimePoints = resp.data['lifetime_points'];
      this.PanelistSurveyAttend = resp.data['survey_attended'];
      this.PanelistSurveyCompleted = resp.data['survey_completed'];
      this.PanelistEmail = resp.data['email'];
      this.PanelistAuthToken = resp.data['auth'];
      this.PanelistJoined = resp.data['created_at'];
     // this.PanelistStatusNewsLetter = resp.data['status_newsletter'];   // change becoz nilesh change the key from backend
     this.PanelistEmailOptOut = resp.data['status_email']; 
      this.PanelistsCountryCode = resp.data['country']['code'];
      this.PanelistsCountryId = resp.data['country']['id'];
      this.PanelistPreferedLang = resp.data['language'].toLowerCase();
      this.IsOldPanelist = resp.data['source'];
      this.ProfilingPts = resp.data['profiling_bonus_point'];
      this.DOIPts = resp.data['doi_point'];
      
      if(resp.data.step == 1){
        this.dynamicProfileMenu = "dashboard_sidebar_profile";
        this.BannerShowHide.emit({hide_banner:1}); // show on profiling category 
      } else if(resp.data.step == 2){
        this.dynamicProfileMenu = "dashboard_sidebar_updateprofile";
        this.BannerShowHide.emit({hide_banner:0}); // Hide on profiling category 
      }
      

      if(currentPreferLang != this.PanelistPreferedLang){
        this.translateService.setLanguage(this.PanelistPreferedLang);
      }
      
      let img_split = resp.data.avatar.split("/");
      
      let img_sec_last_word = img_split[img_split.length - 2];
      let img_last_word = img_split[img_split.length - 1];
      //console.log(resp.data['avatar']);
      //console.log(img_last_word);
      //console.log(img_sec_last_word);
      if(resp.data['avatar'] !="" && (img_sec_last_word == "profile_pic" || img_sec_last_word == "avatar") && img_last_word != ""){

        var first_key_image = resp.data['avatar'].substring(0,6);
        if(first_key_image == "assets"){
          this.ProfileImgPath = resp.data['avatar'];
        } else {
          this.ProfileImgPath = resp.data['avatar'];
        }
      }
      else if(resp.data['gender'] == 'male' || resp.data['gender'] == "Male"){
        this.ProfileImgPath = "assets/images/users/dashboard/profile/male-pp.png";
      } else {
        this.ProfileImgPath = "assets/images/users/dashboard/profile/female-pp.png";
      }

      let set_last_name = "";
      if(this.PanelistLastName == 'null'){
        set_last_name = "";
      } else {
        set_last_name = this.PanelistLastName;
      }
      this.form.patchValue({
        first_name: this.PanelistName,
        last_name: set_last_name
      });

      // Below Code is find language
      
      if(this.PanelistsCountryCode != "" && this.PanelistsCountryCode != null && this.PanelistsCountryCode != "undefined"){
        
        this._homeservice.getlanguageFromCountryCode(this.PanelistsCountryCode).subscribe((response:any) => {
          
          if(response.auth == true && response.success == true && response.count >0){
            this.languages.push(new Language(0,'Select your preferred language','cl'));
            for(var i=0; i<response.count; i++){
              var lang_code = response.data[i].lang_cod.toLowerCase( );
              this.languages.push(new Language(response.data[i].lang_id, response.data[i].lang, lang_code));
            }
          } else {
            this.languages.push(new Language(1,'English','en'));
          }
        },(error:any) => {
          this.languages.push(new Language(1,'English','en'));
        });
      }
      this.DashboardPanelistInfoEvent.emit({name: this.PanelistName, lang_arr: this.languages,
          country_code: this.PanelistsCountryCode, currentLang: this.PanelistPreferedLang,
        gender: this.PanelistGender, dob: this.Panelistdob, step: resp.data.step, 
        available_points: resp.data.points, email_verify:resp.data.email_verified, wisecheck:resp.data.wisecheck,
        country_id:this.PanelistsCountryId, email:this.PanelistEmail, authtoken:this.PanelistAuthToken, is_old_panelist: this.IsOldPanelist,
      status_email:resp.data.status_email, status_survey:resp.data.status_survey, status_cashout:resp.data.status_cashout, profiling_bonus_point:resp.data.profiling_bonus_point, doi_point:resp.data.doi_point, amount:resp.data.amount, promo_code:resp.data.promo_code, referral_code:resp.data.referral_code, pending_pts:this.PendingBalance});
      
      this.StopLeftLoader.emit({comp:'leftbar', flag:1});
      
    }


    /* Tutorial Part Start Here */
      let get_url = this.router.url;
      
      if(get_url == '/dashboard'){
        let dashboard_menu_tutorial = localStorage.getItem('DashboardMenuTutorial');
      
        if(dashboard_menu_tutorial == null)
        {
          this.showDashboardTutorial = true;
        }
      } else if(get_url == '/qualification'){
          if(resp.data.step == 1){
            let profile_menu_tutorial = localStorage.getItem('ProfileMenuTutorial');
        
            if(profile_menu_tutorial == null) {
              this.showProfileTutorial = true;
            } 
          } else if(resp.data.step == 2)  {
            let profile_menu_tutorial_update = localStorage.getItem('ProfileMenuTutorialUpdate');
        
            if(profile_menu_tutorial_update == null) {
              this.showProfileTutorialUpdate = true;
            }  
          }
      } else if(get_url == '/survey_history'){
        let survey_menu_tutorial = localStorage.getItem('SurveyMenuTutorial');
      
        if(survey_menu_tutorial == null)
        {
          this.showSVTutorial = true;
        }
      } else if(get_url == '/transaction'){
        let transaction_menu_tutorial = localStorage.getItem('TransactionMenuTutorial');
      
        if(transaction_menu_tutorial == null)
        {
          this.showTransactionTutorial = true;
        }
      } else if(get_url == '/redeem'){
        let redeem_menu_tutorial = localStorage.getItem('RedeemMenuTutorial');
      
        if(redeem_menu_tutorial == null)
        {
          this.showRedeemTutorial = true;
        }
      } else if(get_url == '/refer_friend'){
        let refer_friend_tutorial = localStorage.getItem('ReferFriendMenuTutorial');
        if(refer_friend_tutorial == null){
          this.showReferFriendTutorial = true;
        }
      } else if(get_url == '/passbook'){
        let passbook_tutorial = localStorage.getItem('PassbookMenuTutorial');

        if(passbook_tutorial == null){
          this.showPassbookTutorial = true;
        }
      }
    /* Tutorial Part End Here */
  }

  photoURL(getPhotoUrl) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(getPhotoUrl);
  }

  LeftSliderOpen(){
    this.isLeftSliderOpen = false;
    this.showOverlay = true;
    this.openSlidebar = true;
    var elem = document.getElementById("profileflip");
    elem.classList.add("open");

    this.SearchAnimationShowHide.emit('hide');
  }

  LeftSliderClose(){
    this.showOverlay = false;
    this.openSlidebar = false;
    this.isLeftSliderOpen = true;
    var elem = document.getElementById("profileflip");
    elem.className = elem.className.replace(/\bopen\b/g, "");

    this.SearchAnimationShowHide.emit('show');
  }

  ActiveMemberForm(){
    this.password_form = false;
    this.member_form = true;

    /*this.form.patchValue({
      first_name: this.PanelistName,
      last_name: this.PanelistLastName
    });*/
    let set_last_name = "";
    
    if(this.PanelistLastName == 'null'){
      set_last_name = "";
    } else {
      set_last_name = this.PanelistLastName;
    }
    this.form = this.formBuilder.group({
      first_name : [this.PanelistName,[Validators.required,Validators.maxLength(15)]],
      last_name : [set_last_name],
      gender:[null],
      dob:[null]
    });
  }


  ActiveUpdateForm(){
    this.member_form = false;
    this.password_form = true;

    this.form = this.formBuilder.group({
      email : [null],
      authtoken : [null],
      old_password : [null,[Validators.required, Validators.minLength(6),Validators.maxLength(15)]],
      password : [null,[Validators.required, Validators.minLength(6),Validators.maxLength(15)]],
      c_password : [null,[Validators.required, Validators.minLength(6),Validators.maxLength(15)]]
    },{
      validator: PasswordValidation.MatchPassword // your validation method
    });
  }

  EmailOff(e){ 
    
    if(e.target.checked == true){ 
      e.target.checked = false;
      
      this.modal_email_optinmsg = false;
      this.modal_email_optoutmsg = true;
      this.email_opt_out_setStatus = false;
      
    } else if(e.target.checked == false){ 
      e.target.checked = true;

      this.modal_email_optoutmsg = false;
      this.modal_email_optinmsg = true;
      this.email_opt_out_setStatus = true;

    }
  }


  EmailModalConfirm(){

    var successmsg = "";
    
    if(this.email_opt_out_setStatus == true){
      successmsg = "Your have successfully start receiving emails from WisePerks";
    } else {
      successmsg = "Your have successfully stops receiving emails from WisePerks";
    }

    //var data = "gender="+this.PanelistGender+"&dob="+this.Panelistdob+"&status_newsletter="+this.email_opt_out_setStatus;
    var data = "gender="+this.PanelistGender+"&dob="+this.Panelistdob+"&status_email="+this.email_opt_out_setStatus; 

    this._panelistService.PanelistUpdate(data).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true){
        //console.log('panelist_news_letter ='+ resp.data['status_newsletter']);
        this.ShowHideResendVerfiyBox.emit(resp.data['status_email']);
        this.PanelistEmailOptOut = resp.data['status_email'];
        this.EmailcloseBtn.nativeElement.click();
        this.toastr.clear();
        this.toastr.success(successmsg,"WisePerks");
        
      }
    },(err:any) =>{
      this.EmailcloseBtn.nativeElement.click();
      this.showNewFlashError(err,"WisePerks");
    });
  }


  EmailModalCancel(){
    this.EmailcloseBtn.nativeElement.click();
  }

  

  EmailButtonStatus(){ 
    
    if(this.PanelistEmailOptOut == false){ 
      return true;
    } else { 
      return false;
    }
  }


  DeactivateAccount(e){
    if(e.target.checked == true){
      e.target.checked = false;
    }
  }

  DeactivateModalConfirm(){

    var data = "gender="+this.PanelistGender+"&dob="+this.Panelistdob+"&status=-1";

    this._panelistService.PanelistUpdate(data).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true){
        this.DeactivatecloseBtn.nativeElement.click();
        this.onLogout();
        this.toastr.clear();
        this.toastr.success("You have successfully Deactivate your account!","WisePerks");
      }
    },(err:any) => {
      this.DeactivatecloseBtn.nativeElement.click();
      this.showNewFlashError(err,"WisePerks");
    });
    
  }

  DeactivateModalCancel(){
    this.DeactivatecloseBtn.nativeElement.click();
  }

  /*DeactivateAccount(){
    if(confirm('Are you sure to Deactivate your account from WisePerks')){
    
      var data = "gender="+this.PanelistGender+"&dob="+this.Panelistdob+"&status=-1";

      this._panelistService.PanelistUpdate(data).subscribe((resp:any)=>{
        if(resp.auth == true && resp.success == true){
          this.onLogout();
          this.toastr.clear();
          this.toastr.success("You have successfully Deactivate your account!");
        }
      },(err:any)=>{
        this.showNewFlashError(err,"WisePerks");
      });
    }
  }*/


  /* Validation Start Here */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  /* Validation End Here */

  SelectedLang(getLang){
    return this.PanelistPreferedLang == getLang;
  }

  PreferredLang(lang_code, event){
    localStorage.setItem('PreferredLanguage',lang_code);
    
    let selectIndex = event.target.selectedIndex;
    let getlanguageId = event.target[selectIndex]['attributes']['lang_id']['value'];
    
    //var data = {first_name:this.PanelistName, gender: this.PanelistGender, dob: this.Panelistdob, language: getlanguageId};
    var data = "gender="+this.PanelistGender+"&dob="+this.Panelistdob+"&language="+getlanguageId; 

    this._panelistService.PanelistUpdate(data).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true) {
        this.translateService.setLanguage(lang_code);
        //console.log(this.router.url);
        location.reload();
        //this.router.navigate([this.router.url]);
        this.toastr.clear();
        this.toastr.success("You have successfully changed your preffered Language", "WisePerks");
      }
    }, (err:any)=>{
      this.showNewFlashError(err,"WisePerks");
    });
  }

  memberSubmit(){
    this.buttonloading = true;
    if(this.form.valid){
      
      this.form.setValue({first_name:this.form.value['first_name'], last_name:this.form.value['last_name'],
      gender:this.PanelistGender, dob:this.Panelistdob});
     
      var user_last_name = "";
      /*if(this.form.value['last_name'] != ""){
        user_last_name = "&last_name="+this.form.value['last_name'];
      }*/

      user_last_name = "&last_name="+this.form.value['last_name'];

      var data = "first_name="+this.form.value['first_name']+user_last_name+"&gender="+this.PanelistGender+"&dob="+this.Panelistdob;

      this._panelistService.PanelistUpdate(data).subscribe((resp:any)=>{
        if(resp.auth == true && resp.success == true){
          this.PanelistName = this.form.value['first_name'];
          this.PanelistLastName = this.form.value['last_name'];
          this.DashboardPanelistChangeNameEvent.emit(this.PanelistName);
          this.toastr.clear();
          this.toastr.success("Profile has been updated successfully!","WisePerks");
          this.buttonloading = false;
        } else {
          this.toastr.clear();
          this.toastr.success("Something goes Error!","WisePerks");
          this.buttonloading = false;
        }
        
      },(err:any)=>{
        this.showNewFlashError(err,"WisePerks");
        this.buttonloading = false;
      });
    } else {
      this.buttonloading = false;
      this.validateAllFormFields(this.form); //{7}
    }
  }



  PasswordChange(){
    this.buttonloading = true;
    if(this.form.valid){ 

      this.form.setValue({email:this.PanelistEmail, old_password:this.form.value['old_password'],
        password:this.form.value['password'], c_password:this.form.value['c_password'], authtoken:this.PanelistAuthToken});
        
        this._panelistService.ChangePassword(this.form.value).subscribe((resp:any)=>{
          if(resp.auth==true && resp.success == true){
            this.toastr.clear();
            this.toastr.success(resp.message,"WisePerks");
            this.form.reset();
            this.buttonloading = false;
          }
        },(err:any)=>{ //console.log(err);
          this.showNewFlashError(err,"WisePerks");
          this.form.reset();
          this.buttonloading = false;
        });
    } else {
      this.buttonloading = false;
      this.validateAllFormFields(this.form);
    }
  }


  /* Below code is for Image Upload, Start Here */
  StaticImgUpload(ImgName){
    /*var Imgdata = {avatar : "assets/images/users/dashboard/panelistImg/avatar/"+ImgName, first_name: this.PanelistName,
      gender: this.PanelistGender, dob: this.Panelistdob};*/

    var data = "avatar=assets/images/users/dashboard/panelistImg/avatar/"+ImgName+"&gender="+this.PanelistGender+"&dob="+this.Panelistdob;  
    
    this._panelistService.PanelistUpdate(data).subscribe((resp:any)=>{
      if(resp.auth == true && resp.success == true){
        this.ProfileImgPath = resp.data['avatar'];
        this.toastr.clear();
        this.toastr.success("Image has been uploaded successfully!","WisePerks");
      }
    },(err:any)=>{
      this.showNewFlashError(err,"WisePerks");
    });
  }
  /* Above code is for Image Upload, End Here */


  handelFileInput(file : FileList){
    this.fileToUpload = file.item(0);
    this.closeBtn.nativeElement.click();
    
    this._panelistService.UploadImg(this.fileToUpload).subscribe((resp:any)=>{
      if(resp.auth== true && resp.success == true){
        this.ProfileImgPath = resp.data.avatar;
        this.toastr.clear();
        this.toastr.success(resp.message,"WisePerks");
      }
    },(err:any)=>{
      this.showNewFlashError(err,"WisePerks");
    });
  }


  ChangeProfileMenu(getevent){
    if(getevent.update_profile_menu == 1){
      this.dynamicProfileMenu = "dashboard_sidebar_updateprofile";
      this.showProfileTutorialUpdate = true;
    }

    if(getevent.main_balance){
      this.MainBalance = getevent.main_balance;
    }

    if(getevent.life_time_points){
      this.PanelistlifetimePoints = getevent.life_time_points;
    }
  }

  MenuTutorialSave(getMenuName){
    //console.log(getMenuName);
    localStorage.setItem(getMenuName,'1');
    if(getMenuName == 'DashboardMenuTutorial'){
      this.showDashboardTutorial = false;
    } else if(getMenuName == 'ProfileMenuTutorial'){
      this.showProfileTutorial = false;
    } else if(getMenuName == 'SurveyMenuTutorial'){
      this.showSVTutorial = false;
    } else if(getMenuName == 'TransactionMenuTutorial'){
      this.showTransactionTutorial = false;
    } else if(getMenuName == 'RedeemMenuTutorial'){
      this.showRedeemTutorial = false;
    }  else if(getMenuName == 'ProfileMenuTutorialUpdate'){
      this.showProfileTutorialUpdate = false;
    }  else if(getMenuName == 'ReferFriendMenuTutorial'){
      this.showReferFriendTutorial = false;
    } else if(getMenuName == 'PassbookMenuTutorial'){
      this.showPassbookTutorial = false;
    }
  }
  
  
  /*Below function is for responsive, Start Here */
  
  ShowSideBar(){
    var elem = document.getElementById("mySidenav");
    elem.className = elem.className.replace(/\bsn-res\b/g, "");
    elem.classList.add("");
  }

  HideSideBar() {
    var elem = document.getElementById("mySidenav");
    elem.classList.add("sn-res");
  }

  /* Above function for responsive, End here */

  showFlashError(err){
    this.toastr.clear();
    var get_errors = JSON.parse(err._body);
            if(get_errors.errors){
              var firstkey = Object.keys(get_errors.errors)[0];
              var msg = get_errors.errors[firstkey];
              this.toastr.error(msg,"WisePerks");
            } else if(get_errors.message){
              this.toastr.error(get_errors.message,"WisePerks");
            }
  }

  omit_special_char(event){   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
 }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }


  onLogout() {
    localStorage.removeItem('currentLoginPanelist');
    this.router.navigate(['']);
  }

}


export class Language
{
    constructor(id:number, name:string, code:string) {
        this.id=id;
        this.name=name;
        this.code=code;
    }
    id:number;
    name:string;
    code: string;
}
