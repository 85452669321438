import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular5-social-login';
import { PasswordValidation } from '../shared/password-validation';
import { HomeService } from '../shared/home.service';
import { PanelistService } from '../../shared/panelist.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-home-register',
  templateUrl: './home-register.component.html',
  styleUrls: ['./home-register.component.css']
})
export class HomeRegisterComponent implements OnInit {
  form: FormGroup;

  sign_up_form:boolean = true;
  sign_in_form:boolean = false;
  forgot_pass_form:boolean = false;
  referURL: any;

  GetPrefferLangId:number = 1; // default Eng

  amountToShow:any;
  iconToShow:any;

  @Output() changeSignUpButton = new EventEmitter();

  public buttonloading: boolean = false;

  constructor(private formBuilder: FormBuilder, 
    private _homeService: HomeService,
    private _panelistService: PanelistService,
    private socialAuthService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      fname : [null,[Validators.required]],
      lname : [null],
      email : [null,[Validators.required, Validators.email]],
      password : [null,[Validators.required,Validators.minLength(6),Validators.maxLength(15)]],
      c_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      tnc: [null, [Validators.required]],
    },{
      validator: PasswordValidation.MatchPassword // your validation method
    });

    this.referURL = "";

    if(this.activatedRoute.queryParams) {
      if(this.activatedRoute.queryParams['value']) {
        this.referURL = this.activatedRoute.queryParams['value']['refer'];
      }
    }
  }


  // function to get currency by country on banner
  getCurrency(get_currency){
    this.iconToShow = get_currency.icon;
    this.amountToShow = get_currency.amount;
  }

  SetPrefferLangId(get_prefer_langid){
    this.GetPrefferLangId = get_prefer_langid;
  }

    

  /*Below function is used to change form from login to Sign-up (slideToggle())*/
  sendData(data:string){
    if(data === 'login'){

      this.RegisterSlideUp();

      this.form = this.formBuilder.group({
        login_email : [null,[Validators.required, Validators.email]],
        login_pass : [null,[Validators.required]]
      });

      
      this.sign_up_form = false;
      this.forgot_pass_form = false;
      this.sign_in_form = true;

    } else {

      this.RegisterSlideDown();
      
      this.form = this.formBuilder.group({
        fname : [null,[Validators.required]],
        lname : [null],
        email : [null,[Validators.required, Validators.email]],
        password : [null,[Validators.required,Validators.minLength(6),Validators.maxLength(15)]],
        c_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
        tnc: [null, [Validators.required]],
        //recaptchaReactive : [null,[Validators.required]]
      },{
        validator: PasswordValidation.MatchPassword // your validation method
      });

      this.sign_in_form = false;
      this.forgot_pass_form = false;
      this.sign_up_form = true;
    }
  }


  ForgotForm(){
    this.changeSignUpButton.emit();
    this.ForgotSlideUp();

    this.form = this.formBuilder.group({
      forgot_email : [null,[Validators.required, Validators.email]]
    });

    this.sign_in_form = false;
    this.sign_up_form = false;
    this.forgot_pass_form = true;
  }

  RegisterSlideUp(){
    var elem = document.getElementById("register_form");
    elem.className = elem.className.replace(/\breg-slide-show\b/g, "");
    elem.classList.add("slide-close");

    var forg_elem = document.getElementById("forgot_form");
    forg_elem.className = forg_elem.className.replace(/\bforgot-slide-show\b/g, "");
    forg_elem.classList.add("slide-close");

    var elem1 = document.getElementById("login_form");
    elem1.className = elem1.className.replace(/\bslide-close\b/g, "");
    elem1.classList.add("log-slide-show");

  }


  RegisterSlideDown(){
    var element = document.getElementById("login_form");
    element.className = element.className.replace(/\blog-slide-show\b/g, "");
    element.classList.add("slide-close");

    var element1 = document.getElementById("register_form");
    element1.className = element.className.replace(/\bslide-close\b/g, "");
    element1.classList.add("reg-slide-show");
  }


  ForgotSlideUp(){
    var log_elem = document.getElementById("login_form");
    log_elem.className = log_elem.className.replace(/\blog-slide-show\b/g, "");
    log_elem.classList.add("slide-close");

    var forg_form = document.getElementById("forgot_form");
    forg_form.className = forg_form.className.replace(/\bslide-close\b/g, "");
    forg_form.classList.add("forgot-slide-show");
  }

  
  /* Validation Start Here */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  /* Validation End Here */


  onSubmit() { //console.log('inside reg.');
    if (this.form.valid) {

      /* below code we got 9 parameter (ip,user_device,country etc) */
      /*this._homeService.remainingParameter().subscribe((par_resp:any)=>{
        if(par_resp){
          let extra_parameter = par_resp;
          this.registerPanelist(this.form.value, extra_parameter,'formLogin');
        }
      },(par_err:any)=>{
        this.showNewFlashError(par_err,"WisePerks");
      });*/

      this.registerPanelist(this.form.value,'formLogin');
    } else { //console.log(this.form);
      this.validateAllFormFields(this.form); //{7}
    }
  }


  registerPanelist(form_value,formflag){
    
    this.buttonloading = true;
    this._homeService.registerUser(form_value,formflag,this.GetPrefferLangId,this.referURL)
    .subscribe((resp:any)=>{ //console.log(resp);
        if(resp.auth == true && resp.success == true){
          
          if(localStorage.getItem('isPanelistAlreadyRegister') === null){
            localStorage.setItem('isPanelistAlreadyRegister', resp.data['_id']);
          }
          
          localStorage.setItem('currentLoginPanelist',resp.data['_id']);
          if(resp.data['step']==0){
            this.router.navigate(['/demographics']).then(()=>{
              this.toastr.clear();
              this.toastr.success("Panelist Register Successfully","WisePerks");
            });
          }else if(resp.data['step']>=1){
            this.router.navigate(['/dashboard']).then(()=>{
              this.toastr.clear();
              this.toastr.success("Panelist Register Successfully","WisePerks");
            });
          }
          this.buttonloading = false;
          
          // calling SetUserComesTwice function form panelist service to check user comes first time to dashboard
          this._panelistService.SetUserComesTwice();

          // set isPromoClosed in local storage at registration time so panelist can see promo code popup
          if(localStorage.getItem('isPromoClosed') === null){
            localStorage.setItem('isPromoClosed', "true");
          }
          
        } else {
          this.toastr.clear();
          this.toastr.error(resp.message,"WisePerks");
          this.buttonloading = false;
        }
      },
      (err:any)=>{
        this.showNewFlashError(err,"WisePerks");
        this.buttonloading = false;
      });
  }


  loginSubmit() {
    this.buttonloading = true;
    if(this.form.valid) {

      // getting other parameter 
      /*this._homeService.remainingParameter().subscribe((param_resp:any) =>{
        if(param_resp){
          let get_extra_parameter = param_resp;
          this.loginPanelist(this.form.value,get_extra_parameter,'formLogin');
        }
      },(param_err:any)=>{
        this.showNewFlashError(param_err,"WisePerks");
      });*/

      this.loginPanelist(this.form.value,'formLogin');
    } else {
      this.validateAllFormFields(this.form);
      this.buttonloading = false;
    }
  }


  loginPanelist(form_value, form_flag){
    
    this._homeService.loginUser(form_value,form_flag)
      .subscribe((resp:any)=>{
        if(resp.auth == true && resp.success == true){
          
          if(localStorage.getItem('isPanelistAlreadyRegister') === null){
            localStorage.setItem('isPanelistAlreadyRegister',resp.data['_id']);
          } else {
            var exists_panelist_id = localStorage.getItem('isPanelistAlreadyRegister');
            var current_login_panelist = resp.data['_id'];
            var current_login_panelist_wc = resp.data['wisecheck'];
            
            // Commenting code for WiseCheck panelist at the time of login as per discussion with GA
            // if(exists_panelist_id != current_login_panelist && current_login_panelist_wc == 0){
            //   this._homeService.updateWiseCheck(current_login_panelist,1).subscribe((resp:any)=>{
            //     //console.log('creating a log file');
            //   },
            //   (err)=>{
            //     //console.log('creating a log file + error');
            //   });
            // }
          }

          localStorage.setItem('currentLoginPanelist',resp.data['_id']);
          if(resp.data['step']==0){
            this.router.navigate(['/demographics']).then(()=>{
              this.toastr.clear();
              this.toastr.success("Panelist Login Successfully","WisePerks");
            });
          }else if(resp.data['step']>=1){
            // set isPromoClosed in local storage at login time so panelist can see promo code popup
            if(localStorage.getItem('isPromoClosed') === null){
              localStorage.setItem('isPromoClosed', "true");
            }
            this.router.navigate(['/dashboard']).then(()=>{
              this.toastr.clear();
              this.toastr.success("Panelist Login Successfully","WisePerks");
            });
          }
          this.buttonloading = false;
        } else {
          this.toastr.clear();
          this.toastr.error(resp.message,"WisePerks");
          this.buttonloading = false;
        }
      },
      (err:any)=>{
        this.showNewFlashError(err,"WisePerks");
        this.buttonloading = false;
      });
  }


  forgotSubmit(){
    this.buttonloading = true;
    if(this.form.valid){
      this._homeService.forgotPassword(this.form.value)
      .subscribe(
        (resp:any) => {
          if(resp.auth == true && resp.success == true){
            this.toastr.clear();
            this.toastr.success(resp.message, "WisePerks");
          }
          this.buttonloading = false;
        },
        (err:any) => {
          this.showNewFlashError(err,"WisePerks");
          this.buttonloading = false;
        }
      );
    } else {
      this.validateAllFormFields(this.form);
      this.buttonloading = false;
    }
  }


  public socialSignIn(socialPlatform : string){
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => { 
        if(userData.email == null){
          this.toastr.clear();
          this.toastr.error("Email is not defined in FB","WisePerks");
        } else {

              this._homeService.isPanelistExists(userData.email).subscribe((resp:any)=>{ 
                  if(resp.auth == true && resp.success == true){
                    var fbuserData = userData;
                    fbuserData['auth'] = resp.data[0].auth;
                  
                    this.loginPanelist(fbuserData,'fbLogin'); // login user via fb, if email exists
                  }
              },(err:any)=>{
                //var get_errors = JSON.parse(err.error);
                var get_errors = err.error;
                
                if(get_errors.auth == true && get_errors.success == true){ // reg user via fb, if email not exists at time of login
                  
                  this.registerPanelist(userData,'fbLogin');

                } else {  // errors part goes here
                  if(get_errors.errors){
                    var firstkey = Object.keys(get_errors.errors)[0];
                    var msg = get_errors.errors[firstkey];
                    this.toastr.clear();
                    this.toastr.error(msg,"WisePerks");
                  } else if(get_errors.message) {
                    this.toastr.clear();
                    this.toastr.error(get_errors.message,"WisePerks");
                  }
                }
              });
            

        }
      } 
    );
  }


  public socialSignUp(socialPlatform : string) {
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => { 
        if(userData.email == null) {
          this.toastr.clear();
          this.toastr.error("Email is not defined in FB","WisePerks");
        } else {
          this.registerPanelist(userData,'fbLogin');
        }
      }
    );
  }


  showFlashError(err){ //console.log(err);
    /*var get_errors = JSON.parse(err._body);
            if(get_errors.errors){
              var firstkey = Object.keys(get_errors.errors)[0];
              var msg = get_errors.errors[firstkey];
              this.toastr.error(msg,"WisePerks");
            } else if(get_errors.message){
              this.toastr.error(get_errors.message,"WisePerks");
            }*/
            this.toastr.error(err.error.message,"WisePerks");
  }

  omit_special_char(event){   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}
