import { Pipe, PipeTransform } from '@angular/core';
import { PanelistService } from './panelist.service';

@Pipe({
  name: 'referralPartialPoint'
})
export class ReferralPartialPointPipe implements PipeTransform {

  constructor(private panelistService: PanelistService){}

  transform(value: any, args?: any): any {
    // return null;
    let partial_referral = this.panelistService.GetReferralPartialPanelistPoints();
    return value  ? value.replace('partial_referral', partial_referral) : value;
  }

}
