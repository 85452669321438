// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  google_analytics_code: 'UA-136936988-1',
  sc_survey_link: "https://samplicity.wisesample.com/return/callback.php?",
  sell_api_survey_link: "https://api.wiseworksresearch.com/profiling?",
  // rootUrl: "//staging.wiseperks.com:7000/api/v1/c342590d3324f0be522862360c9b94af",
  // rootPHPUrl: "//staging.wiseperks.com:9001",
  // socketConnection: "//staging.wiseperks.com:7000"
  rootUrl: "https://wiseperks.com:7000/api/v1/c342590d3324f0be522862360c9b94af",
  rootPHPUrl: "https://wiseperks.com:9001",
  socketConnection: "https://wiseperks.com:9000"
};
