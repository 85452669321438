import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ProfileComponent } from './home/profile/profile.component';
import { FaqComponent } from './home/faq/faq.component';
import { PrivacyPolicyComponent } from './home/privacy-policy/privacy-policy.component';
import { PrivacyPolicyExtendedComponent } from './home/privacy-policy-extended/privacy-policy-extended.component';
import { ResetPasswordComponent } from './home/reset-password/reset-password.component';
import { UnsubscribeComponent } from './home/unsubscribe/unsubscribe.component';
import { TermsConditionsComponent } from './home/terms-conditions/terms-conditions.component';
import { HomeVerifyEmailComponent } from './home/home-verify-email/home-verify-email.component';

import { PageNotFoundComponent } from '../shared/page-not-found/page-not-found.component';
import { NotAvailableComponent } from '../shared/not-available/not-available.component';

import { ProfilingComponent } from './dashboard/profiling/profiling.component';
import { SurveyHistoryComponent } from './dashboard/survey-history/survey-history.component';
import { TransactionHistoryComponent } from './dashboard/transaction-history/transaction-history.component';
import { RedeemComponent } from './dashboard/redeem/redeem.component';
import { PostbackComponent } from './dashboard/postback/postback.component';
import { SurveyLinkComponent } from './dashboard/survey-link/survey-link.component';
import { ReleasesComponent } from './dashboard/releases/releases.component';
import { ReferFriendComponent } from './dashboard/refer-friend/refer-friend.component';
import { PassbookComponent } from './dashboard/passbook/passbook.component';

import { AuthGuard } from './auth/auth.guard';
import { LoginGuard } from './auth/login.guard';
import { ProfileGuard } from './auth/profile.guard';

/*const userRoutes: Routes = [
    { path: 'dashboard', component: DashboardComponent },
    { path: '', component: HomeComponent },
  ];


@NgModule({
    imports: [RouterModule.forChild(userRoutes)],
    exports: [RouterModule]
})
export class UserRoutingModule { }*/

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate:[LoginGuard]
    },
    {
        path: 'wiseperk',
        component: HomeComponent,
        canActivate:[LoginGuard]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate:[AuthGuard]
    },
    {
        path: 'demographics',
        component: ProfileComponent,
        canActivate:[ProfileGuard]
    },
    {
        path: 'redeem',
        component: RedeemComponent,
        canActivate:[AuthGuard]
    },
    { path: 'releases', component: ReleasesComponent},
    { path: 'faq', component: FaqComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'privacy-policy-extended', component: PrivacyPolicyExtendedComponent },
    { path: 'reset_password', component: ResetPasswordComponent },
    { path: 'unsubscribe', component: UnsubscribeComponent },
    { path: 'terms-and-conditions', component: TermsConditionsComponent },
    { path: 'verify_email', component: HomeVerifyEmailComponent },
    { path: 'qualification', component: ProfilingComponent, canActivate:[AuthGuard] },
    { path: 'survey_history', component: SurveyHistoryComponent, canActivate:[AuthGuard] },
    { path: 'refer_friend', component: ReferFriendComponent, canActivate:[AuthGuard] },
    { path: 'transaction', component: TransactionHistoryComponent, canActivate:[AuthGuard] },
    { path: 'passbook', component: PassbookComponent, canActivate:[AuthGuard] },
    { path: 'postback/:id', component: PostbackComponent },
    { path: 'survey_link/:id', component: SurveyLinkComponent },
    { path: 'not-available', component: NotAvailableComponent },
    { path: '**', component: PageNotFoundComponent }

];

export const ModuleRouting: ModuleWithProviders = RouterModule.forChild(routes);