// Indonesia
export const TRANSLATIONS_ID = {
  "home_header_selectlang": "Pilih bahasa",
  "home_header_loginbutton": "Masuk",
  "home_header_signupbutton": "Daftar",
  "home_header_homebutton": "Rumah", 
  
  "home_register_bannertxt1": "Di sini Anda",
  "home_register_bannertxt2": "pikiran",
  "home_register_bannertxt3": "dapat menjemput Anda",
  "home_register_bannertxt4": "Penghargaan$",
  "home_register_bannertxt5": "Dapatkan bonus",
  "home_register_bannertxt6": "Saat Mendaftar",
  "home_register_fbsignup": "MENDAFTAR DENGAN FACEBOOK",
  "home_register_fblogin": "MASUK DENGAN FACEBOOK",
  "home_register_or": "ATAU",
  "home_register_emailsignup": "Mendaftar dengan Email",
  "home_register_emaillogin": "Login dengan Email",
  "home_register_fpass": "Lupa kata sandi?",
  "home_register_fname": "Nama depan",
  "home_register_lname": "Nama keluarga",
  "home_register_email": "Alamat email",
  "home_register_pass": "Kata sandi",
  "home_register_cpass": "konfirmasi sandi",
  "home_register_tcpp": "Dengan bergabung, Anda menyetujui kami",
  "home_register_tcpptandcond": "Syarat & Ketentuan",
  "home_register_tcppand": "dan",
  "home_register_tcppprivacy": "Kebijakan pribadi",
  "home_register_join": "Bergabunglah dengan kami. Gratis",
  "home_register_login": "masuk",
  "home_register_name_required" : "Nama wajib diisi.",
  "home_register_email_required" : "Email diperlukan.",
  "home_register_invalid_email" : "Format email salah",
  "home_register_password_required" : "Katakunci dibutuhkan.",
  "home_register_password_lower_digit" : "Kata sandi harus 6 digit diperlukan",
  "home_register_password_higher_digit" : "Kata sandi harus digunakan 15 digit diperlukan",
  "home_register_cpassword_required" : "Konfirmasi kata sandi diperlukan",
  "home_register_cpassword_lower_digit" : "Konfirmasi kata sandi harus 6 digit diperlukan",
  "home_register_cpassword_higher_digit" : "Konfirmasi kata sandi harus digunakan 15 digit diperlukan",
  "home_register_cpassword_not_match" : "Konfirmasi kata sandi tidak cocok",

  "home_register_fp_not_worry" : "Masukkan emailid Anda terdaftar.", 
  "home_register_fp_resetpass_msg" : "Untuk menyetel ulang sandi, masukkan id email terdaftar Anda.",
  "home_register_fp_enter_email_msg" : "Masukkan email Anda di sini", 
  "home_register_fp_submit_button" : "Kirim",
  "home_register_fp_temporary_sent_pass_msg" : "Tautan setel ulang sandi telah dikirim ke email terdaftar id Anda. Perbarui sandi Anda di awal.", 
  
  "home_works_howitworks": "Bagaimana itu bekerja?",
  "home_works_howitworkst1": "Perkenalkan dirimu", 
  "home_works_howitworksd1": "Menjadi anggota, Mendaftar",
  "home_works_howitworkst2": "Terima survei",
  "home_works_howitworksd2": "Mulai menerima survei yang intuitif dan sederhana",
  "home_works_howitworkst3": "Hasilkan Poin",
  "home_works_howitworksd3": "Berikan tanggapan terbaik Anda dan kumpulkan poin",
  "home_works_howitworkst4": "Menebus",
  "home_works_howitworksd4": "Tukarkan poin Anda untuk banyak hadiah menarik",
  
  "home_aboutus_whoweare": "Siapa kita?",
  "home_aboutus_whowearep1": "Di WisePerks, kami bekerja keras untuk memastikan bahwa waktu yang Anda habiskan di internet adalah pengalaman yang bermanfaat. Kami bekerja dengan ratusan perusahaan yang ingin mendapatkan umpan balik Anda, tahu bagaimana perasaan Anda tentang produk atau layanan mereka. Anda memberikan pendapat Anda tentang berbagai layanan, tetapi tidak satu pun dari layanan tersebut yang benar-benar memberi Anda imbalan waktu Anda, tidak seperti WisePerks - di mana setiap survei yang Anda ikuti akan memberi Anda hadiah uang tunai yang besar untuk waktu Anda.",
  "home_aboutus_whowearep2": "Kami menghargai umpan balik Anda dan ingin Anda mendapatkan imbalan atas waktu Anda. Kami memiliki survei tentang berbagai topik untuk disesuaikan dengan minat Anda dan membuatnya mudah untuk dijawab. Anda dapat dengan mudah menghasilkan $ 50 - $ 100 setiap bulan dengan mengambil berbagai survei. Jadi, mari bekerja bersama untuk mewujudkannya.",
  
  "home_earn_more_surabt": "Survei meminta apa?",
  "home_earn_more_bp": "Pakar bisnis",
  "home_earn_more_consumers": "Konsumen",
  "home_earn_more_hc": "Kesehatan & kebugaran",
  "home_earn_more_entertainment": "Menghibur & menyenangkan",
  "home_earn_more_gaming": "Game aneh",
  "home_earn_more_tech": "Tech Savvy",
  "home_earn_more_bonus_survey": "Bonus survei",
  
  "home_reward_whatuearn": "Apa yang Anda hasilkan?",
  "home_reward_whatuearnp1": "Kami ingin menjaga hal-hal sederhana. Anda mendapatkan poin untuk semua survei yang berhasil diselesaikan. Semakin banyak survei yang Anda selesaikan, semakin banyak poin yang Anda peroleh. Poin dapat ditebus untuk Kartu Hadiah pilihan Anda setelah Anda melewati batas tertentu.",
  "home_reward_whatuearnp2": "Setelah siap untuk ditukarkan, pilih opsi yang Anda suka - Amazon, Starbucks, iTunes, dan banyak lainnya. Kami juga memberikan Bonus dan Syukur God Its Friday menawarkan dari waktu ke waktu.",
  
  "home_counter_earnedover": "Anggota telah mendapatkan lebih",
  
  "home_contact_getintouch": "Berhubungan",
  "home_contact_emailus": "Untuk pertanyaan apa pun, silakan kirim email kepada kami di",
  
  "home_footer_faq": "Tanya jawab",
  "home_footer_pp": "Kebijakan pribadi",
  "home_footer_tc": "T＆C",
  "home_footer_uf": "Fitur yang akan datang",
  "home_footer_copyright": "Hak cipta © current_year WisePerks. Seluruh hak cipta.",

  "home_header_home": "Rumah", 
  "home_footer_toknowmore": "Untuk mengetahui lebih lanjut",
  "home_footer_createacc": "Buat akun gratis dan bergabunglah dengan ribuan anggota bahagia kami.",
  "home_footer_registernow": "Daftar sekarang",

  "home_reset_password" : "Membuat ulang sandi", 
  "home_reset_pass_content" : "Anda minta untuk me-reset password",   

  "dashboard_sidebar_selectavatar" : "Pilih avatar Anda", 
  "dashboard_sidebar_uploadavatar" : "Klik di sini untuk meng-upload gambar Anda", 
  "dashboard_sidebar_pbank" : "Bank poin",
  "dashboard_sidebar_accumulated" : "Diterima", 
  "dashboard_sidebar_realized" : "Menyadari",
  "dashboard_sidebar_pending" : "Penyesuaian",
  "dashboard_sidebar_earned_tooltip" : "Poin yang telah Anda kumpulkan sejauh ini.",
  "dashboard_sidebar_pending_tooltip" : "Poin yang perlu dibalik dari akun Anda.",
  "dashboard_sidebar_main" : "Utama",
  "dashboard_sidebar_dashboard" : "Dasbor",
  "dashboard_sidebar_profile" : "Profil",
  "dashboard_sidebar_updateprofile" : "Update profil",
  "dashboard_sidebar_shistory" : "Riwayat Survei",
  "dashboard_sidebar_mtrans" : "Transaksi saya",
  "dashboard_sidebar_passbook" : "Buku Poin",
  "dashboard_sidebar_redeem" : "Menebus",
  "dashboard_sidebar_refer_friend" : "Rujuk Teman",
  "dashboard_sidebar_logout" : "Keluar",

  "dashboard_sidebar_tutorial1" : "Menunjukkan kepada Anda semua yang tersedia survei dan apa yang dapat Anda peroleh dari mereka.",
  "dashboard_sidebar_tutorial2" : "Menjawab beberapa pertanyaan di sini untuk memberitahu kami tahu Anda lebih baik dan membantu kita dalam mengirim Anda survei minat Anda. Harap dicatat untuk beberapa pertanyaan Anda tidak akan dapat memperbarui Tanggapan Anda setelah mengirimkan.",
   "dashboard_sidebar_tutorial2update" : "Anda dapat memperbarui Tanggapan Anda di sini, namun beberapa pertanyaan tidak akan tersedia untuk updation.",
  "dashboard_sidebar_tutorial3" : "Temukan di sini semua survei Anda mencoba.",
  "dashboard_sidebar_tutorial4" : "Membantu Anda melacak semua transaksi Anda pada WisePerks.",
  "dashboard_sidebar_tutorial5" : "Beberapa langkah cepat untuk mengkonversi poin Anda menyadari pahala.",
  "dashboard_sidebar_tutorial6" : "Lihat seluruh riwayat transaksi dari poin yang didebit / dikreditkan ke akun Anda.",
  "dashboard_sidebar_gotit" : "Mengerti",

  "dashboard_sidebar_note" : "Tolong dicatat",
  "dashboard_sidebar_note1" : "Semua tanggapan harus dijawab dengan jujur.",
  "dashboard_sidebar_note2" : "Harap spesifik dan tepat dengan tanggapan terbuka.",
  "dashboard_sidebar_note3" : "Jangan mempercepat! Waktu penyelesaian survei minimum ditetapkan.",
  "dashboard_sidebar_note4" : "Harap berikan tanggapan yang konsisten ketika pertanyaan yang sama ditanyakan.",
  "dashboard_sidebar_note5" : "Survei baru akan ditambahkan dan tertutup yang akan dihapus secara otomatis dari dashboard Anda.",
  "dashboard_sidebar_note6" : "Dapatkan beberapa poin cepat hanya dengan referensi.",

  "dashboard_setting_hi" : "Hai",
  "dashboard_setting_minfo" : "Info Keanggotaan",
  "dashboard_setting_macc" : "Kelola Akun",
  "dashboard_setting_upass" : "Perbarui Kata Sandi",
  "dashboard_setting_yourname" : "Nama Anda",
  "dashboard_setting_fname" : "Nama depan",
  "dashboard_setting_lname" : "Nama keluarga",
  "dashboard_setting_save" : "Simpan perubahan",
  "dashboard_setting_bornon" : "Anda dilahirkan",
  "dashboard_setting_youare" : "Anda adalah",
  "dashboard_setting_postal" : "Kode pos Anda adalah",
  "dashboard_setting_emailus" : "Untuk bantuan atau pertanyaan apapun, silakan kirim email ke",
  "dashboard_setting_membersince" : "Anda telah menjadi anggota sejak itu",
  "dashboard_setting_taken" : "Anda telah mengambil",
  "dashboard_setting_completed" : "survei dan selesai",
  "dashboard_setting_ofthem" : "dari mereka.",
  "dashboard_setting_earned" : "Anda berhasil",
  "dashboard_setting_pts" : "poin",
  "dashboard_setting_started" : "sejak kamu mulai.",
   "dashboard_register_email" : "Anda terdaftar dengan kami dari",
  "dashboard_setting_preferences" : "Kelola preferensi Anda",
  "dashboard_setting_emailoptout" : "Hapus Email Keluar",
  "dashboard_setting_futureemails" : "Memilih untuk tidak menerima email dari WisePerks di masa mendatang.",
  "dashboard_setting_futureemailsflash" : "Email kami membuat Anda diposting pada semua survei menguntungkan tersedia untuk Anda. Apakah Anda yakin Anda tidak ingin menerima mereka.",
  "dashboard_setting_nrecemail" : "Email kami membuat Anda diposting pada semua survei menguntungkan tersedia untuk Anda. Apakah Anda yakin Anda tidak ingin menghentikan mereka.",
  "dashboard_setting_emailout_modalmsg" : "Apakah Anda yakin Anda ingin memilih keluar dari menerima email masa depan dari WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Apakah Anda yakin untuk mulai menerima email masa depan dari WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Anda telah berhasil berhenti menerima email dari WisePerks",
  "dashboard_setting_emailin_succmsg" : "Anda telah berhasil mulai menerima email dari WisePerks",
  "dashboard_setting_deactivateacc" : "Nonaktifkan Akun",
  "dashboard_setting_disableprofile" : "Menonaktifkan akun Anda akan menonaktifkan profil Anda dari WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Apakah Anda yakin ingin menonaktifkan account di WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Jika Anda punya keluhan atau masukan, silahkan berbagi dengan kami. Kami akan mengatasinya prioritas. Berikan kesempatan. Apakah Anda yakin Anda ingin menghapus account Anda dengan kami.",
  "dashboard_setting_changelang" : "Ganti BAHASA",
  "dashboard_setting_preferredlang" : "Pilih bahasa pilihan Anda",
  "dashboard_setting_updatepass" : "Perbarui Kata Sandi",
  "dashboard_setting_plsupdate" : "Perbarui sandi Anda di bawah ini.",
  "dashboard_setting_oldpass" : "Password lama",
  "dashboard_setting_enteroldpass" : "Masukkan Kata Sandi Lama",
  "dashboard_setting_newpass" : "Kata sandi baru",
  "dashboard_setting_enternewpass" : "Masukan kata sandi baru",
  "dashboard_setting_confpass" : "Konfirmasi sandi",
  "dashboard_setting_enternewpassagain" : "Masukkan Kata Sandi Baru Lagi",
  "dashboard_setting_update" : "Memperbarui",

   "dashboard_doi_welcome" : "Selamat datang",
  "dashboard_doi_welbck" : "Selamat datang kembali",
  "dashboard_doi_accntverified" : "Sepertinya Anda merindukan memverifikasi akun email Anda.",
  "dashboard_doi_getaccess" : "Cepat! Anda mendapatkan",
  "dashboard_doi_getaccess2" : "poin setelah Anda memverifikasi.",
  "dashboard_doi_activateacc" : "Silakan cek inbox Anda dan klik link yang diberikan dalam email verifikasi akun yang dikirim ke akun email yang terdaftar.",
  "dashboard_doi_chckspam" : "Mohon, periksa folder Spam/Junk jika Anda tidak dapat menemukan email verifikasi.",
  "dashboard_doi_didntgetconf" : "Tidak mendapatkan email verifikasi?",
  "dashboard_doi_resendconf" : "Kirim ulang email verifikasi",
  "dashboard_doi_resendconfsucc" : "Verifikasi email telah dikirim ke email terdaftar Anda.", 
  
  "dashboard_survey_availsurveys" : "Survei yang Tersedia",
  "dashboard_survey_wisechecksurveys1" : "Anda tidak dapat melihat survei karena beberapa alasan keamanan. Silahkan hubungi untuk mendukung di",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Survei yang tidak tersedia untuk alasan berhenti berlangganan",
  "dashboard_featured" : "FEATURE",
  "dashboard_profile_survey" : "Survei profil",
  "dashboard_survey_start" : "Mulai",
  "dashboard_survey_on" : "Di",
  "dashboard_survey_off" : "Mati",
  "dashboard_survey_point": "Titik",
  "dashboard_survey_points" : "Poin",
  "dashboard_survey_minute" : "Menit",
  "dashboard_survey_minutes" : "Menit",

  "demography_hi" : "Hai",
  "demography_welcome" : "Selamat datang di WisePerks. Anda hanya mendapatkan diri Anda",
  "demography_points" : "poin dengan mendaftar dengan kami.",
  "demography_share" : "PSilakan berbagi beberapa rincian lebih lanjut pada form dibawah, untuk membantu kami dalam membawa survei yang relevan kepada Anda.",

  "demography_gender" : "saya adalah seorang",
  "demography_male" : "Pria",
  "demography_female" : "Wanita",
  "demography_birthday" : "Ulang tahun",
  "demography_month" : "Bulan", 
  "demography_day" : "Hari", 
  "demography_year" : "Tahun", 
  "demography_jan" : "Januari",
  "demography_feb" : "Februari",
  "demography_mar" : "Maret",
  "demography_apr" : "April",
  "demography_may" : "Mungkin",
  "demography_jun" : "Juni",
  "demography_jul" : "Juli",
  "demography_aug" : "Agustus",
  "demography_sep" : "September",
  "demography_oct" : "Oktober",
  "demography_nov" : "November",
  "demography_dec" : "Desember",
  "demography_hearabout" : "Dari mana Anda mendengar tentang WisePerks?",
  "demography_opt1" : "Pilih yang terdekat yang berlaku",
  "demography_opt2" : "Email / Buletin",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum atau Blog",
  "demography_opt5" : "Teman",
  "demography_opt6" : "LinkedIn",
  "demography_opt7" : "Mesin pencari",
  "demography_opt8" : "Iklan TV",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Situs web",
  "demography_opt11" : "YouTube",
  "demography_opt12" : "Lainnya",
  "demography_zipcode": "Kode Pos",
  "demography_submit" : "Kirim",

  "privacypolicy_privacypolicy" : "Kebijakan pribadi",
  "privacypolicy_privacyisimp" : "Privasi Anda penting bagi kami.",
  "privacypolicy_lastupdated" : "Terakhir diperbarui saat",

  "privacypolicy_para1" : 'WiseWorks (bersama dengan afiliasinya, "Perusahaan") memiliki dan mengoperasikan sejumlah situs web berbeda, aplikasi seluler, dan layanan interaktif, termasuk tanpa batasan Wisesample.com, Flapbucks.com, WisePerks.com, dan lainnya (secara kolektif, " Situs WiseWorks ").Kebijakan privasi ini ("Kebijakan Privasi") berlaku untuk Situs WiseWorks dan untuk semua fitur, aplikasi seluler, email, layanan online, dan fungsi lainnya, baik diakses melalui komputer, perangkat seluler, atau lainnya dan layanan yang tersedia pada atau melalui Situs dan Fitur WiseWorks ("Layanan").Kebijakan Privasi ini hanya mencakup informasi yang dikumpulkan oleh Perusahaan melalui Situs dan Fitur WiseWorks dan Layanan kami, dan tidak mencakup informasi apa pun yang diterima atau dikumpulkan offline oleh Perusahaan (kecuali dinyatakan secara khusus) atau dikumpulkan oleh situs web atau perusahaan yang tidak terkait di mana kami dapat menyediakan tautan.',

  "privacypolicy_para2" : "Diharapkan bahwa panelis / responden harus mengisi profilnya secara jujur ​​dan jujur ​​dan tidak mengirimkan informasi yang salah atau salah hanya untuk memenuhi syarat untuk survei lebih lanjut.Diharapkan juga bahwa panelis / responden tidak boleh memanipulasi jawaban-jawabannya atau menggunakan cara lain yang tidak adil (mengubah IP, banyak akun dll) selama pengisian survei untuk mendapatkan insentif.WisePerks memiliki perlindungan yang memadai untuk mendeteksi anomali tersebut dan jika perilaku tersebut diperhatikan, panelis akan dicekal / diblokir dari WisePerks dan semua / permintaan penebusannya akan dibatalkan.",

  "privacypolicy_para3" : "WiseWorks sangat berkomitmen untuk menjaga privasi Anda dengan kami.Kebijakan privasi berikut menjelaskan bagaimana informasi pribadi Anda disimpan dan diperlakukan ketika Anda menjelajahi situs web dan layanan kami.",

  "privacypolicy_topic1" : "Informasi pengenal pribadi apa yang dikumpulkan oleh WisePerks.com dari saya?",
  "privacypolicy_topic1ans" : "WisePerks.com mengumpulkan informasi dengan beberapa cara dari berbagai bagian di situs web dan jaringan kami.Beberapa informasi dikumpulkan ketika Anda mendaftar.Selama pendaftaran anggota, WisePerks.com menanyakan nama Anda, alamat email, kode pos dan informasi rumah tangga.Semakin banyak informasi yang Anda berikan secara akurat, semakin baik kami dapat menawarkan layanan dan hadiah kepada Anda.Selain itu, jika Anda pernah menghubungi WisePerks.com dengan cara apa pun, kami mungkin menyimpan catatan korespondensi itu.Setiap kali WisePerks.com mengumpulkan informasi semacam itu, kami bertujuan untuk menyertakan tautan ke Kebijakan Privasi ini pada halaman itu.",

  "privacypolicy_topic2" : "Apa itu cookie dan bagaimana WisePerks.com menggunakannya?",
  "privacypolicy_topic2ans" : "Cookie adalah paket data elektronik yang dikirim ke browser Anda, dari server web dan disimpan di hard drive komputer Anda.Sebagai bagian dari penawaran survei spesifik, informasi, dan layanan yang dipersonalisasi, WisePerks.com dapat menggunakan cookie untuk menyimpan dan terkadang melacak informasi tentang Anda.WisePerks.com mungkin mengharuskan Anda menerima cookie untuk menyiapkan konten spesifik tersebut untuk Anda.Ini juga menghindari Anda harus memasukkan informasi login Anda setiap saat.",

  "privacypolicy_topic3" : "Apa kebijakan WisePerks.com tentang memperbolehkan saya memperbarui, mengedit, mengubah atau menghapus informasi akun pribadi saya?",
  "privacypolicy_topic3ans" : "Anda dapat mengedit Informasi Akun WisePerks.com Anda kapan saja dengan menggunakan Login dan kata sandi WisePerks.com Anda.Silakan hubungi",
   "privacypolicy_topic3ans2" : "untuk instruksi lebih lanjut tentang menonaktifkan akun WisePerks.com Anda.",

  "privacypolicy_topic4" : "Siapa yang mengumpulkan informasi dan untuk tujuan apa?",
  "privacypolicy_topic4ansp1" : "WisePerks.com mengumpulkan beberapa informasi melalui survei dan pendaftaran dan cara lain, yang membantu klien penelitiannya mengembangkan produk dan layanan baru bagi konsumen.Anda membagikan informasi itu hanya dengan WisePerks.com, kecuali jika secara khusus dinyatakan sebaliknya pada survei apa pun yang Anda ikuti.Jika data dikumpulkan dan / atau dikelola oleh perusahaan lain selain WiseWorks - atau situs WiseWorks, Anda akan diberitahu sebelum waktu pengumpulan atau transfer data.Jika Anda tidak ingin data Anda dibagikan, Anda dapat memilih untuk tidak mengizinkan transfer dengan tidak menggunakan survei atau produk tertentu.",
  "privacypolicy_topic4ansp2" : "Hadiah dan promosi yang kami tawarkan di WisePerks.com dapat disponsori oleh perusahaan lain secara mandiri, atau dapat disponsori bersama oleh WisePerks.com dan perusahaan lain.Beberapa data yang dikumpulkan selama promosi tersebut dapat dibagikan dengan sponsor.Namun, Anda akan diberitahu sebelum waktu pengumpulan atau transfer data.Anda dapat memutuskan untuk tidak berpartisipasi dalam promosi tertentu jika Anda tidak ingin membagikan data Anda.",
  "privacypolicy_topic4ansp3" : "Informasi Sensitif tertentu (seperti informasi yang menentukan kondisi medis atau kesehatan, asal ras atau etnis, pandangan politik, keyakinan agama atau spiritual, perdagangan dan keanggotaan serikat atau informasi yang menentukan kehidupan seks individu) akan diperlakukan dengan hati-hati.Jika WisePerks.com bermaksud untuk membagikan informasi tersebut ke perusahaan lain untuk tujuan yang berbeda dari yang semula dikumpulkan, maka Anda akan diminta secara tegas meminta izin sebelum pengungkapan tersebut.",

  "privacypolicy_topic5" : "Dengan siapa WisePerks.com membagikan informasi saya?",
  "privacypolicy_topic5ansp1" : "Kami menjaga privasi dengan sangat serius.WisePerks.com tidak akan mengungkapkan informasi apa pun yang dapat diidentifikasi secara pribadi kecuali jika kami memiliki izin eksplisit Anda atau dalam keadaan khusus apa pun, bahwa undang-undang mewajibkannya atau dalam situasi yang dirinci secara terperinci di bawah ini.Berikut ini menjelaskan beberapa cara agar informasi Anda dapat diungkapkan.Silakan lihat Ketentuan Layanan untuk layanan kami untuk informasi lebih rinci tentang bagaimana informasi pribadi Anda dapat dibagikan.",
  "privacypolicy_topic5ansp2" : "Mitra Bisnis, Klien & Sponsor:",
  "privacypolicy_topic5ansp3" : "WisePerks.com dapat mengungkapkan informasi pribadi Anda kepada mitra, klien, atau sponsor kami.Namun, jika ada data pribadi tentang Anda yang pernah dibagikan, kami akan memberi tahu Anda sebelum kami mengumpulkan atau mentransfer data.Banyak kegiatan yang kami tawarkan meminta informasi tambahan dari sponsor.Dengan Anda meminta informasi lebih lanjut, Anda memberi izin WisePerks.com untuk membagikan informasi Anda dengan sponsor sehingga mereka dapat memenuhi permintaan Anda.Dalam banyak contoh, hanya alamat email Anda yang akan dibagikan.Jika informasi lebih lanjut akan dibagikan dengan mitra kami, Anda akan diberitahu terlebih dahulu.Dari waktu ke waktu, Anda dapat ditawari kesempatan untuk menerima bahan ujian, atau produk lain atau penawaran khusus dari mitra kami.Jika Anda memilih untuk menerima informasi dari pihak ketiga ini, WisePerks.com akan (dengan izin Anda) membagikan nama dan alamat email Anda kepada mereka.",
  "privacypolicy_topic5ansp4" : "WisePerks.com juga dapat mengungkapkan informasi pribadi dalam beberapa kasus khusus ketika mengungkapkan informasi ini diperlukan untuk mengidentifikasi, menghubungi atau membawa tindakan hukum terhadap seseorang yang mungkin melanggar kebijakan dan Ketentuan Layanan WisePerks.com atau mungkin menyebabkan cedera atau interferensi dengan (baik sengaja atau tidak sengaja) produk, layanan, hak atau properti WisePerks.com, anggota dan pengguna WisePerks.com lainnya, atau siapa pun yang dapat terpengaruh oleh aktivitas semacam itu.WisePerks.com dapat mengungkapkan atau mengakses informasi akun ketika kami percaya dengan itikad baik bahwa undang-undang mensyaratkan hal itu dan untuk tujuan administratif dan lainnya yang kami anggap perlu untuk mempertahankan, melayani, dan meningkatkan produk dan layanan kami.",

  "privacypolicy_topic6" : "Apa landasan hukum yang kami gunakan untuk memproses informasi Anda?",
  "privacypolicy_topic6ans" : 'WiseWorks berkomitmen untuk privasi dan perlindungan data.Kebijakan privasi kami mengadopsi prinsip-prinsip Peraturan Perlindungan Data Umum yang dikeluarkan oleh Uni Eropa ("GDPR") sebagai patokan yang kami patuhi, karyawan dan pemasok kami.',

  "privacypolicy_topic7" : "Apa saja pilihan saya tentang pengumpulan, penggunaan, dan distribusi informasi saya?",
  "privacypolicy_topic7ans" : "Hanya WisePerks.com (atau mitra yang bekerja dengan WisePerks.com di bawah perjanjian kerahasiaan) yang akan mengirimi Anda email langsung, hanya jika Anda menyatakan bahwa Anda tidak keberatan dengan ini.Anda juga memiliki pilihan sehubungan dengan cara kerja cookie.Dengan mengubah pengaturan browser internet Anda, Anda memiliki pilihan untuk menerima semua cookie, diberitahu ketika cookie diatur, atau untuk menolak semua permintaan cookie.Jika Anda memilih untuk menolak semua cookie, Anda tidak akan dapat menggunakan layanan WisePerks.com yang memang mengharuskan pendaftaran sebelumnya untuk berpartisipasi.WisePerks.com tidak menjual atau menyewakan informasi pengguna kepada siapa pun.Kami akan selalu memberi tahu Anda pada saat pengumpulan data atau transfer jika data Anda akan dibagikan dengan pihak ketiga mana pun dan Anda akan selalu memiliki opsi untuk tidak mengizinkannya dibagikan.",

  "privacypolicy_topic8" : "Tindakan pencegahan keamanan apa yang ada di WisePerks.com untuk melindungi kehilangan, penyalahgunaan, atau pengubahan informasi akun saya?",
  "privacypolicy_topic8ansp1" : "Informasi Akun WisePerks.com Anda dilindungi kata sandi sehingga Anda dan hanya Anda yang memiliki akses ke informasi ini.",
  "privacypolicy_topic8ansp2" : "Kami sangat menyarankan Anda tidak membagi kata sandi Anda dengan siapa pun.Kami tidak akan pernah meminta kata sandi Anda dalam panggilan telepon atau email yang tidak diminta.Silakan keluar dari akun WisePerks.com Anda dan tutup jendela browser Anda ketika Anda telah menyelesaikan aktivitas Anda.Ini memastikan bahwa orang lain tidak dapat mengakses informasi pribadi dan akun Anda, jika Anda menggunakan komputer publik atau berbagi komputer Anda dengan orang lain.Setiap kali WisePerks.com menangani informasi pribadi seperti yang dijelaskan di atas, di mana pun hal ini terjadi, WisePerks.com mengambil langkah-langkah untuk memastikan bahwa informasi Anda diperlakukan dengan aman dan sesuai dengan Persyaratan Layanan yang relevan dan Kebijakan Privasi ini.Sayangnya, tidak ada transmisi data melalui Internet yang dapat dijamin 100% aman.Sebagai hasilnya, sementara kami berusaha untuk melindungi informasi pribadi Anda, WisePerks.com tidak dapat memastikan atau menjamin keamanan informasi yang Anda kirimkan kepada kami atau dari produk atau layanan online kami, dan Anda melakukannya dengan risiko Anda sendiri.Setelah kami menerima data Anda, kami melakukan upaya terbaik untuk memastikan keamanannya pada sistem kami.",

  "privacypolicy_topic9" : "Apa lagi yang harus saya ketahui tentang privasi saya?",
  "privacypolicy_topic9ans" : "Harap diingat bahwa kapan pun (dan di mana saja) Anda memilih untuk mengungkapkan informasi pribadi secara online - misalnya melalui email, situs web jejaring sosial, blog, atau di area obrolan - informasi itu dapat dikumpulkan dan digunakan oleh pengguna lain.Jika Anda memposting informasi pribadi secara online yang dapat diakses oleh publik, Anda dapat menerima pesan yang tidak diminta dari pihak lain sebagai imbalannya.Pada akhirnya, Anda sepenuhnya bertanggung jawab untuk menjaga kerahasiaan kata sandi dan / atau informasi akun Anda.Harap berhati-hati dan bertanggung jawab setiap kali Anda online.",

  "privacypolicy_topic10" : "Bagaimana cara WisePerks.com melindungi privasi anak-anak saya?",
  "privacypolicy_topic10ans" : "WisePerks.com memperoleh persetujuan orang tua sebelumnya di mana survei mensyaratkan anak-anak untuk berpartisipasi dan pengawasan orang tua juga diperlukan untuk survei semacam itu dengan anak-anak berusia 6-15 tahun.Kami melakukan perawatan ekstra khusus untuk melindungi keamanan dan privasi pengguna muda di situs kami, dan kami mendorong Anda untuk berpartisipasi dalam pengalaman online anak Anda.Kami tidak mengizinkan pemasaran atau penelitian anak-anak dan topik tertentu dilarang sama sekali dalam penelitian di antara anak-anak: Nama / nama akun / E-mail atau alamat fisik, Nomor telepon, Ras atau Etnis, Agama atau Cast, Pendapatan rumah tangga, penyakit keluarga , Status perkawinan orang tua atau anggota keluarga lainnya, Hubungan penting, Nilai di sekolah atau perguruan tinggi, Alkohol, Masalah Seksual, Tembakau, dan Senjata Api.",

  "privacypolicy_topic11" : "Catatan Khusus untuk Pengguna Internasional",
  "privacypolicy_topic11ansp1" : "Situs ini diselenggarakan di Amerika Serikat.Jika Anda adalah Pengguna yang mengakses Situs dari Uni Eropa, Asia, atau wilayah lain dengan undang-undang atau peraturan yang mengatur pengumpulan, penggunaan dan pengungkapan data pribadi, yang berbeda dari hukum Amerika Serikat, harap dicatat bahwa Anda mentransfer data pribadi Anda ke Amerika Serikat yang tidak memiliki undang-undang perlindungan data yang sama dengan UE dan wilayah lain, dan dengan memberikan data pribadi Anda, Anda menyetujui:",
  "privacypolicy_topic11ansp2" : "Penggunaan data pribadi Anda untuk penggunaan yang diidentifikasi di atas sesuai dengan Kebijakan Privasi; dan transfer data pribadi Anda ke Amerika Serikat seperti yang ditunjukkan di atas.ANDA MENGAKUI BAHWA HUKUM DARI AMERIKA SERIKAT MEMPERLAKUKAN INFORMASI ANDA DALAM SEBAGIAN BESAR YANG MUNGKIN BERBEDA, DAN KURANG MELINDUNGI DARI, PERAWATAN YANG DIPERLUKAN DI BAWAH HUKUM NEGARA-NEGARA LAIN DAN YURISDIKSI.JIKA ANDA TIDAK INGIN INFORMASI ANDA TRANSFERRED KE AMERIKA SERIKAT, ANDA TIDAK HARUS BERBAGI INFORMASI ANDA DENGAN AS, ATAU MEMBUAT PENGGUNAAN SITUS.SEPANJANG DIPERBOLEHKAN OLEH HUKUM NEGARA YANG ANDA TERLETAK, ANDA SECARA TEGAS MENYELESAIKAN HAK-HAK APA PUN ANDA HARUS MEMERLUKAN KAMI UNTUK MEMPERLAKUKAN IDENTIFIKASI INFORMASI ANDA SESUAI DENGAN HUKUM DARI NEGARA ATAU YURISDIKSI LAINNYA DARI AMERIKA SERIKAT.NAMUN, PENGHAPUSAN HUKUM INI TIDAK DAPAT MENGIKAT SECARA HUKUM DI BEBERAPA NEGARA, SEPERTI NEGARA ANGGOTA UNI EROPA.SEJAUH INI TIDAK MENGIKAT SECARA HUKUM DI NEGARA YANG ANDA TERLETAK, PENGIRIMAN HUKUM INI TIDAK BERLAKU BAGI ANDA.",

  "privacypolicy_topic12" : "Perubahan dan Pembaruan pada Kebijakan Privasi ini",
  "privacypolicy_topic12ans" : "WiseWorks berhak, atas kebijaksanaan kami, untuk mengubah, memodifikasi, menambah, atau menghapus bagian dari Kebijakan Privasi ini setiap saat karena alasan apa pun.Setiap kali WiseWorks menjalankan hak tersebut, Anda harus dengan tegas menerima Kebijakan Privasi yang diperbarui, dan jika Anda gagal melakukannya, Anda tidak akan lagi dapat mengakses atau menggunakan Situs.",

  "privacypolicy_topic13": "Catatan Khusus untuk mereka yang mencari detail GDPR / CCPA",
  "privacypolicy_topic13ansp1": "Silahkan",
  "privacypolicy_topic13ansp2": "Klik disini",
  "privacypolicy_topic13ansp3": "untuk mempelajari lebih lanjut tentang detail kepatuhan GDPR (Peraturan Perlindungan Data Umum) dan CCPA (Undang-Undang Privasi Konsumen California).",


  "privacypolicy_addinfo" : "Informasi tambahan",
    "privacypolicy_sentto": "Pertanyaan lain tentang ini harus dikirim ke",



    "privacypolicyext_para1": "BACA PERNYATAAN PRIVASI INI DENGAN TELITI",
    "privacypolicyext_para2": "WiseWorks menghormati privasi setiap pengguna, dan kami berkomitmen untuk bersikap transparan dengan Anda tentang cara kami menangani data pribadi. Kebijakan Privasi ini menjelaskan bagaimana WiseWorks mengumpulkan, membagikan, dan menggunakan data pribadi yang Anda berikan kepada kami saat menggunakan Situs Web WiseWorks atau berinteraksi dengan kami. Kami juga menawarkan beberapa bagian bagi Anda untuk mempelajari bagaimana Anda dapat menggunakan hak dan pilihan privasi Anda.",
    "privacypolicyext_para3": "Harap baca Kebijakan Privasi ini dengan cermat sebelum mengakses Situs Web kami dan menggunakan Layanan WiseWorks. Jika Anda memiliki pertanyaan, harap hubungi kami di ",

    "privacypolicyext_topic1": "Gambaran",
    "privacypolicyext_topic2": "Penerimaan dan Penggunaan",
    "privacypolicyext_topic3": "Who We Are",
    "privacypolicyext_topic4": "Ringkasan Informasi dan Penggunaan Pribadi",
    "privacypolicyext_topic5": "Tujuan Pengumpulan Data",
    "privacypolicyext_topic6": "Prinsip Perlindungan Data",
    "privacypolicyext_topic7": "Jenis Data Yang Kami Kumpulkan Dari Anda",
    "privacypolicyext_topic8": "Cookies dan web beacon",
    "privacypolicyext_topic9": "Memberitahu Anda Tentang Survei dan Proyek Penelitian",
    "privacypolicyext_topic10": "Bagaimana Kami Menggunakan Data Anda",
    "privacypolicyext_topic11": "Dengan Siapa Kami Berbagi Data Anda",
    "privacypolicyext_topic12": "EEA Data Subjects and Non- EEASubjek Data EEA dan Non-EEA",
    "privacypolicyext_topic13": "Hak Perlindungan Data Anda",
    "privacypolicyext_topic14": "Kebijakan Kami Terhadap Anak-anak",
    "privacypolicyext_topic15": "Keamanan data",
    "privacypolicyext_topic16": "Syarat dan ketentuan",
    "privacypolicyext_topic17": "Yurisdiksi",
    "privacypolicyext_topic18": "Perubahan pada Kebijakan Privasi kami",
    "privacypolicyext_topic19": "Hubungi kami",

    "privacypolicyext_t1": "kami",
    "privacypolicyext_t2": "kita",
    "privacypolicyext_t3": "atau",
    "privacypolicyext_t4": "kami punya",

    "privacypolicyext_topic1ansp1": " menghormati hak privasi penggunanya dan memahami pentingnya melindungi dan menangani informasi yang dikumpulkan tentang Anda berdasarkan hukum dan praktik terbaik. Pemberitahuan Privasi ini ditulis untuk dipatuhi",
    "privacypolicyext_topic1ansp1_1": "Pasal 13 dan Pasal 14 Peraturan Perlindungan Data Umum UE, yaitu, Peraturan (UE) 2016/679 ('GDPR'), dan Undang-Undang Privasi Konsumen California CCPA.",
    "privacypolicyext_topic1ansp2": "Pemberitahuan Privasi ini berlaku untuk informasi pribadi yang kami proses terkait dengan orang-orang yang memiliki:",
    "privacypolicyext_topic1ansp2_l1": "Daftarkan akun dengan kami;",
    "privacypolicyext_topic1ansp2_l2": "setuju untuk menerima informasi pemasaran dari kami;",
    "privacypolicyext_topic1ansp2_l3": "berpartisipasi dalam survei;",
    "privacypolicyext_topic1ansp2_l4": "hubungi kami untuk pertanyaan dan dukungan",
    "privacypolicyext_topic1ansp3": 'Kebijakan Privasi ini juga menjelaskan hak apa yang Anda miliki terkait data Anda, bagaimana Anda memberikan persetujuan Anda, dan bagaimana Anda dapat menarik persetujuan Anda. WiseWorks (bersama dengan afiliasinya, "Perusahaan") memiliki dan mengoperasikan beberapa situs web, aplikasi seluler, dan layanan interaktif yang berbeda, termasuk namun tidak terbatas pada Wisesample.com, Flapbucks.com, WisePerks.com, dan lainnya (secara kolektif, "Situs WiseWorks "). Kebijakan privasi ini ("Kebijakan Privasi") berlaku untuk Situs WiseWorks dan semua fitur, aplikasi seluler, email, layanan online, dan fungsi lainnya, baik diakses melalui komputer, perangkat seluler, atau lainnya, dan layanan yang tersedia di atau melalui Situs dan Fitur WiseWorks ("Layanan"). Kebijakan Privasi ini hanya mencakup informasi yang dikumpulkan oleh Perusahaan melalui Situs dan Fitur WiseWorks dan Layanan kami, dan tidak mencakup informasi apa pun yang diterima atau dikumpulkan secara offline oleh Perusahaan (kecuali dinyatakan secara khusus) atau dikumpulkan oleh situs web atau perusahaan yang tidak terkait yang kami dapat berikan tautan.',

    "privacypolicyext_topic2ansp1_1": "Kebijakan Privasi ini mencakup ",
    "privacypolicyext_topic2ansp1_2": " dan anak perusahaannya, termasuk",
    "privacypolicyext_topic2ansp1_3": "secara kolektif ",
    "privacypolicyext_topic2ansp1_4": "Dengan menggunakan situs web kami, Anda menandakan penerimaan terhadap ketentuan Kebijakan Privasi ini, yang dianggap sebagai persetujuan resmi Anda untuk memproses informasi pribadi Anda, persetujuan Anda untuk pengumpulan, penggunaan, dan pengungkapan informasi pribadi Anda sebagaimana dijelaskan lebih lanjut di bawah ini. Kami dapat meminta Anda untuk memberikan pengungkapan atau informasi tambahan tentang praktik pemrosesan data dari Layanan tertentu. Pemberitahuan ini dapat melengkapi atau memperjelas praktik privasi kami atau memberi Anda pilihan lain tentang cara kami memproses data Anda.",
    "privacypolicyext_topic2ansp2": "Jika Anda merasa tidak nyaman dengan aspek apa pun dari Kebijakan Privasi ini. Anda harus segera menghentikan akses apa pun ke situs WiseWorks dan situs web kami.",

    "privacypolicyext_topic3ansp1_1": " adalah perusahaan yang didirikan dan terdaftar di Canada (# 10369675). Kantor terdaftar kami ada di ",
    "privacypolicyext_topic3ansp1_2": "Kami menyediakan layanan panel, untuk industri riset pasar online. Kami memungkinkan pengguna atau peserta yang memenuhi persyaratan kami untuk mendaftar ke akun panel pengguna dan mengikuti survei untuk menerima poin atau insentif lainnya.",
    "privacypolicyext_topic3ansp2": "Layanan kami tersedia di beberapa negara, serta di California (AS), Kanada, Jerman, India, dll. Layanan tidak dimaksudkan untuk digunakan oleh Pengguna di bawah usia 18 tahun; Anda harus berusia 18 tahun ke atas untuk menggunakan situs web dan layanan kami.",

    "privacypolicyext_topic4ansp1": "Kami dapat mengumpulkan atau memperoleh Data Pribadi tentang Anda, dan informasi ini dapat disimpan atau diproses di Wilayah Amerika Serikat melalui metode berikut:",
    "privacypolicyext_topic4ansp1_l1": "Langsung dari Anda (mis., Di mana Anda menghubungi kami); selama hubungan kami dengan Anda (misalnya, jika Anda menggunakan layanan kami);",
    "privacypolicyext_topic4ansp1_l2": "saat Anda mengunjungi situs web kami; atau menggunakan bagian mana pun dari situs web; ",
    "privacypolicyext_topic4ansp2": "Kami juga dapat menerima Informasi Pribadi tentang Anda dari pihak ketiga.",

    "privacypolicyext_topic5ansp1": "Berikut adalah contoh bagaimana kami menggunakan Informasi Pribadi Anda:",
    "privacypolicyext_topic5ansp1_l1": "Penyediaan Layanan",
    "privacypolicyext_topic5ansp1_l2": "Menawarkan dan Meningkatkan situs web",
    "privacypolicyext_topic5ansp1_l3": "Survei",
    "privacypolicyext_topic5ansp1_l4": "Komunikasi",
    "privacypolicyext_topic5ansp1_l5": "Keterlibatan Pengguna",
    "privacypolicyext_topic5ansp1_l6": "Pemasaran",
    "privacypolicyext_topic5ansp1_l7": "Generasi pemimpin",
    "privacypolicyext_topic5ansp1_l8": "Kepatuhan Hukum",

    "privacypolicyext_topic6ansp1": "Kami mengumpulkan, memproses, dan menggunakan data pribadi (di bawah definisi Pasal 13 dan Pasal 14 Peraturan Perlindungan Data Umum UE, yaitu, Peraturan (UE) 2016/679 ('GDPR'), dan Undang-Undang Privasi Konsumen California CCPA) untuk menyediakan layanan kami. Data pribadi berarti setiap informasi yang memungkinkan kami untuk mengidentifikasi Anda, secara langsung atau tidak langsung, seperti nama, email, alamat, informasi kesehatan, segala bentuk nomor identifikasi, atau satu atau lebih faktor yang spesifik untuk Anda atau identitas penerima Anda.",
    "privacypolicyext_topic6ansp2": "Kami berkomitmen untuk mematuhi undang-undang perlindungan data yang berlaku dan akan memastikan bahwa data pribadi:",
    "privacypolicyext_topic6ansp2_l1": "Digunakan secara sah, adil, dan transparan;",
    "privacypolicyext_topic6ansp2_l2": "Dikumpulkan hanya untuk tujuan valid yang telah kami jelaskan dengan jelas kepada Anda dan tidak digunakan dengan cara apa pun yang tidak sesuai dengan tujuan tersebut;",
    "privacypolicyext_topic6ansp2_l3": "Relevan dengan tujuan yang telah kami beri tahukan kepada Anda dan terbatas hanya pada tujuan tersebut; ",
    "privacypolicyext_topic6ansp2_l4": "Akurat dan selalu diperbarui;",
    "privacypolicyext_topic6ansp2_l5": "Disimpan hanya selama diperlukan untuk tujuan yang telah kami ceritakan kepada Anda;",

    "privacypolicyext_topic7ansp1": "Kami dapat mengumpulkan dan memproses informasi pribadi berikut tentang Anda:",
    "privacypolicyext_topic7ansp1_l1title": "Informasi Profil Pribadi.",
    "privacypolicyext_topic7ansp1_l1": "Jika Anda mendaftarkan akun pada kami, kami akan mengumpulkan nama depan dan belakang Anda, alamat email Anda, dan kata sandi yang Anda pilih.",
    "privacypolicyext_topic7ansp1_l2title": "Kontak informasi.",
    "privacypolicyext_topic7ansp1_l2": "Jika Anda memilih untuk menerima survei melalui pesan teks atau media perpesanan lainnya, kami juga mengumpulkan nomor ponsel Anda atau detail kontak yang relevan.",
    "privacypolicyext_topic7ansp1_l3title": "Data Kuisioner.",
    "privacypolicyext_topic7ansp1_l3": "Kuesioner pembuatan profil awal mengumpulkan informasi tentang latar belakang, pendidikan, status kerja, kebiasaan konsumen, dan, secara opsional, kesehatan Anda. Kami mengumpulkan informasi tentang setiap pembaruan yang Anda lakukan pada informasi profil Anda.",
    "privacypolicyext_topic7ansp1_l4title": "Data Opini.",
    "privacypolicyext_topic7ansp1_l4": "Selama peluang penelitian, kami mengumpulkan informasi yang Anda berikan terkait dengan pendapat, sikap, pengalaman, dan penggunaan produk atau layanan tertentu. Terkadang kami dapat mengumpulkan kiriman video, audio, atau foto yang Anda buat selama kesempatan penelitian.",
    "privacypolicyext_topic7ansp1_l5title": "Data Pengalaman Pengguna.",
    "privacypolicyext_topic7ansp1_l5": "Kami mengumpulkan informasi tentang login Anda, komentar pada posting blog, peringkat, polling, halaman web yang dilihat.",
    "privacypolicyext_topic7ansp1_l6title": "Informasi Imbalan.",
    "privacypolicyext_topic7ansp1_l6": "Informasi pribadi apa pun yang mungkin kami perlukan untuk memberikan hadiah atau hadiah kepada Anda atau memantau penggunaan Anda atas aktivitas Panel dan Survei kami.",
    "privacypolicyext_topic7ansp1_l7title": "Data Korespondensi.",
    "privacypolicyext_topic7ansp1_l7": "Semua korespondensi yang Anda kirimkan kepada kami.",
    "privacypolicyext_topic7ansp1_l8title": "Data Penggunaan.",
    "privacypolicyext_topic7ansp1_l8": "Kami juga mengumpulkan data penggunaan tentang Anda setiap kali Anda berinteraksi dengan layanan kami. Ini mungkin termasuk halaman web mana yang Anda kunjungi, apa yang Anda klik ketika Anda melakukan tindakan tersebut, dan seterusnya. Selain itu, kami menyimpan file log digital yang merekam data setiap kali perangkat mengakses server kami. File log berisi data tentang sifat setiap akses. Kami mengumpulkan data dari perangkat dan aplikasi yang Anda gunakan untuk mengakses layanan kami, seperti alamat IP Anda, jenis browser, penyedia layanan internet (ISP), halaman rujukan / keluar, sistem operasi, cap tanggal / waktu, dan data clickstream.",
    "privacypolicyext_topic7ansp2": "Kami juga dapat menyimpulkan lokasi geografis Anda berdasarkan alamat IP Anda. Jika Anda tiba di layanan WiseWorks dari sumber eksternal (seperti tautan di situs web lain, yang terdapat dalam aplikasi email atau perpesanan), kami dapat menggunakan informasi ini, untuk menganalisis tren, mengelola situs, melacak pengguna, dan mengumpulkan informasi demografis tentang basis pengguna kami secara keseluruhan.",

    "privacypolicyext_topic8ansp1": "Cookie adalah file teks yang berisi sejumlah kecil informasi yang dikirim ke browser Anda ketika Anda mengunjungi sebuah situs web. Cookie kemudian dikirim kembali ke situs web asal pada setiap kunjungan berikutnya, atau ke situs web lain yang mengenalinya. teknologi yang sangat berguna dan melakukan banyak pekerjaan berbeda. Suar web adalah gambar grafik yang sering transparan, biasanya tidak lebih besar dari 1 - piksel x 1 piksel, yang ditempatkan di situs web atau di email yang digunakan untuk memantau perilaku pengguna mengunjungi situs web atau mengirim email. Ini sering kali digunakan dalam kombinasi dengan cookie.",
    "privacypolicyext_topic8ansp2": "Kami dapat mengumpulkan informasi melalui penggunaan cookie, web beacon, atau teknologi berbasis analitik serupa.",
    "privacypolicyext_topic8ansp3_title": "Cookie apa yang kami gunakan?",
    "privacypolicyext_topic8ansp3": "Cookie yang kami gunakan termasuk dalam kategori berikut:",
    "privacypolicyext_topic8ansp3_l1title": "Cookie yang sangat diperlukan.",
    "privacypolicyext_topic8ansp3_l1": "Ini adalah cookie yang diperlukan untuk pengoperasian situs web kami, termasuk, misalnya, cookie yang memungkinkan Anda masuk ke area aman di situs web kami.",
    "privacypolicyext_topic8ansp3_l2title": "Cookie analitis / kinerja.",
    "privacypolicyext_topic8ansp3_l2": "Mereka memungkinkan kami untuk mengenali dan menghitung jumlah pengunjung dan untuk melihat bagaimana pengunjung bergerak di sekitar situs web kami saat mereka menggunakannya. Ini membantu kami meningkatkan cara kerja situs web kami, misalnya, dengan memastikan bahwa pengguna menemukan apa yang mereka cari dengan mudah.",
    "privacypolicyext_topic8ansp3_l3title": "Cookie fungsionalitas.",
    "privacypolicyext_topic8ansp3_l3": "Ini digunakan untuk mengenali Anda ketika Anda kembali ke situs web kami. Ini memungkinkan kami mempersonalisasi konten kami untuk Anda, menyapa Anda dengan nama, dan mengingat preferensi Anda.",
    "privacypolicyext_topic8ansp3_l4title": "Menargetkan cookie.",
    "privacypolicyext_topic8ansp3_l4": "Cookie ini merekam kunjungan Anda ke situs web kami, halaman yang Anda kunjungi, dan tautan yang Anda ikuti. Kami akan menggunakan informasi ini untuk membuat situs web kami dan iklan yang ditampilkan lebih relevan dengan minat Anda. Kami juga dapat membagikan informasi ini dengan pihak ketiga untuk tujuan ini.",
    "privacypolicyext_topic8ansp4": "Kami menggunakan berbagai jenis cookie (termasuk beberapa yang kami tempatkan dan yang lain yang kami izinkan ditempatkan pihak ketiga di situs kami) untuk beberapa tujuan. Informasi yang dikumpulkan oleh cookie memungkinkan kami untuk memantau berapa banyak orang yang menggunakan layanan kami dan interaksi mereka dengan layanan lain, berapa banyak orang yang membuka email kami, dan untuk tujuan apa tindakan ini diambil. Cookie juga digunakan untuk memfasilitasi login pengguna, sebagai bantuan navigasi, dan sebagai pengatur waktu sesi, dan untuk membatasi penggunaan alat dan layanan kami di bawah umur. Kami tidak menghubungkan informasi non-pribadi dari cookie ke informasi pribadi tanpa izin. Jika karena alasan apa pun Anda tidak ingin kami melacak penggunaan Anda atas situs kami menggunakan cookie, Anda dapat memilih keluar dari ini menggunakan pengaturan browser Anda dan juga dapat memilih opsi &ldquo;Jangan Lacak&rdquo; (untuk informasi lebih lanjut silakan merujuk ke browser Anda ). Sebagian besar browser menawarkan instruksi tentang cara mengatur ulang browser untuk menolak cookie di bagian &ldquo;Bantuan&rdquo; pada toolbar.",
    "privacypolicyext_topic8ansp5": "Kami menggunakan suar web untuk mengumpulkan informasi tentang penggunaan layanan kami. Informasi yang dikumpulkan oleh web beacon memungkinkan kami untuk memantau berapa banyak orang yang menggunakan layanan kami, berapa banyak orang yang membuka email kami dan menentukan untuk tujuan apa tindakan ini diambil. Beacon web kami tidak digunakan untuk melacak aktivitas di luar layanan kami.",
    "privacypolicyext_topic8ansp6_title": "Bagaimana cara mengubah pengaturan cookie saya?",
    "privacypolicyext_topic8ansp6_1": "Sebagian besar browser web mengizinkan beberapa kontrol atas sebagian besar cookie melalui pengaturan browser. Untuk mengetahui lebih lanjut tentang cookie, termasuk cara melihat cookie apa yang telah disetel, kunjungi www.aboutcookies.org atau www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "Untuk mengetahui cara mengelola cookie di browser populer:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "Ketika Anda bergabung dengan WiseWorks, kami dapat berkomunikasi dengan Anda melalui email, telepon, atau layanan pesan (tergantung pada preferensi Anda) mengenai keanggotaan Anda. Kesempatan, ketika kami dapat berkomunikasi dengan Anda termasuk yang berikut ini:",
    "privacypolicyext_topic9ansp1_l1": "Peluang penelitian (seperti survei, jajak pendapat, kelompok fokus, dll)",
    "privacypolicyext_topic9ansp1_l2": "Hasil penelitian",
    "privacypolicyext_topic9ansp1_l3": "Buletin panelis",
    "privacypolicyext_topic9ansp2": "Proyek kami dapat berfokus pada berbagai topik. Kami biasanya akan mengundang Anda untuk berpartisipasi dalam proyek dengan mengirim email kepada Anda tentang peluang tersebut. Kami akan, pada saat itu, menjelaskan topik survei yang luas, kemungkinan durasinya atau keterlibatan yang diperlukan, dan hadiah yang ditawarkan. Terserah Anda untuk memutuskan apakah Anda ingin ambil bagian atau tidak. Partisipasi tidak pernah wajib. Anda selalu dapat keluar kapan saja meskipun kami lebih suka Anda menyelesaikan tugas setelah Anda memulainya dan kami biasanya tidak membayar insentif apa pun untuk tugas yang tidak selesai.",
    "privacypolicyext_topic9ansp3": "Kami mengharapkan Anda untuk, setiap saat, memberikan informasi yang benar, akurat, terkini, dan lengkap seperti yang diminta. ",


    "privacypolicyext_topic10ansp1": "Kami hanya akan menggunakan data pribadi Anda jika hukum mengizinkan kami untuk. Umumnya, kami akan menggunakan data pribadi Anda dalam keadaan berikut, tetapi untuk informasi lebih lanjut tentang jenis dasar hukum yang akan kami andalkan untuk memproses data Anda, silakan klik sini.:",
    "privacypolicyext_topic10ansp1_l1": "Menyediakan produk dan layanan kami untuk Anda",
    "privacypolicyext_topic10ansp1_l2": "temukan orang-orang yang paling cocok untuk setiap peluang penelitian;",
    "privacypolicyext_topic10ansp1_l3": "kurangi jumlah pertanyaan yang perlu kami tanyakan dalam survei;",
    "privacypolicyext_topic10ansp1_l4": "melakukan analisis lebih lanjut terhadap data penelitian yang telah kami kumpulkan;",
    "privacypolicyext_topic10ansp1_l5": "dalam bentuk agregat untuk membantu kami menjelaskan kemampuan penelitian kami kepada klien kami.",
    "privacypolicyext_topic10ansp1_l6": "mengundang Anda untuk meneliti peluang seperti survei, grup fokus, komunitas online, pemantauan pasif dari situs web yang Anda kunjungi, dll.",
    "privacypolicyext_topic10ansp1_l7": "mengirimi Anda imbalan insentif yang telah Anda peroleh,",
    "privacypolicyext_topic10ansp1_l8": "meminta Anda untuk memperbarui profil Anda,",
    "privacypolicyext_topic10ansp1_l9": "Mengundang Anda untuk berpartisipasi dalam survei tentang produk atau layanan kami",
    "privacypolicyext_topic10ansp1_l10": "Pantau, deteksi, dan cegah penipuan, aktivitas yang tidak pantas, dan pastikan kepatuhan kebijakan WiseWorks",
    "privacypolicyext_topic10ansp1_l11": "Melakukan penelitian termasuk penelitian sekunder dan penelitian lanjutan",
    "privacypolicyext_topic10ansp1_l12": "Evaluasi bagaimana Anda dan pengguna lain berinteraksi dengan layanan online kami",
    "privacypolicyext_topic10ansp1_l13": "Lindungi WiseWorks dan pengguna kami",
    "privacypolicyext_topic10ansp1_l14": "Lakukan audit terkait interaksi kita saat ini dengan Anda",
    "privacypolicyext_topic10ansp1_l15": "Debug situs dan layanan untuk mengidentifikasi dan memperbaiki kesalahan yang mengganggu fungsionalitas yang dimaksudkan",
    "privacypolicyext_topic10ansp1_l16": "Membela diri kita dalam litigasi dan investigasi serta menuntut litigasi",
    "privacypolicyext_topic10ansp1_l17": "Untuk mematuhi kewajiban hukum dan peraturan.",


    "privacypolicyext_topic11ansp1": "WiseWorks hanya membagikan informasi pribadi Anda dengan pihak ketiga dalam keadaan yang dijelaskan di bawah ini. Kami tidak menjual atau mengungkapkan informasi pribadi yang kami kumpulkan tentang Anda untuk pertimbangan moneter atau pertimbangan berharga lainnya.",
    "privacypolicyext_topic11ansp1_l1title": "Vendor resmi:",
    "privacypolicyext_topic11ansp1_l1": "Kami dapat membagikan data Anda dengan vendor pihak ketiga yang membantu kami mengoperasikan situs web kami, memproses pembayaran, dan memberikan layanan kami dan juga mematuhi instruksi Anda dan kewajiban kontrak kami. Ini termasuk pemroses pembayaran, jaringan pengiriman konten (CDN), berbasis cloud layanan hosting, layanan pemantauan, penyedia layanan email, vendor jaminan dan pengujian kualitas, vendor pencegahan penipuan dan penyalahgunaan, manajemen hubungan pelanggan (CRM), vendor",
    "privacypolicyext_topic11ansp1_l2title": "Mitra Periklanan dan Analytics:",
    "privacypolicyext_topic11ansp1_l2": "Kami dapat membagikan data penggunaan dengan pengiklan pihak ketiga dan penyedia analitik melalui cookie dan teknologi serupa lainnya. Pihak ketiga ini dapat mengumpulkan informasi yang dikirim oleh komputer, browser, atau perangkat seluler Anda sebagai tanggapan atas permintaan konten, seperti pengidentifikasi unik, alamat IP Anda, atau informasi lain tentang komputer atau perangkat Anda.",
    "privacypolicyext_topic11ansp1_l3title": "Mitra Bisnis:",
    "privacypolicyext_topic11ansp1_l3": "Terkadang kami bermitra dengan pihak ketiga untuk menawarkan acara, promosi, kontes, dan program lainnya kepada Anda. Untuk berpartisipasi dalam penawaran ini, Anda mungkin diminta untuk memberikan alamat email Anda dan informasi pribadi tambahan. Kami hanya akan membagikan alamat email Anda dan informasi pribadi lainnya dengan mitra bisnis jika Anda secara tegas mengizinkan kami untuk melakukannya.",
    "privacypolicyext_topic11ansp1_l4title": "Kewajiban hukum tertentu:",
    "privacypolicyext_topic11ansp1_l4": "WiseWorks mungkin diharuskan untuk mengungkapkan informasi pribadi jika diarahkan oleh pengadilan atau entitas pemerintah lainnya.Tanpa membatasi sebelumnya, kami berhak untuk mengungkapkan informasi tersebut di mana kami memiliki dasar itikad baik untuk percaya bahwa tindakan tersebut diperlukan untuk: mematuhi dengan hukum yang berlaku, peraturan, perintah pengadilan, permintaan pemerintah, dan lembaga penegak hukum, melindungi dan membela hak dan properti WiseWorks atau pihak ketiga, atau keamanan WiseWorks, pengguna kami, karyawan kami, atau orang lain, mencegah, mendeteksi, menyelidiki dan mengambil tindakan terhadap aktivitas kriminal, penipuan, dan penyalahgunaan atau penggunaan tidak sah dari Situs web kami dan untuk menegakkan Ketentuan Penggunaan kami atau perjanjian atau kebijakan lain, sejauh diizinkan oleh hukum, kami akan berusaha memberi Anda pemberitahuan sebelumnya sebelum mengungkapkan informasi Anda di menanggapi permintaan seperti itu.",
    "privacypolicyext_topic11ansp1_l5title": "Dengan Persetujuan Anda:",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks dapat mengirimkan Informasi Pribadi Anda ke pihak ketiga jika Anda setuju untuk melakukannya atau sesuai arahan Anda. Misalnya, ini mungkin terjadi saat kami menyelesaikan transaksi atas permintaan Anda.",
    "privacypolicyext_topic11ansp1_l6title": "Dengan Penyedia Layanan:",
    "privacypolicyext_topic11ansp1_l6": "Kami dapat membagikan informasi pribadi dengan vendor, konsultan, pemroses pembayaran, dan penyedia layanan lain yang memberi kami layanan khusus dan informasi proses unik atas nama kami. Layanan ini mungkin termasuk menyediakan dukungan pelanggan, melakukan analisis bisnis dan penjualan, mendukung fungsionalitas situs web kami, memfasilitasi pemrosesan pembayaran, mendukung kontes, survei, dan fitur lain yang ditawarkan di Situs Web kami. Penyedia layanan ini tidak diizinkan untuk menggunakan informasi pribadi Anda untuk tujuan lain apa pun. Penggunaan mereka atas data pribadi tersebut akan tunduk pada kerahasiaan dan langkah-langkah keamanan yang sesuai.",
    "privacypolicyext_topic11ansp1_l7title": "Dengan Afiliasi:",
    "privacypolicyext_topic11ansp1_l7": "Kami dapat membagikan informasi pribadi dengan beberapa mitra kami, afiliasi, dan perusahaan lain di bawah kendali dan kepemilikan bersama, tunduk pada ketentuan Kebijakan Privasi ini. Kami juga dapat membagikan informasi pribadi Anda sehubungan dengan transaksi perusahaan yang penting, seperti penjualan bisnis kami, divestasi, merger, konsolidasi, atau penjualan aset, jika terjadi kebangkrutan, atau persiapan untuk salah satu peristiwa ini. Entitas lain yang membeli kami atau menjadi bagian dari bisnis kami akan memiliki hak untuk terus menggunakan informasi pribadi, tetapi hanya dengan cara yang ditetapkan dalam Kebijakan Privasi ini kecuali Anda setuju sebaliknya.",

    "privacypolicyext_topic12ansp1_title": "Dasar Hukum untuk Memproses Informasi Anda:",
    "privacypolicyext_topic12ansp1": "Untuk individu yang berada di Wilayah Ekonomi Eropa, Inggris, atau Swiss (secara kolektif disebut &ldquo;Penduduk EEA&rdquo;), data yang dikumpulkan dari Anda dapat ditransfer atau diproses di Amerika Serikat (secara kolektif disebut &ldquo;Subjek Data&rdquo;). Saat data mereka dikumpulkan, kami mengandalkan dasar hukum untuk memproses informasi Anda. Kami biasanya hanya memproses data Anda di mana kami diminta, di mana pemrosesan diperlukan untuk memberikan layanan kami kepada Anda (atau untuk mengambil langkah-langkah atas permintaan Anda sebelum menandatangani kontrak dengan Anda). ",
    "privacypolicyext_topic12ansp2_title": "Shine the Light (Untuk Warga California) CCPA",
    "privacypolicyext_topic12ansp2_1": "Undang-undang &ldquo;Shine the Light&rdquo; California mengizinkan pelanggan di California untuk meminta detail spesifik tentang bagaimana jenis informasi tertentu mereka dibagikan dengan pihak ketiga dan, dalam beberapa kasus, afiliasi untuk tujuan pemasaran langsung pihak ketiga dan afiliasi tersebut. Berdasarkan hukum, bisnis harus memberikan informasi tertentu kepada pelanggan California berdasarkan permintaan atau mengizinkan pelanggan California untuk menyisih dari jenis berbagi ini.",
    "privacypolicyext_topic12ansp2_2": 'Untuk menjalankan permintaan Shine the Light, silakan hubungi kami.Anda harus mencantumkan pernyataan "Hak Privasi California Anda" di badan permintaan Anda, serta nama, alamat jalan, kota, negara bagian, dan kode pos. Harap berikan secukupnya informasi untuk menentukan apakah ini berlaku untuk Anda dalam isi permintaan Anda. Harap dicatat bahwa kami tidak akan menerima pertanyaan melalui telepon, email, dan kami tidak bertanggung jawab atas pemberitahuan yang tidak diberi label atau dikirim dengan benar atau yang tidak memiliki informasi lengkap.',
    "privacypolicyext_topic12ansp3_title": "Hak Privasi Nevada Anda",
    "privacypolicyext_topic12ansp3": 'Jika Anda adalah penduduk Nevada, Anda memiliki hak untuk menyisih dari penjualan Data Pribadi tertentu kepada pihak ketiga yang bermaksud untuk melisensikan atau menjual Data Pribadi tersebut. Anda dapat menggunakan hak ini dengan menghubungi kami di sini atau mengirim email kepada kami dengan subjek "Permintaan Jangan Jual Nevada" dan memberi kami nama dan alamat email yang terkait dengan akun Anda.',

    "privacypolicyext_topic13ansp1": "Kami memungkinkan Anda untuk membuat banyak pilihan tentang data Anda: Di bawah ini adalah hak perlindungan data berikut yang Anda miliki saat menggunakan layanan kami.",
    "privacypolicyext_topic13ansp1_l1": "Anda dapat memilih untuk tidak memberikan informasi tertentu kepada kami.",
    "privacypolicyext_topic13ansp1_l2": "Misalkan Anda ingin mengakses, memperbaiki, memperbarui atau meminta penghapusan informasi pribadi Anda. Jika demikian, Anda dapat melakukannya kapan saja dengan menghubungi kami melalui portal layanan pelanggan atau di informasi yang disediakan di bagian bawah Kebijakan Privasi ini.",
    "privacypolicyext_topic13ansp1_l3": "Jika Anda meminta akses ke data Anda, kami akan mengonfirmasi apakah kami menyimpan informasi Anda dan memberikan salinannya kepada Anda, menjelaskan bagaimana kami memprosesnya dan mengapa, berapa lama kami menyimpannya, dan hak-hak Anda terkait dengannya.",
    "privacypolicyext_topic13ansp1_l4": "Jika Anda meminta penghapusan informasi pribadi Anda, kami akan menghapusnya. Harap dicatat bahwa kami perlu menyimpan informasi apa pun yang kami perlukan untuk memenuhi kewajiban hukum kami atau menetapkan, menjalankan, atau membela klaim hukum.",
    "privacypolicyext_topic13ansp1_l5": "Anda dapat menolak pemrosesan informasi pribadi Anda, meminta kami membatasi pemrosesan informasi pribadi Anda, atau meminta portabilitas informasi pribadi Anda. Sekali lagi, Anda dapat menggunakan hak-hak ini dengan menghubungi kami melalui portal layanan pelanggan atau di informasi yang disediakan di bagian bawah Kebijakan Privasi ini.",
    "privacypolicyext_topic13ansp1_l6": 'Anda memiliki hak untuk memilih keluar dari komunikasi pemasaran email yang kami kirimkan kepada Anda setiap saat. Anda dapat menggunakan hak ini dengan mengklik tautan "berhenti berlangganan" atau "menyisih" di email pemasaran yang kami kirimkan kepada Anda.',
    "privacypolicyext_topic13ansp1_l7": "Memilih Keluar Dari Pesan dari Kami: Untuk memilih keluar dari pesan promosi kami di masa mendatang, Anda harus berhenti berlangganan di badan pesan promosi yang dikirimkan kepada Anda (ditemukan di bagian bawah email) atau mengirim permintaan berhenti berlangganan kepada kami. akan memproses permintaan Anda dalam waktu yang wajar setelah diterima.",
    "privacypolicyext_topic13ansp1_l8": "Demikian pula, jika kami telah mengumpulkan dan memproses informasi pribadi Anda dengan persetujuan Anda, maka Anda dapat menarik persetujuan Anda kapan saja. Menarik persetujuan Anda tidak akan memengaruhi keabsahan pemrosesan apa pun yang kami lakukan sebelum penarikan Anda, juga tidak akan memengaruhi pemrosesan Anda. informasi pribadi dilakukan dengan mengandalkan alasan pemrosesan yang sah selain persetujuan.",
    "privacypolicyext_topic13ansp1_l9": "Anda memiliki hak untuk mengajukan keluhan kepada otoritas perlindungan data tentang pengumpulan dan penggunaan informasi pribadi Anda oleh kami. Silakan hubungi kami untuk informasi lebih lanjut, dan kami akan mengirimkan Anda tautan atau alamat petugas perlindungan data kami.",
    "privacypolicyext_topic13ansp1_l10": "Cara termudah untuk mengirimkan permintaan Anda kepada kami adalah melalui email dukungan Pelanggan kami yang terdapat di bagian bawah Kebijakan Privasi ini. Kami menanggapi semua permintaan yang kami terima dari individu yang ingin menggunakan hak perlindungan data mereka berdasarkan undang-undang perlindungan data yang berlaku.",

    "privacypolicyext_topic14ansp1": "Situs web dan layanan tidak ditujukan untuk anak-anak di bawah usia tiga belas (18) tahun.WiseWorks mematuhi Undang-Undang Perlindungan Privasi Online Anak-Anak (COPPA). Kami tidak secara sengaja meminta untuk mengumpulkan informasi pribadi dari siapa pun yang berusia di bawah 18 tahun. Harap beri tahu kami jika Anda mengetahui ada individu di bawah usia 18 tahun yang menggunakan situs web kami untuk mencegah mereka mengakses Layanan kami.",

    "privacypolicyext_topic15ansp1": "Kami berkomitmen untuk menjaga keamanan data Anda, memiliki langkah-langkah untuk melindungi dari kehilangan, penyalahgunaan, dan perubahan data di bawah kendali kami. Kami menerapkan teknik modern dan aman untuk melindungi sistem kami dari gangguan oleh individu yang tidak berwenang, dan kami secara teratur meningkatkan keamanan kami saat metode yang lebih baik tersedia. Pusat data kami dan mitra kami menggunakan langkah-langkah keamanan fisik baru untuk mencegah akses tidak sah ke fasilitas.",
    "privacypolicyext_topic15ansp2": "Perlu diketahui bahwa tidak ada tindakan keamanan yang sempurna atau tidak dapat ditembus. Oleh karena itu, meskipun kami menggunakan praktik standar industri untuk melindungi privasi Anda, kami tidak dapat (dan tidak) menjamin keamanan mutlak data pribadi.",


    "privacypolicyext_topic16ansp1": "Untuk mempelajari lebih lanjut tentang syarat dan ketentuan kami, silakan kunjungi halaman Ketentuan Layanan kami, yang menetapkan penggunaan, penafian, dan batasan tanggung jawab yang mengatur penggunaan situs web kami.",

    "privacypolicyext_topic17ansp1": "Undang-undang Amerika Serikat mengatur kebijakan privasi dan persyaratan layanan ini serta layanan terkait lainnya.",

    "privacypolicyext_topic18ansp1": "WiseWorks terus berupaya untuk menerapkan teknologi dan proses baru untuk melindungi Anda dengan lebih baik, privasi Anda, dan penggunaan layanan WiseWorks. Akibatnya, perubahan pada kebijakan privasi ini dapat dilakukan oleh WiseWorks dari waktu ke waktu. Perubahan ini tidak akan mengakibatkan penurunan tindakan keamanan apa pun yang dirancang untuk melindungi Anda dari pengungkapan yang tidak sah.",

    "privacypolicyext_topic19ansp1": "Jika Anda memiliki pertanyaan tentang kebijakan privasi ini, silakan hubungi kami di",



  "faq_faq" : "Pertanyaan yang Sering Diajukan",
  "faq_getans" : "Dapatkan jawaban atas semua pertanyaan Anda.",

  "faq_ques1" : "Apa itu WisePerks?",
  "faq_ans1" : "Kami senang Anda bertanya.WisePerks adalah situs survei online.Banyak merek dan perusahaan mencari responden untuk memberikan pendapat mereka dan menerima imbalan sebagai imbalannya.WisePerks adalah salah satu platform di mana Anda dapat memberikan pendapat Anda dan mendapatkan imbalan untuk itu juga.",

  "faq_ques2" : "Apakah gratis untuk bergabung?",
  "faq_ans2" : "Ya, itu gratis untuk bergabung.Bahkan, jika seseorang meminta Anda biaya pendaftaran, kami ingin Anda melaporkannya kepada kami.",

  "faq_ques3" : "Bisakah saya merujuk teman-teman saya?",
  "faq_ans3" : "Ya, Anda dapat mengundang teman dan kolega Anda untuk bergabung dan juga mendapat hadiah untuk itu. Silakan klik tautan 'Rujuk Teman' di dasbor Anda untuk mendapatkan semua detail.",

  "faq_ques4" : "Apa yang saya hasilkan?",
  "faq_ans4" : "Sekarang dalam model baru kami, Anda dapat memperoleh poin dalam banyak cara lain daripada hanya dengan mengambil survei. Anda mendapatkan poin oleh: mendaftar dengan kami, dengan memverifikasi account email, untuk menyelesaikan profil Anda, tentu saja untuk menyelesaikan setiap survei dan mengejutkan Anda bahkan untuk survei didiskualifikasi. Jadi kita memiliki semua alasan untuk Anda mulai bukan?",

  "faq_ques5" : "Bagaimana cara saya menjadi anggota WisePerks.com?",
  "faq_ans5" : 'Buka WisePerks.com di browser Anda.Buat login keanggotaan dengan mengklik tab "Daftar".Cukup ikuti langkah-langkah dan itu akan memandu Anda melalui sisa proses.Anda harus mengkonfirmasi maksud Anda bergabung dengan mengklik email konfirmasi yang dikirim oleh kami ke email Anda yang disediakan.',

  "faq_ques6" : "Di mana email konfirmasi yang Anda sebutkan?",
  "faq_ans6p1" : "E-mail konfirmasi otomatis dikirimkan ke alamat email yang Anda berikan saat mendaftar. Jika Anda tidak melihat email di kotak masuk Anda, silakan cek Anda folder spam atau junk. Untuk menghindari masa depan email dari kami dan survei mengundang/pesan dari mencapai folder spam Anda, silakan tambahkan",
  "faq_ans6p2" : "ke daftar pengirim Anda disetujui atau sebagai kontak di kotak pesan. Jika Anda masih tidak dapat menemukan email konfirmasi tersebut, silakan login ke Account saya, mengkonfirmasi alamat email Anda dengan kami benar, dan meminta email konfirmasi lagi.",

  "faq_ques7" : "Bagaimana cara saya melakukan survei?",
  "faq_ans7" : 'Setelah Anda masuk setelah memasukkan UserName dan Password Anda, Anda mencapai Dashboard Anda dan dapat melihat semua survei yang dapat Anda ambil. Kami juga akan mengirim link survei ke id email terdaftar Anda. Anda dapat mengambil link langsung dari kotak pesan.',

  "faq_ques8" : "Seberapa rahasia tanggapan saya, data saya?",
  "faq_ans8" : "Untuk WisePerks, menjaga privasi dan kerahasiaan pengguna adalah prioritas utama.Klien kami hanya tertarik pada apa yang Anda pikirkan, sebagai konsumen, atau bagaimana perasaan Anda tentang sesuatu.Kami di WisePerks, tidak pernah membagikan / menyampaikan informasi pribadi kepada klien kami, dan tak satu pun dari survei WisePerks juga tidak menjual atau menyewakan informasi apa pun kepada pihak ketiga.",

  "faq_ques9" : "Berapa lama survei ini?",
  "faq_ans9" : "Sebagian besar survei hanya memakan waktu sekitar 10 hingga 15 menit, atau bahkan lebih sedikit.Namun, dari waktu ke waktu, kami mungkin memiliki survei yang lebih lama.Dengan setiap survei, kami memberi tahu Anda sebelumnya berapa lama, tetapi jangan diingat, survei yang lebih panjang juga memiliki penghargaan yang lebih tinggi.",

  "faq_ques10" : "Adakah yang bisa mengambil survei WisePerks?",
  "faq_ans10" : "Anda harus berusia 13 tahun atau lebih untuk mendaftar dengan WisePerks.Meskipun terkadang kami akan mengundang Anda dalam survei untuk berpartisipasi dengan anggota keluarga yang lebih muda.",

  "faq_ques11" : "Bagaimana Anda tahu saya akan memenuhi syarat untuk survei?",
  "faq_ans11" : "Algoritme kami mencocokkan informasi profil Anda dengan survei yang tersedia untuk memastikan Anda mendapatkan survei yang paling tepat.Ketika survei dimulai, biasanya beberapa pertanyaan pertama menentukan apakah Anda adalah responden klien kami mencari survei tertentu.Kami memiliki sejumlah besar survei yang mencari berbagai jenis orang (mis., Orang-orang yang menonton film secara teratur, bermain olahraga tertentu, wanita dengan anak-anak, dll.) Dan survei baru muncul setiap hari!",

  "faq_ques12" : "Apa kewajiban saya kepada WisePerks?",
  "faq_ans12" : "Satu-satunya hal yang kami harapkan dari Anda adalah memberikan opini dan pendapat Anda secara jujur, karena klien mencari jawaban jujur ​​dan tulus Anda untuk memperbaiki produk dan layanan mereka.Harap berikan informasi yang benar ketika mendaftar sebagai anggota dan lakukan pembaruan setiap perubahan yang terjadi.",

  "faq_ques13" : "Mengapa saya harus memberikan informasi tentang rumah tangga, teknologi, atau produk yang saya beli?",
  "faq_ans13" : "Untuk setiap survei, kami mencari profil responden tertentu.Jika kami tahu dari informasi profil Anda bahwa informasi Anda sesuai dengan persyaratan klien, bingo!Kami hampir dapat memastikan bahwa Anda akan dapat menyelesaikan survei dengan sukses dan mendapatkan imbalan untuk itu.Ini pada dasarnya meningkatkan peluang Anda untuk dipilih untuk survei.",

  "faq_ques14" : "Kapan saya akan menerima Kartu Hadiah saya setelah menyelesaikan survei WisePerks?",
  "faq_ans14" : "Poin yang Anda peroleh setelah berhasil menyelesaikan survei yang dikumpulkan di ember. Setelah titik-titik ini menyadari Anda dapat menebus.",

  "faq_ques15" : "Apa itu Akun Saya sebenarnya?",
  "faq_ans15" : "Ini hanya cara mudah bagi Anda untuk melihat informasi pribadi Anda, membuat perubahan apa pun yang diperlukan, melihat aktivitas survei Anda, hadiah yang diperoleh, semuanya di satu tempat.",

  "faq_ques16" : "Bagaimana cara saya membatalkan akun saya?",
  "faq_ans16" : "Dengan begitu banyak datang dengan cara Anda, kami yakin Anda tidak ingin melakukan hal ini. Namun, pada Dashboard Anda--> pergi ke pengaturan--> mengelola account--> menonaktifkan Account",

  "faq_ques17" : "Bagaimana cara kerja sistem poin?",
  "faq_ans17p1" : "Setiap survei untuk jumlah poin tertentu. Setelah berhasil menyelesaikan survei, poin ini ditambahkan ke penghasilan Anda. Anda dapat menebusnya setelah mencapai ambang minimum.",
  "faq_ans17p2" : "Dalam beberapa kasus karena beberapa tantangan yang tidak terduga dengan koneksi atau kerusakan, respons Anda mungkin tidak ditangkap pada akhir klien kami. Kami kemudian harus membalikkan poin-poin itu dari akun Anda. Jika Anda sudah menebus poin-poin itu dan akun Anda memiliki saldo rendah maka penghasilan Anda akan negatif. Penyesuaian ini akan dilakukan dalam siklus penukaran berikutnya.",

  "faq_ques18" : "Seberapa cepat poin untuk survei ditambahkan ke saldo poin saya?",
  "faq_ans18" : "Poin Anda akan ditambahkan ke saldo poin Anda dalam 48 jam.",

  "faq_ques19" : "Berapa batas minimum untuk penebusan?",
  "faq_ans19" : "Ambang batas minimum untuk penebusan adalah 25000 poin.Rata-rata, Anda harus menyelesaikan 8-12 survei agar memenuhi syarat untuk ditukarkan.",

  "faq_ques20" : "Bagaimana poin dikonversi menjadi uang Kartu Hadiah?Apakah ada rasio?",
  "faq_ans20" : "Iya nih. Bergantung pada negara Anda, poin dikonversi menjadi uang Kartu Hadiah.Rasio konversi seperti ditunjukkan di bawah ini",
  "faq_ponits" : "Poin",
  "faq_point" : "Titik",
  "faq_cent" : "Sen",

  "faq_ques21" : "Masih ada lebih banyak pertanyaan?",
  "faq_ans21p1" : "Merasa bebas untuk email kami di",
  "faq_ans21p2" : "dan kami akan kembali kepada Anda dalam waktu 48 jam.",

  "Profiling_profiledashboard" : "Profil Dashboard", 
  "Profiling_completeprofile" : "Lengkapi profil Anda dan mendapatkan kekalahan",
  "Profiling_completepoints" : "poin!",
  "Profiling_answercarefully" : "Umum serangkaian pertanyaan akan digunakan untuk membuat profil pribadi Anda untuk memberikan Anda kesempatan masa depan survei terbaik. Silakan Ambil waktu Anda, menjawab dengan hati-hati dan jujur.",

  "Profiling_cat_general" : "Umum",
  "Profiling_cat_personal" : "Pribadi",
  "Profiling_cat_household" : "Rumah tangga",
  "Profiling_cat_profession" : "Profesi",

  "Profiling_bar_completed" : "% Selesai",

  "Profiling_update_ans_button" : "Perbarui & Lanjutkan",
  "Profiling_save_ans_button" : "Simpan", 
  "Profiling_sc_ans_button" : "Simpan & Lanjutkan", 

  "Category_thanku_msg1" : "Bagus going",
  "Category_thanku_msg2" : "beberapa lagi dan Anda akan memiliki",
  "Category_thanku_msg3" : "poin dalam account Anda.",

  "Category_thanku_update_msg1" : "Terima kasih untuk memperbarui informasi Anda!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "Lanjutkan",

  "OverAll_thanku_msg1" : "Terima kasih untuk berbagi informasi Anda. Anda telah diterima", 
  "OverAll_thanku_msg2" : "poin.",

  "Survey_history_surveyhistory" : "Survey sejarah",
  "Survey_history_fromthisscreen" : "Dari layar ini, Anda dapat melihat semua transaksi Anda.",
  "Survey_history_show" : "Tampilkan",
  "Survey_history_entries" : "entri",
  "Survey_history_date" : "Tanggal",
  "Survey_history_details" : "Rincian",
  "Survey_history_surveys" : "Survei",
  "Survey_history_pointsreceived" : "Poin yang diterima",
  "Survey_history_result" : "Hasil",
  "Survey_history_status" : "Status",
  "Survey_history_status_complete" : "Menyelesaikan",
  "Survey_history_status_disqualified" : "Didiskualifikasi", 
  "Survey_history_keyword" : "Kata kunci",
  "Survey_history_search" : "Cari",
  "Survey_history_to" : "untuk",
  "Survey_history_selectoption" : "Pilih opsi",
  "Survey_history_earned" : "Diterima",
  "Survey_history_redeemed" : "Ditebus",
  "Survey_history_norecord" : "Data tidak ditemukan",
  "Survey_history_Surveypts" : "Survei poin",
  "Survey_history_Promocode" : "Kode promo",

  "My_transactions_mytransactions" : "Transaksi saya",
  "My_transactions_fromthisscreen" : "Dari layar ini, Anda dapat melihat semua transaksi Anda.",
  "My_transactions_show" : "Tampilkan",
  "My_transactions_entries" : "entri",
  "My_transactions_transactionid" : "Id transaksi",
  "My_transactions_date" : "Tanggal",
  "My_transactions_details" : "Rincian",
  "My_transactions_points" : "Poin",
  "My_transactions_status" : "Status",
  "My_transactions_keyword" : "Kata kunci",
  "My_transactions_search" : "Cari",
  "My_transactions_to" : "untuk",
  "My_transactions_selectoption" : "Pilih opsi",
  "My_transactions_status_earned" : "Diterima",
  "My_transactions_status_redeemed" : "Ditebus",
  "My_transactions_status_pending" : "Tertunda",
  "My_transactions_status_reverted" : "Dikembalikan",
  "My_transactions_status_declined" : "Menurun",
  "My_transactions_norecord" : "Data tidak ditemukan",
  "My_transactions_off_msg1" : "Setelah ",
  "My_transactions_off_msg2" : ", semua detail transaksi Anda akan dipublikasikan pada tab Buku poin",

  "Passbook_heading" : "Buku Poin",
  "Passbook_show" : "Menunjukkan",
  "Passbook_entries" : "entri",
  "Passbook_search" : "Cari",
  "Passbook_type" : "Tipe",
  "Passbook_surveyid" : "Survei",
  "Passbook_date" : "Tanggal",
  "Passbook_selectoption" : "Pilih opsi",
  "Passbook_credit" : "Kredit",
  "Passbook_debit" : "Debet",
  "Passbook_survey_id" : "Id Survei",
  "Passbook_survey_pts" : "Poin Survei",
  "Passbook_earned_pts" : "Poin",
  "Passbook_pending_pts" : "Poin Tertunda",
  "Passbook_promo_code" : "Kode promosi",
  "Passbook_transaction_type" : "Tipe transaksi",
  "Passbook_balance" : "Keseimbangan",
  "Passbook_status" : "Status",
  "Passbook_type_soi" : "Registrasi",
  "Passbook_type_doi" : "Verifikasi email",
  "Passbook_type_profile" : "Penyelesaian Profil",
  "Passbook_type_survey" : "Survei",
  "Passbook_type_doir" : "Verifikasi Email Oleh",
  "Passbook_type_doirt" : "Verifikasi Email Dengan Rujukan",
  "Passbook_type_surveyr" : "Upaya Survei Oleh",
  "Passbook_type_surveyrt" : "Upaya Survei Dengan Rujukan",
  "Passbook_type_reward" : "Penghargaan",
  "Passbook_type_pending" : "Penyesuaian",
  "Passbook_type_reverted" : "Poin dikembalikan",
  "Passbook_type_declined" : "Permintaan Ditolak",
  "Passbook_type_redeem" : "Menebus",
  "Passbook_type_balance" : "Meneruskan",
  "Passbook_balance_vary" : "Saldo aktual dapat bervariasi",
  "Passbook_survey_debit_reason" : "Kami menghargai upaya Anda. Namun karena beberapa koneksi yang tidak terkendali terjatuh saat transfer data, respons Anda tidak dapat didaftarkan. Kami harus membalikkan poin yang Anda terima sebelumnya untuk survei ini. Permintaan maaf atas ketidaknyamanan yang ditimbulkan.",

  "Redeem_redeemed" : "Menebus",
  "Redeem_redeemhistory" : "Menebus sejarah",
  "Redeem_history_norecord" : "Data tidak ditemukan",
  "Redeem_date" : "Tanggal",
  "Redeem_details" : "Rincian",
  "Redeem_points" : "Poin",
  "Redeem_status" : "Status",
  "Redeem_lookslike" : "Sepertinya Anda merindukan memverifikasi akun email Anda.",
  "Redeem_hurry" : "Cepat! Anda mendapatkan 1000 poin setelah Anda memverifikasi.",
  "Redeem_pleasecheck" : "Silakan cek inbox Anda dan klik tautan dalam email konfirmasi dikirim untuk mengaktifkan akun Anda.",
  "Redeem_kindlycheck" : "Silakan periksa folder Spam/Junk juga untuk email konfirmasi.",
  "Redeem_getconfirmationemail" : "Tidak mendapatkan email konfirmasi?",
  "Redeem_resendconfirmationemail" : "Kirim ulang email konfirmasi",
  "Redeem_rewardredemptionrule" : "Hadiah penebusan aturan",
  "Redeem_completesurvey" : "Menyelesaikan survei",
  "Redeem_earnpoints" : "Mendapatkan poin",
  "Redeem_redeemrewards" : "Menebus hadiah",
  "Redeem_note" : "Catatan:",
  "Redeem_yourearnedpoints" : "Poin Anda akan menjadi redeemable setelah kualifikasi survei. Untuk menebus hadiah, minimum Point dapat ditukarkan di akun Anda harus",
  "Redeem__points" : "poin.",
  "Redeem_availablebalance" : "Saldo yang tersedia:",
  "Redeem_historydetails" : "Klik di sini untuk melihat detail riwayat penukaran Anda.",
  "Redeem_reqsucc" : "Selamat! Poin Anda telah diselamatkan. Silakan periksa kotak pesan terdaftar untuk rincian lebih lanjut.", 
  "Redeem_wisecheck_youhave" : "Selamat! Anda hanya satu klik saja dari hadiah Anda.",
  "Redeem_wisecheck_gcagainst" : "Kartu Hadiah untuk",
  "Redeem_wisecheck_points" : "poin.",
  "Redeem_wisecheck_issue" : "Sistem telah menangkap beberapa aktivitas yang tidak diakui dalam account Anda, karena yang Anda tidak mampu mengirim permintaan redeem. Silahkan hubungi di",
  "Redeem_emailverify_issue" : "Untuk menebus poin Anda, kami akan meminta Anda untuk memverifikasi akun email terdaftar Anda.",

  "Redeem_unsubscribe_issue" : "Anda tidak dapat mengirim permintaan tebusan, karena Anda telah berhenti berlangganan dari WisePerks. Silakan hubungi support@wiseperks.com untuk berlangganan lagi.",
  "Redeem_wisecheck_message1" : "Anda tidak dapat menebus karena beberapa alasan keamanan. Silakan hubungi untuk dukungan",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Konfirmasi",
  "Redeem_Cancel_button" : "Batal",
  "Redeem_pending_hello" : "Halo",
  "Redeem_pending" : "Pengaturan",
  "Redeem_pending_msg1" : "Akun Anda menunjukkan penyesuaian",
  "Redeem_pending_msg2" : "poin yang harus dilakukan. Harap konfirmasi untuk memulai transaksi.",

  "Dashboard_wisecheck_issue" : "Sistem telah menangkap beberapa aktivitas yang tidak dikenal di akun Anda, karena itu Anda tidak dapat melihat survei. Silakan hubungi support@wiseperks.com untuk penjelasan lebih lanjut.",
  "Dashboard_unsubscribe_issue" : "Anda tidak dapat melihat survei apa pun, karena Anda telah berhenti berlangganan dari WisePerks. Silakan hubungi support@wiseperks.com untuk menerima survei lagi.",


  "WC_DashboardMsg_1" : "Ups! Tidak ada survei untuk mencocokkan profil Anda seperti yang sekarang.",
  "WC_DashboardSubMsg_1" : "Jangan khawatir, kami mengocok inventaris kami dan akan segera mengirimkan survei baru kepada Anda.",

  "WC_DashboardMsg_2" : "Kami menyegarkan Dasbor Anda. Silakan periksa survei baru setelah beberapa waktu.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Terima kasih untuk mengisi survei. Anda hanya mendapat response_points poin. Login ke Dashboard Anda untuk melihat Anda total akumulasi poin.",
  "Survey_success_msg2" : "Terima kasih untuk mengisi survei. Anda hanya mendapat response_points poin.",

  "Survey_success_but_nomore_survey" : "Ucapan Selamat! Anda hanya mendapat response_points poin untuk mengisi survei. Periksa untuk mendatang survei setelah kadang-kadang.",
  
  "Survey_terminate_pnt_msg1" : "Sepertinya beberapa informasi Anda sedikit berbeda dari persyaratan. Kami menghargai waktu dan upaya Anda, akan menambahkan response_points poin ke akun Anda. Kita berfokus pada membawa Anda lebih menguntungkan dan intuitif survei.",
  
  "Survey_terminate_nopnt_msg1" : "Sepertinya beberapa respons Anda sedikit berbeda dengan persyaratan. Kami akan mengirimkan lebih banyak survei ke jalan Anda. Terima kasih untuk waktu dan usaha anda.",

  "TermsConditions_t&c" : "Syarat & Ketentuan",
  "TermsConditions_WhenRegister" : "Ketika Anda mendaftar",
  "TermsConditions_WhenYouCreate" : "Saat Anda membuat akun pribadi, kami menanyakan beberapa informasi pribadi, seperti yang disebutkan di sini:",
  "TermsConditions_YouCanUpdate" : "Anda dapat memperbarui atau mengubah informasi profil Dasar Anda kapan saja.",
  "TermsConditions_YouCanOptOut" : "Anda dapat memilih keluar atau menutup akun Anda kapan saja.",
  "TermsConditions_WeRespectYour" : "Kami menghormati privasi Anda dan memanfaatkan teknologi sebaik-baiknya untuk menjaga informasi Anda dari akses fisik dan elektronik yang tidak sah.",
  "TermsConditions_WeShareYour" : "Kami hanya membagikan informasi Anda dengan organisasi, yang diperlukan untuk mengoperasikan WiseWorks. Dan organisasi-organisasi tersebut perlu menjaga komitmen kami untuk privasi dan keamanan Anda.",
  "TermsConditions_WhatInformation" : "Informasi apa yang kami kumpulkan dari Anda",
  "TermsConditions_DirectlyCollected" : "Langsung dikumpulkan",
  "TermsConditions_EmailAddressAndPassword" : "Alamat email dan kata sandi akun Anda dengan kami.",
  "TermsConditions_YourResponse" : "Tanggapan Anda terhadap pertanyaan pembuatan profil.",
  "TermsConditions_AnyCustomer" : "Korespondensi layanan pelanggan apa pun.",
  "TermsConditions_AnyPersonalDetails" : "Setiap detail pribadi yang Anda bagikan untuk penebusan",
  "TermsConditions_AnyOtherInformation" : "Informasi lain yang disediakan langsung oleh Anda.",
  "TermsConditions_UsageData" : "Data penggunaan",
  "TermsConditions_YourIPAddress" : "Alamat IP Anda, lokasi geografis, dan cookie",
  "TermsConditions_InformationAboutTheDevice" : "Informasi tentang perangkat yang Anda gunakan",
  "TermsConditions_YourSurveyActivity" : "Aktivitas survei Anda, arahkan ke akun Anda, pembayaran yang Anda terima setelah ditukarkan.",
  "TermsConditions_HowDoWeUse" : "Bagaimana kami menggunakan data Anda",
  "TermsConditions_SendingYouInvitation" : "Mengirim email undangan kepada Anda untuk mengambil survei yang memenuhi syarat untuk Anda.",
  "TermsConditions_DetectAndPrevent" : "Deteksi dan cegah aktivitas curang.",
  "TermsConditions_ImproveYourExperience" : "Tingkatkan pengalaman Anda di platform WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Tanggapan Anda ditransfer ke pihak ketiga dan mitra afiliasi kami dalam format gabungan dan tidak teridentifikasi.",
  "TermsConditions_OurPartnersCanAsk" : "Mitra kami dapat meminta Anda untuk memberikan nama, alamat email, dan informasi kontak lainnya selama survei.",
  "TermsConditions_YouAreFreeToDecline" : "Anda bebas untuk menolak memberikan rincian Anda kepada mitra kami, jika Anda setuju daripada Anda memberikan informasi ini langsung kepada mereka dan tidak ke WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Informasi Anda akan ditangani sesuai dengan Kebijakan Privasi bisnis yang relevan dan bukan Kebijakan Privasi WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Mitra kami berkomitmen untuk menghormati dan melindungi privasi Anda.",
  "TermsConditions_WhatWeDont" : "Apa yang tidak kita lakukan dengannya",
  "TermsConditions_WeDontSellOnThat" : "Kami tidak menjual informasi itu kepada siapa pun, dan kami tidak menggunakannya untuk menjual apa pun kepada Anda. Kami akan selalu meminta izin sebelum membagikan data Anda.",
  "TermsConditions_YourRightToControl" : "Hak Anda untuk mengontrol data Anda",
  "TermsConditions_YouHaveFullControl" : "Anda memiliki kontrol penuh atas data Anda.",
  "TermsConditions_YouCanAccess" : "Anda dapat mengakses, memperbarui atau mengubah informasi profil dasar Anda kapan pun Anda mau.",
  "TermsConditions_YouCanUnsubscribe" : "Anda dapat berhenti berlangganan dan menonaktifkan akun Anda.",
  "TermsConditions_YouAreFreeToGetYour" : "Anda bebas untuk menghapus profil Anda dengan mengirimkan email kepada kami di support@wiseperks.com. Kami akan mencoba menyelesaikan masalah Anda dan jika Anda masih ingin menghapus akun Anda, maka Anda harus melakukannya dalam waktu 72 jam.",
  "TermsConditions_DataSecurity" : "Keamanan data",
  "TermsConditions_OurTechTeamWorks" : "Tim teknologi kami bekerja sangat keras untuk mengamankan semua data Anda.",
  "TermsConditions_WeMakeUseOfLatest" : "Kami menggunakan teknologi dan tindakan terbaru untuk menghindari penyalahgunaan atau akses tidak sah terhadap data Anda. Namun, karena sifat lingkungan online, kami tidak dapat memberikan jaminan keamanan data 100%.",
  "TermsConditions_WeTryOurBestToRule" : "Kami mencoba sebaik mungkin untuk mengesampingkan risiko, namun tidak dapat menjamin lingkungan bebas risiko. Dengan menerima Syarat dan Ketentuan ini, Anda mengakui dan menerima risiko ini.",
  "TermsConditions_AnyQuestions" : "Ada pertanyaan, jangan ragu untuk menghubungi kami",
  "TermsConditions_IfYouHaveAnyQueries" : "Jika Anda memiliki pertanyaan atau keluhan tentang kebijakan dan prosedur privasi kami. Silakan kirim email kepada kami di support@wiseperks.com dan kami akan menghubungi Anda kembali dalam waktu 48 jam.",

  "Validation_PleaseEnterProperDate" : "Harap Masukkan tanggal lahir yang tepat",
  "Validation_PleaseSelectOne" : "Silakan Pilih satu opsi!",
  "Validation_PleaseSelectValidOptions" : "Silakan Pilih Valid Options!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Silakan Pilih setidaknya Satu Pilihan",
  "Validation_PleaseInsertTheZip" : "Silakan Masukkan Kode Zip / Pos.",

 "Releases_releases" : "Rilis",
  "Releases_theUseOfThis" : "Penggunaan situs ini diatur oleh persyaratan penggunaan berikut.",
  "Releases_featuresOfWP" : "Fitur dari WP rilis",
  "Releases_updatedOn" : "diperbaharui pada",
  "Releases_WisePerksNow" : "WisePerks sekarang mendukung beberapa bahasa",
  "Releases_youCanWalkThourgh" : "Anda dapat berjalan-jalan menelusuri situs dengan lebih mudah sekarang sebagai WisePerks tersedia dalam bahasa Anda.",
  "Releases_languagesAvailable" : "Bahasa yang tersedia:",
  "Releases_EarnMore" : "Mendapatkan lebih banyak dengan sistem titik baru",
  "Releases_theNewWisePerksAccumulates" : "WisePerks baru akumulasi poin ke akun Anda. Titik-titik ini memegang banyak nilai yang lebih tinggi dan mudah untuk memperoleh dan menebus. Dengan beberapa menebus pilihan untuk memilih dari, kami yakin Anda akan menikmati survei mengambil pengalaman.",
  "Releases_accumulatePoints" : "Mengumpulkan poin dengan hassel gratis redeemption",
  "Releases_youGetTheBenefit" : "Anda mendapatkan manfaat dari penghasilan tidak sama. poin, terlepas dari mata uang yang Anda ingin untuk menebus mereka di.",
  "Releases_surveyDisqualification" : "Survei diskualifikasi adalah tidak lebih banyak berita buruk",
  "Releases_youEarnPoints" : "Anda mendapatkan poin bahkan ketika Anda tidak memenuhi syarat untuk survei, Anda mulai menjawab.",
  "Releases_newAndImproved" : "Layout baru dan peningkatan",
  "Releases_theFreshLookOf" : "Tampilan segar WisePerks akan membuat Anda jatuh cinta dengan hal itu. Itu sekarang jauh lebih mudah digunakan dan akan menjadi pengalaman yang hebat untuk Anda.",
  "Releases_whatAvatarAreYouToday" : "Apa Avatar yang Anda hari ini",
  "Releases_youCanNowCreate" : "Anda sekarang dapat membuat avatar Anda sendiri untuk fitur pada dashboard Anda.",
  "Releases_freshAndHotSurveys" : "Segar dan panas survei pada dashboard Anda",
  "Releases_youDontHaveTo" : "Anda tidak perlu khawatir tentang refresing dashboard Anda untuk melihat survei baru, kami akan membuat Anda dashboard yang diisi dengan Survey terbaru secara otomatis.",
  "Releases_promoCode" : "Fitur Kode Promo",
  "Releases_promoCodeInfo" : "Atas permintaan populer, WisePerks membawa Kode Promo untuk panelisnya setiap minggu. Cara cepat untuk meraih lebih banyak hadiah.",
  "Releases_referFriend" : "Rujuk Teman",
  "Releases_referFriendInfo" : "Buat teman Anda terkait dengan WisePerks dan dapatkan hadiah menarik. Carilah opsi Rujuk Teman di akun Anda.",

  "Dashboard_Promocode_Usepromo" : "Gunakan kode Promo",
  "Dashboard_Promocode_to" : "untuk",
  "Dashboard_Promocode_earnminimum_1" : "dapatkan minimum",
  "Dashboard_Promocode_earnminimum_2" : "poin ekstra",
  "Dashboard_Promocode_minimum" : "minimum",
  "Dashboard_Promocode_minimumextrapoints" : "poin tambahan untuk setiap survei yang diselesaikan hari ini",
  "Dashboard_Promocode_apply" : "Menerapkan",
  "Dashboard_Promocode_message1" : "Kode Promo tidak valid!",
  "Dashboard_Promocode_message2" : "Selamat! Anda akan mendapatkan minimum",
  "Dashboard_Promocode_message3" : "ekstra untuk setiap survei yang diselesaikan hari ini!",

  "Dashboard_modal_Enjoythefestive" : "Menikmati semangat meriah dengan penghasilan minimum",
  "Dashboard_modal_Extrapointson" : "tambahan poin pada setiap survei yang Anda menyelesaikan hari ini.",

  "refer_friend_title" : "Referensikan Teman dan Dapatkan Hadiah",
  "refer_friend_step_first": "Undang teman ke WisePerks",
  "refer_friend_step_second": "Teman berhasil mendaftar di WisePerks",
  "refer_friend_step_third": "Anda mendapatkan each_referral poin",
  "refer_friend_link_title": "Undang teman sekarang!",
  "refer_friend_share_title": "Bagikan tautannya",
  "refer_friend_guideline_title": 'Bagaimana "merujuk teman" akan bermanfaat bagi Anda:',
  "refer_friend_guideline_one": "Silakan bagikan tautan yang disediakan di atas dengan referensi Anda.",
  "refer_friend_guideline_two": "Anda dapat merujuk orang sebanyak yang Anda inginkan, setelah 5 referral Anda memverifikasi akun mereka dan memulai aktivitas survei, poin referensi yang Anda dapatkan akan ditambahkan ke akun Anda.",
  "refer_friend_guideline_three": "Anda mendapatkan each_referral poin masing-masing untuk rujukan yang berhasil (dengan akun email terverifikasi dan kegiatan survei).",
  "refer_friend_guideline_four": "Poin akan diperoleh dalam dua tahap, partial_referral pada verifikasi email dan partial_second_referral pada aktivitas survei oleh rujukan Anda",
  "refer_friend_guideline_five": "Setelah 5 referral berhasil (dengan akun email terverifikasi dan aktivitas survei), tautan referensi akan dinonaktifkan.",

}

