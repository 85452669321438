// German
export const TRANSLATIONS_DE = {
  "home_header_selectlang": "Sprache auswählen",
  "home_header_loginbutton": "Anmeldung",
  "home_header_signupbutton": "Anmelden",
  "home_header_homebutton": "Zuhause",
  
  "home_register_bannertxt1": "Hier dein",
  "home_register_bannertxt2": "Gedanken",
  "home_register_bannertxt3": "kann dich holen",
  "home_register_bannertxt4": "Belohnung$",
  "home_register_bannertxt5": "Erhalten Bonus",
  "home_register_bannertxt6": "Auf Anmelde",
  "home_register_fbsignup": "ANMELDEN MIT FACEBOOK",
  "home_register_fblogin": "MIT FACEBOOK EINLOGGEN",
  "home_register_or": "ODER",
  "home_register_emailsignup": "Registrieren Sie sich mit Ihrer E-Mail Adresse",
  "home_register_emaillogin": "Einloggen mit Email",
  "home_register_fpass": "Passwort vergessen?",
  "home_register_fname": "Vorname",
  "home_register_lname": "Familienname, Nachname",
  "home_register_email": "E-Mail-Addresse",
  "home_register_pass": "Passwort",
  "home_register_cpass": "Bestätige das Passwort",
  "home_register_tcpp": "Mit ihrem Beitritt erklären Sie sich mit unseren",
  "home_register_tcpptandcond": "Geschäftsbedingungen",
  "home_register_tcppand": "Und",
  "home_register_tcppprivacy": "Datenschutz",
  "home_register_join": "Begleiten Sie uns. Es ist kostenlos",
  "home_register_login": "Einloggen",
  "home_register_name_required" : "Name ist erforderlich.",
  "home_register_email_required" : "E-Mail ist erforderlich.",
  "home_register_invalid_email" : "Ungültiges Email-Format",
  "home_register_password_required" : "Passwort wird benötigt.",
  "home_register_password_lower_digit" : "Das Passwort muss 6-stellig sein",
  "home_register_password_higher_digit" : "Das Passwort muss verwendet werden. 15 Ziffern sind erforderlich",
  "home_register_cpassword_required" : "Passwort bestätigen ist erforderlich",
  "home_register_cpassword_lower_digit" : "Passwort bestätigen muss 6-stellig sein",
  "home_register_cpassword_higher_digit" : "Passwort bestätigen muss 15 Ziffern benötigt werden",
  "home_register_cpassword_not_match" : "Passwort bestätigen stimmt nicht überein",

  "home_register_fp_not_worry" : "Bitte geben Sie Ihren registrierten emailid an.", 
  "home_register_fp_resetpass_msg" : "Um Ihr Passwort zurückzusetzen, geben Sie bitte Ihre registrierte e-Mail-ID.", 
  "home_register_fp_enter_email_msg" : "Geben Sie hier Ihre e-Mail ein", 
  "home_register_fp_submit_button" : "Senden", 
  "home_register_fp_temporary_sent_pass_msg" : "Ein Reset-Passwort-Link wurde an Ihre registrierte e-Mail-ID gesendet. Bitte aktualisieren Sie Ihr Passwort frühestens.", 
  
  "home_works_howitworks": "Wie es funktioniert?",
  "home_works_howitworkst1": "Stellen Sie sich", 
  "home_works_howitworksd1": "Mitglied werden, sich anmelden",
  "home_works_howitworkst2": "Umfragen erhalten",
  "home_works_howitworksd2": "Beginnen Sie intuitiv und einfach zu Umfragen",
  "home_works_howitworkst3": "Punkte sammeln",
  "home_works_howitworksd3": "Geben Sie Ihre besten Antworten und sammeln Sie Punkte",
  "home_works_howitworkst4": "Einlösen",
  "home_works_howitworksd4": "Lösen Sie Ihre Punkte für viele aufregende Belohnungen ein",
  
  "home_aboutus_whoweare": "Wer wir sind?",
  "home_aboutus_whowearep1": "Bei WisePerks arbeiten wir hart daran, dass die Zeit, die Sie online verbringen, eine lohnende Erfahrung ist. Wir arbeiten mit Hunderten von Unternehmen zusammen, die Ihr Feedback gerne erhalten möchten und wissen, was Sie über ihre Produkte oder Dienstleistungen denken. Sie geben Ihre Meinung zu verschiedenen Diensten ab, aber keiner dieser Dienste belohnt Sie tatsächlich für Ihre Zeit, im Gegensatz zu WisePerks - wo jede Umfrage, an der Sie teilnehmen, Ihnen großartige Geldprämien für Ihre Zeit bietet.",
  "home_aboutus_whowearep2": "Wir schätzen Ihr Feedback und möchten, dass Sie für Ihre Zeit belohnt werden. Wir haben Umfragen zu verschiedenen Themen, um Ihre Interessen zu erfüllen und sie einfach zu beantworten. Sie können jeden Monat ungefähr 50- 100 $ verdienen, indem Sie verschiedene Umfragen machen. Lasst uns zusammenarbeiten, um es zu ermöglichen.",
  
  "home_earn_more_surabt": "Welche Umfragen Fragen?",
  "home_earn_more_bp": "Wirtschaftsexperten",
  "home_earn_more_consumers": "Verbraucher",
  "home_earn_more_hc": "Gesundheit",
  "home_earn_more_entertainment": "Unterhaltung & Spaß",
  "home_earn_more_gaming": "Gaming Freaks",
  "home_earn_more_tech": "Technisch versiert",
  "home_earn_more_bonus_survey": "Bonusumfrage",
  
  "home_reward_whatuearn": "Was verdienst du?",
  "home_reward_whatuearnp1": "Wir möchten die Dinge einfach halten. Sie erhalten Punkte für alle erfolgreich abgeschlossenen Umfragen. Je mehr Umfragen Sie durchführen, desto mehr Punkte erhalten Sie. Die Punkte können für Geschenkkarten Ihrer Wahl eingelöst werden, nachdem Sie einen bestimmten Schwellenwert überschritten haben.",
  "home_reward_whatuearnp2": "Sobald Sie bereit sind einzulösen, wählen Sie die gewünschte Option - Amazon, Starbucks, iTunes und viele andere. Wir geben auch Boni und Gott sei Dank seine Freitags Angebote von Zeit zu Zeit.",
  
  "home_counter_earnedover": "Mitglieder haben verdient",
  
  "home_contact_getintouch": "In Kontakt kommen",
  "home_contact_emailus": "Bei Fragen senden Sie uns bitte eine E-Mail an",
  
  "home_footer_faq": "FAQs",
  "home_footer_pp": "Datenschutz-Bestimmungen",
  "home_footer_tc": "AGB",
  "home_footer_uf": "Kommenden Features",
  "home_footer_copyright": "Copyright © current_year WisePerks. Alle Rechte vorbehalten.",

  "home_header_home": "Startseite", 
  "home_footer_toknowmore": "Mehr zu wissen",
  "home_footer_createacc": "Erstellen Sie kostenlos ein Konto und schließen Sie sich unseren Tausenden von glücklichen Mitgliedern an.",
  "home_footer_registernow": "Jetzt anmelden",
  
  "home_reset_password" : "Passwort zurücksetzen", 
  "home_reset_pass_content" : "Sie haben darum gebeten, das Passwort zurückzusetzen, um", 
  
  "dashboard_sidebar_selectavatar" : "Wählen Sie Ihren Avatar", 
  "dashboard_sidebar_uploadavatar" : "Klicken Sie hier, um Ihr Bild hochzuladen", 
  "dashboard_sidebar_pbank" : "Punkte Bank",
  "dashboard_sidebar_accumulated" : "Verdient",
  "dashboard_sidebar_realized" : "Realisiert", 
  "dashboard_sidebar_pending" : "Anpassungen",
  "dashboard_sidebar_earned_tooltip" : "Punkte, die Sie bisher gesammelt haben.",
  "dashboard_sidebar_pending_tooltip" : "Punkte, die von Ihrem Konto abgebucht werden müssen.",
  "dashboard_sidebar_main" : "Wichtigsten",
  "dashboard_sidebar_dashboard" : "Instrumententafel",
  "dashboard_sidebar_profile" : "Profil",
  "dashboard_sidebar_updateprofile" : "Profil aktualisieren",
  "dashboard_sidebar_shistory" : "Umfrageverlauf",
  "dashboard_sidebar_mtrans" : "Meine Transaktion",
  "dashboard_sidebar_passbook" : "Punktebuch",
  "dashboard_sidebar_redeem" : "Einlösen",
  "dashboard_sidebar_refer_friend" : "Freund weiterempfehlen",
  "dashboard_sidebar_logout" : "Ausloggen",
  
  "dashboard_sidebar_tutorial1" : "Zeigt Ihnen alle verfügbaren Umfragen und was Sie von ihnen verdienen können.",
  "dashboard_sidebar_tutorial2" : "Beantworten Sie hier einige Fragen, um uns Sie besser kennen zu lernen und helfen Sie uns, Ihnen Umfragen von Ihrem Interesse zu senden. Bitte beachten Sie für einige Fragen, dass Sie Ihre Antworten nach der Einreichung nicht aktualisieren können.",
  "dashboard_sidebar_tutorial2update" : "Sie können Ihre Antworten hier aktualisieren, aber es werden nur wenige Fragen für die updation verfügbar sein.",
  "dashboard_sidebar_tutorial3" : "Hier finden Sie alle Umfragen, die Sie versucht haben.",
  "dashboard_sidebar_tutorial4" : "Hilft Ihnen, den Überblick über alle Ihre Transaktionen auf wiseparks zu behalten.",
  "dashboard_sidebar_tutorial5" : "Paar schnelle Schritte, um Ihre realisierten Punkte in Belohnungen umzuwandeln.",
  "dashboard_sidebar_tutorial6" : "Zeigen Sie den gesamten Transaktionsverlauf der Punkte an, die Ihrem Konto belastet / gutgeschrieben wurden.",
  "dashboard_sidebar_gotit" : "Ich hab es",

  "dashboard_sidebar_note" : "bitte beachten Sie",
  "dashboard_sidebar_note1" : "Alle Antworten müssen ehrlich beantwortet werden.",
  "dashboard_sidebar_note2" : "Bitte seien Sie präzise und präzise mit offenen Antworten.",
  "dashboard_sidebar_note3" : "Geschwindigkeit nicht! Die Mindestzeit für die Durchführung der Umfrage ist festgelegt.",
  "dashboard_sidebar_note4" : "Bitte geben Sie einheitliche Antworten, wenn dieselben Fragen gestellt werden.",
  "dashboard_sidebar_note5" : "Neue Umfragen werden hinzugefügt und geschlossene werden automatisch aus Ihrem Armaturenbrett entfernt.",
  "dashboard_sidebar_note6" : "Verdienen Sie einige kurze Punkte, nur durch Empfehlungen.",

  "dashboard_setting_hi" : "Hallo",
  "dashboard_setting_minfo" : "Mitgliedschaftsinfo",
  "dashboard_setting_macc" : "Konto verwalten",
  "dashboard_setting_upass" : "Kennwort aktualisieren",
  "dashboard_setting_yourname" : "Dein Name ist",
  "dashboard_setting_fname" : "Vorname",
  "dashboard_setting_lname" : "Familienname, Nachname",
  "dashboard_setting_save" : "Änderungen speichern",
  "dashboard_setting_bornon" : "Du wurdest geboren",
  "dashboard_setting_youare" : "Du bist ein",
  "dashboard_setting_postal" : "Ihre Postleitzahl ist",
  "dashboard_setting_emailus" : "Für jede Hilfe oder Fragen senden Sie bitte eine E-Mail an",
  "dashboard_setting_membersince" : "Sie sind seit dem Mitglied",
  "dashboard_setting_taken" : "Du hast genommen",
  "dashboard_setting_completed" : "Umfragen und abgeschlossen",
  "dashboard_setting_ofthem" : "von ihnen.",
  "dashboard_setting_earned" : "Du hast verdient",
  "dashboard_setting_pts" : "Punkte",
  "dashboard_setting_started" : "seit du angefangen hast.",
  "dashboard_register_email" : "Sie sind bei uns registriert",
  "dashboard_setting_preferences" : "Verwalten Sie Ihre Einstellungen",
  "dashboard_setting_emailoptout" : "Email abmelden",
  "dashboard_setting_futureemails" : "Entscheiden Sie sich für zukünftige E-Mails von WisePerks.",
  "dashboard_setting_futureemailsflash" : "Unsere e-Mails halten Sie auf dem Laufenden über alle lukrativen Umfragen, die für Sie verfügbar sind. Sind Sie sicher, dass Sie Sie nicht erhalten wollen.",
  "dashboard_setting_nrecemail" : "Unsere E-Mails halten Sie auf dem Laufenden über alle lukrativen Umfragen, die für Sie verfügbar sind. Sind Sie sicher, dass Sie sie nicht aufhalten wollen.",
  "dashboard_setting_emailout_modalmsg" : "Sind Sie sicher, dass Sie sich gegen den Empfang künftiger e-Mails von Wiseparks entscheiden wollen?",
  "dashboard_setting_emailin_modalmsg" : "Sind Sie sicher, dass Sie zukünftige e-Mails von wiseparks erhalten?",
  "dashboard_setting_emailout_succmsg" : "Sie haben erfolgreich aufhören, e-Mails von wiseparks zu erhalten",
  "dashboard_setting_emailin_succmsg" : "Sie haben erfolgreich damit begonnen, e-Mails von wiseparks zu erhalten",
  "dashboard_setting_deactivateacc" : "Benutzerkonto deaktivieren",
  "dashboard_setting_disableprofile" : "Wenn Sie Ihr Konto deaktivieren, wird Ihr Profil von WisePerks deaktiviert.",
  "dashboard_setting_deactivateacc_msg" : "Sind Sie sicher, dass Sie Ihr Konto auf Wiseparks deaktivieren wollen?",
  "dashboard_setting_deactivateacc_flash" : "Wenn Sie Beschwerden oder Feedback haben, dann teilen Sie uns bitte mit. Wir würden uns vorrangig damit befassen. Geben Sie uns eine Chance. Sind Sie sicher, dass Sie Ihr Konto bei uns löschen möchten.",
  "dashboard_setting_changelang" : "Sprache ändern",
  "dashboard_setting_preferredlang" : "Wählen Sie Ihre bevorzugte Sprache",
  "dashboard_setting_updatepass" : "Kennwort aktualisieren",
  "dashboard_setting_plsupdate" : "Bitte aktualisieren Sie Ihr Passwort unten.",
  "dashboard_setting_oldpass" : "Altes Passwort",
  "dashboard_setting_enteroldpass" : "Altes Passwort eingeben",
  "dashboard_setting_newpass" : "Neues Kennwort",
  "dashboard_setting_enternewpass" : "Neues Passwort eingeben",
  "dashboard_setting_confpass" : "Bestätige das Passwort",
  "dashboard_setting_enternewpassagain" : "Geben Sie erneut ein neues Passwort ein",
  "dashboard_setting_update" : "Aktualisieren",

  "dashboard_doi_welcome" : "Willkommen",
  "dashboard_doi_welbck" : "Willkommen zurück",
  "dashboard_doi_accntverified" : "Es sieht so aus, als hätten Sie Ihre e-Mail-Konto",
  "dashboard_doi_getaccess" : "Eile! Du bekommst",
  "dashboard_doi_getaccess2" : "Punkte, sobald Sie überprüfen.",
  "dashboard_doi_activateacc" : "Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf den Link in der e-Mail, die Sie an Ihr registriertes e-Mail-Konto senden",
  "dashboard_doi_chckspam" : "Bitte überprüfen Sie Ihren Spam/Junk-Ordner, falls Sie die Verifizierungs-e-Mail nicht finden können.",
  "dashboard_doi_didntgetconf" : "Haben Sie noch keine Verifizierungs Mails erhalten?",
  "dashboard_doi_resendconf" : "Verifizierungsmail zurücksenden",
  "dashboard_doi_resendconfsucc" : "Verifizierungs-e-Mails wurden an Ihre registrierte e-Mail gesendet.", 

  "dashboard_survey_availsurveys" : "Verfügbare Umfragen",
  "dashboard_survey_wisechecksurveys1" : "Umfragen aus Sicherheitsgründen sind nicht zu erkennen. Bitte kontaktieren Sie den Support auf",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Nicht verfügbare Umfragen zur Abmeldezeit",
  "dashboard_featured" : "Empfohlene",
  "dashboard_profile_survey" : "Profilumfrage",
  "dashboard_survey_start" : "Anfang",
  "dashboard_survey_on" : "Auf",
  "dashboard_survey_off" : "aus",
  "dashboard_survey_point" : "Punkt",
  "dashboard_survey_points" : "Punkte",
  "dashboard_survey_minute" : "Minute",
  "dashboard_survey_minutes" : "Minuten",

  "demography_hi" : "Hallo",
  "demography_welcome" : "Willkommen bei wiseparks. Sie haben sich gerade verdient",
  "demography_points" : "Punkte, indem Sie sich bei uns registrieren.",
  "demography_share" : "Bitte teilen Sie uns einige weitere Details in dem untenstehenden Formular mit, um Ihnen bei der Durchführung relevanter Umfragen zu helfen.",

  "demography_gender" : "Ich bin ein",
  "demography_male" : "Männlich",
  "demography_female" : "Weiblich",
  "demography_birthday" : "Geburtstag",
  "demography_month" : "Monat", 
  "demography_day" : "Tag", 
  "demography_year" : "Jahr", 
  "demography_jan" : "Januar",
  "demography_feb" : "Februar",
  "demography_mar" : "März",
  "demography_apr" : "April",
  "demography_may" : "Kann",
  "demography_jun" : "Juni",
  "demography_jul" : "Juli",
  "demography_aug" : "August",
  "demography_sep" : "September",
  "demography_oct" : "Oktober",
  "demography_nov" : "November",
  "demography_dec" : "Dezember",
  "demography_hearabout" : "Wo hast du von WisePerks gehört?",
  "demography_opt1" : "Wählen Sie das nächstgelegene zutreffende Element aus",
  "demography_opt2" : "E-Mail-Newsletter",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum oder Blog",
  "demography_opt5" : "Freund",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "Suchmaschine",
  "demography_opt8" : "TV Commercial",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Website",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Andere",
  "demography_zipcode": "Postleitzahl",
  "demography_submit" : "einreichen",

  "privacypolicy_privacypolicy" : "Datenschutz-Bestimmungen",
  "privacypolicy_privacyisimp" : "Ihre Privatsphäre ist uns wichtig.",
  "privacypolicy_lastupdated" : "Zuletzt aktualisiert am",

  "privacypolicy_para1" : 'WiseWorks (zusammen mit seinen Tochtergesellschaften, der "Firma") besitzt und betreibt eine Reihe von verschiedenen Websites, mobilen Apps und interaktiven Diensten, einschließlich ohne Einschränkung Wisesample.com, Flapbucks.com, WisePerks.com und andere (zusammenfassend " WiseWorks-Sites ").Diese Datenschutzerklärung ("Datenschutzrichtlinie") gilt für die WiseWorks-Sites und für alle Funktionen, mobilen Anwendungen, E-Mails, Onlinedienste und andere Funktionen, unabhängig davon, ob diese über einen Computer, ein mobiles Gerät oder auf andere Weise genutzt werden die WiseWorks Sites und Funktionen (die "Services").Diese Datenschutzerklärung bezieht sich nur auf Informationen, die vom Unternehmen über die WiseWorks-Websites und -Features gesammelt werden, und deckt keine vom Unternehmen erhaltenen oder gesammelten Daten ab (sofern nicht ausdrücklich angegeben) oder von nicht verwandten Websites oder Unternehmen, auf die wir Zugriff haben Links bereitstellen',

  "privacypolicy_para2" : "Es wird erwartet, dass der Panelist / Befragte sein Profil ehrlich und wahrheitsgemäß ausfüllt und keine falschen oder falschen Informationen einreicht, nur um sich für weitere Umfragen zu qualifizieren.Es wird auch erwartet, dass Panelist / Respondent seine Antworten nicht manipulieren oder andere unfaire Mittel (IP ändern, mehrere Konten usw.) während der Umfrage ausfüllen, um Anreize zu erhalten.WisePerks verfügt über ausreichende Sicherheitsmaßnahmen, um solche Anomalien zu erkennen, und wenn ein solches Verhalten bemerkt wird, wird der Testteilnehmer von WisePerks gesperrt / blockiert und alle / seine Rücknahmeanträge werden storniert.",

  "privacypolicy_para3" : "WiseWorks verpflichtet sich, Ihre Privatsphäre bei uns zu schützen.Die folgende Datenschutzerklärung erläutert, wie Ihre persönlichen Daten gespeichert und gespeichert werden, wenn Sie unsere Website und Dienste erkunden.",

  "privacypolicy_topic1" : "Welche persönlich identifizierbaren Informationen sammelt WisePerks.com von mir?",
  "privacypolicy_topic1ans" : "WisePerks.com sammelt Informationen auf verschiedene Arten von verschiedenen Teilen unserer Website und Netzwerk.Einige Informationen werden gesammelt, wenn Sie sich anmelden.Während der Mitgliederregistrierung fragt WisePerks.com nach Ihrem Namen, Ihrer E-Mail-Adresse, Postleitzahl und Haushaltsinformationen.Je mehr Informationen Sie freiwillig zur Verfügung stellen, desto besser können wir Ihnen Dienstleistungen und Belohnungen anbieten.Sollten Sie jemals WisePerks.com auf irgendeine Weise kontaktieren, können wir diese Korrespondenz aufzeichnen.Wenn WisePerks.com solche Informationen sammelt, möchten wir auf dieser Seite einen Link zu dieser Datenschutzrichtlinie einfügen.",

  "privacypolicy_topic2" : "Was sind Cookies und wie benutzt WisePerks.com sie?",
  "privacypolicy_topic2ans" : "Ein Cookie ist ein Paket elektronischer Daten, die von einem Webserver an Ihren Browser gesendet und auf der Festplatte Ihres Computers gespeichert werden.Im Rahmen der Bereitstellung spezifischer Umfragen, Informationen und personalisierter Dienste verwendet WisePerks.com möglicherweise Cookies, um Informationen über Sie zu speichern und manchmal zu verfolgen.WisePerks.com kann verlangen, dass Sie Cookies akzeptieren, um solche spezifischen Inhalte für Sie vorzubereiten.Dadurch vermeiden Sie auch, dass Sie jedes Mal Ihre Login-Daten eingeben müssen.",

  "privacypolicy_topic3" : "Was ist die WisePerks.com-Richtlinie, die es mir erlaubt, meine persönlichen Kontoinformationen zu aktualisieren, zu bearbeiten, zu ändern oder zu löschen?",
  "privacypolicy_topic3ans" : "Sie können Ihre WisePerks.com-Kontoinformationen jederzeit bearbeiten, indem Sie Ihre WisePerks.com-Anmeldung und Ihr Passwort verwenden.Bitte kontaktieren Sie",
  "privacypolicy_topic3ans2" : "für weitere Anweisungen zur Deaktivierung Ihres WisePerks.com-Kontos.",

  "privacypolicy_topic4" : "Wer sammelt Informationen und für welchen Zweck?",
  "privacypolicy_topic4ansp1" : "WisePerks.com sammelt mehrere Informationen durch Umfragen und Registrierung und andere Mittel, die seinen Forschungskunden hilft, neue Produkte und Dienstleistungen für die Verbraucher zu entwickeln.Sie geben diese Informationen nur an WisePerks.com weiter, es sei denn, in einer Umfrage, an der Sie teilnehmen, ist dies ausdrücklich anders angegeben.Wenn Daten von einem anderen Unternehmen als WiseWorks - oder WiseWorks-Websites erfasst und / oder verwaltet werden, werden Sie vor dem Zeitpunkt der Datensammlung oder -übertragung benachrichtigt.Wenn Sie nicht möchten, dass Ihre Daten freigegeben werden, können Sie die Übertragung nicht zulassen, indem Sie die betreffende Umfrage oder das Produkt nicht verwenden.",
  "privacypolicy_topic4ansp2" : "Belohnungen und Werbeaktionen, die wir auf WisePerks.com anbieten, können entweder von anderen Unternehmen unabhängig gesponsert werden oder von WisePerks.com und einem anderen Unternehmen gemeinsam gesponsert werden.Einige Daten, die während einer solchen Werbeaktion gesammelt werden, können mit dem Sponsor geteilt werden.Sie werden jedoch vor dem Zeitpunkt der Datenerhebung oder -übertragung benachrichtigt.Sie können entscheiden, nicht an dieser Aktion teilzunehmen, wenn Sie Ihre Daten nicht teilen möchten.",
  "privacypolicy_topic4ansp3" : "Alle spezifischen sensiblen Informationen (wie Informationen über medizinische oder gesundheitliche Bedingungen, Rasse oder ethnische Herkunft, politische Ansichten, religiöse oder spirituelle Überzeugungen, Handel und Gewerkschaftsmitgliedschaft oder Informationen, die das Sexualleben des Individuums angeben) werden mit zusätzlicher Sorgfalt behandelt.Wenn WisePerks.com beabsichtigt, diese Informationen an andere Unternehmen zu einem anderen als dem ursprünglich vereinbarten Zweck weiterzugeben, werden Sie vor einer solchen Offenlegung ausdrücklich um Erlaubnis gebeten.",

  "privacypolicy_topic5" : "Mit wem teilt WisePerks.com meine Informationen?",
  "privacypolicy_topic5ansp1" : "Wir nehmen die Privatsphäre sehr ernst.WisePerks.com gibt keine Ihrer persönlich identifizierbaren Informationen weiter, es sei denn, wir haben Ihre ausdrückliche Erlaubnis oder unter besonderen Umständen, die das Gesetz erfordert, oder unter den im Folgenden näher spezifizierten Umständen. Im Folgenden werden einige Möglichkeiten beschrieben, wie Ihre Informationen weitergegeben werden können.Weitere Informationen zur Weitergabe Ihrer persönlichen Daten finden Sie in den Nutzungsbedingungen unserer Dienste.",
  "privacypolicy_topic5ansp2" : "Geschäftspartner, Kunden & Sponsoren:",
  "privacypolicy_topic5ansp3" : "WisePerks.com kann Ihre persönlichen Informationen an unsere Partner, Kunden oder Sponsoren weitergeben.Sollten jedoch persönliche Daten über Sie weitergegeben werden, werden wir dies Ihnen mitteilen, bevor wir die Daten erfassen oder übertragen.Viele Aktivitäten, die wir anbieten, verlangen zusätzliche Informationen von Sponsoren.Indem Sie weitere Informationen anfordern, geben Sie WisePerks.com die Erlaubnis, Ihre Informationen mit dem Sponsor zu teilen, damit sie Ihre Anfrage erfüllen können.In vielen Fällen wird nur Ihre E-Mail-Adresse freigegeben.Wenn mehr Informationen mit unseren Partnern geteilt werden, werden Sie im Voraus benachrichtigt.Von Zeit zu Zeit erhalten Sie möglicherweise die Möglichkeit, Testmaterialien oder andere Produkte oder Sonderangebote von unseren Partnern zu erhalten.Wenn Sie sich dafür entscheiden, Informationen von diesen Dritten zu erhalten, wird WisePerks.com (mit Ihrer Erlaubnis) Ihren Namen und Ihre E-Mail-Adresse mit ihnen teilen.",
  "privacypolicy_topic5ansp4" : "WisePerks.com kann in einigen speziellen Fällen auch persönliche Daten offenlegen, wenn die Offenlegung dieser Informationen erforderlich ist, um Personen zu identifizieren, zu kontaktieren oder rechtliche Schritte einzuleiten, die gegen WisePerks.com Richtlinien und Nutzungsbedingungen verstoßen oder Verletzungen verursachen können Einmischung in (absichtlich oder unabsichtlich) WisePerks.com Produkte, Dienstleistungen, Rechte oder Eigentum, andere WisePerks.com Mitglieder und Benutzer oder andere Personen, die von solchen Aktivitäten betroffen sein könnten.WisePerks.com kann Kontoinformationen offenlegen oder darauf zugreifen, wenn wir in gutem Glauben glauben, dass das Gesetz dies erfordert, sowie für administrative und andere Zwecke, die wir für die Wartung, den Service und die Verbesserung unserer Produkte und Dienstleistungen für notwendig erachten.",

  "privacypolicy_topic6" : "Welche rechtlichen Grundlagen verwenden wir für die Verarbeitung Ihrer Informationen?",
  "privacypolicy_topic6ans" : 'WiseWorks verpflichtet sich zum Datenschutz und zum Datenschutz.Unsere Datenschutzerklärung basiert auf den Grundsätzen der Datenschutzgrundverordnung der Europäischen Union ("DSGVO"), an die sich unsere Mitarbeiter und Lieferanten halten.',

  "privacypolicy_topic7" : "Was sind meine Möglichkeiten bezüglich der Sammlung, Verwendung und Verbreitung meiner Informationen?",
  "privacypolicy_topic7ans" : "Nur WisePerks.com (oder Partner, die unter Vertraulichkeitsvereinbarungen mit WisePerks.com zusammenarbeiten) sendet Ihnen nur dann direkte Mails, wenn Sie angegeben haben, dass Sie diesen nicht widersprechen.Sie haben auch Möglichkeiten, wie Cookies funktionieren.Wenn Sie Ihre Internet-Browser-Einstellungen ändern, haben Sie die Wahl, alle Cookies zu akzeptieren, benachrichtigt zu werden, wenn ein Cookie gesetzt ist, oder alle Cookies abzulehnen.Wenn Sie alle Cookies ablehnen, können Sie diese WisePerks.com-Dienste, die eine vorherige Registrierung erfordern, nicht nutzen, um daran teilzunehmen.WisePerks.com verkauft oder least keine Benutzerinformationen an Dritte.Wir werden Sie immer zum Zeitpunkt der Datenerhebung oder -übertragung benachrichtigen, wenn Ihre Daten an Dritte weitergegeben werden, und Sie haben immer die Möglichkeit, die Weitergabe nicht zuzulassen.",

  "privacypolicy_topic8" : "Welche Sicherheitsvorkehrungen gibt es bei WisePerks.com, um Verlust, Missbrauch oder Änderung meiner Kontoinformationen zu verhindern?",
  "privacypolicy_topic8ansp1" : "Ihre WisePerks.com-Kontoinformationen sind passwortgeschützt, sodass Sie und nur Sie Zugriff auf diese Informationen haben.",
  "privacypolicy_topic8ansp2" : "Wir empfehlen dringend, dass Sie Ihr Passwort nicht mit anderen teilen.Wir werden Sie niemals in einem unaufgeforderten Telefonanruf oder per E-Mail nach Ihrem Passwort fragen.Bitte melden Sie sich von Ihrem WisePerks.com-Konto ab und schließen Sie Ihr Browserfenster, wenn Sie mit Ihrer Aktivität fertig sind.Dadurch wird sichergestellt, dass andere Personen nicht auf Ihre persönlichen Informationen und Ihr Konto zugreifen können, wenn Sie einen öffentlichen Computer verwenden oder Ihren Computer für eine andere Person freigeben.Wann immer WisePerks.com wie oben beschrieben personenbezogene Daten verarbeitet, unternimmt WisePerks.com Schritte, um sicherzustellen, dass Ihre Daten sicher und in Übereinstimmung mit den relevanten Nutzungsbedingungen und dieser Datenschutzrichtlinie behandelt werden.Leider kann keine Datenübertragung über das Internet garantiert 100% sicher sein.Obwohl wir uns bemühen, Ihre persönlichen Daten zu schützen, kann WisePerks.com die Sicherheit von Informationen, die Sie uns oder unseren Online-Produkten oder -Dienstleistungen übermitteln, nicht garantieren oder gewährleisten, und Sie tun dies auf Ihr eigenes Risiko.Sobald wir Ihre Daten erhalten, bemühen wir uns, die Sicherheit unserer Systeme zu gewährleisten.",

  "privacypolicy_topic9" : "Was sollte ich sonst noch über meine Privatsphäre wissen?",
  "privacypolicy_topic9ans" : "Bitte beachten Sie, dass Sie (und wo auch immer) Sie persönliche Informationen online offenlegen möchten - zum Beispiel über E-Mails, soziale Netzwerke, Blogs oder Chat-Bereiche -, dass diese Informationen von anderen Nutzern gesammelt und verwendet werden können.Wenn Sie personenbezogene Daten online veröffentlichen, die für die Öffentlichkeit zugänglich sind, können Sie im Gegenzug unerwünschte Nachrichten von anderen Parteien erhalten.Letztendlich sind Sie allein für die Geheimhaltung Ihrer Passwörter und / oder Kontoinformationen verantwortlich.Bitte seien Sie vorsichtig und verantwortungsbewusst, wenn Sie online sind.",

  "privacypolicy_topic10" : "Wie schützt WisePerks.com die Privatsphäre meiner Kinder?",
  "privacypolicy_topic10ans" : "WisePerks.com erhält die vorherige Zustimmung der Eltern, wenn Umfragen die Teilnahme von Kindern erfordern und die elterliche Aufsicht auch für solche Umfragen mit Kindern im Alter von 6-15 Jahren erforderlich ist.Wir legen besonderen Wert darauf, die Sicherheit und Privatsphäre junger Benutzer auf unserer Website zu schützen, und wir ermutigen Sie, online an den Erfahrungen Ihres Kindes teilzunehmen.Wir erlauben kein Marketing oder in der Kinderforschung und die bestimmten Themen sind in der Forschung unter Kindern völlig verboten: Name / Kundenname / E-Mail oder Adresse, Telefonnummer, Rasse oder Ethnizität, Religion oder Besetzung, Haushaltseinkommen, Familienkrankheit , Familienstand von Eltern oder anderen Familienmitgliedern, Beziehungsfragen, Schulnoten, Alkohol-, Sexual-, Tabak- und Schusswaffen.",

  "privacypolicy_topic11" : "Besonderer Hinweis für internationale Benutzer",
  "privacypolicy_topic11ansp1" : "Die Website wird in den Vereinigten Staaten gehostet.Wenn Sie als Benutzer von der Europäischen Union, Asien oder einer anderen Region aus auf die Website zugreifen und Gesetze oder Bestimmungen bezüglich der Erhebung, Nutzung und Offenlegung personenbezogener Daten, die von den Gesetzen der Vereinigten Staaten abweichen, beachten, beachten Sie bitte, dass Sie Ihre persönlichen Daten an die Vereinigte Staaten, die nicht die gleichen Datenschutzgesetze wie die EU und andere Regionen haben, und indem Sie Ihre persönlichen Daten angeben, stimmen Sie zu:",
  "privacypolicy_topic11ansp2" : "Die Verwendung Ihrer persönlichen Daten für die oben genannten Zwecke in Übereinstimmung mit der Datenschutzerklärung; und die Übermittlung Ihrer persönlichen Daten in die Vereinigten Staaten wie oben angegeben.SIE ERKENNEN AN, DASS DIE GESETZGEBUNG DER VEREINIGTEN STAATEN IHRE INFORMATIONEN AUF EINE WEISE BESCHREIBEN, DIE SICH WESENTLICH VON DER NACH DEN GESETZEN ANDERER LÄNDER UND JURISDIKEN ERFORDERLICHEN BEHANDLUNG UNTERSCHEIDET UND WENIGER SCHÜTZT.WENN SIE IHRE INFORMATIONEN NICHT IN DIE VEREINIGTEN STAATEN ÜBERTRAGEN WOLLEN, SOLLTEN SIE IHRE INFORMATIONEN NICHT MIT UNS TEILEN ODER DIE WEBSITE NUTZEN.SOWEIT DAS GESETZ DES LANDES, IN DEM SIE SICH BEFINDEN, ZULÄSSIG IST, VERMEIDEN SIE AUSDRÜCKLICH JEDES RECHT, DAS SIE ERFORDERLICH MÜSSEN, UM IHRE IDENTIFIZIERUNGSINFORMATIONEN NACH DEN GESETZEN EINES LÄNDERS ODER EINER JEWEILS GERICHTLICHEN GERICHTSBARKEIT ZU BEHANDELN.DER VORLIEGENDE VERZUG IST JEDOCH IN EINIGEN LÄNDERN, WIE IN DEN MITGLIEDSTAATEN DER EUROPÄISCHEN UNION, NICHT RECHTSVERBINDLICH.SOWEIT SIE NICHT IN DEM LAND, IN DEM SIE SICH BEFINDEN, GESETZLICH VERBINDLICH IST, GILT DIESER VORWORT NICHT FÜR SIE.",

  "privacypolicy_topic12" : "Änderungen und Aktualisierungen dieser Datenschutzrichtlinie",
  "privacypolicy_topic12ans" : "WiseWorks behält sich das Recht vor, nach eigenem Ermessen Teile dieser Datenschutzrichtlinie jederzeit zu ändern, zu ändern, hinzuzufügen oder zu entfernen.Jedes Mal, wenn WiseWorks ein solches Recht ausübt, müssen Sie die aktualisierte Datenschutzrichtlinie zustimmend akzeptieren, und wenn Sie dies nicht tun, können Sie nicht mehr auf die Website zugreifen oder diese nutzen.",

  "privacypolicy_topic13": "Besonderer Hinweis für diejenigen, die nach GDPR / CCPA-Details suchen ",
  "privacypolicy_topic13ansp1": "Bitte ",
  "privacypolicy_topic13ansp2": "Klicken Sie hier ",
  "privacypolicy_topic13ansp3": "um mehr über die Compliance-Details der DSGVO (Allgemeine Datenschutzverordnung) und der CCPA (California Consumer Privacy Act) zu erfahren. ",

  "privacypolicy_addinfo" : "Zusätzliche Information",
  "privacypolicy_sentto": "Alle anderen Fragen dazu sollten an gesendet werden",

    "privacypolicyext_para1": "BITTE LESEN SIE DIESE DATENSCHUTZERKLÄRUNG SORGFÄLTIG DURCH ",
    "privacypolicyext_para2": "WiseWorks respektiert die Privatsphäre jedes Benutzers und wir verpflichten uns, Ihnen gegenüber transparent zu sein, wie wir mit personenbezogenen Daten umgehen. In dieser Datenschutzrichtlinie wird erläutert, wie WiseWorks personenbezogene Daten sammelt, weitergibt und verwendet, die Sie uns zur Verfügung stellen, wenn Sie die WiseWorks-Website nutzen oder auf andere Weise mit uns interagieren. Wir haben Ihnen auch einige Abschnitte angeboten, in denen Sie erfahren, wie Sie Ihre Datenschutzrechte und -optionen ausüben können.",
    "privacypolicyext_para3": "Bitte lesen Sie diese Datenschutzrichtlinie sorgfältig durch, bevor Sie auf unsere Website zugreifen und die WiseWorks-Dienste nutzen. Bei Fragen wenden Sie sich bitte an ",

    "privacypolicyext_topic1": "Überblick",
    "privacypolicyext_topic2": "Akzeptanz und Nutzung ",
    "privacypolicyext_topic3": "Wer wir sind ",
    "privacypolicyext_topic4": "Zusammenfassung der persönlichen Informationen und Verwendung ",
    "privacypolicyext_topic5": "Zwecke der Datenerfassung ",
    "privacypolicyext_topic6": "Datenschutzprinzip ",
    "privacypolicyext_topic7": "Arten von Daten, die wir von Ihnen sammeln",
    "privacypolicyext_topic8": "Cookies und Web Beacons",
    "privacypolicyext_topic9": "Benachrichtigung über Umfragen und Forschungsprojekte ",
    "privacypolicyext_topic10": "Wie wir Ihre Daten verwenden",
    "privacypolicyext_topic11": "Mit wem wir Ihre Daten teilen ",
    "privacypolicyext_topic12": "Betroffene und Nicht-EWR-Betroffene des EWR",
    "privacypolicyext_topic13": "Ihre Datenschutzrechte ",
    "privacypolicyext_topic14": "Unsere Politik gegenüber Kindern",
    "privacypolicyext_topic15": "Datensicherheit",
    "privacypolicyext_topic16": "Geschäftsbedingungen",
    "privacypolicyext_topic17": "Zuständigkeit",
    "privacypolicyext_topic18": "Änderungen unserer Datenschutzerklärung",
    "privacypolicyext_topic19": "Kontaktiere uns",

    "privacypolicyext_t1": "wir",
    "privacypolicyext_t2": "uns",
    "privacypolicyext_t3": "oder",
    "privacypolicyext_t4": "unser",

    "privacypolicyext_topic1ansp1": " respektiert die Datenschutzrechte seiner Benutzer und ist sich der Bedeutung des Schutzes und des Umgangs mit Informationen bewusst, die über Sie sowohl nach dem Gesetz als auch nach bewährten Verfahren gesammelt wurden. Diese Datenschutzerklärung wurde zur Einhaltung geschrieben",
    "privacypolicyext_topic1ansp1_1": "Artikel 13 und Artikel 14 der Allgemeinen Datenschutzverordnung der EU, d. H. Der Verordnung (EU) 2016/679 („DSGVO“) und der California Consumer Privacy Acts CCPA. ",
    "privacypolicyext_topic1ansp2": "Diese Datenschutzerklärung gilt für personenbezogene Daten, die wir in Bezug auf Personen verarbeiten, die:",
    "privacypolicyext_topic1ansp2_l1": "Registrieren Sie sich für ein Konto bei uns;",
    "privacypolicyext_topic1ansp2_l2": "vereinbart, Marketinginformationen von uns zu erhalten;",
    "privacypolicyext_topic1ansp2_l3": "an Umfragen teilnehmen;",
    "privacypolicyext_topic1ansp2_l4": "Kontaktieren Sie uns für Anfragen und Unterstützung",
    "privacypolicyext_topic1ansp3": 'Diese Datenschutzrichtlinie beschreibt auch, welche Rechte Sie in Bezug auf Ihre Daten haben, wie Sie Ihre Einwilligung erteilen und wie Sie Ihre Einwilligung widerrufen können. WiseWorks (zusammen mit seinen verbundenen Unternehmen, dem "Unternehmen") besitzt und betreibt mehrere verschiedene Websites, mobile Apps und interaktive Dienste, darunter ohne Einschränkung Wisesample.com, Flapbucks.com, WisePerks.com und andere (zusammen die "WiseWorks-Websites" "). Diese Datenschutzrichtlinie ("Datenschutzrichtlinie") gilt für die WiseWorks-Websites und alle Funktionen, mobilen Anwendungen, E-Mails, Onlinedienste und anderen Funktionen, unabhängig davon, ob über einen Computer, ein mobiles Gerät oder auf andere Weise zugegriffen wird, sowie für die auf oder verfügbaren Dienste über die WiseWorks-Websites und -Funktionen (die "Dienste"). Diese Datenschutzrichtlinie gilt nur für Informationen, die vom Unternehmen über die WiseWorks-Websites und -Funktionen und unsere Dienste gesammelt wurden, und gilt nicht für Informationen, die vom Unternehmen offline empfangen oder gesammelt wurden (sofern nicht ausdrücklich anders angegeben) oder von Websites oder Unternehmen, mit denen wir möglicherweise nichts zu tun haben Links bereitstellen.',

    "privacypolicyext_topic2ansp1_1": "Diese Datenschutzerklärung gilt ",
    "privacypolicyext_topic2ansp1_2": " und seine Tochtergesellschaften, einschließlich",
    "privacypolicyext_topic2ansp1_3": "gemeinsam  ",
    "privacypolicyext_topic2ansp1_4": "Durch die Nutzung unserer Website erklären Sie sich mit den Bestimmungen dieser Datenschutzrichtlinie einverstanden. Dies gilt als Ihre offizielle Zustimmung zur Verarbeitung Ihrer persönlichen Daten, Ihre Zustimmung zur Erfassung, Verwendung und Offenlegung Ihrer persönlichen Daten, wie weiter unten beschrieben. Wir können Sie auffordern Diese Hinweise können unsere Datenschutzpraktiken ergänzen oder klarstellen oder Ihnen andere Möglichkeiten bieten, wie wir Ihre Daten verarbeiten.",
    "privacypolicyext_topic2ansp2": "Wenn Sie mit einem Aspekt dieser Datenschutzrichtlinie nicht vertraut sind, sollten Sie den Zugriff auf die WiseWorks-Website und unsere Website sofort einstellen.",

    "privacypolicyext_topic3ansp1_1": " ist eine in Canada(# 10369675) eingetragene und eingetragene Gesellschaft . Unser Sitz befindet sich in  ",
    "privacypolicyext_topic3ansp1_2": "Wir bieten Panel-Services für die Online-Marktforschungsbranche. Wir ermöglichen Benutzern oder Teilnehmern, die unsere Teilnahmebedingungen erfüllen, sich für ein User-Panel-Konto zu registrieren und an Umfragen teilzunehmen, um Punkte oder andere Anreize zu erhalten.",
    "privacypolicyext_topic3ansp2": "Unsere Dienste sind in mehreren Ländern sowie in Kalifornien (USA), Kanada, Deutschland, Indien usw. verfügbar. Die Dienste sind nicht für Benutzer unter 18 Jahren bestimmt. Sie müssen mindestens 18 Jahre alt sein, um unsere Website und Dienstleistungen nutzen zu können.",

    "privacypolicyext_topic4ansp1": "Wir können personenbezogene Daten über Sie sammeln oder erhalten, und diese Informationen können in der Region der Vereinigten Staaten auf folgende Weise gespeichert oder verarbeitet werden: ",
    "privacypolicyext_topic4ansp1_l1": "Direkt von Ihnen (z. B. wo Sie uns kontaktieren); im Verlauf unserer Beziehung zu Ihnen (z. B. wenn Sie unsere Dienste nutzen);  ",
    "privacypolicyext_topic4ansp1_l2": "wenn Sie unsere Website besuchen; oder einen Teil der Website nutzen;  ",
    "privacypolicyext_topic4ansp2": "Wir können auch personenbezogene Daten über Sie von Dritten erhalten. ",

    "privacypolicyext_topic5ansp1": "Im Folgenden finden Sie Beispiele für die Verwendung Ihrer persönlichen Daten: ",
    "privacypolicyext_topic5ansp1_l1": "Erbringung von Dienstleistungen ",
    "privacypolicyext_topic5ansp1_l2": "Angebot und Verbesserung der Website",
    "privacypolicyext_topic5ansp1_l3": "Umfragen",
    "privacypolicyext_topic5ansp1_l4": "Kommunikation",
    "privacypolicyext_topic5ansp1_l5": "Benutzer Engagement ",
    "privacypolicyext_topic5ansp1_l6": "Marketing",
    "privacypolicyext_topic5ansp1_l7": "Lead-Generierung ",
    "privacypolicyext_topic5ansp1_l8": "Einhaltung gesetzlicher Bestimmungen",

    "privacypolicyext_topic6ansp1": "Wir erheben, verarbeiten und verwenden personenbezogene Daten (gemäß den Definitionen von Artikel 13 und Artikel 14 der Allgemeinen Datenschutzverordnung der EU, dh der Verordnung (EU) 2016/679 („DSGVO“) und der California Consumer Privacy Acts CCPA) bieten unsere Dienstleistungen. Personenbezogene Daten sind alle Informationen, mit denen wir Sie direkt oder indirekt identifizieren können, z. B. Name, E-Mail-Adresse, Adresse, Gesundheitsinformationen, jede Form von Identifikationsnummer oder ein oder mehrere Faktoren, die für Sie oder die Identität Ihres Begünstigten spezifisch sind.",
    "privacypolicyext_topic6ansp2": "Wir verpflichten uns zur Einhaltung der geltenden Datenschutzgesetze und stellen sicher, dass personenbezogene Daten:",
    "privacypolicyext_topic6ansp2_l1": "Rechtmäßig, fair und transparent verwendet;",
    "privacypolicyext_topic6ansp2_l2": "Nur für gültige Zwecke gesammelt, die wir Ihnen klar erklärt haben, und nicht in einer Weise verwendet werden, die mit diesen Zwecken nicht vereinbar ist;",
    "privacypolicyext_topic6ansp2_l3": "Relevant für die Zwecke, über die wir Ihnen berichtet haben, und beschränkt auf diese Zwecke;",
    "privacypolicyext_topic6ansp2_l4": "Genau und auf dem neuesten Stand gehalten; ",
    "privacypolicyext_topic6ansp2_l5": "Wird nur so lange aufbewahrt, wie es für die Zwecke erforderlich ist, von denen wir Ihnen erzählt haben.",

    "privacypolicyext_topic7ansp1": "Wir können die folgenden persönlichen Informationen über Sie sammeln und verarbeiten: ",
    "privacypolicyext_topic7ansp1_l1title": "Persönliche Profilinformationen. ",
    "privacypolicyext_topic7ansp1_l1": "Wenn Sie ein Konto bei uns registrieren, erfassen wir Ihren Vor- und Nachnamen, Ihre E-Mail-Adresse und Ihr ausgewähltes Passwort.",
    "privacypolicyext_topic7ansp1_l2title": "Kontakt Informationen.",
    "privacypolicyext_topic7ansp1_l2": "Wenn Sie Umfragen per SMS oder anderen Nachrichtenmedien erhalten möchten, erfassen wir auch Ihre Mobiltelefonnummer oder relevante Kontaktdaten.",
    "privacypolicyext_topic7ansp1_l3title": "Fragebogendaten.",
    "privacypolicyext_topic7ansp1_l3": "Der erste Profilierungsfragebogen sammelt Informationen über Ihren Hintergrund, Ihre Ausbildung, Ihren Arbeitsstatus, Ihre Verbrauchergewohnheiten und optional Ihre Gesundheit. Wir sammeln Informationen über Aktualisierungen, die Sie an Ihren Profilinformationen vornehmen.",
    "privacypolicyext_topic7ansp1_l4title": "Meinungsdaten ",
    "privacypolicyext_topic7ansp1_l4": "Bei Recherchemöglichkeiten sammeln wir die Informationen, die Sie in Bezug auf Ihre Meinungen, Einstellungen, Erfahrungen und die Verwendung bestimmter Produkte oder Dienstleistungen bereitstellen. Gelegentlich sammeln wir Video-, Audio- oder Fotoeinreichungen, die Sie während einer Recherchemöglichkeit machen.",
    "privacypolicyext_topic7ansp1_l5title": "User Experience Daten.",
    "privacypolicyext_topic7ansp1_l5": "Wir sammeln Informationen über Ihre Anmeldungen, Kommentare zu Blog-Posts, Bewertungen, Umfragen und angezeigten Webseiten.",
    "privacypolicyext_topic7ansp1_l6title": "Belohnungen Informationen.",
    "privacypolicyext_topic7ansp1_l6": "Alle persönlichen Daten, die wir möglicherweise benötigen, um Ihnen einen Preis oder eine Belohnung auszustellen oder Ihre Nutzung unserer Panel- und Umfrageaktivitäten zu überwachen.",
    "privacypolicyext_topic7ansp1_l7title": "Korrespondenzdaten.",
    "privacypolicyext_topic7ansp1_l7": "Jede Korrespondenz, die Sie uns senden.",
    "privacypolicyext_topic7ansp1_l8title": "Nutzungsdaten.",
    "privacypolicyext_topic7ansp1_l8": "Wir erfassen auch Nutzungsdaten über Sie, wenn Sie mit unseren Diensten interagieren. Dies kann beinhalten, welche Webseiten Sie besuchen, worauf Sie klicken, wenn Sie diese Aktionen ausgeführt haben usw. Zusätzlich speichern wir digitale Protokolldateien, die jedes Mal Daten aufzeichnen, wenn ein Gerät verwendet wird Zugriff auf unsere Server. Die Protokolldateien enthalten Daten über die Art jedes Zugriffs. Wir erfassen Daten von dem Gerät und der Anwendung, mit denen Sie auf unsere Dienste zugreifen, wie z. B. Ihre IP-Adresse, den Browsertyp, den Internetdienstanbieter (ISP), das Verweisen / Beenden Seiten, Betriebssystem, Datums- / Zeitstempel und Clickstream-Daten.",
    "privacypolicyext_topic7ansp2": "Wir können Ihren geografischen Standort auch anhand Ihrer IP-Adresse ableiten. Wenn Sie von einer externen Quelle (z. B. einem Link auf einer anderen Website, die in einer E-Mail- oder Messaging-App enthalten ist) zu einem WiseWorks-Dienst gelangen, verwenden wir diese Informationen möglicherweise zur Analyse Trends, verwalten Sie die Website, verfolgen Sie Benutzer und sammeln Sie demografische Informationen über unsere gesamte Benutzerbasis.",

    "privacypolicyext_topic8ansp1": "Ein Cookie ist eine Textdatei mit einer kleinen Menge an Informationen, die beim Besuch einer Website an Ihren Browser gesendet werden. Das Cookie wird dann bei jedem nachfolgenden Besuch an die ursprüngliche Website oder an eine andere Website zurückgesendet, die es erkennt Eine äußerst nützliche Technologie, die viele verschiedene Aufgaben erledigt. Ein Web Beacon ist ein häufig transparentes Grafikbild, das normalerweise nicht größer als 1 Pixel x 1 Pixel ist und auf einer Website oder in einer E-Mail platziert wird, mit der das Verhalten von überwacht wird Der Benutzer besucht die Website oder sendet die E-Mail. Sie wird häufig in Kombination mit Cookies verwendet.",
    "privacypolicyext_topic8ansp2": "Wir können Informationen mithilfe von Cookies, Web Beacons oder ähnlichen analytikgesteuerten Technologien sammeln.",
    "privacypolicyext_topic8ansp3_title": "Welche Cookies verwenden wir?",
    "privacypolicyext_topic8ansp3": "Die von uns verwendeten Cookies fallen in folgende Kategorien:",
    "privacypolicyext_topic8ansp3_l1title": "Unbedingt notwendige Cookies.",
    "privacypolicyext_topic8ansp3_l1": "Hierbei handelt es sich um Cookies, die für den Betrieb unserer Website erforderlich sind. Dazu gehören beispielsweise Cookies, mit denen Sie sich in sicheren Bereichen unserer Website anmelden können.",
    "privacypolicyext_topic8ansp3_l2title": "Analytische / Leistungs Cookies.",
    "privacypolicyext_topic8ansp3_l2": "Sie ermöglichen es uns, die Anzahl der Besucher zu erkennen und zu zählen und zu sehen, wie sich Besucher auf unserer Website bewegen, wenn sie diese verwenden. Dies hilft uns, die Funktionsweise unserer Website zu verbessern, indem wir beispielsweise sicherstellen, dass Benutzer das finden, wonach sie suchen für leicht.",
    "privacypolicyext_topic8ansp3_l3title": "Funktionalität Cookies.",
    "privacypolicyext_topic8ansp3_l3": "Diese werden verwendet, um Sie zu erkennen, wenn Sie zu unserer Website zurückkehren. Auf diese Weise können wir unsere Inhalte für Sie personalisieren, Sie mit Namen begrüßen und Ihre Vorlieben speichern.",
    "privacypolicyext_topic8ansp3_l4title": "Cookies ausrichten.",
    "privacypolicyext_topic8ansp3_l4": "Diese Cookies zeichnen Ihren Besuch auf unserer Website, die von Ihnen besuchten Seiten und die von Ihnen verfolgten Links auf. Wir verwenden diese Informationen, um unsere Website und die darauf angezeigte Werbung für Ihre Interessen relevanter zu machen. Wir können diese Informationen auch mit teilen Dritte zu diesem Zweck.",
    "privacypolicyext_topic8ansp4": 'Wir verwenden verschiedene Arten von Cookies (einschließlich einiger, die wir platzieren, und anderer, die wir Dritten erlauben, auf unserer Website zu platzieren) für verschiedene Zwecke. Die durch Cookies gesammelten Informationen ermöglichen es uns, zu überwachen, wie viele Personen unsere Dienste nutzen und wie sie mit anderen interagieren Dienste, wie viele Personen unsere E-Mails öffnen und zu welchen Zwecken diese Aktionen ausgeführt werden. Cookies werden auch verwendet, um die Anmeldung eines Benutzers zu erleichtern, als Navigationshilfen und als Sitzungszeitgeber und um die Verwendung unserer Tools durch Minderjährige einzuschränken Wir verknüpfen nicht personenbezogene Daten von Cookies nicht ohne Erlaubnis mit personenbezogenen Daten. Wenn Sie aus irgendeinem Grund nicht möchten, dass wir Ihre Nutzung unserer Website mithilfe von Cookies verfolgen, können Sie dies mithilfe Ihrer Browsereinstellungen deaktivieren Sie können auch die Option \"Nicht verfolgen\" auswählen (weitere Informationen finden Sie in Ihrem Browser). Die meisten Browser bieten Anweisungen zum Zurücksetzen des Browsers, um Cookies abzulehnen, im Abschnitt "Hilfe" der Symbolleiste.',
    "privacypolicyext_topic8ansp5": "Wir verwenden Web Beacons, um Informationen über die Nutzung unserer Dienste zu sammeln. Mit den von Web Beacons gesammelten Informationen können wir überwachen, wie viele Personen unsere Dienste nutzen, wie viele Personen unsere E-Mails öffnen und zu welchen Zwecken diese Maßnahmen ergriffen werden Unsere Web Beacons werden nicht verwendet, um Aktivitäten außerhalb unserer Dienste zu verfolgen.",
    "privacypolicyext_topic8ansp6_title": "Wie ändere ich meine Cookie-Einstellungen?",
    "privacypolicyext_topic8ansp6_1": "Die meisten Webbrowser ermöglichen eine gewisse Kontrolle über die meisten Cookies über die Browsereinstellungen. Weitere Informationen zu Cookies, einschließlich Informationen darüber, welche Cookies gesetzt wurden, finden Sie unter www.aboutcookies.org oder www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "So erfahren Sie, wie Sie Cookies in gängigen Browsern verwalten:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "Wenn Sie sich bei WiseWorks anmelden, kommunizieren wir möglicherweise per E-Mail, Telefon oder Messaging-Dienst (je nach Ihren Vorlieben) mit Ihnen über Ihre Mitgliedschaft. Wenn wir mit Ihnen kommunizieren, können Sie Folgendes tun:",
    "privacypolicyext_topic9ansp1_l1": "Forschungsmöglichkeiten (wie Umfragen, Umfragen, Fokusgruppen usw.)",
    "privacypolicyext_topic9ansp1_l2": "Forschungsergebnisse",
    "privacypolicyext_topic9ansp1_l3": "Newsletter der Diskussionsteilnehmer",
    "privacypolicyext_topic9ansp2": "Unsere Projekte können sich auf eine Vielzahl von Themen konzentrieren. Normalerweise laden wir Sie ein, an Projekten teilzunehmen, indem Sie uns per E-Mail über die Gelegenheit informieren. An diesem Punkt erläutern wir das allgemeine Thema der Umfrage, die wahrscheinliche Dauer oder die erforderliche Beteiligung. und die angebotene Belohnung. Es liegt dann an Ihnen, zu entscheiden, ob Sie teilnehmen möchten oder nicht. Die Teilnahme ist niemals obligatorisch. Sie können jederzeit aussteigen, obwohl wir es vorziehen, dass Sie Aufgaben erledigen, sobald Sie sie starten und wir nicht Normalerweise zahlen Sie keine Anreize für unvollständige Aufgaben.",
    "privacypolicyext_topic9ansp3": "Wir erwarten von Ihnen, dass Sie jederzeit auf Anfrage wahre, genaue, aktuelle und vollständige Informationen bereitstellen.",


    "privacypolicyext_topic10ansp1": "Wir werden Ihre personenbezogenen Daten nur verwenden, wenn dies gesetzlich zulässig ist. In den meisten Fällen werden wir Ihre personenbezogenen Daten unter den folgenden Umständen verwenden. Für weitere Informationen zu den Arten von Rechtsgrundlagen, auf die wir uns bei der Verarbeitung Ihrer Daten stützen, klicken Sie bitte auf Hier.:",
    "privacypolicyext_topic10ansp1_l1": "Stellen Sie Ihnen unsere Produkte und Dienstleistungen zur Verfügung",
    "privacypolicyext_topic10ansp1_l2": "Finden Sie die Personen, die für jede Forschungsmöglichkeit am besten geeignet sind.",
    "privacypolicyext_topic10ansp1_l3": "Reduzieren Sie die Anzahl der Fragen, die wir in Umfragen stellen müssen.",
    "privacypolicyext_topic10ansp1_l4": "weitere Analysen der bereits gesammelten Forschungsdaten durchführen;",
    "privacypolicyext_topic10ansp1_l5": "in aggregierter Form, damit wir unseren Kunden unsere Forschungskapazitäten erklären können.",
    "privacypolicyext_topic10ansp1_l6": "Laden Sie Sie zu Recherchemöglichkeiten wie Umfragen, Fokusgruppen, Online-Communities, passiver Überwachung der von Ihnen besuchten Websites usw. ein.",
    "privacypolicyext_topic10ansp1_l7": "Senden Sie Ihnen Prämien, die Sie verdient haben.",
    "privacypolicyext_topic10ansp1_l8": "fordern Sie auf, Ihr Profil zu aktualisieren,",
    "privacypolicyext_topic10ansp1_l9": "Laden Sie Sie ein, an Umfragen zu unseren Produkten oder Dienstleistungen teilzunehmen",
    "privacypolicyext_topic10ansp1_l10": "Überwachen, erkennen und verhindern Sie Betrug, unsachgemäße Aktivitäten und stellen Sie die Einhaltung der Richtlinien von WiseWorks sicher",
    "privacypolicyext_topic10ansp1_l11": "Führen Sie Forschung durch, einschließlich Sekundär- und Folgeforschung",
    "privacypolicyext_topic10ansp1_l12": "Bewerten Sie, wie Sie und andere Benutzer mit unseren Onlinediensten interagieren",
    "privacypolicyext_topic10ansp1_l13": "Schützen Sie WiseWorks und unsere Benutzer",
    "privacypolicyext_topic10ansp1_l14": "Führen Sie Audits im Zusammenhang mit unseren aktuellen Interaktionen mit Ihnen durch",
    "privacypolicyext_topic10ansp1_l15": "Debuggen Sie die Site und den Service, um Fehler zu identifizieren und zu reparieren, die die vorhandene beabsichtigte Funktionalität beeinträchtigen",
    "privacypolicyext_topic10ansp1_l16": "Verteidigen Sie sich in Rechtsstreitigkeiten und Ermittlungen und verfolgen Sie Rechtsstreitigkeiten",
    "privacypolicyext_topic10ansp1_l17": "Einhaltung gesetzlicher und behördlicher Auflagen.",


    "privacypolicyext_topic11ansp1": "WiseWorks gibt Ihre persönlichen Daten nur unter den nachstehend beschriebenen Umständen an Dritte weiter. Wir verkaufen oder geben keine persönlichen Daten weiter, die wir über Sie sammeln, um Geld oder andere wertvolle Gegenleistungen zu erbringen.",
    "privacypolicyext_topic11ansp1_l1title": "Autorisierte Anbieter:",
    "privacypolicyext_topic11ansp1_l1": "Wir können Ihre Daten an Drittanbieter weitergeben, die uns beim Betrieb unserer Website, bei der Verarbeitung von Zahlungen und bei der Bereitstellung unserer Dienste sowie bei der Einhaltung Ihrer Anweisungen und unserer vertraglichen Verpflichtungen unterstützen. Dazu gehören Zahlungsabwickler, Content Delivery Networks (CDNs) und Cloud-basierte Hosting-Services, Überwachungsdienste, E-Mail-Service-Anbieter, Anbieter von Qualitätssicherungs- und Testverfahren, Anbieter von Betrugs- und Missbrauchsprävention, CRM-Management (Customer Relations Management), Anbieter",
    "privacypolicyext_topic11ansp1_l2title": "Werbe- und Analysepartner:",
    "privacypolicyext_topic11ansp1_l2": "Wir können Nutzungsdaten über Cookies und andere ähnliche Technologien an Werbetreibende und Analyseanbieter von Drittanbietern weitergeben. Diese Drittanbieter erfassen möglicherweise Informationen, die von Ihrem Computer, Browser oder Mobilgerät als Antwort auf eine Anforderung von Inhalten gesendet werden, z. B. eindeutige Kennungen, Ihre IP-Adresse oder andere Informationen zu Ihrem Computer oder Gerät.",
    "privacypolicyext_topic11ansp1_l3title": "Geschäftspartner:",
    "privacypolicyext_topic11ansp1_l3": "Manchmal arbeiten wir mit Dritten zusammen, um Ihnen Veranstaltungen, Werbeaktionen, Wettbewerbe und andere Programme anzubieten. Um an diesen Angeboten teilnehmen zu können, müssen Sie möglicherweise Ihre E-Mail-Adresse und zusätzliche persönliche Informationen angeben. Wir geben nur Ihre E-Mail-Adresse und andere persönliche Informationen weiter mit einem Geschäftspartner, wenn Sie uns ausdrücklich dazu ermächtigen.",
    "privacypolicyext_topic11ansp1_l4title": "Bestimmte rechtliche Verpflichtungen:",
    "privacypolicyext_topic11ansp1_l4": "WiseWorks kann aufgefordert werden, personenbezogene Daten offenzulegen, wenn dies von einem Gericht oder anderen staatlichen Stellen angeordnet wird. Ohne Einschränkung des Vorstehenden behalten wir uns das Recht vor, solche Informationen offenzulegen, wenn wir nach Treu und Glauben der Ansicht sind, dass solche Maßnahmen erforderlich sind, um: die Anforderungen zu erfüllen Mit den geltenden Gesetzen, Vorschriften, Gerichtsbeschlüssen, Anfragen von Behörden und Strafverfolgungsbehörden können die Rechte und das Eigentum von WiseWorks oder Dritten oder die Sicherheit von WiseWorks geschützt und verteidigt werden. Unsere Benutzer, unsere Mitarbeiter oder andere verhindern, erkennen, untersuchen und Maßnahmen gegen kriminelle Aktivitäten, Betrug und Missbrauch oder unbefugte Nutzung unserer Website zu ergreifen und unsere Nutzungsbedingungen oder andere Vereinbarungen oder Richtlinien durchzusetzen, soweit dies gesetzlich zulässig ist, werden wir versuchen, Sie vor der Offenlegung Ihrer Informationen in zu informieren Antwort auf eine solche Anfrage.",
    "privacypolicyext_topic11ansp1_l5title": "Mit Ihrer Zustimmung:",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks kann Ihre persönlichen Daten an Dritte senden, wenn Sie dem zustimmen oder in Ihre Richtung. Beispielsweise kann dies auftreten, wenn wir auf Ihre Anfrage eine Transaktion abschließen.",
    "privacypolicyext_topic11ansp1_l6title": "Mit Dienstleistern:",
    "privacypolicyext_topic11ansp1_l6": "Wir können personenbezogene Daten an Anbieter, Berater, Zahlungsabwickler und andere Dienstleister weitergeben, die uns in unserem Auftrag bestimmte Dienste und eindeutige Prozessinformationen zur Verfügung stellen. Diese Dienste können die Bereitstellung von Kundensupport, die Durchführung von Geschäfts- und Verkaufsanalysen sowie die Unterstützung unserer Website-Funktionalität umfassen. Erleichterung der Zahlungsabwicklung, Unterstützung von Wettbewerben, Umfragen und anderen auf unserer Website angebotenen Funktionen. Diese Dienstleister dürfen Ihre persönlichen Daten nicht für andere Zwecke verwenden. Ihre Verwendung dieser personenbezogenen Daten unterliegt angemessenen Vertraulichkeits- und Sicherheitsmaßnahmen.",
    "privacypolicyext_topic11ansp1_l7title": "Mit Partnern:",
    "privacypolicyext_topic11ansp1_l7": "Wir können personenbezogene Daten an einige unserer Partner, verbundenen Unternehmen und andere Unternehmen weitergeben, die unter gemeinsamer Kontrolle und im gemeinsamen Eigentum stehen, vorbehaltlich der Bestimmungen dieser Datenschutzrichtlinie. Wir können Ihre personenbezogenen Daten auch im Zusammenhang mit einer wesentlichen Unternehmenstransaktion wie einem Verkauf weitergeben Eine Veräußerung, Fusion, Konsolidierung oder ein Verkauf von Vermögenswerten im Falle eines Konkurses oder der Vorbereitung auf eines dieser Ereignisse. Jedes andere Unternehmen, das uns kauft oder Teil unseres Geschäfts wird, hat das Recht, Ihre weiterhin zu nutzen personenbezogene Daten, jedoch nur in der in dieser Datenschutzrichtlinie angegebenen Weise, sofern Sie nichts anderes vereinbaren.",

    "privacypolicyext_topic12ansp1_title": "Rechtliche Grundlagen für die Verarbeitung Ihrer Daten:",
    "privacypolicyext_topic12ansp1": "Für Personen mit Wohnsitz im Europäischen Wirtschaftsraum, im Vereinigten Königreich oder in der Schweiz (zusammen „EWR-Einwohner“) können von Ihnen gesammelte Daten in den Vereinigten Staaten übertragen oder verarbeitet werden (zusammen „betroffene Personen“). Bei der Erhebung ihrer Daten stützen wir uns bei der Verarbeitung Ihrer Daten auf Rechtsgrundlagen. Wir verarbeiten Ihre Daten in der Regel nur dort, wo wir dazu verpflichtet sind, wo die Verarbeitung erforderlich ist, um Ihnen unsere Dienstleistungen zu erbringen (oder auf Ihre Anfrage hin Maßnahmen zu ergreifen, bevor Sie einen Vertrag mit Ihnen abschließen).",
    "privacypolicyext_topic12ansp2_title": "Leuchten Sie das Licht (für kalifornische Bürger) CCPA",
    "privacypolicyext_topic12ansp2_1": "Das kalifornische \"Shine the Light\" -Gesetz erlaubt Kunden in Kalifornien, spezifische Details darüber anzufordern, wie bestimmte Arten ihrer Informationen an Dritte und in einigen Fällen an verbundene Unternehmen für Direktmarketingzwecke dieser Dritten und verbundenen Unternehmen weitergegeben werden. Nach dem Gesetz sollte ein Unternehmen entweder kalifornischen Kunden auf Anfrage bestimmte Informationen zur Verfügung stellen oder kalifornischen Kunden gestatten, diese Art der Weitergabe zu deaktivieren.",
    "privacypolicyext_topic12ansp2_2": 'Um eine Shine the Light-Anfrage auszuführen, kontaktieren Sie uns bitte. Sie müssen die Erklärung "Ihre Datenschutzrechte in Kalifornien" in den Text Ihrer Anfrage sowie Ihren Namen, Ihre Straße, Ihre Stadt, Ihr Bundesland und Ihre Postleitzahl einfügen. Bitte geben Sie genügend an Informationen, um festzustellen, ob dies für Sie im Hauptteil Ihrer Anfrage zutrifft. Bitte beachten Sie, dass wir keine Anfragen per Telefon oder E-Mail annehmen und nicht für Mitteilungen verantwortlich sind, die nicht ordnungsgemäß gekennzeichnet oder gesendet wurden oder keine vollständigen Informationen enthalten.',
    "privacypolicyext_topic12ansp3_title": "Ihre Nevada-Datenschutzrechte",
    "privacypolicyext_topic12ansp3": 'Wenn Sie in Nevada ansässig sind, haben Sie das Recht, den Verkauf bestimmter personenbezogener Daten an Dritte, die diese personenbezogenen Daten lizenzieren oder verkaufen möchten, abzulehnen. Sie können dieses Recht ausüben, indem Sie uns hier kontaktieren oder uns eine E-Mail mit dem Betreff senden Zeile "Nevada verkaufen keine Anfrage" und geben Sie Ihren Namen und die mit Ihrem Konto verknüpfte E-Mail-Adresse an.',

    "privacypolicyext_topic13ansp1": "Wir ermöglichen Ihnen zahlreiche Entscheidungen zu Ihren Daten: Nachfolgend finden Sie die folgenden Datenschutzrechte, die Sie bei der Nutzung unserer Dienste haben.",
    "privacypolicyext_topic13ansp1_l1": "Sie können sich dafür entscheiden, uns bestimmte Informationen nicht zur Verfügung zu stellen.",
    "privacypolicyext_topic13ansp1_l2": "Angenommen, Sie möchten auf Ihre persönlichen Daten zugreifen, diese korrigieren, aktualisieren oder deren Löschung anfordern. In diesem Fall können Sie dies jederzeit tun, indem Sie uns über das Kundendienstportal oder über die Informationen am Ende dieser Datenschutzrichtlinie kontaktieren.",
    "privacypolicyext_topic13ansp1_l3": "Wenn Sie den Zugriff auf Ihre Daten anfordern, bestätigen wir, ob wir Ihre Daten speichern, und stellen Ihnen eine Kopie zur Verfügung, in der erläutert wird, wie wir sie verarbeiten und warum, wie lange wir sie speichern und welche Rechte damit verbunden sind.",
    "privacypolicyext_topic13ansp1_l4": "Wenn Sie die Löschung Ihrer persönlichen Daten beantragen, werden wir diese löschen. Bitte beachten Sie, dass wir alle Informationen aufbewahren müssen, die wir zur Erfüllung unserer gesetzlichen Verpflichtungen oder zur Begründung, Ausübung oder Verteidigung eines Rechtsanspruchs benötigen.",
    "privacypolicyext_topic13ansp1_l5": "Sie können Ihrer Verarbeitung persönlicher Daten widersprechen, uns auffordern, die Verarbeitung Ihrer persönlichen Daten einzuschränken oder die Portabilität Ihrer persönlichen Daten anzufordern. Sie können diese Rechte erneut ausüben, indem Sie uns über das Kundendienstportal oder über die auf der Website bereitgestellten Informationen kontaktieren unten in dieser Datenschutzrichtlinie.",
    "privacypolicyext_topic13ansp1_l6": 'Sie haben das Recht, die E-Mail-Marketing-Mitteilungen, die wir Ihnen senden, jederzeit zu deaktivieren. Sie können dieses Recht ausüben, indem Sie in den Marketing-E-Mails, die wir Ihnen senden, auf den Link "Abbestellen" oder "Deaktivieren" klicken.',
    "privacypolicyext_topic13ansp1_l7": "Deaktivieren von Nachrichten von uns: Um zukünftige Werbebotschaften von uns zu deaktivieren, sollten Sie sich im Hauptteil der an Sie gesendeten Werbebotschaft (unten in der E-Mail) abmelden oder eine Abmeldeanforderung an uns senden wird Ihre Anfrage innerhalb einer angemessenen Zeit nach Erhalt bearbeiten.",
    "privacypolicyext_topic13ansp1_l8": "Wenn wir Ihre persönlichen Daten mit Ihrer Zustimmung gesammelt und verarbeitet haben, können Sie Ihre Zustimmung jederzeit widerrufen. Wenn Sie Ihre Zustimmung widerrufen, hat dies weder Auswirkungen auf die Rechtmäßigkeit einer Verarbeitung, die wir vor Ihrem Widerruf durchgeführt haben, noch auf die Verarbeitung Ihrer Daten personenbezogene Daten, die unter Berufung auf andere rechtmäßige Verarbeitungsgründe als die Einwilligung erstellt wurden.",
    "privacypolicyext_topic13ansp1_l9": "Sie haben das Recht, sich bei einer Datenschutzbehörde über die Erhebung und Verwendung Ihrer persönlichen Daten zu beschweren. Bitte kontaktieren Sie uns für weitere Informationen. Wir senden Ihnen dann einen Link oder die Adresse unseres Datenschutzbeauftragten.",
    "privacypolicyext_topic13ansp1_l10": "Der einfachste Weg, Ihre Anfrage an uns zu senden, ist über unsere Kunden-Support-E-Mail am Ende dieser Datenschutzrichtlinie. Wir beantworten alle Anfragen, die wir von Personen erhalten, die ihre Datenschutzrechte gemäß den geltenden Datenschutzgesetzen ausüben möchten.",

    "privacypolicyext_topic14ansp1": "Die Website und die Dienste richten sich nicht an Kinder unter dreizehn (18) Jahren. WiseWorks entspricht dem COPPA-Gesetz (Children's Online Privacy Protection). Wir bitten Sie nicht wissentlich, personenbezogene Daten von Personen unter 18 Jahren zu sammeln. Bitte benachrichtigen Sie uns, wenn Sie Personen unter 18 Jahren kennen, die unsere Website nutzen, um den Zugriff auf unsere Dienste zu verhindern.",

    "privacypolicyext_topic15ansp1": "Wir verpflichten uns, die Sicherheit Ihrer Daten zu gewährleisten und Maßnahmen zum Schutz vor Verlust, Missbrauch und Änderung der von uns kontrollierten Daten zu ergreifen. Wir setzen moderne und sichere Techniken ein, um unsere Systeme vor dem Eindringen unbefugter Personen zu schützen, und wir aktualisieren unsere Sicherheit regelmäßig, sobald bessere Methoden verfügbar werden. Unsere Rechenzentren und unsere Partner wenden neue physische Sicherheitsmaßnahmen an, um den unbefugten Zugriff auf die Einrichtung zu verhindern.",
    "privacypolicyext_topic15ansp2": "Bitte beachten Sie, dass keine Sicherheitsmaßnahmen perfekt oder undurchdringlich sind. Daher können wir die absolute Sicherheit personenbezogener Daten nicht garantieren, obwohl wir branchenübliche Verfahren anwenden, um Ihre Privatsphäre zu schützen.",


    "privacypolicyext_topic16ansp1": "Um mehr über unsere Allgemeinen Geschäftsbedingungen zu erfahren, besuchen Sie bitte unsere Seite mit den Nutzungsbedingungen, auf der die Verwendung, der Haftungsausschluss und die Haftungsbeschränkungen für die Nutzung unserer Website festgelegt sind.",

    "privacypolicyext_topic17ansp1": "Die Gesetze der Vereinigten Staaten von Amerika regeln diese Datenschutzrichtlinie sowie die Nutzungsbedingungen und andere damit verbundene Dienstleistungen.",

    "privacypolicyext_topic18ansp1": "WiseWorks ist ständig bemüht, neue Technologien und Prozesse zu implementieren, um Sie, Ihre Privatsphäre und Ihre Nutzung des WiseWorks-Dienstes besser zu schützen. Infolgedessen kann WiseWorks von Zeit zu Zeit Änderungen an dieser Datenschutzrichtlinie vornehmen. In keinem Fall werden diese Änderungen vorgenommen führen zu einer Verschlechterung aller Sicherheitsmaßnahmen, die Sie vor unbefugter Offenlegung schützen sollen.",

    "privacypolicyext_topic19ansp1": "Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, wenden Sie sich bitte an",



  "faq_faq" : "Häufig gestellte Fragen",
  "faq_getans" : "Erhalten Sie Antworten auf alle Ihre Fragen.",

  "faq_ques1" : "Was ist WisePerks?",
  "faq_ans1" : "Wir freuen uns, dass Sie gefragt haben.WisePerks ist eine Online-Umfrage-Website.Viele Marken und Unternehmen suchen nach Befragten, die ihre Meinung abgeben und dafür eine Belohnung erhalten.WisePerks ist eine solche Plattform, auf der Sie Ihre Meinung äußern und Belohnungen dafür erhalten können.",

  "faq_ques2" : "Ist es kostenlos, beizutreten?",
  "faq_ans2" : "Ja, es ist kostenlos, beizutreten.Wenn jemand eine Beitrittsgebühr verlangt, möchten wir, dass Sie uns dies melden.",

  "faq_ques3" : "Kann ich meine Freunde verweisen?",
  "faq_ans3" : 'Ja, Sie können Ihre Freunde und Kollegen einladen und dafür auch belohnt werden. Klicken Sie in Ihrem Dashboard auf den Link "Freund empfehlen", um alle Details anzuzeigen.',

  "faq_ques4" : "Was verdiene ich?",
  "faq_ans4" : "Jetzt können Sie in unserem neuen Modell auf vielfältige Weise Punkte sammeln, außer nur durch die Umfragen. Sie erhalten Punkte durch: Registrierung bei uns, durch Überprüfung Ihres e-Mail-Kontos, für das Ausfüllen Ihres Profils, natürlich für den Abschluss jeder Umfrage und zu ihrer Überraschung auch für eine disqualifizierte Umfrage. Also haben wir alle Gründe, Sie zu starten, nicht wahr?",

  "faq_ques5" : "Wie werde ich Mitglied von WisePerks.com?",
  "faq_ans5" : 'Öffnen Sie WisePerks.com in Ihrem Browser.Erstellen Sie eine Mitgliedschaftsanmeldung, indem Sie auf den Tab "Registrieren" klicken.Befolgen Sie einfach die Schritte und es wird Sie durch den Rest des Prozesses führen.Sie müssen Ihre Beitrittswünsche bestätigen, indem Sie auf die Bestätigungs-E-Mail klicken, die von uns an Ihre E-Mail-Adresse gesendet wurde.',

  "faq_ques6" : "Wo ist die Bestätigungs-E-Mail, die Sie erwähnt haben?",
  "faq_ans6p1" : "Eine Bestätigungs-e-Mail wird automatisch an die e-Mail Adresse gesendet, die Sie bei der Anmeldung angeben. Wenn Sie diese e-Mail nicht in Ihrem Posteingang sehen, überprüfen Sie bitte Ihren Spam-oder Junk-Ordner. Um zukünftige e-Mails von uns zu vermeiden und die Umfrage-Einladungen/Nachrichten vom Erreichen Ihres Spam-Ordners zu übernehmen, fügen Sie bitte",
  "faq_ans6p2" : "auf Ihre Liste ihrer zugelassenen Absender oder als Kontakt in Ihrem Postfach. Wenn Sie diese Bestätigungs-e-Mail immer noch nicht finden, melden Sie sich bitte bei meinem Konto an, bestätigen Sie, dass Ihre e-Mail-Adresse bei uns korrekt ist, und fordern Sie eine",

  "faq_ques7" : "Wie nehme ich eine Umfrage?",
  "faq_ans7" : 'Sobald Sie sich nach Eingabe von Benutzername und Passwort eingeloggt haben, erreichen Sie Ihr Armaturenbrett und können alle Umfragen sehen, die Sie durchführen können. Wir senden Ihnen auch die Umfrage Links zu ihren registrierten e-Mail-IDs. Sie können den Link direkt aus Ihrem Postfach nehmen.',

  "faq_ques8" : "Wie vertraulich sind meine Antworten, meine Daten?",
  "faq_ans8" : "Für WisePerks hat die Wahrung der Privatsphäre und Vertraulichkeit oberste Priorität.Unsere Kunden interessieren sich nur für das, was Sie denken, als Verbraucher oder wie Sie sich für etwas fühlen.Wir bei WisePerks geben niemals persönliche Informationen an unsere Kunden weiter, und keine der Umfragen WisePerks verkauft oder vermietet auch keine Informationen an Dritte.",

  "faq_ques9" : "Wie lange dauert diese Umfrage?",
  "faq_ans9" : "Die meisten Umfragen dauern nur etwa 10 bis 15 Minuten oder sogar weniger.Von Zeit zu Zeit haben wir jedoch möglicherweise längere Umfragen.Mit jeder Umfrage sagen wir Ihnen im Voraus, wie lange es dauern würde, aber denken Sie daran, dass längere Umfragen auch höhere Belohnungen haben.",

  "faq_ques10" : "Kann jemand WisePerks Umfragen machen?",
  "faq_ans10" : "Sie müssen 13 Jahre oder älter sein, um sich bei WisePerks zu registrieren.Manchmal werden wir Sie jedoch in Umfragen einladen, um mit jüngeren Mitgliedern Ihrer Familie teilzunehmen.",

  "faq_ques11" : "Woher weißt du, dass ich mich für eine Umfrage qualifizieren werde?",
  "faq_ans11" : "Unser Algorithmus passt Ihre Profilinformationen den verfügbaren Umfragen an, um sicherzustellen, dass Sie die am besten geeigneten Umfragen erhalten.Wenn eine Umfrage beginnt, bestimmen normalerweise die ersten Fragen, ob Sie der Befragte sind, den unser Kunde für diese bestimmte Umfrage sucht.Wir haben eine große Auswahl an Umfragen, die nach vielen verschiedenen Arten von Menschen suchen (z. B. Leute, die sich regelmäßig Filme ansehen, eine bestimmte Sportart spielen, Frauen mit Kindern usw.) und täglich neue Umfragen erscheinen!",

  "faq_ques12" : "Was sind meine Verpflichtungen gegenüber WisePerks?",
  "faq_ans12" : "Das einzige, was wir von Ihnen erwarten, ist, Ihre Meinungen und Gedanken ehrlich zu vermitteln, da Kunden nach Ihren ehrlichen und echten Antworten suchen, um ihre Produkte und Dienstleistungen zu verbessern.Bitte geben Sie bei der Anmeldung als Mitglied korrekte Informationen an und aktualisieren Sie alle Änderungen, sobald sie auftreten.",

  "faq_ques13" : "Warum sollte ich Informationen über meinen Haushalt, meine Technologie oder Produkte, die ich kaufe, bereitstellen?",
  "faq_ans13" : "Für jede Umfrage suchen wir nach einem bestimmten Profil der Befragten.Wenn wir aus Ihren Profilinformationen wissen, dass Ihre Informationen den Kundenanforderungen entsprechen, Bingo!Wir können mit ziemlicher Sicherheit versichern, dass Sie die Umfrage erfolgreich abschließen und die Belohnung dafür erhalten können.Dies erhöht grundsätzlich Ihre Chancen, für eine Umfrage ausgewählt zu werden.",

  "faq_ques14" : "Wann erhalte ich meine Geschenkkarten nach Abschluss einer WisePerks-Umfrage?",
  "faq_ans14" : "Die Punkte, die Sie nach erfolgreichem Abschluss einer Umfrage sammeln, werden in Ihrem Eimer angesammelt. Sobald diese Punkte realisiert sind, können Sie einlösen.",

  "faq_ques15" : "Was ist mein Konto wirklich?",
  "faq_ans15" : "Es ist nur eine einfache Möglichkeit für Sie, Ihre persönlichen Informationen zu sehen, Änderungen nach Bedarf vorzunehmen, Ihre Umfrageaktivitäten zu sehen, erhaltene Prämien und alles an einem Ort.",

  "faq_ques16" : "Wie kann ich mein Konto kündigen?",
  "faq_ans16" : "Wenn Sie so viel kommen, sind wir sicher, dass Sie das nicht wollen. Allerdings auf Ihrem Armaturenbrett--> gehen Sie zu Einstellungen--> Konten verwalten--> Konto deaktivieren",

  "faq_ques17" : "Wie funktioniert das Punktesystem?",
  "faq_ans17p1" : "Jede Umfrage bezieht sich auf eine bestimmte Anzahl von Punkten. Nach dem erfolgreichen Abschluss einer Umfrage werden diese Punkte zu Ihren Einnahmen addiert. Sie können sie einlösen, sobald Sie die Mindestschwelle erreicht haben.",
  "faq_ans17p2" : "In einigen Fällen kann es vorkommen, dass Ihre Antwort aufgrund unvorhergesehener Probleme mit der Verbindung oder aufgrund von Ausfällen beim Kunden nicht erfasst wird. Wir müssen diese Punkte dann von Ihrem Konto stornieren. Wenn Sie diese Punkte bereits eingelöst haben und Ihr Kontostand niedrig ist, ist Ihr Gewinn negativ. Diese Anpassungen werden in Ihrem nächsten Einlösungszyklus vorgenommen.",

  "faq_ques18" : "Wie schnell werden die Punkte für eine Umfrage zu meinem Punktekonto hinzugefügt?",
  "faq_ans18" : "Ihre Punkte werden innerhalb von 48 Stunden Ihrem Punktekonto gutgeschrieben.",

  "faq_ques19" : "Was ist die Mindestschwelle für die Einlösung?",
  "faq_ans19" : "Die Mindestschwelle für die Einlösung beträgt 25000 Punkte.Im Durchschnitt müssten Sie 8-12 Umfragen ausfüllen, um für die Einlösung in Frage zu kommen.",

  "faq_ques20" : "Wie werden Punkte in Guthabenkarten umgewandelt?Gibt es ein Verhältnis?",
  "faq_ans20" : "Ja. Abhängig von Ihrem Land werden Punkte in Bargeld der Geschenkkarte umgewandelt.Die Umrechnungsfaktoren sind wie folgt",
  "faq_ponits" : "Punkte",
  "faq_point" : "Punkt",
  "faq_cent" : "Cent",

  "faq_ques21" : "Haben Sie noch weitere Fragen?",
  "faq_ans21p1" : "Senden Sie uns eine e-Mail an ",
  "faq_ans21p2" : "und wir werden innerhalb von 48 Stunden zu ihnen zurückkehren.",

  "Profiling_profiledashboard" : "Profil Dashboard", 
  "Profiling_completeprofile" : "Vervollständigen Sie Ihr Profil und holen Sie sich satte",
  "Profiling_completepoints" : "Punkte!",
  "Profiling_answercarefully" : "Die folgenden allgemeinen Fragen werden verwendet, um Ihr persönliches Profil zu erstellen, um Ihnen die besten umfragemöglichkeiten für die Zukunft zu bieten. Bitte nehmen Sie sich Zeit, Antworten Sie sorgfältig und wahrheitsgemäß.",

  "Profiling_cat_general" : "Allgemeine",
  "Profiling_cat_personal" : "Persönlichen",
  "Profiling_cat_household" : "Haushalt",
  "Profiling_cat_profession" : "Beruf",

  "Profiling_bar_completed" : "% Abgeschlossen",
  
  "Profiling_update_ans_button" : "Aktualisieren und fortfahren",
  "Profiling_save_ans_button" : "Speichern",
  "Profiling_sc_ans_button" : "Speichern fortsetzen",

  "Category_thanku_msg1" : "Gutes gehen",
  "Category_thanku_msg2" : "nur noch wenige und Sie werden",
  "Category_thanku_msg3" : "Punkte auf Ihrem Konto haben.",

  "Category_thanku_update_msg1" : "Vielen Dank für die Aktualisierung Ihrer Informationen!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "Weiter",

  "OverAll_thanku_msg1" : "Vielen Dank für den Austausch Ihrer Informationen. Sie verdient haben",
  "OverAll_thanku_msg2" : "Punkte.",

  "Survey_history_surveyhistory" : "Umfrage Geschichte",
  "Survey_history_fromthisscreen" : "Von diesem Bildschirm aus können Sie alle Ihre Transaktionen sehen.",
  "Survey_history_show" : "Zeigen",
  "Survey_history_entries" : "Einträge",
  "Survey_history_date" : "Datum",
  "Survey_history_details" : "Details",
  "Survey_history_surveys" : "Umfragen",
  "Survey_history_pointsreceived" : "Erhaltenen Punkte",
  "Survey_history_result" : "Ergebnis",
  "Survey_history_status" : "Status",
  "Survey_history_status_complete" : "Vollständige", 
  "Survey_history_status_disqualified" : "Disqualifiziert",
  "Survey_history_keyword" : "Schlüsselwort",
  "Survey_history_search" : "Suche",
  "Survey_history_to" : "An",
  "Survey_history_selectoption" : "Option wählen",
  "Survey_history_earned" : "Verdient",
  "Survey_history_redeemed" : "Eingelöst",
  "Survey_history_norecord" : "Keine Aufzeichnung gefunden",
  "Survey_history_Surveypts" : "Umfragepunkte",
  "Survey_history_Promocode" : "Promo-Code",

  "My_transactions_mytransactions" : "Meine Transaktionen",
  "My_transactions_fromthisscreen" : "Von diesem Bildschirm aus können Sie alle Ihre Transaktionen sehen.",
  "My_transactions_show" : "Zeigen",
  "My_transactions_entries" : "Einträge",
  "My_transactions_transactionid" : "Transaction ID",
  "My_transactions_date" : "Datum",
  "My_transactions_details" : "Details",
  "My_transactions_points" : "Punkte",
  "My_transactions_status" : "Status",
  "My_transactions_keyword" : "Schlüsselwort",
  "My_transactions_search" : "Suche",
  "My_transactions_to" : "An",
  "My_transactions_selectoption" : "Option wählen",
  "My_transactions_status_earned" : "Verdient",
  "My_transactions_status_redeemed" : "Eingelöst",
  "My_transactions_status_pending" : "Ausstehende",
  "My_transactions_status_reverted" : "Wiederhergestellt",
  "My_transactions_status_declined" : "Abgelehnt",
  "My_transactions_norecord" : "Keine Aufzeichnung gefunden",
  "My_transactions_off_msg1" : "Nach ",
  "My_transactions_off_msg2" : ", Alle Ihre Transaktionsdetails werden auf der Registerkarte Punkteheft veröffentlicht",

  "Passbook_heading" : "Punktebuch",
  "Passbook_show" : "Show",
  "Passbook_entries" : "Einträge",
  "Passbook_search" : "Suche",
  "Passbook_type" : "Art",
  "Passbook_surveyid" : "Umfragen",
  "Passbook_date" : "Datum",
  "Passbook_selectoption" : "Wähle eine Option",
  "Passbook_credit" : "Kredit",
  "Passbook_debit" : "Lastschrift",
  "Passbook_survey_id" : "Umfrage-ID",
  "Passbook_survey_pts" : "Umfragepunkte",
  "Passbook_earned_pts" : "Punkte",
  "Passbook_pending_pts" : "Anstehende Punkte",
  "Passbook_promo_code" : "Gutscheincode",
  "Passbook_transaction_type" : "Art der Transaktion",
  "Passbook_balance" : "Balance",
  "Passbook_status" : "Status",
  "Passbook_type_soi" : "Anmeldung",
  "Passbook_type_doi" : "E-Mail-Verifizierung",
  "Passbook_type_profile" : "Profilvervollständigung",
  "Passbook_type_survey" : "Umfrage",
  "Passbook_type_doir" : "E-Mail-Bestätigung von",
  "Passbook_type_doirt" : "E-Mail-Bestätigung durch Überweisung",
  "Passbook_type_surveyr" : "Umfrageversuch von",
  "Passbook_type_surveyrt" : "Umfrageversuch durch Überweisung",
  "Passbook_type_reward" : "Belohnung",
  "Passbook_type_pending" : "Anpassungen",
  "Passbook_type_reverted" : "Punkte zurückgesetzt",
  "Passbook_type_declined" : "Anfragen abgelehnt",
  "Passbook_type_redeem" : "Einlösen",
  "Passbook_type_balance" : "Mitnehmen",
  "Passbook_balance_vary" : "Das tatsächliche Gleichgewicht kann variieren",
  "Passbook_survey_debit_reason" : "Wir schätzen Ihre Bemühungen. Aufgrund einiger unkontrollierbarer Verbindungsabbrüche während der Datenübertragung konnte Ihre Antwort jedoch nicht registriert werden. Wir müssten die zuvor für diese Umfrage erhaltenen Punkte umkehren. Entschuldigung für die verursachten Unannehmlichkeiten.",

  "Redeem_redeemed" : "Einlösen",
  "Redeem_redeemhistory" : "Geschichte einlösen",
  "Redeem_history_norecord" : "Keine Aufzeichnung gefunden",
  "Redeem_date" : "Datum",
  "Redeem_details" : "Details",
  "Redeem_points" : "Punkte",
  "Redeem_status" : "Status",
  "Redeem_lookslike" : "Es sieht so aus, als hätten Sie Ihre e-Mail-Konto",
  "Redeem_hurry" : "Eile! Sie erhalten 1000 Punkte, sobald Sie überprüfen.",
  "Redeem_pleasecheck" : "Bitte prüfen Sie Ihren Posteingang und klicken Sie auf den Link in der Bestätigungs-e-Mail, um Ihr Konto zu aktivieren",
  "Redeem_kindlycheck" : "Bitte überprüfen Sie auch Ihren Spam/Junk-Ordner für die Bestätigungs-e-Mail.",
  "Redeem_getconfirmationemail" : "Noch keine Bestätigungs-e-Mail bekommen?",
  "Redeem_resendconfirmationemail" : "Bestätigungs-e-Mail zurücksenden",
  "Redeem_rewardredemptionrule" : "Belohnung Erlösungs Regel",
  "Redeem_completesurvey" : "Komplette Umfrage",
  "Redeem_earnpoints" : "Punkte sammeln",
  "Redeem_redeemrewards" : "Belohnungen einlösen",
  "Redeem_note" : "Hinweis:",
  "Redeem_yourearnedpoints" : "Ihre verdienten Punkte werden nach dem Qualifying der Umfrage eingelöst. Um die Belohnung einzulösen, müssen die minimalen einlösbaren Punkte in Ihrem Konto",
  "Redeem__points" : "Punkte.",
  "Redeem_availablebalance :" : "Verfügbare Balance:",
  "Redeem_historydetails" : "Klicken Sie hier, um Ihre Erlösungs Historie zu sehen.",
  "Redeem_reqsucc" : "Herzlichen glückwunsch! Ihre Punkte wurden eingelöst. Weitere Informationen finden Sie in ihrer eingetragenen Mailbox.", 
  "Redeem_wisecheck_youhave" : "Herzliche Glückwünsche! Sie sind nur einen Klick von Ihrer Belohnung entfernt.",
  "Redeem_wisecheck_gcagainst" : "Geschenkkarte für",
  "Redeem_wisecheck_points" : "Punkte.",
  "Redeem_wisecheck_issue" : "System hat einige nicht erkannte Aktivitäten in Ihrem Konto erfasst, aufgrund derer Sie nicht in der Lage sind, eine eingelösende Anfrage zu senden. Bitte wenden Sie sich an",
  "Redeem_emailverify_issue" : "Um Ihre Punkte einzulösen, bitten wir Sie, Ihr registriertes E-Mail-Konto zu bestätigen.",

  "Redeem_unsubscribe_issue" : "Sie können keine Einlösungsanfrage senden, da Sie sich von WisePerks abgemeldet haben. Bitte kontaktieren Sie support@wiseperks.com, um sich erneut zu registrieren.",
  "Redeem_wisecheck_message1" : "Aus Sicherheitsgründen können Sie nicht einlösen. Bitte wenden Sie sich an den Support",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Bestätigen",
  "Redeem_Cancel_button" : "Abbrechen",
  "Redeem_pending_hello" : "Hallo",
  "Redeem_pending" : "Einstellung",
  "Redeem_pending_msg1" : "Ihr Konto zeigt die Anpassung von",
  "Redeem_pending_msg2" : "Punkte durchgeführt werden. Bitte bestätigen Sie, um die Transaktion zu starten.",

  "Dashboard_wisecheck_issue" : "Das System hat in Ihrem Konto einige nicht erkannte Aktivitäten erfasst, aufgrund derer Sie die Umfragen nicht sehen können. Bitte kontaktieren Sie support@wiseperks.com zur weiteren Klärung.",
  "Dashboard_unsubscribe_issue" : "Sie können keine Umfragen anzeigen, da Sie sich von WisePerks abgemeldet haben. Bitte kontaktieren Sie support@wiseperks.com, um erneut Umfragen zu erhalten.",


  "WC_DashboardMsg_1" : "Hoppla! Keine Umfragen, die jetzt zu Ihrem Profil passen.",
  "WC_DashboardSubMsg_1" : "Keine Sorge, wir mischen unser Inventar und senden dir bald neue Umfragen.",

  "WC_DashboardMsg_2" : "Wir aktualisieren Ihr Dashboard. Bitte überprüfen Sie nach einiger Zeit neue Umfragen.",
  "WC_DashboardSubMsg_2" : "", 

  "Survey_success_msg1" : "Vielen Dank für den Abschluss der Umfrage. Sie haben gerade response_points Punkte verdient. Melden Sie sich in Ihrem ArmaturenBrett an, um Ihre gesammelten Punkte zu sehen.",
  "Survey_success_msg2" : "Vielen Dank für den Abschluss der Befragung. Sie haben gerade verdient response_points Punkte.",

  "Survey_success_but_nomore_survey" : "Glückwunsch! Sie haben gerade verdient response_points Punkte für den Abschluss der Umfrage. Bitte schauen Sie sich nach einiger Zeit nach den kommenden Umfragen an.",
  
  "Survey_terminate_pnt_msg1" : "Sieht so aus, als ob sich einige Ihrer Informationen etwas von der Anforderung unterscheiden. Wir schätzen Ihre Zeit und ihren Einsatz, werden response_points Punkte auf Ihr Konto hinzufügen. Wir konzentrieren uns darauf, Ihnen lukrativere und intuitive Umfragen zu bringen.",
  
  "Survey_terminate_nopnt_msg1" : "Sieht aus wie einige Ihrer Antworten sind etwas anders als die Anforderung. Wir werden mehr Umfragen auf deinen Weg schicken. Danke für deine Zeit und Mühe.",

  "TermsConditions_t&c" : "GeschäftsBedingungen",
  "TermsConditions_WhenRegister" : "Wenn Sie sich registrieren",
  "TermsConditions_WhenYouCreate" : "Wenn Sie ein persönliches Konto erstellen, fragen wir Sie nach persönlichen Informationen, wie hier erwähnt:",
  "TermsConditions_YouCanUpdate" : "Sie können Ihre Basisprofilinformationen jederzeit aktualisieren oder ändern.",
  "TermsConditions_YouCanOptOut" : "Sie können Ihr Konto jederzeit deaktivieren oder schließen.",
  "TermsConditions_WeRespectYour" : "Wir respektieren Ihre Privatsphäre und nutzen Technologie optimal, um Ihre Daten vor unbefugtem Zugriff physisch und elektronisch zu schützen.",
  "TermsConditions_WeShareYour" : "Wir geben Ihre Informationen nur an Organisationen weiter, die für den Betrieb von WiseWorks benötigt werden. Und diese Organisationen müssen sich um unsere Verpflichtungen bezüglich Ihrer Privatsphäre und Sicherheit kümmern.",
  "TermsConditions_WhatInformation" : "Welche Informationen sammeln wir von Ihnen?",
  "TermsConditions_DirectlyCollected" : "Direkt gesammelt",
  "TermsConditions_EmailAddressAndPassword" : "E-Mail-Adresse und Passwort Ihres Kontos bei uns.",
  "TermsConditions_YourResponse" : "Ihre Antwort auf Profilfragen.",
  "TermsConditions_AnyCustomer" : "Irgendwelche Kundendienstkorrespondenz.",
  "TermsConditions_AnyPersonalDetails" : "Alle persönlichen Daten, die Sie für die Einlösung teilen",
  "TermsConditions_AnyOtherInformation" : "Alle anderen Informationen, die direkt von Ihnen zur Verfügung gestellt werden.",
  "TermsConditions_UsageData" : "Nutzungsdaten",
  "TermsConditions_YourIPAddress" : "Ihre IP-Adresse, Standort und Cookies",
  "TermsConditions_InformationAboutTheDevice" : "Informationen über das von Ihnen verwendete Gerät",
  "TermsConditions_YourSurveyActivity" : "Ihre Umfrageaktivität, Punkte in Ihrem Konto, die Zahlungen, die Sie nach dem Einlösen erhalten.",
  "TermsConditions_HowDoWeUse" : "Wie verwenden wir Ihre Daten?",
  "TermsConditions_SendingYouInvitation" : "Senden Sie Einladungs-E-Mails, um an der Umfrage teilzunehmen, für die Sie sich qualifiziert haben.",
  "TermsConditions_DetectAndPrevent" : "Erkennen und verhindern Sie betrügerische Aktivitäten.",
  "TermsConditions_ImproveYourExperience" : "Verbessern Sie Ihre Erfahrung auf der WiseWorks-Plattform",
  "TermsConditions_YourResponseAreTransferred" : "Ihre Antworten werden in einem aggregierten und nicht identifizierten Format an unsere verbundenen Dritten und Partner übermittelt.",
  "TermsConditions_OurPartnersCanAsk" : "Unsere Partner können Sie bitten, Ihren Namen, Ihre E-Mail-Adresse und andere Kontaktinformationen während einer Umfrage anzugeben.",
  "TermsConditions_YouAreFreeToDecline" : "Es steht Ihnen frei, die Weitergabe Ihrer Daten an unsere Partner abzulehnen, wenn Sie damit einverstanden sind, da Sie diese Informationen direkt an sie weitergeben und nicht an WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Ihre Daten werden in Übereinstimmung mit den relevanten Datenschutzrichtlinien des Unternehmens und nicht mit den Datenschutzrichtlinien von WiseWorks behandelt.",
  "TermsConditions_OurPartnersAreCommited" : "Unsere Partner verpflichten sich, Ihre Privatsphäre zu respektieren und zu schützen.",
  "TermsConditions_WhatWeDont" : "Was wir damit nicht machen",
  "TermsConditions_WeDontSellOnThat" : "Wir verkaufen diese Informationen nicht an Dritte, und wir verwenden sie nicht, um Ihnen etwas zu verkaufen. Wir werden immer um Erlaubnis fragen, bevor wir Ihre Daten teilen.",
  "TermsConditions_YourRightToControl" : "Ihr Recht, Ihre Daten zu kontrollieren",
  "TermsConditions_YouHaveFullControl" : "Sie haben die volle Kontrolle über Ihre Daten.",
  "TermsConditions_YouCanAccess" : "Sie können jederzeit auf Ihre grundlegenden Profilinformationen zugreifen, sie aktualisieren oder ändern.",
  "TermsConditions_YouCanUnsubscribe" : "Sie können Ihr Konto abmelden und deaktivieren.",
  "TermsConditions_YouAreFreeToGetYour" : "Sie können Ihr Profil löschen, indem Sie uns eine E-Mail an support@wiseperks.com senden. Wir versuchen, Ihre Bedenken zu lösen, und wenn Sie Ihren Account trotzdem löschen möchten, tun Sie dies innerhalb von 72 Stunden.",
  "TermsConditions_DataSecurity" : "Datensicherheit",
  "TermsConditions_OurTechTeamWorks" : "Unser Tech-Team arbeitet sehr hart, um alle Ihre Daten sicher zu halten.",
  "TermsConditions_WeMakeUseOfLatest" : "Wir nutzen die neuesten Technologien und Maßnahmen, um Missbrauch oder unbefugten Zugriff auf Ihre Daten zu vermeiden. Aufgrund der Natur der Online-Umgebung können wir jedoch keine hundertprozentige Sicherheit für die Datensicherheit geben.",
  "TermsConditions_WeTryOurBestToRule" : "Wir versuchen unser Bestes, um die Risiken auszuschließen, können jedoch keine risikofreie Umgebung garantieren. Durch die Annahme dieser Allgemeinen Geschäftsbedingungen erkennen Sie diese Risiken an und akzeptieren sie.",
  "TermsConditions_AnyQuestions" : "Bei Fragen stehen wir Ihnen gerne zur Verfügung",
  "TermsConditions_IfYouHaveAnyQueries" : "Wenn Sie Fragen oder Beschwerden zu unseren Datenschutzrichtlinien und -verfahren haben. Bitte senden Sie uns eine E-Mail an support@wiseperks.com und wir werden uns innerhalb von 48 Stunden bei Ihnen melden.",

  "Validation_PleaseEnterProperDate" : "Bitte geben Sie das korrekte Geburtsdatum ein",
  "Validation_PleaseSelectOne" : "Bitte wählen Sie eine Option!",
  "Validation_PleaseSelectValidOptions" : "Bitte wählen Sie gültige Optionen!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Bitte wählen Sie mindestens eine Option",
  "Validation_PleaseInsertTheZip" : "Bitte geben Sie die Postleitzahl ein.",

  "Releases_releases" : "Gibt",
  "Releases_theUseOfThis" : "Die Nutzung dieser Website unterliegt den folgenden Nutzungsbedingungen.",
  "Releases_featuresOfWP" : "Features von WP Release",
  "Releases_updatedOn" : "aktualisiert am",
  "Releases_WisePerksNow" : "Wiseparks unterstützt nun mehrere Sprachen",
  "Releases_youCanWalkThourgh" : "Sie können die Seite jetzt mit viel mehr Leichtigkeit zu Fuß erreichen, da Wiseparks in ihrer vorgeferdenen Sprache verfügbar ist.",
  "Releases_languagesAvailable" : "Sprachen verfügbar:",
  "Releases_EarnMore" : "Mit unserem neuen Punktesystem mehr verdienen",
  "Releases_theNewWisePerksAccumulates" : "Die neuen Wiseparks sammeln Punkte auf Ihr Konto. Diese Punkte halten einen viel höheren Wert und sind leicht zu verdienen und zu erlösen. Mit mehreren einlösungs Optionen, aus denen wir auswählen können, sind wir sicher, dass Sie die Umfrage erleben werden.",
  "Releases_accumulatePoints" : "Sammeln Sie Punkte mit Hassel Free redeemption",
  "Releases_youGetTheBenefit" : "Sie erhalten den Vorteil, dass Sie das gleiche Nein verdienen. von Punkten, unabhängig von der Währung, die Sie einlösen wollen.",
  "Releases_surveyDisqualification" : "Umfrage Disqualifikation ist keine schlechte Nachricht mehr",
  "Releases_youEarnPoints" : "Sie sammeln Punkte, auch wenn Sie sich nicht für eine Umfrage qualifizieren, begannen sie zu antworten.",
  "Releases_newAndImproved" : "Neues und verbessertes Layout",
  "Releases_theFreshLookOf" : "Der frische Look von Wiseparks wird Sie in sich verlieben lassen. Es ist jetzt viel einfacher zu bedienen und wäre ein tolles Erlebnis für Sie.",
  "Releases_whatAvatarAreYouToday" : "Was für ein Avatar bist du heute",
  "Releases_youCanNowCreate" : "Sie können jetzt Ihren eigenen Avatar erstellen, um auf Ihrem Armaturenbrett zu präsentieren.",
  "Releases_freshAndHotSurveys" : "Frische und heiße Befragungen auf dem Armaturenbrett",
  "Releases_youDontHaveTo" : "Sie müssen sich keine Sorgen machen, Ihr Armaturenbrett zu aktualisieren, um neue Umfragen anzuzeigen, wir werden Ihr Armaturenbrett automatisch mit den neuesten Umfragen bevölkert halten.",
  "Releases_promoCode" : "Promo-Code-Funktion",
  "Releases_promoCodeInfo" : "Auf vielfachen Wunsch bringt WisePerks jede Woche Promo-Codes für seine Teilnehmer. Schneller Weg, um mehr Belohnungen zu bekommen.",
  "Releases_referFriend" : "Freund weiterempfehlen",
  "Releases_referFriendInfo" : "Bringen Sie Ihre Freunde mit WisePerks in Verbindung und holen Sie sich aufregende Belohnungen. Achten Sie in Ihrem Konto auf die Option Freund empfehlen.",

  "Dashboard_Promocode_Usepromo" : "Promo-Code verwenden",
  "Dashboard_Promocode_to" : "An",
  "Dashboard_Promocode_earnminimum_1" : "mindestens verdienen",
  "Dashboard_Promocode_earnminimum_2" : "extra Punkte",
  "Dashboard_Promocode_minimum" : "Minimum",
  "Dashboard_Promocode_minimumextrapoints" : "zusätzliche Punkte für jede Umfrage, die heute abgeschlossen wurde",
  "Dashboard_Promocode_apply" : "Anwenden",
  "Dashboard_Promocode_message1" : "Promo Code nicht gültig!",
  "Dashboard_Promocode_message2" : "Herzliche Glückwünsche! Sie werden das Minimum bekommen",
  "Dashboard_Promocode_message3" : "extra für jede heute ausgefüllte Umfrage!",

  "Dashboard_modal_Enjoythefestive" : "Genießen Sie den festlichen Geist, indem Sie bei jeder Umfrage, die Sie heute durchführen, mindestens",
  "Dashboard_modal_Extrapointson" : "zusätzliche Punkte sammeln.",

  "refer_friend_title" : "Empfehlen Sie einen Freund und erhalten Sie eine Belohnung",
  "refer_friend_step_first": "Laden Sie einen Freund zu WisePerks ein",
  "refer_friend_step_second": "Freund meldet sich erfolgreich bei WisePerks an",
  "refer_friend_step_third": "Sie erhalten each_referral Punkte",
  "refer_friend_link_title": "Lade jetzt einen Freund ein!",
  "refer_friend_share_title": "Teilen Sie den Link",
  "refer_friend_guideline_title": 'Wie empfehle ich einem Freund:',
  "refer_friend_guideline_one": "Bitte teilen Sie den oben angegebenen Link mit Ihren Empfehlungen.",
  "refer_friend_guideline_two": "Sie können beliebig viele Personen weiterleiten, sobald 5 Ihrer Empfehlungen ihren Account bestätigt und die Umfrage-Aktivität gestartet haben, werden Ihre verdienten Referenzpunkte Ihrem Konto hinzugefügt.",
  "refer_friend_guideline_three": "Sie erhalten jeweils each_referral Punkte für eine erfolgreiche Vermittlung (mit verifiziertem E-Mail-Konto und Umfrageaktivität).",
  "refer_friend_guideline_four": "Die Punkte werden in zwei Schritten, partial_referral bei der E-Mail-Bestätigung und partial_second_referral bei der Umfrage-Aktivität, durch Ihre Empfehlung gesammelt.",
  "refer_friend_guideline_five": "Nach 5 erfolgreichen Verweisen (mit verifiziertem E-Mail-Konto und Umfrageaktivität) wird der Verweislink deaktiviert.",
	
  }
  
  

  