// Fillipino
export const TRANSLATIONS_FL = {
  "home_header_selectlang": "Piliin ang Wika",
  "home_header_loginbutton": "Mag log in",
  "home_header_signupbutton": "SignUp",
  "home_header_homebutton": "Tahanan", 
  
  "home_register_bannertxt1": "Narito ang iyong",
  "home_register_bannertxt2": "mga saloobin",
  "home_register_bannertxt3": "makakakuha ka",
  "home_register_bannertxt4": "gantimpala$",
  "home_register_bannertxt5": "Makakuha ng bonus",
  "home_register_bannertxt6": "Sa SignUp",
  "home_register_fbsignup": "MAG-SIGN UP SA FACEBOOK",
  "home_register_fblogin": "MAG LOGIN SA FACEBOOK",
  "home_register_or": "O",
  "home_register_emailsignup": "Mag-sign Up sa Email",
  "home_register_emaillogin": "Mag-login gamit ang Email",
  "home_register_fpass": "Nakalimutan ang password?",
  "home_register_fname": "Pangalan",
  "home_register_lname": "Huling pangalan",
  "home_register_email": "Email Address",
  "home_register_pass": "Ang password",
  "home_register_cpass": "Kumpirmahin ang Password",
  "home_register_tcpp": "Sa pagsasama ng sumasang-ayon ka sa aming",
  "home_register_tcpptandcond": "Mga tuntunin & kundisyon",
  "home_register_tcppand": "at",
  "home_register_tcppprivacy": "Patakaran sa Pagkapribado",
  "home_register_join": "Sumali ka. Ito'y LIBRE",
  "home_register_login": "mag log in",
  "home_register_name_required" : "Kinakailangan ang pangalan.",
  "home_register_email_required" : "Kinakailangan ang email.",
  "home_register_invalid_email" : "Di-wastong Format ng Email",
  "home_register_password_required" : "Kinakailangan ang password.",
  "home_register_password_lower_digit" : "Kinakailangan ang password na 6 digit",
  "home_register_password_higher_digit" : "Dapat gamitin ang password 15 digit ay kinakailangan",
  "home_register_cpassword_required" : "Kumpirmahin ang Password ay kinakailangan",
  "home_register_cpassword_lower_digit" : "Kumpirmahin ang Password ay kailangang 6 digit na kinakailangan",
  "home_register_cpassword_higher_digit" : "Kumpirmahin ang Password ay dapat gamitin ng 15 digit na kinakailangan",
  "home_register_cpassword_not_match" : "Kumpirmahin ang hindi tumutugma sa Password",

  "home_register_fp_not_worry" : "Mangyaring ipasok ang iyong rehistradong emailid.",
  "home_register_fp_resetpass_msg" : "Upang i-reset ang iyong password, mangyaring ipasok ang iyong rehistradong email id.", 
  "home_register_fp_enter_email_msg" : "Ipasok ang iyong email dito", 
  "home_register_fp_submit_button" : "Isumite", 
  "home_register_fp_temporary_sent_pass_msg" : "Naipadala ang pag-reset ng password link na iyong rehistradong email id. Mangyaring i-update ang iyong password sa pinakamaagang.", 
  
  "home_works_howitworks": "Paano ito gumagana?",
  "home_works_howitworkst1": "Ipakilala ang iyong sarili", 
  "home_works_howitworksd1": "Maging isang miyembro, Mag-sign Up",
  "home_works_howitworkst2": "Tumanggap ng mga survey",
  "home_works_howitworksd2": "Simulan na ang pagtanggap ng mga survey na madaling maunawaan at simpleng",
  "home_works_howitworkst3": "Kumita ng mga puntos",
  "home_works_howitworksd3": "Ibigay ang iyong pinakamahusay na mga tugon at treehouse",
  "home_works_howitworkst4": "Tubusin",
  "home_works_howitworksd4": "Tubusin ang iyong mga punto para sa maraming kapana-panabik na mga gantimpala",
  
  "home_aboutus_whoweare": "Kung sino tayo?",
  "home_aboutus_whowearep1": "Sa WisePerks nagsusumikap kami upang matiyak na ang oras na iyong ginugugol sa online ay isang kasiya-siyang karanasan. Nagtatrabaho kami sa daan-daang mga kumpanya na sabik na magkaroon ng iyong feedback, alam kung ano ang nararamdaman mo tungkol sa kanilang mga produkto o serbisyo. Ibinibigay mo ang iyong opinyon sa iba't ibang mga serbisyo, ngunit wala sa mga serbisyong iyon ang talagang nagbibigay sa iyo ng gantimpala para sa iyong oras, hindi tulad ng WisePerks - kung saan ang bawat survey na iyong lumahok ay nagbibigay sa iyo ng mahusay na mga gantimpala sa cash para sa iyong oras.",
  "home_aboutus_whowearep2": "Pinahahalagahan namin ang iyong feedback at nais mong gantimpalaan para sa iyong oras. Mayroon kaming mga survey sa iba't ibang paksa upang tumugma sa iyong mga interes at gawing madaling sagutin. Maaari mong madaling gawing $ 50- $ 100 bawat buwan sa pamamagitan ng pagkuha ng iba't ibang mga survey. Kaya magtulungan tayo upang gawin ito.",
  
  "home_earn_more_surabt": "Ano ang sabihin ng survey?", 
  "home_earn_more_bp": "Mga eksperto sa negosyo",
  "home_earn_more_consumers": "Consumer",
  "home_earn_more_hc": "Kalusugan & Kalakasan ng katawan",
  "home_earn_more_entertainment": "Nakakaaliw & masaya",
  "home_earn_more_gaming": "Sugal Freaks",
  "home_earn_more_tech": "Tech Savvy",
  "home_earn_more_bonus_survey": "Bonus Survey",
  
  "home_reward_whatuearn": "Ano ang kinita mo?",
  "home_reward_whatuearnp1": "Gusto naming panatilihing simple ang mga bagay. Makakakuha ka ng mga puntos para sa lahat ng matagumpay na nakumpletong mga survey. Ang mas maraming mga survey na nakumpleto mo, mas maraming mga puntos na iyong kinikita. Ang mga puntos ay maaaring matubos para sa mga Gift Card na iyong pinili pagkatapos mong i-cross ang isang tiyak na threshold.",
  "home_reward_whatuearnp2": "Sa sandaling handa na upang tubusin, piliin ang opsyon na gusto mo - Amazon, Starbucks, iTunes at marami pang iba. Nagbibigay din kami ng Mga Bonus at Salamat sa Diyos na nag-aalok ng Biyernes mula sa oras-oras.",
  
  "home_counter_earnedover": "Nagkamit ang mga miyembro",
  
  "home_contact_getintouch": "Makipag-ugnayan",
  "home_contact_emailus": "Para sa anumang mga katanungan, mangyaring mag-email sa amin sa",
  
  "home_footer_faq": "Mga FAQ",
  "home_footer_pp": "Patakaran sa Pagkapribado",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Paparating na mga tampok", 
  "home_footer_copyright": "Copyright © current_year WisePerks. Lahat ng karapatan ay nakalaan.",

  "home_header_home": "Bahay",
  "home_footer_toknowmore": "Upang malaman ang higit pa",
  "home_footer_createacc": "Lumikha ng account para sa libre at sumali sa aming libu-libong miyembro ng masaya.",
  "home_footer_registernow": "Magparehistro ngayo",

  "home_reset_password" : "I-reset ang Password", 
  "home_reset_pass_content" : "Hinihiling mo na mai-reset ang password para sa", 

  "dashboard_sidebar_selectavatar" : "Piliin ang iyong avatar", 
  "dashboard_sidebar_uploadavatar" : "Mag-click dito upang i-upload ang iyong larawan", 
  "dashboard_sidebar_pbank" : "Puntos ng bangko",
  "dashboard_sidebar_accumulated" : "Kinita", 
  "dashboard_sidebar_realized" : "Natanto", 
  "dashboard_sidebar_pending" : "Pagsasaayos",
  "dashboard_sidebar_earned_tooltip" : "Mga puntos na iyong nakolekta hanggang ngayon.",
  "dashboard_sidebar_pending_tooltip" : "Mga puntos na kailangang ibalik mula sa iyong account.", 
  "dashboard_sidebar_main" : "Main",
  "dashboard_sidebar_dashboard" : "Dashboard",
  "dashboard_sidebar_profile" : "Profile",
  "dashboard_sidebar_updateprofile" : "I-update ang Profile",
  "dashboard_sidebar_shistory" : "Kasaysayan ng Survey",
  "dashboard_sidebar_mtrans" : "Aking Transaksyon",
  "dashboard_sidebar_passbook" : "Mga Book Book",
  "dashboard_sidebar_redeem" : "Tubusin",
  "dashboard_sidebar_refer_friend" : "Sumangguni sa Kaibigan",
  "dashboard_sidebar_logout" : "Mag-log out",

  "dashboard_sidebar_tutorial1" : "Pinapakita ng lahat ng makukuha survey at ano ang maaari mong kumita mula sa kanila.",
  "dashboard_sidebar_tutorial2" : "Sagutin ang ilang katanungan na ang dito sa amin alam mo mas maganda at makakatulong sa atin sa pagpapadala sa iyo ng survey ng iyong interes. Mangyaring tandaan para sa ilang mga tanong mo ay hindi magagawang upang i-update ang iyong mga sagot matapos isumite.",
  "dashboard_sidebar_tutorial2update" : "Maaari mong i-update ang iyong mga sagot dito, Gayunpaman ilang mga tanong ay hindi magagamit para sa mga updation.",
  "dashboard_sidebar_tutorial3" : "Hanapin dito sa lahat ng survey na sinubukan mong.",
  "dashboard_sidebar_tutorial4" : "Tumutulong sa iyo na subaybayan ng lahat ng iyong mga transaksyon sa WisePerks.",
  "dashboard_sidebar_tutorial5" : "Ilang mabilis na hakbang upang i-convert ang iyong naisakatuparan na puntos sa gantimpala.",
  "dashboard_sidebar_tutorial6" : "Tingnan ang buong kasaysayan ng transaksyon ng mga puntos na nai-debit / na-kredito sa iyong account.",
  "dashboard_sidebar_gotit" : "Nakuha ang mga ito",

  "dashboard_sidebar_note" : "Paalala",
  "dashboard_sidebar_note1" : "Ang lahat ng mga tugon ay dapat na sagutin matapat.",
  "dashboard_sidebar_note2" : "Mangyaring maging tiyak at tumpak na may bukas na natapos na tugon.",
  "dashboard_sidebar_note3" : "Huwag pabilisin! Ang minimum na oras ng pagkumpleto ng survey ay nakatakda.",
  "dashboard_sidebar_note4" : "Mangyaring magbigay ng mga pare-parehong tugon kapag ang mga tanong ay tatanungin.",
  "dashboard_sidebar_note5" : "Idinagdag ang mga bagong survey at sarado na ito ay tatanggalin sa awtomatikong mula sa iyong dashboard.",
  "dashboard_sidebar_note6" : "Kumita ng ilang mga mabilis na puntos sa pamamagitan lamang ng mga referral.",

  "dashboard_setting_hi" : "Hi",
  "dashboard_setting_minfo" : "Impormasyon sa Pagsapi",
  "dashboard_setting_macc" : "Pamahalaan ang Account",
  "dashboard_setting_upass" : "I-update ang Password",
  "dashboard_setting_yourname" : "Ang iyong pangalan ay",
  "dashboard_setting_fname" : "Pangalan",
  "dashboard_setting_lname" : "Huling pangalan",
  "dashboard_setting_save" : "I-save ang mga pagbabago",
  "dashboard_setting_bornon" : "Ipinanganak ka",
  "dashboard_setting_youare" : "Ikaw ay",
  "dashboard_setting_postal" : "Ang iyong postal code ay",
  "dashboard_setting_emailus" : "Para sa anumang tulong o query, mangyaring magpadala ng email sa",
  "dashboard_setting_membersince" : "Ikaw ay isang miyembro mula noon",
  "dashboard_setting_taken" : "Kinuha mo",
  "dashboard_setting_completed" : "mga survey at nakumpleto",
  "dashboard_setting_ofthem" : "ng mga ito.",
  "dashboard_setting_earned" : "Nakuha mo na",
  "dashboard_setting_pts" : "mga puntos",
  "dashboard_setting_started" : "dahil nagsimula ka.",
  "dashboard_register_email" : "Ikaw ay rehistrado sa amin mula sa",
  "dashboard_setting_preferences" : "Pamahalaan ang iyong mga kagustuhan",
  "dashboard_setting_emailoptout" : "Email mag-opt",
  "dashboard_setting_futureemails" : "Mag-opt out sa pagtanggap ng mga email sa hinaharap mula sa WisePerks.",
  "dashboard_setting_futureemailsflash" : "Aming mga email ang panatilihing ka posted sa lahat mga kapaki-pakinabang survey magagamit para sa iyo. Sigurado ka ba na hindi mo gustong tumanggap sa kanila.",
  "dashboard_setting_nrecemail" : "Aming mga email ang panatilihing ka posted sa lahat mga kapaki-pakinabang survey magagamit para sa iyo. Sigurado ka ba na hindi mo nais na pigilin sila.",
  "dashboard_setting_emailout_modalmsg" : "Tiyak ka bang gusto mong mag-opt sa pagtanggap ng mga hinaharap na mga email mula sa WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Sigurado ka ba na magsimula sa pagtanggap ng mga hinaharap na mga email mula sa WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Iyong mga asignatura ay tumitigil sa pagtanggap ng mga email mula sa WisePerks",
  "dashboard_setting_emailin_succmsg" : "Iyong matagumpay na magsimula sa pagtanggap ng mga email mula sa WisePerks",
  "dashboard_setting_deactivateacc" : "I-deactivate ang Account",
  "dashboard_setting_disableprofile" : "Ang pag-deactivate ng iyong account ay hindi paganahin ang iyong profile mula sa WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Tiyak ka bang gusto mong i-deactivate ang iyong account sa WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Kung mayroon kang anumang mga reklamo o puna, pagkatapos mangyaring ibahagi sa amin. Sana nating tugunan ang mga ito sa prayoridad. Nagbibigay sa atin ng pagkakataon. Tiyak ka bang gusto mong magtanggal ng account mo sa amin.",
  "dashboard_setting_changelang" : "Baguhin ang Wika",
  "dashboard_setting_preferredlang" : "Piliin ang iyong ginustong wika",
  "dashboard_setting_updatepass" : "I-update ang Password",
  "dashboard_setting_plsupdate" : "Mangyaring i-update ang iyong password sa ibaba.",
  "dashboard_setting_oldpass" : "Lumang password",
  "dashboard_setting_enteroldpass" : "Ipasok ang Lumang Password",
  "dashboard_setting_newpass" : "Bagong password",
  "dashboard_setting_enternewpass" : "Ipasok ang Bagong Password",
  "dashboard_setting_confpass" : "Kumpirmahin ang password",
  "dashboard_setting_enternewpassagain" : "Ipasok muli ang Bagong Password",
  "dashboard_setting_update" : "I-update",

  "dashboard_doi_welcome" : "Maligayang pagdating",
  "dashboard_doi_welbck" : "Maligayang pagbabalik",
  "dashboard_doi_accntverified" : "Kamukha mo pinalampas na nagpapatunay ng iyong email account.",
  "dashboard_doi_getaccess" : "Magmadali! makakakuha ka ng",
  "dashboard_doi_getaccess2" : "puntos sa sandaling mapatunayan mo.",
  "dashboard_doi_activateacc" : "Pakitiyak ang iyong inbox at i-click ang link na ibinigay sa mga account verification email na ipinadala sa iyong rehistradong email account.",
  "dashboard_doi_chckspam" : "Magiliw, suriin ang iyong Spam/Junk folder kung sakaling kayo ay hindi magagawang upang mahanap ang verification email.",
  "dashboard_doi_didntgetconf" : "Hindi makakuha ng verification email pa?",
  "dashboard_doi_resendconf" : "Ipadalang muli ang verification email",
  "dashboard_doi_resendconfsucc" : "Naipadala ang verification email sa iyong rehistradong email.", 
  
  "dashboard_survey_availsurveys" : "Magagamit na Mga Surveys",
  "dashboard_survey_wisechecksurveys1" : "Ikaw ay hindi nakikita sa survey dahil sa ilang mga dahilan ng seguridad. Mangyaring makipag-ugnay sa suporta sa",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Aboneliği iptal etme nedeni için mevcut anketler",
  "dashboard_featured" : "ITINAMPOK",
  "dashboard_profile_survey" : "Survey ng profile",
  "dashboard_survey_start" : "Magsimula",
  "dashboard_survey_on" : "Sa",
  "dashboard_survey_off" : "Off",
  "dashboard_survey_point": "punto",
  "dashboard_survey_points" : "Mga punto",
  "dashboard_survey_minute" : "Minuto",
  "dashboard_survey_minutes" : "Mga minuto",

  "demography_hi" : "Hi",
  "demography_welcome" : "Maligayang pagdating sa WisePerks. Nakuha mo lang ang iyong sarili",
  "demography_points" : "ang mga puntos sa pamamagitan ng pagrerehistro sa amin.",
  "demography_share" : "Mangyaring ibahagi ang ilang higit pang mga detalye sa ang form sa ibaba, upang makatulong tayo sa pagdadala ng mga survey na nauugnay sa iyo.",

  "demography_gender" : "Ako ay isang",
  "demography_male" : "Lalake",
  "demography_female" : "Babae",
  "demography_birthday" : "Kaarawan",
  "demography_month" : "Buwang", 
  "demography_day" : "Araw", 
  "demography_year" : "Taon", 
  "demography_jan" : "Enero",
  "demography_feb" : "Pebrero",
  "demography_mar" : "Marso",
  "demography_apr" : "Abril",
  "demography_may" : "Mayo",
  "demography_jun" : "Hunyo",
  "demography_jul" : "Hulyo",
  "demography_aug" : "Agosto",
  "demography_sep" : "Setyembre",
  "demography_oct" : "Oktubre",
  "demography_nov" : "Nobyembre",
  "demography_dec" : "Disyembre",
  "demography_hearabout" : "Saan mo naririnig ang tungkol sa WisePerks?",
  "demography_opt1" : "Piliin ang pinakamalapit na naaangkop",
  "demography_opt2" : "Email / Newsletter",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum o Blog",
  "demography_opt5" : "Kaibigan",
  "demography_opt6" : "LinkedIn",
  "demography_opt7" : "Na Search Engine",
  "demography_opt8" : "TV komersyal",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Website",
  "demography_opt11" : "YouTube",
  "demography_opt12" : "Iba pa",
  "demography_zipcode": "Zipcode",
  "demography_submit" : "Isumite",

  "privacypolicy_privacypolicy" : "Patakaran sa Pagkapribado",
  "privacypolicy_privacyisimp" : "Mahalaga sa amin ang iyong pagkapribado.",
  "privacypolicy_lastupdated" : "Huling na-update sa",

  "privacypolicy_para1" : "Ang WiseWorks (kasama ang mga kaakibat nito, ang 'Kumpanya') ay nagmamay-ari at nagpapatakbo ng maraming iba't ibang mga website, mga mobile na apps, at mga interactive na serbisyo, kabilang ang walang limitasyon sa Wisesample.com, Flapbucks.com, WisePerks.com, at iba pa (sama-sama, ang 'Mga Site ng WiseWorks ').Ang patakaran sa pagkapribado ('Patakaran sa Pagkapribado') ay nalalapat sa Mga Site ng WiseWorks at sa lahat ng mga tampok, mga mobile na application, mga email, mga serbisyong online at iba pang mga pag-andar, kung na-access sa pamamagitan ng computer, mobile device, o iba pa at ang mga serbisyo na magagamit sa o sa pamamagitan ng ang Mga Site at Mga Tampok ng WiseWorks (ang 'Mga Serbisyo').Sinasaklaw lamang ng Patakaran sa Privacy na ito ang impormasyon na nakolekta ng Kumpanya sa pamamagitan ng Mga Site at Mga Tampok ng WiseWorks at ng aming Mga Serbisyo, at hindi sumasakop sa anumang impormasyon na natanggap o nakolekta offline ng Kumpanya (maliban kung partikular na nakasaad) o nakolekta ng anumang hindi nauugnay na mga website o kumpanya magbigay ng mga link.",

  "privacypolicy_para2" : "Inaasahan na ang panelist / respondent ay punan ang kanyang profile matapat at truthfully at hindi magsumite ng mali o hindi tamang impormasyon upang maging kuwalipikado para sa higit pang mga survey.Inaasahan din na ang panelist / respondent ay hindi dapat mamanipula ang kanyang mga sagot o gumamit ng iba pang hindi makatarungang paraan (pagbabago ng IP, maramihang mga account atbp) sa panahon ng survey pagpuno upang kumita ng mga insentibo.Ang WisePerks ay may sapat na pananggalang upang makita ang mga anomalya at kung ang naturang pag-uugali ay napansin, ang panelist ay pinagbawalan / hinarangan mula sa WisePerks at ang lahat ng / anumang mga kahilingan sa pagtubos ay dapat kanselahin.",

  "privacypolicy_para3" : "Ang WiseWorks ay lubos na nakatuon upang pangalagaan ang iyong privacy sa amin.Ipinapaliwanag ng sumusunod na patakaran sa pagkapribado kung paano gaganapin at ginagamot ang iyong personal na impormasyon habang tinutuklasan mo ang aming website at serbisyo.",

  "privacypolicy_topic1" : "Anong personal na impormasyon na makikilala ang kinukuha ng WisePerks.com mula sa akin?",
  "privacypolicy_topic1ans" : "Kinokolekta ng WisePerks.com ang impormasyon sa maraming mga kaugalian mula sa iba't ibang bahagi ng aming website at network.Ang ilang impormasyon ay natipon kapag nag-sign up ka.Sa panahon ng pagpaparehistro ng miyembro, hinihiling ng WisePerks.com ang iyong pangalan, email address, zip code at impormasyon sa sambahayan.Ang mas maraming impormasyon na iyong binibigyang magbibigay ng tumpak, mas mahusay na magagawa naming mag-alok sa iyo ng mga serbisyo at gantimpala.Bukod pa rito, kung nakikipag-ugnay ka sa WisePerks.com sa anumang paraan, maaari naming panatilihin ang isang talaan ng sulat na iyon.Kailanman nangongolekta ng WisePerks.com ang anumang naturang impormasyon, layunin naming isama ang isang link sa Patakaran sa Pagkapribado na ito sa pahinang iyon.",

  "privacypolicy_topic2" : "Ano ang mga cookies at paano ginagamit ng WisePerks.com ang mga ito?",
  "privacypolicy_topic2ans" : "Ang isang cookie ay isang packet ng elektronikong data na ipinadala sa iyong browser, mula sa isang web server at naka-imbak sa hard drive ng iyong computer.Bilang bahagi ng pag-aalok sa iyo ng mga tukoy na survey, impormasyon, at mga personalized na serbisyo, maaaring gamitin ng WisePerks.com ang cookies upang mag-imbak at kung minsan ay subaybayan ang impormasyon tungkol sa iyo.Maaaring mangailangan ng WisePerks.com na tumanggap ka ng mga cookies para sa paghahanda ng partikular na nilalaman para sa iyo.Naaalis din nito na kinakailangang ipasok mo ang iyong impormasyon sa pag-login sa bawat oras.",

  "privacypolicy_topic3" : "Ano ang patakaran ng WisePerks.com na nagpapahintulot sa akin na i-update, i-edit, baguhin o tanggalin ang impormasyon ng aking personal na account?",
  "privacypolicy_topic3ans" : "Maaari mong i-edit ang iyong Impormasyon sa WisePerks.com Account anumang oras sa pamamagitan ng paggamit ng iyong WisePerks.com Mag-log in at password.Mangyaring makipag-ugnay sa",
  "privacypolicy_topic3ans2" : "para sa karagdagang mga tagubilin tungkol sa pag-deactivate ng iyong WisePerks.com account.",

  "privacypolicy_topic4" : "Sino ang nagtitipon ng impormasyon at para sa anong layunin?",
  "privacypolicy_topic4ansp1" : "Kinokolekta ng WisePerks.com ang ilang impormasyon sa pamamagitan ng mga survey at pagpaparehistro at iba pang paraan, na tumutulong sa mga kliyente nito sa pananaliksik na bumuo ng mga bagong produkto at serbisyo para sa mga mamimili.Ibinabahagi mo ang impormasyong iyon sa WisePerks.com lamang, maliban kung ito ay partikular na nakasaad kung hindi man sa anumang survey na iyong lumahok.Kung ang data ay nakolekta at / o pinananatili ng anumang kumpanya maliban sa mga site ng WiseWorks - o WiseWorks, aabisuhan ka bago ang oras ng pagkolekta o paglipat ng data.Kung hindi mo nais na ibahagi ang iyong data, maaari mong piliin na huwag payagan ang paglipat sa pamamagitan ng hindi gumagamit ng partikular na survey o produkto.",
  "privacypolicy_topic4ansp2" : "Ang mga gantimpala at promosyon na aming inaalok sa WisePerks.com ay maaaring i-sponsor ng iba pang mga kumpanya nang nakapag-iisa, o maaaring co-sponsor ng WisePerks.com at ibang kumpanya.Ang ilang data na nakolekta sa panahon ng naturang promosyon ay maaaring ibahagi sa sponsor.Gayunpaman, aabisuhan ka bago ang oras ng pagkolekta o paglipat ng data.Maaari kang magpasya na huwag sumali sa partikular na promosyon kung ayaw mong ibahagi ang iyong data.",
  "privacypolicy_topic4ansp3" : "Ang anumang tukoy na Sensitibong Impormasyon (tulad ng impormasyon na tumutukoy sa mga kondisyon ng medikal o pangkalusugan, pinagmumulan ng lahi o pinagmulang etniko, pananaw pampulitika, relihiyon o espirituwal na paniniwala, trades at pagiging kasapi ng unyon o impormasyon na tumutukoy sa buhay ng kasarian ng indibidwal) ay ituturing ng karagdagang pangangalaga.Kung nais ng WisePerks.com na ibahagi ang naturang impormasyon sa ibang kumpanya para sa ibang layunin kaysa sa orihinal na nakolekta, pagkatapos ay hayagang hilingin ang pahintulot bago ang anumang naturang pagsisiwalat.",

  "privacypolicy_topic5" : "Kaninong ibinahagi ng WisePerks.com ang aking impormasyon?",
  "privacypolicy_topic5ansp1" : "Sineseryoso namin ang privacy.Hindi ibubunyag ng WisePerks.com ang anuman sa iyong impormasyong personal na makikilala maliban kung mayroon kang tahasang pahintulot o sa ilalim ng anumang mga espesyal na pangyayari, na hinihingi ito ng batas o sa ilalim ng mga pangyayari na tinukoy nang detalyado sa ibaba.Ang sumusunod ay naglalarawan ng ilan sa mga paraan na maaaring isiwalat ang iyong impormasyon.Mangyaring tingnan ang Mga Tuntunin ng Serbisyo para sa aming mga serbisyo para sa mas detalyadong impormasyon tungkol sa kung paano maibabahagi ang iyong personal na impormasyon.",
  "privacypolicy_topic5ansp2" : "Mga Kasosyo sa Negosyo, Mga Kliyente at Sponsor:",
  "privacypolicy_topic5ansp3" : "Maaaring ibunyag ng WisePerks.com ang iyong personal na impormasyon sa aming mga kasosyo, kliyente o sponsor.Gayunpaman, kung may anumang personal na data tungkol sa iyo na ibabahagi, sasabihin namin sa iyo bago namin mangolekta o ilipat ang data.Maraming mga aktibidad na aming inaalok ay humiling ng karagdagang impormasyon mula sa mga sponsor.Sa pamamagitan ng iyong humihiling ng karagdagang impormasyon, binibigyan mo ng WisePerks.com ang pahintulot na ibahagi ang iyong impormasyon sa sponsor upang matupad nila ang iyong kahilingan.Sa maraming pagkakataon, ibabahagi lamang ang iyong email address.Kung mas maraming impormasyon ang ibabahagi sa aming mga kasosyo, aabisuhan ka nang maaga.Paminsan-minsan, maaari kang maibigay ang oportunidad upang makatanggap ng mga materyales sa pagsusulit, o iba pang mga produkto o mga espesyal na alok mula sa aming mga kasosyo.Kung nag-opt-in ka upang makatanggap ng impormasyon mula sa mga third party na ito, ang WisePerks.com ay (kasama ang iyong pahintulot) ibahagi ang iyong pangalan at email address sa kanila.",
  "privacypolicy_topic5ansp4" : "Ang WisePerks.com ay maaari ring ibunyag ang personal na impormasyon sa ilang mga espesyal na kaso kapag ang pagsisiwalat ng impormasyong ito ay kinakailangan upang makilala, makipag-ugnay o magdala ng legal na aksyon laban sa isang tao na maaaring lumabag sa mga patakaran at Mga Tuntunin ng Serbisyo ng WisePerks.com o maaaring magdulot ng anumang pinsala sa o panghihimasok sa (alinman sa sinadya o hindi sinasadya) ang mga produkto, serbisyo, karapatan o ari-arian ng WisePerks.com, iba pang mga miyembro at gumagamit ng WisePerks.com, o sinumang iba pa na maaapektuhan ng anumang ganitong gawain.Maaaring ibunyag o ma-access ng WisePerks.com ang impormasyon ng account kapag naniniwala kami na may mabuting pananampalataya na nangangailangan ito ng batas at para sa administratibo at iba pang mga layunin na itinuturing nating kinakailangan upang mapanatili, mapapakinabangan, at mapabuti ang aming mga produkto at serbisyo.",

  "privacypolicy_topic6" : "Ano ang legal na bases na ginagamit namin para sa pagproseso ng iyong impormasyon?",
  "privacypolicy_topic6ans" : 'Ang WiseWorks ay nakatuon sa Privacy at Proteksyon ng data.Ang aming patakaran sa pagkapribado ay nagpapatupad ng mga prinsipyo ng Regulasyon ng Proteksyon sa Pangkalahatang Data na inisyu ng European Union ("GDPR") bilang isang benchmark na kung saan kami, ang aming mga empleyado at mga supplier ay sumunod.',

  "privacypolicy_topic7" : "Ano ang aking mga pagpipilian tungkol sa pagkolekta, paggamit, at pamamahagi ng aking impormasyon?",
  "privacypolicy_topic7ans" : "Tanging WisePerks.com (o mga kasosyo na nagtatrabaho sa WisePerks.com sa ilalim ng mga kasunduan sa pagiging kompidensyal) ay magpapadala sa iyo ng anumang mga direktang mail, kung ipinahiwatig mo na hindi ka bagay sa mga ito.Mayroon ka ring mga pagpipilian na may paggalang sa kung paano gumagana ang cookies.Sa pamamagitan ng pagpapalit ng mga setting ng iyong internet browser, mayroon kang pagpipilian upang tanggapin ang lahat ng cookies, maabisuhan kapag ang isang cookie ay nakatakda, o upang tanggihan ang lahat ng kahilingan ng cookies.Kung pinili mong tanggihan ang lahat ng mga cookies, hindi mo magagawang gamitin ang mga serbisyong WisePerks.com na nangangailangan ng naunang pagpaparehistro upang makilahok.Ang WisePerks.com ay hindi nagbebenta o umuupa ng anumang impormasyon ng user sa sinuman.Laging ipaalam namin sa iyo sa oras ng pagkolekta o paglipat ng data kung ibabahagi ang iyong data sa anumang third party at palagi kang magkakaroon ng opsyon na huwag pahintulutan itong ibahagi.",

  "privacypolicy_topic8" : "Anong mga pag-iingat sa seguridad ang nakalagay sa WisePerks.com upang protektahan ang anumang pagkawala, maling paggamit, o pagbabago ng impormasyon ng aking account?",
  "privacypolicy_topic8ansp1" : "Ang iyong Impormasyon sa WisePerks.com ay protektado ng password upang ikaw at ikaw lamang ang may access sa impormasyong ito.",
  "privacypolicy_topic8ansp2" : "Lubos naming inirerekumenda na huwag mong ibahagi ang iyong password sa sinuman.Hindi namin hihilingin sa iyo ang iyong password sa isang hindi hinihinging tawag sa telepono o email.Mangyaring mag-sign out sa iyong WisePerks.com account at isara ang window ng iyong browser kapag natapos mo na ang iyong aktibidad.Tinitiyak nito na hindi ma-access ng iba ang iyong personal na impormasyon at account, kung gumagamit ka ng pampublikong computer o ibahagi ang iyong computer sa ibang tao.Sa tuwing pinangangasiwaan ng WisePerks.com ang personal na impormasyon tulad ng inilarawan sa itaas, hindi alintana kung saan ito nangyayari, ang WisePerks.com ay tumatagal ng mga hakbang upang matiyak na ang iyong impormasyon ay ginagamot nang ligtas at alinsunod sa mga may-katuturang Tuntunin ng Serbisyo at Patakaran sa Pagkapribado.Sa kasamaang palad, walang pagpapadala ng data sa Internet ang maaaring garantisadong maging 100% na secure.Bilang resulta, habang sinisikap naming protektahan ang iyong personal na impormasyon, hindi masisiguro o ginagarantiya ng WisePerks.com ang seguridad ng anumang impormasyong iyong ipinapadala sa amin o mula sa aming mga online na produkto o serbisyo, at ginagawa mo ito sa iyong sariling peligro.Sa sandaling natanggap namin ang iyong data, ginagawa namin ang aming pinakamahusay na pagsisikap upang matiyak ang seguridad nito sa aming mga system.",

  "privacypolicy_topic9" : "Ano pa ang dapat kong malaman tungkol sa aking privacy?",
  "privacypolicy_topic9ans" : "Mangyaring tandaan na sa tuwing (at saanman) pipiliin mong ibunyag ang personal na impormasyon sa online - halimbawa sa pamamagitan ng mga email, mga website ng social networking, mga blog, o sa mga chat area - ang impormasyong iyon ay maaaring kolektahin at gamitin ng iba pang user.Kung nag-post ka ng personal na impormasyon sa online na naa-access sa publiko, maaari kang makatanggap ng mga hindi hinihinging mensahe mula sa iba pang mga partido bilang kapalit.Sa huli, ikaw ang tanging may pananagutan sa pagpapanatili ng lihim ng iyong mga password at / o anumang impormasyon sa account.Mangyaring maging maingat at responsable tuwing ikaw ay online.",

  "privacypolicy_topic10" : "Paano pinoprotektahan ng WisePerks.com ang privacy ng aking mga anak?",
  "privacypolicy_topic10ans" : "Ang WisePerks.com ay nakakuha ng naunang pahintulot ng magulang kung saan ang mga survey ay nangangailangan ng mga bata na lumahok at ang pangangasiwa ng magulang ay kinakailangan din para sa anumang naturang mga survey sa mga batang edad na 6-15.Gumagawa kami ng espesyal na dagdag na pangangalaga upang protektahan ang kaligtasan at pagkapribado ng mga kabataan sa aming site, at hinihikayat ka naming lumahok sa karanasan ng iyong anak online.Hindi namin pinahihintulutan ang anumang marketing o sa mga pananaliksik ng mga bata at ang ilang mga paksa ay ganap na ipinagbabawal sa pagsasaliksik sa mga bata: Pangalan / pangalan ng account / E-mail o pisikal na address, Numero ng telepono, Lahi o Lahi, Relihiyon o Cast, Kita ng pamilya, Pamilya sakit , Kalagayan sa pag-aasawa ng mga magulang o iba pang mga miyembro ng pamilya, Mga bagay sa pakikipag-ugnayan, Grado sa paaralan o kolehiyo, Alkohol, Mga Isyu sa Sekswal, Tabako at Mga Baril.",

  "privacypolicy_topic11" : "Espesyal na Paalala sa Mga Lokal na Gumagamit",
  "privacypolicy_topic11ansp1" : "Ang Site ay naka-host sa Estados Unidos.Kung ikaw ay isang User na ma-access ang Site mula sa European Union, Asya, o anumang iba pang rehiyon na may mga batas o regulasyon na namamahala sa pagkolekta, paggamit at pagsisiwalat ng personal na data, na naiiba mula sa mga batas ng Estados Unidos, pakitandaan na inililipat mo ang iyong personal na data sa Estados Unidos na walang parehong mga batas ng proteksyon ng data tulad ng EU at iba pang mga rehiyon, at sa pamamagitan ng pagbibigay ng iyong personal na data na iyong pinahihintulutan na:",
  "privacypolicy_topic11ansp2" : "Ang paggamit ng iyong personal na data para sa mga gamit na tinukoy sa itaas alinsunod sa Patakaran sa Pagkapribado; at ang paglipat ng iyong personal na data sa Estados Unidos tulad ng ipinahiwatig sa itaas.TINATANGGAP MO NA ANG MGA BATAS NG ESTADOS UNIDOS GAMUTIN ANG IYONG IMPORMASYON SA LOOB NG ISANG PARAAN NA MAAARING MAGING kalahatan iba mula sa, AT LESS PROTECTIVE SA, ANG TREATMENT KINAKAILANGAN SA ILALIM NG BATAS NG IBANG BANSA AT MGA HURISDIKSYON.KUNG HINDI MO GUSTO IYONG IMPORMASYON LUMIPAT NG ESTADOS UNIDOS, HINDI MO DAPAT IBAHAGI ANG IMPORMASYON SA US, OR GAWIN PAGGAMIT NG MGA SITE.SA LAWAK NG IPINAHIHINTULOT NG BATAS NG MGA BANSA NA IKAW AY MATATAGPUAN, HAYAGAN MONG talikdan ANUMANG KARAPATAN MO SA nangangailangan US UPANG GAMUTIN ANG IYONG PAGTUKOY SA IMPORMASYON NANG NAAAYON SA BATAS NG ANUMANG COUNTRY O KAPANGYARIHAN MALIBAN SA ESTADOS UNIDOS.  GAANO MAN, ANG NABANGGIT NA WAIVER MAAARING HINDI LEGAL NA IPINATUTUPAD SA ILANG MGA BANSA, GANOONG AS ANG MIYEMBRO STATES ng European Union.SA LAWAK IT AY HINDI LEGAL NA IPINATUTUPAD SA COUNTRY NA IKAW AY MATATAGPUAN, ITO NABANGGIT NA WAIVER AY HINDI ANGKOP SA IYO.",

  "privacypolicy_topic12" : "Mga Pagbabago at Mga Update sa Patakaran sa Privacy na ito",
  "privacypolicy_topic12ans" : "Inilalaan ng WiseWorks ang karapatan, sa aming paghuhusga, upang baguhin, baguhin, idagdag, o alisin ang mga bahagi ng Patakaran sa Pagkapribado sa anumang oras para sa anumang kadahilanan.Sa bawat oras na WiseWorks pagsasanay naturang karapatan, ay kinakailangan mong sadyang tanggapin ang update na Patakaran sa Pagkapribado, at kung hindi mo na gawin ito, hindi ka na ma-access o gamitin ang Site.",

  "privacypolicy_topic13": "Espesyal na Tandaan sa mga naghahanap ng mga detalye ng GDPR / CCPA",
  "privacypolicy_topic13ansp1": "Pakiusap",
  "privacypolicy_topic13ansp2": "pindutin dito",
  "privacypolicy_topic13ansp3": "upang matuto nang higit pa tungkol sa mga detalye ng pagsunod sa GDPR (General Data Protection Regulate) at CCPA (California Consumer Privacy Act).",

  "privacypolicy_addinfo" : "Karagdagang impormasyon",
  "privacypolicy_sentto": "Anumang iba pang mga katanungan tungkol dito ay dapat ipadala sa",


    "privacypolicyext_para1": "MANGYARING BASAHIN MAANGAT ANG PAHAYAG NG PRIVACY NA ITO",
    "privacypolicyext_para2": "Iginagalang ng WiseWorks ang privacy ng bawat gumagamit, at nakatuon kami sa pagiging transparent sa iyo tungkol sa kung paano namin pinangangasiwaan ang personal na data. Ipinapaliwanag ng Patakaran sa Pagkapribado na ito kung paano nangongolekta, nagbabahagi, at gumagamit ang WiseWorks ng personal na data na ibinibigay mo sa amin kapag ginagamit ang WiseWorks Website o kung hindi man nakikipag-ugnay sa amin. Nag-alok din kami ng ilang mga seksyon para malaman mo kung paano mo magagamit ang iyong mga karapatan at pagpipilian sa privacy.",
    "privacypolicyext_para3": "Mangyaring basahin nang mabuti ang Patakaran sa Pagkapribado bago ma-access ang aming Website at gamitin ang Mga Serbisyo sa WiseWorks. Kung mayroon kang anumang mga katanungan, mangyaring makipag-ugnay sa amin sa",
    "privacypolicyext_topic1": "Pangkalahatang-ideya",
    "privacypolicyext_topic2": "Pagtanggap At Paggamit",
    "privacypolicyext_topic3": "Kung sino tayo",
    "privacypolicyext_topic4": "Buod ng Personal na Impormasyon at Paggamit",
    "privacypolicyext_topic5": "Mga Layunin ng Koleksyon ng Data",
    "privacypolicyext_topic6": "Mga Prinsipyo sa Proteksyon ng Data",
    "privacypolicyext_topic7": "Mga Uri ng Data na Kinokolekta Namin Mula sa Iyo",
    "privacypolicyext_topic8": "Mga cookies at web beacon",
    "privacypolicyext_topic9": "Pag-abiso sa Iyo Tungkol sa Mga Survey at Mga Proyekto sa Pananaliksik",
    "privacypolicyext_topic10": "Paano Namin Ginagamit ang Iyong Data",
    "privacypolicyext_topic11": "Sa Kanino Ibinahagi namin ang Iyong Data",
    "privacypolicyext_topic12": "Mga Paksa ng Data ng EEA at Non- EEA",
    "privacypolicyext_topic13": "Ang iyong Mga Karapatan sa Proteksyon ng Data",
    "privacypolicyext_topic14": "Ang aming Patakaran sa Mga Bata",
    "privacypolicyext_topic15": "Seguridad ng data",
    "privacypolicyext_topic16": "Mga Tuntunin at Kundisyon",
    "privacypolicyext_topic17": "Hurisdiksyon",
    "privacypolicyext_topic18": "Mga pagbabago sa aming Patakaran sa Privacy",
    "privacypolicyext_topic19": "Makipag-ugnayan sa amin",

    "privacypolicyext_t1": "kami",
    "privacypolicyext_t2": "tayo",
    "privacypolicyext_t3": "o",
    "privacypolicyext_t4": "ating",

    "privacypolicyext_topic1ansp1": " iginagalang ang mga karapatang pagkapribado ng mga gumagamit nito at nauunawaan ang kahalagahan ng pagprotekta at paghawak ng impormasyong nakolekta tungkol sa iyo sa ilalim ng parehong batas at pinakamahusay na kasanayan. Ang Abiso sa Privacy na ito ay isinulat upang sumunod",
    "privacypolicyext_topic1ansp1_1": "Ang Artikulo 13 at Artikulo 14 ng Pangkalahatang Regulasyon ng Proteksyon ng Data ng EU, ibig sabihin, Regulasyon (EU) 2016/679 ('GDPR'), at ang Mga Gawa sa Privacy ng Consumer ng California na CCPA.",
    "privacypolicyext_topic1ansp2": "Nalalapat ang Abiso sa Privacy na ito sa personal na impormasyon na pinoproseso namin na nauugnay sa mga taong mayroong:",
    "privacypolicyext_topic1ansp2_l1": "Magrehistro para sa isang account sa amin;",
    "privacypolicyext_topic1ansp2_l2": "sumang-ayon na makatanggap ng impormasyon sa marketing mula sa amin;",
    "privacypolicyext_topic1ansp2_l3": "lumahok sa mga survey;",
    "privacypolicyext_topic1ansp2_l4": "makipag-ugnay sa amin para sa mga pagtatanong at suporta",
    "privacypolicyext_topic1ansp3": "Inilalarawan din ng patakaran sa Privacy kung anong mga karapatan ang mayroon ka patungkol sa iyong data, kung paano mo bibigyan ang iyong pahintulot, at kung paano mo maaaring bawiin ang iyong pahintulot. Ang WiseWorks (kasama ang mga kaakibat nito, ang &ldquo;Kumpanya&rdquo;) ay nagmamay-ari at nagpapatakbo ng maraming iba't ibang mga website, mobile app, at mga serbisyong interactive, kabilang ang walang limitasyon sa Wisesample.com, Flapbucks.com, WisePerks.com, at iba pa(sama- sama, ang &ldquo;WiseWorks Site &rdquo;).Ang patakaran sa privacy na ito(&ldquo;Patakaran sa Privacy&rdquo;) ay nalalapat sa Mga WiseWorks Site at lahat ng mga tampok, mobile application, email, mga serbisyong online, at iba pang mga pagpapaandar, na - access sa pamamagitan ng isang computer, mobile device, o kung hindi man, at ang mga serbisyong magagamit sa o sa pamamagitan ng Mga WiseWorks Site at Tampok(ang &ldquo;Mga Serbisyo&rdquo;).Sinasaklaw lamang ng Patakaran sa Privacy na ito ang impormasyong nakolekta ng Kumpanya sa pamamagitan ng Mga WiseWorks Site at Tampok at aming Mga Serbisyo, at hindi sinasaklaw ang anumang impormasyon na natanggap o nakolekta offline ng Kumpanya(maliban kung partikular na sinabi) o nakolekta ng anumang hindi nauugnay na mga website o kumpanya kung saan maaari magbigay ng mga link.",

    "privacypolicyext_topic2ansp1_1": "Saklaw ng Patakaran sa Privacy na ito",
    "privacypolicyext_topic2ansp1_2": " at mga subsidiary nito, kabilang ang",
    "privacypolicyext_topic2ansp1_3": "sama-sama ",
    "privacypolicyext_topic2ansp1_4": "Gamit ang aming website, sinasaad mo ang pagtanggap sa mga tuntunin ng Patakaran sa Privacy na ito, na itinuturing na iyong opisyal na pahintulot na iproseso ang iyong personal na impormasyon, ang iyong pahintulot sa koleksyon, paggamit, at pagsisiwalat ng iyong personal na impormasyon tulad ng inilarawan sa ibaba. Maaari ka naming hilingin na magbigay ng karagdagang mga pagsisiwalat o impormasyon tungkol sa mga kasanayan sa pagpoproseso ng data ng mga partikular na Serbisyo. Ang mga paunawang ito ay maaaring dagdagan o linawin ang aming mga kasanayan sa privacy o magbigay sa iyo ng iba pang mga pagpipilian tungkol sa kung paano namin pinoproseso ang iyong data.",
    "privacypolicyext_topic2ansp2": "Kung hindi ka komportable sa anumang aspeto ng Patakaran sa Privacy na ito. Dapat mong ihinto kaagad ang anumang pag-access sa WiseWorks website at sa aming website.",

    "privacypolicyext_topic3ansp1_1": " ay isang kumpanya na isinama at nakarehistro sa Canada (# 10369675). Ang aming rehistradong tanggapan ay nasa",
    "privacypolicyext_topic3ansp1_2": "Nagbibigay kami ng mga serbisyo sa panel, para sa mga industriya ng pagsasaliksik sa online na merkado. Pinapagana namin ang mga gumagamit o kalahok na nakakatugon sa aming mga kinakailangan sa pagiging karapat-dapat na magparehistro para sa isang account ng panel ng gumagamit at magsasagawa ng mga survey upang makatanggap ng mga puntos o iba pang mga insentibo.",
    "privacypolicyext_topic3ansp2": "Ang aming mga serbisyo ay magagamit sa maraming mga bansa, pati na rin sa California (US), Canada, Alemanya, India, atbp. Ang Serbisyo ay hindi inilaan para magamit ng Mga Gumagamit na wala pang 18 taong gulang; dapat ikaw ay 18 taong gulang at mas matanda upang magamit ang aming website at mga serbisyo.",

    "privacypolicyext_topic4ansp1": "Maaari kaming mangolekta o makakuha ng Personal na Data tungkol sa iyo, at ang impormasyong ito ay maaaring maiimbak o maproseso sa loob ng Rehiyon ng Estados Unidos sa pamamagitan ng mga sumusunod na pamamaraan:",
    "privacypolicyext_topic4ansp1_l1": "Direkta mula sa iyo (hal., Kung saan ka makipag-ugnay sa amin); sa kurso ng aming relasyon sa iyo (hal., kung ginagamit mo ang aming mga serbisyo);",
    "privacypolicyext_topic4ansp1_l2": "kapag binisita mo ang aming website; o gumamit ng anumang bahagi ng website;",
    "privacypolicyext_topic4ansp2": "Maaari rin kaming makatanggap ng Personal na Impormasyon tungkol sa iyo mula sa mga third party.",

    "privacypolicyext_topic5ansp1": "Ang mga sumusunod ay mga halimbawa ng kung paano namin ginagamit ang iyong Personal na Impormasyon:",
    "privacypolicyext_topic5ansp1_l1": "Pagbibigay ng Mga Serbisyo",
    "privacypolicyext_topic5ansp1_l2": "Pag-aalok at Pagpapabuti ng website",
    "privacypolicyext_topic5ansp1_l3": "Mga survey",
    "privacypolicyext_topic5ansp1_l4": "Komunikasyon",
    "privacypolicyext_topic5ansp1_l5": "Pakikipag-ugnayan ng Gumagamit",
    "privacypolicyext_topic5ansp1_l6": "Marketing",
    "privacypolicyext_topic5ansp1_l7": "Pangunahing Henerasyon",
    "privacypolicyext_topic5ansp1_l8": "Pagsunod sa Ligal",

    "privacypolicyext_topic6ansp1": "Kinokolekta namin, pinoproseso, at ginagamit ang personal na data (sa ilalim ng mga kahulugan ng Artikulo 13 at Artikulo 14 ng Pangkalahatang Pagkontrol sa Data ng EU, ibig sabihin, Regulasyon (EU) 2016/679 ('GDPR'), at ang Mga Gawa sa Privacy ng Consumer ng California na CCPA) upang ibigay ang aming mga serbisyo. Ang personal na data ay nangangahulugang anumang impormasyon na nagbibigay-daan sa amin upang makilala ka, direkta o hindi direkta, tulad ng pangalan, email, address, impormasyong pangkalusugan, anumang anyo ng numero ng pagkakakilanlan, o isa o higit pang mga kadahilanan na tukoy sa iyo o pagkakakilanlan ng iyong beneficiary.",
    "privacypolicyext_topic6ansp2": "Nakatuon kaming sumunod sa naaangkop na mga batas sa proteksyon ng data at titiyakin na ang personal na data ay:",
    "privacypolicyext_topic6ansp2_l1": "Ginamit nang ayon sa batas, patas, at malinaw;",
    "privacypolicyext_topic6ansp2_l2": "Nakolekta lamang para sa mga wastong layunin na malinaw na ipinaliwanag namin sa iyo at hindi ginamit sa anumang paraan na hindi tugma sa mga layuning iyon;",
    "privacypolicyext_topic6ansp2_l3": "Nauugnay sa mga layuning sinabi namin sa iyo at limitado lamang sa mga layuning iyon;",
    "privacypolicyext_topic6ansp2_l4": "Tumpak at napapanatiling napapanahon;",
    "privacypolicyext_topic6ansp2_l5": "Patuloy lamang hangga't kinakailangan para sa mga hangaring sinabi namin sa iyo;",

    "privacypolicyext_topic7ansp1": "Maaari naming kolektahin at iproseso ang sumusunod na personal na impormasyon tungkol sa iyo:",
    "privacypolicyext_topic7ansp1_l1title": "Impormasyon sa Personal na Profile.",
    "privacypolicyext_topic7ansp1_l1": "Kung magparehistro ka ng isang account sa amin, kokolektahin namin ang iyong una at apelyido, ang iyong email address, at ang iyong napiling password.",
    "privacypolicyext_topic7ansp1_l2title": "Impormasyon sa Pakikipag-ugnay.",
    "privacypolicyext_topic7ansp1_l2": "Kung pipiliin mong makatanggap ng mga survey sa pamamagitan ng text message o iba pang media ng pagmemensahe kinokolekta rin namin ang iyong numero ng mobile phone o mga kaugnay na detalye sa pakikipag-ugnay.",
    "privacypolicyext_topic7ansp1_l3title": "Data ng Katanungan.",
    "privacypolicyext_topic7ansp1_l3": "Ang paunang profiling questionnaire ay nangongolekta ng impormasyon tungkol sa iyong background, edukasyon, katayuan sa pagtatrabaho, ugali ng consumer, at, opsyonal, ang iyong kalusugan. Nangongolekta kami ng impormasyon tungkol sa anumang mga pag-update na ginawa mo sa iyong impormasyon sa profiling.",
    "privacypolicyext_topic7ansp1_l4title": "Data ng Opinion.",
    "privacypolicyext_topic7ansp1_l4": "Sa mga pagkakataon sa pagsasaliksik, kinokolekta namin ang impormasyong ibinibigay mo na nauugnay sa iyong mga opinyon, ugali, karanasan, at paggamit ng ilang mga produkto o serbisyo. Paminsan-minsan maaari kaming mangolekta ng mga pagsusumite ng video, audio o potograpiyang ginawa mo sa isang pagkakataon sa pananaliksik.",
    "privacypolicyext_topic7ansp1_l5title": "Data ng Karanasan ng Gumagamit.",
    "privacypolicyext_topic7ansp1_l5": "Nangongolekta kami ng impormasyon tungkol sa iyong mga pag-log in, komento sa mga post sa blog, rating, botohan, web page na tiningnan.",
    "privacypolicyext_topic7ansp1_l6title": "Impormasyon sa Mga Gantimpala.",
    "privacypolicyext_topic7ansp1_l6": "Anumang personal na impormasyon na maaaring kailanganin namin upang maisyuhan ka ng isang premyo o gantimpala o subaybayan ang iyong paggamit ng aming mga aktibidad sa Panel at Survey.",
    "privacypolicyext_topic7ansp1_l7title": "Data ng Pagsusulat.",
    "privacypolicyext_topic7ansp1_l7": "Anumang sulat na ipadala mo sa amin.",
    "privacypolicyext_topic7ansp1_l8title": "Data ng Paggamit.",
    "privacypolicyext_topic7ansp1_l8": "Kinokolekta rin namin ang data ng paggamit tungkol sa iyo tuwing nakikipag-ugnay ka sa aming mga serbisyo. Maaaring kasama rito ang mga webpage na iyong binibisita, kung ano ang na-click mo kapag isinagawa mo ang mga pagkilos na iyon, at iba pa. Bilang karagdagan, pinapanatili namin ang mga digital log file na nagtatala ng data sa tuwing isang aparato ina-access ang aming mga server. Naglalaman ang mga file ng log ng data tungkol sa likas na katangian ng bawat pag-access. Kinokolekta namin ang data mula sa aparato at application na ginagamit mo upang ma-access ang aming mga serbisyo, tulad ng iyong IP address, uri ng browser, internet service provider (ISP), pag-refer / exit mga pahina, operating system, stamp ng petsa / oras, at data ng clickstream.",
    "privacypolicyext_topic7ansp2": "Maaari din naming mahihinuha ang iyong lokasyon sa heyograpiya batay sa iyong IP address. Kung nakarating ka sa isang serbisyo ng WiseWorks mula sa isang panlabas na mapagkukunan (tulad ng isang link sa isa pang website, na nilalaman sa isang email o app ng pagmemensahe), maaari naming gamitin ang impormasyong ito, upang pag-aralan ang mga uso, pangasiwaan ang site, subaybayan ang mga gumagamit, at pagtipon ng impormasyong demograpiko tungkol sa aming base ng gumagamit bilang isang buo.",

    "privacypolicyext_topic8ansp1": "Ang cookie ay isang text file na naglalaman ng kaunting impormasyon na ipinadala sa iyong browser kapag bumisita ka sa isang website. Pagkatapos ay ipinadala ang cookie sa pinagmulang website sa bawat kasunod na pagbisita, o sa ibang website na kinikilala ito. Ang cookies ay isang lubhang kapaki-pakinabang na teknolohiya at maraming iba't ibang mga trabaho. Ang isang Web beacon ay isang madalas na transparent na imahe ng graphic, karaniwang hindi mas malaki sa 1 - pixel x 1 pixel, na inilalagay sa isang website o sa isang email na ginagamit upang subaybayan ang pag-uugali ng gumagamit na bumibisita sa website o nagpapadala ng email. Ito ay madalas na ginagamit kasama ng cookies.",
    "privacypolicyext_topic8ansp2": "Maaari kaming mangolekta ng impormasyon sa pamamagitan ng paggamit ng cookies, web beacon, o mga katulad na teknolohiya na hinihimok ng analytics.",
    "privacypolicyext_topic8ansp3_title": "Anong cookies ang ginagamit namin?",
    "privacypolicyext_topic8ansp3": "Ang mga cookies na ginagamit namin ay nasa mga sumusunod na kategorya:",
    "privacypolicyext_topic8ansp3_l1title": "Mahigpit na kinakailangang cookies.",
    "privacypolicyext_topic8ansp3_l1": "Ito ang mga cookies na kinakailangan para sa pagpapatakbo ng aming website. Nagsasama sila, halimbawa, ng mga cookies na nagbibigay-daan sa iyo upang mag-log in sa mga ligtas na lugar ng aming website.",
    "privacypolicyext_topic8ansp3_l2title": "Mga cookies ng pagtukoy / pagganap.",
    "privacypolicyext_topic8ansp3_l2": "Pinapayagan nila kaming kilalanin at bilangin ang bilang ng mga bisita at upang makita kung paano gumalaw ang mga bisita sa paligid ng aming website kapag ginagamit nila ito. Tinutulungan kami nito na mapabuti ang paraan ng paggana ng aming website, halimbawa, sa pamamagitan ng pagtiyak na madaling mahanap ng mga gumagamit ang hinahanap nila.",
    "privacypolicyext_topic8ansp3_l3title": "Mga cookies sa pag-andar.",
    "privacypolicyext_topic8ansp3_l3": "Ginagamit ang mga ito upang makilala ka kapag bumalik ka sa aming website. Nagbibigay-daan ito sa amin na isapersonal ang aming nilalaman para sa iyo, batiin ka sa pangalan, at alalahanin ang iyong mga kagustuhan.",
    "privacypolicyext_topic8ansp3_l4title": "Pag-target ng cookies.",
    "privacypolicyext_topic8ansp3_l4": "Itinatala ng cookies na ito ang iyong pagbisita sa aming website, mga pahinang binisita mo, at mga link na iyong sinundan. Gagamitin namin ang impormasyong ito upang gawing mas nauugnay sa iyong mga interes ang aming website at ang advertising na ipinapakita dito. Maaari rin naming ibahagi ang impormasyong ito sa mga third party para sa hangaring ito.",
    "privacypolicyext_topic8ansp4": "Gumagamit kami ng iba't ibang mga uri ng cookies (kasama ang ilang inilalagay namin at ang iba pa na pinapayagan naming mailagay ang mga third party sa aming site) para sa maraming layunin. Ang impormasyong nakolekta ng cookies ay nagpapahintulot sa amin na subaybayan kung gaano karaming mga tao ang gumagamit ng aming mga serbisyo at kanilang mga pakikipag-ugnayan sa iba pang mga serbisyo, kung gaano karaming mga tao ang nagbubukas ng aming mga email, at para sa kung anong mga layunin ang ginagawa ng mga pagkilos na ito. Ginagamit din ang mga cookies upang mapadali ang isang gumagamit mag-log in, bilang mga pantulong sa nabigasyon, at bilang mga timer ng session, at upang higpitan ang paggamit ng underage ng aming mga tool at serbisyo. Hindi namin na-link ang hindi personal na impormasyon mula sa cookies sa personal na makikilalang impormasyon nang walang pahintulot. Kung sa anumang kadahilanan ayaw mong subaybayan namin ang iyong paggamit ng aming site gamit ang cookies, maaari kang mag-opt-out dito gamit ang iyong mga setting ng browser at maaari mo ring piliin ang opsyong &ldquo;Huwag Subaybayan&rdquo; (para sa karagdagang impormasyon mangyaring sumangguni sa iyong browser ). Karamihan sa mga browser ay nag-aalok ng mga tagubilin sa kung paano i-reset ang browser upang tanggihan ang cookies sa seksyong &ldquo;Tulong&rdquo; ng toolbar.",
    "privacypolicyext_topic8ansp5": "Gumagamit kami ng mga web beacon upang mangolekta ng impormasyon tungkol sa paggamit ng aming mga serbisyo. Ang impormasyong nakolekta ng mga web beacon ay nagbibigay-daan sa amin upang subaybayan kung gaano karaming mga tao ang gumagamit ng aming mga serbisyo, kung gaano karaming mga tao ang nagbubukas ng aming mga e-mail at natutukoy para sa kung anong mga layunin ang ginagawa ng mga pagkilos na ito. Ang aming mga web beacon ay hindi ginagamit upang subaybayan ang aktibidad sa labas ng aming mga serbisyo.",
    "privacypolicyext_topic8ansp6_title": "Paano ko mababago ang aking mga setting ng cookie?",
    "privacypolicyext_topic8ansp6_1": "Pinapayagan ng karamihan sa mga web browser na kontrolin ang karamihan sa mga cookies sa pamamagitan ng mga setting ng browser. Upang malaman ang higit pa tungkol sa cookies, kasama ang kung paano makita kung anong mga cookies ang naitakda, bisitahin ang www.aboutcookies.org o www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "Upang malaman kung paano pamahalaan ang mga cookies sa mga sikat na browser:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "Kapag sumali ka sa WiseWorks maaari kaming makipag-usap sa iyo sa pamamagitan ng email, telepono, o serbisyo sa pagmemensahe (depende sa iyong kagustuhan) tungkol sa iyong pagiging kasapi. Mga okasyon, kapag maaari kaming makipag-usap sa iyo isama ang mga sumusunod:",
    "privacypolicyext_topic9ansp1_l1": "Mga pagkakataon sa pagsasaliksik (tulad ng mga survey, botohan, pokus ng grupo, atbp)",
    "privacypolicyext_topic9ansp1_l2": "Mga resulta sa pagsasaliksik",
    "privacypolicyext_topic9ansp1_l3": "Mga newsletter ng panelist",
    "privacypolicyext_topic9ansp2": "Ang aming mga proyekto ay maaaring tumuon sa isang malawak na hanay ng mga paksa. Karaniwan ka naming anyayahan na lumahok sa mga proyekto sa pamamagitan ng pag-email, sa iyo tungkol sa pagkakataon. Ipapaliwanag namin, sa puntong iyon, ang malawak na paksa ng survey, ang malamang na tagal o kinakailangang kasangkot, at ang gantimpalang inaalok. Nasa sa iyo mismo ang magpasya kung nais mong makilahok o hindi. Ang pakikilahok ay hindi kailanman sapilitan. Maaari kang laging huminto sa anumang oras bagaman mas gusto ka namin na kumpletuhin ang mga gawain sa sandaling sinimulan mo ang mga ito at hindi kami normal na nagbabayad ng anumang mga insentibo para sa mga hindi kumpletong gawain.",
    "privacypolicyext_topic9ansp3": "Inaasahan namin na sa iyo, sa lahat ng oras, magbigay ng totoo, tumpak, kasalukuyang, at kumpletong impormasyon tulad ng hiniling.",


    "privacypolicyext_topic10ansp1": "Gagamitin lamang namin ang iyong personal na data kapag pinapayagan kami ng batas. Karaniwan, gagamitin namin ang iyong personal na data sa mga sumusunod na pangyayari, ngunit para sa karagdagang impormasyon tungkol sa mga uri ng batay sa ayon sa batas na maaasahan namin upang maproseso ang iyong data, mangyaring mag-click dito .:",
    "privacypolicyext_topic10ansp1_l1": "Ibigay ang aming mga produkto at serbisyo sa iyo",
    "privacypolicyext_topic10ansp1_l2": "hanapin ang mga tao na pinakaangkop para sa bawat pagkakataon sa pagsasaliksik;",
    "privacypolicyext_topic10ansp1_l3": "bawasan ang bilang ng mga katanungan na kailangan nating tanungin sa mga survey;",
    "privacypolicyext_topic10ansp1_l4": "magsagawa ng karagdagang pagsusuri sa data ng pagsasaliksik na aming nakolekta; ",
    "privacypolicyext_topic10ansp1_l5": "sa pinagsama-samang form upang matulungan kaming ipaliwanag ang aming mga kakayahan sa pagsasaliksik sa aming mga kliyente.",
    "privacypolicyext_topic10ansp1_l6": "invite you to research opportunities such as surveys, focus groups, online communities, passive monitoring of the websites you visit, etc.",
    "privacypolicyext_topic10ansp1_l7": "anyayahan kang magsaliksik ng mga oportunidad tulad ng mga survey, pokus na pangkat, mga online na komunidad, passive monitoring ng mga website na binibisita mo, atbp.",
    "privacypolicyext_topic10ansp1_l8": "hilingin sa iyo na i-update ang iyong profile,",
    "privacypolicyext_topic10ansp1_l9": "Anyayahan kang lumahok sa mga survey tungkol sa aming mga produkto o serbisyo",
    "privacypolicyext_topic10ansp1_l10": "Subaybayan, tuklasin at maiwasan ang pandaraya, hindi wastong aktibidad at tiyakin ang pagsunod sa patakaran ng WiseWorks",
    "privacypolicyext_topic10ansp1_l11": "Magsagawa ng pagsasaliksik kabilang ang pangalawa at follow - on na pagsasaliksik",
    "privacypolicyext_topic10ansp1_l12": "Suriin kung paano ka at ang iba pang mga gumagamit ay nakikipag-ugnay sa aming mga serbisyong online",
    "privacypolicyext_topic10ansp1_l13": "Protektahan ang WiseWorks at ang aming mga gumagamit",
    "privacypolicyext_topic10ansp1_l14": "Magsagawa ng mga pag-audit na nauugnay sa aming kasalukuyang pakikipag-ugnayan sa iyo",
    "privacypolicyext_topic10ansp1_l15": "I-debug ang site at serbisyo upang makilala at maayos ang mga pagkakamali na nakakapinsala sa umiiral na nilalayong pag-andar",
    "privacypolicyext_topic10ansp1_l16": "Ipagtanggol ang ating sarili sa paglilitis at pagsisiyasat at pag-usig ang mga paglilitis",
    "privacypolicyext_topic10ansp1_l17": "Upang sumunod sa mga obligasyong ligal at pang-regulasyon.",


    "privacypolicyext_topic11ansp1": "Ibinabahagi lamang ng WiseWorks ang iyong personal na impormasyon sa mga third party sa ilalim ng mga pangyayaring inilarawan sa ibaba. Hindi kami nagbebenta o hindi nagsisiwalat ng personal na impormasyon na kinokolekta namin tungkol sa iyo para sa pera o iba pang mahalagang pagsasaalang-alang.",
    "privacypolicyext_topic11ansp1_l1title": "Awtorisadong mga vendor:",
    "privacypolicyext_topic11ansp1_l1": "Maaari naming ibahagi ang iyong data sa mga vendor ng third-party na makakatulong sa amin na mapatakbo ang aming website, maproseso ang mga pagbabayad, at maihatid ang aming mga serbisyo at sumunod din sa iyong mga tagubilin at aming mga obligasyong kontraktwal. Kasama rito ang mga processor ng pagbabayad, mga network ng paghahatid ng nilalaman (CDNs), mga serbisyo sa pagho-host na batay sa cloud, mga serbisyo sa pagsubaybay, mga service provider ng email, kalidad ng katiyakan at pagsubok sa mga vendor, vendor sa pag-iwas sa pandaraya at pag-abuso, customer management management (CRM), vendor",
    "privacypolicyext_topic11ansp1_l2title": "Kasosyo sa Advertising at Analytics:",
    "privacypolicyext_topic11ansp1_l2": "Maaari naming ibahagi ang data ng paggamit sa mga third-party na advertising at provider ng analytics sa pamamagitan ng cookies at iba pang mga katulad na teknolohiya. Ang mga third party ay maaaring mangolekta ng impormasyong ipinadala ng iyong computer, browser, o mobile device bilang tugon sa isang kahilingan para sa nilalaman, tulad ng mga natatanging identifier, iyong IP address, o iba pang impormasyon tungkol sa iyong computer o aparato.",
    "privacypolicyext_topic11ansp1_l3title": "Mga Kasosyo sa Negosyo:",
    "privacypolicyext_topic11ansp1_l3": "Minsan nakikipagsosyo kami sa mga third party upang mag-alok sa iyo ng mga kaganapan, promosyon, paligsahan, at iba pang mga programa. Upang lumahok sa mga alok na ito, maaaring kailanganin kang magbigay ng iyong email address at karagdagang personal na impormasyon. Ibabahagi lamang namin ang iyong email address at iba pang personal na impormasyon sa isang kasosyo sa negosyo kapag malinaw mong pinahintulutan kaming gawin ito.",
    "privacypolicyext_topic11ansp1_l4title": "Ilang mga ligal na obligasyon:",
    "privacypolicyext_topic11ansp1_l4": "Ang WiseWorks ay maaaring mangailangan na ibunyag ang personal na impormasyon kung dinidirekta ng isang korte ng batas o iba pang mga nilalang ng gobyerno. Nang hindi nililimitahan ang naunang, nakalaan sa amin ang karapatang ibunyag ang naturang impormasyon kung saan mayroon kaming mabuting pananampalataya na batayan upang maniwala na ang naturang aksyon ay kinakailangan upang: sumunod sa mga naaangkop na batas, regulasyon, utos ng korte, gobyerno, at mga kahilingan ng mga ahensya ng nagpapatupad ng batas, protektahan at ipagtanggol ang mga karapatan at pag-aari ng WiseWorks o isang third party, o ang kaligtasan ng WiseWorks, ang aming mga gumagamit, ang aming mga empleyado, o iba pa, pinipigilan, nakita, sinisiyasat at gumawa ng mga hakbang laban sa kriminal na aktibidad, pandaraya, at maling paggamit o hindi awtorisadong paggamit ng aming Website at upang ipatupad ang aming Mga Tuntunin sa Paggamit o iba pang mga kasunduan o patakaran, sa lawak na pinapayagan ng batas, susubukan naming bigyan ka ng paunang paunawa bago isiwalat ang iyong impormasyon bilang tugon sa naturang kahilingan.",
    "privacypolicyext_topic11ansp1_l5title": "Sa iyong Pahintulot:",
    "privacypolicyext_topic11ansp1_l5": "Maaaring ipadala ng WiseWorks ang iyong Personal na Impormasyon sa isang third party kapag pumayag kang gawin ito o sa iyong direksyon. Halimbawa, maaaring mangyari ito kapag nakumpleto namin ang isang transaksyon sa iyong kahilingan.",
    "privacypolicyext_topic11ansp1_l6title": "Sa Mga Nagbibigay ng Serbisyo:",
    "privacypolicyext_topic11ansp1_l6": "Maaari kaming magbahagi ng personal na impormasyon sa mga vendor, consultant, processor ng pagbabayad, at iba pang mga service provider na nagbibigay sa amin ng mga tukoy na serbisyo at natatanging impormasyon sa proseso sa aming ngalan. Maaaring kabilang sa mga serbisyong ito ang pagbibigay ng suporta sa customer, pagsasagawa ng pagsusuri sa negosyo at benta, pagsuporta sa pag-andar ng aming website, pinadali ang pagpoproseso ng pagbabayad, pagsuporta sa mga paligsahan, survey, at iba pang mga tampok na inaalok sa aming Website. Hindi pinapayagan ang mga service provider na ito na gamitin ang iyong personal na impormasyon para sa anumang ibang layunin. Ang kanilang paggamit ng naturang personal na data ay sasailalim sa naaangkop na pagiging kompidensiyal at mga hakbang sa seguridad.",
    "privacypolicyext_topic11ansp1_l7title": "Sa mga Kaakibat:",
    "privacypolicyext_topic11ansp1_l7": "Maaari kaming magbahagi ng personal na impormasyon sa ilan sa aming mga kasosyo, kaakibat, at iba pang mga kumpanya sa ilalim ng karaniwang kontrol at pagmamay-ari, napapailalim sa mga tuntunin ng Patakaran sa Privacy na ito. Maaari rin naming ibahagi ang iyong personal na impormasyon na may kaugnayan sa isang malaking transaksyon sa korporasyon, tulad ng isang pagbebenta ng aming negosyo, isang divestiture, pagsasama, pagsasama-sama, o pagbebenta ng asset, sa kaganapan ng pagkalugi, o paghahanda para sa alinman sa mga kaganapang ito. Anumang iba pang nilalang na bumibili sa amin o naging bahagi ng aming negosyo ay may karapatang magpatuloy na gamitin ang iyong personal na impormasyon, ngunit sa paraang itinakda lamang sa Patakaran sa Privacy na ito maliban kung sumang-ayon ka sa iba.",

    "privacypolicyext_topic12ansp1_title": "Mga Legal na Batayan para sa pagpoproseso ng iyong Impormasyon:",
    "privacypolicyext_topic12ansp1": "Para sa mga indibidwal na matatagpuan sa European Economic Area, United Kingdom, o Switzerland (sama-sama na &ldquo;Mga residente ng EEA&rdquo;), ang data na nakolekta mula sa iyo ay maaaring ilipat o maproseso sa Estados Unidos (sama-sama na &ldquo;Mga Paksa ng Data&rdquo;). Kapag nakolekta ang kanilang data, umaasa kami sa mga ligal na base para sa pagproseso ng iyong impormasyon. Pangkalahatan ay pinoproseso lamang namin ang iyong data kung saan kinakailangan namin, kung saan kinakailangan ang pagproseso upang maibigay sa iyo ang aming mga serbisyo (o upang gumawa ng mga hakbang sa iyong kahilingan bago pumasok sa isang kontrata sa iyo).",
    "privacypolicyext_topic12ansp2_title": "Shine the Light (Para sa Mga Mamamayan ng California) CCPA",
    "privacypolicyext_topic12ansp2_1": "Pinapayagan ng batas na &ldquo;Shine the Light&rdquo; ng mga customer ang mga customer sa California na humiling ng mga partikular na detalye tungkol sa kung paano ibinahagi ang ilang mga uri ng kanilang impormasyon sa mga third party at, sa ilang mga kaso, mga kaakibat para sa direktang layunin ng marketing ng mga third party na iyon at mga kaakibat. Sa ilalim ng batas, ang isang negosyo ay dapat magbigay sa mga customer ng California ng ilang impormasyon kapag hiniling o pinapayagan ang mga customer sa California na mag-opt-out sa ganitong uri ng pagbabahagi.",
    "privacypolicyext_topic12ansp2_2": 'Upang maisagawa ang isang kahilingan sa Shine the Light, mangyaring makipag-ugnay sa amin. Dapat mong ilagay ang pahayag na "Iyong Mga Karapatan sa Privacy sa California" sa katawan ng iyong kahilingan, pati na rin ang iyong pangalan, address ng kalye, lungsod, estado, at zip code. Mangyaring magbigay ng sapat na impormasyon upang matukoy kung nalalapat ito sa iyo sa katawan ng iyong kahilingan. Mangyaring tandaan na hindi kami tatanggap ng mga katanungan sa pamamagitan ng telepono, email, at hindi kami responsable para sa mga abiso na hindi nalagyan ng label o naipadala nang maayos o na walang kumpletong impormasyon.',
    "privacypolicyext_topic12ansp3_title": "Ang iyong Mga Karapatan sa Privacy sa Nevada",
    "privacypolicyext_topic12ansp3": 'Kung ikaw ay residente ng Nevada, may karapatan kang mag-opt-out sa pagbebenta ng tukoy na Personal na Data sa mga third party na balak na lisensyahan o ibenta ang Personal na Data na iyon. Maaari mong gamitin ang karapatang ito sa pamamagitan ng pakikipag-ugnay sa amin dito o i-email sa amin ang paksa linya na "Nevada Huwag Magbenta ng Kahilingan" at bibigyan kami ng iyong pangalan at email address na nauugnay sa iyong account.',

    "privacypolicyext_topic13ansp1": "Pinapayagan ka naming gumawa ng maraming mga pagpipilian tungkol sa iyong data: Nasa ibaba ang sumusunod na mga karapatan sa proteksyon ng data na mayroon ka kapag ginamit mo ang aming mga serbisyo.",
    "privacypolicyext_topic13ansp1_l1": "Maaari kang pumili na hindi ibigay sa amin ang ilang mga impormasyon.",
    "privacypolicyext_topic13ansp1_l2": "Ipagpalagay na nais mong i-access, iwasto, i-update o humiling ng pagtanggal ng iyong personal na impormasyon. Sa kasong iyon, magagawa mo ito anumang oras sa pamamagitan ng pakikipag-ugnay sa amin sa pamamagitan ng portal ng mga serbisyo ng customer o sa impormasyong ibinigay sa ilalim ng Patakaran sa Privacy na ito.",
    "privacypolicyext_topic13ansp1_l3": "Kung humiling ka ng pag-access sa iyong data, kumpirmahin namin kung hinahawakan namin ang iyong impormasyon at bibigyan ka ng isang kopya, na nagpapaliwanag kung paano namin ito pinoproseso at kung bakit, gaano kami katagal iniimbak, at ang iyong mga karapatang nauugnay dito.",
    "privacypolicyext_topic13ansp1_l4": "Kung hiniling mo ang pagtanggal ng iyong personal na impormasyon, tatanggalin namin ito. Mangyaring tandaan na kakailanganin naming panatilihin ang anumang impormasyon na kinakailangan namin upang matupad ang aming mga ligal na obligasyon o magtatag, mag-ehersisyo, o ipagtanggol ang isang ligal na paghahabol.",
    "privacypolicyext_topic13ansp1_l5": "Maaari mong tutulan ang iyong personal na pagproseso ng impormasyon, hilingin sa amin na higpitan ang pagproseso ng iyong personal na impormasyon, o humiling ng kakayahang dalhin ang iyong personal na impormasyon. Muli, maaari mong gamitin ang mga karapatang ito sa pamamagitan ng pakikipag-ugnay sa amin sa pamamagitan ng portal ng mga serbisyo ng customer o sa impormasyong ibinigay sa ilalim ng Patakaran sa Privacy na ito.",
    "privacypolicyext_topic13ansp1_l6": 'May karapatan kang mag-opt-out sa mga komunikasyon sa pagmemerkado sa email na ipinadala namin sa iyo anumang oras. Maaari mong gamitin ang karapatang ito sa pamamagitan ng pag-click sa link na "mag-unsubscribe" o "mag-opt-out" sa mga email sa marketing na ipinadala namin sa iyo.',
    "privacypolicyext_topic13ansp1_l7": "Pag-opt Out sa Mga Mensahe mula sa Amin: Upang mag-opt-out sa anumang mga pampromosyong mensahe mula sa amin, dapat kang mag-unsubscribe sa katawan ng mensaheng pang-promosyon na ipinadala sa iyo (matatagpuan sa ilalim ng email) o magpadala ng isang kahilingan sa pag-unsubscribe sa amin. Iproseso namin ang iyong kahilingan sa loob ng isang makatuwirang oras pagkatapos ng pagtanggap.",
    "privacypolicyext_topic13ansp1_l8": "Katulad nito, kung nakolekta at naproseso namin ang iyong personal na impormasyon sa iyong pahintulot, maaari mong bawiin ang iyong pahintulot sa anumang oras. Ang pag-alis ng iyong pahintulot ay hindi makakaapekto sa pagiging ligal ng anumang pagproseso na isinagawa namin bago ang iyong pag-atras, o makakaapekto ito sa pagpoproseso ng iyong personal na impormasyon na isinagawa bilang pag-asa sa ayon sa ayon sa batas na pagproseso bukod sa pahintulot.",
    "privacypolicyext_topic13ansp1_l9": "May karapatan kang magreklamo sa isang awtoridad sa proteksyon ng data tungkol sa aming koleksyon at paggamit ng iyong personal na impormasyon. Mangyaring makipag-ugnay sa amin para sa karagdagang impormasyon, at padadalhan ka namin ng isang link o ang address ng aming opisyal ng proteksyon ng data.",
    "privacypolicyext_topic13ansp1_l10": "Ang pinakamadaling paraan upang isumite ang iyong kahilingan sa amin ay sa pamamagitan ng aming email ng suporta sa Customer na matatagpuan sa ilalim ng Patakaran sa Privacy na ito. Tumutugon kami sa lahat ng mga kahilingan na natatanggap namin mula sa mga indibidwal na nagnanais na gamitin ang kanilang mga karapatan sa proteksyon ng data sa ilalim ng naaangkop na mga batas sa proteksyon ng data.",

    "privacypolicyext_topic14ansp1": "Ang website at mga serbisyo ay hindi nakadirekta sa mga batang wala pang labing tatlong (18) taong gulang. Sumusunod ang WiseWorks sa Batas sa Proteksyon ng Privacy sa Bata ng Online (COPPA). Hindi namin sinasadya na humiling upang mangolekta ng personal na impormasyon mula sa sinumang tao na wala pang edad na 18. Mangyaring ipagbigay-alam sa amin kung alam mo ang anumang mga indibidwal na wala pang 18 taong gulang na gumagamit ng aming website upang maiwasan ang pag-access nila sa aming Mga Serbisyo.",

    "privacypolicyext_topic15ansp1": "Nakatuon kami sa pagpapanatili ng seguridad ng iyong data, pagkakaroon ng mga hakbang upang maprotektahan laban sa pagkawala, maling paggamit, at pagbabago ng data sa ilalim ng aming kontrol. Gumagamit kami ng mga moderno at ligtas na diskarte upang protektahan ang aming mga system mula sa panghihimasok ng mga hindi pinahihintulutang indibidwal, at regular naming ina-upgrade ang aming seguridad habang magagamit ang mas mahusay na mga pamamaraan. Gumagamit ang aming mga data center at aming kasosyo ng mga bagong hakbang sa seguridad para mapigilan ang hindi awtorisadong pag-access sa pasilidad.",
    "privacypolicyext_topic15ansp2": "Mangyaring magkaroon ng kamalayan na walang mga hakbang sa seguridad na perpekto o impenetrable. Samakatuwid, kahit na gumagamit kami ng pamantayan sa pamantayan sa industriya upang protektahan ang iyong privacy, hindi namin (at hindi) ginagarantiyahan ang ganap na seguridad ng personal na data.",


    "privacypolicyext_topic16ansp1": "Upang matuto nang higit pa tungkol sa aming mga tuntunin at kundisyon, mangyaring bisitahin ang aming pahina ng Mga Tuntunin ng Serbisyo, na nagtatakda ng paggamit, disclaimer, at mga limitasyon ng pananagutan na namamahala sa paggamit ng aming website.",

    "privacypolicyext_topic17ansp1": "Ang batas ng Estados Unidos ng Amerika ang namamahala sa patakaran sa privacy na ito at mga tuntunin ng serbisyo at iba pang mga kaugnay na serbisyo.",

    "privacypolicyext_topic18ansp1": "Patuloy na nagsisikap ang WiseWorks na magpatupad ng mga bagong teknolohiya at proseso upang maprotektahan ka nang mas mahusay, ang iyong privacy, at ang iyong paggamit ng serbisyo ng WiseWorks. Bilang isang resulta, ang mga pagbabago sa patakaran sa privacy na ito ay maaaring gawin ng WiseWorks paminsan-minsan. Sa anumang kaganapan ay hindi magreresulta ang mga pagbabagong ito sa pagkasira ng anumang mga hakbang sa seguridad na idinisenyo upang protektahan ka mula sa hindi awtorisadong pagbubunyag.",

    "privacypolicyext_topic19ansp1": "Kung mayroon kang anumang mga katanungan tungkol sa patakaran sa privacy na ito, mangyaring makipag-ugnay sa amin sa ",




  "faq_faq" : "Mga Madalas Itanong",
  "faq_getans" : "Kumuha ng mga sagot sa lahat ng iyong mga katanungan.",

  "faq_ques1" : "Ano ang WisePerks?",
  "faq_ans1" : "Natutuwa kami na tinanong mo.Ang WisePerks ay isang online na survey na site.Maraming mga tatak at kumpanya ang naghahanap para sa mga respondent upang magbigay ng kanilang mga opinyon at makatanggap ng mga gantimpala sa pagbabalik.Ang WisePerks ay isa sa mga ganitong platform kung saan maaari mong bigyan ang iyong opinyon at kumita ng mga gantimpala para dito masyadong.",

  "faq_ques2" : "Malaya ba itong sumali?",
  "faq_ans2" : "Oo, libre ito upang sumali.Sa katunayan, kung ang isang tao ay humihiling sa iyo ng isang pagsali fee, nais namin na iulat mo ito sa amin.",

  "faq_ques3" : "Maaari ko bang i-refer ang aking mga kaibigan?",
  "faq_ans3" : 'Oo, maaari mong anyayahan ang iyong mga kaibigan at kasamahan na sumali at makakuha ng gantimpala para dito. Paki-click ang link na "Sumangguni sa Friend" sa iyong dashboard upang makuha ang lahat ng mga detalye.',

  "faq_ques4" : "Ano ang aking kikitain?",
  "faq_ans4" : "Ngayon sa ating bagong modelo, maaari kang kumita puntos sa maraming mga paraan maliban lamang sa pamamagitan ng pagkuha ng mga survey. Kumuha ka ng mga puntos: nagrerehistro sa atin, sa pamamagitan ng pag-verify ng iyong email account, para makumpleto ang iyong profile, siyempre para sa pagkumpleto ng anumang survey at sa iyong mga sorpresa kahit para sa isang survey na disqualified. Kaya mayroon tayong lahat ang dahilan upang makapagsimula ka di ba?",

  "faq_ques5" : "Paano ako magiging miyembro ng WisePerks.com?",
  "faq_ans5" : 'Buksan ang WisePerks.com sa iyong browser.Lumikha ng pagiging miyembro ng pag-login sa pamamagitan ng pag-click sa tab na "Mag-sign Up".Sundin lamang ang mga hakbang at gagabayan ka nito sa kabuuan ng proseso.Kailangan mong kumpirmahin ang iyong layunin sa pagsali sa pamamagitan ng pag-click sa confirmation e-mail na ipinadala namin sa iyong email na ibinigay.',

  "faq_ques6" : "Nasaan na ang nauugnay na e-mail na nabanggit mo?",
  "faq_ans6p1" : "Isang kumpirmasyon e-mail ay ipinadala awtomatikong sa email address na ibinigay mo kapag pag-sign up. Kung hindi mo makita ang email sa iyong inbox, mangyaring suriin ang iyong spam o junk na folder. Upang maiwasan ang anumang hinaharap na email mula sa amin at ang survey inaanyayahan/mensahe mula sa maabot ang iyong spam folder, mangyaring idagdag",
  "faq_ans6p2" : "listahan ng iyong inaprubahang Tagapagpadala o bilang isang kontak sa iyong mailbox. Kung hindi pa rin mo makikita ang confirmation email, mangyaring mag-login sa My Account, kumpirmahin na tama ang iyong email address sa amin at humiling ng isa pang confirmation email.",

  "faq_ques7" : "Paano ako magsasagawa ng isang survey?",
  "faq_ans7" : 'Kapag kayo ay naka-log pagpasok ang iyong UserName at Password, maabot ang iyong Dashboard mo at makikita mo ang lahat ng mga survey. Ipadala din natin ang mga link ng survey sa iyong rehistradong email ID. Mo ang link na ito nang direkta mula sa iyong mailbox.',

  "faq_ques8" : "Paano kumpidensyal ang aking mga tugon, ang aking data?",
  "faq_ans8" : "Para sa WisePerks, ang pagpapanatili ng privacy at pagiging kompidensiyal ng user ay pinakamahalaga.Ang aming mga kliyente ay interesado lang sa kung ano ang iyong iniisip, bilang isang mamimili, o kung ano ang nararamdaman mo tungkol sa isang bagay.Namin sa WisePerks, hindi kailanman nagbabahagi / pumasa sa anumang personal na impormasyon sa aming mga kliyente, at wala sa mga survey na WisePerks ay hindi rin nagbebenta o umupa ng anumang impormasyon sa mga third party.",

  "faq_ques9" : "Gaano katagal ang mga survey na ito anyways?",
  "faq_ans9" : "Karamihan sa mga survey ay tumatagal lamang ng mga 10 hanggang 15 minuto, o kahit na mas maliit.Gayunpaman, sa pana-panahon, maaari tayong magkaroon ng mas mahabang survey.Sa bawat survey, sasabihin namin sa iyo nang maaga kung gaano katagal ito, ngunit dapat tandaan, ang mga mas maraming survey ay may mas mataas na gantimpala.",

  "faq_ques10" : "Maaari bang kumuha ng sinuman ang mga survey ng WisePerks?",
  "faq_ans10" : "Dapat kang maging 13 taon o mas matanda upang magrehistro sa WisePerks.Kahit na paminsan-minsan ay inaanyayahan ka namin sa mga survey upang makilahok sa mas batang miyembro ng iyong pamilya.",

  "faq_ques11" : "Paano mo nalalaman na kwalipikado ako para sa isang survey?",
  "faq_ans11" : "Ang aming algorithm ay tumutugma sa iyong impormasyon sa profile sa mga survey na magagamit upang matiyak na nakakuha ka ng pinaka-angkop na mga survey.Kapag nagsimula ang isang pagsisiyasat, kadalasan ang unang ilang mga katanungan na matukoy kung ikaw ang tumutugon ang aming kliyente ay naghahanap ng partikular na survey.Mayroon kaming malawak na hanay ng mga survey na naghahanap para sa maraming iba't ibang uri ng mga tao (hal., Mga taong regular na nanonood ng mga pelikula, maglaro ng partikular na isport, mga kababaihan na may mga bata, atbp.) At mga bagong survey na nanggagaling araw-araw!",

  "faq_ques12" : "Ano ang aking mga obligasyon sa WisePerks?",
  "faq_ans12" : "Ang tanging bagay na inaasahan namin mula sa iyo ay upang bigyan ang iyong mga opinyon at mga salo-salo matapat, bilang mga kliyente ay naghahanap para sa iyong tapat at tunay na mga sagot upang mapabuti sa kanilang mga produkto at serbisyo.Mangyaring magbigay ng tamang impormasyon kapag nag-sign up bilang isang miyembro at i-update ang anumang mga pagbabago habang nagaganap ito.",

  "faq_ques13" : "Bakit ako dapat magbigay ng impormasyon sa aking sambahayan, teknolohiya o produkto na aking binibili?",
  "faq_ans13" : "Para sa bawat survey, hinahanap namin ang isang profile ng mga respondent.Kung alam namin mula sa iyong impormasyon sa profile na ang iyong impormasyon ay tumutugma sa mga kinakailangan ng kliyente, bingo!Maaari tayong matiyak na matagumpay mong makumpleto ang survey at kumita ng gantimpala para dito.Ito ay karaniwang nagdaragdag ng iyong mga pagkakataon na mapili para sa isang survey.",

  "faq_ques14" : "Kailan ko matatanggap ang aking mga Gift Card matapos makumpleto ang isang survey na WisePerks?",
  "faq_ans14" : "Ang mga puntos na kumita ka matapos ang matagumpay na pagkumpleto ng survey na ito ay naipon sa iyong timba. Sa sandaling ang mga puntos ay natanto mo kayang tubusin.",

  "faq_ques15" : "Ano talaga ang Aking Account?",
  "faq_ans15" : "Ito ay isang madaling paraan para makita mo ang iyong personal na impormasyon, gumawa ng anumang mga pagbabago kung kinakailangan, tingnan ang iyong aktibidad sa survey, mga natanggap na gantimpala, lahat ng bagay sa isang lugar.",

  "faq_ques16" : "Paano ko kakanselahin ang aking account?",
  "faq_ans16" : "May kaya magkano ang pagdating ng iyong paraan, tayo ay tiyak na hindi ninyo nais na gawin ito. Gayunman, sa iyong Dashboard--> pumunta sa mga setting ng--> pamahalaan account--> Account na I-deactivate ang",

  "faq_ques17" : "Paano gumagana ang mga puntos na sistema?",
  "faq_ans17p1" : "Ang bawat survey ay para sa tiyak na bilang ng mga puntos. Pagkatapos ng matagumpay na pagkumpleto ng isang survey ang mga puntong ito ay idinagdag sa iyong mga kita. Maaari mong tubusin ang mga ito kapag naabot mo ang minimum na threshold.",
  "faq_ans17p2" : "Sa ilang mga kaso dahil sa ilang mga hindi inaasahang mga hamon sa koneksyon o pagbagsak, ang iyong tugon ay maaaring hindi makuha sa pagtatapos ng aming kliyente. Pagkatapos ay kailangan nating baligtarin ang mga puntong iyon mula sa iyong account. Kung natubos mo na ang mga puntong iyon at ang iyong account ay may mababang balanse kung ang iyong mga kita ay magiging negatibo. Ang mga pagsasaayos na ito ay gagawin sa iyong susunod na ikot ng pagtubos.",

  "faq_ques18" : "Gaano katagal na ang mga puntos para sa isang survey ay idinagdag sa balanse ng aking mga punto?",
  "faq_ans18" : "Ang iyong mga puntos ay idaragdag sa balanse ng iyong punto sa loob ng 48 oras.",

  "faq_ques19" : "Ano ang minimum threshold para sa pagtubos?",
  "faq_ans19" : "Ang minimum threshold para sa pagtubos ay 25000 puntos.Sa karaniwan, kakailanganin mong kumpletuhin ang 8-12 survey upang maging karapat-dapat para sa pagtubos.",

  "faq_ques20" : " Paano makakakuha ng mga puntos sa cash na Gift Card? Mayroon bang ratio?",
  "faq_ans20" : "Oo. Depende sa iyong bansa, ang mga punto ay makapag-convert sa cash ng Gift Card. Ang mga rati ng conversion ay tulad ng ipinapakita sa ibaba",
  "faq_ponits" : "Mga puntos",
  "faq_point" : "Punto",
  "faq_cent" : "Cent",

  "faq_ques21" : "Mayroon pa bang higit pang mga tanong?",
  "faq_ans21p1" : "Huwag mag-atubiling email sa amin sa",
  "faq_ans21p2" : "at kami ay bumalik sa iyo sa loob ng 48 oras.",

  "Profiling_profiledashboard" : "Dashboard ng profile", 
  "Profiling_completeprofile" : "Makumpleto ang iyong profile at makuha ang America",
  "Profiling_completepoints" : "mga puntos!",
  "Profiling_answercarefully" : "Ang sumusunod na pangkalahatang set ng mga tanong ay ginamit upang lumikha ng iyong sariling profile upang magbigay sa iyo ng pinakamahusay na mga pagkakataon sa survey na hinaharap. Mangyaring dalhin ang iyong oras, sagutin ang maingat at tapat.",

  "Profiling_cat_general" : "Pangkalahatang",
  "Profiling_cat_personal" : "Personal",
  "Profiling_cat_household" : "Sambahayan",
  "Profiling_cat_profession" : "Propesyon",

  "Profiling_bar_completed" : "% Na natapos",
  
  "Profiling_update_ans_button" : "I-update at Magpatuloy",
  "Profiling_save_ans_button" : "Maliban kung", 
  "Profiling_sc_ans_button" : "I-save & patuloy", 

  "Category_thanku_msg1" : "Mabuting mga pagpunta",
  "Category_thanku_msg2" : "ilan pang at ikaw ay magkakaroon ng",
  "Category_thanku_msg3" : "mga puntos sa iyong account",

  "Category_thanku_update_msg1" : "Salamat para sa mga pag-update ng inyong impormasyon!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
   "Category_Continue_button" : "Magpatuloy",

  "OverAll_thanku_msg1" : "Salamat para sa pagbabahagi ng iyong impormasyon. Inyong mga kinitang",
  "OverAll_thanku_msg2" : "mga punto.", 

  "Survey_history_surveyhistory" : "Kasaysayan ng survey",
  "Survey_history_fromthisscreen" : "Mula sa screen na ito, maaari mong makita ang lahat ng iyong mga transaksyon.",
  "Survey_history_show" : "Ipakita ang",
  "Survey_history_entries" : "mga entry",
  "Survey_history_date" : "Petsa",
  "Survey_history_details" : "Mga detalye",
  "Survey_history_surveys" : "Mga sarbey",
  "Survey_history_pointsreceived" : "Ang mga puntos na natanggap",
  "Survey_history_result" : "Resulta",
  "Survey_history_status" : "Katayuan ng",
  "Survey_history_status_complete" : "Kumpletuhin ang",
  "Survey_history_status_disqualified" : "Hindi karapat-dapat ang", 
  "Survey_history_keyword" : "Keyword",
  "Survey_history_search" : "Paghahanap",
  "Survey_history_to" : "upang",
  "Survey_history_selectoption" : "Pumili ng mga opsiyon",
  "Survey_history_earned" : "Kinita",
  "Survey_history_redeemed" : "Tinubos",
  "Survey_history_norecord" : "Walang tala na natagpuan",
  "Survey_history_Surveypts" : "Mga punto sa survey",
  "Survey_history_Promocode" : "Promo Code",

  "My_transactions_mytransactions" : "Aking mga transaksyon",
  "My_transactions_fromthisscreen" : "Mula sa screen na ito, maaari mong makita ang lahat ng iyong mga transaksyon.",
  "My_transactions_show" : "Ipakita ang",
  "My_transactions_entries" : "mga entry",
  "My_transactions_transactionid" : "Transaksyon Id",
  "My_transactions_date" : "Petsa",
  "My_transactions_details" : "Mga detalye",
  "My_transactions_points" : "Mga punto",
  "My_transactions_status" : "Katayuan ng",
  "My_transactions_keyword" : "Keyword",
  "My_transactions_search" : "Paghahanap",
  "My_transactions_to" : "upang",
  "My_transactions_selectoption" : "Pumili ng mga opsiyon",
  "My_transactions_status_earned" : "Kinita",
  "My_transactions_status_redeemed" : "Tinubos",
  "My_transactions_status_pending" : "Nakabinbin",
  "My_transactions_status_reverted" : "Ang bumalik sa pananatili",
  "My_transactions_status_declined" : "Tinanggihan",
  "My_transactions_norecord" : "Walang tala na natagpuan",
  "My_transactions_off_msg1" : "Pagkatapos ",
  "My_transactions_off_msg2" : ", ang lahat ng iyong mga detalye sa transaksyon ay mai-publish sa tab ng Mga puntos sa libro",

  "Passbook_heading" : "Mga Book Book",
  "Passbook_show" : "Ipakita",
  "Passbook_entries" : "mga entry",
  "Passbook_search" : "Paghahanap",
  "Passbook_type" : "Uri",
  "Passbook_surveyid" : "Surveys",
  "Passbook_date" : "Petsa",
  "Passbook_selectoption" : "Piliin ang Opsyon",
  "Passbook_credit" : "Kredito",
  "Passbook_debit" : "Utang",
  "Passbook_survey_id" : "Id ng Survey",
  "Passbook_survey_pts" : "Mga Punto ng Survey",
  "Passbook_earned_pts" : "Mga Punto",
  "Passbook_pending_pts" : "Mga Pending puntos",
  "Passbook_promo_code" : "Promo code",
  "Passbook_transaction_type" : "Uri ng transaksyon",
  "Passbook_balance" : "Balanse",
  "Passbook_status" : "Katayuan",
  "Passbook_type_soi" : "Pagrehistro",
  "Passbook_type_doi" : "Pag-verify ng Email",
  "Passbook_type_profile" : "Pagkumpleto ng Profile",
  "Passbook_type_survey" : "Survey",
  "Passbook_type_doir" : "Pag-verify ng Email Ni",
  "Passbook_type_doirt" : "Pag-verify ng Email Sa pamamagitan ng Referral",
  "Passbook_type_surveyr" : "Pagsubok sa Survey Ni",
  "Passbook_type_surveyrt" : "Pagsubok sa Survey Sa pamamagitan ng Referral",
  "Passbook_type_reward" : "Gantimpala",
  "Passbook_type_pending" : "Pagsasaayos",
  "Passbook_type_reverted" : "Mga puntos na Binaligtad",
  "Passbook_type_declined" : "Ang mga kahilingan ay Natukoy",
  "Passbook_type_redeem" : "Tubusin",
  "Passbook_type_balance" : "Ipagpatuloy",
  "Passbook_balance_vary" : "Ang aktwal na balanse ay maaaring magkakaiba",
  "Passbook_survey_debit_reason" : "Pinahahalagahan namin ang iyong mga pagsisikap. Gayunpaman dahil sa ilang mga hindi mapigilan na mga patak ng koneksyon habang ang paglipat ng data ay hindi maaaring nakarehistro ang iyong tugon. Kailangan naming baligtarin ang mga puntong natanggap mo nang mas maaga para sa survey na ito. Ang paghingi ng pasensya sa sanhi ng abala.",

  "Redeem_redeemed" : "Tubusin",
  "Redeem_redeemhistory" : "Tubusin ang kasaysayan",
  "Redeem_history_norecord" : "Walang tala na natagpuan",
  "Redeem_date" : "Petsa",
  "Redeem_details" : "Mga detalye",
  "Redeem_points" : "Mga punto",
  "Redeem_status" : "Katayuan ng",
  "Redeem_lookslike" : "Kamukha mo pinalampas na nagpapatunay ng iyong email account.",
  "Redeem_hurry" : "Magmadali! makakakuha ka ng 1000 points sa sandaling mapatunayan mo.",
  "Redeem_pleasecheck" : "Pakitiyak ang iyong inbox at i-click ang link sa confirmation email na ipinadala upang i-activate ang iyong account.",
  "Redeem_kindlycheck" : "Magiliw na suriin ang iyong Spam/Junk folder na rin para sa confirmation email.",
  "Redeem_getconfirmationemail" : "Hindi makakuha ng confirmation email na pa?",
  "Redeem_resendconfirmationemail" : "Ipadalang muli ang confirmation email",
  "Redeem_rewardredemptionrule" : "Patakaran ng pagtubos ng gantimpala",
  "Redeem_completesurvey" : "Kumpletuhin ang Survey",
  "Redeem_earnpoints" : "Kumita ng mga puntos",
  "Redeem_redeemrewards" : "Tubusin ang gantimpala",
  "Redeem_note" : "Paalala:",
  "Redeem_yourearnedpoints" : "Iyong puntos na kinita ay magiging mapapalitang pagkatapos ng pagiging marapat ang survey na ito. Upang tubusin ang mga gantimpala, dapat ang minimum mapapalitang puntos sa iyong account",
  "Redeem__points" : "mga puntos.",
  "Redeem_availablebalance" : "Available na balanse:",
  "Redeem_historydetails" : "Mag-click dito upang makita ang iyong mga detalye sa kasaysayan ng katubusan.", 
  "Redeem_reqsucc" : "Maligayang Bati! Natubos na ang mga iyong mga punto. Mangyaring suriin ang iyong rehistradong mailbox para sa mga karagdagang detalye.", 
  "Redeem_wisecheck_youhave" : "Binabati kita! Ikaw ay isang pag-click lamang mula sa iyong gantimpala.",
  "Redeem_wisecheck_gcagainst" : "Gift Card para sa",
  "Redeem_wisecheck_points" : "mga puntos.",
  "Redeem_wisecheck_issue" : "Sistema ay nakunan ng ilang di-kilalang na aktibidad sa iyong account, dahil sa na kung saan ikaw ay hindi upang magpadala ng isang kahilingan sa redeem. Mangyaring makipag-ugnay sa",
  "Redeem_emailverify_issue" : "Upang makuha ang iyong mga puntos, hihilingin namin sa iyo na i-verify ang iyong rehistradong email account.",

  "Redeem_unsubscribe_issue" : "Hindi ka makapagpadala ng kahilingan sa pag-redeem, dahil nag-unsubscribe ka mula sa WisePerks. Mangyaring makipag-ugnay sa support@wiseperks.com upang mag-subscribe muli.",
  "Redeem_wisecheck_message1" : "Hindi mo matutubos dahil sa ilang mga kadahilanang pang-seguridad. Mangyaring makipag-ugnay sa suporta sa",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Kumpirmahin",
  "Redeem_Cancel_button" : "Kanselahin",
  "Redeem_pending_hello" : "Kamusta",
  "Redeem_pending" : "Pagsasaayos",
  "Redeem_pending_msg1" : "Ang iyong account ay nagpapakita ng pagsasaayos ng",
  "Redeem_pending_msg2" : "puntos na isasagawa. Mangyaring kumpirmahin upang simulan ang transaksyon.",

  "Dashboard_wisecheck_issue" : "Nakuha ng system ang ilang hindi nakikilalang aktibidad sa iyong account, dahil hindi mo makita ang mga survey. Mangyaring makipag-ugnay sa support@wiseperks.com para sa karagdagang paglilinaw.",
  "Dashboard_unsubscribe_issue" : "Hindi mo makita ang anumang mga survey, dahil nag-unsubscribe ka mula sa WisePerks. Mangyaring makipag-ugnay sa support@wiseperks.com upang makatanggap muli ng mga survey.",


  "WC_DashboardMsg_1" : "Oops! Walang mga survey upang tumugma sa iyong profile sa ngayon.",
  "WC_DashboardSubMsg_1" : "Huwag mag-alala, sinasadya namin ang aming imbentaryo at malapit nang magpadala ng mga bagong survey sa iyong paraan.",

  "WC_DashboardMsg_2" : "Nire-refresh namin ang iyong Dashboard. Mangyaring suriin ang mga bagong survey pagkatapos ng ilang sandali.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Salamat sa pagkumpleto ng survey. Nakuha mo lang ang response_points mga puntos. Mag-login sa iyong Dashboard para tingnan ang iyong kabuuang naipong puntos.",
  "Survey_success_msg2" : "Salamat sa pagkumpleto ng survey. Ikaw lang ang kinita response_points mga punto.",

  "Survey_success_but_nomore_survey" : "Isang pagbati! Ikaw lang ang kinita response_points puntos para kumpletuhin ang survey. Mangyaring suriin para sa mga darating na survey matapos ang isang araw.",
  
  "Survey_terminate_pnt_msg1" : "Mukhang tulad ng ilan sa iyong impormasyon ay bahagyang naiiba mula sa kinakailangan. Pahalagahan ang iyong oras at pagsisikap natin, ay maging pagdaragdag response_points mga puntos sa iyong account. Kami ay nakatuon sa nagdadala sa iyo ng mas malaki at madaling maunawaan survey.",
  
  "Survey_terminate_nopnt_msg1" : "Mukhang ang ilan sa iyong mga sagot ay bahagyang naiiba mula sa kinakailangan. Magpapadala kami ng higit pang mga survey sa iyong paraan. Salamat sa iyong oras at pagsisikap.",


"TermsConditions_t&c" : "Mga tuntunin & kundisyon",
  "TermsConditions_WhenRegister" : "Kapag nagrehistro ka",
  "TermsConditions_WhenYouCreate" : "Kapag lumikha ka ng isang personal na account, hinihiling namin sa iyo ang ilang personal na impormasyon, tulad ng nabanggit dito:",
  "TermsConditions_YouCanUpdate" : "Maaari mong i-update o baguhin ang iyong pangunahing impormasyon sa profile sa anumang oras.",
  "TermsConditions_YouCanOptOut" : "Maaari kang mag-opt out o isara ang iyong account sa anumang oras.",
  "TermsConditions_WeRespectYour" : "Nirerespeto namin ang iyong privacy at ginagawang pinakamahusay na paggamit ng teknolohiya upang ligtas na bantayan ang iyong impormasyon mula sa hindi awtorisadong pisikal at electronic na pag-access.",
  "TermsConditions_WeShareYour" : "Ibinahagi namin ang iyong impormasyon lamang sa mga organisasyon, na kinakailangan upang gumana WiseWorks. At kailangan ng mga organisasyong iyon na alagaan ang aming mga pangako sa iyong privacy at seguridad.",
  "TermsConditions_WhatInformation" : "Anong impormasyon ang kinukuha namin mula sa iyo",
  "TermsConditions_DirectlyCollected" : "Direktang nakolekta",
  "TermsConditions_EmailAddressAndPassword" : "Ang email address at password ng iyong account sa amin.",
  "TermsConditions_YourResponse" : "Ang iyong tugon sa mga tanong sa profile.",
  "TermsConditions_AnyCustomer" : "Anumang sulat sa serbisyo ng customer.",
  "TermsConditions_AnyPersonalDetails" : "Anumang mga personal na detalye na iyong ibinabahagi para sa pagtubos",
  "TermsConditions_AnyOtherInformation" : "Anumang iba pang impormasyon na direktang ibinibigay mo.",
  "TermsConditions_UsageData" : "Paggamit ng data",
  "TermsConditions_YourIPAddress" : "Ang iyong IP address, geo na lokasyon at cookies",
  "TermsConditions_InformationAboutTheDevice" : "Impormasyon tungkol sa device na iyong ginagamit",
  "TermsConditions_YourSurveyActivity" : "Ang iyong aktibidad sa pagsisiyasat, tumuturo sa iyong account, ang mga pagbabayad na natanggap mo pagkatapos ng pagtubos.",
  "TermsConditions_HowDoWeUse" : "Paano namin magagamit ang iyong data",
  "TermsConditions_SendingYouInvitation" : "Nagpapadala sa iyo ng mga email ng imbitasyon upang kunin ang survey na iyong kwalipikado.",
  "TermsConditions_DetectAndPrevent" : "Makita at maiwasan ang mapanlinlang na aktibidad.",
  "TermsConditions_ImproveYourExperience" : "Pagbutihin ang iyong karanasan sa platform ng WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Ang iyong mga tugon ay inililipat sa aming mga kaakibat na mga third party at kasosyo sa isang pinagsama-samang at de-nakilala na format.",
  "TermsConditions_OurPartnersCanAsk" : "Maaaring hilingin sa aming mga kasosyo sa iyo na ibigay ang iyong pangalan, email address at iba pang impormasyon sa pakikipag-ugnay sa panahon ng isang survey.",
  "TermsConditions_YouAreFreeToDecline" : "Malaya kang tanggihan ang pagbibigay ng iyong mga detalye sa aming mga kasosyo, kung sumasang-ayon ka kaysa sa direktang ibinibigay mo ang impormasyong ito sa kanila at hindi sa WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Ang iyong impormasyon ay makikitungo alinsunod sa may-katuturang Patakaran sa Pagkapribado sa negosyo at hindi sa Patakaran sa Privacy ng WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Nakatuon ang aming mga kasosyo upang igalang at protektahan ang iyong privacy.",
  "TermsConditions_WhatWeDont" : "Ano ang hindi natin ginagawa dito",
  "TermsConditions_WeDontSellOnThat" : "Hindi kami nagbebenta sa impormasyong iyon sa sinuman, at hindi namin ginagamit ito upang magbenta ng anumang bagay sa iyo. Lagi kaming hihingi ng permiso bago ibahagi ang iyong data.",
  "TermsConditions_YourRightToControl" : "Ang iyong karapatang kontrolin ang iyong data",
  "TermsConditions_YouHaveFullControl" : "Mayroon kang ganap na kontrol sa iyong data.",
  "TermsConditions_YouCanAccess" : "Maaari mong i-access, i-update o palitan ang iyong pangunahing impormasyon sa profile kahit kailan mo gusto.",
  "TermsConditions_YouCanUnsubscribe" : "Maaari kang mag-unsubscribe at mag-deactivate ng iyong account.",
  "TermsConditions_YouAreFreeToGetYour" : "Malaya kang alisin ang iyong profile sa pamamagitan ng pagpapadala sa amin ng isang mail sa support@wiseperks.com. Susubukan naming malutas ang iyong pag-aalala at kung nais mo pa ring alisin ang iyong account, pagkatapos ay gagawin mo ito sa loob ng 72 oras.",
  "TermsConditions_DataSecurity" : "Seguridad ng data",
  "TermsConditions_OurTechTeamWorks" : "Gumagana ang aming tech team na napakahirap upang mapanatiling ligtas ang lahat ng iyong data.",
  "TermsConditions_WeMakeUseOfLatest" : "Ginagamit namin ang pinakabagong teknolohiya at mga panukala upang maiwasan ang anumang maling paggamit o hindi awtorisadong pag-access ng iyong data. Gayunpaman, dahil sa likas na katangian ng online na kapaligiran hindi namin maaaring magbigay ng 100% katiyakan ng seguridad ng data.",
  "TermsConditions_WeTryOurBestToRule" : "Sinusubukan namin ang aming makakaya upang mamuno sa mga panganib, gayunpaman ay hindi magagarantiyahan ang panganib ng libreng kapaligiran. Sa pagtanggap ng mga Tuntunin at Kundisyon na ito, kinikilala at tinatanggap mo ang mga panganib na ito.",
  "TermsConditions_AnyQuestions" : "Anumang mga katanungan, mangyaring huwag mag-atubiling makipag-ugnay sa amin",
  "TermsConditions_IfYouHaveAnyQueries" : "Kung mayroon kang anumang mga query o reklamo tungkol sa aming patakaran sa privacy at mga pamamaraan. Mangyaring magpadala sa amin ng email sa support@wiseperks.com at babalik kami sa iyo sa loob ng 48 oras.",

  "Validation_PleaseEnterProperDate" : "Mangyaring Ipasok ang tamang petsa ng kapanganakan",
  "Validation_PleaseSelectOne" : "Mangyaring Pumili ng isang opsyon!",
  "Validation_PleaseSelectValidOptions" : "Mangyaring Piliin ang Mga Wastong Opsyon!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Mangyaring Pumili ng hindi bababa sa Isang Pagpipilian",
  "Validation_PleaseInsertTheZip" : "Mangyaring Ipasok ang Zip / Postal Code.",

  "Releases_releases" : "Release",
  "Releases_theUseOfThis" : "Ang paggamit ng website na ito ay sasailalim sa sumusunod na mga tuntunin sa paggamit.",
  "Releases_featuresOfWP" : "Ang mga tampok ng WP i-release",
  "Releases_updatedOn" : "Nai-update sa",
  "Releases_WisePerksNow" : "WisePerks suportado na ngayon ng iba 't ibang wika",
  "Releases_youCanWalkThourgh" : "Maari kang maglakad ang thourgh ang site na ito nang mas madali ngayon bilang WisePerks ay makukuha sa inyong preffered wika.",
  "Releases_languagesAvailable" : "Magagamit na wika:",
  "Releases_EarnMore" : "Kumita ng higit pa sa ating bagong point system",
  "Releases_theNewWisePerksAccumulates" : "Ang bagong WisePerks accumulates puntos sa iyong account. Ang mga puntos ay hold sa isang mas mataas na halaga at madaling kumita at tubusin. May maraming tubusin ang mga opsyon upang pumili mula sa, tayo ay tiyak na magiging masaya ang mga survey na pagkuha ng mga karanasan.",
  "Releases_accumulatePoints" : "Makaipon ng mga puntos sa hassel libreng redeemption",
  "Releases_youGetTheBenefit" : "Kang makakuha ng mga benepisyo ng pagkamit ng gayon ding walang. sa puntong ito, anuman ang kanilang mga pera mong tubusin sila sa.",
  "Releases_surveyDisqualification" : "Survey diskuwalipikasyon ay walang pa isang masamang balita",
  "Releases_youEarnPoints" : "Kumita ka ng mga puntos kahit na hindi ka karapat-dapat para sa mga survey, mo simulan ang pagsagot.",
  "Releases_newAndImproved" : "Bago at pinahusay na layout",
  "Releases_theFreshLookOf" : "Gumawa ka mahulog sa pag-ibig sa ito ang sariwang hitsura ng WisePerks. Ngayon ay mas madali upang gamitin ang mga ito at magiging isang napakagandang karanasan para sa inyo.",
  "Releases_whatAvatarAreYouToday" : "Ano ang mga ka ngayon ng Avatar",
  "Releases_youCanNowCreate" : "Makalilikha kayo ngayon ang sarili mong avatar na itatampok sa iyong dashboard.",
  "Releases_freshAndHotSurveys" : "Sariwa at mainit na survey sa iyong dashboard",
  "Releases_youDontHaveTo" : "Hindi mo kailangang mag-alala tungkol sa mga refresing ang iyong dashboard para makita sa bagong survey, susundin natin ang iyong dashboard mataong sa pinakabagong survey awtomatikong.",
  "Releases_promoCode" : "Tampok na Code ng Promo",
  "Releases_promoCodeInfo" : "Sa sikat na pangangailangan, ang WisePerks ay nagdudulot ng Mga Kodigo ng Promo para sa mga panelist nito bawat linggo. Mabilis na paraan upang makakuha ng higit pang mga gantimpala.",
  "Releases_referFriend" : "Sumangguni sa Kaibigan",
  "Releases_referFriendInfo" : "Kunin ang iyong mga kaibigan na nauugnay sa WisePerks at grab kapana-panabik na premyo. Mag-ingat sa pagpipiliang Refer na Friend sa iyong account.",

  "Dashboard_Promocode_Usepromo" : "Gamitin ang Promo Code",
  "Dashboard_Promocode_to" : "upang",
  "Dashboard_Promocode_earnminimum_1" : "kumita ng minimum",
  "Dashboard_Promocode_earnminimum_2" : "dagdag na puntos",
  "Dashboard_Promocode_minimum" : "minimum",
  "Dashboard_Promocode_minimumextrapoints" : "dagdag na puntos sa bawat survey na nakumpleto ngayon",
  "Dashboard_Promocode_apply" : "Ilapat",
  "Dashboard_Promocode_message1" : "Hindi wasto ang Promo Code!",
  "Dashboard_Promocode_message2" : "Binabati kita! Makakakuha ka ng minimum",
  "Dashboard_Promocode_message3" : "dagdag sa bawat survey na nakumpleto ngayon!",

  "Dashboard_modal_Enjoythefestive" : "Tangkilikin ang maligaya Espiritu sa pagkakamit ng minimum",
  "Dashboard_modal_Extrapointson" : "ng dagdag na mga punto sa bawat survey na kumpletuhin mo ngayon.",
	
  "refer_friend_title" : "Sumangguni sa isang Kaibigan at Makakuha ng Gantimpala",
  "refer_friend_step_first": "Anyayahan ang isang kaibigan sa WisePerks",
  "refer_friend_step_second": "Matagumpay na nag-sign up sa Kaibigan sa WisePerks",
  "refer_friend_step_third": "Makakakuha ka ng each_referral puntos",
  "refer_friend_link_title": "Mag-imbita ng isang kaibigan ngayon!",
  "refer_friend_share_title": "Ibahagi ang link",
  "refer_friend_guideline_title": 'Makakatulong sa iyo ang "sumangguni sa isang kaibigan":',
  "refer_friend_guideline_one": "Pakibahagi ang link na ibinigay sa itaas gamit ang iyong mga referral.",
  "refer_friend_guideline_two": "Maaari kang sumangguni ng maraming tao hangga't gusto mo, sa sandaling 5 ng iyong mga referral ay nagpapatunay sa kanilang account at magsimula ng aktibidad sa pagsisiyasat, idaragdag sa iyong account ang iyong nakuha na mga referral point.",
  "refer_friend_guideline_three": "Makakakuha ka ng each_referral puntos bawat isa para sa matagumpay (na may na-verify na email account at aktibidad ng pagsisiyasat) na referral.",
  "refer_friend_guideline_four": "Ang mga puntos ay makukuha sa dalawang yugto, partial_referral sa pag-verify ng email at partial_second_referral sa aktibidad ng pagsuri sa pamamagitan ng iyong pagsangguni.",
  "refer_friend_guideline_five": "Pagkatapos ng 5 matagumpay (na may na-verify na email account at aktibidad ng pagsisiyasat) mga referral, ang link ng referral ay makakakuha ng kapansanan.",

  }
  
