import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PasswordValidation } from '../shared/password-validation';
import { HomeService } from '../shared/home.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CommonJsFunc from '../../../../assets/js/common/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form : FormGroup;
  responseMsg : string;
  decode_email: string;
  decode_auth: number;
  PanelistEmailAdd:string;
  showResetPassLoader:boolean = true;
  public buttonloading: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private _homeservice: HomeService,
    private title: Title
  ) { }

  ngOnInit() {
    this.title.setTitle('WisePerks | Reset password');

    let getemail = this.activatedRoute.snapshot.queryParams.email;
    let getauth = this.activatedRoute.snapshot.queryParams.auth;
    
    if(getemail != null || getauth != null){
      
      let check_correct_email = CommonJsFunc.decode64(getemail);
      let check_correct_auth = CommonJsFunc.decode64(getauth);
      
      this.PanelistEmailAdd = check_correct_email;

      if((check_correct_email != null && check_correct_email != "") && (check_correct_auth !=null && check_correct_auth != "")){
        this.form = this.formBuilder.group({
          password : [null,[Validators.required, Validators.minLength(6),Validators.maxLength(15)]],
          c_password : [null,[Validators.required, Validators.minLength(6),Validators.maxLength(15)]],
          email: [null],
          auth : [null]
        },{
          validator: PasswordValidation.MatchPassword // your validation method
        });
      } else {
        this.router.navigate(['']);
        this.toastr.clear();
        this.toastr.error("You have not a authenticate panelists","WisePerks");
        return false;
      }

    } else {
      this.router.navigate(['']);
      this.toastr.clear();
      this.toastr.error("You have not a authenticate panelists","WisePerks");
      return false;
    }
  }


  /* Validation Start Here */
  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }


  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);     
      //console.log(control);        
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }
  /* Validation End Here */

  onSubmit(){
    this.buttonloading = true;
    if(this.form.valid){
      let email_decode = CommonJsFunc.decode64(this.activatedRoute.snapshot.queryParams.email);
      let auth_decode = CommonJsFunc.decode64(this.activatedRoute.snapshot.queryParams.auth);

      this.form.setValue({email: email_decode,
       auth: auth_decode, password: this.form.value.password, c_password: this.form.value.c_password});
      
      this._homeservice.resetPassword(this.form.value)
      .subscribe((resp:any)=>{ 
        if(resp.auth == true && resp.success == true){
          this.router.navigate(['']);
          this.toastr.clear();
          this.toastr.success(resp.message,'WisePerks');
          this.buttonloading = false;
        }
      },(err:any)=>{
        this.buttonloading = false;
        this.showNewFlashError(err,"WisePerks");
      });
    } else {
      this.buttonloading = false;
      this.validateAllFormFields(this.form);
    }
  }

  StopPageLoading(getflag){
    if(getflag == 1){
      this.showResetPassLoader = false;
    }
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }
}
