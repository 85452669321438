import { Component, OnInit } from '@angular/core';
import { PanelistService } from '../../shared/panelist.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import * as myLangGlobal from '../../shared/globals';
import { trigger,state, style, animate, transition } from '@angular/animations';
import {NgForm} from '@angular/forms';
import {IMyDpOptions , IMyDateModel} from 'mydatepicker';

@Component({
  selector: 'app-passbook',
  templateUrl: './passbook.component.html',
  styleUrls: ['./passbook.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({opacity: 1, transform: 'translateX(0)'})),
      transition('void => *', [
        style({
          opacity: 1,
          transform: 'translateX(100%)'
        }),
        animate('0.2s 0.1s ease-in')
      ]),
      transition('* => void', [
        animate('0.2s 0.1s ease-out', style({
          opacity: 1,
          transform: 'translateX(100%)'
        }))
      ])
    ])
  ]
})
export class PassbookComponent implements OnInit {

	showPassbookLoader:boolean = true;
	GetLeftSideCompJson:boolean = false;
	GetPassbookData:boolean = false;

  MainBalance:Number = 0;   // blance on 1 Sept 2019

  passbook_live_date:string = myLangGlobal.PassbookLiveDate;
  BalanceRowshow:boolean = false;

	current_login_panelist = "";

	passbookTransactions = {
	    "transactionData": "",
	    "totalPage": 0,
	    "totalData": 0
	};

	currentPage: any = 1; // set current page to 1
	public itemsPerPage: any = 20; // we are showing 5 items per page
	totalPage;

	tableData:boolean = true;
	NoRecordData:boolean = false;

	PaginationShow:boolean = true;
  show_search_form: boolean = false;
  statusData: string = "";
  qloadstart:boolean;

  startDate;
  endDate;
  errorMsg:string = "";
  is_last_page:Number = 0;

  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'yyyy-mm-dd',
    disableUntil: {year: 0, month: 0, day: 0},
    disableSince: {year: (new Date()).getFullYear(), month: ((new Date()).getMonth()+1), day: ((new Date()).getDate()+1)},
    editableDateField: false,
    openSelectorOnInputClick:true
  };

  GlobalSearchStartDate = "";
  GlobalSearchEndDate = "";
  GlobalSearchStatus = "";
  GlobalSearchKeyword = "";

  constructor( private _panelistService: PanelistService, private toastr: ToastrService, private title: Title ) { }

  ngOnInit() {
  	this.title.setTitle('WisePerks | Respondent Passbook');
  	this.getPassbookData();

    let resp = this._panelistService.GetGlobalPanelistInfo();

    this.BalanceRowshow = this.ShowBalanceRow();

    this.MainBalance = (resp.data.balance != null ? resp.data.balance : 0);
    this.disableUntil();  //set disable back dates
  }

  ShowBalanceRow(){
    let resp = this._panelistService.GetGlobalPanelistInfo();

    let register_date = (resp.data.created_at).substring(0,10);

    var g1 = new Date(register_date);
    var g2 = new Date(this.passbook_live_date);


    if(g1.getTime() < g2.getTime())
      return true;
    else if(g1.getTime() > g2.getTime())
      return false;
  }
  
  getPassbookData(page = 1, perPage = 20) {
  	this.currentPage = page;
  	
  	if (localStorage.getItem('currentLoginPanelist') === null) {} else {
    	this.current_login_panelist = localStorage.getItem('currentLoginPanelist');
   	}


   	this._panelistService.getPanelistPassbookStatus(this.current_login_panelist, page, perPage, this.GlobalSearchStatus, this.GlobalSearchStartDate, this.GlobalSearchEndDate, this.GlobalSearchKeyword).subscribe((get_resp: any) => {
    // console.log("resp : ", get_resp);

   		if(get_resp.auth == true && get_resp.success == true){

   			var totalTransactions = get_resp.count;
   			this.totalPage = Math.ceil(totalTransactions / perPage);

        //console.log('page 1 : ', page);
        //console.log('total Page 1 : ', this.totalPage);

        if(get_resp.count > 0) {
          if(page == this.totalPage){
            this.is_last_page = 1;
          } else {
            this.is_last_page = 0;
          }
        } else {
          this.is_last_page = 1;
        }

   			if(get_resp.count > 0){

   				this.passbookTransactions = {
   					"transactionData": get_resp.data,
				    "totalPage": this.totalPage,
				    "totalData": get_resp.count
   				};
   				this.itemsPerPage = perPage;
   				this.NoRecordData = false;
   				this.tableData = true;
   				this.PaginationShow = true;

   			} else {
          let defaultArr:any = [];
          this.passbookTransactions = {
              "transactionData": defaultArr,
              "totalPage": 1,
              "totalData": 0
            };

          this.BalanceRowshow = this.ShowBalanceRow();
          
          if(this.BalanceRowshow == true){
            this.tableData = true;
            this.PaginationShow = false;
            this.NoRecordData = false;    
          } else {
            this.tableData = false;
            this.PaginationShow = false;
            this.NoRecordData = true;
          }
        }

   			this.GetPassbookData = true;
   			this.StopPageLoader({});
   		}

   	},(error: any) => {
   		this.showNewFlashError(error,"WisePerks");
   	});

  }


  getSearchData(form: NgForm) {

    var status = form.value.status;
    var startDate = form.value.startDate;
    var endDate = form.value.endDate;
    var keyword = form.value.keyword;
    var page = 1;
    var perPage = 20;

    this.GlobalSearchStartDate = startDate;
    this.GlobalSearchEndDate = endDate;
    this.GlobalSearchStatus = status;
    this.GlobalSearchKeyword = keyword;

    if( Object.keys(status).length == 0  && startDate == undefined && endDate == undefined && keyword == ""){
      this.getPassbookData();
    }else{
      this.qloadstart = true;
      this.currentPage = page;

      if (localStorage.getItem('currentLoginPanelist') === null) {
        // redirect to home component
      } else {
        this.current_login_panelist = localStorage.getItem('currentLoginPanelist');
      }
      
      this._panelistService.getPanelistPassbookStatus(this.current_login_panelist, page, perPage, status, startDate, endDate, keyword).subscribe((get_passbook_resp: any) => {
        if(get_passbook_resp.auth == true && get_passbook_resp.success == true){

          if(get_passbook_resp.count > 0){

            var totalSurveys = get_passbook_resp.count;
            this.totalPage = Math.ceil(totalSurveys / perPage);
            
            if(page == this.totalPage){
              this.is_last_page = 1;
            } else {
              this.is_last_page = 0;
            }

            this.passbookTransactions = {
              "transactionData": get_passbook_resp.data,
              "totalPage": this.totalPage,
              "totalData": get_passbook_resp.count
            };

            this.BalanceRowshow = this.ShowBalanceRow();
            
            if(this.BalanceRowshow == true && (status =='d' || keyword != "")){
              this.BalanceRowshow = false;
            }

            this.itemsPerPage = perPage; 
            this.qloadstart = false;
            this.NoRecordData = false;
            this.tableData = true;
            this.PaginationShow = true;
          } else {
            this.qloadstart = false;

            let defaultArr:any = [];
            this.passbookTransactions = {
              "transactionData": defaultArr,
              "totalPage": 1,
              "totalData": 0
            };

            this.BalanceRowshow = this.ShowBalanceRow();

            if(this.BalanceRowshow == true){
              this.is_last_page = 1;
              this.tableData = true;
              this.PaginationShow = false;
              this.NoRecordData = false;    
            } else {
              this.is_last_page = 0;
              this.tableData = false;
              this.PaginationShow = false;
              this.NoRecordData = true;
            }
          }
        }
      },(err : any) => {

      });

    }
  };

  StopPageLoader(getJson){

    if(getJson['comp'] == 'leftbar' && getJson['flag'] == 1){
      this.GetLeftSideCompJson = true;
    }

    if(this.GetLeftSideCompJson == true && this.GetPassbookData == true){
      this.showPassbookLoader = false;
    }

  }

  SearchAnimation_Show_Hide(getevent){
    //console.log(getevent);
    //console.log(this.show_search_form);
    if(this.show_search_form == true && getevent == 'hide'){
      this.show_search_form = false;
    }
  }


  onDateChangedStart(event: IMyDateModel) {
    if(this.endDate != undefined){
    if (this.endDate.epoc >= event.epoc) {
     this.errorMsg = "";
     return true;
    } else {
     this.startDate = "";
     this.errorMsg = "From date must be less than to date.";
    }
    }else{
      this.errorMsg = "";
     return true;
    }
  }


  onDateChangedEnd(event: IMyDateModel) {
   if (this.startDate.epoc <= event.epoc) {
    this.errorMsg = "";
    return true;
   } else {
    this.endDate = "";
    this.errorMsg = "To date must be greater than from date.";
   }
  }

  /* Below Code is find the disableUntil value, start here*/

  // Calling this function set disableUntil value until yesterday
    disableUntil() {
        let launch_date = new Date(this.passbook_live_date);
        launch_date.setDate(launch_date.getDate() - 1);

        let lauch_one_date_before = this.convertDate(launch_date);
        
        let split_date = lauch_one_date_before.split('-');
        
        let copy = this.getCopyOfOptions();
        copy.disableUntil = {year: parseInt(split_date[0]), 
                             month: parseInt(split_date[1]), 
                             day: parseInt(split_date[2])};
        this.myDatePickerOptions = copy;
    }

    // Returns copy of myOptions
    getCopyOfOptions(): IMyDpOptions {
        return JSON.parse(JSON.stringify(this.myDatePickerOptions));
    }

    convertDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }

  /* Above Code is find the disableUntil value, end here*/  

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}
