import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  showFAQLoader:boolean = true;
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle('WisePerks | FAQ');
  }

  StopLoading(flag){
    if(flag == 1){
      this.showFAQLoader = false;
    }
  }

}
