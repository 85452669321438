// French
export const TRANSLATIONS_FR = {
  "home_header_selectlang": "Choisir la langue",
  "home_header_loginbutton": "S'identifier",
  "home_header_signupbutton": "S'inscrire",
  "home_header_homebutton": "Accueil", 
  
  "home_register_bannertxt1": "Voici votre",
  "home_register_bannertxt2": "pensées",
  "home_register_bannertxt3": "peut vous chercher",
  "home_register_bannertxt4": "récompense$",
  "home_register_bannertxt5": "Obtenez un bonus",
  "home_register_bannertxt6": "Sur inscription",
  "home_register_fbsignup": "INSCRIVEZ-VOUS AVEC FACEBOOK",
  "home_register_fblogin": "SE CONNECTER AVEC FACEBOOK",
  "home_register_or": "OU",
  "home_register_emailsignup": "S'enregistrer avec l'adresse e-mail",
  "home_register_emaillogin": "Connexion avec Email",
  "home_register_fpass": "Mot de passe oublié?",
  "home_register_fname": "Prénom",
  "home_register_lname": "Nom de famille",
  "home_register_email": "Adresse e-mail",
  "home_register_pass": "Mot de passe",
  "home_register_cpass": "Confirmez le mot de passe",
  "home_register_tcpp": "En vous joignant, vous acceptez notre",
  "home_register_tcpptandcond": "termes et conditions",
  "home_register_tcppand": "et",
  "home_register_tcppprivacy": "Politique de confidentialité",
  "home_register_join": "Rejoignez-nous. C'est gratuit",
  "home_register_login": "S'identifier",
  "home_register_name_required" : "Le nom est requis.",
  "home_register_email_required" : "Le courriel est requis",
  "home_register_invalid_email" : "Format d'email invalide",
  "home_register_password_required" : "Mot de passe requis.",
  "home_register_password_lower_digit" : "Le mot de passe doit être 6 chiffres est requis",
  "home_register_password_higher_digit" : "Le mot de passe doit être utilisé. 15 chiffres sont requis",
  "home_register_cpassword_required" : "Confirmer le mot de passe est requis",
  "home_register_cpassword_lower_digit" : "Confirmer le mot de passe doit être 6 chiffres requis",
  "home_register_cpassword_higher_digit" : "Confirmer le mot de passe doit être utilisé 15 chiffres requis",
  "home_register_cpassword_not_match" : "Confirmer le mot de passe non",

  "home_register_fp_not_worry" : "Veuillez entrer votre emailid enregistré.",   
  "home_register_fp_resetpass_msg" : "Pour réinitialiser votre mot de passe, veuillez entrer votre identifiant d'email enregistré.", 
  "home_register_fp_enter_email_msg" : "Entrez votre email ici", 
  "home_register_fp_submit_button" : "Envoyer", 
  "home_register_fp_temporary_sent_pass_msg" : "Un lien réinitialiser le mot de passe a été envoyé à votre ID email enregistré. Veuillez mettre à jour votre mot de passe au plus tôt.", 
  
  "home_works_howitworks": "Comment ça marche?",
  "home_works_howitworkst1": "Présentez-vous", 
  "home_works_howitworksd1": "Devenez membre, inscrivez-vous",
  "home_works_howitworkst2": "Recevoir des sondages",
  "home_works_howitworksd2": "Commencez à recevoir des enquêtes intuitives et simples",
  "home_works_howitworkst3": "Gagnez des points",
  "home_works_howitworksd3": "Donnez vos meilleures réponses et accumulez des points",
  "home_works_howitworkst4": "Racheter",
  "home_works_howitworksd4": "Échangez vos points contre de nombreuses récompenses intéressantes",
  
  "home_aboutus_whoweare": "Qui nous sommes?",
  "home_aboutus_whowearep1": "Chez WisePerks, nous travaillons dur pour nous assurer que le temps passé en ligne est une expérience enrichissante. Nous travaillons avec des centaines d'entreprises désireuses d'avoir vos commentaires, de savoir ce que vous pensez de leurs produits ou services. Vous donnez votre avis sur différents services, mais aucun de ces services ne vous récompense réellement pour votre temps, contrairement à WisePerks - où chaque sondage auquel vous participez vous donne d'excellentes récompenses en argent pour votre temps.",
  "home_aboutus_whowearep2": "Nous apprécions vos commentaires et voulons que vous soyez récompensé pour votre temps. Nous avons des enquêtes sur différents sujets pour correspondre à vos intérêts et les rendre faciles à répondre. Vous pouvez facilement faire environ 50 $ - 100 $ chaque mois en prenant diverses enquêtes. Alors travaillons ensemble pour y arriver.",
  
  "home_earn_more_surabt": "Quels sondages demandent-ils?",
  "home_earn_more_bp": "Experts en affaires",
  "home_earn_more_consumers": "Consommateur",
  "home_earn_more_hc": "Santé et fitness",
  "home_earn_more_entertainment": "Divertissant et amusant",
  "home_earn_more_gaming": "Monstres de jeu",
  "home_earn_more_tech": "Tech savvy",
  "home_earn_more_bonus_survey": "Sondage bonus", 
  
  "home_reward_whatuearn": "Que gagnez-vous?",
  "home_reward_whatuearnp1": "Nous aimons garder les choses simples. Vous obtenez des points pour tous les sondages complétés avec succès. Plus vous complétez d'enquêtes, plus vous gagnez de points. Les points peuvent être échangés contre des cartes-cadeaux de votre choix après avoir franchi un certain seuil.",
  "home_reward_whatuearnp2": "Une fois prêt à utiliser, choisissez l'option que vous aimez - Amazon, Starbucks, iTunes et bien d'autres. Nous donnons également des bonus et remercions Dieu de ses offres de vendredi de temps en temps.",
  
  "home_counter_earnedover": "Les membres ont gagné plus de",
  
  "home_contact_getintouch": "Entrer en contact",
  "home_contact_emailus": "Pour toute question, s'il vous plaît écrivez-nous à",
  
  "home_footer_faq": "FAQ",
  "home_footer_pp": "Politique de confidentialité",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Fonctionnalités à venir", 
  "home_footer_copyright": "Copyright © current_year WisePerks. Tous les droits sont réservés.",

  "home_header_home": "Accueil", 
  "home_footer_toknowmore": "En savoir plus",
  "home_footer_createacc": "Créez un compte gratuitement et rejoignez nos milliers de membres heureux.",
  "home_footer_registernow": "Inscrire maintenant",

  "home_reset_password" : "Réinitialiser le mot de passe", 
  "home_reset_pass_content" : "Vous avez demandé de réinitialiser le mot de passe pour", 

  "dashboard_sidebar_selectavatar" : "Sélectionnez votre avatar", 
  "dashboard_sidebar_uploadavatar" : "Cliquez ici pour télécharger votre image",
  "dashboard_sidebar_pbank" : "Banque de points",
  "dashboard_sidebar_accumulated" : "Gagné", 
  "dashboard_sidebar_realized" : "Réalisé", 
  "dashboard_sidebar_pending" : "Ajustements", 
  "dashboard_sidebar_earned_tooltip" : "Points que vous avez collectés jusqu'à présent.",
  "dashboard_sidebar_pending_tooltip" : "Points à inverser à partir de votre compte.",
  "dashboard_sidebar_main" : "Principale",
  "dashboard_sidebar_dashboard" : "Tableau de bord",
  "dashboard_sidebar_profile" : "Profil",
  "dashboard_sidebar_updateprofile" : "Mettre à jour le profil",
  "dashboard_sidebar_shistory" : "Historique de l'enquête",
  "dashboard_sidebar_mtrans" : "Ma transaction",
  "dashboard_sidebar_passbook" : "Livre de points",
  "dashboard_sidebar_redeem" : "Racheter",
  "dashboard_sidebar_refer_friend" : "Référer un ami",
  "dashboard_sidebar_logout" : "Connectez - Out",

  "dashboard_sidebar_tutorial1" : "Vous montre toutes les enquêtes disponibles et ce que vous pouvez gagner d'eux.",
  "dashboard_sidebar_tutorial2" : "Répondez à quelques questions ici pour nous faire savoir vous mieux et nous aider à vous envoyer des sondages de votre intérêt. S'il vous plaît noter pour certaines questions que vous ne seriez pas en mesure de mettre à jour vos réponses après avoir soumis.",
  "dashboard_sidebar_tutorial2update" : "Vous pouvez mettre à jour vos réponses ici, mais peu de questions ne seront pas disponibles pour la mise à niveau.",
  "dashboard_sidebar_tutorial3" : "Trouvez ici toutes les enquêtes que vous avez tentées.",
  "dashboard_sidebar_tutorial4" : "Vous aide à garder une trace de toutes vos transactions sur WisePerks.",
  "dashboard_sidebar_tutorial5" : "Quelques étapes rapides pour convertir vos points réalisés en récompenses.",
  "dashboard_sidebar_tutorial6" : "Consultez l'historique complet des transactions des points débités / crédités sur votre compte.",
  "dashboard_sidebar_gotit" : "Je l'ai",

  "dashboard_sidebar_note" : "Notez s'il vous plaît",
  "dashboard_sidebar_note1" : "Toutes les réponses doivent être répondues honnêtement.",
  "dashboard_sidebar_note2" : "Veuillez être précis et précis avec des réponses ouvertes.",
  "dashboard_sidebar_note3" : "Ne pas accélérer! Les temps d'achèvement minimum de l'enquête sont définis.",
  "dashboard_sidebar_note4" : "Veuillez fournir des réponses cohérentes lorsque les mêmes questions sont posées.",
  "dashboard_sidebar_note5" : "De nouvelles enquêtes seront ajoutées et fermées seront supprimées automatiquement de votre tableau de bord.",
  "dashboard_sidebar_note6" : "Gagnez des points rapides simplement par des références.",

  "dashboard_setting_hi" : "salut",
  "dashboard_setting_minfo" : "Info sur l'adhésion",
  "dashboard_setting_macc" : "Gérer son compte",
  "dashboard_setting_upass" : "Mettre à jour le mot de passe",
  "dashboard_setting_yourname" : "Ton nom est",
  "dashboard_setting_fname" : "Prénom",
  "dashboard_setting_lname" : "Nom de famille",
  "dashboard_setting_save" : "Sauvegarder les modifications",
  "dashboard_setting_bornon" : "Vous êtes né le",
  "dashboard_setting_youare" : "Tu es un",
  "dashboard_setting_postal" : "Votre code postal est",
  "dashboard_setting_emailus" : "Pour toute assistance ou requête, veuillez envoyer un courriel à",
  "dashboard_setting_membersince" : "Vous avez été membre depuis",
  "dashboard_setting_taken" : "Vous avez pris",
  "dashboard_setting_completed" : "sondages et complétés",
  "dashboard_setting_ofthem" : "d'eux.",
  "dashboard_setting_earned" : "Tu as gagné",
  "dashboard_setting_pts" : "points",
  "dashboard_setting_started" : "depuis que tu as commencé.",
  "dashboard_register_email" : "Vous êtes inscrit auprès de nous de",
  "dashboard_setting_preferences" : "Gérer vos préférences",
  "dashboard_setting_emailoptout" : "Désabonnement par e-mail",
  "dashboard_setting_futureemails" : "Désactivez la réception de futurs e-mails de WisePerks.",
  "dashboard_setting_futureemailsflash" : "Nos courriels vous tenir au courant de toutes les enquêtes lucratives disponibles pour vous. Êtes-vous sûr de ne pas vouloir les recevoir.",
  "dashboard_setting_nrecemail" : "Nos courriels vous tenir au courant de toutes les enquêtes lucratives disponibles pour vous. Êtes-vous sûr de ne pas vouloir les arrêter.",
  "dashboard_setting_emailout_modalmsg" : "Êtes-vous sûr de vouloir vous retirer de la réception de futurs e-mails de WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Êtes-vous sûr de commencer à recevoir de futurs e-mails de WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Vous avez réussi à cesser de recevoir des courriels de WisePerks",
  "dashboard_setting_emailin_succmsg" : "Vous avez réussi à recevoir des courriels de WisePerks",
  "dashboard_setting_deactivateacc" : "Désactiver le compte",
  "dashboard_setting_disableprofile" : "La désactivation de votre compte désactivera votre profil sur WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Êtes-vous sûr de vouloir désactiver votre compte sur WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Si vous avez des plaintes ou des commentaires, alors s'il vous plaît partager avec nous. Nous l'aborderons en priorité. Donne-nous une chance. Êtes-vous sûr de vouloir supprimer votre compte avec nous?",
  "dashboard_setting_changelang" : "Changer de langue",
  "dashboard_setting_preferredlang" : "Sélectionnez votre langue préférée",
  "dashboard_setting_updatepass" : "Mettre à jour le mot de passe",
  "dashboard_setting_plsupdate" : "Veuillez mettre à jour votre mot de passe ci-dessous.",
  "dashboard_setting_oldpass" : "Ancien mot de passe",
  "dashboard_setting_enteroldpass" : "Entrez le vieux mot de passe",
  "dashboard_setting_newpass" : "Nouveau mot de passe",
  "dashboard_setting_enternewpass" : "Entrez un nouveau mot de passe",
  "dashboard_setting_confpass" : "Confirmez le mot de passe",
  "dashboard_setting_enternewpassagain" : "Entrez à nouveau le mot de passe",
  "dashboard_setting_update" : "Mettre à jour",

  "dashboard_doi_welcome" : "Bienvenue",
  "dashboard_doi_welbck" : "Nous saluons le retour",
  "dashboard_doi_accntverified" : "On dirait que vous avez manqué de vérifier votre compte de messagerie.",
  "dashboard_doi_getaccess" : "Dépêchez-vous! vous obtenez",
  "dashboard_doi_getaccess2" : "points une fois que vous vérifiez.",
  "dashboard_doi_activateacc" : "Veuillez vérifier votre boîte de réception et cliquer sur le lien indiqué dans l'e-mail de vérification de compte envoyé à votre compte e-mail enregistré.",
  "dashboard_doi_chckspam" : "Veuillez vérifier votre dossier de spam/junk au cas où vous ne pourriez pas trouver l'email de vérification.",
  "dashboard_doi_didntgetconf" : "N'a pas eu la vérification e-mail encore?",
  "dashboard_doi_resendconf" : "Renvoyer l'email de vérification",
  "dashboard_doi_resendconfsucc" : "L'email de vérification a été envoyé à votre email enregistré.", 
  
  "dashboard_survey_availsurveys" : "Enquêtes disponibles",
  "dashboard_survey_wisechecksurveys1" : "Vous n'êtes pas en mesure de voir des sondages en raison de certaines raisons de sécurité. S'il vous plaît contacter à l'appui sur",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Enquêtes non disponibles pour la raison de désinscription",
  "dashboard_featured" : "Vedettes",
  "dashboard_profile_survey" : "Sondage de profil",
  "dashboard_survey_start" : "Début",
  "dashboard_survey_on" : "Sur",
  "dashboard_survey_off" : "De",
  "dashboard_survey_point": "Point",
  "dashboard_survey_points" : "Points",
  "dashboard_survey_minute" : "Minute",
  "dashboard_survey_minutes" : "Minutes",

  "demography_hi" : "Salut",
  "demography_welcome" : "Bienvenue à WisePerks. Vous venez de gagner vous-même",
  "demography_points" : "points en vous inscrivant chez nous.",
  "demography_share" : "S'il vous plaît partager quelques détails plus dans le formulaire ci-dessous, pour nous aider à vous apporter des sondages pertinents pour vous.",

  "demography_gender" : "je suis un",
  "demography_male" : "Mâle",
  "demography_female" : "Femelle",
  "demography_birthday" : "Anniversaire",
  "demography_month" : "Mois", 
  "demography_day" : "Jour", 
  "demography_year" : "Année", 
  "demography_jan" : "janvier",
  "demography_feb" : "février",
  "demography_mar" : "Mars",
  "demography_apr" : "avril",
  "demography_may" : "Mai",
  "demography_jun" : "juin",
  "demography_jul" : "juillet",
  "demography_aug" : "août",
  "demography_sep" : "septembre",
  "demography_oct" : "octobre",
  "demography_nov" : "novembre",
  "demography_dec" : "décembre",
  "demography_hearabout" : "Où avez-vous entendu parler de WisePerks?",
  "demography_opt1" : "Sélectionnez le plus proche qui s'applique",
  "demography_opt2" : "Courriel",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum ou Blog",
  "demography_opt5" : "Ami",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "Moteur de recherche",
  "demography_opt8" : "Publicité TV",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Site web",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Autres",
  "demography_zipcode": "Code postal",
  "demography_submit" : "Envoyer",

  "privacypolicy_privacypolicy" : "Politique de confidentialité",
  "privacypolicy_privacyisimp" : "Votre vie privée est importante pour nous.",
  "privacypolicy_lastupdated" : "Dernière mise à jour le",

  "privacypolicy_para1" : "WiseWorks (avec ses sociétés affiliées, la «Société») possède et exploite un certain nombre de sites Web, d'applications mobiles et de services interactifs, notamment Wisesample.com, Flapbucks.com, WisePerks.com et d'autres (collectivement, le 'WiseWorks Sites').Cette politique de confidentialité s'applique aux sites WiseWorks et à toutes les fonctionnalités, applications mobiles, e-mails, services en ligne et autres fonctionnalités, qu'elles soient accessibles via un ordinateur, un appareil mobile ou autre, et les services disponibles sur ou via les sites et fonctionnalités WiseWorks (les 'Services').Cette politique de confidentialité ne couvre que les informations collectées par la Société via les Sites et fonctionnalités de WiseWorks et nos Services, et ne couvre pas les informations reçues ou collectées hors connexion par la Société (sauf indication contraire) ou collectées par des sites Web ou des sociétés non liés. fournir des liens.",

  "privacypolicy_para2" : "On s'attend à ce que le panéliste / répondant remplisse son profil avec honnêteté et honnêteté et ne soumette pas d'informations incorrectes ou incorrectes simplement pour être admissible à d'autres sondages.On s'attend également à ce que le panéliste / répondant ne manipule pas ses réponses ou n'utilise pas d'autres moyens injustes (changement de propriété intellectuelle, comptes multiples, etc.) pendant le remplissage de l'enquête pour obtenir des incitations.WisePerks a suffisamment de garanties pour détecter de telles anomalies et si un tel comportement est remarqué, le panéliste sera banni / bloqué de WisePerks et toutes / toutes ses demandes de rachat seront annulées.",

  "privacypolicy_para3" : "WiseWorks est fortement engagé à protéger votre vie privée avec nous.La politique de confidentialité suivante explique comment vos informations personnelles sont conservées et traitées lorsque vous explorez notre site Web et nos services.",

  "privacypolicy_topic1" : "Quelles sont les informations personnellement identifiables que WisePerks.com recueille auprès de moi?",
  "privacypolicy_topic1ans" : "WisePerks.com recueille des informations de plusieurs manières à partir de différentes parties de notre site Web et de notre réseau.Certaines informations sont recueillies lorsque vous vous inscrivez.Lors de l'inscription du membre, WisePerks.com vous demande votre nom, adresse e-mail, code postal et informations sur le ménage.Plus vous fournissez d'informations avec précision, plus nous sommes en mesure de vous offrir des services et des récompenses.En outre, si vous contactez jamais WisePerks.com par quelque moyen que ce soit, nous pouvons conserver une trace de cette correspondance.Chaque fois que WisePerks.com recueille de telles informations, nous visons à inclure un lien vers cette politique de confidentialité sur cette page.",

  "privacypolicy_topic2" : "Que sont les cookies et comment WisePerks.com les utilise-t-il?",
  "privacypolicy_topic2ans" : "Un cookie est un paquet de données électroniques envoyé à votre navigateur, à partir d'un serveur Web et stocké sur le disque dur de votre ordinateur.Dans le cadre de l'offre de sondages spécifiques, d'informations et de services personnalisés, WisePerks.com peut utiliser des cookies pour stocker et parfois suivre les informations vous concernant.WisePerks.com peut exiger que vous acceptiez les cookies pour préparer ce contenu spécifique pour vous.Cela vous évite également d'entrer vos informations de connexion à chaque fois.",

  "privacypolicy_topic3" : "Quelle est la politique de WisePerks.com pour me permettre de mettre à jour, modifier, modifier ou supprimer les informations de mon compte personnel?",
  "privacypolicy_topic3ans" : "Vous pouvez modifier vos informations de compte WisePerks.com à tout moment en utilisant votre identifiant et votre mot de passe WisePerks.com.Veuillez contacter",
  "privacypolicy_topic3ans2" : "pour plus d'informations sur la désactivation de votre compte WisePerks.com.",

  "privacypolicy_topic4" : "Qui recueille des informations et dans quel but?",
  "privacypolicy_topic4ansp1" : "WisePerks.com recueille plusieurs informations par le biais de sondages et d'enregistrement et d'autres moyens, ce qui aide ses clients de recherche à développer de nouveaux produits et services pour les consommateurs.Vous partagez uniquement ces informations avec WisePerks.com, sauf indication contraire dans un sondage auquel vous participez.Si des données sont collectées et / ou conservées par une société autre que WiseWorks - ou des sites WiseWorks, vous serez averti avant l'heure de la collecte ou du transfert des données.Si vous ne souhaitez pas que vos données soient partagées, vous pouvez choisir de ne pas autoriser le transfert en n'utilisant pas ce sondage ou produit particulier.",
  "privacypolicy_topic4ansp2" : "Les récompenses et les promotions que nous offrons sur WisePerks.com peuvent être soit sponsorisées par d'autres sociétés indépendamment, soit être co-sponsorisées par WisePerks.com et une autre société.Certaines données collectées lors d'une telle promotion peuvent être partagées avec le sponsor.Cependant, vous serez averti avant l'heure de la collecte ou du transfert des données.Vous pouvez décider de ne pas participer à cette promotion si vous ne souhaitez pas partager vos données.",
  "privacypolicy_topic4ansp3" : "Toute information sensible spécifique (comme des informations spécifiant des conditions médicales ou de santé, origine raciale ou ethnique, opinions politiques, croyances religieuses ou spirituelles, métiers et appartenance syndicale ou des informations spécifiant la vie sexuelle de l'individu) sera traitée avec précaution.Si WisePerks.com a l'intention de partager ces informations avec une autre société dans un but différent de celui initialement recueilli, il vous sera expressément demandé une autorisation avant toute divulgation.",

  "privacypolicy_topic5" : "Avec qui WisePerks.com partage-t-il mes informations?",
  "privacypolicy_topic5ansp1" : "Nous prenons la confidentialité très au sérieux.WisePerks.com ne divulguera aucune de vos informations personnellement identifiables, sauf si nous avons votre permission explicite ou dans des circonstances particulières, que la loi l'exige ou dans les circonstances spécifiées en détail ci-dessous.Ce qui suit décrit certaines des façons dont vos informations peuvent être divulguées.Veuillez consulter les conditions d'utilisation de nos services pour obtenir des informations plus détaillées sur la manière dont vos informations personnelles peuvent être partagées.",
  "privacypolicy_topic5ansp2" : "Partenaires commerciaux, clients et sponsors:",
  "privacypolicy_topic5ansp3" : "WisePerks.com peut divulguer vos informations personnelles à nos partenaires, clients ou sponsors.Cependant, si des données personnelles vous concernant devaient être partagées, nous vous le dirons avant de collecter ou de transférer les données.De nombreuses activités que nous offrons demandent des informations supplémentaires auprès des sponsors.En demandant plus d'informations, vous autorisez WisePerks.com à partager vos informations avec le sponsor afin qu'il puisse répondre à votre demande.Dans de nombreux cas, seule votre adresse e-mail sera partagée.Si plus d'informations sont partagées avec nos partenaires, vous en serez informé à l'avance.De temps en temps, il se peut que vous ayez la possibilité de recevoir du matériel de test, ou d'autres produits ou offres spéciales de nos partenaires.Si vous acceptez de recevoir des informations de ces tiers, WisePerks.com (avec votre permission) partagera votre nom et votre adresse e-mail avec eux.",
  "privacypolicy_topic5ansp4" : "WisePerks.com peut également divulguer des informations personnelles dans certains cas particuliers lorsque la divulgation de ces informations est nécessaire pour identifier, contacter ou intenter une action en justice contre une personne qui enfreint les politiques et conditions d'utilisation de WisePerks.com ou peut causer des blessures à ou toute interférence avec (intentionnellement ou non) les produits, services, droits ou propriétés de WisePerks.com, les autres membres et utilisateurs de WisePerks.com ou toute autre personne susceptible d'être affectée par de telles activités.WisePerks.com peut divulguer ou accéder à des informations de compte lorsque nous croyons en toute bonne foi que la loi l'exige et à des fins administratives et autres que nous jugeons nécessaires pour maintenir, entretenir et améliorer nos produits et services.",

  "privacypolicy_topic6" : "Quelles sont les bases juridiques que nous utilisons pour traiter vos informations?",
  "privacypolicy_topic6ans" : "WiseWorks s'engage à protéger la vie privée et les données.Notre politique de confidentialité adopte les principes de la réglementation générale sur la protection des données édictée par l'Union européenne («GDPR») comme référence à laquelle nous, nos employés et nos fournisseurs adhérons.",

  "privacypolicy_topic7" : "Quels sont mes choix concernant la collecte, l'utilisation et la distribution de mes informations?",
  "privacypolicy_topic7ans" : "Seul WisePerks.com (ou les partenaires travaillant avec WisePerks.com dans le cadre d'accords de confidentialité) vous enverra des e-mails directs, uniquement si vous avez indiqué que vous n'y voyez pas d'objection.Vous avez également des choix en ce qui concerne le fonctionnement des cookies.En modifiant les paramètres de votre navigateur Internet, vous avez le choix d'accepter tous les cookies, d'être averti lorsqu'un cookie est défini ou de rejeter toute demande de cookies.Si vous choisissez de refuser tous les cookies, vous ne pourrez pas utiliser les services WisePerks.com qui nécessitent une inscription préalable pour participer.WisePerks.com ne vend ou ne loue aucune information d'utilisateur à quiconque.Nous vous informerons toujours au moment de la collecte ou du transfert des données si vos données seront partagées avec des tiers et vous aurez toujours la possibilité de ne pas les partager.",

  "privacypolicy_topic8" : "Quelles précautions de sécurité sont en place chez WisePerks.com pour protéger toute perte, mauvaise utilisation ou modification des informations de mon compte?",
  "privacypolicy_topic8ansp1" : "Vos informations de compte WisePerks.com sont protégées par un mot de passe afin que vous et vous seul puissiez accéder à ces informations.",
  "privacypolicy_topic8ansp2" : "Nous vous recommandons fortement de ne partager votre mot de passe avec personne.Nous ne vous demanderons jamais votre mot de passe lors d'un appel téléphonique ou d'un courriel non sollicité.Veuillez vous déconnecter de votre compte WisePerks.com et fermer la fenêtre de votre navigateur lorsque vous avez terminé votre activité.Cela garantit que les autres utilisateurs ne peuvent pas accéder à vos informations personnelles et votre compte, si vous utilisez un ordinateur public ou partagez votre ordinateur avec quelqu'un d'autre.Chaque fois que WisePerks.com traite des informations personnelles comme décrit ci-dessus, indépendamment de l'endroit où cela se produit, WisePerks.com prend des mesures pour s'assurer que vos informations sont traitées en toute sécurité et conformément aux Conditions d'utilisation pertinentes et à cette Politique de confidentialité.Malheureusement, aucune transmission de données sur Internet ne peut être garantie à 100%.En conséquence, alors que nous nous efforçons de protéger vos informations personnelles, WisePerks.com ne peut garantir ou garantir la sécurité de toute information que vous nous transmettez ou de nos produits ou services en ligne, et vous le faites à vos risques et périls.Une fois que nous recevons vos données, nous faisons de notre mieux pour assurer sa sécurité sur nos systèmes.",

  "privacypolicy_topic9" : "Que devrais-je savoir d'autre sur ma vie privée?",
  "privacypolicy_topic9ans" : "Gardez à l'esprit que chaque fois que vous choisissez de divulguer des informations personnelles en ligne (par exemple par e-mail, sites de réseaux sociaux, blogs ou forums de discussion), ces informations peuvent être collectées et utilisées par d'autres utilisateurs.Si vous publiez des informations personnelles en ligne accessibles au public, vous pouvez recevoir des messages non sollicités de la part d'autres parties en retour.En fin de compte, vous êtes seul responsable du maintien de la confidentialité de vos mots de passe et / ou des informations de votre compte.Soyez prudent et responsable chaque fois que vous êtes en ligne.",

  "privacypolicy_topic10" : "Comment WisePerks.com protège-t-il la vie privée de mes enfants?",
  "privacypolicy_topic10ans" : "WisePerks.com obtient un consentement parental préalable lorsque les enquêtes requièrent la participation des enfants et que la supervision parentale est également requise pour les enquêtes auprès des enfants de 6 à 15 ans.Nous prenons un soin particulier pour protéger la sécurité et la confidentialité des jeunes utilisateurs sur notre site, et nous vous encourageons à participer à l'expérience de votre enfant en ligne.Nous ne permettons aucun marketing ou recherche pour enfants et certains sujets sont complètement interdits dans la recherche chez les enfants: Nom / nom du compte / E-mail ou adresse physique, Numéro de téléphone, Race ou Ethnicité, Religion ou Distribution, Revenu du ménage, Maladie familiale , Situation matrimoniale des parents ou d'autres membres de la famille, Relation, Niveau scolaire ou collégial, Alcool, Problèmes sexuels, Tabac et armes à feu.",

  "privacypolicy_topic11" : "Note spéciale aux utilisateurs internationaux",
  "privacypolicy_topic11ansp1" : "Le site est hébergé aux États-Unis.Si vous êtes un Utilisateur accédant au Site depuis l'Union Européenne, l'Asie ou toute autre région avec des lois ou réglementations régissant la collecte, l'utilisation et la divulgation de données personnelles, qui diffèrent des lois des États-Unis, veuillez noter que vous transférez vos données personnelles. États-Unis qui n'ont pas les mêmes lois sur la protection des données que l'UE et les autres régions, et en fournissant vos données personnelles, vous consentez à:",
  "privacypolicy_topic11ansp2" : "L'utilisation de vos données personnelles pour les utilisations identifiées ci-dessus conformément à la Politique de confidentialité; et le transfert de vos données personnelles aux États-Unis comme indiqué ci-dessus.VOUS RECONNAISSEZ QUE LES LOIS DES ÉTATS-UNIS TRAITENT VOS INFORMATIONS D'UNE MANIÈRE QUI PEUT ÊTRE SUBSTANTIELLEMENT DIFFÉRENTE ET MOINS PROTÉGÉE QUE LE TRAITEMENT REQUIS EN VERTU DES LOIS D'AUTRES PAYS ET JURIDICTIONS.SI VOUS NE VOULEZ PAS TRANSFÉRER VOS RENSEIGNEMENTS AUX ÉTATS-UNIS, VOUS NE DEVEZ PAS PARTAGER VOS RENSEIGNEMENTS AVEC NOUS, NI UTILISER LE SITE.DANS LA MESURE PERMISE PAR LA LOI DU PAYS DANS LEQUEL VOUS ÊTES SITUÉ, VOUS RENONCEZ EXPRESSÉMENT À TOUT DROIT QUE VOUS POUVEZ NOUS DEMANDER DE TRAITER VOS INFORMATIONS D'IDENTIFICATION CONFORMÉMENT AUX LOIS DE TOUT PAYS OU JURIDICTION AUTRE QUE LES ÉTATS-UNIS.TOUTEFOIS, LA RENONCIATION SUSMENTIONNEE PEUT NE PAS ETRE LIEE LEGALEMENT DANS CERTAINS PAYS, TELS QUE LES ETATS MEMBRES DE L'UNION EUROPEENNE.DANS LA MESURE O IT IL N'EST PAS LÉGALEMENT OBLIGATOIRE DANS LE PAYS DANS LEQUEL VOUS ÊTES SITUÉ, LA PRÉSENTE RENONCIATION NE S'APPLIQUE PAS À VOUS.",

  "privacypolicy_topic12" : "Modifications et mises à jour de cette politique de confidentialité",
  "privacypolicy_topic12ans" : "WiseWorks se réserve le droit, à sa discrétion, de changer, modifier, ajouter ou supprimer des parties de cette politique de confidentialité à tout moment et pour n'importe quelle raison.Chaque fois que WiseWorks exerce ce droit, vous devez accepter la Politique de confidentialité mise à jour, et si vous ne le faites pas, vous ne pourrez plus accéder au Site ou l'utiliser.",

    "privacypolicy_topic13": "Note spéciale à ceux qui recherchent des détails GDPR / CCPA ",
    "privacypolicy_topic13ansp1": "S'il vous plaît",
    "privacypolicy_topic13ansp2": "Cliquez ici",
    "privacypolicy_topic13ansp3": "pour en savoir plus sur les détails de conformité au RGPD (règlement général sur la protection des données) et au CCPA (California Consumer Privacy Act).",


  "privacypolicy_addinfo" : "Information additionnelle",
    "privacypolicy_sentto": "Toute autre question à ce sujet doit être envoyée à",


    "privacypolicyext_para1": "VEUILLEZ LIRE ATTENTIVEMENT CETTE DÉCLARATION DE CONFIDENTIALITÉ",
    "privacypolicyext_para2": "WiseWorks respecte la vie privée de chaque utilisateur et nous nous engageons à être transparents avec vous sur la façon dont nous traitons les données personnelles. Cette politique de confidentialité explique comment WiseWorks collecte, partage et utilise les données personnelles que vous nous fournissez lorsque vous utilisez le site Web WiseWorks ou interagissez avec nous. Nous avons également proposé des sections pour vous apprendre comment exercer vos droits et choix en matière de confidentialité.",
    "privacypolicyext_para3": "Veuillez lire attentivement cette politique de confidentialité avant d'accéder à notre site Web et d'utiliser les services WiseWorks. Si vous avez des questions, veuillez nous contacter au",

    "privacypolicyext_topic1": "Aperçu",
    "privacypolicyext_topic2": "Acceptation et utilisation",
    "privacypolicyext_topic3": "Qui nous sommes",
    "privacypolicyext_topic4": "Résumé des informations personnelles et utilisation",
    "privacypolicyext_topic5": "Finalités de la collecte de données",
    "privacypolicyext_topic6": "Principes de protection des données",
    "privacypolicyext_topic7": "Types de données que nous recueillons auprès de vous",
    "privacypolicyext_topic8": "Cookies et balises Web",
    "privacypolicyext_topic9": "Vous informer des enquêtes et des projets de recherche",
    "privacypolicyext_topic10": "Comment nous utilisons vos données",
    "privacypolicyext_topic11": "Avec qui nous partageons vos données",
    "privacypolicyext_topic12": "Sujets EEA Data et non-EEA",
    "privacypolicyext_topic13": "Vos droits de protection des données",
    "privacypolicyext_topic14": "Notre politique envers les enfants",
    "privacypolicyext_topic15": "Sécurité des données",
    "privacypolicyext_topic16": "Termes et conditions",
    "privacypolicyext_topic17": "Juridiction",
    "privacypolicyext_topic18": "Modifications de notre politique de confidentialité",
    "privacypolicyext_topic19": "Nous contacter",

    "privacypolicyext_t1": "on",
    "privacypolicyext_t2": "nous ",
    "privacypolicyext_t3": "ou",
    "privacypolicyext_t4": "notre ",

    "privacypolicyext_topic1ansp1": " respecte les droits à la vie privée de ses utilisateurs et comprend l'importance de protéger et de traiter les informations collectées à votre sujet en vertu de la loi et des meilleures pratiques. Cet avis de confidentialité est rédigé pour se conformer",
    "privacypolicyext_topic1ansp1_1": "Article 13 et article 14 du règlement général sur la protection des données de l'UE, c'est-à-dire le règlement (UE) 2016/679 (`` GDPR '') et le California Consumer Privacy Acts CCPA.",
    "privacypolicyext_topic1ansp2": "Cet avis de confidentialité s'applique aux informations personnelles que nous traitons concernant les personnes qui ont:",
    "privacypolicyext_topic1ansp2_l1": "Créez un compte avec nous;",
    "privacypolicyext_topic1ansp2_l2": "a accepté de recevoir des informations marketing de notre part;",
    "privacypolicyext_topic1ansp2_l3": "participer à des sondages;",
    "privacypolicyext_topic1ansp2_l4": "contactez-nous pour des demandes et une assistance",
    "privacypolicyext_topic1ansp3": 'Cette politique de confidentialité décrit également quels droits vous avez concernant vos données, comment vous donnez votre consentement et comment vous pouvez retirer votre consentement. WiseWorks (avec ses affiliés, la «Société») possède et exploite plusieurs sites Web, applications mobiles et services interactifs différents, y compris, sans s&rsquo;y limiter, Wisesample.com, Flapbucks.com, WisePerks.com et d&rsquo;autres (collectivement, les «Sites WiseWorks "). Cette politique de confidentialité («Politique de confidentialité») s&rsquo;applique aux sites WiseWorks et à toutes les fonctionnalités, applications mobiles, e- mails, services en ligne et autres fonctionnalités, accessibles via un ordinateur, un appareil mobile ou autre, et les services disponibles sur ou via les sites et fonctionnalités WiseWorks (les «services»).Cette politique de confidentialité ne couvre que les informations collectées par la société via les sites et fonctionnalités WiseWorks et nos services, et ne couvre pas les informations reçues ou collectées hors ligne par la société(sauf indication contraire) ou collectées par des sites Web ou sociétés non liés auxquels nous pouvons fournir des liens.',

    "privacypolicyext_topic2ansp1_1": "Cette politique de confidentialité couvre ",
    "privacypolicyext_topic2ansp1_2": " et ses filiales, y compris",
    "privacypolicyext_topic2ansp1_3": "collectivement ",
    "privacypolicyext_topic2ansp1_4": "En utilisant notre site Web, vous signifiez votre acceptation des termes de la présente politique de confidentialité, qui est considérée comme votre consentement officiel au traitement de vos informations personnelles, votre consentement à la collecte, l'utilisation et la divulgation de vos informations personnelles comme décrit ci-dessous. Nous pouvons vous demander de fournir des divulgations ou des informations supplémentaires sur les pratiques de traitement des données de services spécifiques. Ces avis peuvent compléter ou clarifier nos pratiques de confidentialité ou vous proposer d'autres choix sur la manière dont nous traitons vos données.",
    "privacypolicyext_topic2ansp2": "Si vous n'êtes pas à l'aise avec un aspect quelconque de cette politique de confidentialité. Vous devez immédiatement interrompre tout accès au site Web WiseWorks et à notre site Web.",

    "privacypolicyext_topic3ansp1_1": " est une société constituée et enregistrée au Canada (# 10369675) .Notre siège social est au ",
    "privacypolicyext_topic3ansp1_2": "Nous fournissons des services de panel pour les industries d'études de marché en ligne. Nous permettons aux utilisateurs ou participants qui remplissent nos conditions d'éligibilité de s'inscrire à un compte de panel d'utilisateurs et de répondre à des sondages pour recevoir des points ou d'autres incitations.",
    "privacypolicyext_topic3ansp2": "Nos services sont disponibles dans plusieurs pays, ainsi qu'en Californie (États-Unis), au Canada, en Allemagne, en Inde, etc. Les services ne sont pas destinés à être utilisés par des utilisateurs de moins de 18 ans; vous devez être âgé d'au moins 18 ans pour utiliser notre site Web et nos services.",

    "privacypolicyext_topic4ansp1": "Nous pouvons collecter ou obtenir des données personnelles vous concernant, et ces informations peuvent être stockées ou traitées dans la région des États-Unis par les méthodes suivantes:",
    "privacypolicyext_topic4ansp1_l1": "Directement de vous (par exemple, où vous nous contactez); dans le cadre de notre relation avec vous (par exemple, si vous utilisez nos services);",
    "privacypolicyext_topic4ansp1_l2": "lorsque vous visitez notre site Web; ou utiliser n'importe quelle partie du site Web;",
    "privacypolicyext_topic4ansp2": "Nous pouvons également recevoir des informations personnelles vous concernant de la part de tiers.",

    "privacypolicyext_topic5ansp1": "Voici des exemples de la manière dont nous utilisons vos informations personnelles:",
    "privacypolicyext_topic5ansp1_l1": "Fourniture de services",
    "privacypolicyext_topic5ansp1_l2": "Offrir et améliorer le site Web",
    "privacypolicyext_topic5ansp1_l3": "Enquêtes",
    "privacypolicyext_topic5ansp1_l4": "la communication",
    "privacypolicyext_topic5ansp1_l5": "Engagement des utilisateurs",
    "privacypolicyext_topic5ansp1_l6": "Commercialisation",
    "privacypolicyext_topic5ansp1_l7": "Génération de leads",
    "privacypolicyext_topic5ansp1_l8": "Conformité légale",

    "privacypolicyext_topic6ansp1": "Nous collectons, traitons et utilisons des données personnelles (conformément aux définitions de l'article 13 et de l'article 14 du règlement général sur la protection des données de l'UE, à savoir le règlement (UE) 2016/679 (`` GDPR '') et le California Consumer Privacy Acts CCPA) pour fournir nos services. Les données personnelles désignent toutes les informations qui nous permettent de vous identifier, directement ou indirectement, telles que nom, e-mail, adresse, informations de santé, toute forme de numéro d'identification, ou un ou plusieurs facteurs spécifiques à vous ou à l'identité de votre bénéficiaire.",
    "privacypolicyext_topic6ansp2": "Nous nous engageons à respecter les lois applicables en matière de protection des données et veillerons à ce que les données personnelles soient:",
    "privacypolicyext_topic6ansp2_l1": "Utilisé légalement, équitablement et de manière transparente;",
    "privacypolicyext_topic6ansp2_l2": "Recueillies uniquement à des fins valables que nous vous avons clairement expliquées et non utilisées d'une manière incompatible avec ces finalités;",
    "privacypolicyext_topic6ansp2_l3": "Pertinente aux fins dont nous vous avons parlé et limitée uniquement à ces fins;",
    "privacypolicyext_topic6ansp2_l4": "Précis et mis à jour;",
    "privacypolicyext_topic6ansp2_l5": "Conservés aussi longtemps que nécessaire aux fins dont nous vous avons parlé;",

    "privacypolicyext_topic7ansp1": "Nous pouvons collecter et traiter les informations personnelles suivantes vous concernant:",
    "privacypolicyext_topic7ansp1_l1title": "Informations de profil personnel.",
    "privacypolicyext_topic7ansp1_l1": "Si vous créez un compte chez nous, nous collecterons votre nom et prénom, votre adresse e-mail et le mot de passe que vous avez choisi.",
    "privacypolicyext_topic7ansp1_l2title": "Coordonnées.",
    "privacypolicyext_topic7ansp1_l2": "Si vous choisissez de recevoir des sondages par SMS ou par tout autre moyen de messagerie, nous collectons également votre numéro de téléphone mobile ou vos coordonnées pertinentes.",
    "privacypolicyext_topic7ansp1_l3title": "Données du questionnaire.",
    "privacypolicyext_topic7ansp1_l3": "Le questionnaire de profilage initial recueille des informations sur vos antécédents, votre éducation, votre statut professionnel, vos habitudes de consommation et, éventuellement, votre santé. Nous recueillons des informations sur toutes les mises à jour que vous apportez à vos informations de profilage.",
    "privacypolicyext_topic7ansp1_l4title": "Données d'opinion.",
    "privacypolicyext_topic7ansp1_l4": "Lors des opportunités de recherche, nous recueillons les informations que vous fournissez concernant vos opinions, attitudes, expériences et utilisation de certains produits ou services. Occasionnellement, nous pouvons collecter des soumissions vidéo, audio ou photographiques que vous faites lors d'une opportunité de recherche.",
    "privacypolicyext_topic7ansp1_l5title": "Données d'expérience utilisateur.",
    "privacypolicyext_topic7ansp1_l5": "Nous recueillons des informations sur vos connexions, les commentaires sur les articles de blog, les évaluations, les sondages, les pages Web consultées.",
    "privacypolicyext_topic7ansp1_l6title": "Informations sur les récompenses.",
    "privacypolicyext_topic7ansp1_l6": "Toute information personnelle dont nous pourrions avoir besoin pour vous remettre un prix ou une récompense ou surveiller votre utilisation de nos activités de panel et de sondage.",
    "privacypolicyext_topic7ansp1_l7title": "Données de correspondance.",
    "privacypolicyext_topic7ansp1_l7": "Toute correspondance que vous nous envoyez.",
    "privacypolicyext_topic7ansp1_l8title": "Des données d'utilisation.",
    "privacypolicyext_topic7ansp1_l8": "Nous recueillons également des données d'utilisation vous concernant chaque fois que vous interagissez avec nos services. Cela peut inclure les pages Web que vous visitez, les éléments sur lesquels vous cliquez lorsque vous avez effectué ces actions, etc. De plus, nous conservons des fichiers journaux numériques qui enregistrent des données chaque fois qu'un appareil accède à nos serveurs. Les fichiers journaux contiennent des données sur la nature de chaque accès. Nous collectons des données à partir de l'appareil et de l'application que vous utilisez pour accéder à nos services, telles que votre adresse IP, le type de navigateur, le fournisseur d'accès Internet (FAI), les pages de renvoi / sortie, le système d'exploitation, l'horodatage et les données de parcours.",
    "privacypolicyext_topic7ansp2": "Nous pouvons également déduire votre emplacement géographique en fonction de votre adresse IP. Si vous arrivez à un service WiseWorks à partir d'une source externe (comme un lien sur un autre site Web, contenu dans un e-mail ou une application de messagerie), nous pouvons utiliser ces informations pour analyser les tendances, administrer le site, suivre les utilisateurs et recueillir des informations démographiques. sur notre base d'utilisateurs dans son ensemble.",

    "privacypolicyext_topic8ansp1": "Un cookie est un fichier texte contenant une petite quantité d'informations qui est envoyé à votre navigateur lorsque vous visitez un site Web. Le cookie est ensuite renvoyé au site Web d'origine à chaque visite ultérieure, ou à un autre site Web qui le reconnaît. Les cookies sont une technologie extrêmement utile et effectuent de nombreuses tâches différentes. Une balise Web est une image graphique souvent transparente, généralement pas plus grande que 1 pixel x 1 pixel, qui est placée sur un site Web ou dans un e-mail qui est utilisée pour surveiller le comportement de l'utilisateur visitant le site Web ou envoyant l'e-mail. Il est souvent utilisé en combinaison avec des cookies.",
    "privacypolicyext_topic8ansp2": "Nous pouvons collecter des informations grâce à l'utilisation de cookies, de balises Web ou de technologies similaires axées sur l'analyse.",
    "privacypolicyext_topic8ansp3_title": "Quels cookies utilisons-nous?",
    "privacypolicyext_topic8ansp3": "Les cookies que nous utilisons appartiennent aux catégories suivantes:",
    "privacypolicyext_topic8ansp3_l1title": "Cookies strictement nécessaires.",
    "privacypolicyext_topic8ansp3_l1": "Ce sont des cookies nécessaires au fonctionnement de notre site Web. Ils incluent, par exemple, des cookies qui vous permettent de vous connecter à des zones sécurisées de notre site Web.",
    "privacypolicyext_topic8ansp3_l2title": "Cookies analytiques / de performance.",
    "privacypolicyext_topic8ansp3_l2": "Ils nous permettent de reconnaître et de compter le nombre de visiteurs et de voir comment les visiteurs se déplacent sur notre site Web lorsqu'ils l'utilisent. Cela nous aide à améliorer le fonctionnement de notre site Web, par exemple en nous assurant que les utilisateurs trouvent facilement ce qu'ils recherchent.",
    "privacypolicyext_topic8ansp3_l3title": "Cookies de fonctionnalité.",
    "privacypolicyext_topic8ansp3_l3": "Ceux-ci sont utilisés pour vous reconnaître lorsque vous revenez sur notre site Web. Cela nous permet de personnaliser notre contenu pour vous, de vous accueillir par votre nom et de mémoriser vos préférences.",
    "privacypolicyext_topic8ansp3_l4title": "Cookies de ciblage.",
    "privacypolicyext_topic8ansp3_l4": "Ces cookies enregistrent votre visite sur notre site Web, les pages que vous avez visitées et les liens que vous avez suivis. Nous utiliserons ces informations pour rendre notre site Web et la publicité qui y est affichée plus adaptés à vos intérêts. Nous pouvons également partager ces informations avec des tiers à cette fin.",
    "privacypolicyext_topic8ansp4": "Nous utilisons différents types de cookies (dont certains que nous plaçons et d'autres que nous autorisons des tiers à placer sur notre site) à plusieurs fins. Les informations collectées par les cookies nous permettent de surveiller combien de personnes utilisent nos services et leurs interactions avec d'autres services, combien de personnes ouvrent nos e-mails et à quelles fins ces actions sont entreprises. Les cookies sont également utilisés pour faciliter la connexion d'un utilisateur, comme aides à la navigation et comme chronomètres de session, et pour limiter l'utilisation de nos outils et services par les mineurs. Nous ne lions pas les informations non personnelles des cookies à des informations personnellement identifiables sans autorisation. sélectionnez l'option «Ne pas suivre» (pour plus d'informations, veuillez consulter votre navigateur). La plupart des navigateurs proposent des instructions sur la façon de réinitialiser le navigateur pour refuser les cookies dans la section «Aide» de la barre d'outils.",
    "privacypolicyext_topic8ansp5": "Nous utilisons des balises Web pour collecter des informations sur l'utilisation de nos services. Les informations collectées par les balises Web nous permettent de surveiller le nombre de personnes qui utilisent nos services, le nombre de personnes qui ouvrent nos e-mails et de déterminer à quelles fins ces actions sont entreprises. Nos balises Web ne sont pas utilisées pour suivre l'activité en dehors de nos services.",
    "privacypolicyext_topic8ansp6_title": "Comment modifier mes paramètres de cookies?",
    "privacypolicyext_topic8ansp6_1": "La plupart des navigateurs Web permettent un certain contrôle de la plupart des cookies via les paramètres du navigateur. Pour en savoir plus sur les cookies, y compris comment voir quels cookies ont été définis, visitez www.aboutcookies.org ou www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "Pour savoir comment gérer les cookies sur les navigateurs courants:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "Lorsque vous rejoignez WiseWorks, nous pouvons communiquer avec vous par e-mail, téléphone ou service de messagerie (selon vos préférences) concernant votre adhésion. Les occasions où nous pouvons communiquer avec vous incluent les suivantes:",
    "privacypolicyext_topic9ansp1_l1": "Opportunités de recherche (telles que sondages, sondages, groupes de discussion, etc.)",
    "privacypolicyext_topic9ansp1_l2": "Résultats de recherche",
    "privacypolicyext_topic9ansp1_l3": "Bulletins des panélistes",
    "privacypolicyext_topic9ansp2": "Nos projets peuvent se concentrer sur un large éventail de sujets. Nous vous inviterons normalement à participer à des projets en vous envoyant un e-mail sur l'opportunité. À ce stade, nous expliquerons le vaste sujet de l'enquête, sa durée probable ou l'implication requise et la récompense proposée. C'est alors à vous de décider si vous souhaitez ou non participer. La participation n'est jamais obligatoire. Vous pouvez toujours abandonner à tout moment, même si nous préférons que vous terminiez les tâches une fois que vous les avez commencées et que nous ne payons normalement aucune prime pour les tâches incomplètes.",
    "privacypolicyext_topic9ansp3": "Nous attendons de vous que vous fournissiez à tout moment des informations vraies, exactes, actuelles et complètes, comme demandé.",


    "privacypolicyext_topic10ansp1": "Nous n'utiliserons vos données personnelles que lorsque la loi nous le permet. Le plus souvent, nous utiliserons vos données personnelles dans les circonstances suivantes, mais pour plus d'informations sur les types de base légale sur lesquels nous nous baserons pour traiter vos données, veuillez cliquer ici:",
    "privacypolicyext_topic10ansp1_l1": "Vous fournir nos produits et services",
    "privacypolicyext_topic10ansp1_l2": "trouver les personnes les plus adaptées à chaque opportunité de recherche;",
    "privacypolicyext_topic10ansp1_l3": "réduire le nombre de questions que nous devons poser dans les sondages;",
    "privacypolicyext_topic10ansp1_l4": "mener une analyse plus approfondie des données de recherche que nous avons déjà collectées;",
    "privacypolicyext_topic10ansp1_l5": "sous forme agrégée pour nous aider à expliquer nos capacités de recherche à nos clients.",
    "privacypolicyext_topic10ansp1_l6": "vous invitent à rechercher des opportunités telles que des sondages, des groupes de discussion, des communautés en ligne, une surveillance passive des sites Web que vous visitez, etc.",
    "privacypolicyext_topic10ansp1_l7": "vous envoyer des récompenses incitatives que vous avez gagnées,",
    "privacypolicyext_topic10ansp1_l8": "vous demander de mettre à jour votre profil,",
    "privacypolicyext_topic10ansp1_l9": "Vous inviter à participer à des enquêtes sur nos produits ou services",
    "privacypolicyext_topic10ansp1_l10": "Surveiller, détecter et prévenir la fraude, les activités inappropriées et assurer la conformité à la politique de WiseWorks",
    "privacypolicyext_topic10ansp1_l11": "Mener des recherches, y compris des recherches secondaires et de suivi",
    "privacypolicyext_topic10ansp1_l12": "Évaluez comment vous et les autres utilisateurs interagissez avec nos services en ligne",
    "privacypolicyext_topic10ansp1_l13": "Protégez WiseWorks et nos utilisateurs",
    "privacypolicyext_topic10ansp1_l14": "Réaliser des audits liés à nos interactions actuelles avec vous",
    "privacypolicyext_topic10ansp1_l15": "Déboguer le site et le service pour identifier et réparer les erreurs qui altèrent les fonctionnalités prévues existantes",
    "privacypolicyext_topic10ansp1_l16": "Se défendre dans les litiges et enquêtes et poursuivre les litiges",
    "privacypolicyext_topic10ansp1_l17": "Se conformer aux obligations légales et réglementaires.",

    "privacypolicyext_topic11ansp1": "WiseWorks ne partage vos informations personnelles avec des tiers que dans les circonstances décrites ci-dessous. Nous ne vendons ni ne divulguons les informations personnelles que nous recueillons à votre sujet à des fins monétaires ou autres.",
    "privacypolicyext_topic11ansp1_l1title": "Fournisseurs autorisés:",
    "privacypolicyext_topic11ansp1_l1": "Nous pouvons partager vos données avec des fournisseurs tiers qui nous aident à exploiter notre site Web, à traiter les paiements et à fournir nos services, ainsi qu'à nous conformer à vos instructions et à nos obligations contractuelles. Ceux-ci incluent les processeurs de paiement, les réseaux de diffusion de contenu (CDN), les services d'hébergement dans le cloud, les services de surveillance, les fournisseurs de services de messagerie, les fournisseurs d'assurance qualité et de test, les fournisseurs de prévention de la fraude et des abus, la gestion de la relation client (CRM), les fournisseurs",
    "privacypolicyext_topic11ansp1_l2title": "Partenaire de publicité et d'analyse:",
    "privacypolicyext_topic11ansp1_l2": "Nous pouvons partager des données d'utilisation avec des annonceurs tiers et des fournisseurs d'analyses par le biais de cookies et d'autres technologies similaires. Ces tiers peuvent collecter des informations envoyées par votre ordinateur, navigateur ou appareil mobile en réponse à une demande de contenu, comme des identifiants uniques, votre adresse IP ou d'autres informations sur votre ordinateur ou appareil.",
    "privacypolicyext_topic11ansp1_l3title": "Partenaires d'affaires:",
    "privacypolicyext_topic11ansp1_l3": "Nous collaborons parfois avec des tiers pour vous proposer des événements, des promotions, des concours et d'autres programmes. Pour participer à ces offres, vous devrez peut-être fournir votre adresse e-mail et des informations personnelles supplémentaires. Nous ne partagerons votre adresse e-mail et d'autres informations personnelles avec un partenaire commercial que lorsque vous nous autorisez expressément à le faire.",
    "privacypolicyext_topic11ansp1_l4title": "Certaines obligations légales: ",
    "privacypolicyext_topic11ansp1_l4": "WiseWorks peut être tenu de divulguer des informations personnelles si un tribunal ou d'autres entités gouvernementales le lui demandent. Sans limiter ce qui précède, nous nous réservons le droit de divulguer ces informations lorsque nous avons une base de bonne foi pour croire qu'une telle action est nécessaire pour: se conformer aux lois, règlements, ordonnances judiciaires, aux demandes du gouvernement et des forces de l'ordre, protéger et défendre les droits et la propriété de WiseWorks ou d'un tiers, ou la sécurité de WiseWorks, de nos utilisateurs, de nos employés ou d'autres, prévenir, détecter, enquêter et prendre des mesures contre les activités criminelles, la fraude et l'utilisation abusive ou non autorisée de notre site Web et pour faire respecter nos conditions d'utilisation ou d'autres accords ou politiques, dans la mesure permise par la loi, nous tenterons de vous donner un préavis avant de divulguer vos informations en réponse à une telle demande.",
    "privacypolicyext_topic11ansp1_l5title": "Avec votre consentement:",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks peut envoyer vos informations personnelles à un tiers lorsque vous consentez à le faire ou dans votre direction. Par exemple, cela peut se produire lorsque nous effectuons une transaction à votre demande.",
    "privacypolicyext_topic11ansp1_l6title": "Avec les fournisseurs de services:",
    "privacypolicyext_topic11ansp1_l6": "Nous pouvons partager des informations personnelles avec des fournisseurs, des consultants, des processeurs de paiement et d'autres prestataires de services qui nous fournissent des services spécifiques et des informations de processus uniques en notre nom.Ces services peuvent inclure le support client, la réalisation d'analyses commerciales et commerciales, la prise en charge de la fonctionnalité de notre site Web, faciliter le traitement des paiements, soutenir des concours, des enquêtes et d'autres fonctionnalités proposées sur notre site Web.Ces prestataires de services ne sont pas autorisés à utiliser vos informations personnelles à d'autres fins.Leur utilisation de ces données personnelles sera soumise à des mesures de confidentialité et de sécurité appropriées.",
    "privacypolicyext_topic11ansp1_l7title": "Avec les affiliés:",
    "privacypolicyext_topic11ansp1_l7": "Nous pouvons partager des informations personnelles avec certains de nos partenaires, sociétés affiliées et autres sociétés sous contrôle et propriété communs, sous réserve des conditions de la présente politique de confidentialité. Nous pouvons également partager vos informations personnelles dans le cadre d'une transaction d'entreprise importante, telle qu'une vente de notre entreprise, un désinvestissement, une fusion, une consolidation ou une vente d'actifs, en cas de faillite ou de préparation à l'un de ces événements. Toute autre entité qui nous achète ou devient une partie de notre entreprise aura le droit de continuer à utiliser vos informations personnelles, mais uniquement de la manière indiquée dans la présente politique de confidentialité, sauf accord contraire de votre part.",

    "privacypolicyext_topic12ansp1_title": "Bases juridiques du traitement de vos informations:",
    "privacypolicyext_topic12ansp1": "Pour les personnes situées dans l'Espace économique européen, au Royaume-Uni ou en Suisse (collectivement «résidents de l'EEE»), les données collectées auprès de vous peuvent être transférées ou traitées aux États-Unis (collectivement «personnes concernées»). Lorsque leurs données sont collectées, nous nous appuyons sur des bases légales pour traiter vos informations. En règle générale, nous ne traitons vos données que là où nous y sommes tenus, lorsque le traitement est nécessaire pour vous fournir nos services (ou pour prendre des mesures à votre demande avant de conclure un contrat avec vous).",
    "privacypolicyext_topic12ansp2_title": "Shine the Light (pour les citoyens californiens) CCPA",
    "privacypolicyext_topic12ansp2_1": "La loi «Shine the Light» de Californie permet aux clients de Californie de demander des détails spécifiques sur la manière dont certains types de leurs informations sont partagés avec des tiers et, dans certains cas, des sociétés affiliées à des fins de marketing direct de ces tiers et affiliés. En vertu de la loi, une entreprise doit soit fournir aux clients californiens certaines informations sur demande, soit autoriser les clients californiens à refuser ce type de partage.",
    "privacypolicyext_topic12ansp2_2": "Pour exercer une demande Shine the Light, veuillez nous contacter. Vous devez insérer la déclaration «Vos droits à la confidentialité en Californie» dans le corps de votre demande, ainsi que votre nom, adresse postale, ville, état et code postal. Veuillez fournir suffisamment d'informations pour déterminer si cela s'applique à vous dans le corps de votre demande. Veuillez noter que nous n'accepterons pas les demandes par téléphone, e- mail et nous ne sommes pas responsables des avis qui ne sont pas étiquetés ou envoyés correctement ou qui ne contiennent pas d'informations complètes.",
    "privacypolicyext_topic12ansp3_title": "Vos droits de confidentialité au Nevada",
    "privacypolicyext_topic12ansp3": "Si vous résidez au Nevada, vous avez le droit de refuser la vente de données personnelles spécifiques à des tiers qui ont l'intention de concéder une licence ou de vendre ces données personnelles.Vous pouvez exercer ce droit en nous contactant ici ou en nous envoyant un e- mail avec la ligne d'objet «Nevada Do Not Sell Request» et en nous fournissant votre nom et l'adresse e - mail associée à votre compte.",

    "privacypolicyext_topic13ansp1": "Nous vous permettons de faire de nombreux choix concernant vos données: Vous trouverez ci-dessous les droits de protection des données suivants dont vous disposez lorsque vous utilisez nos services.",
    "privacypolicyext_topic13ansp1_l1": "Vous pouvez choisir de ne pas nous fournir certaines informations.",
    "privacypolicyext_topic13ansp1_l2": "Supposons que vous souhaitiez accéder, corriger, mettre à jour ou demander la suppression de vos informations personnelles. Dans ce cas, vous pouvez le faire à tout moment en nous contactant via le portail des services clients ou aux informations fournies au bas de la présente politique de confidentialité.",
    "privacypolicyext_topic13ansp1_l3": "Si vous demandez l'accès à vos données, nous confirmerons si nous détenons vos informations et vous en fournirons une copie, en expliquant comment nous les traitons et pourquoi, pendant combien de temps nous les conservons et vos droits qui y sont associés.",
    "privacypolicyext_topic13ansp1_l4": "Si vous demandez la suppression de vos informations personnelles, nous les effacerons. Veuillez noter que nous devrons conserver toutes les informations dont nous avons besoin pour remplir nos obligations légales ou établir, exercer ou défendre une action en justice.",
    "privacypolicyext_topic13ansp1_l5": "Vous pouvez vous opposer au traitement de vos informations personnelles, nous demander de restreindre le traitement de vos informations personnelles ou demander la portabilité de vos informations personnelles. Encore une fois, vous pouvez exercer ces droits en nous contactant via le portail des services client ou aux informations fournies au bas de cette politique de confidentialité.",
    "privacypolicyext_topic13ansp1_l6": 'Vous avez le droit de vous désinscrire à tout moment des communications marketing par e-mail que nous vous envoyons. Vous pouvez exercer ce droit en cliquant sur le lien «se désinscrire» ou «se désinscrire» dans les e-mails marketing que nous vous envoyons.',
    "privacypolicyext_topic13ansp1_l7": "Désabonnement des messages de notre part: Pour vous désinscrire de tout futur message promotionnel de notre part, vous devez vous désinscrire dans le corps du message promotionnel qui vous a été envoyé (qui se trouve au bas de l'e-mail) ou nous envoyer une demande de désabonnement. Nous traiterons votre demande dans un délai raisonnable après réception.",
    "privacypolicyext_topic13ansp1_l8": "De même, si nous avons collecté et traité vos informations personnelles avec votre consentement, vous pouvez retirer votre consentement à tout moment.Le retrait de votre consentement n'affectera pas la légalité de tout traitement que nous avons effectué avant votre retrait, ni n'affectera le traitement de votre les informations personnelles effectuées sur la base de motifs de traitement licites autres que le consentement",
    "privacypolicyext_topic13ansp1_l9": "Vous avez le droit de vous plaindre auprès d'une autorité de protection des données concernant la collecte et l'utilisation de vos informations personnelles. Veuillez nous contacter pour plus d'informations et nous vous enverrons un lien ou l'adresse de notre responsable de la protection des données.",
    "privacypolicyext_topic13ansp1_l10": "Le moyen le plus simple de nous soumettre votre demande consiste à utiliser notre courrier électronique d'assistance à la clientèle situé au bas de cette politique de confidentialité. Nous répondons à toutes les demandes que nous recevons de personnes souhaitant exercer leurs droits de protection des données en vertu des lois applicables en matière de protection des données.",

    "privacypolicyext_topic14ansp1": "Le site Web et les services ne sont pas destinés aux enfants de moins de treize (18) ans. WiseWorks est conforme à la loi COPPA (Children's Online Privacy Protection). Nous ne demandons pas sciemment de collecter des informations personnelles de toute personne de moins de 18 ans. Veuillez nous informer si vous connaissez des personnes de moins de 18 ans utilisant notre site Web pour les empêcher d'accéder à nos services.",

    "privacypolicyext_topic15ansp1": "Nous nous engageons à maintenir la sécurité de vos données, en mettant en place des mesures pour nous protéger contre la perte, l'utilisation abusive et l'altération des données sous notre contrôle. Nous utilisons des techniques modernes et sécurisées pour protéger nos systèmes contre les intrusions de personnes non autorisées, et nous améliorons régulièrement notre sécurité à mesure que de meilleures méthodes deviennent disponibles. Nos centres de données et nos partenaires utilisent de nouvelles mesures de sécurité physique pour empêcher tout accès non autorisé à l'installation.",
    "privacypolicyext_topic15ansp2": "Veuillez noter qu'aucune mesure de sécurité n'est parfaite ou impénétrable. Par conséquent, bien que nous utilisions des pratiques standard de l'industrie pour protéger votre vie privée, nous ne pouvons pas (et ne garantissons pas) la sécurité absolue des données personnelles.",


    "privacypolicyext_topic16ansp1": "Pour en savoir plus sur nos conditions générales, veuillez visiter notre page Conditions d'utilisation, qui établit l'utilisation, la clause de non-responsabilité et les limitations de responsabilité régissant l'utilisation de notre site Web.",

    "privacypolicyext_topic17ansp1": "Les lois des États-Unis d'Amérique régissent cette politique de confidentialité et les conditions de service et autres services connexes.",

    "privacypolicyext_topic18ansp1": "WiseWorks s'efforce continuellement de mettre en œuvre de nouvelles technologies et de nouveaux processus pour mieux vous protéger, votre vie privée et votre utilisation du service WiseWorks. En conséquence, des modifications de cette politique de confidentialité peuvent être apportées par WiseWorks de temps à autre. En aucun cas, ces changements n'entraîneront la dégradation des mesures de sécurité conçues pour vous protéger contre toute divulgation non autorisée.",

    "privacypolicyext_topic19ansp1": "Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter à ",






  "faq_faq" : "Questions fréquemment posées",
  "faq_getans" : "Obtenez des réponses à toutes vos questions.",

  "faq_ques1" : "Qu'est-ce que WisePerks?",
  "faq_ans1" : "Nous sommes heureux que vous ayez demandé.WisePerks est un site de sondage en ligne.Beaucoup de marques et d'entreprises recherchent des répondants pour donner leur avis et recevoir des récompenses en retour.WisePerks est une telle plate-forme où vous pouvez donner votre avis et gagner des récompenses pour cela aussi.",

  "faq_ques2" : "Est-ce gratuit?",
  "faq_ans2" : "Oui, c'est gratuit.En fait, si quelqu'un vous demande des frais d'adhésion, nous vous demandons de nous le signaler.",

  "faq_ques3" : "Puis-je recommander mes amis?",
  "faq_ans3" : 'Oui, vous pouvez inviter vos amis et collègues à vous rejoindre et être récompensé pour cela. Veuillez cliquer sur le lien "Référer un ami" sur votre tableau de bord pour obtenir tous les détails.',

  "faq_ques4" : "Qu'est-ce que je gagne?",
  "faq_ans4" : "Maintenant, dans notre nouveau modèle, vous pouvez gagner des points à bien des égards autres que simplement en prenant les sondages. Vous obtenez des points par: vous inscrire auprès de nous, en vérifiant votre compte de messagerie, pour remplir votre profil, bien sûr pour remplir n'importe quel sondage et à votre surprise, même pour un sondage disqualifié. Donc, nous avons toutes les raisons de vous faire démarrer n'est-ce pas?",

  "faq_ques5" : "Comment devenir membre de WisePerks.com?",
  "faq_ans5" : "Ouvrez WisePerks.com sur votre navigateur.Créez une connexion en cliquant sur l'onglet 'Inscription'.Il suffit de suivre les étapes et il vous guidera tout au long du processus.Vous devrez confirmer votre intention de rejoindre en cliquant sur l'e-mail de confirmation envoyé par nous à votre adresse email fournie.",

  "faq_ques6" : "Où est cet e-mail de confirmation que vous avez mentionné?",
  "faq_ans6p1" : "Un e-mail de confirmation est automatiquement envoyé à l'adresse e-mail que vous fournissez lors de votre inscription. Si vous ne voyez pas cet email dans votre boîte de réception, vérifiez svp votre dossier de spam ou d'ordure. Pour éviter les e-mails futurs de nous et les invitations de sondage/messages d'atteindre votre dossier spam, s'il vous plaît ne ajouter",
  "faq_ans6p2" : "à votre liste de vos expéditeurs approuvés ou en tant que contact dans votre boîte aux lettres. Si vous ne trouvez toujours pas cette confirmation e-mail, s'il vous plaît vous connecter à mon compte, confirmer que votre adresse e-mail avec nous est correcte, et demander un autre e-mail de confirmation.",

  "faq_ques7" : "Comment puis-je prendre un sondage?",
  "faq_ans7" : "Une fois que vous êtes connecté après avoir entré votre nom d'utilisateur et mot de passe, vous atteignez votre tableau de bord et peut voir toutes les enquêtes que vous pouvez prendre. Nous envoyons également les liens d'enquête à vos identifiants de courriel enregistrés. Vous pouvez prendre le lien directement à partir de votre boîte aux lettres.",

  "faq_ques8" : "Quelle est la confidentialité de mes réponses, de mes données?",
  "faq_ans8" : "Pour WisePerks, le maintien de la confidentialité et de la confidentialité des utilisateurs est une priorité absolue.Nos clients ne s'intéressent qu'à ce que vous pensez, en tant que consommateur, ou à ce que vous pensez de quelque chose.Chez WisePerks, nous ne partageons / transmettons jamais aucune information personnelle à nos clients, et aucune des enquêtes WisePerks ne vend ou ne loue aucune information à des tiers.",

  "faq_ques9" : "Quelle est la durée de ces enquêtes?",
  "faq_ans9" : "La plupart des enquêtes ne prennent qu'environ 10 à 15 minutes, voire moins.Cependant, de temps en temps, nous pouvons avoir des enquêtes plus longues.Avec chaque sondage, nous vous disons à l'avance combien de temps cela va prendre, mais gardez à l'esprit que les enquêtes plus longues ont aussi des récompenses plus élevées.",

  "faq_ques10" : "Quelqu'un peut-il prendre des enquêtes WisePerks?",
  "faq_ans10" : "Vous devez avoir 13 ans ou plus pour vous inscrire à WisePerks.Bien que parfois nous vous invitons dans des sondages pour participer avec les plus jeunes membres de votre famille.",

  "faq_ques11" : "Comment savez-vous que je serai admissible à un sondage?",
  "faq_ans11" : "Notre algorithme met en correspondance les informations de votre profil avec les sondages disponibles pour vous assurer d'obtenir les sondages les plus appropriés.Quand un sondage commence, les premières questions déterminent généralement si vous êtes le répondant, notre client recherche ce sondage particulier.Nous avons une vaste gamme d'enquêtes à la recherche de différents types de personnes (par exemple, les gens qui regardent des films régulièrement, jouent un sport particulier, les femmes avec des enfants, etc.) et de nouvelles enquêtes apparaissent chaque jour!",

  "faq_ques12" : "Quelles sont mes obligations envers WisePerks?",
  "faq_ans12" : "La seule chose que nous attendons de vous est de fournir honnêtement vos opinions et vos pensées, car les clients recherchent vos réponses honnêtes et sincères pour améliorer leurs produits et services.Veuillez fournir des informations correctes lors de votre inscription en tant que membre et mettre à jour les modifications au fur et à mesure qu'elles se produisent.",

  "faq_ques13" : "Pourquoi devrais-je fournir des informations sur mon ménage, la technologie ou les produits que j'achète?",
  "faq_ans13" : "Pour chaque enquête, nous recherchons un certain profil de répondants.Si nous savons à partir de votre profil d'informations que vos informations correspondent aux exigences du client, bingo!Nous pouvons presque certainement assurer que vous serez en mesure de remplir l'enquête avec succès et gagner la récompense pour cela.Cela augmente fondamentalement vos chances d'être sélectionné pour un sondage.",

  "faq_ques14" : "Quand vais-je recevoir mes cartes-cadeaux après avoir rempli un sondage WisePerks?",
  "faq_ans14" : "Les points que vous gagnez après avoir réussi une enquête sont accumulés dans votre seau. Une fois que ces points sont réalisés, vous pouvez échanger.",

  "faq_ques15" : "Qu'est-ce que Mon compte vraiment?",
  "faq_ans15" : "C'est simplement un moyen facile pour vous de voir vos informations personnelles, de faire les changements nécessaires, de voir votre activité de sondage, les récompenses gagnées, tout en un seul endroit.",

  "faq_ques16" : "Comment puis-je annuler mon compte?",
  "faq_ans16" : "Avec tellement venir votre chemin, nous sommes sûrs que vous ne voudriez pas faire ceci. Cependant, sur votre tableau de bord--> allez à Settings--> gérer les comptes--> désactiver compte",

  "faq_ques17" : "Comment fonctionne le système de points?",
  "faq_ans17p1" : "Chaque enquête est pour un certain nombre de points. Après avoir terminé avec succès une enquête, ces points sont ajoutés à vos revenus. Vous pouvez les échanger une fois que vous avez atteint le seuil minimal.",
  "faq_ans17p2" : "Dans de rares cas, en raison de difficultés imprévues liées à la connexion ou de pannes, votre réponse risque de ne pas être capturée par le client. Nous devons ensuite inverser ces points de votre compte. Si vous avez déjà échangé ces points et que le solde de votre compte est faible, vos revenus seront négatifs. Ces ajustements seront effectués lors de votre prochain cycle de rachat.",

  "faq_ques18" : "Quand les points pour un sondage sont-ils ajoutés à mon solde de points?",
  "faq_ans18" : "Vos points seront ajoutés à votre solde de points dans les 48 heures.",

  "faq_ques19" : "Quel est le seuil minimum de rachat?",
  "faq_ans19" : "Le seuil minimum pour le rachat est de 25000 points.En moyenne, vous devrez remplir 8 à 12 sondages pour être admissible au rachat.",

  "faq_ques20" : "Comment les points sont-ils convertis en argent de la carte-cadeau? Y a-t-il un ratio?",
  "faq_ans20" : "Oui. Selon votre pays, les points sont convertis en argent de la carte-cadeau. Les taux de conversion sont indiqués ci-dessous",
  "faq_ponits" : "Points",
  "faq_point" : "Point",
  "faq_cent" : "Cent",

  "faq_ques21" : "Vous avez encore d'autres questions?",
  "faq_ans21p1" : "N'hésitez pas à nous envoyer un courriel à",
  "faq_ans21p2" : "et nous vous reviendrons dans les 48 heures.",

  "Profiling_profiledashboard" : "Tableau de bord du profil", 
  "Profiling_completeprofile" : "Complétez votre profil et obtenez énorme",
  "Profiling_completepoints" : "points!",
  "Profiling_answercarefully" : "L'ensemble de questions générales ci-dessous sera utilisé pour créer votre profil personnel afin de vous fournir les meilleures opportunités de sondages à venir. S'il vous plaît prendre votre temps, répondre soigneusement et sincèrement.",

  "Profiling_cat_general" : "Générales",
  "Profiling_cat_personal" : "Personnel",
  "Profiling_cat_household" : "Ménage",
  "Profiling_cat_profession" : "Profession",

  "Profiling_bar_completed" : "% Terminé",
  
  "Profiling_update_ans_button" : "Mettre à jour et continuer", 
  "Profiling_save_ans_button" : "Sauver", 
  "Profiling_sc_ans_button" : "Enregistrer continuer", 

  "Category_thanku_msg1" : "Bon aller",
  "Category_thanku_msg2" : "peu plus et vous aurez",
  "Category_thanku_msg3" : "points dans votre compte.",

  "Category_thanku_update_msg1" : "Merci de mettre à jour vos informations!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
   "Category_Continue_button" : "Continuer",

  "OverAll_thanku_msg1" : "Merci de partager vos informations. Vous avez gagné", 
  "OverAll_thanku_msg2" : "Points.", 

  "Survey_history_surveyhistory" : "Historique de l'enquête",
  "Survey_history_fromthisscreen" : "À partir de cet écran, vous pouvez voir toutes vos transactions.",
  "Survey_history_show" : "Montrer",
  "Survey_history_entries" : "entrée",
  "Survey_history_date" : "Date",
  "Survey_history_details" : "Détails",
  "Survey_history_surveys" : "Enquêtes",
  "Survey_history_pointsreceived" : "Points reçus",
  "Survey_history_result" : "Résultat",
  "Survey_history_status" : "Statut",
  "Survey_history_status_complete" : "Complet", 
  "Survey_history_status_disqualified" : "Disqualifié", 
  "Survey_history_keyword" : "Mot-clé",
  "Survey_history_search" : "Recherche",
  "Survey_history_to" : "À",
  "Survey_history_selectoption" : "Sélectionnez l'option",
  "Survey_history_earned" : "Gagné",
  "Survey_history_redeemed" : "Racheté",
  "Survey_history_norecord" : "Aucun enregistrement trouvé",
  "Survey_history_Surveypts" : "Points d'arpentage",
  "Survey_history_Promocode" : "Code promo",

  "My_transactions_mytransactions" : "Mes transactions",
  "My_transactions_fromthisscreen" : "À partir de cet écran, vous pouvez voir toutes vos transactions.",
  "My_transactions_show" : "Montrer",
  "My_transactions_entries" : "entrée",
  "My_transactions_transactionid" : "ID de transaction",
  "My_transactions_date" : "Date",
  "My_transactions_details" : "Détails",
  "My_transactions_points" : "Points",
  "My_transactions_status" : "Statut",
  "My_transactions_keyword" : "Mot-clé",
  "My_transactions_search" : "Recherche",
  "My_transactions_to" : "À",
  "My_transactions_selectoption" : "Sélectionnez l'option",
  "My_transactions_status_earned" : "Gagné",
  "My_transactions_status_redeemed" : "Racheté",
  "My_transactions_status_pending" : "Attente",
  "My_transactions_status_reverted" : "Revenue",
  "My_transactions_status_declined" : "Refusé",
  "My_transactions_norecord" : "Aucun enregistrement trouvé",
  "My_transactions_off_msg1" : "Après ",
  "My_transactions_off_msg2" : ", tous les détails de votre transaction seront publiés dans l'onglet du livre de points",

  "Passbook_heading" : "Livre de points",
  "Passbook_show" : "Spectacle",
  "Passbook_entries" : "les entrées",
  "Passbook_search" : "Chercher",
  "Passbook_type" : "Type",
  "Passbook_surveyid" : "Enquêtes",
  "Passbook_date" : "Date",
  "Passbook_selectoption" : "Sélectionnez une option",
  "Passbook_credit" : "Crédit",
  "Passbook_debit" : "Débit",
  "Passbook_survey_id" : "Identifiant d'enquête",
  "Passbook_survey_pts" : "Points de sondage",
  "Passbook_earned_pts" : "Points",
  "Passbook_pending_pts" : "Points en attente",
  "Passbook_promo_code" : "Code promo",
  "Passbook_transaction_type" : "Type de transaction",
  "Passbook_balance" : "Équilibre",
  "Passbook_status" : "Statut",
  "Passbook_type_soi" : "enregistrement",
  "Passbook_type_doi" : "vérification de l'E-mail",
  "Passbook_type_profile" : "Achèvement du profil",
  "Passbook_type_survey" : "Sondage",
  "Passbook_type_doir" : "Vérification de courrier électronique par",
  "Passbook_type_doirt" : "Vérification du courrier électronique par référence",
  "Passbook_type_surveyr" : "Tentative d'enquête par",
  "Passbook_type_surveyrt" : "Tentative d'enquête par renvoi",
  "Passbook_type_reward" : "Récompense",
  "Passbook_type_pending" : "Ajustements",
  "Passbook_type_reverted" : "Points retournés",
  "Passbook_type_declined" : "Demandes refusées",
  "Passbook_type_redeem" : "Racheter",
  "Passbook_type_balance" : "Reporter",
  "Passbook_balance_vary" : "Le solde réel peut varier",
  "Passbook_survey_debit_reason" : "Nous apprécions vos efforts. Cependant, en raison de certaines pertes de connexion incontrôlables lors du transfert de données, votre réponse n'a pas pu être enregistrée. Il faudrait inverser les points que vous avez reçus plus tôt pour cette enquête. Toutes mes excuses pour le désagrément causé.",

  "Redeem_redeemed" : "Racheter",
  "Redeem_redeemhistory" : "Racheter l'histoire",
  "Redeem_history_norecord" : "Aucun enregistrement trouvé",
  "Redeem_date" : "Date",
  "Redeem_details" : "Détails",
  "Redeem_points" : "Points",
  "Redeem_status" : "Statut",
  "Redeem_lookslike" : "On dirait que vous avez manqué de vérifier votre compte de messagerie.",
  "Redeem_hurry" : "Dépêchez-vous! vous obtenez 1000 points une fois que vous vérifiez.",
  "Redeem_pleasecheck" : "Veuillez vérifier votre boîte de réception et cliquez sur le lien dans l'email de confirmation envoyé pour activer votre compte.",
  "Redeem_kindlycheck" : "Veuillez vérifier votre dossier de spam/junk aussi bien pour l'email de confirmation.",
  "Redeem_getconfirmationemail" : "Vous n'avez pas encore reçu un courriel de confirmation?",
  "Redeem_resendconfirmationemail" : "Envoyer un courriel de confirmation",
  "Redeem_rewardredemptionrule" : "Règle de rachat de récompenses",
  "Redeem_completesurvey" : "Enquête complète",
  "Redeem_earnpoints" : "Gagnez des points",
  "Redeem_redeemrewards" : "Racheter des récompenses",
  "Redeem_note" : "Note:",
  "Redeem_yourearnedpoints" : "Vos points gagnés deviendront remboursables après avoir qualifié le sondage. Pour racheter la récompense, les points minimums remboursables sur votre compte doivent être",
  "Redeem__points" : "Points.",
  "Redeem_availablebalance" : "Solde disponible:",
  "Redeem_historydetails" : "Cliquez ici pour voir les détails de votre historique de rachat.", 
  "Redeem_reqsucc" : "Félicitations! Vos points ont été rachetés. Veuillez consulter votre boîte aux lettres recommandée pour plus de détails.", 
  "Redeem_wisecheck_youhave" : "Toutes nos félicitations! Vous n'êtes qu'à un clic de votre récompense.",
  "Redeem_wisecheck_gcagainst" : "Carte-cadeau pour",
  "Redeem_wisecheck_points" : "Points.",
  "Redeem_wisecheck_issue" : "Système a capturé une activité non reconnue dans votre compte, en raison de laquelle vous ne pouvez pas envoyer une demande de rachat. Veuillez contacter à",
  "Redeem_emailverify_issue" : "Pour échanger vos points, nous vous demanderions de vérifier votre compte de messagerie enregistré.",

  "Redeem_unsubscribe_issue" : "Vous ne pouvez pas envoyer de demande d'échange, car vous vous êtes désabonné de WisePerks. Veuillez contacter support@wiseperks.com pour vous abonner à nouveau.",
  "Redeem_wisecheck_message1" : "Vous ne pouvez pas échanger pour des raisons de sécurité. S'il vous plaît contacter pour soutenir sur",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Confirmer",
  "Redeem_Cancel_button" : "Annuler",
  "Redeem_pending_hello" : "Salut",
  "Redeem_pending" : "Ajustement",
  "Redeem_pending_msg1" : "Votre compte affiche un ajustement de",
  "Redeem_pending_msg2" : "points à réaliser. S'il vous plaît confirmer pour lancer la transaction.",

  "Dashboard_wisecheck_issue" : "Le système a capturé une activité non reconnue dans votre compte, ce qui vous empêche de voir les sondages. Veuillez contacter support@wiseperks.com pour plus de précisions.",
  "Dashboard_unsubscribe_issue" : "Vous ne pouvez afficher aucun sondage, car vous vous êtes désabonné de WisePerks. Veuillez contacter support@wiseperks.com pour recevoir à nouveau les sondages.",


  "WC_DashboardMsg_1" : "Oops! Aucune enquête ne correspond à votre profil pour l'instant.",
  "WC_DashboardSubMsg_1" : "Ne vous inquiétez pas, nous mélangeons notre inventaire et vous enverrons bientôt de nouveaux sondages.",

  "WC_DashboardMsg_2" : "Nous actualisons votre tableau de bord. S'il vous plaît vérifier les nouveaux sondages après un certain temps.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Merci d'avoir terminé le sondage. Vous venez de gagner response_points points. Connectez-vous à votre tableau de bord pour voir le total des points accumulés.",
  "Survey_success_msg2" : "Merci d'avoir terminé le sondage. Vous venez de gagner response_points Points.",

  "Survey_success_but_nomore_survey" : "Félicitations! Vous venez de gagner response_points points pour l'achèvement du sondage. S'il vous plaît vérifier pour les sondages à venir après un certain temps.",
  
  "Survey_terminate_pnt_msg1" : "On dirait que certaines de vos informations sont légèrement différentes de l'exigence. Nous apprécions votre temps et vos efforts, sera l'ajout de response_points points à votre compte. Nous nous concentrons sur vous apporter des sondages plus lucratifs et intuitifs.",
  
  "Survey_terminate_nopnt_msg1" : "Certaines réponses semblent légèrement différentes de l'exigence. Nous vous enverrons plus de sondages. Merci pour votre temps et effort.",

  "TermsConditions_t&c" : "Termes et conditions",
  "TermsConditions_WhenRegister" : "Quand vous vous inscrivez",
  "TermsConditions_WhenYouCreate" : "Lorsque vous créez un compte personnel, nous vous demandons certaines informations personnelles, comme indiqué ici:",
  "TermsConditions_YouCanUpdate" : "Vous pouvez mettre à jour ou modifier les informations de votre profil de base à tout moment.",
  "TermsConditions_YouCanOptOut" : "Vous pouvez vous désinscrire ou fermer votre compte à tout moment.",
  "TermsConditions_WeRespectYour" : "Nous respectons votre vie privée et utilisons au mieux les technologies pour protéger vos informations contre tout accès physique et électronique non autorisé.",
  "TermsConditions_WeShareYour" : "Nous partageons vos informations uniquement avec les organisations qui sont tenues de faire fonctionner WiseWorks. Et ces organisations doivent respecter nos engagements en matière de confidentialité et de sécurité.",
  "TermsConditions_WhatInformation" : "Quelles informations nous recueillons de vous",
  "TermsConditions_DirectlyCollected" : "Directement collectés",
  "TermsConditions_EmailAddressAndPassword" : "Adresse e-mail et mot de passe de votre compte chez nous.",
  "TermsConditions_YourResponse" : "Votre réponse aux questions de profilage.",
  "TermsConditions_AnyCustomer" : "Toute correspondance avec le service clientèle.",
  "TermsConditions_AnyPersonalDetails" : "Toutes les données personnelles que vous partagez pour l'échange",
  "TermsConditions_AnyOtherInformation" : "Toute autre information fournie directement par vous.",
  "TermsConditions_UsageData" : "Des données d'utilisation",
  "TermsConditions_YourIPAddress" : "Votre adresse IP, votre localisation géographique et les cookies",
  "TermsConditions_InformationAboutTheDevice" : "Informations sur l'appareil que vous utilisez",
  "TermsConditions_YourSurveyActivity" : "Votre activité d’enquête, les points de votre compte, les paiements que vous recevez après l’échange.",
  "TermsConditions_HowDoWeUse" : "Comment utilisons-nous vos données",
  "TermsConditions_SendingYouInvitation" : "Vous envoyer des courriels d'invitation pour répondre au sondage auquel vous êtes admissible.",
  "TermsConditions_DetectAndPrevent" : "Détecter et prévenir les activités frauduleuses.",
  "TermsConditions_ImproveYourExperience" : "Améliorez votre expérience sur la plateforme WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Vos réponses sont transférées à nos tiers et partenaires affiliés dans un format agrégé et dépersonnalisé.",
  "TermsConditions_OurPartnersCanAsk" : "Nos partenaires peuvent vous demander de fournir votre nom, votre adresse e-mail et d'autres informations de contact lors d'une enquête.",
  "TermsConditions_YouAreFreeToDecline" : "Vous êtes libre de refuser de fournir vos informations à nos partenaires, si vous acceptez que vous leur fournissiez ces informations directement et non à WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Vos informations seront traitées conformément à la politique de confidentialité de l’entreprise et non à la politique de confidentialité de WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Nos partenaires s'engagent à respecter et à protéger votre vie privée.",
  "TermsConditions_WhatWeDont" : "Ce que nous ne faisons pas avec",
  "TermsConditions_WeDontSellOnThat" : "Nous ne vendons ces informations à personne et nous ne les utilisons pas pour vous vendre quoi que ce soit. Nous demanderons toujours la permission avant de partager vos données.",
  "TermsConditions_YourRightToControl" : "Votre droit de contrôler vos données",
  "TermsConditions_YouHaveFullControl" : "Vous avez le contrôle total de vos données.",
  "TermsConditions_YouCanAccess" : "Vous pouvez accéder, mettre à jour ou modifier les informations de votre profil de base quand vous le souhaitez.",
  "TermsConditions_YouCanUnsubscribe" : "Vous pouvez vous désabonner et désactiver votre compte.",
  "TermsConditions_YouAreFreeToGetYour" : "Vous êtes libre de supprimer votre profil en nous envoyant un courrier à support@wiseperks.com. Nous essaierions de résoudre votre problème et si vous souhaitez toujours supprimer votre compte, vous devez le faire dans les 72 heures.",
  "TermsConditions_DataSecurity" : "Sécurité des données",
  "TermsConditions_OurTechTeamWorks" : "Notre équipe technique travaille très fort pour protéger toutes vos données.",
  "TermsConditions_WeMakeUseOfLatest" : "Nous utilisons les dernières technologies et mesures pour éviter toute utilisation abusive ou accès non autorisé de vos données. Cependant, en raison de la nature de l'environnement en ligne, nous ne pouvons donner une assurance à 100% de la sécurité des données.",
  "TermsConditions_WeTryOurBestToRule" : "Nous faisons de notre mieux pour éliminer les risques, mais ne pouvons pas garantir un environnement sans risque. En acceptant ces termes et conditions, vous reconnaissez et acceptez ces risques.",
  "TermsConditions_AnyQuestions" : "Des questions, n'hesitez pas a nous contacter",
  "TermsConditions_IfYouHaveAnyQueries" : "Si vous avez des questions ou des plaintes concernant notre politique et nos procédures de confidentialité. S'il vous plaît envoyez-nous un email à support@wiseperks.com et nous vous contacterons dans les 48 heures.",

  "Validation_PleaseEnterProperDate" : "S'il vous plaît entrer la date de naissance appropriée",
  "Validation_PleaseSelectOne" : "Veuillez sélectionner une option!",
  "Validation_PleaseSelectValidOptions" : "Veuillez sélectionner des options valides!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Veuillez sélectionner au moins une option",
  "Validation_PleaseInsertTheZip" : "Veuillez insérer le code postal.",

  "Releases_releases" : "Versions",
  "Releases_theUseOfThis" : "L'utilisation de ce site Web est soumise aux conditions d'utilisation suivantes.",
  "Releases_featuresOfWP" : "Caracterisitques de WP Release",
  "Releases_updatedOn" : "mise à jour le",
  "Releases_WisePerksNow" : "WisePerks prend désormais en charge plusieurs langues",
  "Releases_youCanWalkThourgh" : "Vous pouvez marcher thourgh le site avec beaucoup plus de facilité maintenant que WisePerks est disponible dans votre langue preffered.",
  "Releases_languagesAvailable" : "Langues disponibles:",
  "Releases_EarnMore" : "Gagnez plus avec notre nouveau système de points",
  "Releases_theNewWisePerksAccumulates" : "Le nouveau WisePerks accumule des points dans votre compte. Ces points détiennent une valeur beaucoup plus élevée et sont faciles à gagner et à racheter. Avec plusieurs options de rachat à choisir, nous sommes sûrs que vous apprécierez l'expérience de prise de l'étude.",
  "Releases_accumulatePoints" : "Accumuler des points avec Hassal redeemption libre",
  "Releases_youGetTheBenefit" : "Vous obtenez l'avantage de gagner le même non. de points, quelle que soit la devise dans laquelle vous souhaitez les échanger.",
  "Releases_surveyDisqualification" : "Disqualification enquête n'est pas plus une mauvaise nouvelles",
  "Releases_youEarnPoints" : "Vous gagnez des points même si vous n'êtes pas admissible à une enquête, vous avez commencé à répondre.",
  "Releases_newAndImproved" : "Mise en page nouvelle et améliorée",
  "Releases_theFreshLookOf" : "Le regard frais de WisePerks vous fera tomber en amour avec elle. Il est maintenant beaucoup plus facile à utiliser et serait une grande expérience pour vous.",
  "Releases_whatAvatarAreYouToday" : "Quel avatar êtes-vous aujourd'hui",
  "Releases_youCanNowCreate" : "Vous pouvez maintenant créer votre propre avatar à la fonctionnalité sur votre tableau de bord.",
  "Releases_freshAndHotSurveys" : "Enquêtes fraîches et chaudes sur votre tableau de bord",
  "Releases_youDontHaveTo" : "Vous n'avez pas à vous soucier de refresing votre tableau de bord pour afficher de nouvelles enquêtes, nous allons garder votre tableau de bord rempli avec les dernières enquêtes automatiquement.",
  "Releases_promoCode" : "Fonction code promo",
  "Releases_promoCodeInfo" : "À la demande générale, WisePerks apporte chaque semaine des codes promotionnels à ses membres. Moyen rapide d'obtenir plus de récompenses.",
  "Releases_referFriend" : "Référer un ami",
  "Releases_referFriendInfo" : "Obtenez vos amis associés à WisePerks et obtenez des récompenses excitantes. Cherchez l’option Parrainer un ami dans votre compte.",

  "Dashboard_Promocode_Usepromo" : "Utiliser le code promo",
  "Dashboard_Promocode_to" : "À",
  "Dashboard_Promocode_earnminimum_1" : "gagner le minimum",
  "Dashboard_Promocode_earnminimum_2" : "points bonus",
  "Dashboard_Promocode_minimum" : "le minimum",
  "Dashboard_Promocode_minimumextrapoints" : "points supplémentaires sur chaque sondage complété aujourd'hui",
  "Dashboard_Promocode_apply" : "Appliquer",
  "Dashboard_Promocode_message1" : "Le code promo n'est pas valide!",
  "Dashboard_Promocode_message2" : "Toutes nos félicitations! Vous aurez minimum",
  "Dashboard_Promocode_message3" : "extra sur chaque enquête complétée aujourd'hui!",

  "Dashboard_modal_Enjoythefestive" : "Profitez de l'esprit festif en gagnant un minimum de",
  "Dashboard_modal_Extrapointson" : "de points supplémentaires sur chaque sondage que vous accomplissez aujourd'hui.",
  
  "refer_friend_title" : "Parrainez un ami et soyez récompensé",
  "refer_friend_step_first": "Inviter un ami à WisePerks",
  "refer_friend_step_second": "Friend s’inscrit avec succès sur WisePerks",
  "refer_friend_step_third": "Vous obtenez each_referral points",
  "refer_friend_link_title": "Invitez un ami maintenant!",
  "refer_friend_share_title": "Partagez le lien",
  "refer_friend_guideline_title": 'Comment "parrainer un ami" vous sera bénéfique:',
  "refer_friend_guideline_one": "S'il vous plaît partager le lien fourni ci-dessus avec vos références.",
  "refer_friend_guideline_two": "Vous pouvez parrainer autant de personnes que vous le souhaitez, une fois que 5 de vos filleuls auront vérifié leur compte et démarré l'activité d'enquête, vos points de filleuls gagnés seront ajoutés à votre compte.",
  "refer_friend_guideline_three": "Vous obtenez each_referral points chacun pour une référence réussie (avec un compte de messagerie vérifié et une activité d'enquête).",
  "refer_friend_guideline_four": "Les points seront gagnés en deux étapes, partial_referral sur la vérification par e-mail et partial_second_referral sur l'activité d'enquête par votre parrain.",
  "refer_friend_guideline_five": "Après 5 références réussies (avec un compte de messagerie électronique et une activité d'enquête), le lien de parrainage est désactivé.",

  }