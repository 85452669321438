import { Component } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';

import {googleAnalytics} from 'assets/js/google_analytics/google_analytics.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showLoadingIndicator = true;
  constructor( private _router:Router){
    this._router.events.distinctUntilChanged((routerEvent: Event, previous: any) => {
      
      if(routerEvent instanceof NavigationStart) {
        this.showLoadingIndicator = true;
        //console.log("Navigation Start Here");
      }

      if(routerEvent instanceof NavigationEnd) {
        this.showLoadingIndicator = false;
        //console.log("Navigation End Here");
        return previous.url === routerEvent.url;
      }
      return true;

    }).subscribe((x: any) => {
        googleAnalytics(x.url); // manage page url for google analytics imported from  google_analytics.js
    });
  }
}
