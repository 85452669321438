import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import { AdminComponent } from './admin/admin.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
/*const appRoutes: Routes = [
    { path: 'admin', component: AdminComponent },
    { 
        path: '',
        component: UserComponent,
        children: [
            { path: 'profile', component: ProfileComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: '', component: HomeComponent }
          ]
    },
    { path: '**', component: PageNotFoundComponent }
  ];


@NgModule({
    imports: [RouterModule.forRoot(appRoutes,{ enableTracing: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }*/

const routes: Routes = [
    { path: 'admin', component: AdminComponent },
    {path: '', loadChildren: 'app/user/user.module#UserModule'},
    { path: '**', component: PageNotFoundComponent }
];

export const ModuleRouting: ModuleWithProviders = RouterModule.forRoot(routes);

