// Chineese
export const TRANSLATIONS_CH = {
  "home_header_selectlang": "选择语言",
  "home_header_loginbutton": "登录",
  "home_header_signupbutton": "註冊",
  "home_header_homebutton": "家",
  
  "home_register_bannertxt1": "在这里你的",
  "home_register_bannertxt2": "思念",
  "home_register_bannertxt3": "可以取你",
  "home_register_bannertxt4": "奖励$",
  "home_register_bannertxt5": "獲得獎金",
  "home_register_bannertxt6": "在SignUp上",
  "home_register_fbsignup": "註冊FACEBOOK",
  "home_register_fblogin": "使用FACEBOOK登錄",
  "home_register_or": "要么",
  "home_register_emailsignup": "使用電子郵件註冊",
  "home_register_emaillogin": "用電子郵件登錄",
  "home_register_fpass": "忘記密碼？",
  "home_register_fname": "名字",
  "home_register_lname": "姓",
  "home_register_email": "電子郵件地址",
  "home_register_pass": "密碼",
  "home_register_cpass": "確認密碼",
  "home_register_tcpp": "加入即表示您同意我們的意見",
  "home_register_tcpptandcond": "條款和條件",
  "home_register_tcppand": "和",
  "home_register_tcppprivacy": "隱私政策",
  "home_register_join": "加入我們。免費",
  "home_register_login": "登錄",
  "home_register_name_required" : "名稱是必需的",
  "home_register_email_required" : "電子郵件是必填項",
  "home_register_invalid_email" : "電子郵件格式無效",
  "home_register_password_required" : "密碼是必需的",
  "home_register_password_lower_digit" : "密碼必須是6位數",
  "home_register_password_higher_digit" : "密碼必須使用15位數",
  "home_register_cpassword_required" : "確認密碼是必需的",
  "home_register_cpassword_lower_digit" : "確認密碼必須為6位數",
  "home_register_cpassword_higher_digit" : "確認密碼必須使用15位數",
  "home_register_cpassword_not_match" : "確認密碼不匹配",

  "home_register_fp_not_worry" : "請輸入您註冊的emailid。", 
  "home_register_fp_resetpass_msg" : "要重置密碼，請輸入您的註冊電子郵件ID。", 
  "home_register_fp_enter_email_msg" : "在此輸入您的電子郵件", 
  "home_register_fp_submit_button" : "提交", 
  "home_register_fp_temporary_sent_pass_msg" : "重置密碼鏈接已發送到您註冊的電子郵件ID。請盡快更新您的密碼。", 
  
  "home_works_howitworks": "這個怎麼運作？",
  "home_works_howitworkst1": "自我介紹", 
  "home_works_howitworksd1": "註冊成為會員",
  "home_works_howitworkst2": "接受調查",
  "home_works_howitworksd2": "開始接收直觀簡單的調查",
  "home_works_howitworkst3": "賺取積分",
  "home_works_howitworksd3": "給出最好的回答並積累積分",
  "home_works_howitworkst4": "贖回",
  "home_works_howitworksd4": "兌換積分以獲得許多令人興奮的獎勵",
  
  "home_aboutus_whoweare": "我們是誰？",
  "home_aboutus_whowearep1": "在WisePerks，我們努力確保您在線消費的時間是一種有益的體驗。我們與數百家渴望獲得反饋的公司合作，了解您對其產品或服務的看法。您提供各種服務，您的意見，但沒有這些服務的實際回報您的時間，不像WisePerks  - 在每次參與調查為您的時間偉大的現金獎勵。",
  "home_aboutus_whowearep2": "我們重視您的反饋意見，並希望您獲得獎勵。我們根據您的興趣對各種主題進行調查，並使其易於回答。通過各種調查，您每月可輕鬆賺取約50至100美元。讓我們共同努力實現它。",
  
  "home_earn_more_surabt": "什麼調查問？",
  "home_earn_more_bp": "業務專家",
  "home_earn_more_consumers": "消費者",
  "home_earn_more_hc": "健康與健身",
  "home_earn_more_entertainment": "娛樂和娛樂",
  "home_earn_more_gaming": "遊戲怪胎",
  "home_earn_more_tech": "科技精明",
  "home_earn_more_bonus_survey": "獎金調查",
  
  "home_reward_whatuearn": "你賺的是什麼？",
  "home_reward_whatuearnp1": "我們喜歡簡單易懂。您可以獲得所有成功完成的調查的積分。您完成的調查越多，獲得的積分就越多。超過某個閾值後，積分可以兌換為您選擇的禮品卡。",
  "home_reward_whatuearnp2": "準備兌換後，選擇您喜歡的選項 - 亞馬遜，星巴克，iTunes等等。我們還會不時發放獎金和感謝上帝週五的優惠。",
  
  "home_counter_earnedover": "會員已經賺了",
  
  "home_contact_getintouch": "保持聯繫",
  "home_contact_emailus": "如有任何問題，請發送電子郵件至",
  
  "home_footer_faq": "常見問題解答",
  "home_footer_pp": "隱私政策",
  "home_footer_tc": "及條件",
  "home_footer_uf": "即將推出的功能",
  "home_footer_copyright": "版權所有©current_year WisePerks。版權所有。",

  "home_header_home": "家", 
  "home_footer_toknowmore": "了解更多",
  "home_footer_createacc": "免費創建一個帳戶，加入我們成千上萬的快樂會員。",
  "home_footer_registernow": "現在註冊",
  
  "home_reset_password" : "重設密碼", 
  "home_reset_pass_content" : "您已請求重置密碼", 
  
  "dashboard_sidebar_selectavatar" : "選擇你的頭像", 
  "dashboard_sidebar_uploadavatar" : "點擊此處上傳您的圖片", 
  "dashboard_sidebar_pbank" : "點",
  "dashboard_sidebar_accumulated" : "掙",
  "dashboard_sidebar_realized" : "集", 
  "dashboard_sidebar_pending" : "調整",
  "dashboard_sidebar_earned_tooltip" : "到目前为止收集的点数。",
  "dashboard_sidebar_pending_tooltip" : "需要从您的帐户撤消的积分。", 
  "dashboard_sidebar_main" : "主要",
  "dashboard_sidebar_dashboard" : "儀表板",
  "dashboard_sidebar_profile" : "輪廓",
  "dashboard_sidebar_updateprofile" : "更新配置文件",
  "dashboard_sidebar_shistory" : "調查歷史",
  "dashboard_sidebar_mtrans" : "我的交易",
  "dashboard_sidebar_passbook" : "積分書",
  "dashboard_sidebar_redeem" : "贖回",
  "dashboard_sidebar_refer_friend" : "推薦給朋友",
  "dashboard_sidebar_logout" : "登出",
  
  "dashboard_sidebar_tutorial1" : "向您顯示所有可用的調查以及您可以從中獲得的收益。",
  "dashboard_sidebar_tutorial2" : "回答幾個問題，讓我們更好地了解您，並幫助我們向您發送您感興趣的調查問卷。請注意，對於某些問題，您無法在提交後更新回复。",
  "dashboard_sidebar_tutorial2update" : "您可以在此處更新您的回复，但很少有問題無法進行更新。",
  "dashboard_sidebar_tutorial3" : "在此處查找您嘗試過的所有調查。",
  "dashboard_sidebar_tutorial4" : "幫助您跟踪WisePerks上的所有交易。",
  "dashboard_sidebar_tutorial5" : "幾乎沒有快速步驟將您的已實現積分轉換為獎勵。",
  "dashboard_sidebar_tutorial6" : "查看從您的帳戶中扣除/貸記的積分的整個交易歷史記錄。",
  "dashboard_sidebar_gotit" : "得到它了",

  "dashboard_sidebar_note" : "請注意",
  "dashboard_sidebar_note1" : "必須誠實地回答所有回复。",
  "dashboard_sidebar_note2" : "請具體而準確地提供開放式回复。",
  "dashboard_sidebar_note3" : "不要速度！設置最小調查完成時間。",
  "dashboard_sidebar_note4" : "在提出相同問題時，請提供一致的回复。",
  "dashboard_sidebar_note5" : "將添加新的調查，並將從您的信息中心自動刪除已關閉的調查。",
  "dashboard_sidebar_note6" : "只需通過推薦即可獲得一些快速積分。",

  "dashboard_setting_hi" : "你好",
  "dashboard_setting_minfo" : "會員資訊",
  "dashboard_setting_macc" : "管理帳戶",
  "dashboard_setting_upass" : "更新密碼",
  "dashboard_setting_yourname" : "你的名字是",
  "dashboard_setting_fname" : "名字",
  "dashboard_setting_lname" : "姓",
  "dashboard_setting_save" : "保存更改",
  "dashboard_setting_bornon" : "你出生於",
  "dashboard_setting_youare" : "你是一個",
  "dashboard_setting_postal" : "你的郵政編碼是",
  "dashboard_setting_emailus" : "如有任何幫助或疑問，請發送電子郵件至",
  "dashboard_setting_membersince" : "你是以來的會員",
  "dashboard_setting_taken" : "你已經服用了",
  "dashboard_setting_completed" : "調查並完成",
  "dashboard_setting_ofthem" : "他們",
  "dashboard_setting_earned" : "你賺了",
  "dashboard_setting_pts" : "點",
  "dashboard_setting_started" : "自從你開始。",
  "dashboard_register_email" : "您是從我們這裡註冊的",
  "dashboard_setting_preferences" : "管理您的偏好",
  "dashboard_setting_emailoptout" : "電子郵件退出",
  "dashboard_setting_futureemails" : "選擇不接收來自WisePerks的未來電子郵件。",
  "dashboard_setting_futureemailsflash" : "我們的電子郵件會讓您發布所有可用的有利可圖的調查。你確定你不想收到它們嗎？",
  "dashboard_setting_nrecemail" : "我們的電子郵件會讓您發布所有可用的有利可圖的調查。你確定你不想阻止他們。",
  "dashboard_setting_emailout_modalmsg" : "您確定要退出接收來自WisePerks的未來電子郵件嗎？",
  "dashboard_setting_emailin_modalmsg" : "您確定要開始接收來自WisePerks的未來電子郵件嗎？",
  "dashboard_setting_emailout_succmsg" : "您已成功停止接收來自WisePerks的電子郵件",
  "dashboard_setting_emailin_succmsg" : "您已成功開始接收來自WisePerks的電子郵件",
  "dashboard_setting_deactivateacc" : "關閉戶口",
  "dashboard_setting_disableprofile" : "停用您的帳戶會從WisePerks中停用您的個人資料。",
  "dashboard_setting_deactivateacc_msg" : "您確定要在WisePerks上停用您的帳戶嗎？",
  "dashboard_setting_deactivateacc_flash" : "如果您有任何投訴或反饋，請與我們分享。我們會優先處理它。給我們一個機會。您確定要刪除我們的帳戶嗎？",
  "dashboard_setting_changelang" : "改變語言",
  "dashboard_setting_preferredlang" : "選擇您的首選語言",
  "dashboard_setting_updatepass" : "更新密碼",
  "dashboard_setting_plsupdate" : "請在下面更新您的密碼。",
  "dashboard_setting_oldpass" : "舊密碼",
  "dashboard_setting_enteroldpass" : "輸入舊密碼",
  "dashboard_setting_newpass" : "新密碼",
  "dashboard_setting_enternewpass" : "輸入新密碼",
  "dashboard_setting_confpass" : "確認密碼",
  "dashboard_setting_enternewpassagain" : "再次輸入新密碼",
  "dashboard_setting_update" : "更新",

  "dashboard_doi_welcome" : "歡迎",
  "dashboard_doi_welbck" : "歡迎回來",
  "dashboard_doi_accntverified" : "您似乎錯過了驗證電子郵件帳戶的情況。",
  "dashboard_doi_getaccess" : "匆忙！你得到",
  "dashboard_doi_getaccess2" : "一旦你驗證就得分。",
  "dashboard_doi_activateacc" : "請檢查您的收件箱，然後點擊發送到您註冊的電子郵件帳戶的帳戶驗證電子郵件中的鏈接。",
  "dashboard_doi_chckspam" : "請檢查您的Spam / Junk文件夾，以防您無法找到驗證郵件。",
  "dashboard_doi_didntgetconf" : "還沒有收到驗證郵件呢？",
  "dashboard_doi_resendconf" : "重新發送驗證郵件",
  "dashboard_doi_resendconfsucc" : "驗證郵件已發送至您註冊的電子郵件。", 

  "dashboard_survey_availsurveys" : "可用的調查",
  "dashboard_survey_wisechecksurveys1" : "由於某些安全原因，您無法查看調查。請聯繫支持",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "取消訂閱原因不可用的調查",
  "dashboard_featured" : "精選",
  "dashboard_profile_survey" : "簡介調查",
  "dashboard_survey_start" : "開始",
  "dashboard_survey_on" : "上",
  "dashboard_survey_off" : "離",
  "dashboard_survey_point" : "點",
  "dashboard_survey_points" : "點",
  "dashboard_survey_minute" : "分鐘",
  "dashboard_survey_minutes" : "分鐘",

  "demography_hi" : "你好",
  "demography_welcome" : "歡迎來到WisePerks。你剛收穫了自己",
  "demography_points" : "通過註冊我們。",
  "demography_share" : "請在下面的表格中分享更多細節，以幫助我們為您提供相關調查。",

  "demography_gender" : "我是一個",
  "demography_male" : "男",
  "demography_female" : "女",
  "demography_birthday" : "生日",
  "demography_month" : "月", 
  "demography_day" : "天", 
  "demography_year" : "年", 
  "demography_jan" : "一月",
  "demography_feb" : "二月",
  "demography_mar" : "遊行",
  "demography_apr" : "四月",
  "demography_may" : "可以",
  "demography_jun" : "六月",
  "demography_jul" : "七月",
  "demography_aug" : "八月",
  "demography_sep" : "九月",
  "demography_oct" : "十月",
  "demography_nov" : "十一月",
  "demography_dec" : "十二月",
  "demography_hearabout" : "你在哪裡聽說過WisePerks？",
  "demography_opt1" : "選擇最接近的適用範圍",
  "demography_opt2" : "郵件/新聞",
  "demography_opt3" : "Facebook的",
  "demography_opt4" : "論壇或博客",
  "demography_opt5" : "朋友",
  "demography_opt6" : "LinkedIn",
  "demography_opt7" : "搜索引擎",
  "demography_opt8" : "電視廣告",
  "demography_opt9" : "推特",
  "demography_opt10" : "網站",
  "demography_opt11" : "YouTube的",
  "demography_opt12" : "其他",
  "demography_zipcode": "郵政編碼",
  "demography_submit" : "提交",

  "privacypolicy_privacypolicy" : "隱私政策",
  "privacypolicy_privacyisimp" : "你的隱私對我們很重要。",
  "privacypolicy_lastupdated" : "最後更新",

  "privacypolicy_para1" : 'WiseWorks（連同其附屬公司，“公司”）擁有並經營許多不同的網站，移動應用程序和互動服務，包括但不限於Wisesample.com，Flapbucks.com，WisePerks.com和其他（統稱為“ WiseWorks網站“）。本隱私政策（“隱私政策”）適用於WiseWorks網站以及所有功能，移動應用程序，電子郵件，在線服務和其他功能，無論是通過計算機，移動設備還是其他方式訪問，以及通過或通過WiseWorks站點和功能（“服務”）。本隱私政策僅涵蓋公司通過WiseWorks網站和功能以及我們的服務收集的信息，不包括公司在線下收到或收集的任何信息（除非特別說明）或由我們可能提供的任何不相關的網站或公司收集的信息。提供鏈接。',

  "privacypolicy_para2" : "預計小組成員/被訪者應誠實，真實地填寫他的個人資料，不得提交錯誤或不正確的信息，以便有資格獲得更多調查。預計小組成員/受訪者在調查填寫期間不得操縱他的答案或使用其他不公平的手段（更改知識產權，多個賬戶等）以獲得獎勵。 WisePerks有足夠的安全措施來檢測此類異常，如果發現此類行為，小組成員將被禁止/阻止WisePerks，並且他/她的所有兌換請求都將被取消。",

  "privacypolicy_para3" : "WiseWorks致力於保護您的隱私。以下隱私政策說明了在瀏覽我們的網站和服務時如何保存和處理您的個人信息。",

  "privacypolicy_topic1" : "WisePerks.com向我收集哪些個人身份信息？",
  "privacypolicy_topic1ans" : "WisePerks.com以多種方式從我們網站和網絡的不同部分收集信息。註冊時會收集一些信息。在會員註冊期間，WisePerks.com會詢問您的姓名，電子郵件地址，郵政編碼和家庭信息。您自願提供的信息越準確，我們就能越好地為您提供服務和獎勵。此外，如果您通過任何方式聯繫WisePerks.com，我們可能會記錄該通信。每當WisePerks.com收集任何此類信息時，我們的目標是在該頁面上包含本隱私政策的鏈接。",

  "privacypolicy_topic2" : "什麼是cookies以及WisePerks.com如何使用它們？",
  "privacypolicy_topic2ans" : "Cookie是從Web服務器發送到您的瀏覽器並存儲在計算機硬盤上的電子數據包。作為向您提供特定調查，信息和個性化服務的一部分，WisePerks.com可能會使用cookie來存儲並有時跟踪您的相關信息。 WisePerks.com可能會要求您接受cookie以便為您準備此類特定內容。這也避免了您每次都必須輸入登錄信息。",

  "privacypolicy_topic3" : "什麼是允許我更新，編輯，更改或刪除我的個人帳戶信息的WisePerks.com政策？",
  "privacypolicy_topic3ans" : "您可以隨時使用WisePerks.com登錄和密碼編輯您的WisePerks.com帳戶信息。請聯繫",
  "privacypolicy_topic3ans2" : "有關停用WisePerks.com帳戶的進一步說明。",

  "privacypolicy_topic4" : "誰在收集信息和目的是什麼？",
  "privacypolicy_topic4ansp1" : "WisePerks.com通過調查和註冊以及其他方式收集多種信息，這有助於其研究客戶為消費者開發新產品和服務。您僅與WisePerks.com共享該信息，除非您參與的任何調查中另有明確說明。如果數據由WiseWorks或WiseWorks網站以外的任何公司收集和/或維護，您將在數據收集或傳輸之前收到通知。如果您不希望共享數據，則可以選擇不允許通過不使用該特定調查或產品進行轉移。",
  "privacypolicy_topic4ansp2" : "我們在WisePerks.com上提供的獎勵和促銷活動可以由其他公司獨立贊助，也可以由WisePerks.com和其他公司共同贊助。在此類促銷期間收集的一些數據可以與讚助商共享。但是，您將在數據收集或轉移之前收到通知。如果您不想共享數據，則可以決定不參與該特定促銷。",
  "privacypolicy_topic4ansp3" : "任何特定的敏感信息（如指定醫療或健康狀況，種族或民族血統，政治觀點，宗教或精神信仰，行業和工會會員資格或指定個人性生活的信息）將得到額外的關注。如果WisePerks.com打算以不同於最初收集的目的向另一家公司分享此類信息，那麼在任何此類披露之前，您將被明確要求獲得許可。",

  "privacypolicy_topic5" : "WisePerks.com與誰分享我的信息？",
  "privacypolicy_topic5ansp1" : "我們非常重視隱私。 WisePerks.com不會披露您的任何個人身份信息，除非我們得到您的明確許可或在任何特殊情況下，法律要求或在下面詳細說明的情況下。以下描述了您的信息可能被披露的一些方式。有關如何共享您的個人信息的詳細信息，請參閱我們服務的服務條款。",
  "privacypolicy_topic5ansp2" : "業務合作夥伴，客戶和讚助商：",
  "privacypolicy_topic5ansp3" : "WisePerks.com可能會向我們的合作夥伴，客戶或贊助商披露您的個人信息。但是，如果有關您的任何個人數據被分享，我們會在收集或傳輸數據之前告訴您。我們提供的許多活動都要求贊助商提供更多信息。通過您請求更多信息，您授予WisePerks.com與讚助商共享您的信息的權限，以便他們能夠滿足您的請求。在許多情況下，只會共享您的電子郵件地址。如果我們的合作夥伴會分享更多信息，我們會提前通知您。您可能不時有機會接收我們合作夥伴提供的測試材料或其他產品或特別優惠。如果您選擇接收來自這些第三方的信息，WisePerks.com將（經您許可）與他們分享您的姓名和電子郵件地址。",
  "privacypolicy_topic5ansp4" : "WisePerks.com也可能在某些特殊情況下披露個人信息，因為有必要披露此信息以識別，聯繫或對可能違反WisePerks.com政策和服務條款的人員提起法律訴訟，或者可能導致任何傷害或干擾（無論是有意還是無意）WisePerks.com的產品，服務，權利或財產，其他WisePerks.com會員和用戶，或任何可能受此類活動影響的人。當我們真誠地相信法律要求以及我們認為維護，服務和改進我們的產品和服務所必需的行政和其他目的時，WisePerks.com可能會披露或訪問帳戶信息。",

  "privacypolicy_topic6" : "我們用於處理您的信息的法律依據是什麼？",
  "privacypolicy_topic6ans" : 'WiseWorks致力於隱私和數據保護。我們的隱私政策採用歐盟頒布的通用數據保護法規（“GDPR”）作為我們，我們的員工和供應商遵守的基準。',

  "privacypolicy_topic7" : "關於我的信息的收集，使用和分發，我有哪些選擇？",
  "privacypolicy_topic7ans" : "只有WisePerks.com（或根據保密協議與WisePerks.com合作的合作夥伴）才會向您發送任何直接郵件，前提是您表示不反對這些郵件。您還可以選擇Cookie的工作方式。通過更改您的互聯網瀏覽器設置，您可以選擇接受所有cookie，在設置cookie時得到通知，或拒絕所有cookie請求。如果您選擇拒絕所有cookie，您將無法使用那些需要事先註冊才能參與的WisePerks.com服務。 WisePerks.com不會向任何人出售或租賃任何用戶信息。如果您的數據將與任何第三方共享，我們將在收集或轉移數據時始終通知您，並且您始終可以選擇不允許共享。",

  "privacypolicy_topic8" : "WisePerks.com採取了哪些安全預防措施來保護我的帳戶信息的任何丟失，誤用或更改？",
  "privacypolicy_topic8ansp1" : "您的WisePerks.com帳戶信息受密碼保護，因此您和您只能訪問此信息。",
  "privacypolicy_topic8ansp2" : "我們強烈建議您不要與任何人共享密碼。我們絕不會在未經請求的電話或電子郵件中詢問您的密碼。完成活動後，請退出WisePerks.com帳戶並關閉瀏覽器窗口。如果您使用的是公共計算機或與其他人共享計算機，這可以確保其他人無法訪問您的個人信息和帳戶。每當WisePerks.com處理上述個人信息時，無論發生何種情況，WisePerks.com都會採取措施確保您的信息得到安全處理，並符合相關的服務條款和本隱私政策。不幸的是，互聯網上的數據傳輸不能保證100％安全。因此，儘管我們努力保護您的個人信息，但WisePerks.com無法確保或保證您傳輸給我們或我們的在線產品或服務的任何信息的安全性，您需要自擔風險。收到您的數據後，我們會盡最大努力確保其在系統上的安全性。",

  "privacypolicy_topic9" : "關於我的隱私，我還應該知道什麼？",
  "privacypolicy_topic9ans" : "請記住，無論何時（以及何處）您選擇在線披露個人信息（例如通過電子郵件，社交網站，博客或聊天區域），該信息都可以被任何其他用戶收集和使用。如果您在線發佈公眾可以訪問的個人信息，您可能會收到來自其他方的未經請求的消息。最終，您自行負責維護您的密碼和/或任何帳戶信息的保密性。無論何時在線，請務必小心並負責。",

  "privacypolicy_topic10" : "WisePerks.com如何保護我孩子的隱私？",
  "privacypolicy_topic10ans" : "WisePerks.com獲得事先父母同意，其中調查要求兒童參與，並且對於6-15歲兒童的任何此類調查也需要父母監督。我們特別注意保護我們網站上年輕用戶的安全和隱私，我們鼓勵您在線參與孩子的體驗。我們不允許任何營銷或兒童的研究，並且兒童的研究中完全禁止某些主題：姓名/帳戶名稱/電子郵件或實際地址，電話號碼，種族或民族，宗教或演員，家庭收入，家庭疾病，父母或其他家庭成員的婚姻狀況，關係事項，學校或大學的成績，酒精，性問題，煙草和槍支。",

  "privacypolicy_topic11" : "國際用戶特別注意事項",
  "privacypolicy_topic11ansp1" : "該網站在美國託管。如果您是從歐盟，亞洲或任何其他地區訪問本網站的用戶，該地區有關於個人數據收集，使用和披露的法律或法規，這些地區與美國法律不同，請注意您將個人數據轉移到美國與歐盟和其他地區沒有相同的數據保護法，並且您同意提供您的個人數據：",
  "privacypolicy_topic11ansp2" : "根據隱私政策將您的個人數據用於上述用途;以及如上所述將您的個人數據傳輸到美國。您承認，美國的法律以某種方式處理您的信息，這種信息可能與其他國家和司法轄區的法律所要求的待遇大不相同，並且不受保護。如果您不希望將您的信息轉移到美國，則您不應與我們分享您的信息，也不應使用本網站。在您所在國家/地區的法律允許的範圍內，您明確放棄任何權利，您可能需要根據美國以外的任何國家或地區的法律處理您的身份識別信息。但是，上述豁免可能在某些國家（如歐洲聯盟的成員國）具有法律約束力。在您所在的國家/地區，這是不具有法律約束力的，此上述豁免不適用於您。",

  "privacypolicy_topic12" : "本隱私政策的變更和更新",
  "privacypolicy_topic12ans" : "WiseWorks保留自行決定隨時以任何理由更改，修改，添加或刪除本隱私政策部分內容的權利。每次WiseWorks行使此類權利時，您都必須肯定地接受更新的隱私政策，如果您不這樣做，您將無法再訪問或使用本網站。",

    "privacypolicy_topic13": "特别注意那些寻求GDPR / CCPA详细信息的人",
    "privacypolicy_topic13ansp1": "请",
    "privacypolicy_topic13ansp2": "点击这里",
    "privacypolicy_topic13ansp3": "了解有关GDPR（通用数据保护法规）和CCPA（加利福尼亚消费者隐私法）合规性详细信息的更多信息。",

  "privacypolicy_addinfo" : "附加信息",
  "privacypolicy_sentto" : "有關此問題的任何其他問題應發送至",

  "faq_faq" : "經常問的問題",
  "faq_getans" : "獲得所有問題的答案。",

  "faq_ques1" : "什麼是WisePerks？",
  "faq_ans1" : "我們很高興你問。 WisePerks是一個在線調查網站。許多品牌和公司都在尋找受訪者提供他們的意見並獲得回報。 WisePerks就是這樣一個平台，您可以在其中發表意見並獲得獎勵。",

  "faq_ques2" : "加入是免費的嗎？",
  "faq_ans2" : "是的，可以免費加入。事實上，如果有人要求您加入費用，我們會希望您向我們報告。",

  "faq_ques3" : "我可以推薦我的朋友嗎？",
  "faq_ans3" : '是的，您可以邀請您的朋友和同事加入，也可以獲得獎勵。請單擊儀表板上的“推薦朋友”鏈接以獲取所有詳細信息。',

  "faq_ques4" : "我賺了多少錢？",
  "faq_ans4" : "現在，在我們的新模型中，您可以通過多種方式獲得積分，而不僅僅是通過調查。您可以通過以下方式獲得積分：通過驗證您的電子郵件帳戶，註冊以完成您的個人資料，當然還可以完成任何調查，甚至是您的意外調查。所以我們有充分的理由讓你入門不是嗎？",

  "faq_ques5" : "我如何成為WisePerks.com會員？",
  "faq_ans5" : '在瀏覽器中打開WisePerks.com。單擊“註冊”選項卡創建成員身份登錄。只需按照步驟操作，它將指導您完成剩餘的過程。您必須通過點擊我們發送給您提供的電子郵件的確認電子郵件來確認您的加入意圖。',

  "faq_ques6" : "您提到的確認電子郵件在哪裡？",
  "faq_ans6p1" : "註冊時，確認電子郵件會自動發送到您提供的電子郵件地址。如果您在收件箱中沒有看到該電子郵件，請檢查您的垃圾郵件或垃圾郵件文件夾。為了避免我們以後的任何電子郵件和調查邀請/郵件到達您的垃圾郵件文件夾，請添加",
  "faq_ans6p2" : "到您的已批准發件人列表或您郵箱中的聯繫人。如果您仍然找不到該確認電子郵件，請登錄我的帳戶，確認您的電子郵件地址是否正確，並請求另一封確認電子郵件。",

  "faq_ques7" : "我如何參加調查？",
  "faq_ans7" : '輸入用戶名和密碼後登錄後，您將進入儀表板並查看可以進行的所有調查。我們還會將調查鏈接發送到您註冊的電子郵件ID。您可以直接從郵箱中獲取鏈接。',

  "faq_ques8" : "我的回復對我的數據有多保密？",
  "faq_ans8" : "對於WisePerks來說，保持用戶隱私和機密性是最重要的。我們的客戶只對您的想法，消費者或您對某事的看法感興趣。我們在WisePerks，從不向我們的客戶分享/傳遞任何個人信息，並且沒有任何調查WisePerks也不會向第三方出售或出租任何信息。",

  "faq_ques9" : "這些調查還有多長時間？",
  "faq_ans9" : "大多數調查只需要大約10到15分鐘，甚至更少。但是，我們可能會不時進行更長時間的調查。每次調查，我們會提前告訴您需要多長時間，但請記住，較長的調查也會有更高的回報。",

  "faq_ques10" : "有人可以參加WisePerks調查嗎？",
  "faq_ans10" : "您必須年滿13歲才能註冊WisePerks。雖然有時我們會邀請您參加調查，與年輕的家庭成員一起參加。",

  "faq_ques11" : "你怎麼知道我有資格參加調查？",
  "faq_ans11" : "我們的算法會將您的個人資料信息與可用的調查相匹配，以確保您獲得最合適的調查。調查開始時，通常前幾個問題確定您是否是我們的客戶正在尋找特定調查的受訪者。我們有大量的調查來尋找許多不同類型的人（例如，經常看電影，玩特定運動的人，有孩子的女人等），每天都會出現新的調查！",

  "faq_ques12" : "我對WisePerks的義務是什麼？",
  "faq_ans12" : "我們唯一期望的是誠實地提供您的意見和想法，因為客戶正在尋找您的誠實和真實的答案，以改進他們的產品和服務。註冊成為會員時請提供正確的信息，並在發生任何更改時進行更新。",

  "faq_ques13" : "我為什麼要提供有關我購買的家庭，技術或產品的信息？",
  "faq_ans13" : "對於每項調查，我們都在尋找受訪者的特定情況。如果我們從您的個人資料中了解到您的信息符合客戶要求，賓果！我們幾乎可以肯定地確保您能夠成功完成調查並獲得獎勵。這基本上增加了您被選中進行調查的機會。",

  "faq_ques14" : "完成WisePerks調查後，我什麼時候會收到禮品卡？",
  "faq_ans14" : "成功完成調查後獲得的積分將累積在您的桶中。一旦實現了這些要點，您就可以兌換。",

  "faq_ques15" : "我的賬戶到底是什麼？",
  "faq_ans15" : "這只是一種簡單的方式，您可以查看您的個人信息，根據需要進行任何更改，查看您的調查活動，獲得的獎勵，一切都在一個地方。",

  "faq_ques16" : "如何取消我的帳戶？",
  "faq_ans16" : "隨著你的到來，我們相信你不會想要這樣做。但是，在您的儀表板上 - >轉到設置 - >管理帳戶 - >停用帳戶",

  "faq_ques17" : "積分系統如何運作？",
  "faq_ans17p1" : "每項調查都針對一定數量的積分。成功完成調查後，這些積分將添加到您的收入中。達到最低門檻後，您可以兌換它們。",
  "faq_ans17p2" : "在少数情况下，由于连接或故障方面的一些不可预见的挑战，您的回复可能不会在我们的客户端获得。然后，我们必须撤消您帐户中的这些要点。如果您已经赎回了这些积分并且您的帐户余额很低，那么您的收入将为负数。这些调整将在您的下一个兑换周期中进行。",

  "faq_ques18" : "調查的積分多久會加到我的積分餘額中？",
  "faq_ans18" : "您的積分將在48小時內添加到積分餘額中。",

  "faq_ques19" : "兌換的最低門檻是多少？",
  "faq_ans19" : "贖回的最低門檻是25000點。平均而言，您需要完成8-12次調查才有資格獲得贖回。",

  "faq_ques20" : "積分如何轉換為禮品卡現金？有比例嗎？",
  "faq_ans20" : "是。根據您所在的國家/地區，積分將轉換為禮品卡現金。轉換率如下所示",
  "faq_ponits" : "點",
  "faq_point" : "點",
  "faq_cent" : "一分錢",

  "faq_ques21" : "還有更多問題嗎？",
  "faq_ans21p1" : "請隨時給我們發電子郵件 ",
  "faq_ans21p2" : "我們會在48小時內回复您。",

  "Profiling_profiledashboard" : "配置文件儀表板", 
  "Profiling_completeprofile" : "完成您的個人資料並獲得驚人的收益",
  "Profiling_completepoints" : "分！",
  "Profiling_answercarefully" : "以下一般性問題將用於創建您的個人資料，以便為您提供最佳的未來調查機會。請花點時間，認真回答並如實回答。",

  "Profiling_cat_general" : "一般",
  "Profiling_cat_personal" : "個人",
  "Profiling_cat_household" : "家庭",
  "Profiling_cat_profession" : "職業",

  "Profiling_bar_completed" : "％已完成",
  
  "Profiling_update_ans_button" : "更新並繼續",
  "Profiling_save_ans_button" : "保存",
  "Profiling_sc_ans_button" : "保存並繼續",

  "Category_thanku_msg1" : "好的",
  "Category_thanku_msg2" : "更多，你會有",
  "Category_thanku_msg3" : "您帳戶中的積分。",

  "Category_thanku_update_msg1" : "感謝您更新您的信息！",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "繼續",

  "OverAll_thanku_msg1" : "感謝您分享您的信息。你賺了",
  "OverAll_thanku_msg2" : "點。",

  "Survey_history_surveyhistory" : "調查歷史",
  "Survey_history_fromthisscreen" : "在此屏幕中，您可以查看所有交易。",
  "Survey_history_show" : "節目",
  "Survey_history_entries" : "項",
  "Survey_history_date" : "日期",
  "Survey_history_details" : "細節",
  "Survey_history_surveys" : "調查",
  "Survey_history_pointsreceived" : "收到的積分",
  "Survey_history_result" : "結果",
  "Survey_history_status" : "狀態",
  "Survey_history_status_complete" : "完成", 
  "Survey_history_status_disqualified" : "被取消資格",
  "Survey_history_keyword" : "關鍵詞",
  "Survey_history_search" : "搜索",
  "Survey_history_to" : "至",
  "Survey_history_selectoption" : "選擇選項",
  "Survey_history_earned" : "掙",
  "Survey_history_redeemed" : "贖回",
  "Survey_history_norecord" : "沒有找到記錄",
  "Survey_history_Surveypts" : "調查點",
  "Survey_history_Promocode" : "促銷代碼",

  "My_transactions_mytransactions" : "我的交易",
  "My_transactions_fromthisscreen" : "在此屏幕中，您可以查看所有交易。",
  "My_transactions_show" : "節目",
  "My_transactions_entries" : "項",
  "My_transactions_transactionid" : "交易ID",
  "My_transactions_date" : "日期",
  "My_transactions_details" : "細節",
  "My_transactions_points" : "點",
  "My_transactions_status" : "狀態",
  "My_transactions_keyword" : "關鍵詞",
  "My_transactions_search" : "搜索",
  "My_transactions_to" : "至",
  "My_transactions_selectoption" : "選擇選項",
  "My_transactions_status_earned" : "掙",
  "My_transactions_status_redeemed" : "贖回",
  "My_transactions_status_pending" : "有待",
  "My_transactions_status_reverted" : "回复",
  "My_transactions_status_declined" : "下降",
  "My_transactions_norecord" : "沒有找到記錄",
  "My_transactions_off_msg1" : "后",
  "My_transactions_off_msg2" : ", 您的所有交易详细信息将发布在“积分簿”选项卡上",

  "Passbook_heading" : "積分書",
  "Passbook_show" : "節目",
  "Passbook_entries" : "項",
  "Passbook_search" : "搜索",
  "Passbook_type" : "類型",
  "Passbook_surveyid" : "調查",
  "Passbook_date" : "日期",
  "Passbook_selectoption" : "選擇選項",
  "Passbook_credit" : "信用",
  "Passbook_debit" : "借方",
  "Passbook_survey_id" : "調查身",
  "Passbook_survey_pts" : "调查点",
  "Passbook_earned_pts" : "点",
  "Passbook_pending_pts" : "待定点",
  "Passbook_promo_code" : "促销代码",
  "Passbook_transaction_type" : "交易类型",
  "Passbook_balance" : "平衡",
  "Passbook_status" : "状态",
  "Passbook_type_soi" : "注册",
  "Passbook_type_doi" : "电子邮件验证",
  "Passbook_type_profile" : "配置文件完成",
  "Passbook_type_survey" : "调查",
  "Passbook_type_doir" : "电子邮件验证人",
  "Passbook_type_doirt" : "通过引荐进行电子邮件验证",
  "Passbook_type_surveyr" : "调查尝试",
  "Passbook_type_surveyrt" : "推荐调查",
  "Passbook_type_reward" : "奖励",
  "Passbook_type_pending" : "调整",
  "Passbook_type_reverted" : "点数还原",
  "Passbook_type_declined" : "请求被拒绝",
  "Passbook_type_redeem" : "赎回",
  "Passbook_type_balance" : "带着向前",
  "Passbook_balance_vary" : "实际余额可能会有所不同",
  "Passbook_survey_debit_reason" : "我们重视您的努力。但是由于某些无法控制的连接丢失而数据传输时您的响应无法注册。我们必须扭转您之前收到的此次调查的积分。对由此带来的不便表示歉意。",

  "Redeem_redeemed" : "贖回",
  "Redeem_redeemhistory" : "兌換歷史",
  "Redeem_history_norecord" : "沒有找到記錄",
  "Redeem_date" : "日期",
  "Redeem_details" : "細節",
  "Redeem_points" : "點",
  "Redeem_status" : "狀態",
  "Redeem_lookslike" : "您似乎錯過了驗證電子郵件帳戶的情況。",
  "Redeem_hurry" : "匆忙！驗證後，您將得到1000分。",
  "Redeem_pleasecheck" : "請檢查您的收件箱，然後點擊發送的確認電子郵件中的鏈接以激活您的帳戶。",
  "Redeem_kindlycheck" : "請檢查您的Spam / Junk文件夾以及確認電子郵件。",
  "Redeem_getconfirmationemail" : "還沒有收到確認電子郵件？",
  "Redeem_resendconfirmationemail" : "重發確認郵件",
  "Redeem_rewardredemptionrule" : "獎勵兌換規則",
  "Redeem_completesurvey" : "完成調查",
  "Redeem_earnpoints" : "賺取積分",
  "Redeem_redeemrewards" : "兌換獎勵",
  "Redeem_note" : "注意：",
  "Redeem_yourearnedpoints" : "在獲得調查資格後，您獲得的積分將可兌換。要兌換獎勵，您帳戶中的最低可兌換積分必須為",
  "Redeem__points" : "點。",
  "Redeem_availablebalance :" : "可用餘額 ：",
  "Redeem_historydetails" : "點擊此處查看您的兌換歷史詳細信息。",
  "Redeem_reqsucc" : "恭喜！您的積分已被兌換。請查看您的註冊郵箱了解更多詳情。", 
  "Redeem_wisecheck_youhave" : "恭喜！您只需点击一下即可获得奖励。",
  "Redeem_wisecheck_gcagainst" : "禮品卡",
  "Redeem_wisecheck_points" : "點.",
  "Redeem_wisecheck_issue" : "系統已在您的帳戶中捕獲了一些無法識別的活動，因此您無法發送兌換請求。請聯繫",
  "Redeem_emailverify_issue" : "要兌換積分，我們會要求您驗證已註冊的電子郵件帳戶。",

  "Redeem_unsubscribe_issue" : "您無法發送兌換申請，因為您已取消訂閱WisePerks。請聯繫support@wiseperks.com再次訂閱。",
  "Redeem_wisecheck_message1" : "由於某些安全原因，您無法兌換。請聯繫支持",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "確認",
  "Redeem_Cancel_button" : "取消",
  "Redeem_pending_hello" : "你好",
  "Redeem_pending" : "调整",
  "Redeem_pending_msg1" : "您的帐户显示调整",
  "Redeem_pending_msg2" : "要点。请确认启动交易。",

  "Dashboard_wisecheck_issue" : "系统已在您的帐户中捕获了一些无法识别的活动，因此您无法查看调查。请联系support@wiseperks.com进一步说明。",
  "Dashboard_unsubscribe_issue" : "您无法查看任何调查，因为您已取消订阅WisePerks。请联系support@wiseperks.com再次接收调查。",


  "WC_DashboardMsg_1" : "哎呀！截至目前，没有与您的个人资料相匹配的调查",
  "WC_DashboardSubMsg_1" : "别担心，我们正在洗牌，很快就会按照您的方式发送新的调查。",

  "WC_DashboardMsg_2" : "我们正在刷新您的仪表板。请在一段时间后检查新的调查。",
  "WC_DashboardSubMsg_2" : "", 

  "Survey_success_msg1" : "感谢您完成调查。你刚获得了response_points积分。登录您的信息中心，查看累计积分。",
  "Survey_success_msg2" : "感谢您完成调查。你刚获得了response_points积分。",

  "Survey_success_but_nomore_survey" : "恭喜！您刚刚获得了完成调查的response_points积分。请在一段时间后检查即将进行的调查。",
  
  "Survey_terminate_pnt_msg1" : "看起来您的某些信息与要求略有不同。我们重视您的时间和精力，将为您的帐户添加response_points积分。我们专注于为您带来更有利可图和直观的调查。",
  
  "Survey_terminate_nopnt_msg1" : "看起来您的某些回复与要求略有不同。我们将以您的方式发送更多调查。感谢您的时间和精力。",

  "TermsConditions_t&c" : "条款和条件",
  "TermsConditions_WhenRegister" : "当你注册",
  "TermsConditions_WhenYouCreate" : "当您创建个人帐户时，我们会向您提供一些个人信息，如下所述：",
  "TermsConditions_YouCanUpdate" : "您可以随时更新或更改您的基本个人资料信息。",
  "TermsConditions_YouCanOptOut" : "您可以随时选择退出或关闭您的帐户。",
  "TermsConditions_WeRespectYour" : "我们尊重您的隐私并充分利用技术来保护您的信息免受未经授权的物理和电子访问。",
  "TermsConditions_WeShareYour" : "我们仅与运营WiseWorks所需的组织共享您的信息。这些组织需要照顾我们对您的隐私和安全的承诺。",
  "TermsConditions_WhatInformation" : "我们从您那里收集了哪些信息",
  "TermsConditions_DirectlyCollected" : "直接收集",
  "TermsConditions_EmailAddressAndPassword" : "我们的帐户的电子邮件地址和密码。",
  "TermsConditions_YourResponse" : "您对分析问题的回答。",
  "TermsConditions_AnyCustomer" : "任何客户服务信件。",
  "TermsConditions_AnyPersonalDetails" : "您分享的任何个人详细信息以进行兑换",
  "TermsConditions_AnyOtherInformation" : "您直接提供的任何其他信息。",
  "TermsConditions_UsageData" : "使用数据",
  "TermsConditions_YourIPAddress" : "您的IP地址，地理位置和Cookie",
  "TermsConditions_InformationAboutTheDevice" : "有关您使用的设备的信息",
  "TermsConditions_YourSurveyActivity" : "您的调查活动，帐户中的积分，兑换后收到的付款。",
  "TermsConditions_HowDoWeUse" : "我们如何使用您的数据",
  "TermsConditions_SendingYouInvitation" : "发送邀请电子邮件以接收您符合条件的调查。",
  "TermsConditions_DetectAndPrevent" : "检测并防止欺诈活动。",
  "TermsConditions_ImproveYourExperience" : "改善您在WiseWorks平台上的体验",
  "TermsConditions_YourResponseAreTransferred" : "您的回复将以汇总和去识别的格式转移给我们的附属第三方和合作伙伴。",
  "TermsConditions_OurPartnersCanAsk" : "我们的合作伙伴可以要求您在调查期间提供您的姓名，电子邮件地址和其他联系信息。",
  "TermsConditions_YouAreFreeToDecline" : "如果您同意，您可以自由拒绝向我们的合作伙伴提供您的详细信息，而不是直接向他们提供此信息，而不是向WiseWorks提供。",
  "TermsConditions_YourInformationWillBe" : "您的信息将根据相关的业务隐私政策处理，而不是WiseWorks的隐私政策。",
  "TermsConditions_OurPartnersAreCommited" : "我们的合作伙伴致力于尊重和保护您的隐私。",
  "TermsConditions_WhatWeDont" : "我们不做什么",
  "TermsConditions_WeDontSellOnThat" : "我们不会将此信息出售给任何人，我们也不会将其用于向您出售任何东西。在分享您的数据之前，我们将始终要求许可。",
  "TermsConditions_YourRightToControl" : "您有权控制您的数据",
  "TermsConditions_YouHaveFullControl" : "您可以完全控制您的数据。",
  "TermsConditions_YouCanAccess" : "您可以随时访问，更新或更改基本配置文件信息。",
  "TermsConditions_YouCanUnsubscribe" : "您可以取消订阅并停用您的帐户。",
  "TermsConditions_YouAreFreeToGetYour" : "您可以通过support@wiseperks.com向我们发送邮件来免费删除您的个人资料。我们会尝试解决您的问题，如果您仍希望删除帐户，则应在72小时内完成。",
  "TermsConditions_DataSecurity" : "数据安全",
  "TermsConditions_OurTechTeamWorks" : "我们的技术团队非常努力地保护您的所有数据安全。",
  "TermsConditions_WeMakeUseOfLatest" : "我们利用最新的技术和措施，以避免任何滥用或未经授权的数据访问。但是，由于在线环境的性质，我们无法100％保证数据安全。",
  "TermsConditions_WeTryOurBestToRule" : "我们尽力排除风险，但不能保证无风险的环境。接受这些条款和条件，即表示您承认并接受这些风险。",
  "TermsConditions_AnyQuestions" : "如有任何问题，请随时与我们联系",
  "TermsConditions_IfYouHaveAnyQueries" : "如果您对我们的隐私政策和程序有任何疑问或投诉。请发送电子邮件至support@wiseperks.com，我们会在48小时内回复您。",

  "Validation_PleaseEnterProperDate" : "请输入正确的出生日期",
  "Validation_PleaseSelectOne" : "请选择一个选项！",
  "Validation_PleaseSelectValidOptions" : "请选择有效选项！",
  "Validation_PleaseSelectAtLeaseOneOption" : "请至少选择一个选项",
  "Validation_PleaseInsertTheZip" : "请插入邮编/邮政编码。",

  "Releases_releases" : "发布",
  "Releases_theUseOfThis" : "使用本网站须遵守以下使用条款。",
  "Releases_featuresOfWP" : "WP发布的特点",
  "Releases_updatedOn" : "更新",
  "Releases_WisePerksNow" : "WisePerks现在支持多种语言",
  "Releases_youCanWalkThourgh" : "由于WisePerks以您的优先语言提供，因此您可以更轻松地走遍网站。",
  "Releases_languagesAvailable" : "可用语言：",
  "Releases_EarnMore" : "使用我们的新点系统赚取更多",
  "Releases_theNewWisePerksAccumulates" : "新的WisePerks会将积分累积到您的帐户中。这些点具有更高的价值，易于赚取和兑换。有多种兑换选项可供选择，我们相信您将享受到调查体验。",
  "Releases_accumulatePoints" : "积累积分免费兑换",
  "Releases_youGetTheBenefit" : "你可以获得同样的收益。积分，不论您想要兑换的货币。",
  "Releases_surveyDisqualification" : "调查取消资格并不是一个坏消息",
  "Releases_youEarnPoints" : "即使您没有资格参加调查，您也可以获得积分，您开始回答。",
  "Releases_newAndImproved" : "新的和改进的布局",
  "Releases_theFreshLookOf" : "WisePerks的清新外观将让您爱上它。它现在更容易使用，对您来说将是一次很棒的体验。",
  "Releases_whatAvatarAreYouToday" : "你今天的阿凡达是什么",
  "Releases_youCanNowCreate" : "您现在可以在仪表板上创建自己的头像。",
  "Releases_freshAndHotSurveys" : "仪表板上的新鲜和热门调查",
  "Releases_youDontHaveTo" : "您无需担心刷新仪表板以查看新调查，我们会自动为您的仪表板填充最新调查。",
  "Releases_promoCode" : "促销代码功能",
  "Releases_promoCodeInfo" : "根据大众的需求，WisePerks每周为其小组成员带来促销代码。获得更多奖励的快捷方式。",
  "Releases_referFriend" : "推荐给朋友",
  "Releases_referFriendInfo" : "让你的朋友与WisePerks联系并获得令人兴奋的奖励。留意您帐户中的推荐朋友选项。",

  "Dashboard_Promocode_Usepromo" : "使用促销代码",
  "Dashboard_Promocode_to" : "至",
  "Dashboard_Promocode_earnminimum_1" : "赚最低",
  "Dashboard_Promocode_earnminimum_2" : "加分",
  "Dashboard_Promocode_minimum" : "最低",
  "Dashboard_Promocode_minimumextrapoints" : "今天完成的每项调查的加分",
  "Dashboard_Promocode_apply" : "应用",
  "Dashboard_Promocode_message1" : "促销代码无效！",
  "Dashboard_Promocode_message2" : "恭喜你！您将获得最低",
  "Dashboard_Promocode_message3" : "今天完成的每个调查都需要额外支付！",

  "Dashboard_modal_Enjoythefestive" : "通过最低收入享受节日气氛",
  "Dashboard_modal_Extrapointson" : "您今天完成的每项调查都有额外的积分。",

  "refer_friend_title" : "推荐朋友并获得奖励",
  "refer_friend_step_first": "邀请朋友加入WisePerks",
  "refer_friend_step_second": "朋友成功注册了WisePerks",
  "refer_friend_step_third": "你得到each_referral点",
  "refer_friend_link_title": "现在邀请朋友加入！",
  "refer_friend_share_title": "分享链接",
  "refer_friend_guideline_title": '如何“引用朋友”将使您受益：',
  "refer_friend_guideline_one": "请与您的推荐人分享上面提供的链接。",
  "refer_friend_guideline_two": "您可以根据需要推荐多个人，一旦您的5个推荐人验证了他们的帐户并开始调查活动，您获得的推荐积分就会被添加到您的帐户中。",
  "refer_friend_guideline_three": "您可以获得每个成功点（使用经过验证的电子邮件帐户和调查活动）推荐的每个点数。",
  "refer_friend_guideline_four": "积分将通过两个阶段获得，电子邮件验证的partial_referral和推荐的调查活动的partial_second_referral。",
  "refer_friend_guideline_five": "在成功完成5次（使用经过验证的电子邮件帐户和调查活动）推介后，推介链接将被禁用。",
	
  }
  
  

  