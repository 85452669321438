// Turkish
export const TRANSLATIONS_TR = {
  "home_header_selectlang": "Dil Seçin",
  "home_header_loginbutton": "Oturum aç",
  "home_header_signupbutton": "Kaydol",
  "home_header_homebutton": "Ev",
  
  "home_register_bannertxt1": "Işte burada",
  "home_register_bannertxt2": "düşünceler",
  "home_register_bannertxt3": "seni alabilir",
  "home_register_bannertxt4": "ödül$",
  "home_register_bannertxt5": "Bonus al",
  "home_register_bannertxt6": "Kaydolma",
  "home_register_fbsignup": "FACEBOOK İLE KAYIT OL",
  "home_register_fblogin": "FACEBOOK İLE GİRİŞ",
  "home_register_or": "VEYA",
  "home_register_emailsignup": "Email ile kayıt olun",
  "home_register_emaillogin": "Email ile giriş yap",
  "home_register_fpass": "Parolanızı mı unuttunuz?",
  "home_register_fname": "İsim",
  "home_register_lname": "Soyadı",
  "home_register_email": "E",
  "home_register_pass": "Parola",
  "home_register_cpass": "Şifreyi Onayla",
  "home_register_tcpp": "Katılarak,",
  "home_register_tcpptandcond": "şartlar ve koşullar",
  "home_register_tcppand": "ve",
  "home_register_tcppprivacy": "Gizlilik Politikası",
  "home_register_join": "Bize katıl. Bedava",
  "home_register_login": "oturum aç",
  "home_register_name_required" : "İsim gerekli.",
  "home_register_email_required" : "Email gereklidir.",
  "home_register_invalid_email" : "Geçersiz e-posta formatı",
  "home_register_password_required" : "Şifre gereklidir.",
  "home_register_password_lower_digit" : "Şifre 6 haneli olmalı",
  "home_register_password_higher_digit" : "Şifre kullanılmalıdır 15 haneli gereklidir",
  "home_register_cpassword_required" : "Şifreyi Onaylayın",
  "home_register_cpassword_lower_digit" : "Şifre Doğrulama 6 haneli olmalıdır",
  "home_register_cpassword_higher_digit" : "Şifre Doğrulama 15 haneli kullanılmalıdır",
  "home_register_cpassword_not_match" : "Şifrenin eşleşmediğini doğrulayın",

  "home_register_fp_not_worry" : "Lütfen kayıtlı emailıd 'nizi girin.", 
  "home_register_fp_resetpass_msg" : "Parolanızı sıfırlamak Için lütfen kayıtlı e-posta kimliğinizi girin.", 
  "home_register_fp_enter_email_msg" : "Buraya e-posta girin", 
  "home_register_fp_submit_button" : "Gönder", 
  "home_register_fp_temporary_sent_pass_msg" : "Kayıtlı e-posta kimliğinize parola sıfırlama bağlantısı gönderildi. Lütfen en erken parolanızı güncelleyin.",  
  
  "home_works_howitworks": "Nasıl çalışır?",
  "home_works_howitworkst1": "Kendini tanıt",
  "home_works_howitworksd1": "Üye ol Üye Ol",
  "home_works_howitworkst2": "Anketler alın",
  "home_works_howitworksd2": "Sezgisel ve basit anketler almaya başlayın",
  "home_works_howitworkst3": "Puan kazanın",
  "home_works_howitworksd3": "En iyi cevaplarınızı verin ve puan toplayın",
  "home_works_howitworkst4": "kurtarmak",
  "home_works_howitworksd4": "Çok heyecanlı ödüller için puanlarınızı kullanın",
  
  "home_aboutus_whoweare": "Biz Kimiz?",
  "home_aboutus_whowearep1": "WisePerks'te, çevrimiçi harcadığınız zamanın ödüllendirici bir deneyim olduğundan emin olmak için çok çalışıyoruz. Geri bildiriminizi almak isteyen, yüzlerce firma ile çalışıyoruz, ürünleriniz veya hizmetleriniz hakkında neler hissettiğinizi biliyoruz. WisePerks'ten farklı olarak, çeşitli hizmetlerle ilgili görüşlerinizi paylaşıyorsunuz, ancak bu hizmetlerin hiçbiri aslında zamanınız için sizi ödüllendirmiyor. Bu, katıldığınız her anketin size büyük para ödülleri kazandırıyor.",
  "home_aboutus_whowearep2": "Geri bildiriminize değer veriyoruz ve zamanınız için ödüllendirilmenizi istiyoruz. İlgi alanlarınıza uyacak ve yanıtlamalarını kolaylaştıracak çeşitli konularda anketlerimiz bulunmaktadır. Her ay çeşitli anketler yaparak 50-100 dolar kazanabilirsiniz. Bu yüzden, gerçekleşmesi için birlikte çalışalım.",
  
  "home_earn_more_surabt": "Ne anketler sormak?",
  "home_earn_more_bp": "İş uzmanları",
  "home_earn_more_consumers": "Tüketici",
  "home_earn_more_hc": "Sağlık & Fitness",
  "home_earn_more_entertainment": "Eğlenceli ve eğlence",
  "home_earn_more_gaming": "Oyun Freaks",
  "home_earn_more_tech": "Tech savvy",
  "home_earn_more_bonus_survey": "Bonus Anketi",
  
  "home_reward_whatuearn": "Ne kazanıyorsun?",
  "home_reward_whatuearnp1": "İşleri basit tutmayı severiz. Tüm başarıyla tamamlanmış anketler için puan kazanırsınız. Tamamladığınız daha fazla anket, daha fazla puan kazanırsınız. Belirli bir eşiği geçtikten sonra puanlar seçtiğiniz Hediye Kartları için kullanılabilir.",
  "home_reward_whatuearnp2": "Kullanmaya hazır olduğunuzda, istediğiniz seçeneği seçin - Amazon, Starbucks, iTunes ve diğerleri. Aynı zamanda Bonuslar veriyoruz ve Tanrı'ya şükürler olsun, o zamanlar Cuma teklifleri.",
  
  "home_counter_earnedover": "Üyeler kazandı",
  
  "home_contact_getintouch": "Temasta olmak",
  "home_contact_emailus": "Herhangi bir sorunuz için lütfen bize e-posta gönderin",
  
  "home_footer_faq": "FAQs",
  "home_footer_pp": "Gizlilik Politikası",
  "home_footer_tc": "T Cı",
  "home_footer_uf": "Yaklaşan Özellikler", 
  "home_footer_copyright": "Telif Hakkı © 2018 WisePerks. Her hakkı saklıdır.",

  "home_header_home": "Ev", 
  "home_footer_toknowmore": "Daha fazla bilmek",
  "home_footer_createacc": "Ücretsiz bir hesap oluşturun ve binlerce mutlu üyeye katılın.",
  "home_footer_registernow": "Şimdi üye Ol",

  "home_reset_password" : "Parolayı Sıfırla", 
  "home_reset_pass_content" : "Şifrenizi sıfırlamak istediniz", 

  "dashboard_sidebar_selectavatar" : "Avatarınızı seçin", 
  "dashboard_sidebar_uploadavatar" : "Görüntüyü yüklemek için buraya tıklayın", 
  "dashboard_sidebar_pbank" : "Puan Bankası",
  "dashboard_sidebar_accumulated" : "Kazan", 
  "dashboard_sidebar_realized" : "Fark", 
  "dashboard_sidebar_pending" : "Düzeltmeler",
  "dashboard_sidebar_earned_tooltip" : "Şimdiye kadar topladığınız puanlar.",
  "dashboard_sidebar_pending_tooltip" : "Hesabınızdan geri alınması gereken puanlar.",
  "dashboard_sidebar_main" : "Ana",
  "dashboard_sidebar_dashboard" : "gösterge paneli",
  "dashboard_sidebar_profile" : "Profil",
  "dashboard_sidebar_updateprofile" : "Profili Güncelle",
  "dashboard_sidebar_shistory" : "Anket Tarihi",
  "dashboard_sidebar_mtrans" : "İşlemim",
  "dashboard_sidebar_passbook" : "Puan Defteri",
  "dashboard_sidebar_redeem" : "kurtarmak",
  "dashboard_sidebar_refer_friend" : "Arkadaşa Öner",
  "dashboard_sidebar_logout" : "Çıkış Yap",

  "dashboard_sidebar_tutorial1" : "Tüm mevcut anketler ve onlardan ne kazanabilirsiniz gösterir.",
  "dashboard_sidebar_tutorial2" : "Bize daha iyi bildirin ve ilgi anketleri göndererek bize yardımcı olmak için burada birkaç soru cevap. Bazı soruların yanıtlarınızı göndermeden sonra güncellemeniz mümkün olmazdı.",
  "dashboard_sidebar_tutorial2update" : "Yanıtlarınızı burada güncelleyebilirsiniz, ancak birkaç soru updation için kullanılabilir olmayacaktır.",
  "dashboard_sidebar_tutorial3" : "Burada çalıştığınız tüm anketler bulun.",
  "dashboard_sidebar_tutorial4" : "Wiseperks tüm işlemleri takip yardımcı olur.",
  "dashboard_sidebar_tutorial5" : "Gerçekleşen puanlarınızı ödüllere dönüştürmek için birkaç hızlı adım.",
  "dashboard_sidebar_tutorial6" : "Hesabınıza borçlu / alacaklıların işlem geçmişinin tamamını görün.",
  "dashboard_sidebar_gotit" : "Anladım",

  "dashboard_sidebar_note" : "lütfen aklınızda bulundurun",
  "dashboard_sidebar_note1" : "Tüm cevaplar dürüstçe cevaplanmalıdır.",
  "dashboard_sidebar_note2" : "Açık uçlu cevaplarla lütfen kesin ve kesin olun.",
  "dashboard_sidebar_note3" : "Hız yapma! Minimum anket tamamlanma süreleri belirlendi.",
  "dashboard_sidebar_note4" : "Aynı sorular sorulduğunda lütfen tutarlı yanıtlar verin.",
  "dashboard_sidebar_note5" : "Yeni anketler eklenecektir ve kapalı olanlar otomatik olarak panonuzda kaldırılacaktır.",
  "dashboard_sidebar_note6" : "Sadece yönlendirmeler yaparak bazı hızlı puanlar kazan.",

  "dashboard_setting_hi" : "Merhaba",
  "dashboard_setting_minfo" : "Üyelik Bilgisi",
  "dashboard_setting_macc" : "Hesabı yönet",
  "dashboard_setting_upass" : "Şifreyi güncelle",
  "dashboard_setting_yourname" : "Senin adın",
  "dashboard_setting_fname" : "İsim",
  "dashboard_setting_lname" : "Soyadı",
  "dashboard_setting_save" : "Değişiklikleri Kaydet",
  "dashboard_setting_bornon" : "Sen doğdun",
  "dashboard_setting_youare" : "Sen bir",
  "dashboard_setting_postal" : "Posta kodun",
  "dashboard_setting_emailus" : "Herhangi bir yardım veya sorgu Için, lütfen bir e-posta gönderin",
  "dashboard_setting_membersince" : "Sen beri üye oldun",
  "dashboard_setting_taken" : "Sen aldın",
  "dashboard_setting_completed" : "anketler ve tamamlandı",
  "dashboard_setting_ofthem" : "onların",
  "dashboard_setting_earned" : "kazandın",
  "dashboard_setting_pts" : "makas",
  "dashboard_setting_started" : "başladığından beri.",
  "dashboard_register_email" : "Bize kayıtlı olduğunuz",
  "dashboard_setting_preferences" : "Tercihlerinizi yönetin",
  "dashboard_setting_emailoptout" : "E-posta Etkinleştir",
  "dashboard_setting_futureemails" : "WisePerks'ten gelecek e-postaları almayı devre dışı bırak.",
  "dashboard_setting_futureemailsflash" : "E-postalarımız sizin için mevcut tüm kazançlı anketler size yayınlanan tutmak. Onları almak istemediğine emin misin?",
  "dashboard_setting_nrecemail" : "E-postalarımız sizin için mevcut tüm kazançlı anketler size yayınlanan tutmak. Onları durdurmak istemediğine emin misin?",
  "dashboard_setting_emailout_modalmsg" : "Eğer WisePerks gelen gelecekteki e-postalar alma dışında bırakmak istiyorum emin misiniz?",
  "dashboard_setting_emailin_modalmsg" : "Wiseperks gelen gelecekteki e-postalar almaya başlamak için emin misiniz?",
  "dashboard_setting_emailout_succmsg" : "Sizin başarılı wiseperks gelen e-postalar alma durur",
  "dashboard_setting_emailin_succmsg" : "Sizin başarılı wiseperks gelen e-postalar almaya başlayabilirsiniz",
  "dashboard_setting_deactivateacc" : "Aktif edilmemiş hesap",
  "dashboard_setting_disableprofile" : "Hesabınızın devre dışı bırakılması, profilinizi WisePerks'ten devre dışı bırakır.",
  "dashboard_setting_deactivateacc_msg" : "WisePerks hesabınızı devre dışı bırakmak istediğinizden emin misiniz?",
  "dashboard_setting_deactivateacc_flash" : "Herhangi bir şikayet veya geribildirim varsa, o zaman bizimle paylaşın lütfen. Öncelikli olarak bunu ele aldık. Bize bir şans ver. Hesabınızı bizimle silmek istediğinizden emin misiniz?",
  "dashboard_setting_changelang" : "Dili değiştir",
  "dashboard_setting_preferredlang" : "Tercih ettiğiniz dili seçin",
  "dashboard_setting_updatepass" : "Şifreyi güncelle",
  "dashboard_setting_plsupdate" : "Lütfen aşağıdaki parolanızı güncelleyin.",
  "dashboard_setting_oldpass" : "Eski şifre",
  "dashboard_setting_enteroldpass" : "Eski Şifreyi Girin",
  "dashboard_setting_newpass" : "Yeni Şifre",
  "dashboard_setting_enternewpass" : "Yeni Şifre Girin",
  "dashboard_setting_confpass" : "Şifreyi Onayla",
  "dashboard_setting_enternewpassagain" : "Tekrar Yeni Şifre Girin",
  "dashboard_setting_update" : "Güncelleştirme",

  "dashboard_doi_welcome" : "Hoş geldi -niz",
  "dashboard_doi_welbck" : "Tekrar hoşgeldiniz",
  "dashboard_doi_accntverified" : "E-posta hesabınızı doğrulama cevapsız gibi görünüyor.",
  "dashboard_doi_getaccess" : "Acele! alacaksın",
  "dashboard_doi_getaccess2" : "puan doğruladıktan sonra.",
  "dashboard_doi_activateacc" : "Lütfen gelen kutunuzu kontrol edin ve kayıtlı e-posta hesabınıza gönderilen hesap doğrulama e-postasında verilen bağlantıya tıklayın.",
  "dashboard_doi_chckspam" : "Eğer doğrulama e-posta bulmak mümkün değildir lütfen, spam/önemsiz klasörü kontrol edin.",
  "dashboard_doi_didntgetconf" : "Henüz doğrulama e-postası alamadım?",
  "dashboard_doi_resendconf" : "Doğrulama e-postasını yeniden gönder",
  "dashboard_doi_resendconfsucc" : "Doğrulama e-postası kayıtlı e-postanızı gönderdi.", 
  
  "dashboard_survey_availsurveys" : "Mevcut Anketler",
  "dashboard_survey_wisechecksurveys1" : "Bazı güvenlik nedenleri nedeniyle anketleri görmek mümkün değildir. Lütfen destek için başvurun",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Aboneliği iptal etme nedeni için mevcut anketler",
  "dashboard_featured" : "Seçme",
  "dashboard_profile_survey" : "Profil Anketi",
  "dashboard_survey_start" : "başla",
  "dashboard_survey_on" : "üzerinde",
  "dashboard_survey_off" : "kapalı",
  "dashboard_survey_point": "Nokta",
  "dashboard_survey_points" : "Puan",
  "dashboard_survey_minute" : "Dakika",
  "dashboard_survey_minutes" : "Dakika",

  "demography_hi" : "Merhaba",
  "demography_welcome" : "Wiseperks 'e hoş geldiniz. Sadece kendini kazandın.",
  "demography_points" : "bize kayıt yaparak puan.",
  "demography_share" : "İlgili anketleri size getirmemize yardımcı olmak için lütfen aşağıdaki formda birkaç ayrıntı paylaşın.",

  "demography_gender" : "Ben bir",
  "demography_male" : "Erkek",
  "demography_female" : "Kadın",
  "demography_birthday" : "Doğum günü",
  "demography_month" : "Ay", 
  "demography_day" : "Gün",
  "demography_year" : "Yıl",
  "demography_jan" : "Ocak",
  "demography_feb" : "Şubat",
  "demography_mar" : "Mart",
  "demography_apr" : "Nisan",
  "demography_may" : "Mayıs ayı",
  "demography_jun" : "Haziran",
  "demography_jul" : "Temmuz",
  "demography_aug" : "Ağustos",
  "demography_sep" : "Eylül",
  "demography_oct" : "Ekim",
  "demography_nov" : "Kasım",
  "demography_dec" : "Aralık",
  "demography_hearabout" : "WisePerks'i nereden duydun?",
  "demography_opt1" : "En yakın olanı seçin",
  "demography_opt2" : "E-posta bülteni",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum veya Blog",
  "demography_opt5" : "Arkadaş",
  "demography_opt6" : "Linkedın",
  "demography_opt7" : "Arama motoru",
  "demography_opt8" : "TV ticari",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Web sitesi",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Diğer",
  "demography_zipcode": "Posta kodu",
  "demography_submit" : "Gönder",

  "privacypolicy_privacypolicy" : "Gizlilik Politikası",
  "privacypolicy_privacyisimp" : "Gizliliğiniz bizim için önemlidir.",
  "privacypolicy_lastupdated" : "Son güncelleme tarihinde",

  "privacypolicy_para1" : 'WiseWorks (iştirakleri, "Şirket" ile birlikte) Wiseample.com, Flapbucks.com, WisePerks.com ve diğerleri (topluca, "dahil olmak üzere, bir dizi farklı web sitesine, mobil uygulamaya ve etkileşimli hizmete sahiptir. WiseWorks Siteleri ").Bu gizlilik politikası ("Gizlilik Politikası"), WiseWorks Siteleri ve bir bilgisayar, mobil aygıt veya başka bir yoldan erişilebilen tüm özellikler, mobil uygulamalar, e-postalar, çevrimiçi hizmetler ve diğer işlevler için geçerlidir. WiseWorks Siteleri ve Özellikleri ("Hizmetler").Bu Gizlilik Politikası sadece Şirket tarafından WiseWorks Siteleri ve Özellikleri ve Hizmetlerimiz aracılığıyla toplanan bilgileri kapsamakta olup, Şirket tarafından çevrimdışı olarak alınan veya toplanan bilgileri (özellikle belirtilmedikçe) kapsamamakta veya ilgili olmayan web siteleri veya şirketler tarafından toplanabilecektir. bağlantılar sağlamak.',

  "privacypolicy_para2" : "Panelist / yanıt veren kişinin profilini dürüstçe ve doğru bir şekilde doldurması ve yanlış veya yanlış bilgi vermesi ve daha fazla ankete hak kazanması beklenmektedir.Ayrıca, panelist / yanıt verenlerin, anketleri doldurmak için cevaplarını manipüle etmemeleri veya diğer haksız yollardan (değişen IP, çoklu hesaplar vb.) Teşvikleri teşvik etmeleri beklenmemektedir.WisePerks, bu tür anormallikleri tespit etmek için yeterli güvenceye sahiptir ve bu tür bir davranış fark edilirse, panelist WisePerks tarafından yasaklanacak / engellenecek ve itiraz taleplerinin tamamı iptal edilecektir.",

  "privacypolicy_para3" : "WiseWorks, gizliliğinizi bizimle korumayı taahhüt eder.Aşağıdaki gizlilik politikası, web sitemizi ve hizmetlerimizi incelerken kişisel bilgilerinizin nasıl tutulduğunu ve nasıl ele alındığını açıklar.",

  "privacypolicy_topic1" : "WisePerks.com, kişisel olarak tanımlanabilir bilgiler benden ne toplar?",
  "privacypolicy_topic1ans" : "WisePerks.com, web sitemizin ve ağımızın farklı bölümlerinden çeşitli şekillerde bilgi toplar.Kayıt olduğunuzda bazı bilgiler toplanır.Üye kaydı sırasında WisePerks.com adınızı, e-posta adresinizi, posta kodunuzu ve ev bilginizi ister.Doğru bir şekilde sağlamak için gönüllü olduğunuz daha fazla bilgi, size daha iyi hizmetler ve ödüller sunabiliyoruz.Ayrıca, WisePerks.com ile herhangi bir şekilde iletişim kurarsanız, bu yazışmanın kaydını tutabiliriz.WisePerks.com bu tür bir bilgi topladığında, bu Gizlilik Politikasına bu sayfada bir bağlantı eklemeyi hedefliyoruz.",

  "privacypolicy_topic2" : "Çerez nedir ve WisePerks.com bunları nasıl kullanır?",
  "privacypolicy_topic2ans" : "Çerez, tarayıcınıza bir web sunucusundan gönderilen ve bilgisayarınızın sabit diskinde saklanan bir elektronik veri paketidir.Size özel anketler, bilgi ve kişiselleştirilmiş hizmetler sunmanın bir parçası olarak, WisePerks.com sizin hakkınızdaki bilgileri saklamak ve bazen izlemek için çerezleri kullanabilir.WisePerks.com sizin için belirli içerikleri hazırlamak için çerezleri kabul etmenizi gerektirebilir.Bu, giriş bilgilerinizi her seferinde girmek zorunda kalmanızı da engeller.",

  "privacypolicy_topic3" : "Kişisel hesap bilgilerimi güncellememe, düzenleme, değiştirme veya silme konusunda WisePerks.com politikası nedir??",
  "privacypolicy_topic3ans" : "WisePerks.com Hesap Bilgilerinizi WisePerks.com Oturum açma ve şifrenizi kullanarak istediğiniz zaman düzenleyebilirsiniz.WisePerks.com hesabınızı devre dışı bırakma hakkında daha fazla bilgi için lütfen",
  "privacypolicy_topic3ans2" : "ile iletişime geçin.",

  "privacypolicy_topic4" : "Kim bilgi topluyor ve ne amaçla?",
  "privacypolicy_topic4ansp1" : "WisePerks.com, araştırma müşterileri için yeni ürün ve hizmetler geliştirmelerine yardımcı olan anketler, kayıt ve diğer yollarla çeşitli bilgiler toplar.Katıldığınız herhangi bir ankette aksi belirtilmedikçe, bu bilgileri yalnızca WisePerks.com ile paylaşıyorsunuz.Veriler WiseWorks - veya WiseWorks siteleri dışındaki herhangi bir şirket tarafından toplanır ve / veya sürdürülürse, veri toplama veya aktarma zamanından önce size bildirilir.Verilerinizin paylaşılmasını istemiyorsanız, söz konusu anketi veya ürünü kullanmadan aktarıma izin vermemeyi tercih edebilirsiniz.",
  "privacypolicy_topic4ansp2" : "WisePerks.com'da sunduğumuz ödüller ve promosyonlar, diğer şirketler tarafından bağımsız olarak desteklenebilir veya WisePerks.com ve başka bir şirket tarafından desteklenebilir.Böyle bir promosyon sırasında toplanan bazı veriler sponsorla paylaşılabilir.Ancak, veri toplama veya aktarma zamanından önce size bildirilecektir.Verilerinizi paylaşmak istemiyorsanız söz konusu promosyona katılmamaya karar verebilirsiniz.",
  "privacypolicy_topic4ansp3" : "Herhangi bir Özel Hassas Bilgi (tıbbi veya sağlık koşullarını, ırksal veya etnik köken, siyasi görüş, dini veya manevi inanç, ticaret ve sendika üyeliği ya da bireyin cinsel yaşamını belirten bilgiler gibi bilgileri belirten bilgiler gibi) ek bir özenle tedavi edilecektir.WisePerks.com, bu bilgileri başka bir şirkete orijinal olarak toplanmaktan farklı bir amaç için paylaşmayı düşünürse, bu açıklamadan önce açıkça sizden izin istenir.",

  "privacypolicy_topic5" : "WisePerks.com kiminle bilgi paylaşıyor?",
  "privacypolicy_topic5ansp1" : "Gizliliği çok ciddiye alıyoruz.WisePerks.com, açık izniniz veya herhangi bir özel koşulunuz söz konusu olduğunda, yasanın gerektirdiği ya da aşağıda ayrıntılı olarak belirtilen koşullar altında kişisel kimlik bilgilerinizi ifşa etmeyecektir.Aşağıda bilgilerinizin açıklanabileceği bazı yollar açıklanmaktadır.Kişisel bilgilerinizin nasıl paylaşılabileceği hakkında daha ayrıntılı bilgi için hizmetlerimiz için Hizmet Şartlarına bakınız.",
  "privacypolicy_topic5ansp2" : "İş Ortakları, Müşteriler ve Sponsorlar:",
  "privacypolicy_topic5ansp3" : "WisePerks.com kişisel bilgilerinizi iş ortaklarımıza, müşterilerimize veya sponsorlarımıza açıklayabilir.Bununla birlikte, hakkınızdaki herhangi bir kişisel veri hiç paylaşılmamışsa, verileri toplayıp aktarmadan önce size söyleyeceğiz.Sunduğumuz birçok etkinlik sponsorlardan ek bilgi talep ediyor.Daha fazla bilgi talebinde bulunarak, WisePerks.com'a bilginizi sponsorla paylaşma izni verirsiniz, böylece isteğinizi yerine getirebilirler.Birçok durumda, sadece e-posta adresiniz paylaşılacaktır.Ortaklarımızla daha fazla bilgi paylaşılacaksa, önceden bilgilendirileceksiniz.Zaman zaman, test materyallerini veya diğer ürünlerimizi veya ortaklarımızdan gelen özel teklifleri alma fırsatı sunulabilir.Bu üçüncü taraflardan bilgi almayı tercih ederseniz, WisePerks.com (izninizle birlikte) adınızı ve e-posta adresinizi onlarla paylaşacaktır.",
  "privacypolicy_topic5ansp4" : "WisePerks.com ayrıca, bazı bilgileri, WisePerks.com'un politikalarını ve Hizmet Şartlarını ihlal eden veya herhangi bir yaralanmaya neden olabilecek kişilere karşı yasal işlem gerçekleştirme, iletişim kurma veya yasal işlem başlatma konusunda gerekli bilgileri açıklarken, bazı özel durumlarda da ifşa edebilir. WisePerks.com'un ürünleri, hizmetleri, hakları ya da mülkleri, diğer WisePerks.com üyeleri ve kullanıcıları ya da bu tür etkinliklerden etkilenebilecek herhangi bir kişi ile (ya kasıtlı ya da kasıtsız olarak) müdahale.WisePerks.com, kanunun gerektirdiğini ve ürünlerimizi ve hizmetlerimizi korumak, hizmet etmek ve geliştirmek için gerekli gördüğümüz idari ve diğer amaçlara iyi niyetle inandığımızda hesap bilgilerini ifşa edebilir veya bunlara erişebilir.",

  "privacypolicy_topic6" : "Bilgilerinizi işlemek için kullandığımız yasal dayanaklar nelerdir?",
  "privacypolicy_topic6ans" : "WiseWorks, Gizlilik ve Veri korumasına kendini adamıştır.Gizlilik politikamız, Avrupa Birliği tarafından yayınlanan Genel Verilerin Korunması Yönetmeliği'nin (“GSYİH”) prensiplerini, çalışanlarımızın ve tedarikçilerimizin bağlı olduğu bir kriter olarak benimsemektedir.",

  "privacypolicy_topic7" : "Bilgilerimin toplanması, kullanımı ve dağıtımı ile ilgili seçeneklerim nelerdir?",
  "privacypolicy_topic7ans" : "Sadece WisePerks.com (veya gizlilik sözleşmeleri kapsamında WisePerks.com ile çalışan ortaklar), bunlara itiraz etmediğinizi belirttiyseniz, size herhangi bir doğrudan posta gönderecektir.Ayrıca, çerezlerin nasıl çalıştığıyla ilgili seçimleriniz de var.İnternet tarayıcı ayarlarınızı değiştirerek, tüm çerezleri kabul etme, bir çerez ayarlandığında bildirilen veya tüm çerez isteklerini reddetme seçeneğiniz vardır.Tüm çerezleri reddetmeyi seçerseniz, katılmak için önceden kayıt olmak isteyen WisePerks.com hizmetlerini kullanamazsınız.WisePerks.com herhangi bir kullanıcı bilgisini kimseye satmaz veya kiralamaz.Verileriniz herhangi bir üçüncü taraf ile paylaşılacaksa ve her zaman paylaşılmasına izin vermeme seçeneğiniz varsa, veri toplama veya aktarma sırasında sizi her zaman bilgilendireceğiz.",

  "privacypolicy_topic8" : "WisePerks.com'da hesap bilgilerimin kaybolmasını, kötüye kullanılmasını veya değiştirilmesini önlemek için hangi güvenlik önlemleri vardır?",
  "privacypolicy_topic8ansp1" : "WisePerks.com Hesap Bilgileriniz şifre korumalıdır, böylece siz ve sadece bu bilgilere erişebilirsiniz.",
  "privacypolicy_topic8ansp2" : "Şifrenizi hiç kimseyle paylaşmamanızı şiddetle tavsiye ederiz.İstenmeyen bir telefon veya e-postayla şifrenizi asla istemeyiz.Lütfen aktivitenizi bitirdiğinizde WisePerks.com hesabınızdan çıkış yapın ve tarayıcı pencerenizi kapatın.Bu, herkese açık bir bilgisayar kullanıyorsanız veya bilgisayarınızı başka biriyle paylaşıyorsanız, başkalarının kişisel bilgilerinize ve hesabınıza erişememesini sağlar.WisePerks.com, kişisel bilgileri yukarıda açıklandığı gibi ele aldığında, bunun nerede olduğuna bakılmaksızın, WisePerks.com, bilgilerinizin güvenli bir şekilde ve ilgili Hizmet Şartları ve bu Gizlilik Politikası uyarınca ele alındığından emin olmak için gerekli adımları atmaktadır.Ne yazık ki, İnternet üzerinden hiçbir veri iletiminin% 100 güvenli olduğu garanti edilemez.Sonuç olarak, kişisel bilgilerinizi korumak için çabalarken, WisePerks.com bize ilettiğiniz veya çevrimiçi ürünlerimizden veya hizmetlerimizden aldığınız bilgilerin güvenliğini garanti edemez veya garanti edemez ve bunu kendi sorumluluğunuzda gerçekleştirirsiniz.Verilerinizi aldıktan sonra, sistemlerimizde güvenliğini sağlamak için elimizden geleni yapıyoruz.",

  "privacypolicy_topic9" : "Gizliliğimle ilgili başka ne bilmeliyim?",
  "privacypolicy_topic9ans" : "Kişisel bilgilerinizi çevrimiçi olarak (örneğin e-postalar, sosyal ağ siteleri, bloglar veya sohbet alanlarında) açıklamayı seçtiğinizde (ve nerede olursanız) bu bilgilerin başka herhangi bir kullanıcı tarafından toplanıp kullanılabileceğini lütfen unutmayın.Kişisel bilgileri çevrimiçi olarak herkese açık olarak yayınlarsanız, karşılığında diğer taraflardan istenmeyen iletiler alabilirsiniz.Son olarak, şifrelerinizin gizliliğini ve / veya herhangi bir hesap bilgisini korumaktan yalnızca siz sorumlusunuz.Çevrimiçi olduğunuzda lütfen dikkatli ve sorumlu olun.",

  "privacypolicy_topic10" : "WisePerks.com, çocuklarımın gizliliğini nasıl koruyor?",
  "privacypolicy_topic10ans" : "WisePerks.com, anketlerin çocukların katılmasını gerektirdiği ve ebeveyn denetimi için 6-15 yaş arası çocuklarla yapılan anketler için gerekli olan ebeveyn onayını alır.Sitemizdeki genç kullanıcıların güvenliğini ve gizliliğini korumak için özel özen gösteriyoruz ve çocuğunuzun çevrimiçi deneyimlerine katılmanızı öneririz.Herhangi bir pazarlama veya çocuk araştırmasına izin vermemekteyiz ve çocuklar arasında araştırmalarda bazı konular tamamen yasaklanmıştır: İsim / hesap adı / E-posta veya fiziki adres, Telefon numarası, Irk veya Etnik köken, Din veya Döküm, Hane geliri, Aile hastalıkları Ebeveynlerin veya diğer aile fertlerinin medeni durumu, İlişkiler önemlidir, Okul veya kolejde dereceler, Alkol, Cinsel Konular, Tütün ve Ateşli Silahlar.",

  "privacypolicy_topic11" : "Uluslararası Kullanıcılara Özel Not",
  "privacypolicy_topic11ansp1" : "Site Amerika Birleşik Devletleri'nde barındırılmaktadır.Siteye Avrupa Birliğinden, Asya'dan veya Birleşik Devletler yasalarından farklı olarak kişisel verilerin toplanması, kullanımı ve ifşasını düzenleyen yasa veya yönetmeliklere sahip herhangi bir bölgeye erişiyorsanız, lütfen kişisel verilerinizi AB ve diğer bölgelerle aynı veri koruma yasalarına sahip olmayan ve kişisel verilerinizi veren Amerika Birleşik Devletleri:",
  "privacypolicy_topic11ansp2" : "Kişisel verilerinizin, Gizlilik Politikası uyarınca yukarıda belirtilen kullanımlar için kullanılması; ve kişisel verilerinizin yukarıda belirtildiği gibi Amerika Birleşik Devletleri'ne aktarılması.AMERİKA BİRLEŞİK DEVLETLERİNİN YASALARININ DİĞER ÜLKELER VE HUKUK DÜZENLERİNİN YASALARINDAN GERÇEKLEŞTİRİLEN BİR TEDAVİDEN FARKLI BİR FARKLI OLABİLECEĞİNİ KABUL ETMİŞ VE KABUL ETMİŞ OLACAĞINI KABUL ETMİŞ OLURSUNUZ.AMERİKA BİRLEŞİK DEVLETLERİNE AKTARILAN BİLGİLERİNİZİ İSTEMİYORSANIZ, BİLGİLERİNİZLE ABD'NİZİ PAYLAŞMAYIN VEYA SİTEYİ KULLANMAYINIZ.ÜLKEDE BULUNDUĞUNUZ ÜLKEDEN YASA'NIN İZİN VERDİĞİ ÖLÇÜDE, HERHANGİ BİR AMACA VEYA BİRLEŞMİŞ ÜLKELERDEN DİĞER BİR ÜLKEDE VEYA HUKUK DÜZENLERİNE GÖRE KİMLİK BİLGİLERİNİZİ TANITMAK İÇİN GEREKENLERİNİZİ AÇIKLAYABİLİRSİNİZ.ANCAK BÜTÜNLEŞTİRİLMİŞ BÜTÜN, AVRUPA BİRLİĞİ'NİN ÜYE ÜLKELERİ OLARAK BAZI ÜLKELERDE HAREKET ETMEMEKTEDİR.YERİNE YERİNİZDE BULUNDUĞUNUZ ÜLKEDE BÜTÜN BAĞLAMIYOR, BU YUKARIDAKİ FERAGAT SİZİN İÇİN GEÇERLİ DEĞİLDİR.",

  "privacypolicy_topic12" : "Bu Gizlilik Politikasındaki Değişiklikler ve Güncellemeler",
  "privacypolicy_topic12ans" : "WiseWorks, kendi takdirimize bağlı olarak, herhangi bir sebepten ötürü herhangi bir zamanda bu Gizlilik Politikasının bölümlerini değiştirme, değiştirme, ekleme veya kaldırma hakkını saklı tutar.WiseWorks her zaman böyle bir hakkı uygularsa, güncellenmiş Gizlilik Politikasını olumlu bir şekilde kabul etmeniz gerekir ve bunu yapamazsanız, Site'ye artık erişemez veya Site'yi kullanamazsınız.",

  "privacypolicy_addinfo" : "Ek bilgi",
  "privacypolicy_sentto" : "Bununla ilgili başka herhangi bir soruya gönderilmelidir.",

  "faq_faq" : "sık sorulan Sorular",
  "faq_getans" : "Tüm sorularınızın cevaplarını alın.",

  "faq_ques1" : "WisePerks nedir?",
  "faq_ans1" : "Sorduğun için mutluyuz.WisePerks bir online anket sitesidir.Birçok marka ve şirket, görüşlerini bildirmek ve karşılığında ödül almak için katılımcıları arıyor.WisePerks, fikrinizi verebileceğiniz ve bunun için ödüller alabileceğiniz bir platformdur.",

  "faq_ques2" : "Katılmak serbest mi?",
  "faq_ans2" : "Evet, katılmak ücretsizdir.Aslında, sizden bir katılım ücreti talep ediyorsa, bunu bize bildirmenizi isteriz.",

  "faq_ques3" : "Arkadaşlarıma başvurabilir miyim?",
  "faq_ans3" : 'Evet, arkadaşlarınızı ve iş arkadaşlarınızı katılmaya ve ayrıca ödüllendirilmeye davet edebilirsiniz. Tüm detayları almak için lütfen gösterge tablonuzdaki "Arkadaşa Bak" bağlantısını tıklayın.',

  "faq_ques4" : "Ne kazanırım?",
  "faq_ans4" : "Şimdi yeni modelimizde, sadece anketler alarak dışında birçok yönden puan kazanabilirsiniz. Sizin tarafınızdan puan almak: bize kayıt, e-posta hesabınızı doğrulayarak, profilinizi tamamlamak için, elbette herhangi bir anket tamamlamak için ve sürpriz bir diskalifiye anket için bile. Yani seni başlamak için tüm sebeplerimiz var değil mi?",

  "faq_ques5" : "WisePerks.com üyesine nasıl üye olurum?",
  "faq_ans5" : "WisePerks.com'u tarayıcınızda açın.'Kayıt Ol' sekmesini tıklayarak üyelik girişi oluşturun.Sadece adımları izleyin ve sürecin geri kalanı boyunca size rehberlik edecektir.Sunulan e-postanıza gönderilen onay e-postasını tıklayarak katılma niyetinizi onaylamak zorundasınız.",

  "faq_ques6" : "Bahsettiğin onay e-postası nerede?",
  "faq_ans6p1" : "Kaydolurken sağladığınız e-posta adresine otomatik olarak bir onay e-postası gönderilir. Bu e-postayı gelen kutunuzda göremeyin, lütfen Spam veya önemsiz klasörünüzü kontrol edin. Bize gelen herhangi bir gelecekteki e-postaları önlemek için ve anket davet/spam klasörüne ulaşmasını mesajları, lütfen Ekle",
  "faq_ans6p2" : "onayladığınız gönderenlerin listenize veya posta kutunuzdaki bir kişi olarak. Bu onay e-postasını hala bulamıyor iseniz, lütfen Hesabıma giriş yapın, bizimle e-posta adresinizin doğru olduğunu onaylayın ve başka bir onay e-postası isteyin.",

  "faq_ques7" : "Ankete nasıl katılırım?",
  "faq_ans7" : "Kullanıcı adınızı ve parolanızı girdikten sonra oturum açtıktan sonra, panonuza ulaşır ve alabilirsiniz tüm anketleri görebilirsiniz. Ayrıca anket bağlantılarını kayıtlı e-posta kimlikleriniz için de göndeririz. Bağlantıyı doğrudan posta kutunuzdaki alabilirsiniz.",

  "faq_ques8" : "Yanıtlarım ne kadar gizli, verilerim?",
  "faq_ans8" : "WisePerks için, kullanıcı gizliliğini ve gizliliğini korumak en üst düzeyde önceliğe sahiptir.Müşterilerimiz sadece düşündüğünüz, bir tüketici olarak veya bir şey hakkında ne hissettiğinizle ilgilenir.WisePerks'te, herhangi bir kişisel bilgiyi müşterilerimize asla paylaşmayız / aktarmayız ve WisePerks anketlerinin hiçbiri üçüncü taraflara herhangi bir bilgiyi satmaz veya kiralamaz.",

  "faq_ques9" : "Bu anket ne kadar sürüyor?",
  "faq_ans9" : "Çoğu anket sadece 10 ila 15 dakika veya hatta daha az sürer.Bununla birlikte, zaman zaman, daha uzun anketlerimiz olabilir.Her bir ankette, ne kadar zaman alacağınızı önceden söyleriz, ancak daha uzun anketlerin de daha yüksek ödülleri olduğunu unutmayın.",

  "faq_ques10" : "WisePerks anketlerini kim alabilir?",
  "faq_ans10" : "WisePerks'e kayıt olmak için 13 yaşında olmanız gerekmektedir.Her ne kadar bazen ailenizin genç üyelerine katılmak için sizi anketlere davet edeceğiz.",

  "faq_ques11" : "Ankete katılacağımı nereden biliyorsunuz?",
  "faq_ans11" : "Algoritmamız, en uygun anketleri almanızı sağlamak için mevcut anketlerle profil bilgilerinizle eşleşir.Bir anket başladığında, genellikle ilk birkaç soru, müvekkilimizin söz konusu anketi aradığınızı belirler.Birçok farklı türde insanı (örneğin, düzenli olarak film izleyen, belirli bir sporu oynayan, çocukları olan çocuklar vb.) Arayan çok çeşitli anketlerimiz var ve her gün yeni anketler geliyor!",

  "faq_ques12" : "WisePerks'e karşı yükümlülüklerim nelerdir?",
  "faq_ans12" : "Sizden beklediğimiz tek şey, müşterilerinizin ürün ve hizmetlerini geliştirmek için dürüst ve doğru cevaplar aradıklarından, düşüncelerinizi ve düşüncelerinizi dürüst bir şekilde sunmaktır.Lütfen üye olarak kaydolurken doğru bilgileri sağlayın ve meydana gelen değişiklikleri güncelleyin.",

  "faq_ques13" : "Neden aldığım ev, teknoloji veya ürünler hakkında bilgi vermeliyim?",
  "faq_ans13" : "Her bir anket için, belirli bir katılımcı profilini arıyoruz.Profil bilgilerinizden bildiğiniz takdirde, bilgilerinizin müşteri gereksinimleriyle eşleştiğini, tombala!Anketi başarıyla tamamlayabileceğinizi ve bunun için ödül alabileceğinizi kesinlikle temin edebiliriz.Bu temel olarak bir anket için seçilme şansınızı artırır.",

  "faq_ques14" : "WisePerks anketini tamamladıktan sonra Hediye Kartımı ne zaman alacağım?",
  "faq_ans14" : "Bir anket başarıyla tamamladıktan sonra kazanmak puan kova birikmiş. Bu puanlar fark edildikten sonra kullanabilirsiniz.",

  "faq_ques15" : "Hesabım gerçekten nedir?",
  "faq_ans15" : "Kişisel bilgilerinizi görebilmeniz, gerektiğinde değişiklikler yapabilmeniz, anket etkinliğinizi görebilmeniz, kazandığınız ödülleri, her şeyi tek bir yerde görebilmeniz için kolay bir yoldur.",

  "faq_ques16" : "Hesabımı nasıl iptal edebilirim?",
  "faq_ans16" : "Çok yol geliyor ile, biz bunu yapmak istemediğinizden eminiz. Ancak, panonuzda--> ayarlar--> hesapları Yönet--> hesabı devre dışı bırak",

  "faq_ques17" : "Puan sistemi nasıl çalışır?",
  "faq_ans17p1" : "Her anket belirli puanlar içindir. Anketi başarıyla tamamladıktan sonra bu puanlar kazancınıza eklenir. Minimum eşiğe ulaştığınızda bunları kullanabilirsiniz.",
  "faq_ans17p2" : "Bazı durumlarda bağlantı veya arızalarla ilgili öngörülemeyen bazı zorluklar nedeniyle, cevabınız müşterimizin sonunda yakalanmayabilir. Daha sonra bu puanları hesabınızdan geri çevirmeliyiz. Bu puanları zaten kullandıysanız ve hesabınızın bakiyesi düşükse, kazancınız negatif olacaktır. Bu düzeltmeler bir sonraki kullanım çevriminizde yapılacaktır.",

  "faq_ques18" : "Ankete ait puanlar puan dengemize ne zaman eklenir?",
  "faq_ans18" : "Puanlarınız 48 saat içerisinde puan bakiyenize eklenecektir.",

  "faq_ques19" : "Kefaret için asgari eşik nedir?",
  "faq_ans19" : "Kefaret için asgari eşik 25000'dür.Ortalama olarak, geri ödeme için uygun olmak için 8-12 anketi doldurmanız gerekir.",

  "faq_ques20" : "Puanlar Hediye Kartı parasına nasıl dönüştürülür? Bir oran var mı?",
  "faq_ans20" : "Evet. Ülkenize bağlı olarak, puanlar Hediye Kartı parasına dönüştürülür.Dönüşüm oranları aşağıda gösterildiği gibidir",
  "faq_ponits" : "makas",
  "faq_point" : "Puan",
  "faq_cent" : "sent",

  "faq_ques21" : "Hala daha fazla sorunuz var mı?",
  "faq_ans21p1" : "Feel bize e-posta ücretsiz",
  "faq_ans21p2" : "ve 48 saat içinde size geri döneceğiz.",

  "Profiling_profiledashboard" : "Profil panosu", 
  "Profiling_completeprofile" : "Profilinizi tamamlayın ve kuyruklu alın",
  "Profiling_completepoints" : "Puan!",
  "Profiling_answercarefully" : "Aşağıdaki genel soru kümesi, size gelecekteki en iyi anket olanaklarını sağlamak için kişisel profilinizi oluşturmak amacıyla kullanılacaktır. Lütfen zaman ayırın, dikkatli ve dürüstçe cevap verin.",

  "Profiling_cat_general" : "Genel",
  "Profiling_cat_personal" : "Kişisel",
  "Profiling_cat_household" : "Ev",
  "Profiling_cat_profession" : "Mesleği",

  "Profiling_bar_completed" : "TaMamlanan%",
  
  "Profiling_update_ans_button" : "Güncelle ve Devam Et",
  "Profiling_save_ans_button" : "Kaydetmek",
  "Profiling_sc_ans_button" : "Kaydet ve devam et",

  "Category_thanku_msg1" : "Iyi gidiyor",
  "Category_thanku_msg2" : "birkaç daha ve hesabınızda",
  "Category_thanku_msg3" : "puan olacak.",

  "Category_thanku_update_msg1" : "Bilgilerinizi güncellemek için teşekkürler!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
   "Category_Continue_button" : "Devam",

  "OverAll_thanku_msg1" : "Bilgilerinizi paylaştığınız için teşekkür ederiz. Kazandığınız",
  "OverAll_thanku_msg2" : "Puan.",

  "Survey_history_surveyhistory" : "Anket geçmişi",
  "Survey_history_fromthisscreen" : "Bu ekrandan tüm işlemlerinizi görebilirsiniz.",
  "Survey_history_show" : "Göster",
  "Survey_history_entries" : "Giriş",
  "Survey_history_date" : "Tarih",
  "Survey_history_details" : "Şey",
  "Survey_history_surveys" : "Anket",
  "Survey_history_pointsreceived" : "Alınan puanlar",
  "Survey_history_result" : "Sonuç",
  "Survey_history_status" : "Durum",
  "Survey_history_status_complete" : "Tam", 
  "Survey_history_status_disqualified" : "Diskalifiye", 
  "Survey_history_keyword" : "Anahtar kelime",
  "Survey_history_search" : "Arama",
  "Survey_history_to" : "Hedef",
  "Survey_history_selectoption" : "Seçeneği seçin",
  "Survey_history_earned" : "Kazan",
  "Survey_history_redeemed" : "Itfa",
  "Survey_history_norecord" : "Kayıt bulunamadı",
  "Survey_history_Surveypts" : "Anket noktaları",
  "Survey_history_Promocode" : "Promosyon kodu",

  "My_transactions_mytransactions" : "Benim hareketleri",
  "My_transactions_fromthisscreen" : "Bu ekrandan tüm işlemlerinizi görebilirsiniz.",
  "My_transactions_show" : "Göster",
  "My_transactions_entries" : "Giriş",
  "My_transactions_transactionid" : "İşlem kimliği",
  "My_transactions_date" : "Tarih",
  "My_transactions_details" : "Şey",
  "My_transactions_points" : "Puan",
  "My_transactions_status" : "Durum",
  "My_transactions_keyword" : "Anahtar kelime",
  "My_transactions_search" : "Arama",
  "My_transactions_to" : "Hedef",
  "My_transactions_selectoption" : "Seçeneği seçin",
  "My_transactions_status_earned" : "Kazan",
  "My_transactions_status_redeemed" : "Itfa",
  "My_transactions_status_pending" : "Bekleyen",
  "My_transactions_status_reverted" : "Döndürülür",
  "My_transactions_status_declined" : "Reddetti",
  "My_transactions_norecord" : "Kayıt bulunamadı",
  "My_transactions_off_msg1" : "Sonra ",
  "My_transactions_off_msg2" : ", Tüm işlem detaylarınız Puan defteri sekmesinde yayınlanacaktır.",

  "Passbook_heading" : "Puan Defteri",
  "Passbook_show" : "Göstermek",
  "Passbook_entries" : "girdileri",
  "Passbook_search" : "Ara",
  "Passbook_type" : "tip",
  "Passbook_surveyid" : "Anketler",
  "Passbook_date" : "Tarih",
  "Passbook_selectoption" : "Seçeneği Seç",
  "Passbook_credit" : "Kredi",
  "Passbook_debit" : "borç",
  "Passbook_survey_id" : "Anket No",
  "Passbook_survey_pts" : "Anket Noktaları",
  "Passbook_earned_pts" : "makas",
  "Passbook_pending_pts" : "Bekleyen puan",
  "Passbook_promo_code" : "Promosyon kodu",
  "Passbook_transaction_type" : "İşlem tipi",
  "Passbook_balance" : "Denge",
  "Passbook_status" : "durum",
  "Passbook_type_soi" : "kayıt",
  "Passbook_type_doi" : "Eposta Doğrulama",
  "Passbook_type_profile" : "Profil Tamamlama",
  "Passbook_type_survey" : "anket",
  "Passbook_type_doir" : "E-posta Doğrulaması",
  "Passbook_type_doirt" : "Yönlendirerek E-posta Doğrulama",
  "Passbook_type_surveyr" : "Anket Girişimi",
  "Passbook_type_surveyrt" : "Yönlendirmeyle Anket Girişimi",
  "Passbook_type_reward" : "Ödül",
  "Passbook_type_pending" : "ayarlama",
  "Passbook_type_reverted" : "Alınan Puanlar",
  "Passbook_type_declined" : "İstekler Reddedildi",
  "Passbook_type_redeem" : "Tazmin etmek",
  "Passbook_type_balance" : "İleri doğru",
  "Passbook_balance_vary" : "Gerçek bakiye değişebilir",
  "Passbook_survey_debit_reason" : "Çabalarınıza değer veriyoruz. Ancak, veri aktarımı sırasında kontrol edilemeyen bazı bağlantılar nedeniyle, yanıtınız kaydedilemedi. Bu anket için daha önce aldığınız noktaları tersine çevirmemiz gerekir. Bu rahatsızlıktan dolayı özür dileriz.",

  "Redeem_redeemed" : "Kurtarmak",
  "Redeem_redeemhistory" : "Geçmişi kullan",
  "Redeem_history_norecord" : "Kayıt bulunamadı",
  "Redeem_date" : "Tarih",
  "Redeem_details" : "Şey",
  "Redeem_points" : "Puan",
  "Redeem_status" : "Durum",
  "Redeem_lookslike" : "E-posta hesabınızı doğrulama cevapsız gibi görünüyor.",
  "Redeem_hurry" : "Acele! doğruladıktan sonra 1000 puan alırsınız.",
  "Redeem_pleasecheck" : "Lütfen gelen kutunuzu kontrol edin ve hesabınızı etkinleştirmek için gönderilen onay e-postasında bulunan bağlantıyı tıklayın.",
  "Redeem_kindlycheck" : "Lütfen Spam/Junk klasörünü de onay e-postası için kontrol edin.",
  "Redeem_getconfirmationemail" : "Henüz onay e-postası alamadım?",
  "Redeem_resendconfirmationemail" : "Onay e-postası yeniden gönderin",
  "Redeem_rewardredemptionrule" : "Ödül ödeme kuralı",
  "Redeem_completesurvey" : "Komple anket",
  "Redeem_earnpoints" : "Puan kazanın",
  "Redeem_redeemrewards" : "Ödülleri kullan",
  "Redeem_note" : "Not:",
  "Redeem_yourearnedpoints" : "Kazanılan puanlar, ankete uygun olduktan sonra elde edilebilir hale gelecektir. Ödülünün kullanılması için, hesabınızdaki en az geri alınabilen puanlar",
  "Redeem__points" : "Puan.",
  "Redeem_availablebalance" : "Kullanılabilir bakiye:",
  "Redeem_historydetails" : "Kurtuluş geçmişi bilgilerinizi görmek için burayı tıklatın.", 
  "Redeem_reqsucc" : "Tebrikler! Puanlarınız Refa edilmiştir. Lütfen daha fazla ayrıntı için kayıtlı posta kutunuzu kontrol edin.", 
  "Redeem_wisecheck_youhave" : "Tebrikler! Ödülünüzden sadece bir tık uzaktasınız.",
  "Redeem_wisecheck_gcagainst" : "Hediye kartı",
  "Redeem_wisecheck_points" : "Puan.",
  "Redeem_wisecheck_issue" : "Sistem hesabınızdaki bazı tanınmayan aktiviteyi yakalamıştır, çünkü hangi bir kullanma isteği gönderemiyorsanız. Lütfen iletişim",
  "Redeem_emailverify_issue" : "Puanlarınızı kullanmak için, kayıtlı e-posta hesabınızı doğrulamanızı rica ediyoruz.",
  
  "Redeem_unsubscribe_issue" : "WisePerks'ten aboneliğinizin iptal edildiği gibi bir itfa isteği gönderemezsiniz. Tekrar abone olmak için lütfen support@wiseperks.com ile iletişime geçin.",
  "Redeem_wisecheck_message1" : "Bazı güvenlik nedenleriyle kullanamazsınız. Destek almak için lütfen iletişime geçin",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Onaylamak",
  "Redeem_Cancel_button" : "İptal",
  "Redeem_pending_hello" : "Merhaba",
  "Redeem_pending" : "ayarlama",
  "Redeem_pending_msg1" : "Hesabınız ayarını gösterir",
  "Redeem_pending_msg2" : "yapılması gereken noktalar. İşlemi başlatmak için lütfen onaylayın.",

  "Dashboard_wisecheck_issue" : "Sistem, hesabınızda bazı tanınmayan etkinlikleri yakaladı, dolayısıyla anketleri göremiyorsunuz. Daha fazla açıklama için lütfen support@wiseperks.com ile iletişime geçin.",
  "Dashboard_unsubscribe_issue" : "WisePerks'ten abone olduğunuzdan, herhangi bir anketi görüntüleyemezsiniz. Anketleri tekrar almak için lütfen support@wiseperks.com ile iletişime geçin.",


  "WC_DashboardMsg_1" : "Hata! Profilinizi şimdikiyle eşleştirmek için anket yok.",
  "WC_DashboardSubMsg_1" : "Endişelenmeyin, envanterimizi karıştırıyoruz ve yakında yeni anketleri yollayacağız.",

  "WC_DashboardMsg_2" : "Kontrol Panelinizi yeniliyoruz. Lütfen bir süre sonra yeni anketleri kontrol edin.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Anket Tamamlanıyor için teşekkür ederiz. Sadece response_points puan kazandı. Toplam birikmiş puanlarınızı görmek için panonuza giriş yapın.",
  "Survey_success_msg2" : "Anket Tamamlanıyor için teşekkür ederiz. Az önce kazandın response_points Puan.",

  "Survey_success_but_nomore_survey" : "Kutlama! Az önce kazandın response_points Anketin tamamlanması için puan. Gelecek anketler için bir ara sonra kontrol ediniz.",
  
  "Survey_terminate_pnt_msg1" : "Bazı bilgileriniz gibi görünüyor gereksinimi biraz farklıdır. Biz değer zaman ve çabaları, hesabınıza response_points puan ekleyeceğiz. Size daha kârlı ve sezgisel anketler getirerek odaklandık.",
  
  "Survey_terminate_nopnt_msg1" : "Bazı cevaplarınız gereksinimden biraz farklı görünüyor. Yolunuza daha fazla anket göndereceğiz. Emeğiniz ve ayırdığınız zaman için teşekkürler.",

"TermsConditions_t&c" : "Şartlar ve koşullar",
  "TermsConditions_WhenRegister" : "Kayıt olduğunuzda",
  "TermsConditions_WhenYouCreate" : "Kişisel bir hesap oluşturduğunuzda, burada belirtildiği gibi size bazı kişisel bilgiler soruyoruz:",
  "TermsConditions_YouCanUpdate" : "Temel profil bilgilerinizi istediğiniz zaman güncelleyebilir veya değiştirebilirsiniz.",
  "TermsConditions_YouCanOptOut" : "Hesabınızı istediğiniz zaman devre dışı bırakabilir veya kapatabilirsiniz.",
  "TermsConditions_WeRespectYour" : "Bilgilerinizi izinsiz fiziksel ve elektronik erişimden korumak için gizliliğinize saygı duyuyor ve teknolojiyi en iyi şekilde kullanıyoruz.",
  "TermsConditions_WeShareYour" : "Bilgilerinizi yalnızca WiseWorks'ü işletmek için gerekli olan kuruluşlarla paylaşıyoruz. Ve bu kuruluşların gizlilik ve güvenliğiniz için taahhütlerimizi yerine getirmeleri gerekiyor.",
  "TermsConditions_WhatInformation" : "Sizden hangi bilgileri topluyoruz?",
  "TermsConditions_DirectlyCollected" : "Doğrudan toplanan",
  "TermsConditions_EmailAddressAndPassword" : "Hesabınızın e-posta adresi ve şifresi bizimle.",
  "TermsConditions_YourResponse" : "Profil sorularına cevabınız.",
  "TermsConditions_AnyCustomer" : "Herhangi bir müşteri hizmetleri yazışması.",
  "TermsConditions_AnyPersonalDetails" : "Kefaret için paylaştığınız kişisel detaylar",
  "TermsConditions_AnyOtherInformation" : "Doğrudan sizin tarafınızdan sağlanan diğer bilgiler.",
  "TermsConditions_UsageData" : "Kullanım verisi",
  "TermsConditions_YourIPAddress" : "IP adresiniz, coğrafi konumunuz ve çerezleriniz",
  "TermsConditions_InformationAboutTheDevice" : "Kullandığınız cihazla ilgili bilgiler",
  "TermsConditions_YourSurveyActivity" : "Anket etkinliğiniz, hesabınızdaki puanlar, ödemenizden sonra aldığınız ödemeler.",
  "TermsConditions_HowDoWeUse" : "Verilerinizi nasıl kullanıyoruz?",
  "TermsConditions_SendingYouInvitation" : "Hak ettiğiniz ankete katılmak için davetiye göndermek için e-posta gönderebilirsiniz.",
  "TermsConditions_DetectAndPrevent" : "Hileli aktiviteyi tespit edin ve önleyin.",
  "TermsConditions_ImproveYourExperience" : "WiseWorks platformunda deneyiminizi geliştirin",
  "TermsConditions_YourResponseAreTransferred" : "Yanıtlarınız, birleştirilmiş ve tanımlanmamış bir biçimde bağlı üçüncü taraflarımıza ve ortaklarımıza aktarılır.",
  "TermsConditions_OurPartnersCanAsk" : "Ortaklarımız anket sırasında adınızı, e-posta adresinizi ve diğer iletişim bilgilerinizi vermenizi isteyebilir.",
  "TermsConditions_YouAreFreeToDecline" : "Bu bilgileri WiseWorks'e değil doğrudan onlara sunduğunuzu kabul ederseniz, ortaklarınıza bilgi vermeyi reddetmekte özgürsünüz.",
  "TermsConditions_YourInformationWillBe" : "Bilgileriniz WiseWorks’ün Gizlilik Politikası değil, ilgili iş Gizlilik Politikası uyarınca ele alınacaktır.",
  "TermsConditions_OurPartnersAreCommited" : "Ortaklarımız gizliliğinize saygı duymayı ve korumayı taahhüt eder.",
  "TermsConditions_WhatWeDont" : "Bununla ne yapmıyoruz?",
  "TermsConditions_WeDontSellOnThat" : "Bu bilgileri kimseyle satmıyoruz ve bunu size satmak için kullanmıyoruz. Verilerinizi paylaşmadan önce her zaman izin isteyeceğiz.",
  "TermsConditions_YourRightToControl" : "Verilerinizi kontrol etme hakkınız",
  "TermsConditions_YouHaveFullControl" : "Verilerinizin tam kontrolü sizde.",
  "TermsConditions_YouCanAccess" : "Temel profil bilgilerinizi istediğiniz zaman erişebilir, güncelleyebilir veya değiştirebilirsiniz.",
  "TermsConditions_YouCanUnsubscribe" : "Hesabınızın aboneliğini iptal edebilir ve devre dışı bırakabilirsiniz.",
  "TermsConditions_YouAreFreeToGetYour" : "Support@wiseperks.com adresinden bize bir mail göndererek profilinizi silebilirsiniz. Endişenizi çözmeyi deneyeceğiz ve hesabınızı hala silmek istiyorsanız, 72 saat içinde bunu yapıyor olacak.",
  "TermsConditions_DataSecurity" : "Veri güvenliği",
  "TermsConditions_OurTechTeamWorks" : "Teknik ekibimiz tüm verilerinizi güvende tutmak için gerçekten çok çalışıyor.",
  "TermsConditions_WeMakeUseOfLatest" : "Verilerinizin yanlış kullanımından veya yetkisiz erişimlerinden kaçınmak için en son teknoloji ve önlemlerden yararlanıyoruz. Ancak, çevrimiçi ortamın doğası gereği, veri güvenliğinin% 100 güvencesini veremeyiz.",
  "TermsConditions_WeTryOurBestToRule" : "Riskleri ortadan kaldırmak için elimizden gelenin en iyisini yapıyoruz, ancak risksiz çevreyi garanti edemeyiz. Bu Hüküm ve Koşulları kabul ederek, bu riskleri kabul edersiniz ve kabul edersiniz.",
  "TermsConditions_AnyQuestions" : "Herhangi bir sorunuz, lütfen bizimle temas kurmaktan çekinmeyin",
  "TermsConditions_IfYouHaveAnyQueries" : "Gizlilik politikamız ve prosedürlerimiz hakkında herhangi bir sorunuz veya şikayetiniz varsa. Lütfen bize support@wiseperks.com adresinden bir e-posta gönderin ve size 48 saat içinde geri döneceğiz.",

  "Validation_PleaseEnterProperDate" : "Lütfen doğru doğum tarihini giriniz",
  "Validation_PleaseSelectOne" : "Lütfen bir seçeneği seçin!",
  "Validation_PleaseSelectValidOptions" : "Lütfen Geçerli Seçenekleri Seçiniz!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Lütfen en az bir seçenek seçin",
  "Validation_PleaseInsertTheZip" : "Lütfen Posta Kodu / Posta Kodunu giriniz.",

  "Releases_releases" : "Serbest bırakır",
  "Releases_theUseOfThis" : "Bu Web sitesinin kullanımı aşağıdaki kullanım koşullarına tabidir.",
  "Releases_featuresOfWP" : "Şekil-in WP serbest bırakmak",
  "Releases_updatedOn" : "güncelleştirmek üstünde",
  "Releases_WisePerksNow" : "WisePerks şimdi birden fazla dil destekler",
  "Releases_youCanWalkThourgh" : "Wiseperks sizin tercih edilen dilde kullanılabilir olarak çok daha kolay şimdi site logosuna yürüyebilirsiniz.",
  "Releases_languagesAvailable" : "Kullanılabilir diller:",
  "Releases_EarnMore" : "Yeni nokta sistemimiz ile daha fazlasını kazanın",
  "Releases_theNewWisePerksAccumulates" : "Yeni WisePerks hesabınıza puan biriktirir. Bu puanlar çok daha yüksek bir değer tutar ve kazanmak ve kurtarmak kolay. Birden fazla kullanma seçeneği arasından seçim yapmak için, anketin deneyiminden faydalanacağından eminiz.",
  "Releases_accumulatePoints" : "Hassel ücretsiz redeemption ile puan birikir",
  "Releases_youGetTheBenefit" : "Sen aynı Hayır kazanma yararı olsun. Onları kurtarmak istediğiniz para birimine bakılmaksızın, puanlar.",
  "Releases_surveyDisqualification" : "Anket diskalifiye artık kötü bir haber değil",
  "Releases_youEarnPoints" : "Eğer bir anket için uygun değil bile puan kazanmak, cevap başladı.",
  "Releases_newAndImproved" : "Yeni ve geliştirilmiş düzen",
  "Releases_theFreshLookOf" : "WisePerks 'in taze bakışı sana aşık olacak. Artık kullanımı çok daha kolay ve sizin için büyük bir deneyim olacaktır.",
  "Releases_whatAvatarAreYouToday" : "Hangi Avatar bugün",
  "Releases_youCanNowCreate" : "Artık panonuzda kendi avatarınızı oluşturabilirsiniz.",
  "Releases_freshAndHotSurveys" : "Panonuzda taze ve sıcak anketler",
  "Releases_youDontHaveTo" : "Yeni anketler görüntülemek için panonuza yenileme konusunda endişelenmenize gerek yok, biz otomatik olarak en son anketler ile doldurulan Pano devam edeceğiz.",
  "Releases_promoCode" : "Promosyon Kodu özelliği",
  "Releases_promoCodeInfo" : "Popüler talep üzerine, WisePerks her hafta panelistleri için Promosyon Kodları getiriyor. Daha fazla ödül almanın hızlı yolu.",
  "Releases_referFriend" : "Arkadaşa Öner",
  "Releases_referFriendInfo" : "Arkadaşlarınızı WisePerks ile ilişkilendirin ve heyecan verici ödüller kazanın. Hesabınızdaki Arkadaşına Tavsiye Et seçeneğine bakın.",

  "Dashboard_Promocode_Usepromo" : "Promo kodunu kullan",
  "Dashboard_Promocode_to" : "Hedef",
  "Dashboard_Promocode_earnminimum_1" : "minimum kazan",
  "Dashboard_Promocode_earnminimum_2" : "ekstra puanlar",
  "Dashboard_Promocode_minimum" : "asgari",
  "Dashboard_Promocode_minimumextrapoints" : "bugün tamamlanan her ankette ekstra puan",
  "Dashboard_Promocode_apply" : "Uygulamak",
  "Dashboard_Promocode_message1" : "Promosyon Kodu geçerli değil!",
  "Dashboard_Promocode_message2" : "Tebrikler! Minimum alacak",
  "Dashboard_Promocode_message3" : "Bugün tamamlanan her ankette fazladan!",

  "Dashboard_modal_Enjoythefestive" : "Bugün tamamlayacak olan her ankete en az",
  "Dashboard_modal_Extrapointson" : "ekstra puan kazanarak şenlikli ruhun keyfini çıkarın.",

  "refer_friend_title" : "Arkadaşınıza Tavsiye Edin ve Ödüllendirin",
  "refer_friend_step_first": "Bir arkadaşını WisePerks'e davet et",
  "refer_friend_step_second": "Arkadaş WisePerks'te başarıyla kaydoldu",
  "refer_friend_step_third": "each_referral puan alıyorsunuz",
  "refer_friend_link_title": "Şimdi bir arkadaşını davet et!",
  "refer_friend_share_title": "Bağlantıyı paylaş",
  "refer_friend_guideline_title": '"ARKADAŞLA BAŞVURUN" size nasıl fayda sağlayacak:',
  "refer_friend_guideline_one": "Lütfen yukarıda verilen bağlantıyı önerilerinizle paylaşın.",
  "refer_friend_guideline_two": "İstediğiniz sayıda kişiye başvurabilirsiniz. Tavsiyelerinizden 5'i hesaplarını doğruladıktan ve anket etkinliğini başlattıktan sonra, kazanılan tavsiye puanlarınız hesabınıza eklenecektir.",
  "refer_friend_guideline_three": "Başarılı (her biri doğrulanmış e-posta hesabı ve anket etkinliği ile) tavsiye için each_referral puan alıyorsunuz.",
  "refer_friend_guideline_four": "Puanlar, iki aşamada, e-posta doğrulamasında partial_referral ve yönlendirmeniz yoluyla ankette partial_referral kazanılacaktır.",
  "refer_friend_guideline_five": "5 başarılı (doğrulanmış e-posta hesabı ve anket etkinliği) yönlendirmesinden sonra yönlendirme bağlantısı devre dışı bırakılacak",


  }
  
