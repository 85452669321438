export const TRANSLATIONS_EN = {
  "home_header_selectlang": "Select Language",
  "home_header_loginbutton": "Login",
  "home_header_signupbutton": "SignUp",
  "home_header_homebutton": "Home", 
  
  "home_register_bannertxt1": "Here your",
  "home_register_bannertxt2": "thoughts",
  "home_register_bannertxt3": "can fetch you",
  "home_register_bannertxt4": "reward$",
  "home_register_bannertxt5": "Get bonus",
  "home_register_bannertxt6": "On SignUp",
  "home_register_fbsignup": "SIGN UP WITH FACEBOOK",
  "home_register_fblogin": "LOG IN WITH FACEBOOK",
  "home_register_or": "OR",
  "home_register_emailsignup": "Sign Up with Email",
  "home_register_emaillogin": "Login with Email",
  "home_register_fpass": "Forgot Password?",
  "home_register_fname": "First Name",
  "home_register_lname": "Last Name",
  "home_register_email": "Email Address",
  "home_register_pass": "Password",
  "home_register_cpass": "Confirm Password",
  "home_register_tcpp": "Please agree to our",
  "home_register_tcpptandcond": "Terms & Conditions",
  "home_register_tcppand": "and",
  "home_register_tcppprivacy": "Privacy Policy",
  "home_register_join": "Join Us. It's Free",
  "home_register_login": "Log In",
  "home_register_name_required" : "Name is Required",
  "home_register_email_required" : "Email is Required",
  "home_register_invalid_email" : "Invalid Email Format",
  "home_register_password_required" : "Password is required",
  "home_register_password_lower_digit" : "Password must be 6 digit is required",
  "home_register_password_higher_digit" : "Password must be used 15 digit is required",
  "home_register_cpassword_required" : "Confirm Password is required",
  "home_register_cpassword_lower_digit" : "Confirm Password must be 6 digit required",
  "home_register_cpassword_higher_digit" : "Confirm Password must be used 15 digit required",
  "home_register_cpassword_not_match" : "Confirm Password not match",
  "home_register_tnc_required" : "Please agree T&C",

  "home_register_fp_not_worry" : "Please enter your registered emailid.",
  "home_register_fp_resetpass_msg" : "To reset your password, please enter your registered email id.",
  "home_register_fp_enter_email_msg" : "Enter your email here", 
  "home_register_fp_submit_button" : "Submit", 
  "home_register_fp_temporary_sent_pass_msg" : "A reset password link has been sent to your registered email id. Please update your password at the earliest.", 

  "home_works_howitworks": "How it works?",
  "home_works_howitworkst1": "Introduce yourself", 
  "home_works_howitworksd1": "Become a member, Sign Up",
  "home_works_howitworkst2": "Receive surveys",
  "home_works_howitworksd2": "Start receiving intuitive and simple surveys",
  "home_works_howitworkst3": "Earn Points",
  "home_works_howitworksd3": "Give your best responses and accumulate points",
  "home_works_howitworkst4": "Redeem",
  "home_works_howitworksd4": "Redeem your points for many exciting rewards",
  
  "home_aboutus_whoweare": "Who we are?",
  "home_aboutus_whowearep1": "At WisePerks we work hard to make sure that the time you spend online is a rewarding experience. We work with hundreds of companies who are eager to have your feedback, know how you feel about their products or services. You provide your opinion on various services, but none of those services actually reward you for your time, unlike WisePerks - where every survey you participate in gives you great cash rewards for your time.",
  "home_aboutus_whowearep2": "We value your feedback and want you to get rewarded for your time. We have surveys on various topics to match your interests and make them easy to answer. You can easily make around $50-$100 every month by taking various surveys. So let's work together to make it happen.",
  
  "home_earn_more_surabt": "What surveys ask?",
  "home_earn_more_bp": "Business Experts",
  "home_earn_more_consumers": "Consumer",
  "home_earn_more_hc": "Health & Fitness",
  "home_earn_more_entertainment": "Entertaining & Fun",
  "home_earn_more_gaming": "Gaming Freaks",
  "home_earn_more_tech": "Tech Savvy",
  "home_earn_more_bonus_survey": "Bonus Survey",

  "home_reward_whatuearn": "What you earn?",
  "home_reward_whatuearnp1": "We like to keep things simple. You get points for all successfully completed surveys. The more surveys you complete, the more points you earn. The points can be redeemed for Gift Cards of your choice after you cross a certain threshold.",
  "home_reward_whatuearnp2": "Once ready to redeem, choose the option you like - Amazon, Starbucks, iTunes and many others. We also give out Bonuses and Thank God Its Friday offers from time to time.",
  
  "home_counter_earnedover": "Members have earned over",
  
  "home_contact_getintouch": "Get in touch",
  "home_contact_emailus": "For any questions, please email us at",

  "home_footer_faq": "FAQs",
  "home_footer_pp": "Privacy Policy",
  "home_footer_tc": "T&C",
  "home_footer_uf": "Upcoming Features",
  "home_footer_copyright": "Copyright © current_year WisePerks. All rights reserved.",

  "home_header_home": "Home", 
  "home_footer_toknowmore": "To know more",
  "home_footer_createacc": "Create an account for free and join our thousands of happy members.",
  "home_footer_registernow": "Register Now",

  "home_reset_password" : "Reset Password", 
  "home_reset_pass_content" : "You have requested to reset password for", 

  "dashboard_sidebar_selectavatar" : "Select your avatar", 
  "dashboard_sidebar_uploadavatar" : "Click here to upload your image", 
  "dashboard_sidebar_pbank" : "Points",
  "dashboard_sidebar_accumulated" : "Earned",
  "dashboard_sidebar_realized" : "Collected",
  "dashboard_sidebar_pending" : "Adjustments",
  "dashboard_sidebar_earned_tooltip" : "Points you have collected so far.",
  "dashboard_sidebar_pending_tooltip" : "Points that need to be reversed from your account.",
  "dashboard_sidebar_main" : "Main",
  "dashboard_sidebar_dashboard" : "Dashboard",
  "dashboard_sidebar_profile" : "Profile",
  "dashboard_sidebar_updateprofile" : "Update Profile",
  "dashboard_sidebar_shistory" : "Survey History",
  "dashboard_sidebar_mtrans" : "My Transaction",
  "dashboard_sidebar_passbook" : "Points Book",
  "dashboard_sidebar_redeem" : "Redeem",
  "dashboard_sidebar_refer_friend" : "Refer Friend",
  "dashboard_sidebar_logout" : "Logout",

  "dashboard_sidebar_tutorial1" : "Shows you all the available surveys and what you can earn from them.",
  "dashboard_sidebar_tutorial2" : "Answer few questions here to let us know you better and help us in sending you surveys of your interest. Please note for some questions you would not be able to update your responses after submitting.",
  "dashboard_sidebar_tutorial2update" : "You can update your responses here, however few questions will not be available for updation.",
  "dashboard_sidebar_tutorial3" : "Find here all the surveys you attempted.",
  "dashboard_sidebar_tutorial4" : "Helps you keep track of all your transactions on WisePerks.",
  "dashboard_sidebar_tutorial5" : "Few quick steps to convert your realized points to rewards.",
  "dashboard_sidebar_tutorial6" : "See the entire transaction history of the points debited/credited to your account.",
  "dashboard_sidebar_gotit" : "Got it",

  "dashboard_sidebar_note" : "Please note",
  "dashboard_sidebar_note1" : "All responses must be answered honestly.",
  "dashboard_sidebar_note2" : "Please be specific and precise with open ended responses.",
  "dashboard_sidebar_note3" : "Don't speed! Minimum survey completion times is set.",
  "dashboard_sidebar_note4" : "Please provide consistent responses when same questions are asked.",
  "dashboard_sidebar_note5" : "New surveys will be added and closed ones will be removed automatically from your dashboard.",
  "dashboard_sidebar_note6" : "Earn some quick points just by referrals.",

  "dashboard_setting_hi" : "Hi",
  "dashboard_setting_minfo" : "Membership Info",
  "dashboard_setting_macc" : "Manage Account",
  "dashboard_setting_upass" : "Update Password",
  "dashboard_setting_yourname" : "Your name is",
  "dashboard_setting_fname" : "Firstname",
  "dashboard_setting_lname" : "Lastname",
  "dashboard_setting_save" : "Save Changes",
  "dashboard_setting_bornon" : "You were born on",
  "dashboard_setting_youare" : "You are a",
  "dashboard_setting_postal" : "Your postal code is",
  "dashboard_setting_emailus" : "For any assistance or queries, please send an email to",
  "dashboard_setting_membersince" : "You have been a member since",
  "dashboard_setting_taken" : "You have taken",
  "dashboard_setting_completed" : "surveys and completed",
  "dashboard_setting_ofthem" : "of them.",
  "dashboard_setting_earned" : "You've earned",
  "dashboard_setting_pts" : "points",
  "dashboard_setting_started" : "since you started.",
  "dashboard_register_email" : "You are registered with us from",
  "dashboard_setting_preferences" : "Manage your preferences",
  "dashboard_setting_emailoptout" : "Email Opt Out",
  "dashboard_setting_futureemails" : "Opt out of receiving future emails from WisePerks.",
  "dashboard_setting_futureemailsflash" : "Our emails keep you posted on all the lucrative surveys available for you. Are you sure you don't want to receive them.",
  "dashboard_setting_nrecemail" : "Our emails keep you posted on all the lucrative surveys available for you. Are you sure you don't want to stop them.",
  "dashboard_setting_emailout_modalmsg" : "Are you sure you want to opt out from receiving future emails from WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Are you sure to start receiving future emails from WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Your have successfully stops receiving emails from WisePerks",
  "dashboard_setting_emailin_succmsg" : "Your have successfully start receiving emails from WisePerks",
  "dashboard_setting_deactivateacc" : "Deactivate Account",
  "dashboard_setting_disableprofile" : "Deactivating your account will disable your profile from WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Are you sure you want to deactivate your account on WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "If you have any complaints or feedback, then please share with us. We would address it on priority. Give us a chance. Are you sure you want to delete your account with us.",
  "dashboard_setting_changelang" : "Change Language",
  "dashboard_setting_preferredlang" : "Select your preferred language",
  "dashboard_setting_updatepass" : "Update Password",
  "dashboard_setting_plsupdate" : "Please update your password below.",
  "dashboard_setting_oldpass" : "Old password",
  "dashboard_setting_enteroldpass" : "Enter Old Password",
  "dashboard_setting_newpass" : "New password",
  "dashboard_setting_enternewpass" : "Enter New Password",
  "dashboard_setting_confpass" : "Confirm password",
  "dashboard_setting_enternewpassagain" : "Enter New Password Again",
  "dashboard_setting_update" : "Update",

  "dashboard_doi_welcome" : "Welcome",
  "dashboard_doi_welbck" : "Welcome back",
  "dashboard_doi_accntverified" : "Looks like you missed verifying your email account. ", 
  "dashboard_doi_getaccess" : "Hurry! you get",
  "dashboard_doi_getaccess2" : "points once you verify.",
  "dashboard_doi_activateacc" : "Please check your inbox and click the link given in the account verification email sent to your registered email account.", 
  "dashboard_doi_chckspam" : "Kindly, check your Spam/Junk folder in case you are not able to find the verification email.",
  "dashboard_doi_didntgetconf" : "Didn't get verification email yet?", 
  "dashboard_doi_resendconf" : "Resend verification email", 
  "dashboard_doi_resendconfsucc" : "Verification email has been sent to your registered email.", 
  
  "dashboard_survey_availsurveys" : "Available Surveys",
  "dashboard_survey_wisechecksurveys1" : "You are not able to see surveys due to some security reasons. Please contact to support on",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Not Available Surveys for Unsubscribe Reason",
  "dashboard_featured" : "FEATURED",
  "dashboard_profile_survey" : "Profile Survey",
  "dashboard_survey_start" : "Start",
  "dashboard_survey_on" : "On",
  "dashboard_survey_off" : "Off",
  "dashboard_survey_point" : "pt",
  "dashboard_survey_points" : "Pts",
  "dashboard_survey_minute" : "Min",
  "dashboard_survey_minutes" : "Mins",

  "demography_hi" : "Hi",
  "demography_welcome" : "Welcome to WisePerks. You have just earned yourself",
  "demography_points" : "points by registering with us.",
  "demography_share" : "Please share few more details in the form below, to help us in bringing relevant surveys to you.",

  "demography_gender" : "I am a",
  "demography_male" : "Male",
  "demography_female" : "Female",
  "demography_birthday" : "Birthday",
  "demography_month" : "Month", 
  "demography_day" : "Day", 
  "demography_year" : "Year", 
  "demography_jan" : "January",
  "demography_feb" : "February",
  "demography_mar" : "March",
  "demography_apr" : "April",
  "demography_may" : "May",
  "demography_jun" : "June",
  "demography_jul" : "July",
  "demography_aug" : "August",
  "demography_sep" : "September",
  "demography_oct" : "October",
  "demography_nov" : "November",
  "demography_dec" : "December",
  "demography_hearabout" : "Where did you hear about WisePerks?",
  "demography_opt1" : "Select the closest that applies",
  "demography_opt2" : "Email/Newsletter",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum or Blog",
  "demography_opt5" : "Friend",
  "demography_opt6" : "LinkedIn",
  "demography_opt7" : "Search Engine",
  "demography_opt8" : "TV Commercial",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Website",
  "demography_opt11" : "YouTube",
  "demography_opt12" : "Other",
  "demography_zipcode": "Zipcode",
  "demography_submit" : "Submit",

  "privacypolicy_privacypolicy" : "Privacy Policy",
  "privacypolicy_privacyisimp" : "Your privacy is important to us.",
  "privacypolicy_lastupdated" : "Last updated on",

  "privacypolicy_para1" : 'WiseWorks (together with its affiliates, the "Company") owns and operates a number of different websites, mobile apps, and interactive services, including without limitation Wisesample.com, Flapbucks.com, WisePerks.com, and others (collectively, the "WiseWorks Sites"). This privacy policy ("Privacy Policy") applies to the WiseWorks Sites and to all of the features, mobile applications, emails, online services and other functionalities, whether accessed via a computer, mobile device, or otherwise and the services available on or through the WiseWorks Sites and Features (the "Services"). This Privacy Policy only covers information collected by the Company through the WiseWorks Sites and Features and our Services, and does not cover any information received or collected offline by the Company (unless specifically stated) or collected by any unrelated websites or companies to which we may provide links.',

  "privacypolicy_para2" : "It is expected that the panelist/respondent shall fill out his profile honestly and truthfully and not submit wrong or incorrect information simply to qualify for more surveys. It is also expected that panelist/respondent shall not manipulate his answers or use other unfair means (changing IP, multiple accounts etc ) during survey filling to earn incentives. WisePerks has sufficient safeguards to detect such anomalies and if such behaviour is noticed, the panelist will be banned/blocked from WisePerks and all/any of his redemption requests shall be cancelled.",

  "privacypolicy_para3" : "WiseWorks is strongly committed to safeguarding your privacy with us. The following privacy policy explains how your personal information is held and treated as you explore our website and services.",

  "privacypolicy_topic1" : "What personally identifiable information does WisePerks.com collect from me?",
  "privacypolicy_topic1ans" : "WisePerks.com collects information in several manners from different parts of our website and network. Some information is gathered when you sign up. During member registration, WisePerks.com asks for your name, email address, zip code and household information. The more information you volunteer to provide accurately, the better we are able to offer you services and rewards. Additionally, if you ever contact WisePerks.com through any means, we may keep a record of that correspondence. Whenever WisePerks.com collects any such information, we aim to include a link to this Privacy Policy on that page.",

  "privacypolicy_topic2" : "What are cookies and how does WisePerks.com use them?",
  "privacypolicy_topic2ans" : "A cookie is a packet of electronic data sent to your browser, from a web server and stored on your computer's hard drive. As part of offering you specific surveys, information, and personalized services, WisePerks.com may use cookies to store and sometimes track information about you. WisePerks.com may require that you accept cookies for preparing such specific content for you. This also avoids you having to enter your login information every time.",

  "privacypolicy_topic3" : "What is WisePerks.com policy on allowing me to update, edit, change or delete my personal account information?",
  "privacypolicy_topic3ans" : "You may edit your WisePerks.com Account Information any time by using your WisePerks.com Log in and password. Please contact",
  "privacypolicy_topic3ans2" : "for further instructions about deactivating your WisePerks.com account.",

  "privacypolicy_topic4" : "Who is collecting information and for what purpose?",
  "privacypolicy_topic4ansp1" : "WisePerks.com collects several information through surveys and registration and other means, which helps its research clients develop new products and services for consumers. You are sharing that information with WisePerks.com only, unless it is specifically stated otherwise on any survey you participate. If data is being collected and/or maintained by any company other than WiseWorks -- or WiseWorks sites , you will be notified prior to the time of the data collection or transfer. If you do not want your data to be shared, you may choose not to allow the transfer by not using that particular survey or product.",
  "privacypolicy_topic4ansp2" : "Rewards and promotions that we offer on WisePerks.com may be either sponsored by other companies independently, or may be co-sponsored by WisePerks.com and another company. Some data collected during such a promotion may be shared with the sponsor. However, you will be notified prior to the time of data collection or transfer. You can decide not to participate in that particular promotion if you do not want to share your data.",
  "privacypolicy_topic4ansp3" : "Any specific Sensitive Information (like information specifying medical or health conditions, racial or ethnic origin, political views, religious or spiritual beliefs, trades and union membership or information specifying the sex life of the individual) will be treated with additional care. If WisePerks.com intends to share such information to another company for a different purpose than originally collected, then you will be expressly asked permission prior to any such disclosure.",

  "privacypolicy_topic5" : "With whom does WisePerks.com share my information?",
  "privacypolicy_topic5ansp1" : "We take privacy very seriously. WisePerks.com will not disclose any of your personally identifiable information except when we have your explicit permission or under any special circumstances, that the law requires it or under the circumstances specified in detail below. The following describes some of the ways that your information may be disclosed. Please see the Terms of Service for our services for more detailed information about how your personal information may be shared.",
  "privacypolicy_topic5ansp2" : "Business Partners, Clients & Sponsors:",
  "privacypolicy_topic5ansp3" : "WisePerks.com may disclose your personal information to our partners, clients or sponsors. However, if any personal data about you were ever to be shared, we will tell you before we collect or transfer the data. Many activities we offer request additional information from sponsors. By you requesting more information, you give WisePerks.com permission to share your information with the sponsor so they can fulfill your request. In many instances, only your email address will be shared. If more information will be shared with our partners, you will be notified in advance. From time to time, you may be offered the opportunity to receive test materials, or other products or special offers from our partners. If you opt-in to receive information from these third parties, WisePerks.com will (with your permission) share your name and email address with them.",
  "privacypolicy_topic5ansp4" : "WisePerks.com may also disclose personal information in some special cases when disclosing this information is necessary to identify, contact or bring legal action against someone who may be violating WisePerks.com 's policies and Terms of Service or may be causing any injury to or interference with (either intentionally or unintentionally) WisePerks.com 's products, services, rights or property, other WisePerks.com members and users, or anyone else that could be affected by any such activities. WisePerks.com may disclose or access account information when we believe in good faith that the law requires it and for administrative and other purposes that we deem necessary to maintain, service, and improve our products and services.",

  "privacypolicy_topic6" : "What are the legal bases we use for processing your information?",
  "privacypolicy_topic6ans" : 'WiseWorks is committed to Privacy and Data protection. Our privacy policy adopts the principles of General Data Protection Regulation issued by the European Union ("GDPR") as a benchmark to which we, our employees and suppliers adhere.',

  "privacypolicy_topic7" : "What are my choices regarding collection, use, and distribution of my information?",
  "privacypolicy_topic7ans" : "Only WisePerks.com (or partners working with WisePerks.com under confidentiality agreements) will send you any direct mails, only if you indicated that you do not object to these. You also have choices with respect to how cookies work. By changing your internet browser settings, you have the choice to accept all cookies, notified when a cookie is set, or to reject all cookies request. If you choose to reject all cookies, you will be unable to use those WisePerks.com services that do require prior registration in order to participate. WisePerks.com does not sell or lease any user information to anyone. We will always notify you at the time of data collection or transfer if your data will be shared with any third party and you will always have the option of not allowing it to be shared.",

  "privacypolicy_topic8" : "What security precautions are in place at WisePerks.com to protect any loss, misuse, or alteration of my account information?",
  "privacypolicy_topic8ansp1" : "Your WisePerks.com Account Information are password-protected so that you and only you have access to this information.",
  "privacypolicy_topic8ansp2" : "We strongly recommend that you do not share your password with anyone. We will never ask you for your password in an unsolicited phone call or email. Please sign out of your WisePerks.com account and close your browser window when you have finished your activity. This ensures that others cannot access your personal information and account, if you are using a public computer or share your computer with someone else. Whenever WisePerks.com handles personal information as described above, regardless of where this occurs, WisePerks.com takes steps to ensure that your information is treated securely and in accordance with the relevant Terms of Service and this Privacy Policy. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, WisePerks.com cannot ensure or warrant the security of any information you transmit to us or from our online products or services, and you do so at your own risk. Once we receive your data, we make our best effort to ensure its security on our systems.",

  "privacypolicy_topic9" : "What else should I know about my privacy?",
  "privacypolicy_topic9ans" : "Please keep in mind that whenever (and wherever) you choose to disclose personal information online - for example through emails, social networking websites, blogs, or in chat areas - that information can be collected and used by any other user. If you post personal information online that is accessible to the public, you may receive unsolicited messages from other parties in return. Ultimately, you are solely responsible for maintaining the secrecy of your passwords and/or any account information. Please be careful and responsible whenever you're online.",

  "privacypolicy_topic10" : "How does WisePerks.com protect the privacy of my children?",
  "privacypolicy_topic10ans" : "WisePerks.com obtains prior parental consent where surveys require children to participate and parental supervision is also required for any such surveys with children ages 6-15. We take special extra care to protect the safety and privacy of young users on our site, and we encourage you to participate in your child's experience online. We do not permit any marketing or in children's research and the certain topics are completely prohibited in research among children: Name/account name/E-mail or physical address, Phone number, Race or Ethnicity, Religion or Cast, Household income, Family illness, Marital status of parents or other family members, Relationship matters, Grades in school or college, Alcohol, Sexual Issues, Tobacco and Firearms.",

  "privacypolicy_topic11" : "Special Note to International Users",
  "privacypolicy_topic11ansp1" : "The Site is hosted in the United States. If you are a User accessing the Site from the European Union, Asia, or any other region with laws or regulations governing personal data collection, use and disclosure, that differ from United States laws, please note that you are transferring your personal data to the United States which does not have the same data protection laws as the EU and other regions, and by providing your personal data you consent to:",
  "privacypolicy_topic11ansp2" : "The use of your personal data for the uses identified above in accordance with the Privacy Policy; and the transfer of your personal data to the United States as indicated above. YOU ACKNOWLEDGE THAT THE LAWS OF THE UNITED STATES TREAT YOUR INFORMATION IN A MANNER THAT MAY BE SUBSTANTIALLY DIFFERENT FROM, AND LESS PROTECTIVE THAN, THE TREATMENT REQUIRED UNDER THE LAWS OF OTHER COUNTRIES AND JURISDICTIONS. IF YOU DO NOT WANT YOUR INFORMATION TRANSFERRED TO THE UNITED STATES, YOU SHOULD NOT SHARE YOUR INFORMATION WITH US, OR MAKE USE OF THE SITE. TO THE EXTENT ALLOWED BY THE LAW OF THE COUNTRY IN WHICH YOU ARE LOCATED, YOU EXPRESSLY WAIVE ANY RIGHT YOU MAY HAVE TO REQUIRE US TO TREAT YOUR IDENTIFYING INFORMATION IN ACCORDANCE WITH THE LAWS OF ANY COUNTRY OR JURISDICTION OTHER THAN THE UNITED STATES. HOWEVER, THE FOREGOING WAIVER MAY NOT BE LEGALLY BINDING IN SOME COUNTRIES, SUCH AS THE MEMBER STATES OF THE EUROPEAN UNION. TO THE EXTENT IT IS NOT LEGALLY BINDING IN THE COUNTRY IN WHICH YOU ARE LOCATED, THIS FOREGOING WAIVER DOES NOT APPLY TO YOU.",

  "privacypolicy_topic12" : "Changes and Updates to this Privacy Policy",
  "privacypolicy_topic12ans" : "WiseWorks reserves the right, at our discretion, to change, modify, add, or remove portions of this Privacy Policy at any time for any reason. Each time WiseWorks exercises such right, you shall be required to affirmatively accept the updated Privacy Policy, and if you fail to do so, you will no longer be able to access or use the Site.",

  "privacypolicy_topic13": "Special Note to those looking for GDPR/CCPA details",
  "privacypolicy_topic13ansp1": "Please",
  "privacypolicy_topic13ansp2": "click here",
  "privacypolicy_topic13ansp3": "to learn more about the GDPR(General Data Protection Regulation) and CCPA(California Consumer Privacy Act) compliance details.",

  "privacypolicy_addinfo" : "Additional Information",
    "privacypolicy_sentto": "Any other questions regarding this should be sent to",

    "privacypolicyext_para1": "PLEASE READ THIS PRIVACY STATEMENT CAREFULLY",
    "privacypolicyext_para2": "WiseWorks respects every user's privacy, and we are committed to being transparent with you about how we handle personal data. This Privacy Policy explains how WiseWorks collects, shares, and uses personal data you provide to us when using the WiseWorks Website or otherwise interacting with us. We also offered some sections for you to learn how you can exercise your privacy rights and choices.",
    "privacypolicyext_para3": "Please read this Privacy Policy carefully before accessing our Website and using the WiseWorks Services. If you have any questions, please contact us at ",

    "privacypolicyext_topic1": "Overview",
    "privacypolicyext_topic2": "Acceptance And Use",
    "privacypolicyext_topic3": "Who We Are",
    "privacypolicyext_topic4": "Summary of Personal Information and Use",
    "privacypolicyext_topic5": "Purposes of Data Collection",
    "privacypolicyext_topic6": "Data Protection Principles",
    "privacypolicyext_topic7": "Types Of Data We Collect From You",
    "privacypolicyext_topic8": "Cookies and web beacons",
    "privacypolicyext_topic9": "Notifying You About Surveys And Research Projects",
    "privacypolicyext_topic10": "How We Use Your Data",
    "privacypolicyext_topic11": "With Whom We Share Your Data",
    "privacypolicyext_topic12": "EEA Data Subjects and Non- EEA",
    "privacypolicyext_topic13": "Your Data Protection Rights",
    "privacypolicyext_topic14": "Our Policy Towards Children",
    "privacypolicyext_topic15": "Data Security",
    "privacypolicyext_topic16": "Terms and Conditions",
    "privacypolicyext_topic17": "Jurisdiction",
    "privacypolicyext_topic18": "Changes to our Privacy Policy",
    "privacypolicyext_topic19": "Contact Us",

    "privacypolicyext_t1": "we",
    "privacypolicyext_t2": "us",
    "privacypolicyext_t3": "or",
    "privacypolicyext_t4": "our",

    "privacypolicyext_topic1ansp1": " respects the privacy rights of its users and understands the importance of protecting and handling information collected about you under both the law and best practice. This Privacy Notice is written to comply with",
    "privacypolicyext_topic1ansp1_1": "Article 13 and Article 14 of EU General Data Protection Regulation, i.e., Regulation(EU) 2016/679 ('GDPR'), and the California Consumer Privacy Acts CCPA.",
    "privacypolicyext_topic1ansp2": "This Privacy Notice applies to personal information that we process relating to people who have:",
    "privacypolicyext_topic1ansp2_l1": "Register for an account with us;",
    "privacypolicyext_topic1ansp2_l2": "agreed to receive marketing information from us;",
    "privacypolicyext_topic1ansp2_l3": "participate in surveys;",
    "privacypolicyext_topic1ansp2_l4": "contact us for inquires and support",
    "privacypolicyext_topic1ansp3": 'This Privacy policy also describes what rights you have regarding your data, how you give your consent, and how you can withdraw your consent. WiseWorks (together with its affiliates, the "Company") owns and operates several different websites, mobile apps, and interactive services, including without limitation Wisesample.com, Flapbucks.com, WisePerks.com, and others (collectively, the "WiseWorks Sites"). This privacy policy ("Privacy Policy") applies to the WiseWorks Sites and all of the features, mobile applications, emails, online services, and other functionalities, whether accessed via a computer, mobile device, or otherwise, and the services available on or through the WiseWorks Sites and Features (the "Services"). This Privacy Policy only covers information collected by the Company through the WiseWorks Sites and Features and our Services, and does not cover any information received or collected offline by the Company (unless specifically stated) or collected by any unrelated websites or companies to which we may provide links.',

    "privacypolicyext_topic2ansp1_1": "This Privacy Policy covers ",
    "privacypolicyext_topic2ansp1_2": " and its subsidiaries, including",
    "privacypolicyext_topic2ansp1_3": "collectively ",
    "privacypolicyext_topic2ansp1_4": "Using our website, you signify acceptance to the terms of this Privacy Policy, which is considered your official consent to process your personal information, your consent to the collection, use, and disclosure of your personal information as described further below.We may request you to provide additional disclosures or information about the data processing practices of specific Services.These notices may supplement or clarify our privacy practices or provide you with other choices about how we process your data.",
    "privacypolicyext_topic2ansp2": "If you are not comfortable with any aspect of this Privacy Policy.You should immediately discontinue any access to the WiseWorks website and our website.",

    "privacypolicyext_topic3ansp1_1": " is a company incorporated and registered in Canada(#10369675).Our registered office is at ",
    "privacypolicyext_topic3ansp1_2": "We provide panel services, for online market research industries.We enable users or participants who meet our eligibility requirements to register for a user panel account and take surveys to receive points or other incentives.",
    "privacypolicyext_topic3ansp2": "Our services are available in several countries, as well as in California (US), Canada, Germany, India, etc. The Services is not intended for use by Users under the age of 18; you must be 18 years and older to use our website and services.",

    "privacypolicyext_topic4ansp1": "We may collect or obtain Personal Data about you, and this information may be stored or processed within the United States Region through the following methods: ",
    "privacypolicyext_topic4ansp1_l1": "Directly from you (e.g., where you contact us); in the course of our relationship with you (e.g., if you use our services); ",
    "privacypolicyext_topic4ansp1_l2": "when you visit our website; or use any part of the website; ",
    "privacypolicyext_topic4ansp2": "We may also receive Personal Information about you from third parties.",

    "privacypolicyext_topic5ansp1": "The following are examples of how we use your Personal Information:",
    "privacypolicyext_topic5ansp1_l1": "Provision of Services",
    "privacypolicyext_topic5ansp1_l2": "Offering and Improving the website",
    "privacypolicyext_topic5ansp1_l3": "Surveys",
    "privacypolicyext_topic5ansp1_l4": "Communication",
    "privacypolicyext_topic5ansp1_l5": "User Engagement",
    "privacypolicyext_topic5ansp1_l6": "Marketing",
    "privacypolicyext_topic5ansp1_l7": "Lead Generation",
    "privacypolicyext_topic5ansp1_l8": "Legal Compliance",

    "privacypolicyext_topic6ansp1": "We collect, process, and use personal data(under the definitions of Article 13 and Article 14 of EU General Data Protection Regulation, i.e., Regulation(EU) 2016/679 ('GDPR'), and the California Consumer Privacy Acts CCPA) to provide our services. Personal data means any information that enables us to identify you, directly or indirectly, such as name, email, address, health information, any form of identification number, or one or more factors specific to you or your beneficiary's identity.",
    "privacypolicyext_topic6ansp2": "We are committed to complying with applicable data protection laws and will ensure that personal data is:",
    "privacypolicyext_topic6ansp2_l1": "Used lawfully, fairly, and transparently;",
    "privacypolicyext_topic6ansp2_l2": "Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those purposes;",
    "privacypolicyext_topic6ansp2_l3": "Relevant to the purposes we have told you about and limited only to those purposes;",
    "privacypolicyext_topic6ansp2_l4": "Accurate and kept up to date;",
    "privacypolicyext_topic6ansp2_l5": "Kept only as long as necessary for the purposes we have told you about;",

    "privacypolicyext_topic7ansp1": "We may collect and process the following personal information about you:",
    "privacypolicyext_topic7ansp1_l1title": "Personal Profile Information.",
    "privacypolicyext_topic7ansp1_l1": "If you register an account with us, we will collect your first and last name, your email address, and your chosen password.",
    "privacypolicyext_topic7ansp1_l2title": "Contact Information.",
    "privacypolicyext_topic7ansp1_l2": "If you opt to receive surveys via text message or other messaging media we also collect your mobile phone number or relevant contact details.",
    "privacypolicyext_topic7ansp1_l3title": "Questionnaire Data.",
    "privacypolicyext_topic7ansp1_l3": "The initial profiling questionnaire collects information about your background, education, working status, consumer habits, and, optionally, your health.We collect information about any updates you make to your profiling information.",
    "privacypolicyext_topic7ansp1_l4title": "Opinion Data.",
    "privacypolicyext_topic7ansp1_l4": "During research opportunities, we collect the information you provide relating to your opinions, attitudes, experiences, and use of certain products or services.Occasionally we may collect video, audio or photographic submissions you make during a research opportunity.",
    "privacypolicyext_topic7ansp1_l5title": "User Experience Data.",
    "privacypolicyext_topic7ansp1_l5": "We collect information about your logins, comments on blog posts, ratings, polls, web pages viewed.",
    "privacypolicyext_topic7ansp1_l6title": "Rewards Information.",
    "privacypolicyext_topic7ansp1_l6": "Any personal information we may require to issue you with a prize or reward or monitor your usage of our Panel and Survey activities.",
    "privacypolicyext_topic7ansp1_l7title": "Correspondence Data.",
    "privacypolicyext_topic7ansp1_l7": "Any correspondence you send us.",
    "privacypolicyext_topic7ansp1_l8title": "Usage Data.",
    "privacypolicyext_topic7ansp1_l8": "We also collect usage data about you whenever you interact with our services.This may include which webpages you visit, what you click on when you performed those actions, and so on.Additionally, we keep digital log files that record data each time a device accesses our servers.The log files contain data about the nature of each access.We collect data from the device and application you use to access our services, such as your IP address, browser type, internet service provider(ISP), referring / exit pages, operating system, date / time stamp, and clickstream data.",
    "privacypolicyext_topic7ansp2": "We may also infer your geographic location based on your IP address.If you arrive at an WiseWorks service from an external source(such as a link on another website, contained in an email or messaging app), we may use this information, to analyse trends, administer the site, track users, and gather demographic information about our user base as a whole.",

    "privacypolicyext_topic8ansp1": "A cookie is a text file containing a small amount of information that is sent to your browser when you visit a website.The cookie is then sent back to the originating website on each subsequent visit, or to another website that recognises it.Cookies are an extremely useful technology and do lots of different jobs.A Web beacon is an often- transparent graphic image, usually no larger than 1 - pixel x 1 pixel, that is placed on a website or in an email that is used to monitor the behaviour of the user visiting the website or sending the email.It is often used in combination with cookies.",
    "privacypolicyext_topic8ansp2": "We may collect information through the use of cookies, web beacons, or similar analytics - driven technologies.",
    "privacypolicyext_topic8ansp3_title": "What cookies do we use?",
    "privacypolicyext_topic8ansp3": "The cookies that we use fall into the following categories:",
    "privacypolicyext_topic8ansp3_l1title": "Strictly necessary cookies.",
    "privacypolicyext_topic8ansp3_l1": "These are cookies that are required for the operation of our website.They include, for example, cookies that enable you to log into secure areas of our website.",
    "privacypolicyext_topic8ansp3_l2title": "Analytical / performance cookies.",
    "privacypolicyext_topic8ansp3_l2": "They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it.This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.",
    "privacypolicyext_topic8ansp3_l3title": "Functionality cookies.",
    "privacypolicyext_topic8ansp3_l3": "These are used to recognise you when you return to our website.This enables us to personalise our content for you, greet you by name, and remember your preferences.",
    "privacypolicyext_topic8ansp3_l4title": "Targeting cookies.",
    "privacypolicyext_topic8ansp3_l4": "These cookies record your visit to our website, the pages you have visited, and the links you have followed.We will use this information to make our website and the advertising displayed on it more relevant to your interests.We may also share this information with third parties for this purpose.",
    "privacypolicyext_topic8ansp4": "We use various types of cookies(including some that we place and others that we permit third parties to place on our site) for several purposes.The information collected by cookies allows us to monitor how many people are using our services and their interactions with other services, how many people open our e- mails, and for what purposes these actions are being taken.Cookies are also used to facilitate a user’s log -in, as navigation aids, and as session timers, and to restrict underage use of our tools and services.We do not link non - personal information from cookies to personally identifiable information without permission.If for any reason you do not want us to track your use of our site using cookies, you can opt - out of this using your browser settings and can also select the option “Do Not Track” (for more information please refer to your browser).Most browsers offer instructions on how to reset the browser to reject cookies in the “Help” section of the toolbar.",
    "privacypolicyext_topic8ansp5": "We use web beacons to collect information about the use of our services.The information collected by web beacons allows us to monitor how many people are using our services, how many people open our e-mails and determine for what purposes these actions are being taken.Our web beacons are not used to track activity outside of our services.",
    "privacypolicyext_topic8ansp6_title": "How do I change my cookie settings?",
    "privacypolicyext_topic8ansp6_1": "Most web browsers allow some control of most cookies through the browser settings.To find out more about cookies, including how to see what cookies have been set, visit www.aboutcookies.org or www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "To find out how to manage cookies on popular browsers:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "When you join the WiseWorks we may communicate with you via email, phone, or messaging service(depending on your preference) concerning your membership.Occasions, when we may communicate with you include the following:",
    "privacypolicyext_topic9ansp1_l1": "Research opportunities(such as surveys, polls, focus group, etc)",
    "privacypolicyext_topic9ansp1_l2": "Research results",
    "privacypolicyext_topic9ansp1_l3": "Panelist newsletters",
    "privacypolicyext_topic9ansp2": "Our projects can focus on a wide range of topics.We will normally invite you to participate in projects by emailing, you about the opportunity.We will, at that point, explain the broad topic of the survey, its likely duration or required involvement, and the reward on offer.It is then up to you to decide whether or not you wish to take part.Participation is never mandatory.You can always drop out at any time although we prefer you to complete tasks once you start them and we don’t normally pay any incentives for incomplete tasks.",
    "privacypolicyext_topic9ansp3": "We expect you to, at all times, provide true, accurate, current, and complete information as requested.",


    "privacypolicyext_topic10ansp1": "We will only use your personal data when the law allows us to.Most commonly, we will use your personal data in the following circumstances, but for more information about the types of lawful basis that we will rely on to process your data, please click here.:",
    "privacypolicyext_topic10ansp1_l1": "Provide our products and services to you",
    "privacypolicyext_topic10ansp1_l2": "find the people who are most suitable for each research opportunity;",
    "privacypolicyext_topic10ansp1_l3": "reduce the number of questions we need to ask in surveys;",
    "privacypolicyext_topic10ansp1_l4": "conduct further analysis on research data we have already collected;",
    "privacypolicyext_topic10ansp1_l5": "in aggregated form to help us explain our research capabilities to our clients.",
    "privacypolicyext_topic10ansp1_l6": "invite you to research opportunities such as surveys, focus groups, online communities, passive monitoring of the websites you visit, etc.",
    "privacypolicyext_topic10ansp1_l7": "send you incentive rewards that you have earned,",
    "privacypolicyext_topic10ansp1_l8": "request you to update your profile,",
    "privacypolicyext_topic10ansp1_l9": "Invite you to participate in surveys about our products or services",
    "privacypolicyext_topic10ansp1_l10": "Monitor, detect and prevent fraud, improper activity and ensure WiseWorks's policy compliance",
    "privacypolicyext_topic10ansp1_l11": "Conduct research including secondary and follow - on research",
    "privacypolicyext_topic10ansp1_l12": "Evaluate how you and other users interact with our online services",
    "privacypolicyext_topic10ansp1_l13": "Protect WiseWorks and our users",
    "privacypolicyext_topic10ansp1_l14": "Conduct audits related to our current interactions with you",
    "privacypolicyext_topic10ansp1_l15": "Debug the site and service to identify and repair errors that impair existing intended functionality",
    "privacypolicyext_topic10ansp1_l16": "Defend ourselves in litigation and investigations and prosecute litigations",
    "privacypolicyext_topic10ansp1_l17": "To comply with legal and regulatory obligations.",


    "privacypolicyext_topic11ansp1": "WiseWorks only shares your personal information with third parties under the circumstances described below.We do not sell or otherwise disclose personal information we collect about you for monetary or other valuable consideration.",
    "privacypolicyext_topic11ansp1_l1title": "Authorized vendors:",
    "privacypolicyext_topic11ansp1_l1": "We may share your data with third - party vendors that help us operate our website, process payments, and deliver our services and also comply with your instructions and our contractual obligations.These include payment processors, content delivery networks(CDNs), cloud - based hosting services, monitoring services, email service providers, quality assurance and testing vendors, fraud and abuse prevention vendors, customer relations management(CRM), vendors",
    "privacypolicyext_topic11ansp1_l2title": "Advertising and Analytics Partner:",
    "privacypolicyext_topic11ansp1_l2": "We may share usage data with third - party advertisers and analytics providers through cookies and other similar technologies.These third parties may collect information sent by your computer, browser, or mobile device in response to a request for content, such as unique identifiers, your IP address, or other information about your computer or device.",
    "privacypolicyext_topic11ansp1_l3title": "Business Partners:",
    "privacypolicyext_topic11ansp1_l3": "We sometimes partner with third parties to offer you events, promotions, contests, and other programs.To participate in these offers, you may be required to provide your email address and additional personal information.We will only share your email address and other personal information with a business partner when you expressly authorize us to do so.",
    "privacypolicyext_topic11ansp1_l4title": "Certain legal obligations: ",
    "privacypolicyext_topic11ansp1_l4": "WiseWorks may be required to disclose personal information if directed by a court of law or other governmental entities.Without limiting the preceding, we reserve the right to disclose such information where we have a good faith basis to believe that such action is necessary to: comply with applicable laws, regulations, court orders, government, and law enforcement agencies' requests, protect and defend WiseWorks's or a third party's rights and property, or the safety of WiseWorks, our users, our employees, or others, prevent, detect, investigate and take measures against criminal activity, fraud, and misuse or unauthorized use of our Website and to enforce our Terms of Use or other agreements or policies, to the extent permitted by law, we will attempt to give you prior notice before disclosing your information in response to such a request.",
    "privacypolicyext_topic11ansp1_l5title": "With Your Consent:",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks may send your Personal Information to a third party when you consent to do so or in your direction.For example, this may occur when we complete a transaction at your request.",
    "privacypolicyext_topic11ansp1_l6title": "With Service Providers:",
    "privacypolicyext_topic11ansp1_l6": "We may share personal information with vendors, consultants, payment processors, and other service providers that provide us with specific services and unique process information on our behalf.These services may include providing customer support, performing business and sales analysis, supporting our website functionality, facilitating payment processing, supporting contests, surveys, and other features offered on our Website.These service providers are not permitted to use your personal information for any other purpose.Their use of such personal data will be subject to appropriate confidentiality and security measures.",
    "privacypolicyext_topic11ansp1_l7title": "With Affiliates:",
    "privacypolicyext_topic11ansp1_l7": "We may share personal information with some of our partners, affiliates, and other companies under common control and ownership, subject to the terms of this Privacy Policy.We may also share your personal information in connection with a substantial corporate transaction, such as a sale of our business, a divestiture, merger, consolidation, or asset sale, in the event of bankruptcy, or preparation for any of these events.Any other entity which buys us or becomes part of our business will have the right to continue to use your personal information, but only in the manner set out in this Privacy Policy unless you agree otherwise.",

    "privacypolicyext_topic12ansp1_title": "Legal Bases for Processing your Information:",
    "privacypolicyext_topic12ansp1": "For individuals who are located in the European Economic Area, United Kingdom, or Switzerland (collectively “EEA Residents”), data collected from you may be transferred or processed in the United States (collectively “Data Subjects”). When their data is collected, we rely on legal bases for processing your information. We generally only process your data where we are required to, where the processing is necessary to provide our services to you (or to take steps at your request before entering into a contract with you). ",
    "privacypolicyext_topic12ansp2_title": "Shine the Light (For California Citizens) CCPA",
    "privacypolicyext_topic12ansp2_1": "California's \"Shine the Light\" law permits customers in California to request specific details about how certain types of their information are shared with third parties and, in some cases, affiliates for those third parties' and affiliates' direct marketing purposes. Under the law, a business should either provide California customers certain information upon request or permit California customers to opt-out of this type of sharing.",
    "privacypolicyext_topic12ansp2_2": 'To exercise a Shine the Light request, please contact us.You must put the statement "Your California Privacy Rights" in the body of your request, as well as your name, street address, city, state, and zip code.Please provide enough information to determine if this applies to you in the body of your request.Please note that we will not accept inquiries via telephone, email, and we are not responsible for notices that are not labeled or sent properly or that do not have complete information.',
    "privacypolicyext_topic12ansp3_title": "Your Nevada Privacy Rights",
    "privacypolicyext_topic12ansp3": 'If you are a Nevada resident, you have the right to opt- out of the sale of specific Personal Data to third parties who intend to license or sell that Personal Data.You can exercise this right by contacting us here or email us with the subject line "Nevada Do Not Sell Request" and providing us with your name and the email address associated with your account.',

    "privacypolicyext_topic13ansp1": "We enable you to make numerous choices about your data: Below is the following data protection rights you have when you use our services.",
    "privacypolicyext_topic13ansp1_l1": "You may choose not to provide us with certain information.",
    "privacypolicyext_topic13ansp1_l2": "Suppose you wish to access, correct, update or request deletion of your personal information.In that case, you can do so at any time by contacting us through the customer services portal or at the information provided at the bottom of this Privacy Policy.",
    "privacypolicyext_topic13ansp1_l3": "If you request access to your data, we will confirm whether we hold your information and provide you with a copy, explaining how we process it and why, how long we store it for, and your rights associated with it.",
    "privacypolicyext_topic13ansp1_l4": "If you request the deletion of your personal information, we will erase it.Please note that we will need to retain any information that we require to fulfill our legal obligations or establish, exercise, or defend a legal claim.",
    "privacypolicyext_topic13ansp1_l5": "You can object to your personal information processing, ask us to restrict the processing of your personal information, or request portability of your personal information.Again, you can exercise these rights by contacting us through the customer services portal or at the information provided at the bottom of this Privacy Policy.",
    "privacypolicyext_topic13ansp1_l6": 'You have the right to opt - out of the email marketing communications we send you at any time.You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails we send you.',
    "privacypolicyext_topic13ansp1_l7": "Opting Out Of Messages from Us: To opt - out of any future promotional messages from us, you should unsubscribe in the body of the promotional message sent to you(found at the bottom of the email) or send an unsubscribe request to us.We will process your request within a reasonable time after receipt.",
    "privacypolicyext_topic13ansp1_l8": "Similarly, if we have collected and processed your personal information with your consent, then you can withdraw your consent at any time.Withdrawing your consent will not affect the lawfulness of any processing we conducted before your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.",
    "privacypolicyext_topic13ansp1_l9": "You have the right to complain to a data protection authority about our collection and use of your personal information.Please contact us for more information, and we will send you a link or our data protection officer's address.",
    "privacypolicyext_topic13ansp1_l10": "The easiest way to submit your request to us is through our Customer support email found at the bottom of this Privacy Policy.We respond to all requests we receive from individuals wishing to exercise their data protection rights under applicable data protection laws.",

    "privacypolicyext_topic14ansp1": "The website and services are not directed to children under thirteen(18) years of age.WiseWorks complies with the Children's Online Privacy Protection (COPPA) Act. We do not knowingly request to collect personal information from any person under the age of 18.  Please notify us if you know of any individuals under the age of 18 using our website to prevent them access to our Services.",

    "privacypolicyext_topic15ansp1": "We are committed to maintaining your data's security, having measures in place to protect against the loss, misuse, and alteration of the data under our control. We employ modern and secure techniques to protect our systems from intrusion by unauthorized individuals, and we regularly upgrade our security as better methods become available. Our data centers and our partners utilize new physical security measures to prevent unauthorized access to the facility. ",
    "privacypolicyext_topic15ansp2": "Please be aware that no security measures are perfect or impenetrable.Therefore, although we use industry- standard practices to protect your privacy, we cannot (and do not) guarantee the absolute security of personal data.",


    "privacypolicyext_topic16ansp1": "To learn more about our terms and conditions, please visit our Terms of Service page, which establishes the use, disclaimer, and limitations of liability governing our website's usage.",

    "privacypolicyext_topic17ansp1": "The United States of America laws govern this privacy policy and terms of service and other related services.",

    "privacypolicyext_topic18ansp1": "WiseWorks continually strives to implement new technologies and processes to protect you better, your privacy, and your use of the WiseWorks service.As a result, changes to this privacy policy may be made by WiseWorks from time to time.In no event will these changes result in the degradation of any security measures designed to protect you from unauthorized disclosure.",

    "privacypolicyext_topic19ansp1": "If you have any questions about this privacy policy, please contact us at ",




  "faq_faq" : "Frequently Asked Questions",
  "faq_getans" : "Get answers to all your questions.",

  "faq_ques1" : "What is WisePerks?",
  "faq_ans1" : "We are glad you asked. WisePerks is an online survey site. A lot of brands and companies are looking for respondents to provide their opinions and receive rewards in return. WisePerks is one such platform where you can give your opinion and earn rewards for it too.",

  "faq_ques2" : "Is it free to join?",
  "faq_ans2" : "Yes, it is free to join. In fact, if someone is asking you a joining fee, we would want you to report it to us.",

  "faq_ques3" : "Can I refer my friends?",
  "faq_ans3" : 'Yes, you can invite your friends and colleagues to join and also get rewarded for it. Please click "Refer Friend" link on your dashboard to get all the details.',

  "faq_ques4" : "What do I earn?", 
  "faq_ans4" : "Now in our new model, you can earn points in many ways other than just by taking the surveys. You get points by: Registering with us, By verifying your email account, For completing your profile, of course for completing any survey and to your surprise even for a disqualified survey. So we have all the reasons to get you started isn't it?",

  "faq_ques5" : "How do I become a WisePerks.com member?",
  "faq_ans5" : 'Open WisePerks.com on your browser. Create a membership login by clicking the "Sign Up" tab. Simply follow the steps and it will guide you through the rest of the process. You will have to confirm your intent of joining by clicking on the confirmation e-mail sent by us to your email provided.',

  "faq_ques6" : "Where is that confirmation e-mail you mentioned?",
  "faq_ans6p1" : "A confirmation e-mail is automatically sent to the email address you provide when signing up. If you don't see that email in your inbox, please check your spam or junk folder. To avoid any future emails from us and survey invites/messages from reaching your spam folder, please do add",
  "faq_ans6p2" : "to your list of your approved senders or as a contact in your mailbox. If you still can't find that confirmation e-mail, please login to My Account, confirm that your email address with us is correct, and request another confirmation email.",

  "faq_ques7" : "How do I take a survey?",  
  "faq_ans7" : 'Once you are logged in after entering your UserName and Password, you reach your Dashboard and can see all the surveys you can take. We also send the survey links to your registered email ids. You can take the link directly from your mailbox.',

  "faq_ques8" : "How confidential are my responses, my data?",
  "faq_ans8" : "For WisePerks, maintaining user privacy and confidentiality are of utmost priority. Our clients are only interested in what you think, as a consumer, or how you feel about something. We at WisePerks, never share/pass on any personal information to our clients, and none of the surveys WisePerks also does not sell or rent any information to third parties.",

  "faq_ques9" : "How long are these survey anyways?",
  "faq_ans9" : "Most surveys take only about 10 to 15 minutes, or even lesser. However, from time to time, we may have longer surveys. With each survey, we tell you in advance how long it would be, but do keep in mind, longer surveys have higher rewards as well.",

  "faq_ques10" : "Can anyone take WisePerks surveys?",
  "faq_ans10" : "You must be 13 years or older to register with WisePerks. Though sometimes we will invite you in surveys to participate with younger members of your family.",

  "faq_ques11" : "How do you know I will qualify for a survey?",
  "faq_ans11" : "Our algorithm matches your profile information with the surveys available to ensure you get the most appropriate surveys. When a survey begins, usually the first few questions determine whether you are the respondent our client is looking for that particular survey. We have a vast range of surveys looking for many different types of people (e.g., people who watch movies regularly, play a particular sport, women with kids, etc.) and new surveys come up every day!",

  "faq_ques12" : "What are my obligations to WisePerks?",
  "faq_ans12" : "The only thing we expect from you is to provide your opinions and thoughts honestly, as clients are looking for your honest and true answers to improve upon their products and services. Please provide correct information when signing up as a member and do update any changes as they occur.",

  "faq_ques13" : "Why should I provide information on my household, technology or products I buy?",
  "faq_ans13" : "For each survey, we are looking for a certain profile of respondents. If we know from your profile information that your information matches to the client requirements, bingo! We can almost certainly assure that you will be able to complete the survey successfully and earn the reward for it. This basically increases your chances of being selected for a survey.",

  "faq_ques14" : "When will I receive my Gift Cards after completing a WisePerks survey?",  
  "faq_ans14" : "The points you earn after successfully completing a survey are accumulated in your bucket. Once these points are realised you can redeem.",

  "faq_ques15" : "What is My Account really?",
  "faq_ans15" : "It's just an easy way for you to see your personal information, make any changes as required, see your survey activity, rewards earned, everything in one place.",

  "faq_ques16" : "How do I cancel my account?", 
  "faq_ans16" : "With so much coming your way, we are sure you would not want to do this. However, on your Dashboard-->Go to Settings-->Manage Accounts-->Deactivate Account",

  "faq_ques17" : "How does the points system work?",
  "faq_ans17p1" : "Each survey is for certain number of points. After successfully completing a survey these points are added to your earnings. You can redeem them once you reach the minimum threshold.",
  "faq_ans17p2" : "In few cases due to some unforeseen challenges with the connection or break downs, your response might not get captured at our client’s end. We then have to reverse those points from your account. If you have already redeemed those points and your account has low balance then your earnings will be in negative. These adjustments will be made in your next redeem cycle.",

  "faq_ques18" : "How soon do the points for a survey get added to my points balance?",
  "faq_ans18" : "Your points shall be added to your points balance in 48 hours.",

  "faq_ques19" : "What is the minimum threshold for redemption?",
  "faq_ans19" : "The minimum threshold for redemption is 25000 points. On an average, you would need to complete 8-12 surveys to be eligible for redemption.",

  "faq_ques20" : "How do points get converted to Gift Card cash? Is there a ratio?",
  "faq_ans20" : "Yes. Depending on your country, points get converted to Gift Card cash. The conversion ratios are as shown below",
  "faq_ponits" : "Points",
  "faq_point" : "Point",
  "faq_cent" : "Cent",

  "faq_ques21" : "Still have more questions?",
  "faq_ans21p1" : "Feel free to email us at ",
  "faq_ans21p2" : "and we will get back to you within 48 hours.",

  "Profiling_profiledashboard" : "Profile Dashboard", 
  "Profiling_completeprofile" : "Complete your profile and get whopping",
  "Profiling_completepoints" : "points!",
  "Profiling_answercarefully" : "The following general set of questions will be used to create your personal profile in order to provide you with the best future survey opportunities. Please take your time, answer carefully and truthfully.",

  "Profiling_cat_general" : "General",
  "Profiling_cat_personal" : "Personal",
  "Profiling_cat_household" : "Household",
  "Profiling_cat_profession" : "Profession",

  "Profiling_bar_completed" : "% Completed",
  
  "Profiling_update_ans_button" : "Update & Continue",
  "Profiling_save_ans_button" : "Save",
  "Profiling_sc_ans_button" : "Save & Continue",

  "Category_thanku_msg1" : "Good going",
  "Category_thanku_msg2" : "few more and you'll have",
  "Category_thanku_msg3" : "points in your account.",

  "Category_thanku_update_msg1" : "Thanks for updating your information!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "Continue",

  "OverAll_thanku_msg1" : "Thanks for sharing your information. You have earned",
  "OverAll_thanku_msg2" : "points.",

  "Survey_history_surveyhistory" : "Survey History",
  "Survey_history_fromthisscreen" : "From this screen, you can see all your transactions.",
  "Survey_history_show" : "Show",
  "Survey_history_entries" : "enteries",
  "Survey_history_date" : "Date",
  "Survey_history_details" : "Details",
  "Survey_history_surveys" : "Surveys",
  "Survey_history_pointsreceived" : "Points Received",
  "Survey_history_result" : "Result",
  "Survey_history_status" : "Status",
  "Survey_history_status_complete" : "Complete", 
  "Survey_history_status_disqualified" : "Disqualified", 
  "Survey_history_keyword" : "Keyword",
  "Survey_history_search" : "Search",
  "Survey_history_to" : "to",
  "Survey_history_selectoption" : "Select Option",
  "Survey_history_earned" : "Earned",
  "Survey_history_redeemed" : "Redeemed",
  "Survey_history_norecord" : "No Record Found",
  "Survey_history_Surveypts" : "Survey Points",
  "Survey_history_Promocode" : "Promo Code",

  "My_transactions_mytransactions" : "My Transactions",
  "My_transactions_fromthisscreen" : "From this screen, you can see all your transactions.",
  "My_transactions_show" : "Show",
  "My_transactions_entries" : "enteries",
  "My_transactions_transactionid" : "Transaction Id",
  "My_transactions_date" : "Date",
  "My_transactions_details" : "Details",
  "My_transactions_points" : "Points",
  "My_transactions_status" : "Status",
  "My_transactions_keyword" : "Keyword",
  "My_transactions_search" : "Search",
  "My_transactions_to" : "to",
  "My_transactions_selectoption" : "Select Option",
  "My_transactions_status_earned" : "Earned",
  "My_transactions_status_redeemed" : "Redeemed",
  "My_transactions_status_pending" : "Pending",
  "My_transactions_status_reverted" : "Reverted",
  "My_transactions_status_declined" : "Declined",
  "My_transactions_norecord" : "No Record Found",
  "My_transactions_off_msg1" : "After ",
  "My_transactions_off_msg2" : ", all your transaction details will be published on Points book tab",

  "Passbook_heading" : "Points Book",
  "Passbook_show" : "Show",
  "Passbook_entries" : "enteries",
  "Passbook_search" : "Search",
  "Passbook_type" : "Type",
  "Passbook_surveyid" : "Surveys",
  "Passbook_date" : "Date",
  "Passbook_selectoption" : "Select Option",
  "Passbook_credit" : "Credit",
  "Passbook_debit" : "Debit",
  "Passbook_survey_id" : "Survey Id",
  "Passbook_survey_pts" : "Survey Points",
  "Passbook_earned_pts" : "Points",
  "Passbook_pending_pts" : "Pending Points",
  "Passbook_promo_code" : "Promo Code",
  "Passbook_transaction_type" : "Transaction type",
  "Passbook_balance" : "Balance",
  "Passbook_status" : "Status",
  "Passbook_type_soi" : "Registration",
  "Passbook_type_doi" : "Email Verification",
  "Passbook_type_profile" : "Profile Completion",
  "Passbook_type_survey" : "Survey",
  "Passbook_type_doir" : "Email Verification By",
  "Passbook_type_doirt" : "Email Verification By Referral",
  "Passbook_type_surveyr" : "Survey Attempt By",
  "Passbook_type_surveyrt" : "Survey Attempt By Referral",
  "Passbook_type_reward" : "Reward",
  "Passbook_type_pending" : "Adjustment",
  "Passbook_type_reverted" : "Points Reverted",
  "Passbook_type_declined" : "Requests Declined",
  "Passbook_type_redeem" : "Redeem",
  "Passbook_type_balance" : "Carry Forward",
  "Passbook_balance_vary" : "Actual balance may vary",
  "Passbook_survey_debit_reason" : "We value your efforts. However due to some uncontrollable connection drops while data transfer your response could not get registered. We would have to reverse the points you received earlier for this survey. Apologies for the inconvenience caused.",

  "Redeem_redeemed" : "Redeem",
  "Redeem_redeemhistory" : "Redeem History",
  "Redeem_history_norecord" : "No Record Found",
  "Redeem_date" : "Date",
  "Redeem_details" : "Details",
  "Redeem_points" : "Points",
  "Redeem_status" : "Status",
  "Redeem_lookslike" : "Looks like you missed verifying your email account.",
  "Redeem_hurry" : "Hurry! you get 1000 points once you verify.",
  "Redeem_pleasecheck" : "Please check your inbox and click the link in the confirmation email sent to activate your account.",
  "Redeem_kindlycheck" : "Kindly check your Spam/Junk folder as well for the confirmation email.",
  "Redeem_getconfirmationemail" : "Didn't get confirmation email yet?",
  "Redeem_resendconfirmationemail" : "Resend confirmation email",
  "Redeem_rewardredemptionrule" : "Reward Redemption Rule",
  "Redeem_completesurvey" : "Complete Survey",
  "Redeem_earnpoints" : "Earn Points",
  "Redeem_redeemrewards" : "Redeem Rewards",
  "Redeem_note" : "Note:",
  "Redeem_yourearnedpoints" : "Your earned points will become redeemable after qualifying the survey. To redeem the reward, the minimum redeemable points in your account must be",
  "Redeem__points" : "points.",
  "Redeem_availablebalance" : "Available Balance :",
  "Redeem_historydetails" : "Click here to view your redemption history details.",
  "Redeem_reqsucc" : "Congratulations! Your points have been redeemed. Please check your registered mailbox for further details.",
  "Redeem_wisecheck_youhave" : "Congratulations! You are just one click away from your reward.",
  "Redeem_wisecheck_gcagainst" : "Gift Card for",
  "Redeem_wisecheck_points" : "points.",
  "Redeem_wisecheck_issue" : "System has captured some unrecognized activity in your account, due to which you are unable to send a redeem request. Please contact at",
  "Redeem_emailverify_issue" : "To redeem your points, we would request you to verify your registered email account.",

  "Redeem_unsubscribe_issue" : "You are unable to send a redeem request, as you have unsubscribed from WisePerks. Please contact support@wiseperks.com to subscribe again.",
  "Redeem_wisecheck_message1" : "You are not able to redeem due to some security reasons. Please contact to support on",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Confirm",
  "Redeem_Cancel_button" : "Cancel",
  "Redeem_pending_hello" : "Hello",
  "Redeem_pending" : "Adjustment",
  "Redeem_pending_msg1" : "Your account shows adjustment of",
  "Redeem_pending_msg2" : "points to be carried out. Please confirm to initiate the transaction.",

  "Dashboard_wisecheck_issue" : "System has captured some unrecognized activity in your account, due to which you are unable to see the surveys. Please contact support@wiseperks.com for further clarification.",
  "Dashboard_unsubscribe_issue" : "You are unable to view any surveys, as you have unsubscribed from WisePerks. Please contact support@wiseperks.com to receive surveys again.",


  "WC_DashboardMsg_1" : "Oops! No surveys to match your profile as of now.",
  "WC_DashboardSubMsg_1" : "Don't worry, we are shuffling our inventory and will soon send new surveys your way.",

  "WC_DashboardMsg_2" : "We are refreshing your Dashboard. Please check for new surveys after sometime.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Thanks for completing the survey. You just earned response_points points. Login to your Dashboard to see your total accumulated points.",
  "Survey_success_msg2" : "Thanks for completing the survey. You just earned response_points points.",

  "Survey_success_but_nomore_survey" : "Congratulation! You just earned response_points points for completing the survey. Please check for upcoming surveys after sometime.",
  
  "Survey_terminate_pnt_msg1" : "Looks like some of your information is slightly different from the requirement. We value your time and efforts, will be adding response_points points to your account. We are focused on bringing you more lucrative and intuitive surveys.",
  
  "Survey_terminate_nopnt_msg1" : "Looks like some of your responses are slightly different from the requirement. We'll be sending more surveys to your way. Thanks for your time and effort.",

  "TermsConditions_t&c" : "Terms & Conditions",
  "TermsConditions_WhenRegister" : "When you register",
  "TermsConditions_WhenYouCreate" : "When you create a personal account, we ask you some personal information, as mentioned here:",
  "TermsConditions_YouCanUpdate" : "You can update or change your Basic profile information at any time.",
  "TermsConditions_YouCanOptOut" : "You can opt-out or close your account at any time.",
  "TermsConditions_WeRespectYour" : "We respect your privacy and make best use of technology to safe guard your information from unauthorized physical and electronic access.",
  "TermsConditions_WeShareYour" : "We share your information only with organizations, which are required to operate WiseWorks. And those organizations need to take care of our commitments to your privacy and security.",
  "TermsConditions_WhatInformation" : "What information we collect from you",
  "TermsConditions_DirectlyCollected" : "Directly collected",
  "TermsConditions_EmailAddressAndPassword" : "Email address and password of your account with us.",
  "TermsConditions_YourResponse" : "Your response to profiling questions.",
  "TermsConditions_AnyCustomer" : "Any customer service correspondence.",
  "TermsConditions_AnyPersonalDetails" : "Any personal details you share for redemption",
  "TermsConditions_AnyOtherInformation" : "Any other information directly provided by you.",
  "TermsConditions_UsageData" : "Usage data",
  "TermsConditions_YourIPAddress" : "Your IP address, geo location and cookies",
  "TermsConditions_InformationAboutTheDevice" : "Information about the device you use",
  "TermsConditions_YourSurveyActivity" : "Your survey activity, points in your account, the payments you receive after redeeming.",
  "TermsConditions_HowDoWeUse" : "How do we use your data",
  "TermsConditions_SendingYouInvitation" : "Sending you invitation emails to take up the survey you qualify for.",
  "TermsConditions_DetectAndPrevent" : "Detect and prevent fraudulent activity.",
  "TermsConditions_ImproveYourExperience" : "Improve your experience on WiseWorks platform",
  "TermsConditions_YourResponseAreTransferred" : "Your responses are transferred to our affiliated third parties and partners in an aggregated and de-identified format.",
  "TermsConditions_OurPartnersCanAsk" : "Our partners can ask you to provide your name, email address and other contact information during a survey.",
  "TermsConditions_YouAreFreeToDecline" : "You are free to decline providing your details to our partners, if you agree than you are providing this information directly to them and not to WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Your information will be dealt with in accordance with the relevant business Privacy Policy and not WiseWorks’s Privacy Policy.",
  "TermsConditions_OurPartnersAreCommited" : "Our partners are committed to respect and protect your privacy.",
  "TermsConditions_WhatWeDont" : "What we don’t do with it",
  "TermsConditions_WeDontSellOnThat" : "We don’t sell on that information to anyone, and we don’t use it to sell anything to you. We will always ask permission before sharing your data.",
  "TermsConditions_YourRightToControl" : "Your right to control your data",
  "TermsConditions_YouHaveFullControl" : "You have full control of your data.",
  "TermsConditions_YouCanAccess" : "You can access, update or change your basic profile information whenever you want.",
  "TermsConditions_YouCanUnsubscribe" : "You can unsubscribe and deactivate your account.",
  "TermsConditions_YouAreFreeToGetYour" : "You are free to get your profile deleted by sending us a mail at support@wiseperks.com. We would try to resolve your concern and if you still wish to get your account deleted, then shall be doing it within 72 hours.",
  "TermsConditions_DataSecurity" : "Data Security",
  "TermsConditions_OurTechTeamWorks" : "Our tech team works really hard to keep all your data secure.",
  "TermsConditions_WeMakeUseOfLatest" : "We make use of latest technology and measures to avoid any misuse or unauthorized access of your data. However, due to the nature of the online environment we cannot give 100% assurance of data security.",
  "TermsConditions_WeTryOurBestToRule" : "We try our best to rule out the risks, however cannot guarantee risk free environment. By accepting these Terms and Condition, you acknowledge and accept these risks.",
  "TermsConditions_AnyQuestions" : "Any questions, please feel free to contact us",
  "TermsConditions_IfYouHaveAnyQueries" : "If you have any queries or complaints about our privacy policy and procedures. Please send us an email at support@wiseperks.com and we shall get back to you within 48 hours.",

  "Validation_PleaseEnterProperDate" : "Please Enter proper date of birth",
  "Validation_PleaseSelectOne" : "Please Select one option!",
  "Validation_PleaseSelectValidOptions" : "Please Select Valid Options!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Please Select at least One Option",
  "Validation_PleaseInsertTheZip" : "Please Insert the Zip/Postal Code.",

  "Releases_releases" : "Releases",
  "Releases_theUseOfThis" : "The use of this website is subject to the following terms of use.",
  "Releases_featuresOfWP" : "Features of WP release",
  "Releases_updatedOn" : "updated on",
  "Releases_WisePerksNow" : "WisePerks now supports multiple languages",
  "Releases_youCanWalkThourgh" : "You can walk thourgh the site with much more ease now as WisePerks is available in your preffered language.",
  "Releases_languagesAvailable" : "Languages Available:",
  "Releases_EarnMore" : "Earn more with our new point system",
  "Releases_theNewWisePerksAccumulates" : "The new WisePerks accumulates points into your account. These points hold a much higher value and are easy to earn and redeem. With multiple redeem options to select from, we are sure you'll enjoy the survey taking experience.",
  "Releases_accumulatePoints" : "Accumulate points with hassel free redeemption",
  "Releases_youGetTheBenefit" : "You get the benefit of earning the same no. of points, irrespective of the currency you want to redeem them in.",
  "Releases_surveyDisqualification" : "Survey disqualification is no more a bad news",
  "Releases_youEarnPoints" : "You earn points even when you do not qualify for a survey, you started answering.",
  "Releases_newAndImproved" : "New and Improved layout",
  "Releases_theFreshLookOf" : "The fresh look of WisePerks will make you fall in love with it. It is now much easier to use and would be a great experience for you.",
  "Releases_whatAvatarAreYouToday" : "What Avatar are you today",
  "Releases_youCanNowCreate" : "You can now create your own avatar to feature on your dashboard.",
  "Releases_freshAndHotSurveys" : "Fresh and Hot surveys on your dashboard",
  "Releases_youDontHaveTo" : "You don't have to worry about refresing your dashboard to view new surveys, we'll keep your dashboard populated with latest surveys automatically.",
  "Releases_promoCode" : "Promo Code feature",
  "Releases_promoCodeInfo" : "On popular demand, WisePerks brings Promo Codes for its panelists every week. Quick way to grab more rewards.",
  "Releases_referFriend" : "Refer Friend",
  "Releases_referFriendInfo" : "Get your friends associated with WisePerks and grab exciting rewards. Look out for Refer Friend option in your account.",

  "Dashboard_Promocode_Usepromo" : "Use Promo Code",
  "Dashboard_Promocode_to" : "to",
  "Dashboard_Promocode_earnminimum_1" : "earn minimum",
  "Dashboard_Promocode_earnminimum_2" : "extra points",
  "Dashboard_Promocode_minimum" : "minimum",
  "Dashboard_Promocode_minimumextrapoints" : "extra points on every survey completed today",
  "Dashboard_Promocode_apply" : "Apply",
  "Dashboard_Promocode_message1" : "Promo Code not valid!",
  "Dashboard_Promocode_message2" : "Congratulations! You will get minimum",
  "Dashboard_Promocode_message3" : "extra on each survey completed today!",

  "Dashboard_modal_Enjoythefestive" : "Enjoy the festive spirit by earning minimum",
  "Dashboard_modal_Extrapointson" : "extra points on every survey you complete today.",

  "refer_friend_title" : "Refer a Friend and Get Rewarded",
  "refer_friend_step_first": "Invite a friend to WisePerks",
  "refer_friend_step_second": "Friend successfully signs up on WisePerks",
  "refer_friend_step_third": "You get each_referral points",
  "refer_friend_link_title": "Invite a friend now!",
  "refer_friend_share_title": "Share the link",
  "refer_friend_guideline_title": 'How "REFER A FRIEND" will benefit you:',
  "refer_friend_guideline_one": "Please share the link provided above with your referrals.",
  "refer_friend_guideline_two": "You can refer as many people as you want, once 5 of your referrals verify their account and start survey activity, your earned referral points will be added to your account.",
  "refer_friend_guideline_three": "You get each_referral points each for successful (with verified email account and survey activity) referral.",
  "refer_friend_guideline_four": "The points will be earned in two stages, partial_referral on email verification and partial_second_referral on survey activity by your referral.",
  "refer_friend_guideline_five": "After 5 successful (with verified email account and survey activity) referrals, the referral link will get disabled.",

}
  
