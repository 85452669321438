import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CommonJsFunc from '../../../../assets/js/common/common';
import { Title } from '@angular/platform-browser';
import { HomeService } from '../shared/home.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {
  panelistId:string;

  
  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private toastr: ToastrService,
    private _homeservice: HomeService,
    private title: Title) {
    
  }

  ngOnInit() { 
    this.title.setTitle('WisePerks | Unsubscribe');

    let getemail = this.activatedRoute.snapshot.queryParams.email;
    let getauth = this.activatedRoute.snapshot.queryParams.auth;

    if(getemail != null && getauth != null){

      let check_correct_email = CommonJsFunc.decode64(getemail);
      let check_correct_auth = CommonJsFunc.decode64(getauth);

      if(check_correct_email.trim() != "" && this.validateEmail(check_correct_email.trim()) && check_correct_auth != ""){
        var postData = []
        postData['email'] = check_correct_email;
        postData['auth'] = check_correct_auth;

        this._homeservice.panelistUnsubscribe(postData).subscribe((resp:any) => {
          if(resp.auth == true && resp.success == true){
            
            let responseMsg = resp.data;
            
            this._homeservice.PanelistGetDataViaEmail(check_correct_email).subscribe((response:any) => {
              if(response.auth == true && response.success == true){

                let system_exists_panelist = localStorage.getItem('currentLoginPanelist');
                let unsubscribe_panelistId = response.data[0]._id;

                if(system_exists_panelist != unsubscribe_panelistId){
                  localStorage.removeItem('currentLoginPanelist');
                  localStorage.setItem('currentLoginPanelist',response.data[0]._id);
                }

                let getStep = response.data[0].step;
                
                if(getStep == 0){
                  this.router.navigate(['/demographics']).then(()=>{
                    this.toastr.clear();
                    this.toastr.success(responseMsg, "WisePerks");
                    return false;
                  });
                  
                } else if(getStep > 0){
                  this.router.navigate(['/dashboard']).then(()=>{
                    this.toastr.clear();
                    this.toastr.success(responseMsg, "WisePerks");
                    return false;
                  });
                } else {
                  this.router.navigate(['']).then(()=>{
                    this.toastr.clear();
                    this.toastr.success(responseMsg, "WisePerks");
                    return false;
                  });
                  
                }

                /*if(system_exists_panelist != unsubscribe_panelistId){
                  localStorage.removeItem('currentLoginPanelist');
                  this.router.navigate(['']).then(() => {
                    this.toastr.clear();
                    this.toastr.success(responseMsg, "WisePerks");
                    return false;
                  });
                } else {
                  if(response.data[0].step >= 1){
                    this.router.navigate(['/dashboard']).then(() => {
                      this.toastr.clear();
                      this.toastr.success(responseMsg, "WisePerks");
                      return false;
                    });
                  } else if(response.data[0].step == 0) {
                    this.router.navigate(['/demographics']).then(() => {
                      this.toastr.clear();
                      this.toastr.success(responseMsg, "WisePerks");
                      return false;
                    });
                  } else {
                    this.router.navigate(['']).then(() => {
                      this.toastr.clear();
                      this.toastr.success(responseMsg, "WisePerks");
                      return false;
                    });
                  }
                }*/
              }
            },(error:any) => {
              this.router.navigate(['']).then(() => {
                this.toastr.clear();
                this.toastr.error(responseMsg,"Wiseperks");
              });
            });
          }
        }, (err:any) => {
          this.router.navigate(['']).then(() => {
            this.toastr.clear();
            this.showNewFlashError(err,'WisePerks');
            return false;
          });
          
        });
      }
      else {
        this.router.navigate(['']).then(()=>{
          this.toastr.clear();
          this.toastr.error("You have not a authenticate panelists","WisePerks");
          return false;
        });
        
      }
    } else {
      this.router.navigate(['']).then(() => {
        this.toastr.clear();
        this.toastr.error("You have not a authenticate panelists","WisePerks");
        return false;
      });
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}
