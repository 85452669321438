import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HomeService } from '../shared/home.service';
import * as myLangGlobal from '../../shared/globals';
import { TranslateService } from '../../shared/translate.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {

  login_button:boolean = true;
  signup_button:boolean = false;
  home_button:boolean = false;
  dashboard_button:boolean = false;
  language_flag:string;

  setPreferredLang:string = "";

  languages = Array<Language>();

  iconToShow:any;
  amountToShow:any;

  @Output() shareDataEvent = new EventEmitter();
  @Output() AmountAsciiEvent: EventEmitter<any> = new EventEmitter();
  @Output() changeLanguageEvent: EventEmitter<string> = new EventEmitter();
  @Output() PassLanguageId: EventEmitter<number> = new EventEmitter();
  @Output() HomeBannerImg: EventEmitter<any> = new EventEmitter();
  @Output() StopLoaderEvent: EventEmitter<number> = new EventEmitter();

  constructor(private _homeService:HomeService, private translateService: TranslateService,
     private router: Router, private toastr: ToastrService, private activatedRoute: ActivatedRoute) { 
    
    let currentUrl = this.router.url;
    let resetUrl = "";
    
    if(activatedRoute.snapshot.url[0]){
      resetUrl = activatedRoute.snapshot.url[0].path;
    }
    //console.log(resetUrl);
    //console.log(currentUrl);
    
    if(currentUrl == '/privacy-policy' || resetUrl == "reset_password" || resetUrl == "unsubscribe" || currentUrl == '/terms-and-conditions'){
      this.login_button = false;
      this.signup_button = false;
      this.home_button = true; 
    }

    if(currentUrl == '/faq' || currentUrl == '/releases'){
      this.login_button = false;
      this.signup_button = false;
      if( localStorage.getItem('currentLoginPanelist')!= null){
        this.dashboard_button = true;
      } else{
        this.home_button = true;
      } 
    }

    let currentPreferLang = localStorage.getItem('PreferredLanguage');
    if(currentPreferLang != null && myLangGlobal.LangArr.indexOf(currentPreferLang) !== -1){
      this.translateService.setLanguage(currentPreferLang);
    }
    this.getIp(); 
  }

  ngOnInit() {
  }

  /*getIp(){
    this._homeService.getUserIp().subscribe((response:any)=>{
      if(response.status == true){
        let get_user_ip  = response.data.ip;
        if(get_user_ip){
          this._homeService.getCountryFromIp(get_user_ip).subscribe((resp:any)=>{
            if(resp.status == true){
              let get_country_code = resp.data.country_code;
              if(get_country_code){
                this.language_flag = "assets/images/users/home/flags/"+get_country_code+".png";
                this._homeService.getlanguageFromCountryCode(get_country_code).subscribe((get_lang_resp:any)=>{
                  if(get_lang_resp.auth == true && get_lang_resp.success == true && get_lang_resp.count > 0){
                    this.languages.push(new Language(0,'Select Language','Sl'));
                    for(var i=0; i<get_lang_resp.count; i++){
                      var lang_code = get_lang_resp.data[i].lang_cod.toLowerCase( );
                      this.languages.push(new Language(get_lang_resp.data[i].lang_id, get_lang_resp.data[i].lang, lang_code));
                    }
                    this.StopLoader();
                  }else{
                    this.languages.push(new Language(1,'English','en'));
                    this.StopLoader();
                  }
                },
                (get_lang_err:HttpErrorResponse)=>{
                  //console.log("Get Language Time Error"); console.log(get_lang_err);
                  //let msg = "Get Language Time Error on ip address ="+get_user_ip;
                  this.languages.push(new Language(0,'Select Language','Sl'));
                  this.languages.push(new Language(1,'English','en')); // default language set
                  this.StopLoader();
                  //this.logService.saveLogFile(msg,'language');
                });
              }
            }
          },
          (err:HttpErrorResponse)=>{
            //console.log("Get Country Time Error"); console.log(err);
            this.languages.push(new Language(0,'Select Language','Sl'));
            this.languages.push(new Language(1,'English','en')); // default language set
            this.StopLoader();
          });
        }
      }
    },
    (error:HttpErrorResponse)=>{
      //console.log("Get IP Time Error!"); console.log(error);
      this.languages.push(new Language(0,'Select Language','Sl'));
      this.languages.push(new Language(1,'English','en')); //default language set.
      this.StopLoader();
    });
  }*/

  getIp(){
    this._homeService.getUserLangDetails().subscribe((response:any) =>{
      if(response.status == 200){
        let resp = response.body;
        if(resp.auth == true && resp.success == true){
          this.iconToShow = resp.ascii;
          this.amountToShow = resp.amount;
          this.AmountAsciiEvent.emit({icon : this.iconToShow,amount : this.amountToShow});
          this.HomeBannerImg.emit({'home_banner_img':resp.banner_img});
          this.language_flag = "assets/images/users/home/flags/"+resp.country_code+".png";
          this.languages.push(new Language(0,'Select Language','Sl'));
          
          for(var i=0; i < resp.data.length; i++){
            var lang_code = resp.data[i].lang_cod.toLowerCase();
            this.languages.push(new Language(resp.data[i].lang_id, resp.data[i].lang, lang_code));
          }
          this.StopLoader();
        }
      } else if(response.status == 204){
        this._homeService.Set_notAvailable_country();
        this._homeService.SetNotAvailableCountryName(response.headers.get('Content-Country'));
        this.router.navigate(['/not-available']);
        this.StopLoader();
        return false;
      } 
    }, (err:any) => {
      this.languages.push(new Language(0,'Select Language','Sl'));
      this.languages.push(new Language(1,'English','en')); // default language set
      this.StopLoader();
    });
  }

  StopLoader(){
    this.StopLoaderEvent.emit(1);
  }

  changeform(show_form_name){
    if(show_form_name == 'login'){
      this.signup_button = true;
      this.login_button = false;
    } else {
      this.login_button = true;
      this.signup_button = false;
    }
    this.shareDataEvent.emit(show_form_name);
  }

  changeLanguage(lang_code,event){ 
    // Below Find the lang_id from event
    let selectIndex = event.target.selectedIndex;
    let getlanguageId = event.target[selectIndex]['attributes']['lang_id']['value'];
    this.PassLanguageId.emit(getlanguageId);
    
    this.translateService.setLanguage(lang_code);
    localStorage.setItem('PreferredLanguage',lang_code);
    //this.changeLanguageEvent.emit(lang_code);
  }

  FindPreferredLang(getLangCode,getLangId){
    let getPreferredLang = localStorage.getItem('PreferredLanguage');
    if(getPreferredLang != null){ 
      if(myLangGlobal.LangArr.indexOf(getPreferredLang) !== -1){
        if(getLangCode == getPreferredLang){
          this.PassLanguageId.emit(getLangId);
          return true;
        } else {
          return false;
        }
      } else {  // if not exists in language array then choose default eng lang
        if(getLangCode == 'en'){
          this.PassLanguageId.emit(1); // default for english
          return true;
        } else {
          return false;
        }
      }
    } else {
      if(getLangCode === 'en'){
        this.PassLanguageId.emit(1) // default for english
        return true;
      } else {
        return false;
      }
    }
  }

  showLoginButton(){
    this.login_button = true;
    this.signup_button = false;
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}


export class Language
{
    constructor(id:number, name:string, code:string) {
        this.id=id;
        this.name=name;
        this.code=code;
    }
    id:number;
    name:string;
    code: string;
}
