// Japan
export const TRANSLATIONS_JA = {
  "home_header_selectlang": "言語を選択する",
  "home_header_loginbutton": "ログイン",
  "home_header_signupbutton": "サインアップ",
  "home_header_homebutton": "ホーム", 
  
  "home_register_bannertxt1": "ここにあなた",
  "home_register_bannertxt2": "思考",
  "home_register_bannertxt3": "あなたをフェッチすることができます",
  "home_register_bannertxt4": "褒賞$",
  "home_register_bannertxt5": "ボーナスを得る",
  "home_register_bannertxt6": "SignUpで",
  "home_register_fbsignup": "FACEBOOKでサインアップ",
  "home_register_fblogin": "フェイスブックでログイン",
  "home_register_or": "または",
  "home_register_emailsignup": "電子メールでサインアップする",
  "home_register_emaillogin": "メールでログイン",
  "home_register_fpass": "パスワードをお忘れですか？",
  "home_register_fname": "ファーストネーム",
  "home_register_lname": "苗字",
  "home_register_email": "電子メールアドレス",
  "home_register_pass": "パスワード",
  "home_register_cpass": "パスワードを認証する",
  "home_register_tcpp": "参加することで、私たちに同意します",
  "home_register_tcpptandcond": "利用規約",
  "home_register_tcppand": "そして",
  "home_register_tcppprivacy": "個人情報保護方針",
  "home_register_join": "参加しませんか。それは無料です",
  "home_register_login": "ログイン",
  "home_register_name_required" : "名前は必須です。",
  "home_register_email_required" : "電子メールが必要です。",
  "home_register_invalid_email" : "無効なメール形式",
  "home_register_password_required" : "パスワードが必要。",
  "home_register_password_lower_digit" : "パスワードは6桁でなければなりません",
  "home_register_password_higher_digit" : "パスワードは15桁が必要です",
  "home_register_cpassword_required" : "パスワードの確認が必要です",
  "home_register_cpassword_lower_digit" : "パスワードの確認は6桁でなければなりません",
  "home_register_cpassword_higher_digit" : "パスワードの確認は15桁を使用する必要があります",
  "home_register_cpassword_not_match" : "パスワードが一致しないことを確認する",

  "home_register_fp_not_worry" : "登録したメールアドレスを入力してください。",
  "home_register_fp_resetpass_msg" : "パスワードをリセットするには、登録したメール id を入力してください。", 
  "home_register_fp_enter_email_msg" : "ここにあなたのメールアドレスを入力", 
  "home_register_fp_submit_button" : "送信",
  "home_register_fp_temporary_sent_pass_msg" : "パスワードのリセットリンクが登録済みのメール id に送信されました。早くパスワードを更新してください。", 
  
  "home_works_howitworks": "使い方？",
  "home_works_howitworkst1": "自己紹介をしてください", 
  "home_works_howitworksd1": "会員になる、サインアップする",
  "home_works_howitworkst2": "アンケートを受ける",
  "home_works_howitworksd2": "直感的で簡単なアンケートを開始",
  "home_works_howitworkst3": "ポイント獲得",
  "home_works_howitworksd3": "あなたのベスト・レスポンスを与え、ポイントを集める",
  "home_works_howitworkst4": "償還",
  "home_works_howitworksd4": "多くのエキサイティングな報酬のためにあなたのポイントを交換する",
  
  "home_aboutus_whoweare": "私たちは誰ですか？",
  "home_aboutus_whowearep1": "WisePerksでは、オンラインで過ごす時間が有益な経験であることを確認するために頑張っています。私たちはあなたのフィードバックを得たいと思っている数百の企業と仕事をしており、製品やサービスについてどのように感じているかを知っています。あなたは様々なサービスについて意見を述べていますが、WisePerksとは異なり、実際に報酬を受けるサービスはありません。",
  "home_aboutus_whowearep2": "私たちはあなたのフィードバックを大切にし、あなたの時間のために報酬を得たいと思っています。私たちは、あなたの興味に合った答えを見つけやすくするために、さまざまなトピックについての調査を行っています。さまざまな調査を毎月約50ドルから100ドルで簡単に行うことができます。それで、それを実現させるために一緒に働きましょう。",
  
  "home_earn_more_surabt": "どのような調査を求める?",
  "home_earn_more_bp": "ビジネスエキスパート",
  "home_earn_more_consumers": "消費 者",
  "home_earn_more_hc": "健康とフィットネス",
  "home_earn_more_entertainment": "娯楽 & 楽しい",
  "home_earn_more_gaming": "ゲームフリーク",
  "home_earn_more_tech": "ハイテクに精通",
  "home_earn_more_bonus_survey": "ボーナス調査", 
  
  "home_reward_whatuearn": "あなたは何を稼ぐ？",
  "home_reward_whatuearnp1": "私たちは物事を簡単に保つのが好きです。正常に完了したすべての調査のポイントを取得します。あなたが完了した調査が多いほど、獲得するポイントが増えます。ポイントは、一定の基準を超えた後、お好きなギフトカードに交換することができます。",
  "home_reward_whatuearnp2": "償還の準備が整ったら、Amazon、スターバックス、iTunesなどの好きなオプションを選んでください。私たちはまた、賞金を出し、時には金曜のオファーを神に感謝します。",
  
  "home_counter_earnedover": "メンバーは",
  
  "home_contact_getintouch": "連絡する",
  "home_contact_emailus": "ご不明な点がございましたら、",
  
  "home_footer_faq": "よくある質問",
  "home_footer_pp": "個人情報保護方針",
  "home_footer_tc": "T＆C",
  "home_footer_uf": "今後の機能", 
  "home_footer_copyright": "著作権©current_year WisePerks。全著作権所有。",

  "home_header_home": "ホーム", 
  "home_footer_toknowmore": "もっと知るには",
  "home_footer_createacc": "無料でアカウントを作成し、何千もの幸せなメンバーに加わってください。",
  "home_footer_registernow": "今すぐ登録",

  "home_reset_password" : "パスワードのリセット", 
  "home_reset_pass_content" : "パスワードのリセットを要求しました。", 

  "dashboard_sidebar_selectavatar" : "あなたのアバターを選択", 
  "dashboard_sidebar_uploadavatar" : "画像をアップロードするにはここをクリック", 
  "dashboard_sidebar_pbank" : "ポイント銀行",
  "dashboard_sidebar_accumulated" : "獲得", 
  "dashboard_sidebar_realized" : "実現", 
  "dashboard_sidebar_pending" : "調整", 
  "dashboard_sidebar_earned_tooltip" : "これまでに収集したポイント。",
  "dashboard_sidebar_pending_tooltip" : "アカウントから取り消す必要があるポイント。",
  "dashboard_sidebar_main" : "メイン",
  "dashboard_sidebar_dashboard" : "ダッシュボード",
  "dashboard_sidebar_profile" : "プロフィール",
  "dashboard_sidebar_updateprofile" : "プロファイルの更新",
  "dashboard_sidebar_shistory" : "調査履歴",
  "dashboard_sidebar_mtrans" : "私の取引",
  "dashboard_sidebar_passbook" : "ポイントブック",
  "dashboard_sidebar_redeem" : "償還",
  "dashboard_sidebar_refer_friend" : "友達に紹介する",
  "dashboard_sidebar_logout" : "ログアウト",

  "dashboard_sidebar_tutorial1" : "すべての利用可能なアンケートと、それらから得ることができるものを示しています。",
  "dashboard_sidebar_tutorial2" : "私たちはあなたをよりよく知っているとあなたの興味の調査を送信する私たちを助けるためにここにいくつかの質問に答えてください。いくつかの質問については、提出後の回答を更新することはできませんのでご注意ください。",
  "dashboard_sidebar_tutorial2update" : "あなたはここにあなたの応答を更新することができます, しかし、いくつかの質問は updation のために利用できません.",
  "dashboard_sidebar_tutorial3" : "あなたが試みたすべての調査をここに見つけなさい。",
  "dashboard_sidebar_tutorial4" : "WisePerks 上のすべてのトランザクションを追跡するのに役立ちます。",
  "dashboard_sidebar_tutorial5" : "あなたの実現ポイントを報酬に変換するいくつかの簡単な手順。",
  "dashboard_sidebar_tutorial6" : "アカウントに引き落とされた/入金されたポイントの取引履歴全体を確認します。",
  "dashboard_sidebar_gotit" : "手に入れました",

  "dashboard_sidebar_note" : "ご注意ください",
  "dashboard_sidebar_note1" : "すべての回答は正直に答えなければなりません。",
  "dashboard_sidebar_note2" : "オープンエンドレスポンスで具体的かつ正確に記述してください。",
  "dashboard_sidebar_note3" : "スピードを上げないでください！最小の調査完了時間が設定されます。",
  "dashboard_sidebar_note4" : "同じ質問が表示された場合は、一貫した回答を提供してください。",
  "dashboard_sidebar_note5" : "新しいアンケートが追加され、閉じたものがダッシュボードから自動的に削除されます。",
  "dashboard_sidebar_note6" : "紹介だけでいくつかの簡単なポイントを獲得できます。",

  "dashboard_setting_hi" : "こんにちは",
  "dashboard_setting_minfo" : "会員情報",
  "dashboard_setting_macc" : "アカウントの管理",
  "dashboard_setting_upass" : "パスワードの更新",
  "dashboard_setting_yourname" : "あなたのお名前は",
  "dashboard_setting_fname" : "ファーストネーム",
  "dashboard_setting_lname" : "苗字",
  "dashboard_setting_save" : "変更内容を保存",
  "dashboard_setting_bornon" : "あなたは生まれました",
  "dashboard_setting_youare" : "あなたは・・・だ",
  "dashboard_setting_postal" : "あなたの郵便番号は",
  "dashboard_setting_emailus" : "任意の支援やクエリについては、メールを送信してください",
  "dashboard_setting_membersince" : "あなたは今からメンバーになっています",
  "dashboard_setting_taken" : "あなたは撮影しています",
  "dashboard_setting_completed" : "調査と完了",
  "dashboard_setting_ofthem" : "そのうちの。",
  "dashboard_setting_earned" : "あなたは稼いだ",
  "dashboard_setting_pts" : "ポイント",
  "dashboard_setting_started" : "あなたが始まって以来。",
  "dashboard_register_email" : "あなたは私たちに登録されている",
  "dashboard_setting_preferences" : "好みを調整する",
  "dashboard_setting_emailoptout" : "電子メールオプトアウト",
  "dashboard_setting_futureemails" : "WisePerksからの今後のメールを受信しないようにする。",
  "dashboard_setting_futureemailsflash" : "私達の電子メールはあなたのために利用できるすべてのもうかる調査で掲示しておく。あなたはそれらを受信したくないことを確認しています。",
  "dashboard_setting_nrecemail" : "私達の電子メールはあなたのために利用できるすべてのもうかる調査で掲示しておく。彼らを止めたくないのか?",
  "dashboard_setting_emailout_modalmsg" : "あなたは WisePerks から将来の電子メールを受信するからオプトアウトしたいと確信しています?",
  "dashboard_setting_emailin_modalmsg" : "あなたは WisePerks から将来のメールを受信を開始してよろしいですか?",
  "dashboard_setting_emailout_succmsg" : "あなたが正常に WisePerks からのメールを受信停止している",
  "dashboard_setting_emailin_succmsg" : "あなたが正常に WisePerks からメールを受信を開始している",
  "dashboard_setting_deactivateacc" : "アカウントを無効化し",
  "dashboard_setting_disableprofile" : "アカウントを無効にすると、WisePerksからプロファイルが無効になります。",
  "dashboard_setting_deactivateacc_msg" : "WisePerks でアカウントを無効にしますか?",
  "dashboard_setting_deactivateacc_flash" : "あなたは、私たちと共有してください任意の苦情やフィードバックがある場合。優先的に対処するチャンスをくれアカウントを削除してもよろしいですか?",
  "dashboard_setting_changelang" : "言語を変えてください",
  "dashboard_setting_preferredlang" : "あなたの好みの言語を選んでください",
  "dashboard_setting_updatepass" : "パスワードの更新",
  "dashboard_setting_plsupdate" : "以下のパスワードを更新してください。",
  "dashboard_setting_oldpass" : "以前のパスワード",
  "dashboard_setting_enteroldpass" : "古いパスワードを入力",
  "dashboard_setting_newpass" : "新しいパスワード",
  "dashboard_setting_enternewpass" : "新しいパスワードを入力してください",
  "dashboard_setting_confpass" : "パスワードを認証する",
  "dashboard_setting_enternewpassagain" : "もう一度新しいパスワードを入力してください",
  "dashboard_setting_update" : "更新",

  "dashboard_doi_welcome" : "ようこそ",
  "dashboard_doi_welbck" : "お帰りなさい",
  "dashboard_doi_accntverified" : "あなたのメールアカウントの確認を逃したように見えます。",
  "dashboard_doi_getaccess" : "急いで！を取得",
  "dashboard_doi_getaccess2" : "ポイントは、一度確認します。",
  "dashboard_doi_activateacc" : "受信トレイをチェックして、登録したメールアカウントに送信されたアカウント確認メールで指定したリンクをクリックしてください。",
  "dashboard_doi_chckspam" : "親切に、確認メールを見つけることができない場合には、あなたのスパム/ジャンクフォルダをチェックしてください。",
  "dashboard_doi_didntgetconf" : "まだ確認メールを取得できませんでしたか?",
  "dashboard_doi_resendconf" : "確認メールの再送",
  "dashboard_doi_resendconfsucc" : "確認メールが登録メールに送信されました。", 
  
  "dashboard_survey_availsurveys" : "利用可能なアンケート",
  "dashboard_survey_wisechecksurveys1" : "セキュリティ上の理由により、アンケートを表示できません。サポートにお問い合わせください",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "退会理由の調査はできません",
  "dashboard_featured" : "注目",
  "dashboard_profile_survey" : "プロフィール調査",
  "dashboard_survey_start" : "開始",
  "dashboard_survey_on" : "に",
  "dashboard_survey_off" : "オフ",
  "dashboard_survey_point": "ポイント",
  "dashboard_survey_points" : "ポイント",
  "dashboard_survey_minute" : "分",
  "dashboard_survey_minutes" : "分",

  "demography_hi" : "こんにちは",
  "demography_welcome" : "WisePerks へようこそ自分で稼いできただけで",
  "demography_points" : "私たちに登録してポイント。",
  "demography_share" : "以下のフォームにいくつかの詳細を共有してください, あなたに関連する調査をもたらすことに私たちを助けるために.",

  "demography_gender" : "私は",
  "demography_male" : "男性",
  "demography_female" : "女性",
  "demography_birthday" : "お誕生日",
  "demography_month" : "月", 
  "demography_day" : "日", 
  "demography_year" : "年",
  "demography_jan" : "1月",
  "demography_feb" : "2月",
  "demography_mar" : "行進",
  "demography_apr" : "4月",
  "demography_may" : "5月",
  "demography_jun" : "六月",
  "demography_jul" : "7月",
  "demography_aug" : "8月",
  "demography_sep" : "9月",
  "demography_oct" : "10月",
  "demography_nov" : "11月",
  "demography_dec" : "12月",
  "demography_hearabout" : "WisePerksについてはどこで知りましたか？",
  "demography_opt1" : "適用される最も近いものを選択する",
  "demography_opt2" : "メール/ニュースレター",
  "demography_opt3" : "Facebookの",
  "demography_opt4" : "フォーラムやブログ",
  "demography_opt5" : "友人",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "検索エンジン",
  "demography_opt8" : "テレビコマーシャル",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "ウェブサイト",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "他",
  "demography_zipcode": "郵便番号",
  "demography_submit" : "送信",

  "privacypolicy_privacypolicy" : "個人情報保護方針",
  "privacypolicy_privacyisimp" : "あなたのプライバシーは私たちにとって重要です。",
  "privacypolicy_lastupdated" : "最終更新日",

  "privacypolicy_para1" : 'WiseWorksは、Wisesample.com、Flapbucks.com、WisePerks.comなどを含むがこれに限定されない多数の異なるWebサイト、モバイルアプリ、および双方向サービスを所有し、運営しています（総称して " WiseWorksサイト」を参照）。このプライバシーポリシー（以下「プライバシーポリシー」）は、WiseWorksサイト、機能、モバイルアプリケーション、電子メール、オンラインサービス、その他の機能（コンピュータ、モバイルデバイスなどを問わず）にアクセスするか、 WiseWorksのサイトと機能（以下「本サービス」）。このプライバシーポリシーは、WiseWorksのサイトと機能およびサービスを通じて当社が収集した情報のみを対象としており、（特に明記されていない限り）当社がオフラインで受け取ったり収集した情報は対象としません。リンクを提供する',

  "privacypolicy_para2" : "パネリスト/回答者は、彼のプロフィールを正直かつ誠実に記入し、誤ったまたは間違った情報を提出しないで、より多くの調査を受ける資格があることが期待されます。パネリスト/回答者は、インセンティブを得るために調査記入中に回答を操作したり、他の不公平な手段（IP、複数の口座等を変更するなど）を使用しないことも求められます。WisePerksはそのような異常を検出するための十分な保護手段を備えており、そのような行為が検出された場合、パネリストはWisePerksからの禁止/ブロックが行われ、すべての償還請求はキャンセルされます。",

  "privacypolicy_para3" : "WiseWorksは、私たちのプライバシーを守ることを強くお約束します。以下のプライバシーポリシーは、あなたの個人情報がどのように保持され、取り扱われているかを説明しています。",

  "privacypolicy_topic1" : "WisePerks.comが私から収集する個人識別情報は何ですか？",
  "privacypolicy_topic1ans" : "WisePerks.comは、ウェブサイトとネットワークのさまざまな部分からいくつかの方法で情報を収集します。登録するといくつかの情報が収集されます。会員登録時に、WisePerks.comはあなたの氏名、Eメールアドレス、郵便番号、世帯情報を尋ねます。あなたが正確に提供するボランティアの情報が多くなればなるほど、サービスと報酬を提供することができます。さらに、何らかの方法でWisePerks.comに連絡した場合、その対応を記録することがあります。WisePerks.comがそのような情報を収集するたびに、このページにこのプライバシーポリシーへのリンクを含めることを目指します。",

  "privacypolicy_topic2" : "クッキーとは何ですか？また、WisePerks.comはそれらをどのように使用しますか？",
  "privacypolicy_topic2ans" : "クッキーは、Webサーバーからブラウザに送信され、コンピュータのハードドライブに保存された電子データのパケットです。特定の調査、情報、パーソナライズされたサービスを提供する一環として、WisePerks.comはCookieを使用して、あなたの情報を保存したり、追跡することがあります。WisePerks.comは、そのような特定のコンテンツを準備するためのクッキーを受け入れることを要求することがあります。これにより、毎回ログイン情報を入力する必要がなくなります。",

  "privacypolicy_topic3" : "個人アカウント情報の更新、編集、変更、削除を許可する際のWisePerks.comのポリシーは何ですか？",
  "privacypolicy_topic3ans" : "WisePerks.comのログイン情報とパスワードを使用して、いつでもWisePerks.comのアカウント情報を編集することができます。WisePerks.comアカウントを無効にする方法の詳細については、",
  "privacypolicy_topic3ans2" : "にお問い合わせください。",

  "privacypolicy_topic4" : "誰が情報を収集していますか？",
  "privacypolicy_topic4ansp1" : "WisePerks.comは、アンケートや登録などを通じていくつかの情報を収集し、リサーチクライアントが消費者のために新しい製品やサービスを開発するのを助けます。あなたがWisePerks.comとのみ情報を共有しているのは、あなたが参加した調査で特に明記されていない限りです。データがWiseWorksまたはWiseWorksサイト以外の企業によって収集および/または管理されている場合は、データの収集または転送の前に通知を受けます。データを共有したくない場合は、その特定の調査または製品を使用しないことによって転送を許可しないことを選択することができます。",
  "privacypolicy_topic4ansp2" : "WisePerks.comで提供する報酬およびプロモーションは、他社から独立してスポンサーとなっている場合もあれば、WisePerks.comおよび他社が共同スポンサーである場合もあります。そのような宣伝の間に収集されたデータの一部は、スポンサーと共有することができます。ただし、データの収集または転送の前に通知されます。データを共有したくない場合は、その特定のプロモーションに参加しないことを決定できます。",
  "privacypolicy_topic4ansp3" : "特定の機密情報（医療または健康状態、人種または民族の起源、政治的見解、宗教または精神的信念、貿易および組合員のメンバーシップ、または個人の性生活を規定する情報など）は、追加の注意を払って扱われます。WisePerks.comが最初に収集した目的とは異なる目的で別の会社にそのような情報を共有しようとする場合は、そのような開示の前に明示的に許可を求められます。",

  "privacypolicy_topic5" : "WisePerks.comは誰と私の情報を共有していますか？",
  "privacypolicy_topic5ansp1" : "私たちはプライバシーを大切にしています。WisePerks.comは、お客様の明示的な許可がある場合や特別な状況、法律で必要とされる場合、または以下に詳述する場合を除いて、お客様の個人情報を開示することはありません。以下に、あなたの情報が開示される方法のいくつかについて説明します。個人情報の共有方法の詳細については、サービスの利用規約をご覧ください。",
  "privacypolicy_topic5ansp2" : "ビジネスパートナー、クライアント、スポンサー：",
  "privacypolicy_topic5ansp3" : "WisePerks.comは、お客様の個人情報をパートナー、クライアントまたはスポンサーに開示することがあります。ただし、お客様に関する個人情報が共有される場合は、データを収集または転送する前にお知らせします。私たちが提供する多くの活動は、スポンサーからの追加情報を要求します。あなたがより多くの情報を要求することにより、WisePerks.comはあなたの情報をスポンサーと共有して、彼らがあなたの要求を満たすことができるようにします。多くの場合、あなたのメールアドレスだけが共有されます。より多くの情報がパートナーと共有される場合は、事前に通知されます。随時、試験材料やその他の製品やスペシャルオファーをパートナーから受け取る機会が与えられます。これらの第三者から情報を受け取るようにオプトインする場合、WisePerks.comはあなたの名前と電子メールアドレスを（あなたの許可で）共有します。",
  "privacypolicy_topic5ansp4" : "WisePerks.comは、WisePerks.comのポリシーおよび利用規約に違反している可能性のある人物を特定、連絡、または訴訟を起こすために必要な場合や、 WisePerks.comの製品、サービス、権利または財産、その他のWisePerks.comのメンバーおよびユーザー、またはそのような活動によって影響を受ける可能性のある他者への（意図的または非意図的）妨害。WisePerks.comは、法律で必要とされることを誠実に信じている場合や、当社の製品やサービスを維持、サービス、改善するために必要と考える行政上その他の目的で口座情報を開示したり、アクセスしたりする可能性があります。",

  "privacypolicy_topic6" : "お客様の情報の処理に使用される法的拠点は何ですか？",
  "privacypolicy_topic6ans" : 'WiseWorksは、プライバシーとデータの保護に取り組んでいます。当社のプライバシーポリシーは、当社、従業員およびサプライヤーが遵守するベンチマークとして、欧州連合（GDPR）が発行する一般データ保護規制の原則を採用しています。',

  "privacypolicy_topic7" : "私の情報の収集、使用、配布に関する私の選択は何ですか？",
  "privacypolicy_topic7ans" : "WisePerks.com（またはWisePerks.comと守秘義務契約を締結しているパートナー）のみが、あなたがこれらに反対していないことを明示した場合にのみ、任意のダイレクトメールを送信します。クッキーの仕組みについても選択肢があります。インターネットブラウザの設定を変更することで、すべてのクッキーを受け入れるか、クッキーが設定されたかを通知するか、すべてのクッキーリクエストを拒否するかを選択できます。すべてのクッキーを拒否することを選択した場合、事前登録が必要なWisePerks.comサービスを使用して参加することはできません。WisePerks.comは、いかなるユーザー情報も誰にも販売またはリースしません。お客様のデータが第三者と共有される場合は、常にデータの収集または転送時に通知されます。また、データを共有することを許可しないという選択肢が常にあります。",

  "privacypolicy_topic8" : "WisePerks.comのアカウント情報の紛失、誤用、改ざんを防ぐために、どのようなセキュリティ対策が施されていますか？",
  "privacypolicy_topic8ansp1" : "あなたのWisePerks.comのアカウント情報はパスワードで保護されており、この情報にアクセスできるのはあなただけです。",
  "privacypolicy_topic8ansp2" : "誰とでもパスワードを共有しないことを強くお勧めします。迷惑な電話やメールであなたのパスワードを尋ねることは決してありません。WisePerks.comアカウントからログアウトし、あなたの活動を終了したらブラウザウィンドウを閉じてください。これにより、公共のコンピュータを使用している場合や、他の人とコンピュータを共有している場合、他人があなたの個人情報やアカウントにアクセスできなくなります。上記のようにWisePerks.comが個人情報を取り扱う場合はいつでも、WisePerks.comは、あなたの情報が関連する利用規約およびこのプライバシーポリシーに従って安全に取り扱われるように措置を講じます。残念なことに、インターネット上のデータ伝送は100％安全であることは保証できません。その結果、お客様の個人情報を保護するために努力していますが、WisePerks.comは、お客様または当社のオンライン製品またはサービスから送信された情報のセキュリティを保証または保証することはできません。お客様のデータを受け取った後、私たちはシステム上のセキュリティを確保するために最善の努力をします。",

  "privacypolicy_topic9" : "私のプライバシーについて他に何を知っておくべきですか？",
  "privacypolicy_topic9ans" : "電子メール、ソーシャルネットワーキングウェブサイト、ブログ、チャットエリアなど、個人情報をオンラインで公開する場合はいつでも、その情報を他のユーザーが収集して使用することができます。一般に公開されているオンラインの個人情報を投稿すると、代理人から迷惑メールを受け取ることがあります。最終的には、お客様のパスワードおよび/または口座情報の秘密を維持することは、お客様自身の責任となります。あなたがオンラインになったときは常に注意し、責任を持ってください。",

  "privacypolicy_topic10" : "WisePerks.comは私の子供のプライバシーをどのように保護しますか？",
  "privacypolicy_topic10ans" : "WisePerks.comは、調査に子供の参加が必要な場合は事前の親の同意を得て、6-15歳の子供がいるようなアンケートには親の監督も必要です。私たちは、サイト上の若いユーザーの安全とプライバシーを保護するために特別な注意を払い、オンラインで子供の経験に参加することをお勧めします。私たちはマーケティングや子供の研究を許可しておらず、特定のトピックは子供の研究では完全に禁止されています：名前/口座名/ Eメールまたは住所、電話番号、人種または民族性、宗教またはキャスト、親または他の家族の婚姻状態、関係事項、学校またはカレッジの成績、アルコール、性的問題、たばこおよび銃器。",

  "privacypolicy_topic11" : "国際ユーザーへの特別な注意",
  "privacypolicy_topic11ansp1" : "このサイトは米国内でホストされています。個人情報の収集、使用、開示に関する法律や規制を持つ欧州連合、アジア、その他の地域から本サイトにアクセスしているユーザーが米国法と異なる場合は、お客様の個人情報をEUその他の地域と同じデータ保護法を持っておらず、お客様が同意する個人情報を提供することにより、",
  "privacypolicy_topic11ansp2" : "プライバシーポリシーに従って上記で特定された使用のためのあなたの個人データの使用。および前述の米国へのあなたの個人データの転送。あなたは、米国の法律が、他の国および法域の法律の下で必要とされる治療とは実質的に異なる可能性があり、かつその保護よりも低い可能性のある方法であなたの情報を処理することを認めるものとします。あなたの情報が米国に移転されないようにするには、あなたは米国との間であなたの情報を共有したり、サイトを使用したりしてはなりません。あなたが所在する国の法律によって許可されている範囲で、米国以外の国または法域の法律に従って、あなたの身元を確認する必要があるかどうかは明示的に黙ってください。しかしながら、前述の権利放棄者は、欧州連合の加盟国のようないくつかの国で法的に束縛されていない可能性があります。あなたが所在する国に法的に束縛されている場合を除き、上記の権利放棄はあなたには適用されません。",

  "privacypolicy_topic12" : "このプライバシーポリシーの変更と更新",
  "privacypolicy_topic12ans" : "WiseWorksは、当社の裁量により、何らかの理由でいつでもこのプライバシーポリシーの一部を変更、変更、追加、または削除する権利を留保します。WiseWorksがこのような権利を行使するたびに、更新されたプライバシーポリシーに肯定的に同意する必要があります。そうしないと、そのサイトにアクセスまたは使用できなくなります。",


    "privacypolicy_topic13": "GDPR / CCPAの詳細をお探しの方への特記事項",
    "privacypolicy_topic13ansp1": "お願いします",
    "privacypolicy_topic13ansp2": "ここをクリック",
    "privacypolicy_topic13ansp3": "GDPR（一般データ保護規則）およびCCPA（カリフォルニア消費者プライバシー法）コンプライアンスの詳細については、こちらをご覧ください。",


    "privacypolicy_addinfo": "追加情報",
    "privacypolicy_sentto": "これに関するその他の質問は、",


    "privacypolicyext_para1": "このプライバシーステートメントを注意深くお読みください",
    "privacypolicyext_para2": "WiseWorks はすべてのユーザーのプライバシーを尊重し、個人データの取り扱いについて透明性を保つことをお約束します。 このプライバシーポリシーでは、WiseWorks Webサイトを使用するとき、またはその他の方法で当社とやり取りするときに、お客様から提供された個人データをWiseWorksが収集、共有、および使用する方法について説明します。 また、プライバシーの権利と選択を行使する方法を学ぶためのセクションもいくつか提供しました。",
    "privacypolicyext_para3": "当社のウェブサイトにアクセスしてWiseWorksサービスを使用する前に、このプライバシーポリシーを注意深くお読みください。 ご不明な点がございましたら、までお問い合わせください。 ",

    "privacypolicyext_topic1": "概要概要",
    "privacypolicyext_topic2": "受け入れと使用",
    "privacypolicyext_topic3": "私たちは誰ですか",
    "privacypolicyext_topic4": "個人情報と利用の概要",
    "privacypolicyext_topic5": "データ収集の目的",
    "privacypolicyext_topic6": "データ保護の原則",
    "privacypolicyext_topic7": "お客様から収集するデータの種類",
    "privacypolicyext_topic8": "クッキーとウェブビーコン",
    "privacypolicyext_topic9": "調査および研究プロジェクトについての通知",
    "privacypolicyext_topic10": "お客様のデータの使用方法",
    "privacypolicyext_topic11": "私たちはあなたのデータを誰と共有しますか",
    "privacypolicyext_topic12": "EEAデータ主体と非EEA",
    "privacypolicyext_topic13": "あなたのデータ保護権",
    "privacypolicyext_topic14": "子供に対する私たちの方針",
    "privacypolicyext_topic15": "データセキュリティ",
    "privacypolicyext_topic16": "規約と条件",
    "privacypolicyext_topic17": "管轄",
    "privacypolicyext_topic18": "プライバシーポリシーの変更",
    "privacypolicyext_topic19": "お問い合わせ",

    "privacypolicyext_t1": "私達、",
    "privacypolicyext_t2": "我ら",
    "privacypolicyext_t3": "または ",
    "privacypolicyext_t4": "私たちの",

    "privacypolicyext_topic1ansp1": " ユーザーのプライバシー権を尊重し、法律とベストプラクティスの両方に基づいて収集された情報を保護および処理することの重要性を理解しています。 このプライバシー通知は、以下に準拠するように書かれています",
    "privacypolicyext_topic1ansp1_1": "EU一般データ保護規則の第13条および第14条、つまり規則（EU）2016/679（「GDPR」）、およびカリフォルニア消費者プライバシー法CCPA。",
    "privacypolicyext_topic1ansp2": "このプライバシー通知は、以下を有する人々に関連して当社が処理する個人情報に適用されます。",
    "privacypolicyext_topic1ansp2_l1": "アカウントに登録してください。",
    "privacypolicyext_topic1ansp2_l2": "当社からマーケティング情報を受け取ることに同意しました。",
    "privacypolicyext_topic1ansp2_l3": "調査に参加する。",
    "privacypolicyext_topic1ansp2_l4": "お問い合わせやサポートについてはお問い合わせください",
    "privacypolicyext_topic1ansp3": "このプライバシーポリシーでは、データに関してどのような権利を持っているか、どのように同意を与えるか、どのように同意を取り消すことができるかについても説明しています。 WiseWorks（およびその関連会社である当社）は、Wisesample.com、Flapbucks.com、WisePerks.comなど（総称してWiseWorksサイト）を含むがこれらに限定されない、いくつかの異なるWebサイト、モバイルアプリ、およびインタラクティブサービスを所有および運営しています。このプライバシーポリシー（プライバシーポリシー）は、WiseWorksサイトとすべての機能、モバイルアプリケーション、電子メール、オンラインサービス、およびその他の機能に適用されます。これらの機能は、コンピューター、モバイルデバイス、またはその他の方法でアクセスされます。 WiseWorksのサイトと機能（サービス）。このプライバシーポリシーは、WiseWorksのサイトと機能および当社のサービスを通じて当社が収集した情報のみを対象とし、当社がオフラインで受信または収集した情報（特に明記されていない限り）、または当社が関係のないWebサイトまたは企業が収集した情報は対象としません。リンクを提供します。",

    "privacypolicyext_topic2ansp1_1": "このプライバシーポリシーは ",
    "privacypolicyext_topic2ansp1_2": " およびその子会社を含む",
    "privacypolicyext_topic2ansp1_3": "まとめて ",
    "privacypolicyext_topic2ansp1_4": "当社のウェブサイトを使用して、お客様はこのプライバシーポリシーの条件に同意したことを意味します。これは、お客様の個人情報を処理するための正式な同意、以下でさらに説明するようにお客様の個人情報の収集、使用、および開示に対するお客様の同意と見なされます。 特定のサービスのデータ処理慣行に関する追加の開示または情報を提供するため。これらの通知は、当社のプライバシー慣行を補足または明確にしたり、データの処理方法に関するその他の選択肢を提供したりする場合があります。",
    "privacypolicyext_topic2ansp2": "このプライバシーポリシーのいずれかの側面に満足できない場合。 WiseWorksWebサイトおよび当社のWebサイトへのアクセスは直ちに中止する必要があります。",

    "privacypolicyext_topic3ansp1_1": " はカナダで設立され登録された会社です（＃10369675）。私たちの登録事務所は ",
    "privacypolicyext_topic3ansp1_2": "オンライン市場調査業界向けにパネルサービスを提供しています。資格要件を満たすユーザーまたは参加者は、ユーザーパネルアカウントに登録し、アンケートに回答してポイントやその他のインセンティブを受け取ることができます。",
    "privacypolicyext_topic3ansp2": "当社のサービスは、カリフォルニア（米国）、カナダ、ドイツ、インドなど、いくつかの国で利用できます。本サービスは、18歳未満のユーザーによる使用を目的としていません。 当社のウェブサイトおよびサービスを使用するには、18歳以上である必要があります。",

    "privacypolicyext_topic4ansp1": "当社は、お客様に関する個人データを収集または取得する場合があり、この情報は、以下の方法で米国地域内に保存または処理される場合があります。 ",
    "privacypolicyext_topic4ansp1_l1": "あなたから直接（例えば、あなたが私たちに連絡する場所）; あなたとの関係の過程で（例えば、あなたが私たちのサービスを利用する場合）;",
    "privacypolicyext_topic4ansp1_l2": "あなたが私たちのウェブサイトを訪問するとき; またはウェブサイトの任意の部分を使用します。 ",
    "privacypolicyext_topic4ansp2": "また、第三者からお客様の個人情報を受け取る場合があります。",

    "privacypolicyext_topic5ansp1": "以下は、当社がお客様の個人情報を使用する方法の例です。",
    "privacypolicyext_topic5ansp1_l1": "サービスの提供",
    "privacypolicyext_topic5ansp1_l2": "ウェブサイトの提供と改善",
    "privacypolicyext_topic5ansp1_l3": "調査",
    "privacypolicyext_topic5ansp1_l4": "コミュニケーション",
    "privacypolicyext_topic5ansp1_l5": "ユーザーエンゲージメント",
    "privacypolicyext_topic5ansp1_l6": "マーケティング",
    "privacypolicyext_topic5ansp1_l7": "リードジェネレーション",
    "privacypolicyext_topic5ansp1_l8": "法令順守",

    "privacypolicyext_topic6ansp1": "当社は、個人データ（EU一般データ保護規則の第13条および第14条、すなわち、規則（EU）2016/679（「GDPR」）およびカリフォルニア消費者プライバシー法CCPAの定義に基づく）を収集、処理、および使用します。 私たちのサービスを提供します。 個人データとは、名前、電子メール、住所、健康情報、任意の形式の識別番号、またはあなたまたはあなたの受益者の身元に固有の1つ以上の要素など、直接的または間接的にあなたを識別することを可能にする情報を意味します。",
    "privacypolicyext_topic6ansp2": "当社は、適用されるデータ保護法を遵守することを約束し、個人データが次のとおりであることを保証します。",
    "privacypolicyext_topic6ansp2_l1": "合法的に、公正に、そして透過的に使用されます。",
    "privacypolicyext_topic6ansp2_l2": "明確に説明した有効な目的でのみ収集され、それらの目的と矛盾する方法で使用されることはありません。",
    "privacypolicyext_topic6ansp2_l3": "私たちがあなたに話した目的に関連し、それらの目的だけに限定されます。",
    "privacypolicyext_topic6ansp2_l4": "正確で最新の状態に保たれています。",
    "privacypolicyext_topic6ansp2_l5": "私たちがあなたに話した目的のために必要な間だけ保持されました。",

    "privacypolicyext_topic7ansp1": "当社は、お客様に関する以下の個人情報を収集および処理する場合があります。",
    "privacypolicyext_topic7ansp1_l1title": "個人プロフィール情報。",
    "privacypolicyext_topic7ansp1_l1": "アカウントを登録すると、名前と名前、メールアドレス、選択したパスワードが収集されます。",
    "privacypolicyext_topic7ansp1_l2title": "連絡先。",
    "privacypolicyext_topic7ansp1_l2": "テキストメッセージまたはその他のメッセージングメディアを介して調査を受け取ることを選択した場合、携帯電話番号または関連する連絡先の詳細も収集されます。",
    "privacypolicyext_topic7ansp1_l3title": "アンケートデータ。",
    "privacypolicyext_topic7ansp1_l3": "最初のプロファイリング質問票は、あなたの経歴、教育、労働状況、消費者の習慣、およびオプションであなたの健康に関する情報を収集します。私たちはあなたがプロファイリング情報に加えた更新に関する情報を収集します。",
    "privacypolicyext_topic7ansp1_l4title": "意見データ。",
    "privacypolicyext_topic7ansp1_l4": "調査の機会に、私たちはあなたの意見、態度、経験、および特定の製品やサービスの使用に関してあなたが提供する情報を収集します。 時折、研究の機会にあなたが提出したビデオ、オーディオ、または写真を収集する場合があります。",
    "privacypolicyext_topic7ansp1_l5title": "ユーザーエクスペリエンスデータ。",
    "privacypolicyext_topic7ansp1_l5": "私たちはあなたのログイン、ブログ投稿へのコメント、評価、投票、閲覧されたウェブページに関する情報を収集します。",
    "privacypolicyext_topic7ansp1_l6title": "特典情報。",
    "privacypolicyext_topic7ansp1_l6": "賞品や報酬を発行したり、パネルや調査活動の使用状況を監視したりするために必要となる可能性のある個人情報。",
    "privacypolicyext_topic7ansp1_l7title": "通信データ。",
    "privacypolicyext_topic7ansp1_l7": "あなたが私たちに送るどんな通信でも。",
    "privacypolicyext_topic7ansp1_l8title": "使用状況データ。",
    "privacypolicyext_topic7ansp1_l8": "また、お客様が当社のサービスを利用するたびに、お客様に関する使用状況データを収集します。これには、アクセスしたWebページ、それらのアクションを実行したときにクリックしたものなどが含まれます。さらに、デバイスごとにデータを記録するデジタルログファイルを保持します。 当社のサーバーにアクセスします。ログファイルには、各アクセスの性質に関するデータが含まれます。当社のサービスへのアクセスに使用するデバイスおよびアプリケーションから、IPアドレス、ブラウザーの種類、インターネットサービスプロバイダー（ISP）、参照/終了などのデータを収集します。 ページ、オペレーティングシステム、日付/タイムスタンプ、およびクリックストリームデータ。",
    "privacypolicyext_topic7ansp2": "また、IPアドレスに基づいて地理的位置を推測する場合があります。外部ソース（電子メールまたはメッセージングアプリに含まれる別のWebサイトのリンクなど）からWiseWorksサービスにアクセスした場合、この情報を使用して分析する場合があります。 トレンド、サイトの管理、ユーザーの追跡、およびユーザーベース全体に関する人口統計情報の収集。",

    "privacypolicyext_topic8ansp1": "Cookieは、Webサイトにアクセスしたときにブラウザに送信される少量の情報を含むテキストファイルです。 その後、Cookieは、その後のアクセスごとに元のWebサイトに返送されるか、Cookieを認識する別のWebサイトに返送されます。 クッキーは非常に便利なテクノロジーであり、さまざまな仕事をします。 Webビーコンは、多くの場合透明なグラフィックイメージであり、通常は1ピクセルx 1ピクセル以下で、Webサイトに配置されるか、Webサイトにアクセスしたり電子メールを送信したりするユーザーの行動を監視するために使用される電子メールに配置されます。 多くの場合、Cookieと組み合わせて使用されます。",
    "privacypolicyext_topic8ansp2": "当社は、Cookie、Webビーコン、または同様の分析主導のテクノロジーを使用して情報を収集する場合があります。",
    "privacypolicyext_topic8ansp3_title": "どのCookieを使用しますか？",
    "privacypolicyext_topic8ansp3": "私たちが使用するCookieは、次のカテゴリに分類されます。",
    "privacypolicyext_topic8ansp3_l1title": "厳密に必要なCookie。",
    "privacypolicyext_topic8ansp3_l1": "これらは、当社のウェブサイトの運営に必要なクッキーであり、例えば、当社のウェブサイトの安全な領域にログインすることを可能にするクッキーが含まれています。",
    "privacypolicyext_topic8ansp3_l2title": "分析/パフォーマンスCookie。",
    "privacypolicyext_topic8ansp3_l2": "これにより、訪問者の数を認識してカウントし、訪問者がWebサイトを使用しているときにWebサイト内をどのように移動するかを確認できます。 これは、たとえば、ユーザーが探しているものを簡単に見つけられるようにすることで、Webサイトの動作を改善するのに役立ちます。",
    "privacypolicyext_topic8ansp3_l3title": "機能性クッキー。",
    "privacypolicyext_topic8ansp3_l3": "これらは、お客様が当社のWebサイトに戻ったときにお客様を認識するために使用されます。 これにより、コンテンツをパーソナライズし、名前で挨拶し、好みを覚えることができます。",
    "privacypolicyext_topic8ansp3_l4title": "ターゲティングCookie。",
    "privacypolicyext_topic8ansp3_l4": "これらのCookieは、当社のWebサイトへのアクセス、アクセスしたページ、およびアクセスしたリンクを記録します。 この情報を使用して、当社のWebサイトとそこに表示される広告をお客様の興味により関連性のあるものにします。 また、この目的のために、この情報を第三者と共有する場合があります。",
    "privacypolicyext_topic8ansp4": "当社は、さまざまな種類のCookie（当社が配置するものと第三者が当社のサイトに配置することを許可するものを含む）をいくつかの目的で使用します。 Cookieによって収集された情報により、当社のサービスを使用している人の数と他のサービスとの相互作用、電子メールを開いている人の数、およびこれらのアクションが実行されている目的を監視できます。 Cookieは、ユーザーのログインを容易にしたり、ナビゲーションエイドとして、セッションタイマーとして使用したり、未成年者によるツールやサービスの使用を制限したりするためにも使用されます。クッキーからの非個人情報を許可なく個人を特定できる情報にリンクすることはありません。何らかの理由でクッキーを使用して当サイトの使用を追跡したくない場合は、ブラウザの設定を使用してこれをオプトアウトすることができます。 「追跡しない」オプションを選択します（詳細については、ブラウザを参照してください）。ほとんどのブラウザでは、ツールバーの「ヘルプ」セクションで、ブラウザをリセットしてCookieを拒否する方法について説明しています。",
    "privacypolicyext_topic8ansp5": "当社は、ウェブビーコンを使用してサービスの使用に関する情報を収集します。ウェブビーコンによって収集された情報により、サービスを使用している人の数、電子メールを開いている人の数を監視し、これらのアクションがどのような目的で実行されているかを判断できます。 。当社のウェブビーコンは、当社のサービス外の活動を追跡するために使用されていません。",
    "privacypolicyext_topic8ansp6_title": "Cookieの設定を変更するにはどうすればよいですか？",
    "privacypolicyext_topic8ansp6_1": "ほとんどのWebブラウザでは、ブラウザの設定を通じてほとんどのCookieをある程度制御できます。設定されているCookieの確認方法など、Cookieの詳細については、www.aboutcookies.orgまたはwww.allaboutcookies.orgにアクセスしてください。",
    "privacypolicyext_topic8ansp6_2": "人気のあるブラウザでCookieを管理する方法を見つけるには：",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "WiseWorksに参加すると、メンバーシップに関して電子メール、電話、またはメッセージングサービス（好みに応じて）を介して連絡する場合があります。連絡する場合は、次のようなものがあります。",
    "privacypolicyext_topic9ansp1_l1": "調査の機会（調査、世論調査、フォーカスグループなど）",
    "privacypolicyext_topic9ansp1_l2": "研究成果",
    "privacypolicyext_topic9ansp1_l3": "パネリストニュースレター",
    "privacypolicyext_topic9ansp2": "私たちのプロジェクトは、幅広いトピックに焦点を当てることができます。 通常、機会についてメールでプロジェクトに参加するよう招待します。 その時点で、調査の幅広いトピック、その可能性のある期間または必要な関与、および提供される報酬について説明します。 参加するかどうかはあなた次第です。参加は必須ではありません。 タスクを開始したら完了することをお勧めしますが、通常は不完全なタスクに対してインセンティブを支払うことはありませんが、いつでもドロップアウトできます。",
    "privacypolicyext_topic9ansp3": "要求に応じて、常に、真実、正確、最新かつ完全な情報を提供することを期待しています。",


    "privacypolicyext_topic10ansp1": "当社は、法律で許可されている場合にのみお客様の個人データを使用します。通常、お客様の個人データは次の状況で使用されますが、お客様のデータを処理するために依存する法的根拠の種類の詳細については、をクリックしてください。 ここに。：",
    "privacypolicyext_topic10ansp1_l1": "私たちの製品とサービスをあなたに提供します",
    "privacypolicyext_topic10ansp1_l2": "それぞれの研究機会に最も適した人を見つける。",
    "privacypolicyext_topic10ansp1_l3": "調査で尋ねる必要のある質問の数を減らします。",
    "privacypolicyext_topic10ansp1_l4": "すでに収集した調査データをさらに分析します。",
    "privacypolicyext_topic10ansp1_l5": "私たちの研究能力をクライアントに説明するのに役立つ集約された形で。",
    "privacypolicyext_topic10ansp1_l6": "調査、フォーカスグループ、オンラインコミュニティ、アクセスしたWebサイトのパッシブモニタリングなどの調査機会に招待します。",
    "privacypolicyext_topic10ansp1_l7": "獲得したインセンティブ報酬を送信し、",
    "privacypolicyext_topic10ansp1_l8": "プロファイルの更新をリクエストし、",
    "privacypolicyext_topic10ansp1_l9": "当社の製品またはサービスに関する調査にご参加ください。",
    "privacypolicyext_topic10ansp1_l10": "詐欺、不適切な活動を監視、検出、防止し、WiseWorksのポリシーコンプライアンスを確保します",
    "privacypolicyext_topic10ansp1_l11": "二次調査を含む調査を実施し、調査をフォローする",
    "privacypolicyext_topic10ansp1_l12": "あなたと他のユーザーが私たちのオンラインサービスとどのように相互作用するかを評価します",
    "privacypolicyext_topic10ansp1_l13": "WiseWorksとユーザーを保護する",
    "privacypolicyext_topic10ansp1_l14": "現在のお客様とのやり取りに関連する監査を実施します",
    "privacypolicyext_topic10ansp1_l15": "サイトとサービスをデバッグして、既存の意図された機能を損なうエラーを特定して修復します",
    "privacypolicyext_topic10ansp1_l16": "訴訟や調査で身を守り、訴訟を起訴する",
    "privacypolicyext_topic10ansp1_l17": "法的および規制上の義務を遵守するため。",


    "privacypolicyext_topic11ansp1": "WiseWorksは、以下に説明する状況でのみ、お客様の個人情報を第三者と共有します。 当社は、金銭的またはその他の貴重な対価のために収集したお客様の個人情報を販売またはその他の方法で開示しません。",
    "privacypolicyext_topic11ansp1_l1title": "認定ベンダー：",
    "privacypolicyext_topic11ansp1_l1": "当社は、当社のWebサイトの運営、支払いの処理、サービスの提供を支援し、お客様の指示と契約上の義務を遵守するのに役立つサードパーティベンダーとお客様のデータを共有する場合があります。 これらには、支払い処理業者、コンテンツ配信ネットワーク（CDN）、クラウドベースのホスティングサービス、監視サービス、電子メールサービスプロバイダー、品質保証およびテストベンダー、詐欺および乱用防止ベンダー、顧客関係管理（CRM）、ベンダーが含まれます。",
    "privacypolicyext_topic11ansp1_l2title": "広告および分析パートナー:",
    "privacypolicyext_topic11ansp1_l2": "当社は、Cookieおよびその他の同様のテクノロジーを通じて、サードパーティの広告主および分析プロバイダーと使用状況データを共有する場合があります。 これらのサードパーティは、一意の識別子、IPアドレス、またはコンピューターやデバイスに関するその他の情報など、コンテンツの要求に応じてコンピューター、ブラウザー、またはモバイルデバイスから送信される情報を収集する場合があります。",
    "privacypolicyext_topic11ansp1_l3title": "ビジネスパートナー：",
    "privacypolicyext_topic11ansp1_l3": "イベント、プロモーション、コンテスト、その他のプログラムを提供するために、サードパーティと提携する場合があります。 これらのオファーに参加するには、電子メールアドレスと追加の個人情報の提供が必要になる場合があります。 あなたが明示的に許可した場合にのみ、あなたのメールアドレスやその他の個人情報をビジネスパートナーと共有します。",
    "privacypolicyext_topic11ansp1_l4title": "特定の法的義務： ",
    "privacypolicyext_topic11ansp1_l4": "WiseWorksは、裁判所またはその他の政府機関から指示された場合、個人情報の開示を求められる場合があります。 上記を制限することなく、当社は、適用される法律、規制、裁判所命令、政府、および法執行機関の要求を遵守し、保護し、 WiseWorksまたは第三者の権利と財産、またはWiseWorks、当社のユーザー、当社の従業員、またはその他の人々の安全を守り、犯罪行為、詐欺、および当社のWebサイトの誤用または不正使用を防止、検出、調査、および対策し、施行する 当社の利用規約またはその他の契約またはポリシーは、法律で許可されている範囲で、そのような要求に応じてお客様の情報を開示する前に、事前に通知するよう努めます。",
    "privacypolicyext_topic11ansp1_l5title": "あなたの同意を得て：",
    "privacypolicyext_topic11ansp1_l5": "WiseWorksは、お客様が同意した場合、またはお客様の指示により、お客様の個人情報を第三者に送信する場合があります。 たとえば、これは、お客様のリクエストに応じてトランザクションを完了したときに発生する可能性があります。",
    "privacypolicyext_topic11ansp1_l6title": "サービスプロバイダーの場合：",
    "privacypolicyext_topic11ansp1_l6": "当社は、当社に代わって特定のサービスおよび独自のプロセス情報を提供するベンダー、コンサルタント、支払い処理業者、およびその他のサービスプロバイダーと個人情報を共有する場合があります。 これらのサービスには、カスタマーサポートの提供、ビジネスおよび販売分析の実行、Webサイト機能のサポート、支払い処理の促進、コンテスト、調査、およびWebサイトで提供されるその他の機能のサポートが含まれる場合があります。 これらのサービスプロバイダーは、他の目的でお客様の個人情報を使用することを許可されていません。 そのような個人データの使用は、適切な機密性とセキュリティ対策の対象となります。",
    "privacypolicyext_topic11ansp1_l7title": "アフィリエイトと：",
    "privacypolicyext_topic11ansp1_l7": "当社は、このプライバシーポリシーの条件に従い、共通の管理および所有権の下にある一部のパートナー、関連会社、およびその他の企業と個人情報を共有する場合があります。 また、破産した場合、またはこれらのイベントの準備のために、事業の売却、売却、合併、統合、資産売却などの実質的な企業取引に関連して、お客様の個人情報を共有する場合があります。 当社を購入する、または当社の事業の一部となるその他の事業体は、お客様の個人情報を引き続き使用する権利を有しますが、お客様が別途同意しない限り、このプライバシーポリシーに記載されている方法でのみ使用します。",

    "privacypolicyext_topic12ansp1_title": "あなたの情報を処理するための法的根拠：",
    "privacypolicyext_topic12ansp1": "欧州経済領域、英国、またはスイスに所在する個人（総称して「EEA居住者」）の場合、お客様から収集されたデータは、米国で転送または処理される場合があります（総称して「データ主体」）。 彼らのデータが収集されるとき、私たちはあなたの情報を処理するための法的根拠に依存しています。 当社は通常、お客様に当社のサービスを提供するために（またはお客様と契約を結ぶ前にお客様の要求に応じて措置を講じるために）必要な場合にのみお客様のデータを処理します。 ",
    "privacypolicyext_topic12ansp2_title": "シャインザライト（カリフォルニア市民向け）CCPA",
    "privacypolicyext_topic12ansp2_1": "カリフォルニア州の「Shine the Light」法により、カリフォルニア州の顧客は、特定の種類の情報をサードパーティ、場合によってはアフィリエイトとサードパーティおよびアフィリエイトのダイレクトマーケティング目的で共有する方法に関する具体的な詳細を要求できます。 法律の下では、企業はカリフォルニアの顧客に要求に応じて特定の情報を提供するか、カリフォルニアの顧客がこのタイプの共有をオプトアウトすることを許可する必要があります。",
    "privacypolicyext_topic12ansp2_2": 'シャインザライトのリクエストを実行するには、お問い合わせください。リクエストの本文に「カリフォルニア州のプライバシー権」と、名前、住所、都市、州、郵便番号を記載する必要があります。十分に入力してください。 これがあなたのリクエストの本文であなたに当てはまるかどうかを判断するための情報。電話、電子メールによる問い合わせは受け付けず、適切にラベル付けまたは送信されていない、または完全な情報がない通知については責任を負いません。',
    "privacypolicyext_topic12ansp3_title": "あなたのネバダプライバシー権",
    "privacypolicyext_topic12ansp3": 'ネバダ州に居住している場合は、特定の個人データのライセンス供与または販売を目的とする第三者への特定の個人データの販売をオプトアウトする権利があります。 この権利を行使するには、こちらからお問い合わせいただくか、「Nevada Do Not Sell Request」という件名でメールを送信し、アカウントに関連付けられている名前とメールアドレスをお知らせください。',

    "privacypolicyext_topic13ansp1": "私たちはあなたがあなたのデータについて多くの選択をすることを可能にします：あなたが私たちのサービスを使うときあなたが持っている以下のデータ保護権は以下のとおりです。",
    "privacypolicyext_topic13ansp1_l1": "特定の情報を提供しないことを選択できます。",
    "privacypolicyext_topic13ansp1_l2": "個人情報へのアクセス、修正、更新、削除のリクエストを希望する場合は、カスタマーサービスポータルまたはこのプライバシーポリシーの下部に記載されている情報からいつでも連絡できます。",
    "privacypolicyext_topic13ansp1_l3": "お客様がお客様のデータへのアクセスを要求した場合、当社はお客様の情報を保持しているかどうかを確認し、そのコピーを提供し、その処理方法とその理由、保存期間、およびそれに関連するお客様の権利について説明します。",
    "privacypolicyext_topic13ansp1_l4": "個人情報の削除をご希望の場合は、消去させていただきます。法的義務の履行、法的請求の成立、行使、弁護に必要な情報は保持する必要がありますので、あらかじめご了承ください。",
    "privacypolicyext_topic13ansp1_l5": "個人情報の処理に異議を唱えたり、個人情報の処理を制限するように依頼したり、個人情報の移植性を要求したりすることができます。また、カスタマーサービスポータルまたはで提供される情報から当社に連絡することにより、これらの権利を行使することができます。 このプライバシーポリシーの下部。",
    "privacypolicyext_topic13ansp1_l6": 'あなたには、いつでも私たちがあなたに送る電子メールマーケティングコミュニケーションをオプトアウトする権利があります。あなたは私たちがあなたに送るマーケティング電子メールの「購読解除」または「オプトアウト」リンクをクリックすることによってこの権利を行使することができます。',
    "privacypolicyext_topic13ansp1_l7": "当社からのメッセージのオプトアウト：当社からの今後のプロモーションメッセージをオプトアウトするには、送信されたプロモーションメッセージの本文（電子メールの下部にあります）で購読を解除するか、購読解除リクエストを送信する必要があります。 受領後、妥当な時間内にリクエストを処理します。",
    "privacypolicyext_topic13ansp1_l8": "同様に、当社がお客様の同意を得てお客様の個人情報を収集および処理した場合、お客様はいつでもお客様の同意を取り消すことができます。お客様の同意を取り消すことは、お客様の撤回前に行った処理の合法性に影響を与えることも、お客様の処理に影響を与えることもありません。 同意以外の合法的な処理の根拠に基づいて行われる個人情報。",
    "privacypolicyext_topic13ansp1_l9": "お客様は、当社の個人情報の収集および使用についてデータ保護当局に苦情を申し立てる権利があります。詳細については、当社までお問い合わせください。リンクまたはデータ保護責任者の住所をお送りします。",
    "privacypolicyext_topic13ansp1_l10": "リクエストを送信する最も簡単な方法は、このプライバシーポリシーの下部にあるカスタマーサポートメールを使用することです。適用されるデータ保護法に基づいてデータ保護権を行使することを希望する個人から受け取ったすべてのリクエストに対応します。",

    "privacypolicyext_topic14ansp1": "ウェブサイトおよびサービスは、13歳未満の子供を対象としていません。WiseWorksは、児童オンラインプライバシー保護（COPPA）法に準拠しています。 18歳未満の個人情報の収集を故意に要求することはありません。18歳未満の個人が当社のサービスにアクセスできないように当社のウェブサイトを使用していることをご存知の場合はお知らせください。",

    "privacypolicyext_topic15ansp1": "当社は、お客様のデータのセキュリティを維持し、当社の管理下にあるデータの損失、誤用、および改ざんから保護するための対策を講じることをお約束します。 許可されていない個人による侵入からシステムを保護するために、最新の安全な技術を採用しており、より良い方法が利用可能になると、定期的にセキュリティをアップグレードします。 当社のデータセンターとパートナーは、施設への不正アクセスを防ぐために新しい物理的セキュリティ対策を利用しています。",
    "privacypolicyext_topic15ansp2": "完全な、または侵入できないセキュリティ対策はないことに注意してください。 したがって、当社はお客様のプライバシーを保護するために業界標準の慣行を使用していますが、個人データの絶対的なセキュリティを保証することはできません（保証しません）。",


    "privacypolicyext_topic16ansp1": "当社の利用規約の詳細については、当社の利用規約のページをご覧ください。このページでは、当社のWebサイトの使用に適用される使用、免責事項、および責任の制限について説明しています。",

    "privacypolicyext_topic17ansp1": "アメリカ合衆国の法律は、このプライバシーポリシーと利用規約およびその他の関連サービスに適用されます。",

    "privacypolicyext_topic18ansp1": "WiseWorksは、お客様、プライバシー、およびWiseWorksサービスの使用をより適切に保護するために、新しいテクノロジーとプロセスの実装に継続的に取り組んでいます。 その結果、このプライバシーポリシーの変更は、WiseWorksによって随時行われる場合があります。これらの変更によって、不正な開示からユーザーを保護するために設計されたセキュリティ対策が低下することはありません。",

    "privacypolicyext_topic19ansp1": "このプライバシーポリシーについてご不明な点がございましたら、次のアドレスまでお問い合わせください。 ",






  "faq_faq" : "よくある質問",
  "faq_getans" : "あなたのすべての質問に対する回答を得る。",

  "faq_ques1" : "WisePerksとは何ですか？",
  "faq_ans1" : "私たちはあなたに喜んで尋ねました。WisePerksはオンライン調査サイトです。多くのブランドや企業が、回答者が意見を述べ、報酬を受け取ることを求めています。WisePerksは、あなたの意見を与えて報酬を得ることができるプラットフォームの1つです。",

  "faq_ques2" : "参加するのは無料ですか？",
  "faq_ans2" : "はい、自由に参加できます。実際、誰かがあなたに参加料を請求している場合、私たちはあなたにそれを私たちに報告してもらいたいでしょう。",

  "faq_ques3" : "私は友達を紹介することはできますか？",
  "faq_ans3" : "はい、あなたはあなたの友人や同僚に参加するように招待することもでき、それに対して報酬を得ることもできます。すべての詳細を取得するには、ダッシュボードの[友達を紹介]リンクをクリックしてください。",

  "faq_ques4" : "私は何を得ますか？",
  "faq_ans4" : "今私たちの新しいモデルでは、あなただけの調査を取ることによって以外の多くの方法でポイントを獲得することができます。あなたがポイントを得る: 私たちとの登録, あなたのメールアカウントを確認することによって, あなたのプロフィールを完了するための, もちろん、任意の調査を完了し、あなたの驚きにも失格調査のための.だから我々はあなたが開始するすべての理由があるのではないですか?",

  "faq_ques5" : "WisePerks.comのメンバーになるにはどうすればいいですか？",
  "faq_ans5" : 'あなたのブラウザでWisePerks.comを開きます。「サインアップ」タブをクリックして会員ログインを作成します。手順に従うだけで、プロセスの残りの部分をガイドします。提供された電子メールに私達が送信した確認メールをクリックして、参加の意思を確認する必要があります。',

  "faq_ques6" : "あなたが言及したその確認メールはどこですか？",
  "faq_ans6p1" : "確認メールは、サインアップ時に入力したメールアドレスに自動的に送信されます。受信トレイにそのメールが表示されない場合は、スパムまたはジャンクフォルダを確認してください。私たちからの将来の電子メールを避けるために、あなたの迷惑メールフォルダに到達するから/メッセージを招待し、追加を行う",
  "faq_ans6p2" : "、承認された送信者のリスト、またはメールボックスの連絡先として使用できます。それでも確認メールが見つからない場合は、アカウントにログインして、お客様のメールアドレスが正しいことを確認し、別の確認メールをリクエストしてください。",

  "faq_ques7" : "アンケートにはどうすればいいですか？",
  "faq_ans7" : 'ユーザー名とパスワードを入力した後にログインすると、ダッシュボードに到達し、すべての調査を見ることができます。また、登録したメール id にアンケートのリンクを送信します。あなたのメールボックスから直接リンクを取ることができます。',

  "faq_ques8" : "私の回答、データはどのように秘密ですか？",
  "faq_ans8" : "WisePerksの場合、ユーザーのプライバシーと機密保持は最優先事項です。私たちのクライアントは、あなたの考え、消費者、またはあなたが何かについて感じることにのみ関心があります。私たちはWisePerksで、いかなる個人情報もお客様と共有/渡すことはありません。また、WisePerksは第三者に情報を売却または貸与していません。",

  "faq_ques9" : "これらの調査はどのくらいの期間ですか？",
  "faq_ans9" : "ほとんどの調査はわずか10〜15分かかります。しかし、時々、私たちはより長い調査をしているかもしれません。各調査では、どれくらいの時間がかかるかを事前に教えていますが、長めの調査でも報酬が高くなっています。",

  "faq_ques10" : "誰でもWisePerks調査を受けることができますか？",
  "faq_ans10" : "WisePerksに登録するには、13歳以上でなければなりません。時には、あなたの家族の若いメンバーに参加するためのアンケートにご招待します。",

  "faq_ques11" : "私はアンケートの対象となることをどのように知っていますか？",
  "faq_ans11" : "私たちのアルゴリズムは、お客様のプロファイル情報と利用可能なアンケートを照合し、最も適切なアンケートを確実に取得します。アンケートが始まると、最初のいくつかの質問が、あなたがその特定のアンケートのためにクライアントが探している回答者かどうかを判断します。私たちは、さまざまなタイプの人々（例えば、定期的に映画を観たり、特定のスポーツをしたり、子供がいる女性など）を探していて、毎日新しい調査が行われています。",

  "faq_ques12" : "WisePerksに対する私の義務は何ですか？",
  "faq_ans12" : "私たちがあなたから期待しているのは、顧客が自分の製品やサービスを改善するための正直で真の答えを探しているので、あなたの意見と考えを正直に提供することだけです。メンバーとしてサインアップする際に正しい情報を提供し、変更が発生したときに更新してください。",

  "faq_ques13" : "購入した家庭、技術、製品に関する情報を提供するのはなぜですか？",
  "faq_ans13" : "各アンケートについて、回答者の特定のプロフィールを探しています。あなたのプロフィール情報からあなたの情報がクライアントの要求に合っていることを知っていれば、ビンゴ！アンケートを成功裏に完了して報酬を得ることができることはほぼ確実です。これは基本的にあなたの調査のために選択される機会を増やします。",

  "faq_ques14" : "WisePerks調査を完了した後、私はいつギフトカードを受け取りますか？",
  "faq_ans14" : "アンケートを正常に完了した後に獲得したポイントは、バケットに蓄積されます。これらのポイントが実現されると、交換することができます。",

  "faq_ques15" : "本当に私のアカウントは何ですか？",
  "faq_ans15" : "それは、あなたの個人情報を見たり、必要に応じて変更したり、調査活動、得られた報酬、すべてを1か所で見たりする簡単な方法です。",

  "faq_ques16" : "アカウントをキャンセルするにはどうすればいいですか？",
  "faq_ans16" : "そんなにあなたの方法来ると、我々はあなたがこれを行うにはしたくないと確信しています。ただし、ダッシュボードで--> [設定]--> [アカウントの管理]--> [アカウントの非アクティブ化]",

  "faq_ques17" : "ポイントシステムはどのように機能しますか？",
  "faq_ans17p1" : "各調査は一定数のポイントに対するものです。アンケートを正常に完了すると、これらのポイントは収益に加算されます。最小しきい値に達するとそれらを引き換えることができます。",
  "faq_ans17p2" : "接続や故障に関する予期せぬ問題が原因でいくつかのケースでは、クライアント側であなたの応答が取得されない場合があります。その後、アカウントからこれらのポイントを取り消す必要があります。これらのポイントを既に引き換えており、アカウントの残高が低い場合、収益はマイナスになります。これらの調整は、次回の引き換えサイクルで行われます。",

  "faq_ques18" : "調査のポイントはどのくらいでポイント残高に加算されますか？",
  "faq_ans18" : "ポイントはポイント残高に48時間以内に加算されます。",

  "faq_ques19" : "償還の最低基準は何ですか？",
  "faq_ans19" : "償還の最低基準は25000ポイントです。平均して、償還の対象となるには8-12回の調査を完了する必要があります。",

  "faq_ques20" : "ポイントはギフトカードの現金にどのように変換されますか？比率はありますか？",
  "faq_ans20" : "はい。あなたの国によって、ポイントはギフトカード現金に変換されます。変換率は以下の通りです",
  "faq_ponits" : "ポイント",
  "faq_point" : "ポイント",
  "faq_cent" : "セント",

  "faq_ques21" : "まだ多くの質問がありますか？",
  "faq_ans21p1" : "お気軽にメールでお問い合わせ",
  "faq_ans21p2" : "そして、我々は48時間以内にあなたに戻って取得します。",

  "Profiling_profiledashboard" : "プロファイルダッシュボード", 
  "Profiling_completeprofile" : "あなたのプロフィールを完了し、痛打を取得",
  "Profiling_completepoints" : "ポイント！",
  "Profiling_answercarefully" : "次の一般的な質問のセットは、最適な将来の調査機会を提供するために、個人プロファイルを作成するために使用されます。あなたの時間をかけて、慎重に、正直に答えてください。",

  "Profiling_cat_general" : "一般",
  "Profiling_cat_personal" : "個人",
  "Profiling_cat_household" : "世帯",
  "Profiling_cat_profession" : "職業",

  "Profiling_bar_completed" : "% 完了",
  
  "Profiling_update_ans_button" : "更新して続行",
  "Profiling_save_ans_button" : "保存",
  "Profiling_sc_ans_button" : "保存と続行",

  "Category_thanku_msg1" : "良いつもり",
  "Category_thanku_msg2" : "いくつかの詳細は、あなたのアカウントで",
  "Category_thanku_msg3" : "ポイントを持つことになります",

  "Category_thanku_update_msg1" : "あなたの情報を更新してくれてありがとう!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "続行",

  "OverAll_thanku_msg1" : "あなたの情報を共有していただきありがとうございます。獲得している",
  "OverAll_thanku_msg2" : "ポイント",

  "Survey_history_surveyhistory" : "調査の歴史",
  "Survey_history_fromthisscreen" : "この画面から、すべての取引を見ることができます。",
  "Survey_history_show" : "表示",
  "Survey_history_entries" : "エントリ",
  "Survey_history_date" : "日付",
  "Survey_history_details" : "詳細",
  "Survey_history_surveys" : "調査",
  "Survey_history_pointsreceived" : "受信したポイント",
  "Survey_history_result" : "結果",
  "Survey_history_status" : "ステータス",
  "Survey_history_status_complete" : "完了", 
  "Survey_history_status_disqualified" : "失格", 
  "Survey_history_keyword" : "キーワード",
  "Survey_history_search" : "検索",
  "Survey_history_to" : "宛先",
  "Survey_history_selectoption" : "オプションの選択",
  "Survey_history_earned" : "獲得",
  "Survey_history_redeemed" : "償還",
  "Survey_history_norecord" : "レコードが見つかりません",
  "Survey_history_Surveypts" : "測量ポイント",
  "Survey_history_Promocode" : "プロモーションコード",

  "My_transactions_mytransactions" : "私の取引",
  "My_transactions_fromthisscreen" : "この画面から、すべての取引を見ることができます。",
  "My_transactions_show" : "表示",
  "My_transactions_entries" : "エントリ",
  "My_transactions_transactionid" : "トランザクション Id",
  "My_transactions_date" : "日付",
  "My_transactions_details" : "詳細",
  "My_transactions_points" : "ポイント",
  "My_transactions_status" : "ステータス",
  "My_transactions_keyword" : "キーワード",
  "My_transactions_search" : "検索",
  "My_transactions_to" : "宛先",
  "My_transactions_selectoption" : "オプションの選択",
  "My_transactions_status_earned" : "獲得",
  "My_transactions_status_redeemed" : "償還",
  "My_transactions_status_pending" : "保留 中",
  "My_transactions_status_reverted" : "戻す",
  "My_transactions_status_declined" : "減少",
  "My_transactions_norecord" : "レコードが見つかりません",
  "My_transactions_off_msg1" : "後 ",
  "My_transactions_off_msg2" : ", すべての取引の詳細は[ポイントブック]タブに公開されます",

  "Passbook_heading" : "ポイントブック",
  "Passbook_show" : "公演",
  "Passbook_entries" : "エントリー",
  "Passbook_search" : "調べる",
  "Passbook_type" : "タイプ",
  "Passbook_surveyid" : "調査",
  "Passbook_date" : "日付",
  "Passbook_selectoption" : "オプションを選択",
  "Passbook_credit" : "クレジット",
  "Passbook_debit" : "借方",
  "Passbook_survey_id" : "調査ID",
  "Passbook_survey_pts" : "調査ポイント",
  "Passbook_earned_pts" : "ポイント",
  "Passbook_pending_pts" : "保留ポイント",
  "Passbook_promo_code" : "プロモーションコード",
  "Passbook_transaction_type" : "取引タイプ",
  "Passbook_balance" : "残高",
  "Passbook_status" : "状態",
  "Passbook_type_soi" : "登録",
  "Passbook_type_doi" : "メール確認",
  "Passbook_type_profile" : "プロファイルの完了",
  "Passbook_type_survey" : "調査",
  "Passbook_type_doir" : "メール検証者",
  "Passbook_type_doirt" : "紹介によるメール検証",
  "Passbook_type_surveyr" : "調査の試行者",
  "Passbook_type_surveyrt" : "紹介による調査の試み",
  "Passbook_type_reward" : "褒賞",
  "Passbook_type_pending" : "調整",
  "Passbook_type_reverted" : "復帰ポイント",
  "Passbook_type_declined" : "拒否されたリクエスト",
  "Passbook_type_redeem" : "引き換え",
  "Passbook_type_balance" : "繰り越し",
  "Passbook_balance_vary" : "実際の残高は異なる場合があります",
  "Passbook_survey_debit_reason" : "私たちはあなたの努力を大切にします。ただし、データ転送中に制御不能な接続ドロップが発生するため、応答を登録できませんでした。この調査で以前に受け取ったポイントを逆にする必要があります。ご不便をおかけして申し訳ございません。",

  "Redeem_redeemed" : "交換",
  "Redeem_redeemhistory" : "履歴の交換",
  "Redeem_history_norecord" : "レコードが見つかりません",
  "Redeem_date" : "日付",
  "Redeem_details" : "詳細",
  "Redeem_points" : "ポイント",
  "Redeem_status" : "ステータス",
  "Redeem_lookslike" : "あなたのメールアカウントの確認を逃したように見えます。",
  "Redeem_hurry" : "急いで！あなたが確認したら1000ポイントを取得します。",
  "Redeem_pleasecheck" : "受信トレイを確認して、アカウントを有効にするために送信された確認メールのリンクをクリックしてください。",
  "Redeem_kindlycheck" : "親切に確認メールのためにもあなたのスパム/ジャンクフォルダを確認してください。",
  "Redeem_getconfirmationemail" : "確認メールがまだ届きませんでしたか?",
  "Redeem_resendconfirmationemail" : "再送信確認メール",
  "Redeem_rewardredemptionrule" : "特典交換ルール",
  "Redeem_completesurvey" : "完全な調査",
  "Redeem_earnpoints" : "ポイントを獲得",
  "Redeem_redeemrewards" : "特典交換",
  "Redeem_note" : "メモ：",
  "Redeem_yourearnedpoints" : "あなたの獲得ポイントは、調査を修飾した後に償還になります。特典を引き換えるには、アカウントの最低償還ポイントが",
  "Redeem__points" : "ポイント。",
  "Redeem_availablebalance" : "利用可能な残高:",
  "Redeem_historydetails" : "ここをクリックして、償還履歴の詳細を表示します。",
  "Redeem_reqsucc" : "おめでとう！あなたのポイントは換金されました。詳細については、登録済みメールボックスを確認してください。",
  "Redeem_wisecheck_youhave" : "おめでとうございます。あなたは報酬からワンクリック離れています。",
  "Redeem_wisecheck_gcagainst" : "ギフトカード",
  "Redeem_wisecheck_points" : "ポイント。",
  "Redeem_wisecheck_issue" : "システムは、あなたが引き換え要求を送信することができないため、あなたのアカウントでいくつかの認識されない活動をキャプチャしました。でお問い合わせください",
  "Redeem_emailverify_issue" : "ポイントを引き換えるには、登録されているEメールアカウントを確認するようにお願いします。",

  "Redeem_unsubscribe_issue" : "WisePerksの登録を解除したため、払い戻しリクエストを送信することはできません。再度購読するにはsupport@wiseperks.comまでご連絡ください。",
  "Redeem_wisecheck_message1" : "セキュリティ上の理由により償還することはできません。 サポートに連絡してください",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "確認",
  "Redeem_Cancel_button" : "キャンセル",
  "Redeem_pending_hello" : "こんにちは",
  "Redeem_pending" : "調整",
  "Redeem_pending_msg1" : "アカウントの調整が表示されます",
  "Redeem_pending_msg2" : "実行するポイント。トランザクションを開始することを確認してください。",

  "Dashboard_wisecheck_issue" : "システムがあなたのアカウントで認識できないアクティビティをいくつか取得しました。そのため、あなたはアンケートを見ることができません。詳細は、support@wiseperks.comまでお問い合わせください。",
  "Dashboard_unsubscribe_issue" : "WisePerksの登録を解除したため、アンケートは表示できません。再度調査を受けるにはsupport@wiseperks.comまでご連絡ください。",


  "WC_DashboardMsg_1" : "おっとっと！ 今のところあなたのプロフィールに一致するアンケートはありません。",
  "WC_DashboardSubMsg_1" : "心配する必要はありません。私たちは目録をシャッフルしていて、まもなく新しいアンケートをあなたのもとに送ります。",

  "WC_DashboardMsg_2" : "ダッシュボードをリフレッシュしています。 いつか新しい調査があるかどうか確認してください。",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "調査を完了していただきありがとうございます。あなただけのresponse_pointsポイントを獲得した。ダッシュボードにログインして、累積ポイントの合計を確認します。",
  "Survey_success_msg2" : "調査を完了していただきありがとうございます。あなただけの稼いだ response_points ポイント",

  "Survey_success_but_nomore_survey" : "お 祝い！あなただけの稼いだ response_points 調査を完了するためのポイント。今後の調査については、いつか確認してください。",
  
  "Survey_terminate_pnt_msg1" : "いくつかの情報のように見えますわずかに要件とは異なります。私たちはあなたの時間と労力を大切に、あなたのアカウントにresponse_pointsポイントを追加されます。我々は、より収益性の高い直感的な調査をもたらすことに焦点を当てています。",
  
  "Survey_terminate_nopnt_msg1" : "あなたの回答のいくつかが要件と少し異なるように見えます。 私たちはあなたの道にさらに調査を送ります。 あなたの時間と努力に感謝します。",

  "TermsConditions_t&c" : "ご利用条件",
  "TermsConditions_WhenRegister" : "あなたが登録するとき",
  "TermsConditions_WhenYouCreate" : "個人アカウントを作成する際には、ここに記載されているようにいくつかの個人情報をお尋ねします。",
  "TermsConditions_YouCanUpdate" : "基本プロファイル情報は、いつでも更新または変更できます。",
  "TermsConditions_YouCanOptOut" : "いつでもアカウントをオプトアウトまたは閉じることができます。",
  "TermsConditions_WeRespectYour" : "私たちは、お客様のプライバシーを尊重し、不正な物理的および電子的アクセスからお客様の情報を安全に保護するためのテクノロジーを最大限に活用します。",
  "TermsConditions_WeShareYour" : "私たちはあなたの情報をWiseWorksの操作が必要な組織とだけ共有します。また、これらの組織は、お客様のプライバシーとセキュリティへのコミットメントを遵守する必要があります。",
  "TermsConditions_WhatInformation" : "私たちがあなたから収集する情報",
  "TermsConditions_DirectlyCollected" : "直接収集",
  "TermsConditions_EmailAddressAndPassword" : "あなたのアカウントの電子メールアドレスとパスワードは私たちにあります。",
  "TermsConditions_YourResponse" : "プロファイリングの質問に対するあなたの応答。",
  "TermsConditions_AnyCustomer" : "顧客サービスの対応。",
  "TermsConditions_AnyPersonalDetails" : "償還のために共有する個人情報",
  "TermsConditions_AnyOtherInformation" : "あなたが直接提供したその他の情報。",
  "TermsConditions_UsageData" : "使用データ",
  "TermsConditions_YourIPAddress" : "あなたのIPアドレス、地理的位置とクッキー",
  "TermsConditions_InformationAboutTheDevice" : "使用するデバイスに関する情報",
  "TermsConditions_YourSurveyActivity" : "あなたの調査活動、あなたの口座内のポイント、償還後の支払い。",
  "TermsConditions_HowDoWeUse" : "データをどのように使用するのですか",
  "TermsConditions_SendingYouInvitation" : "あなたが対象とするアンケートの電子メールを送信して、あなたが対象とするアンケートを受け取ります。",
  "TermsConditions_DetectAndPrevent" : "詐欺行為を検出して防止します。",
  "TermsConditions_ImproveYourExperience" : "WiseWorksプラットフォームの経験を向上させる",
  "TermsConditions_YourResponseAreTransferred" : "お客様の回答は、提携先の第三者およびパートナーに、集約された、または識別されない形式で転送されます。",
  "TermsConditions_OurPartnersCanAsk" : "私たちのパートナーは、調査中にあなたの名前、電子メールアドレス、およびその他の連絡先情報を提供するよう依頼することができます。",
  "TermsConditions_YouAreFreeToDecline" : "WiseWorksではなく、直接この情報を提供することに同意する場合は、パートナーに詳細を提供することを拒否することは自由です。",
  "TermsConditions_YourInformationWillBe" : "お客様の情報は、WiseWorksのプライバシーポリシーではなく、関連するビジネスのプライバシーポリシーに従って処理されます。",
  "TermsConditions_OurPartnersAreCommited" : "当社のパートナーは、お客様のプライバシーを尊重し、守ることを約束します。",
  "TermsConditions_WhatWeDont" : "私たちはそれとは関係がない",
  "TermsConditions_WeDontSellOnThat" : "私たちは誰にもその情報を販売していません。私たちはあなたに何かを売るためにそれを使用しません。私たちはあなたのデータを共有する前に、常に許可を求めます。",
  "TermsConditions_YourRightToControl" : "あなたのデータを管理するあなたの権利",
  "TermsConditions_YouHaveFullControl" : "あなたはあなたのデータを完全に管理しています。",
  "TermsConditions_YouCanAccess" : "必要に応じて、基本プロファイル情報にアクセス、更新、変更することができます。",
  "TermsConditions_YouCanUnsubscribe" : "アカウントの登録を解除して無効にすることができます。",
  "TermsConditions_YouAreFreeToGetYour" : "あなたはsupport@wiseperks.comまでメールを送ってあなたのプロフィールを削除することができます。あなたの懸念を解決しようとしますが、あなたのアカウントを削除したい場合は、72時間以内にそれを実行しなければなりません。",
  "TermsConditions_DataSecurity" : "データセキュリティ",
  "TermsConditions_OurTechTeamWorks" : "当社の技術チームは、すべてのデータを安全に保つために本当に懸命に働いています。",
  "TermsConditions_WeMakeUseOfLatest" : "当社は、データの不正使用や不正アクセスを避けるため、最新の技術と対策を活用しています。ただし、オンライン環境の性質上、データセキュリティを100％保証することはできません。",
  "TermsConditions_WeTryOurBestToRule" : "私たちはリスクを排除するために全力を尽くしていますが、リスクのない環境を保証することはできません。これらの利用規約に同意することにより、お客様はこれらのリスクを承諾し、承諾します。",
  "TermsConditions_AnyQuestions" : "ご不明な点がございましたら、お気軽にお問い合わせください",
  "TermsConditions_IfYouHaveAnyQueries" : "プライバシーポリシーと手続きに関する質問や苦情がある場合。 support@wiseperks.comまでメールをお送りください.48時間以内に返信させていただきます。",

  "Validation_PleaseEnterProperDate" : "正しい生年月日を入力してください",
  "Validation_PleaseSelectOne" : "1つのオプションを選択してください！",
  "Validation_PleaseSelectValidOptions" : "有効なオプションを選択してください！",
  "Validation_PleaseSelectAtLeaseOneOption" : "少なくとも1つのオプションを選択してください",
  "Validation_PleaseInsertTheZip" : "郵便番号を挿入してください。",

  "Releases_releases" : "リリース",
  "Releases_theUseOfThis" : "本ウェブサイトのご利用には、以下の利用規約が適用されます。",
  "Releases_featuresOfWP" : "WP のリリース",
  "Releases_updatedOn" : "の機能を更新",
  "Releases_WisePerksNow" : "WisePerks は複数の言語をサポートするようになりました",
  "Releases_youCanWalkThourgh" : "あなたは WisePerks があなたの選ばれた言語で利用可能であるとして、はるかに簡単にサイトを thourgh 歩くことができます。",
  "Releases_languagesAvailable" : "利用可能な言語:",
  "Releases_EarnMore" : "私たちの新しいポイントシステムでより多くを獲得",
  "Releases_theNewWisePerksAccumulates" : "新しい WisePerks はアカウントにポイントを蓄積します。これらのポイントは、はるかに高い値を保持し、獲得し、償還するのは簡単です。から選択する複数の交換オプションを使用すると、我々はあなたが経験を取って調査をお楽しみいただけますことを確認しています。",
  "Releases_accumulatePoints" : "安価な償還でポイントを貯める",
  "Releases_youGetTheBenefit" : "あなたは同じ no を獲得の利益を得る。を交換する通貨に関係なく、ポイントの",
  "Releases_surveyDisqualification" : "調査失格はこれ以上悪い知らせではない",
  "Releases_youEarnPoints" : "あなたが調査の対象としない場合でも、ポイントを獲得、あなたが答え始めた。",
  "Releases_newAndImproved" : "新機能と改良されたレイアウト",
  "Releases_theFreshLookOf" : "WisePerks の新鮮な外観は、あなたがそれと恋に落ちるようになります。今でははるかに使いやすく、あなたのための素晴らしい経験になるだろう。",
  "Releases_whatAvatarAreYouToday" : "どのようなアバターは、今日です",
  "Releases_youCanNowCreate" : "これで、ダッシュボードに機能する独自のアバターを作成することができます。",
  "Releases_freshAndHotSurveys" : "ダッシュボードでの新鮮で熱い調査",
  "Releases_youDontHaveTo" : "新しいアンケートを表示するためにダッシュボードの refresing を心配する必要はありませんが、ダッシュボードに最新のアンケートを自動的に入力しておくことができます。",
  "Releases_promoCode" : "プロモーションコード機能",
  "Releases_promoCodeInfo" : "人気の要求に応じて、WisePerksは毎週そのパネリストのためにプロモコードをもたらします。より多くの報酬をつかむための素早い方法。",
  "Releases_referFriend" : "友達に紹介する",
  "Releases_referFriendInfo" : "友達をWisePerksに関連付けてもらってエキサイティングな報酬をゲットしてください。アカウントの[友達を紹介]オプションを探します。",

  "Dashboard_Promocode_Usepromo" : "プロモーションコードを使用する",
  "Dashboard_Promocode_to" : "宛先",
  "Dashboard_Promocode_earnminimum_1" : "最低稼ぐ",
  "Dashboard_Promocode_earnminimum_2" : "追加ポイント",
  "Dashboard_Promocode_minimum" : "最小",
  "Dashboard_Promocode_minimumextrapoints" : "今日完了したすべての調査の追加ポイント",
  "Dashboard_Promocode_apply" : "適用",
  "Dashboard_Promocode_message1" : "プロモーションコードが無効です！",
  "Dashboard_Promocode_message2" : "おめでとうございます！あなたは最小限になります",
  "Dashboard_Promocode_message3" : "今日完了した各調査の追加！",

  "Dashboard_modal_Enjoythefestive" : "あなたが今日完了するすべての調査で最低",
  "Dashboard_modal_Extrapointson" : "の余分なポイントを獲得することによってお祝いの精神を楽しみます。",

  "refer_friend_title" : "友達を紹介して報酬を受け取る",
  "refer_friend_step_first": "WisePerksに友達を紹介する",
  "refer_friend_step_second": "友達がWisePerksに登録する",
  "refer_friend_step_third": "each_referralポイントもらえます",
  "refer_friend_link_title": "今すぐ友達を招待しましょう！",
  "refer_friend_share_title": "リンクを共有する",
  "refer_friend_guideline_title": 'どのように「友達に紹介する」ことがあなたにとって有益なのでしょうか。',
  "refer_friend_guideline_one": "上記のリンクを紹介者と共有してください。",
  "refer_friend_guideline_two": "5人の紹介者が自分のアカウントを確認して調査活動を開始すると、獲得した紹介ポイントが自分のアカウントに追加されます。",
  "refer_friend_guideline_three": "あなたは成功した（確認されたEメールアカウントと調査活動で）紹介に対してそれぞれeach_referralポイントを得ます。",
  "refer_friend_guideline_four": "ポイントは2段階で獲得されます。電子メールの確認でpartial_referral、紹介による調査活動でpartial_second_referralです。",
  "refer_friend_guideline_five": "（確認された電子メールアカウントと調査アクティビティを含む）紹介が5回成功すると、紹介リンクは無効になります。",

  }
  
  
