import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PanelistService } from '../../../shared/panelist.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profiling-questions',
  templateUrl: './profiling-questions.component.html',
  styleUrls: ['./profiling-questions.component.css'],
  providers: [DatePipe]
})
export class ProfilingQuestionsComponent implements OnInit {

  @Output() DisabledCategoryEvent = new EventEmitter();
  @Output() PercentageCategoryWise = new EventEmitter();
  @Output() NextCategoryEvent = new EventEmitter();
  @Output() StopQuestionLoader = new EventEmitter(); // page loader
  @Output() CompletingProfile = new EventEmitter();
  @Input() ProfilingPts;


  mainArr = [];
  quesFetchArr = [8,9,10,11,12,13,14,15,16,17,18,19,20];
  disabledCatArr = [];
  cat_wise_per = [];
  ansJson = [];
  ansOptArr = [];
  mschecked: string[] = []; // for multiselectbox
  ms_selected_checked = []; // checkbox value selected answer show, if ans exists

  save_continue_button_show : boolean;
  submit_button_text:string;

  i:number = 0;   // each category first index of arr
  CurrentCategory:string;
  question_label:string = "";
  answer_label:any;
  ProfileCompleted:number;
  ProcessStart:number=0;
  qloadstart:boolean=false;
  bloadstart:boolean = false;


  agebox:boolean = false;
  radiobox:boolean = false;
  multiselectbox:boolean = false;
  inputbox:boolean = false;
  selectbox:boolean = false;

  categoryThanku:boolean =false;
  overallThanku:boolean = false;
  hideContinueButton:boolean = false;

  years = Array<Years>();
  months = Array<Months>();
  days = Array<Days>();

  /* fetched ans variables */
  dob_month = "";
  dob_day = "";
  dob_year = "";
  radio_answer:string = "";
  errorMsg:string;
  dropdownfirstOption:string;
  selectedDropdown:string="";
  insertedZip:string;
  PanelistDob:string;   // for Update Api panelist time
  PanelistGender:string; // for Update Api panelist time
  
  HeadingCurrentCategory:string;
  CategoryThankuMsg_1:string;
  CategoryThankuMsg_2:string;
  CategoryThankuMsg_3:any;
  CategoryThankuMsg_4:string;
  
  OverallThankuMsg:string;
  
  constructor(private _panelistService: PanelistService, private toastr: ToastrService, private datePipe: DatePipe) {
    this.months = [{label:'demography_jan', value:"01"},{label:"demography_feb", value:"02"},{label:"demography_mar", value:"03"},{label:"demography_apr", value:"04"},
        {label:"demography_may", value:"05"}, {label:"demography_jun", value:"06"},{label:"demography_jul", value:"07"},{label:"demography_aug", value:"08"},{label:"demography_sep", value:"09"},
        {label:"demography_oct", value:"10"},{label:"demography_nov", value:"11"}, {label:"demography_dec", value:"12"}];

        let PanelistId = localStorage.getItem('currentLoginPanelist');

        this._panelistService.ProfilingQuestion(PanelistId).subscribe((resp:any) => {
          if(resp.auth == true && resp.success == true){
            this.RegenrateMainJson(resp,1);
            this.StopQuestionLoader.emit({comp:'questioning', flag:1});
          }
        },(err:any) => {
          this.StopQuestionLoader.emit({comp:'questioning', flag:1});
          this.showNewFlashError(err,"WisePerks");
        });
  }

  ngOnInit() {
  }

  changeCategory(get_category_name){
    this.i = 0;
    let getCategoryName = get_category_name.charAt(0).toUpperCase() + get_category_name.substr(1);
    this.CurrentCategory = getCategoryName;
    this.HeadingCurrentCategory = getCategoryName;
    if(this.HeadingCurrentCategory == 'General'){
      this.HeadingCurrentCategory = "Profiling_cat_general";
    } else if(this.HeadingCurrentCategory == 'Personal'){
      this.HeadingCurrentCategory = "Profiling_cat_personal";
    } else if(this.HeadingCurrentCategory == 'Household'){
      this.HeadingCurrentCategory = "Profiling_cat_household";
    } else if(this.HeadingCurrentCategory == 'Profession'){
      this.HeadingCurrentCategory = "Profiling_cat_profession";
    }
    this.GenerateNextQuestion();
  }

  GenerateNextQuestion(){ //console.log(this.i);

    this.ProcessStart = 0;
    this.qloadstart = false;
    this.bloadstart = false;
    this.question_label = this.mainArr[this.CurrentCategory][this.i]['q_txt'];
    this.AnswerBoxOpen();
    this.answer_label = this.mainArr[this.CurrentCategory][this.i]['opt'];
    if(this.mainArr[this.CurrentCategory][this.i]['q_type'] == 6){
      this.AnswerLabelOption(); 
     }
    this.SelectedAnswer();
    this.ButtonShow(); // save & continue
  }

  AnswerLabelOption(){
    if(this.mainArr[this.CurrentCategory][this.i]['q_type'] == 6){
      let get_qid = this.mainArr[this.CurrentCategory][this.i]['q_id'];

      if(get_qid == 3){
        this.dropdownfirstOption = "DMA";
      } else if(get_qid == 4){
        this.dropdownfirstOption = "State";
      } else if(get_qid == 5){
        this.dropdownfirstOption = "Division";
      } else if(get_qid == 6){
        this.dropdownfirstOption = "Region";
      }
    }
  }

  // below function is show the selected answer
  SelectedAnswer(){

    // Intially we do blank all the variable
    this.dob_month = "";
    this.dob_day = "";
    this.dob_year = "";
    this.radio_answer = "";
    
    let question_type = this.mainArr[this.CurrentCategory][this.i]['q_type'];
    let question_id = this.mainArr[this.CurrentCategory][this.i]['q_id'];
    
    if(question_type == 3){ // for age question
      this.SetDob();
    } else if (question_type == 1){ // for radio button
      if("panelist_ans" in this.mainArr[this.CurrentCategory][this.i]){
        let panelist_ans_arr_radio = this.mainArr[this.CurrentCategory][this.i]['panelist_ans'];
        
        if(typeof panelist_ans_arr_radio !== 'undefined' && panelist_ans_arr_radio.length > 0){
          let selected_panelist_radio_ans = panelist_ans_arr_radio[0]['opt_id'];
          this.radio_answer = String(selected_panelist_radio_ans);
        }
      }
      
    } else if (question_type == 2){ // for multicheckbox button
      this.ms_selected_checked = [];

      if("panelist_ans" in this.mainArr[this.CurrentCategory][this.i]){
        let selectedArrlength = this.mainArr[this.CurrentCategory][this.i]['panelist_ans'].length;
        
        for(var j=0; j<selectedArrlength; j++){
          this.ms_selected_checked.push(this.mainArr[this.CurrentCategory][this.i]['panelist_ans'][j]['opt_id']);
          // below line update time we check is already exists then we push with last values
          this.mschecked.push(this.mainArr[this.CurrentCategory][this.i]['panelist_ans'][j]['opt_id']);
        }
      }

    } else if (question_type == 6){
      this.selectedDropdown = "";
      if("panelist_ans" in this.mainArr[this.CurrentCategory][this.i]){
        let panelist_ans_arr = this.mainArr[this.CurrentCategory][this.i]['panelist_ans'];
        if(typeof panelist_ans_arr !== 'undefined' && panelist_ans_arr.length > 0){
          this.selectedDropdown = this.mainArr[this.CurrentCategory][this.i]['panelist_ans'][0]['opt_id'];
        }
      }

    } else if(question_type == 4){
      if("panelist_ans" in this.mainArr[this.CurrentCategory][this.i]){
        let panelist_ans_arr_input = this.mainArr[this.CurrentCategory][this.i]['panelist_ans'];
        if(typeof panelist_ans_arr_input !== 'undefined' && panelist_ans_arr_input.length > 0){
          this.insertedZip = panelist_ans_arr_input[0]['opt_txt'];
        }
      }
    }
  }


  ClickNextButton(form: NgForm){
    
    if(this.ProcessStart == 0){
      this.ProcessStart = 1; // not submitt multiple answer
      this.qloadstart = true; // loader start
      this.bloadstart = true; // button loader start
      let question_status = this.AnswerValidation(form.value);

      if(question_status == true){
        this.SaveProfilingAnswer(form.value);
      }
    }
    
  }


  AnswerValidation(FormValue){

    this.errorMsg = "";

    let get_question_type = this.mainArr[this.CurrentCategory][this.i]['q_type'];
    let get_question_id = this.mainArr[this.CurrentCategory][this.i]['q_id'];
    
    var statusflag = false;

    if(get_question_type == 3){ // validation for age
      
      if(FormValue.dobMonth != "" && FormValue.dobDay != "" && FormValue.dobYear != ""){
        let month_val_exists = this.ValueExistsArray(FormValue.dobMonth,this.months,'value');
        let day_val_exists = this.ValueExistsArray(FormValue.dobDay,this.days,'value');
        let year_val_exists = this.ValueExistsArray(FormValue.dobYear, this.years,'label');

        if((month_val_exists == true) && (day_val_exists == true) && (year_val_exists == true)){
          statusflag = true;
        } else {
          this.errorMsg = "Validation_PleaseEnterProperDate";
          this.ProcessStart = 0;
          this.qloadstart = false;
          this.bloadstart = false;
        }
      } else {
        this.errorMsg = "Validation_PleaseEnterProperDate";
        this.ProcessStart = 0;
        this.qloadstart = false;
        this.bloadstart = false;
      }

    } else if(get_question_type == 1){ // radio ans validation
      if(FormValue.radio_val != "" && FormValue.radio_val != null){
        let optionArr = this.mainArr[this.CurrentCategory][this.i]['opt'];
        let radio_val_exists = this.ValueExistsArray(FormValue.radio_val,optionArr,"opt_id");
        
        if(radio_val_exists == true){
          statusflag = true;
        }
      } else {
        this.errorMsg = "Validation_PleaseSelectOne";
        this.ProcessStart = 0;
        this.qloadstart = false;
        this.bloadstart = false;
      }

    } else if(get_question_type == 2){  // for multiselect radio button
      
      var checkbox_value__exist = false;

      if(this.mschecked.length > 0){
        
        let optionArr = this.mainArr[this.CurrentCategory][this.i]['opt'];

        for(var x=0; x < this.mschecked.length; x++){

          let checkbox_val = this.mschecked[x]; 
          let Ischeckbox_val_exists = this.ValueExistsArray(checkbox_val,optionArr,"opt_id");
          
          if(Ischeckbox_val_exists == false){
            checkbox_value__exist = false;
            break;
          } else {
            checkbox_value__exist = true;
          }
        }

        if(checkbox_value__exist == true){
          //now do blank the mschecked, becoz we used for nxt multiselect question

          while(this.mschecked.length > 0) {
            this.mschecked.pop();
          }
          statusflag = true;

        } else {
          this.errorMsg = "Validation_PleaseSelectValidOptions";
          this.ProcessStart = 0;
          this.qloadstart = false;
          this.bloadstart = false;
        }
      } else {
        this.errorMsg = "Validation_PleaseSelectAtLeaseOneOption";
        this.ProcessStart = 0;
        this.qloadstart = false;
        this.bloadstart = false;
      }

    } else if(get_question_type == 6) {  // validation for dropdown options
      if(FormValue.dropdown_ans != "" && FormValue.dropdown_ans != null){
        let optionArr = this.mainArr[this.CurrentCategory][this.i]['opt'];
        let select_val_exists = this.ValueExistsArray(FormValue.dropdown_ans,optionArr,"opt_id");
        
        if(select_val_exists == true){
          statusflag = true;
        } else {
          this.errorMsg = "Please Select Valid Option!";
          this.ProcessStart = 0;
          this.qloadstart = false;
          this.bloadstart = false;
        }
      } else {
        this.errorMsg = "Validation_PleaseSelectOne";
        this.ProcessStart = 0;
        this.qloadstart = false;
        this.bloadstart = false;
      }

    } else if(get_question_type == 4){ // for input like zipcode
      if(FormValue.zipcode != "" && FormValue.zipcode != null && FormValue.zipcode !== 'undefined'){
        statusflag = true;
      } else {
        this.errorMsg = "Validation_PleaseInsertTheZip";
        this.ProcessStart = 0;
        this.qloadstart = false;
        this.bloadstart = false;
      }
    }

    return statusflag;
  }


  updatedChecked(optionid,event){
    var index = this.mschecked.indexOf(optionid);

    if(event.target.checked){
      if(index === -1){
        this.mschecked.push(optionid);
      }
    } else {
      if(index !== -1){
        this.mschecked.splice(index, 1);
      }

    }

    //console.log(this.mschecked);
  }

  msSelectedAns(get_opt_id){
    //console.log(this.ms_selected_checked);
    
    if(this.ms_selected_checked.indexOf(get_opt_id) !== -1){ 
      return true;
    } else {
      return false;
    }
  }

  MultiSelectCheckboxRequired(){
    if(this.mschecked.length > 0){
      return false;
    } else { 
      return true;
    }
  }


  SaveProfilingAnswer(FormValue){
    let saveflag = false;

    let PanelistId = localStorage.getItem('currentLoginPanelist');

    this.ansJson = Object.assign({}, this.mainArr);

    this.ansJson = JSON.parse(JSON.stringify(this.ansJson));

    let questype = this.mainArr[this.CurrentCategory][this.i]['q_type'];
    let quesId = this.mainArr[this.CurrentCategory][this.i]['q_id'];

    this.ansOptArr = [];

    if(questype == 3){ // for age
      let panelist_age_ans = FormValue.dobMonth+'-'+FormValue.dobDay+'-'+FormValue.dobYear;      
      this.ansOptArr = [{'opt_id':"", 'opt_txt': panelist_age_ans}];

    }  else if(questype == 1){ // for radio
      let get_opt_txt_arr = this.mainArr[this.CurrentCategory][this.i]['opt'];
      let opt_txt ="";

      for(var v=0; v < get_opt_txt_arr.length; v++){
        if(get_opt_txt_arr[v].opt_id == FormValue.radio_val){
          opt_txt = get_opt_txt_arr[v].opt_txt;
        }
      }
      let radio_opt_id = Number(FormValue.radio_val);
      this.ansOptArr = [{'opt_id':radio_opt_id,'opt_txt': opt_txt}];
    
    } else if(questype == 2){ // for multiselect
      var objcount = Object.keys(FormValue).length;

      for(var m = 1; m <= objcount; m++){      
        if(FormValue['checkbox_val_'+m] == true){
          var ms_val_json = this.answer_label[m-1];
          
          this.ansOptArr.push(ms_val_json);
        }
      }

    } else if(questype == 6){ // for select box
      let get_opt_txt_arr = this.mainArr[this.CurrentCategory][this.i]['opt'];
      let opt_txt ="";

      for(var v=0; v < get_opt_txt_arr.length; v++){
        if(get_opt_txt_arr[v].opt_id == FormValue.dropdown_ans){
          opt_txt = get_opt_txt_arr[v].opt_txt;
        }
      }

      let dropdown_opt_id = Number(FormValue.dropdown_ans);
      this.ansOptArr = [{'opt_id':dropdown_opt_id,'opt_txt': opt_txt}];
      
    } else if(questype == 4){
      if(FormValue.zipcode != "" && FormValue.zipcode != null && FormValue.zipcode !== 'undefined'){
        this.ansOptArr = [{'opt_id':"", 'opt_txt': FormValue.zipcode}];
      }
    }

    this.ansJson[this.CurrentCategory][this.i]['opt'] = this.ansOptArr;

    if(this.ansJson[this.CurrentCategory][this.i]['panelist_ans']){
      delete this.ansJson[this.CurrentCategory][this.i]['panelist_ans'];
    }

    //console.log(this.ansJson);
    //console.log(this.mainArr);
    var formDataVal = this.ansJson[this.CurrentCategory][this.i];

    this._panelistService.SavePanelistProfilingAns(PanelistId, formDataVal).subscribe((resp:any) => {
       if(resp.success == true && resp.auth == true){
         this.RegenrateMainJson(resp,2);
       }
    }, (err:any)=>{
      this.showNewFlashError(err,"WisePerks");
    });
  }

  RegenrateMainJson(resp,disableflag){
    
    this.mainArr = [];

    let question_length = resp.data.data.length;

    this.ProfileCompleted = resp.data.step;

    let a = 0; let b= 0; let c = 0; let d = 0;

    let g = 0; let h = 0; let p = 0; let y = 0;
    let g_a = 0; let h_a = 0; let p_a = 0; let y_a = 0;

    for(let k = 0; k < question_length; k++ ){

      /*Below code generate mainJson, start here */
        var cat_name = resp.data.data[k].cat_txt;
        var ques_id = resp.data.data[k].q_id;

        var isPanelistAnsExists = false;

        if("panelist_ans" in resp.data.data[k]){
            isPanelistAnsExists = true;
        }
        

        /*Below code calculate percentage scroll bar, start here */
        if(cat_name == 'GENERAL' || cat_name == 'AUTOPUNCH'){
          if(!this.cat_wise_per['General']){
            this.cat_wise_per['General'] = [];
          }
          this.cat_wise_per['General']['no_of_ques'] = ++g;

          if('panelist_ans' in resp.data.data[k]){
            this.cat_wise_per['General']['no_of_ans'] = ++g_a;
          }

        } else if(cat_name == "BASIC"){
          if(!this.cat_wise_per['Personal']){
            this.cat_wise_per['Personal'] = [];
          }
          this.cat_wise_per['Personal']['no_of_ques'] = ++p;

          if('panelist_ans' in resp.data.data[k]){
            this.cat_wise_per['Personal']['no_of_ans'] = ++p_a;
          }

        } else if(cat_name == "HOUSEHOLD"){
          if(!this.cat_wise_per['Household']){
            this.cat_wise_per['Household'] = [];
          }
          this.cat_wise_per['Household']['no_of_ques'] = ++h;

          if('panelist_ans' in resp.data.data[k]){
            this.cat_wise_per['Household']['no_of_ans'] = ++h_a;
          }

        } else if(cat_name == "BUSINESS"){
          if(!this.cat_wise_per['Profession']){
            this.cat_wise_per['Profession'] = [];
          }
          this.cat_wise_per['Profession']['no_of_ques'] = ++y;
          
          if('panelist_ans' in resp.data.data[k]){
            this.cat_wise_per['Profession']['no_of_ans'] = ++y_a;
          }
        }

      /*Above code calculate percentage scroll bar, start here */


        /* Below Code is Set Dob & gender for update time api */
        if(ques_id == 1 || ques_id == 2){
          if('panelist_ans' in resp.data.data[k]){
            let  type_panelist_ans = resp.data.data[k]['panelist_ans'];
            if(typeof type_panelist_ans !== 'undefined' && type_panelist_ans.length > 0){
              if(ques_id == 1){
                this.PanelistDob = type_panelist_ans[0]['opt_txt'];
              } else {
                this.PanelistGender = type_panelist_ans[0]['opt_txt'].toLowerCase();
              }
            }
          }
        }
      /* Above Code is Set Dob & gender for update time api */

        if("panelist_ans" in resp.data.data[k]){
          if(resp.data.step == 1 ){ // @Nilesh (If panelist leave questions in between then we are directly switching to not answered question)
            continue;
          }
        }

        if(this.quesFetchArr.indexOf(ques_id) !== -1 || isPanelistAnsExists == false){
          if(cat_name == 'GENERAL' || cat_name == 'AUTOPUNCH'){ 

            if(!this.mainArr['General']){
              this.mainArr['General'] = [];
            }
            this.mainArr['General'][a] = resp.data.data[k];
            a++;

          } else if(cat_name == 'AUTOPUNCH' && ques_id == 4){  // for state question only and assign in general category

            if(!this.mainArr['General']){
              this.mainArr['General'] = [];
            }
            this.mainArr['General'][a] = resp.data.data[k];
            a++;

          } else if(cat_name == 'BASIC'){
            
              if(!this.mainArr['Personal']){
                this.mainArr['Personal'] = [];
              }
              this.mainArr['Personal'][b] = resp.data.data[k];
              b++;

          } else if(cat_name == "HOUSEHOLD"){
            
              if(!this.mainArr['Household']){
                this.mainArr['Household'] = [];
              }
              this.mainArr['Household'][c] = resp.data.data[k];
              c++;

          } else if(cat_name == "BUSINESS"){
            
              if(!this.mainArr['Profession']){
                this.mainArr['Profession'] = [];
              }
              this.mainArr['Profession'][d] = resp.data.data[k];
              d++;
          }
        }

      
    }
    

    if(!this.mainArr['General']){ 
      this.disabledCatArr.push('General');
    }

    if(!this.mainArr['Personal']){ 
      this.disabledCatArr.push('Personal');
    }

    if(!this.mainArr['Household']){ 
      this.disabledCatArr.push('Household');
    }

    if(!this.mainArr['Profession']){ 
      this.disabledCatArr.push('Profession');
    }
    
    this.PercentageCategoryWise.emit(this.cat_wise_per);
    this.DisabledCategoryEvent.emit({arr: this.disabledCatArr, flag: disableflag});
    

    //console.log('Step='+resp.data.step); console.log("Disabled flag="+disableflag);
    var isPanelistAllAnsExists = this.isPanelistAllAnsExists();
    if(resp.data.step == 1 && disableflag == 2 && isPanelistAllAnsExists == true){   // Last Thank you page
      
      this.qloadstart = false;
      this.bloadstart = false;
      this.OverAllThankyou();
      this.i = 0;
      return false;
    }

    /* Below Code is Commented and Modify, after change the by backend json via Nilesh */
      /*if(disableflag==2){
        this.Increment_I();
      }*/

      if(this.CurrentCategory in this.mainArr){
        if(disableflag == 2){
          this.Increment_I();
        }
      } else {   // special case
        this.qloadstart = false;
        this.bloadstart = false;
        this.CategoryThanku();
        this.i = 0;
      }

    /* Above Code is Commented and Modify, after change the by backend json via Nilesh */



    if(this.ProfileCompleted == 2){
      this.CategoryThankuMsg_1 = "Category_thanku_update_msg1";
      this.CategoryThankuMsg_2 = "Category_thanku_update_msg2";
      this.CategoryThankuMsg_3 = "";
      this.CategoryThankuMsg_4 = "Category_thanku_update_msg3";
    } else {
      this.CategoryThankuMsg_1 = "Category_thanku_msg1";
      this.CategoryThankuMsg_2 = "Category_thanku_msg2";
      this.CategoryThankuMsg_3 = this.ProfilingPts;
      this.CategoryThankuMsg_4 = "Category_thanku_msg3";
    }

  }

  Increment_I(){
    /* Below code is written becoz json change and its removed question, so first we check i val*/
    // console.log('I1='+this.i);
    // console.log("I1="+this.i);
    // console.log(this.mainArr);

    
    if(this.i in this.mainArr[this.CurrentCategory]){
      if('panelist_ans' in this.mainArr[this.CurrentCategory][this.i]){
        this.i += 1;
      }
    }
    
    
    //console.log('I2='+this.i);

    let get_is_last_ans = this.IsLastAnswer();
    
    if(this.i == this.mainArr[this.CurrentCategory].length){
      this.qloadstart = false;
      this.bloadstart = false;
      this.CategoryThanku();
      this.i = 0;
    } else { 
      this.GenerateNextQuestion();
    }

  }

  /* Below function for age fetching, start here */

    //if panelist already give answer then we set his answer by below function

    SetDob(){
      if('panelist_ans' in this.mainArr[this.CurrentCategory][this.i]){

        let panelist_age = this.mainArr[this.CurrentCategory][this.i]['panelist_ans'][0]['opt_txt'];

        if(panelist_age !="" && panelist_age != null){

          var dob_date = panelist_age.split('-');
          this.dob_month = dob_date[0];

          this.showDates(dob_date[0]); // first get the date then fetch
          this.dob_day = dob_date[1];
          
          this.showYears(dob_date[1], dob_date[0]);
          this.dob_year = dob_date[2];
        }
      }
    }

    showDates(getmonths){
      this.dob_day = "";
      this.days = [];
      this.dob_year = "";
      this.years = [];

      if(getmonths){
        let loopflow = 31;

        if(getmonths == "04" || getmonths == "06" || getmonths == "09" || getmonths == "11"){
          loopflow = 30;
        } else if (getmonths == "02") {
          loopflow = 29;
        }

        let j:string = "";

        for(var i=1; i<=loopflow; i++){

            if(i <= 9){
              j = "0"+i;
            } else {
              j = "" + i +"";
            }

          this.days.push(new Days(j,j));
        }
      }
    }

    showYears(getdate, getMonthId){
      this.dob_year = "";
      this.years = [];

      var date = new Date();
      var year = date.getFullYear();
      var start = year - 13;
      var count = year - 99;

      for (var i = start; i >= count; i--){

        if(getdate == 29 && getMonthId == '02'){
          var leap = !((i % 4) || (!(i % 100) && (i % 400)));
          
          if(leap === true){
            this.years.push(new Years(i));
          }
        } else {
          this.years.push(new Years(i));
        }
      }
    }

    
  /* Above function for age fetching, end here */


  ValueExistsArray(getVal,getArr,getKey){
    let ArrLength = getArr.length;
    
    var flagExists = false;
    for(var l=0; l < ArrLength; l++){
      if(getVal == getArr[l][getKey]){
        flagExists = true;
        break;
      }
    }
    return flagExists;
  }

  CategoryThanku(){
    this.agebox = false;
    this.radiobox = false;
    this.inputbox = false;
    this.selectbox = false;
    this.multiselectbox = false;
    this.save_continue_button_show = false;
    this.categoryThanku = true;
    this.question_label = "";
  }

  TransferNextCategory(){

    let nxtCategory = "";
    if(this.CurrentCategory == 'General'){
      
      if(this.NextCategoryEligible('Personal') == true){
        nxtCategory = 'Personal';  
      } else if(this.NextCategoryEligible('Household') == true){
        nxtCategory = 'Household'
      } else if(this.NextCategoryEligible('Profession') == true){
        nxtCategory = "Profession";
      }

    } else if(this.CurrentCategory == 'Personal'){
      
      if(this.NextCategoryEligible('Household') == true){
        nxtCategory = 'Household';  
      } else if(this.NextCategoryEligible('Profession') == true){
        nxtCategory = 'Profession'
      } else if(this.NextCategoryEligible('General') == true){
        nxtCategory = "General";
      }

    } else if(this.CurrentCategory == 'Household'){
      if(this.ProfileCompleted == 2) { // Not showing continue button on last category question update (@Nilesh)
        this.hideContinueButton = true;
      }
      
      if(this.NextCategoryEligible('Profession') == true){ 
        nxtCategory = 'Profession';  
      } else if(this.NextCategoryEligible('General') == true){
        nxtCategory = 'General'
      } else if(this.NextCategoryEligible('Personal') == true){
        nxtCategory = "Personal";
      }

    } else if(this.CurrentCategory == 'Profession'){
      if(this.NextCategoryEligible('General') == true){
        nxtCategory = 'General';  
      } else if(this.NextCategoryEligible('Personal') == true){
        nxtCategory = 'Personal'
      } else if(this.NextCategoryEligible('Household') == true){
        nxtCategory = "Household";
      }
    }
   
   if(nxtCategory != ""){
    this.CurrentCategory = nxtCategory;
    this.categoryThanku = false;
    this.NextCategoryEvent.emit(nxtCategory);
   } 
   // else { // Added By Nilesh
   //    this.hideContinueButton = true;
   //    // this.OverAllThankyou();
   // }
    
  }

  NextCategoryEligible(getCatName){
    //this.disabledCatArr.push('Profession');
    var status_flag = false;
  
    if(this.disabledCatArr.indexOf(getCatName) == -1){ // not exists in disable arr, mns nxt catg is active
      
      if(this.ProfileCompleted == 1){ // for step = 1
        let NxtCatArrLength = this.mainArr[getCatName].length;
        
        for(var c=0; c < NxtCatArrLength; c++){
          if('panelist_ans' in this.mainArr[getCatName][c]){
            status_flag = false;
          } else {
            status_flag = true;
            break;
          }
        }
      } else { // for step =2 (at time of update)
        status_flag = true;
      }
    }

    return status_flag;
  }

  ButtonShow(){
    // a) Save & Continue for every question
    // b) Save for last question
    // c) Update for if all answergive by panelist and step value = 2
    
    var Is_last_ans = this.IsLastAnswer();
        
    if(this.ProfileCompleted == 2){
      this.submit_button_text = "Profiling_update_ans_button";
    } else if(this.ProfileCompleted == 1 && Is_last_ans == true){
      this.submit_button_text = "Profiling_save_ans_button";
    } else {
      this.submit_button_text = "Profiling_sc_ans_button";
    }
    this.save_continue_button_show = true;
  }

  IsLastAnswer(){
    /* find the last ans is remaning of ques */
    let catArr = this.cat_wise_per;
    let total_no_of_ques:number = 0;
    let total_no_of_ans:number = 0;

    for(let categ_arr in catArr){
      if('no_of_ques' in catArr[categ_arr]){
        total_no_of_ques += catArr[categ_arr]['no_of_ques'];
      }

      if('no_of_ans' in catArr[categ_arr]){
        total_no_of_ans += catArr[categ_arr]['no_of_ans'];
      }
    }
    
    var isLastAns = ((total_no_of_ques - total_no_of_ans == 1) ? true : false);

    return isLastAns;
  }

  isPanelistAllAnsExists(){ //Mns Panelist Completed his Profile

    let CatgArr = this.cat_wise_per;
    let total_no_ques:number = 0;
    let total_no_ans:number = 0;

    for(let categArr in CatgArr){
      if('no_of_ques' in CatgArr[categArr]){
        total_no_ques += CatgArr[categArr]['no_of_ques'];
      }

      if('no_of_ans' in CatgArr[categArr]){
        total_no_ans += CatgArr[categArr]['no_of_ans'];
      }
    }

    var isPanelistProfileComplete = ((total_no_ques == total_no_ans) ? true : false);
    return isPanelistProfileComplete;

  }

  OverAllThankyou(){

    /*var data = {gender: this.PanelistGender, dob: this.PanelistDob, step:2};*/
    var panelistDataDOB = this.datePipe.transform(this.PanelistDob, 'yyyy-MM-dd');
    var data = "gender="+this.PanelistGender+"&dob="+panelistDataDOB+"&step=2";

    this._panelistService.PanelistUpdate(data).subscribe((resp:any) => {
      if(resp.success == true && resp.auth == true){
        if(resp.data.step == 2){
          //console.log(resp);
          this.CompletingProfile.emit({main_balance : resp.data.points, life_time_points : resp.data.lifetime_points, update_profile_menu : 1, hide_banner : 0});
        }
      }
    },(err:any)=>{
      this.showNewFlashError(err,"WisePerks");
    });

    this.agebox = false;
    this.radiobox = false;
    this.inputbox = false;
    this.selectbox = false;
    this.multiselectbox = false;
    this.save_continue_button_show = false;
    this.categoryThanku = false;
    this.question_label = "";
    this.answer_label = "";
    this.overallThanku = true;
  }

  AnswerBoxOpen() {

    this.categoryThanku = false; // remove the msg page
    this.overallThanku = false;

    let question_type = this.mainArr[this.CurrentCategory][this.i]['q_type'];
    let qid = this.mainArr[this.CurrentCategory][this.i]['q_id'];
    
    if(question_type == 1){
      this.agebox = false;
      this.multiselectbox = false;
      this.inputbox = false;
      this.selectbox = false;
      this.radiobox = true;
    } else if (question_type == 2) {
      this.agebox = false;
      this.radiobox = false;
      this.inputbox = false;
      this.selectbox = false;
      this.multiselectbox = true;
    } else if(question_type == 3){
      this.radiobox = false;
      this.inputbox = false;
      this.multiselectbox = false;
      this.selectbox = false;
      this.agebox = true;
    } else if( question_type == 4){
      this.radiobox = false;
      this.multiselectbox = false;
      this.agebox = false;
      this.selectbox = false;
      this.inputbox = true;
    } else if(question_type == 6){  // only for state question
      this.radiobox = false;
      this.multiselectbox = false;
      this.agebox = false;
      this.inputbox = false;
      this.selectbox = true;
    }
  }


  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}


export class Years {
  constructor(label:number){
    this.label = label;
  }
  label:number;
}

export class Months {
  constructor(label:string,value:string){
    this.label = label;
    this.value = value;
  }
  label: string;
  value: string;
}

export class Days {
  constructor(label:string,value:string){
    this.label = label;
    this.value = value;
  }
  label: string;
  value: string;
}


