import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgForm } from '@angular/forms';
import {IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { ToastrService } from 'ngx-toastr';
import { PanelistService } from '../../shared/panelist.service';
import { Title } from '@angular/platform-browser';
import * as myLangGlobal from '../../shared/globals';
 
 @Component({
  selector: 'app-survey-history',
  templateUrl: './survey-history.component.html',
  styleUrls: ['./survey-history.component.css'],
  animations: [
   trigger('flyInOut', [ 
   
    transition('void => *', [
     style({
      opacity: 1,
      transform: 'translateX(100%)',
     }),
     animate('0.2s 0.1s ease-in')
    ]),
    transition('* => void', [
     animate('0.2s 0.1s ease-out', style({
      opacity: 1,
      transform: 'translateX(100%)',
     }))
    ])
   ])
  ]
 })
 export class SurveyHistoryComponent implements OnInit {

  showSurveyHistoryLoader:boolean = true;
  GetLeftSideCompJson:boolean = false;
  GetTransactionData:boolean = false;

  PassbookLaunchDate:string = myLangGlobal.PassbookLiveDate;

  panelistSurveys = {
    "surveyData": "",
    "totalPage": 0,
    "totalData": 0
  };

  current_login_panelist = "";
 
  public totalItems: any;
  currentPage: any = 1; // set current page to 1
  public itemsPerPage: any = 20; // we are showing 5 items per page
 
  show_search_form: boolean = false;
 
  status = [{id: 2,name: "Survey_history_status_complete"},{id: 0,name: "Survey_history_status_disqualified"}];
 
  statusData: string = "";
  startDate;
  endDate;
  keyword;
  errorMsg:string;
  totalPage;
 

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd', 
    disableSince: {year: (new Date()).getFullYear(), month: ((new Date()).getMonth()+1), day: ((new Date()).getDate()+1)},
    editableDateField: false,
    openSelectorOnInputClick:true
  };
  

  PaginationShow:boolean = true;
  tableData:boolean = true;
  NoRecordData:boolean = false;

  qloadstart:boolean;

  constructor(private _panelistService: PanelistService, private toastr: ToastrService, private title: Title) {
  }
 
  ngOnInit() {
   this.title.setTitle('WisePerks | Respondent Survey History');
   this.getSurveyData();
  }
 
  getSurveyData(page = 1, perPage = 20) {
   this.currentPage = page;
   if (localStorage.getItem('currentLoginPanelist') === null) {
    // redirect to home component
   } else {
    this.current_login_panelist = localStorage.getItem('currentLoginPanelist');
   }
 
   this._panelistService.getPanelistSurvey(this.current_login_panelist, page, perPage).subscribe((get_survey_resp: any) => {
    //console.log("get_survey_resp : ", get_survey_resp);
 
    if(get_survey_resp.auth == true && get_survey_resp.success == true){
      
      var totalSurveys = get_survey_resp.count;
       this.totalPage = Math.ceil(totalSurveys / perPage);
      
      //get_survey_resp.count = 0;
      
      if(get_survey_resp.count > 0){
        this.panelistSurveys = {
          "surveyData": get_survey_resp.data,
          "totalPage": this.totalPage,
          "totalData": get_survey_resp.count
          };
          this.itemsPerPage = perPage; 
        this.NoRecordData = false;
        this.tableData = true;
        this.PaginationShow = true;
      } else {
        this.tableData = false;
        this.PaginationShow = false;
        this.NoRecordData = true;
      }
      

      this.GetTransactionData = true;
      this.StopPageLoader({});
    }
   },
    (error:any) => {
      this.showNewFlashError(error,"WisePerks");
    });
  }
 
 
  getSearchData(form: NgForm) {
 
    var status = form.value.status;
    var startDate = form.value.startDate;
    var endDate = form.value.endDate;
    var keyword = form.value.keyword;
    var page = 1;
    var perPage = 20;
    
    
    if( Object.keys(status).length == 0  && startDate == undefined && endDate == undefined &&  (keyword == undefined || keyword == "")){}else{
      this.qloadstart = true;
      this.currentPage = page;
      if (localStorage.getItem('currentLoginPanelist') === null) {
        // redirect to home component
      } else {
        this.current_login_panelist = localStorage.getItem('currentLoginPanelist');
      }
      
      this._panelistService.getPanelistSurveyByStatus(this.current_login_panelist, page, perPage, status, startDate, endDate, keyword).subscribe((get_survey_resp: any) => {
        //console.log("get_survey_resp : ", get_survey_resp);
    
        if(get_survey_resp.auth == true && get_survey_resp.success == true){

          if(get_survey_resp.count > 0){

            var totalSurveys = get_survey_resp.count;
             this.totalPage = Math.ceil(totalSurveys / perPage);

            this.panelistSurveys = {
              "surveyData": get_survey_resp.data,
              "totalPage": this.totalPage,
              "totalData": get_survey_resp.count
            };
            this.itemsPerPage = perPage; 
            this.qloadstart = false;
            this.NoRecordData = false;
            this.tableData = true;
            this.PaginationShow = true;
          } else {
            this.qloadstart = false;
            this.tableData = false;
            this.PaginationShow = false;
            this.NoRecordData = true;
          }
        }
      },
        (err:any) => {
          this.qloadstart = false;
          this.showNewFlashError(err,"WisePerks");
        });
      }
  }
 
 
  onDateChangedStart(event: IMyDateModel) {
    if(this.endDate != undefined){
    if (this.endDate.epoc >= event.epoc) {
     this.errorMsg = "";
     return true;
    } else {
     this.startDate = "";
     this.errorMsg = "From date must be less than to date.";
    }
    }else{
      this.errorMsg = "";
     return true;
    }
  }

  onDateChangedEnd(event: IMyDateModel) {
   if (this.startDate.epoc <= event.epoc) {
    this.errorMsg = "";
    return true;
   } else {
    this.endDate = "";
    this.errorMsg = "To date must be greater than from date.";
   }
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

  StopPageLoader(getJson){

    if(getJson['comp'] == 'leftbar' && getJson['flag'] == 1){
      //this.showDashboardLoader = false;
      this.GetLeftSideCompJson = true;
    }

    if(this.GetLeftSideCompJson == true && this.GetTransactionData == true){
      this.showSurveyHistoryLoader = false;
    }
  }

  SearchAnimation_Show_Hide(getevent){
    //console.log(getevent);
    //console.log(this.show_search_form);

    if(this.show_search_form == true && getevent == 'hide'){
      this.show_search_form = false;
    }
  }
 } 