import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Observer } from 'rxjs/Observer';
import { map, catchError } from 'rxjs/operators';
import * as socketIo from 'socket.io-client';

//const SERVER_URL = 'http://10.0.0.113:9000/users';

/*const SERVER_URL = socketIo('http://10.0.0.113:9000', {
  path: '/users'
});*/


@Injectable()
export class ServeyService {

	ws:WebSocket;	

  constructor() { }

  	createObservableSocket(url:string):Observable<string>{
  			this.ws = new WebSocket(url);
  			return new Observable(observer => {
  					this.ws.onmessage = (event) => observer.next(event.data);
  					this.ws.onerror = (event) => observer.error(event);
  					this.ws.onclose = (event) => observer.complete();

  			});
  	}







  	//private socket;
  	/*public initSocket(): void {
        this.socket = socketIo(SERVER_URL);
    }

    public onMessage(): Observable<Message> {
        return new Observable<Message>(observer => {
            this.socket.on('socketToMe', (data:any) => observer.next(data));
        });
    }*/

}
