import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

// All the RxJS stuff we need
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import * as myGlobal from './globals';

@Injectable()
export class PanelistService {

  mainArr = [];

  PostCallVariables = [];
  GlobalPanelistInfo:any = [];
  GlobalPromoCodeInfo:any = [];
  PanelistPostbackpoints:number;
  PanelistReferralpoints:number;
  PanelistReferralPartialpoints:number;
  PanelistSecReferralPartialpoints:number;
  PanelistPromoPopup:boolean;
  promoPostData:string;

  public IsUserComesTwice:boolean =false;

  constructor(private _http: HttpClient) { }

  getPanelistInfo(panelistId) {
    return this._http.get(myGlobal.rootUrl+'/panelist/'+panelistId);
  }

  UploadImg(fileToUpload:File){

    let getPanelistId = localStorage.getItem('currentLoginPanelist');

    const formData: FormData = new FormData();
    formData.append('avatar', fileToUpload, fileToUpload.name);
    return this._http.post(myGlobal.rootUrl+'/panelist/'+getPanelistId+'/image_upload',formData);
  }

  PanelistUpdate(data){
    
    let panelistId = localStorage.getItem('currentLoginPanelist');

    return this._http.put(myGlobal.rootUrl+'/panelist/'+panelistId,data);
  }

  PanelistPromoCode(promoPostData){
    let panelist_id = localStorage.getItem('currentLoginPanelist');
    var data = "panelist_id="+panelist_id+"&promo_code="+promoPostData.value['promo_code'];
    return this._http.post(myGlobal.rootUrl+'/panelist/promo',data);
  }

  getTodayPromoCode() {
    return this._http.get(myGlobal.rootUrl+'/promo/today');
  }

  public SetTodayPromoCodeInfo(getResponse){
    this.GlobalPromoCodeInfo = getResponse;
  }
  public GetTodayPromoCodeInfo(){
    return this.GlobalPromoCodeInfo;
  }


  ChangePassword(user){

    var data = "email="+user.email+"&old_password="+user.old_password+"&new_password="+user.password+"&auth="+user.authtoken;

    return this._http.post(myGlobal.rootUrl+'/panelist/resetpassword',data);
  }

  ProfilingQuestion(panelistId){
    var data = "";

    return this._http.post(myGlobal.rootUrl+'/panelist/'+panelistId+'/answers', data);
  }

   /* Get States from Country code, start here */
   GetStatesFromCountry(country_id){

    return this._http.get(myGlobal.rootUrl+'/country/'+country_id+'/states')
    .catch(this.handleError);
  }
  /* Get States from Country code, end here */


  SavePanelistProfilingAns(panelistId,formData){
    
    var data = {
      "que" : formData
    }
    
    return this._http.post(myGlobal.rootUrl+'/panelist/'+panelistId+'/answers', data);
  }

  /* Profiling Answer end here */


  /* Survey gets start here */
    PanelistSurvey(panelistId){

      return this._http.get(myGlobal.rootUrl+'/panelist/'+panelistId+'/getsurvey');
    }
  /* Survey gets end here */


  /* Survey Start Api, start here */
    SurveyStart(getPostData){

      var postData = "panelist_id=" + getPostData['panelist_id'] + "&survey_id=" + getPostData['survey_id'] + 
       "&points=" + getPostData['points'] + "&user_device=" + getPostData['user_device'] + 
       "&survey_source=" + getPostData['survey_source'];

      return this._http.post(myGlobal.rootUrl+'/survey/start',postData);
    }
  /* Survey Start Api, end here */


  /* Get Card via Country id on Redeem Page */
  getCountryCard(getCountryId){
    return this._http.get(myGlobal.rootUrl+'/giftcard/country/'+getCountryId);
  }
  /* Get Card via Country id on Redeem Page */


  RedeemPoints(getData){

    var postData = "panelist_id="+getData['panelist_id']+"&giftcard_id="+getData['giftcard_id']+"&points="+getData['points']+"&amount="+getData['amount']+"&ascii="+getData['ascii'];

    return this._http.post(myGlobal.rootUrl+'/redeem',postData);
  }


  RedeemHistoryData(getData){
    return this._http.get(myGlobal.rootUrl+'/redeem/panelist/'+getData['panelist_id']);
  }

  ResendConfirmationMail(getData){

    var postData = "email="+getData['email']+"&auth="+getData['auth'];
    
    return this._http.post(myGlobal.rootUrl+'/panelist/resendconfirmationmail', postData);
  }


  /************************* Transaction Service, Start Here *********************************/

  getPanelistTransaction(panelist_id, page=1, pagesize=20){
    //console.log(page); console.log(pagesize);
    return this._http.get(myGlobal.rootUrl+'/panelist/'+panelist_id+'/transactions?page='+page+'&pagesize='+pagesize);
  }


  getPanelistTransactionByStatus(panelist_id, page=1, pagesize=10, status,startDate,endDate){
    var result = "";
    var from = startDate;
    if(from){
      result = result + '&from='+from.formatted;
    }
    var to = endDate;
    if(to){
      result = result +'&to='+ to.formatted;
    }
    var transStatus = status;
    if(Object.keys(transStatus).length){
      result = result + '&status='+transStatus;
    }
    
    return this._http.get(myGlobal.rootUrl+'/panelist/'+panelist_id+'/transactions?page='+page+'&pagesize='+pagesize+result);
  }

  /************************* Transaction Service, End Here *********************************/



  /************************* Survey History Service, Start Here *********************************/

  getPanelistSurvey(panelist_id, page=1, pagesize=10){
    
    return this._http.get(myGlobal.rootUrl+'/panelist/'+panelist_id+'/transactions?page='+page+'&pagesize='+pagesize+'&survey=true');
  }

  getPanelistSurveyByStatus(panelist_id, page=1, pagesize=10, status,startDate,endDate,keyword){
    var result = "";

    var from = startDate;
    if(from){
      result = result + '&from='+from.formatted;
    }
    
    var to = endDate;
    if(to){
      result = result +'&to='+ to.formatted;
    }
    
    var surveyStatus = status;
    if(Object.keys(surveyStatus).length){
      result = result + '&status='+surveyStatus;
    }
    
    if(keyword != "" && keyword != undefined){
      if(Object.keys(keyword).length){
        result = result + '&keyword='+keyword;
      }
    }
    
    return this._http.get(myGlobal.rootUrl+'/panelist/'+panelist_id+'/transactions?page='+page+'&pagesize='+pagesize+result+'&survey=true');
  }


  /************************* Survey History Service, End Here *********************************/



  /*************************** Postback Call from Samplicity, Start Here************************/

  getPostBackCall(postData){
    let createToken = postData['token'];
    let getToken = createToken.trim();
    let newgetToken = getToken.replace(/\0/g, '');

    var postBackData = "panelist_id="+postData['panelistId']+"&survey_id="+postData['survey_id']+"&status="+postData['status']+"&token="+newgetToken;

    return this._http.post(myGlobal.rootUrl+'/survey/postback',postBackData);
  }

  public setPostbackVar(get_postback_dynamic_msg){
    this.PostCallVariables = [];
    if(get_postback_dynamic_msg){
      this.PostCallVariables['success_msg'] = get_postback_dynamic_msg;
      this.PostCallVariables['show_dashboard_earn_point_banner'] = true;
    }
    
    //console.log(this.PostCallVariables);
  
  }

  public getPostbackVar(){
    return this.PostCallVariables;
  }

  
  /*************************** Postback Call from Samplicity, End Here************************/



  /***************************** Ledger Balance move to Main Balance, Start Here *************/

  // public ledgerToMainBalance(){

  //   let getPanelistId = localStorage.getItem('currentLoginPanelist');
  //   var postData = "";
  //   return this._http.post(myGlobal.rootUrl+'/panelist/'+getPanelistId+'/ledger_balance',postData);
  // }
  /***************************** Ledger Balance move to Main Balance, End Here ***************/



  /*************************** Set & Get Global Panelist Info, Start Here ********************/

  public SetGlobalPanelistInfo(getResponse){
    this.GlobalPanelistInfo = getResponse;
  }

  public GetGlobalPanelistInfo(){
    return this.GlobalPanelistInfo;
  }

  /*************************** Set & Get Global Panelist Info, End Here *********************/

  /*************************** Set and get panelist promo popup value to check if popup is closed or not **************/
  // public SetPanelistPromo(){
  //   this.PanelistPromoPopup = true;
  // }
  // public GetPanelistPromo(){
  //   return this.PanelistPromoPopup;
  // }
  // public ResetPanelistPromo() {
  //   this.PanelistPromoPopup = false;
  // }
  /*************************** END Set and get panelist promo popup value to check if popup is closed or not **************/

  /**************************** Set & Unset & Get Panelists Postback Points, Start Here *******/
  public ResetPostbackPanelistPoints(){
    this.PanelistPostbackpoints = 0;
  }

  public SetPostbackPanelistPoints(getPoints) {
    this.PanelistPostbackpoints = getPoints;
  }

  public GetPostbackPanelistPoints() {
    return this.PanelistPostbackpoints;
  }
  /**************************** Set & Unset & Get Panelists Postback Points, End Here *********/

  /**************************** Set & Unset & Get Panelists Referral Points, Start Here *******/
  public ResetReferralPanelistPoints(){
    this.PanelistReferralpoints = 0;
  }

  public SetReferralPanelistPoints(eachReferral) {
    this.PanelistReferralpoints = eachReferral;
  }

  public GetReferralPanelistPoints() {
    return this.PanelistReferralpoints;
  }
  public ResetReferralPartialPanelistPoints(){
    this.PanelistReferralPartialpoints = 0;
  }

  public SetReferralPartialPanelistPoints(partialReferral) {
    this.PanelistReferralPartialpoints = partialReferral;
  }

  public GetReferralPartialPanelistPoints() {
    return this.PanelistReferralPartialpoints;
  }
  public ResetReferralSecPartialPanelistPoints(){
    this.PanelistSecReferralPartialpoints = 0;
  }

  public SetReferralSecPartialPanelistPoints(partialSecondReferral) {
    this.PanelistSecReferralPartialpoints = partialSecondReferral;
  }

  public GetReferralSecPartialPanelistPoints() {
    return this.PanelistSecReferralPartialpoints;
  }
  /**************************** Set & Unset & Get Panelists Referral Points, End Here *********/


  /**************************** User comes twice on dashboard, Start Here *********/
  public ResetUserComesTwice() {
    this.IsUserComesTwice = false;
  }
  
  public SetUserComesTwice() {
    this.IsUserComesTwice = true;
  }

  public GetUserComesTwice() {
    return this.IsUserComesTwice;
  }

  /**************************** User comes twice on dashboard, End Here *********/


/**************************** Get amount on card redeem modal, Start Here *********/

  getCardAmount(country_id,points){

    var postBackData = "country_id="+country_id+"&points="+points;

    return this._http.post(myGlobal.rootUrl+'/redeem/amount',postBackData);
  }
  /**************************** Get amount on card redeem modal, End Here *********/




  /************************** Settle up the Pending Points, Start Here *************/

  SettlePendingPoints(getData){

    var postData = "panelist_id="+getData['panelist_id'];

    return this._http.post(myGlobal.rootUrl+'/settleuppendingpoints',postData);
  }

  /************************** Settle up the Pending Points, End Here *************/


  /************************* Passbook, Start Here *********************************/

  getPanelistPassbook(panelist_id, page=1, pagesize=10){
    
    return this._http.get(myGlobal.rootUrl+'/panelist/'+panelist_id+'/transactions?page='+page+'&pagesize='+pagesize+'&passbook=true');
  }


  getPanelistPassbookStatus(panelist_id, page=1, pagesize=20, status, startDate, endDate, keyword){
    var result = "";

    var from = startDate;
    if(from){
      result = result + '&from='+from.formatted;
    }
    
    var to = endDate;
    if(to){
      result = result +'&to='+ to.formatted;
    }
    
    var surveyStatus = status;
    if(Object.keys(surveyStatus).length){
      result = result + '&tr_type='+surveyStatus;
    }
    
    if(keyword != "" && keyword != undefined){
      if(Object.keys(keyword).length){
        result = result + '&sri='+keyword;
      }
    }
    
    return this._http.get(myGlobal.rootUrl+'/panelist/'+panelist_id+'/transactions?page='+page+'&pagesize='+pagesize+result+'&passbook=true');
  }


  /************************* Passbook, End Here *********************************/


  handleError(error: Response) {
    //console.log('in error');
    //console.error(error);
    return Observable.throw(error);
  }
}
