// Italian
export const TRANSLATIONS_IT = {
  "home_header_selectlang": "Seleziona la lingua",
  "home_header_loginbutton": "Accesso",
  "home_header_signupbutton": "Iscriviti",
  "home_header_homebutton": "Casa",
  
  "home_register_bannertxt1": "Ecco il tuo",
  "home_register_bannertxt2": "pensieri",
  "home_register_bannertxt3": "puoi prenderti",
  "home_register_bannertxt4": "ricompensa$",
  "home_register_bannertxt5": "Ottieni un bonus",
  "home_register_bannertxt6": "Al momento dell'iscrizione",
  "home_register_fbsignup": "ISCRIVITI CON FACEBOOK",
  "home_register_fblogin": "FAI IL LOGIN CON FACEBOOK",
  "home_register_or": "O",
  "home_register_emailsignup": "Iscriviti con e-mail",
  "home_register_emaillogin": "Accedi con l'email",
  "home_register_fpass": "Ha dimenticato la password?",
  "home_register_fname": "Nome di battesimo",
  "home_register_lname": "Cognome",
  "home_register_email": "Indirizzo email",
  "home_register_pass": "Parola d'ordine",
  "home_register_cpass": "conferma password",
  "home_register_tcpp": "Iscrivendoti accetti il ​​nostro",
  "home_register_tcpptandcond": "Termini & Condizioni",
  "home_register_tcppand": "e",
  "home_register_tcppprivacy": "politica sulla riservatezza",
  "home_register_join": "Unisciti a noi. È gratis",
  "home_register_login": "Accesso",
  "home_register_name_required" : "Il nome è obbligatorio",
  "home_register_email_required" : "L'email è richiesta",
  "home_register_invalid_email" : "Formato email non valido",
  "home_register_password_required" : "E 'richiesta la password.",
  "home_register_password_lower_digit" : "La password deve essere di 6 cifre",
  "home_register_password_higher_digit" : "La password deve essere utilizzata 15 cifre necessarie",
  "home_register_cpassword_required" : "Conferma Password è richiesta",
  "home_register_cpassword_lower_digit" : "Conferma password deve essere richiesto 6 cifre",
  "home_register_cpassword_higher_digit" : "La password di conferma deve essere utilizzata 15 cifre richieste",
  "home_register_cpassword_not_match" : "Conferma la password non corrisponde",

  "home_register_fp_not_worry" : "Si prega di inserire il emailid registrato.", 
  "home_register_fp_resetpass_msg" : "Per reimpostare la password, Inserisci il tuo ID e-mail registrato.", 
  "home_register_fp_enter_email_msg" : "Inserisci la tua email qui",
  "home_register_fp_submit_button" : "Invia", 
  "home_register_fp_temporary_sent_pass_msg" : "Un link password reset è stato inviato al tuo ID e-mail registrato. Si prega di aggiornare la password al più presto.", 
  
  "home_works_howitworks": "Come funziona?",
  "home_works_howitworkst1": "Presentati", 
  "home_works_howitworksd1": "Diventa un membro, iscriviti",
  "home_works_howitworkst2": "Ricevi sondaggi",
  "home_works_howitworksd2": "Inizia a ricevere sondaggi intuitivi e semplici",
  "home_works_howitworkst3": "Guadagna punti",
  "home_works_howitworksd3": "Dai le tue migliori risposte e accumula punti",
  "home_works_howitworkst4": "Riscattare",
  "home_works_howitworksd4": "Riscatta i tuoi punti per molti premi eccitanti",

  "home_aboutus_whoweare": "Chi siamo?",
  "home_aboutus_whowearep1": "In WisePerks lavoriamo sodo per assicurarci che il tempo trascorso online sia un'esperienza gratificante. Lavoriamo con centinaia di aziende che sono desiderose di avere il tuo feedback, sapere come ti senti sui loro prodotti o servizi. Fornisci la tua opinione su vari servizi, ma nessuno di questi servizi ti ricompensa per il tuo tempo, a differenza di WisePerks - dove ogni sondaggio a cui partecipi ti offre grandi premi in denaro per il tuo tempo.",
  "home_aboutus_whowearep2": "Apprezziamo il tuo feedback e ti vogliamo premiato per il tuo tempo. Abbiamo sondaggi su vari argomenti per soddisfare i tuoi interessi e renderli facili da rispondere. Puoi facilmente guadagnare circa $ 50- $ 100 ogni mese prendendo vari sondaggi. Quindi lavoriamo insieme per farlo accadere.",
  
  "home_earn_more_surabt": "Quali sondaggi chiedere?",
  "home_earn_more_bp": "Esperti aziendali",
  "home_earn_more_consumers": "Consumatore",
  "home_earn_more_hc": "Salute e fitness",
  "home_earn_more_entertainment": "Divertente e divertente",
  "home_earn_more_gaming": "Gioco Freaks",
  "home_earn_more_tech": "Tech savvy",
  "home_earn_more_bonus_survey": "Sondaggio bonus", 
  
  "home_reward_whatuearn": "Cosa guadagni?",
  "home_reward_whatuearnp1": "Ci piace mantenere le cose semplici. Ottieni punti per tutti i sondaggi completati con successo. Più sondaggi completi, più punti guadagni. I punti possono essere riscattati per le carte regalo di tua scelta dopo aver superato una certa soglia.",
  "home_reward_whatuearnp2": "Una volta pronto per riscattare, scegli l'opzione che preferisci: Amazon, Starbucks, iTunes e molti altri. Inoltre distribuiamo bonus e grazie a Dio le offerte del venerdì di volta in volta.",
  
  "home_counter_earnedover": "I membri si sono guadagnati da vivere",
  
  "home_contact_getintouch": "Mettiti in contatto",
  "home_contact_emailus": "Per qualsiasi domanda, scriveteci all'indirizzo",
  
  "home_footer_faq": "FAQs",
  "home_footer_pp": "politica sulla riservatezza",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Prossime caratteristiche", 
  "home_footer_copyright": "Copyright © current_year WisePerks. Tutti i diritti riservati.",

  "home_header_home": "Casa",
  "home_footer_toknowmore": "Per saperne di più",
  "home_footer_createacc": "Crea un account gratuitamente e unisciti alle migliaia di membri felici.",
  "home_footer_registernow": "Registrati ora",

  "home_reset_password" : "Reimposta password", 
  "home_reset_pass_content" : "È stato richiesto di reimpostare la password per", 

  "dashboard_sidebar_selectavatar" : "Seleziona il tuo avatar", 
  "dashboard_sidebar_uploadavatar" : "Clicca qui per caricare la tua immagine", 
  "dashboard_sidebar_pbank" : "Banca punti",
  "dashboard_sidebar_accumulated" : "Guadagnato",
  "dashboard_sidebar_realized" : "Realizzato", 
  "dashboard_sidebar_pending" : "regolazioni", 
  "dashboard_sidebar_earned_tooltip" : "Punti che hai raccolto finora.",
  "dashboard_sidebar_pending_tooltip" : "Punti che devono essere annullati dal tuo account.",
  "dashboard_sidebar_main" : "Principale",
  "dashboard_sidebar_dashboard" : "Cruscotto",
  "dashboard_sidebar_profile" : "Profilo",
  "dashboard_sidebar_updateprofile" : "Aggiorna profilo",
  "dashboard_sidebar_shistory" : "Storia del sondaggio",
  "dashboard_sidebar_mtrans" : "La mia transazione",
  "dashboard_sidebar_passbook" : "Libro punti",
  "dashboard_sidebar_redeem" : "Riscattare",
  "dashboard_sidebar_refer_friend" : "Segnala un amico",
  "dashboard_sidebar_logout" : "Disconnettersi",
  
  "dashboard_sidebar_tutorial1" : "Mostra tutti i sondaggi disponibili e che cosa si può guadagnare da loro.",
  "dashboard_sidebar_tutorial2" : "Rispondere a qualche domanda qui per farci conoscere meglio e ci aiutano a inviare sondaggi di vostro interesse. Si prega di notare per alcune domande che non sarebbe in grado di aggiornare le risposte dopo l'invio.",
  "dashboard_sidebar_tutorial2update" : "Potete aggiornare le vostre risposte qui, comunque poche domande non saranno disponibili per updation.",
  "dashboard_sidebar_tutorial3" : "Trovate qui tutti i sondaggi che avete tentato.",
  "dashboard_sidebar_tutorial4" : "Ti aiuta a tenere traccia di tutte le tue transazioni su WisePerks.",
  "dashboard_sidebar_tutorial5" : "Pochi passi rapidi per convertire i vostri punti realizzati in premi.",
  "dashboard_sidebar_tutorial6" : "Visualizza l'intera cronologia delle transazioni dei punti addebitati / accreditati sul tuo conto.",
  "dashboard_sidebar_gotit" : "Capito",

  "dashboard_sidebar_note" : "notare che",
  "dashboard_sidebar_note1" : "Tutte le risposte devono essere risposte onestamente.",
  "dashboard_sidebar_note2" : "Si prega di essere specifici e precisi con le risposte aperte.",
  "dashboard_sidebar_note3" : "Non accelerare! È impostato il tempo minimo di completamento del sondaggio.",
  "dashboard_sidebar_note4" : "Si prega di fornire risposte coerenti quando vengono poste le stesse domande.",
  "dashboard_sidebar_note5" : "Verranno aggiunti nuovi sondaggi e quelli chiusi verranno rimossi automaticamente dalla dashboard.",
  "dashboard_sidebar_note6" : "Guadagna alcuni punti veloci solo con i referral.",

  "dashboard_setting_hi" : "Ciao",
  "dashboard_setting_minfo" : "Informazioni per l'iscrizione",
  "dashboard_setting_macc" : "Gestisci profilo",
  "dashboard_setting_upass" : "Aggiorna password",
  "dashboard_setting_yourname" : "Il tuo nome è",
  "dashboard_setting_fname" : "Nome di battesimo",
  "dashboard_setting_lname" : "Cognome",
  "dashboard_setting_save" : "Salva I Cambiamenti",
  "dashboard_setting_bornon" : "Sei nato",
  "dashboard_setting_youare" : "Tu sei un",
  "dashboard_setting_postal" : "Il tuo codice postale è",
  "dashboard_setting_emailus" : "Per qualsiasi assistenza o domanda, si prega di inviare una e-mail a",
  "dashboard_setting_membersince" : "Sei stato un membro da allora",
  "dashboard_setting_taken" : "Hai preso",
  "dashboard_setting_completed" : "sondaggi e completato",
  "dashboard_setting_ofthem" : "di loro.",
  "dashboard_setting_earned" : "hai guadagnato",
  "dashboard_setting_pts" : "punti",
  "dashboard_setting_started" : "da quando hai iniziato",
  "dashboard_register_email" : "Sei registrato con noi da",
  "dashboard_setting_preferences" : "Gestisci le tue preferenze",
  "dashboard_setting_emailoptout" : "E-mail Optare",
  "dashboard_setting_futureemails" : "Disattivare la ricezione di e-mail future da WisePerks.",
  "dashboard_setting_futureemailsflash" : "I nostri emails lo mantengono inviato su tutti i sondaggi lucrativi disponibili per voi. Sei sicuro che non vuoi riceverli.",
  "dashboard_setting_nrecemail" : "I nostri emails lo mantengono inviato su tutti i sondaggi lucrativi disponibili per voi. Sei sicuro di non voler fermarli.",
  "dashboard_setting_emailout_modalmsg" : "Sei sicuro di voler rinunciare a ricevere le email future da WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Sei sicuro di iniziare a ricevere le email future da WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Il tuo hanno con successo smette di ricevere email da WisePerks",
  "dashboard_setting_emailin_succmsg" : "Il tuo hanno iniziato con successo la ricezione di email da WisePerks",
  "dashboard_setting_deactivateacc" : "Disattiva Account",
  "dashboard_setting_disableprofile" : "La disattivazione del tuo account disabiliterà il tuo profilo da WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Sei sicuro di voler disattivare il tuo account su WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Se avete qualsiasi reclamo o feedback, quindi si prega di condividere con noi. Vorremmo affrontare la priorità. Dacci una possibilità. Sei sicuro di voler cancellare il tuo account con noi.",
  "dashboard_setting_changelang" : "Cambia lingua",
  "dashboard_setting_preferredlang" : "Seleziona la tua lingua preferita",
  "dashboard_setting_updatepass" : "Aggiorna password",
  "dashboard_setting_plsupdate" : "Si prega di aggiornare la password qui sotto.",
  "dashboard_setting_oldpass" : "Vecchia password",
  "dashboard_setting_enteroldpass" : "Inserisci la vecchia password",
  "dashboard_setting_newpass" : "Nuova password",
  "dashboard_setting_enternewpass" : "Inserire una nuova password",
  "dashboard_setting_confpass" : "Conferma password",
  "dashboard_setting_enternewpassagain" : "Immettere nuovamente la nuova password",
  "dashboard_setting_update" : "Aggiornare",

  "dashboard_doi_welcome" : "Benvenuto",
  "dashboard_doi_welbck" : "Ben tornato",
  "dashboard_doi_accntverified" : "Sembra che ti sei perso la verifica del tuo account e-mail.",
  "dashboard_doi_getaccess" : "Fretta! si ottiene",
  "dashboard_doi_getaccess2" : "punti una volta verificato.",
  "dashboard_doi_activateacc" : "Si prega di controllare la posta in arrivo e fare clic sul link indicato nella e-mail di verifica del conto inviato al tuo account e-mail registrato.",
  "dashboard_doi_chckspam" : "Gentilmente, controllare la cartella spam/junk nel caso in cui non si è in grado di trovare l'email di verifica.",
  "dashboard_doi_didntgetconf" : "Non ha ancora ottenere e-mail di verifica?",
  "dashboard_doi_resendconf" : "Invia email di verifica",
  "dashboard_doi_resendconfsucc" : "E-mail di verifica è stato inviato al tuo indirizzo email registrato.", 
  
  "dashboard_survey_availsurveys" : "Sondaggi disponibili",
  "dashboard_survey_wisechecksurveys1" : "Non siete in grado di vedere le indagini a causa di alcuni motivi di sicurezza. Si prega di contattare per il supporto",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Sondaggi non disponibili per la ragione di annullamento dell'iscrizione",
  "dashboard_featured" : "Presenti",
  "dashboard_profile_survey" : "Sondaggio profilo",
  "dashboard_survey_start" : "Inizio",
  "dashboard_survey_on" : "Sopra",
  "dashboard_survey_off" : "via",
  "dashboard_survey_point": "Punto",
  "dashboard_survey_points" : "Punti",
  "dashboard_survey_minute" : "Minuti",
  "dashboard_survey_minutes" : "Minuti",

  "demography_hi" : "Ciao",
  "demography_welcome" : "Benvenuti a WisePerks. Ti sei appena guadagnato",
  "demography_points" : "punti di registrazione con noi.",
  "demography_share" : "Si prega di condividere qualche dettaglio in più nel modulo sottostante, per aiutarci a portare le indagini rilevanti per voi.",

  "demography_gender" : "sono un",
  "demography_male" : "Maschio",
  "demography_female" : "Femmina",
  "demography_birthday" : "Compleanno",
  "demography_month" : "Mese", 
  "demography_day" : "Giorno", 
  "demography_year" : "Anno", 
  "demography_jan" : "gennaio",
  "demography_feb" : "febbraio",
  "demography_mar" : "marzo",
  "demography_apr" : "aprile",
  "demography_may" : "potrebbe",
  "demography_jun" : "giugno",
  "demography_jul" : "luglio",
  "demography_aug" : "agosto",
  "demography_sep" : "settembre",
  "demography_oct" : "ottobre",
  "demography_nov" : "novembre",
  "demography_dec" : "dicembre",
  "demography_hearabout" : "Dove hai sentito parlare di WisePerks?",
  "demography_opt1" : "Seleziona il più vicino che si applica",
  "demography_opt2" : "E-mail / Newsletter",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum o Blog",
  "demography_opt5" : "Amico",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "Motore di ricerca",
  "demography_opt8" : "TV commerciale",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Sito web",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Altri",
  "demography_zipcode": "Zipcode",
  "demography_submit" : "Invia",

  "privacypolicy_privacypolicy" : "politica sulla riservatezza",
  "privacypolicy_privacyisimp" : "La tua privacy è importante per noi.",
  "privacypolicy_lastupdated" : "Ultimo aggiornamento il",

  "privacypolicy_para1" : 'WiseWorks (insieme alle sue affiliate, la "Società") possiede e gestisce numerosi siti Web, app mobili e servizi interattivi, inclusi, a titolo esemplificativo, Wisesample.com, Flapbucks.com, WisePerks.com e altri (collettivamente, " Siti WiseWorks ").La presente informativa sulla privacy ("Informativa sulla privacy") si applica ai Siti WiseWorks ea tutte le funzionalità, applicazioni mobili, e-mail, servizi online e altre funzionalità, accessibili tramite computer, dispositivo mobile o altro e i servizi disponibili su o tramite i siti e le funzionalità di WiseWorks (i "Servizi").La presente Informativa sulla privacy copre solo le informazioni raccolte dalla Società attraverso i Siti e le funzionalità WiseWorks e i nostri Servizi e non copre tutte le informazioni ricevute o raccolte non in linea dalla Società (se non espressamente indicato) o raccolte da eventuali siti Web o società non collegati a cui possiamo fornire collegamenti.',

  "privacypolicy_para2" : "Si prevede che il relatore / rispondente compili il proprio profilo in modo onesto e veritiero e non fornisca informazioni errate o errate semplicemente per qualificarsi per ulteriori sondaggi.Si prevede inoltre che il panelista / rispondente non manipoli le sue risposte o usi altri mezzi non equi (cambio di IP, account multipli ecc.) Durante la compilazione del sondaggio per guadagnare incentivi.WisePerks dispone di sufficienti protezioni per rilevare tali anomalie e se tale comportamento viene notato, il panelista sarà bandito / bloccato da WisePerks e tutte / eventuali richieste di rimborso saranno annullate.",

  "privacypolicy_para3" : "WiseWorks è fortemente impegnata a salvaguardare la tua privacy con noi.La seguente informativa sulla privacy spiega come vengono conservate e trattate le tue informazioni personali mentre esplori il nostro sito Web e i nostri servizi.",

  "privacypolicy_topic1" : "Quali informazioni personali identificabili vengono raccolte da WisePerks.com?",
  "privacypolicy_topic1ans" : "WisePerks.com raccoglie informazioni in diversi modi da diverse parti del nostro sito Web e rete.Alcune informazioni sono raccolte quando ti iscrivi.Durante la registrazione del membro, WisePerks.com richiede il tuo nome, indirizzo email, codice postale e informazioni sulla casa.Più informazioni fornite volontariamente per fornire con precisione, meglio siamo in grado di offrire servizi e vantaggi.Inoltre, se contatti WisePerks.com con qualsiasi mezzo, potremmo tenere un registro di tale corrispondenza.Ogni volta che WisePerks.com raccoglie tali informazioni, miriamo a includere un collegamento a questa Informativa sulla privacy in quella pagina.",

  "privacypolicy_topic2" : "Cosa sono i cookie e come li usa WisePerks.com?",
  "privacypolicy_topic2ans" : "Un cookie è un pacchetto di dati elettronici inviati al tuo browser, da un server web e memorizzati sul disco rigido del tuo computer.Nell'ambito dell'offerta di indagini, informazioni e servizi personalizzati specifici, WisePerks.com può utilizzare i cookie per archiviare e talvolta tenere traccia delle informazioni su di te.WisePerks.com potrebbe richiedere l'accettazione dei cookie per la preparazione di tali contenuti specifici.Questo evita anche che tu debba inserire le tue informazioni di accesso ogni volta.",

  "privacypolicy_topic3" : "Qual è la politica di WisePerks.com che mi consente di aggiornare, modificare, modificare o eliminare le informazioni del mio account personale?",
  "privacypolicy_topic3ans" : "È possibile modificare le informazioni sull'account WisePerks.com in qualsiasi momento utilizzando il login e la password di WisePerks.com.Per ulteriori informazioni sulla disattivazione dell'account WisePerks.com, contattare",
  "privacypolicy_topic3ans2" : ".",

  "privacypolicy_topic4" : "Chi sta raccogliendo informazioni e per quale scopo?",
  "privacypolicy_topic4ansp1" : "WisePerks.com raccoglie diverse informazioni attraverso sondaggi e registrazioni e altri mezzi, che aiutano i suoi clienti di ricerca a sviluppare nuovi prodotti e servizi per i consumatori.Stai condividendo queste informazioni solo con WisePerks.com, a meno che non sia specificato diversamente in qualsiasi sondaggio a cui partecipi.Se i dati vengono raccolti e / o gestiti da qualsiasi azienda diversa dai siti WiseWorks o WiseWorks, l'utente verrà informato prima del momento della raccolta o del trasferimento dei dati.Se non vuoi che i tuoi dati siano condivisi, puoi scegliere di non consentire il trasferimento non utilizzando quel particolare sondaggio o prodotto.",
  "privacypolicy_topic4ansp2" : "I premi e le promozioni che offriamo su WisePerks.com possono essere sponsorizzati da altre società in modo indipendente, oppure possono essere sponsorizzati congiuntamente da WisePerks.com e un'altra società.Alcuni dati raccolti durante tale promozione possono essere condivisi con lo sponsor.Tuttavia, riceverai una notifica prima del momento della raccolta o del trasferimento dei dati.Puoi decidere di non partecipare a quella promozione se non vuoi condividere i tuoi dati.",
  "privacypolicy_topic4ansp3" : "Eventuali informazioni sensibili specifiche (ad esempio informazioni che specificano condizioni mediche o di salute, origine razziale o etnica, opinioni politiche, credenze religiose o spirituali, scambi e appartenenza sindacale o informazioni che specificano la vita sessuale dell'individuo) saranno trattate con ulteriore cura.Se WisePerks.com intende condividere tali informazioni con un'altra società per uno scopo diverso da quello originariamente raccolto, verrà richiesto espressamente il permesso prima di tale divulgazione.",

  "privacypolicy_topic5" : "Con chi WisePerks.com condivide le mie informazioni?",
  "privacypolicy_topic5ansp1" : "Prendiamo molto sul serio la privacy.WisePerks.com non divulgherà alcuna delle tue informazioni di identificazione personale tranne quando abbiamo la tua autorizzazione esplicita o in circostanze particolari, che la legge lo richiede o nelle circostanze specificate in dettaglio di seguito.Quanto segue descrive alcuni dei modi in cui le tue informazioni potrebbero essere divulgate.Si prega di consultare i Termini di servizio per i nostri servizi per informazioni più dettagliate su come le vostre informazioni personali possono essere condivise.",
  "privacypolicy_topic5ansp2" : "Partner commerciali, clienti e sponsor:",
  "privacypolicy_topic5ansp3" : "WisePerks.com può divulgare le tue informazioni personali ai nostri partner, clienti o sponsor.Tuttavia, se dovessero essere condivisi dati personali su di te, ti informeremo prima di raccogliere o trasferire i dati.Molte attività che offriamo richiedono ulteriori informazioni dagli sponsor.Richiedendo maggiori informazioni, si concede a WisePerks.com il permesso di condividere le informazioni con lo sponsor in modo che possano soddisfare la richiesta.In molti casi, solo il tuo indirizzo email sarà condiviso.Se ulteriori informazioni saranno condivise con i nostri partner, sarete avvisati in anticipo.Di volta in volta, ti potrebbe essere offerta l'opportunità di ricevere materiali di prova, o altri prodotti o offerte speciali dei nostri partner.Se si sceglie di ricevere informazioni da queste terze parti, WisePerks.com (con il proprio permesso) condividerà il proprio nome e indirizzo e-mail con loro.",
  "privacypolicy_topic5ansp4" : "WisePerks.com può anche divulgare informazioni personali in alcuni casi speciali quando la divulgazione di queste informazioni è necessaria per identificare, contattare o portare azioni legali contro qualcuno che potrebbe violare le politiche e i Termini di servizio di WisePerks.com o potrebbe causare lesioni ao interferenze con (intenzionalmente o involontariamente) prodotti, servizi, diritti o proprietà di WisePerks.com, altri membri e utenti di WisePerks.com o chiunque altro possa essere interessato da tali attività.WisePerks.com può divulgare o accedere alle informazioni sull'account quando crediamo in buona fede che la legge lo richiede e per scopi amministrativi e altri che riteniamo necessari per mantenere, fornire assistenza e migliorare i nostri prodotti e servizi.",

  "privacypolicy_topic6" : "Quali sono le basi legali che usiamo per elaborare le tue informazioni?",
  "privacypolicy_topic6ans" : "WiseWorks si impegna a proteggere la privacy e i dati.La nostra politica sulla privacy adotta i principi del Regolamento generale sulla protezione dei dati emessi dall'Unione Europea ('GDPR') come punto di riferimento a cui noi, i nostri dipendenti e fornitori aderiamo.",

  "privacypolicy_topic7" : "Quali sono le mie scelte in merito alla raccolta, uso e distribuzione delle mie informazioni?",
  "privacypolicy_topic7ans" : "Solo WisePerks.com (oi partner che lavorano con WisePerks.com in base ad accordi di riservatezza) ti invieranno qualsiasi posta diretta, solo se hai indicato di non opporsi a questi.Hai anche delle scelte riguardo a come funzionano i cookie.Modificando le impostazioni del browser Internet, hai la possibilità di accettare tutti i cookie, notificati quando viene impostato un cookie o di rifiutare tutte le richieste di cookie.Se si sceglie di rifiutare tutti i cookie, non sarà possibile utilizzare quei servizi WisePerks.com che richiedono la registrazione preventiva per poter partecipare.WisePerks.com non vende né affitta alcuna informazione dell'utente a nessuno.Ti informeremo sempre al momento della raccolta o del trasferimento dei dati se i tuoi dati saranno condivisi con terze parti e avrai sempre la possibilità di non permetterne la condivisione.",

  "privacypolicy_topic8" : "Quali precauzioni di sicurezza sono in vigore su WisePerks.com per proteggere eventuali perdite, usi impropri o alterazioni delle informazioni del mio account?",
  "privacypolicy_topic8ansp1" : "Le informazioni sull'account WisePerks.com sono protette da password in modo che tu e solo tu abbia accesso a queste informazioni.",
  "privacypolicy_topic8ansp2" : "Ti consigliamo vivamente di non condividere la tua password con nessuno.Non ti chiederemo mai la tua password in una telefonata o un'email non richiesta.Esci dal tuo account WisePerks.com e chiudi la finestra del browser quando hai finito la tua attività.Ciò garantisce che gli altri non possano accedere ai tuoi dati personali e al tuo account, se stai utilizzando un computer pubblico o condividi il tuo computer con qualcun altro.Ogni volta che WisePerks.com gestisce le informazioni personali come descritto sopra, indipendentemente da dove si verifica, WisePerks.com provvede affinché le tue informazioni siano trattate in modo sicuro e in conformità con i Termini di servizio e la presente Informativa sulla privacy.Sfortunatamente, nessuna trasmissione di dati su Internet può essere garantita al 100% sicura.Di conseguenza, mentre ci sforziamo di proteggere le tue informazioni personali, WisePerks.com non può assicurare o garantire la sicurezza delle informazioni che ci trasmetti o dei nostri prodotti o servizi online, e lo fai a tuo rischio.Una volta ricevuti i tuoi dati, facciamo del nostro meglio per garantire la sua sicurezza sui nostri sistemi.",

  "privacypolicy_topic9" : "Cos'altro dovrei sapere sulla mia privacy?",
  "privacypolicy_topic9ans" : "Tieni presente che ogni volta che (e ovunque) scegli di divulgare informazioni personali online, ad esempio tramite e-mail, siti web di social network, blog o aree di chat, tali informazioni possono essere raccolte e utilizzate da qualsiasi altro utente.Se pubblichi informazioni personali online accessibili al pubblico, potresti ricevere in cambio messaggi non richiesti da altre parti.In definitiva, sei l'unico responsabile del mantenimento della segretezza delle tue password e / o di qualsiasi informazione sull'account.Si prega di essere cauti e responsabili ogni volta che si è online.",

  "privacypolicy_topic10" : "In che modo WisePerks.com protegge la privacy dei miei figli?",
  "privacypolicy_topic10ans" : "WisePerks.com ottiene il consenso dei genitori prima che i sondaggi richiedano la partecipazione dei bambini e che sia necessaria anche la supervisione dei genitori per tali indagini con bambini di età compresa tra 6 e 15 anni.Facciamo particolare attenzione per proteggere la sicurezza e la privacy dei giovani utenti sul nostro sito e ti incoraggiamo a partecipare all'esperienza di tuo figlio online.Non permettiamo alcun tipo di marketing o di ricerca per bambini e alcuni argomenti sono completamente proibiti nella ricerca tra bambini: Nome / nome account / E-mail o indirizzo fisico, Numero di telefono, Razza o Etnia, Religione o Cast, Reddito familiare, Malattia familiare , Stato civile dei genitori o di altri membri della famiglia, Questioni relazionali, Gradi scolastici o universitari, Alcol, Problemi sessuali, Tabacco e Armi da fuoco.",

  "privacypolicy_topic11" : "Nota speciale per gli utenti internazionali",
  "privacypolicy_topic11ansp1" : "Il sito è ospitato negli Stati Uniti.Se sei un Utente che accede al Sito dall'Unione Europea, dall'Asia o da qualsiasi altra regione con leggi o regolamenti che regolano la raccolta, l'uso e la divulgazione di dati personali, che differiscono dalle leggi degli Stati Uniti, tieni presente che stai trasferendo i tuoi dati personali al Stati Uniti che non hanno le stesse leggi sulla protezione dei dati dell'UE e di altre regioni e, fornendo i propri dati personali, acconsentono a:",
  "privacypolicy_topic11ansp2" : "L'utilizzo dei tuoi dati personali per gli usi sopra identificati in conformità con la politica sulla privacy; e il trasferimento dei tuoi dati personali negli Stati Uniti come sopra indicato.L'UTENTE RICONOSCE CHE LE LEGGI DEGLI STATI UNITI TRATTANO LE TUE INFORMAZIONI IN UN MODO CHE PU BE ESSERE SOSTANZIALMENTE DIVERSO DA, E MENO PROTETTO DI, IL TRATTAMENTO RICHIESTO AI SENSI DELLE LEGGI DI ALTRI PAESI E GIURISDIZIONI.SE NON DESIDERI TRASFERIRE LE TUE INFORMAZIONI AGLI STATI UNITI, NON DEVI CONDIVIDERE LE SUE INFORMAZIONI CON NOI O UTILIZZARE IL SITO.NELLA MISURA CONSENTITA DALLA LEGGE DEL PAESE IN CUI SIETE SITUATI, RINUNCIATE ESPRESSAMENTE A QUALSIASI DIRITTO CHE POTREBBERO CHIEDERE A NOI DI TRATTARE LE INFORMAZIONI IDENTIFICATIVE IN CONFORMITÀ CON LE LEGGI DI QUALSIASI PAESE O GIURISDIZIONE DIVERSI DAGLI STATI UNITI.TUTTAVIA, LA RINUNCIA PRECEDENTE NON PUO ESSERE LEGALMENTE VINCOLANTE IN ALCUNI PAESI, QUALI GLI STATI MEMBRI DELL'UNIONE EUROPEA.NELLA MISURA IN CUI SI TROVA LEGITTAMENTE VINCOLANTE NEL PAESE IN CUI SI TROVA, QUESTA RINUNCIA PRECEDENTE NON SI APPLICA A VOI.",

  "privacypolicy_topic12" : "Modifiche e aggiornamenti a questa Informativa sulla privacy",
  "privacypolicy_topic12ans" : "WiseWorks si riserva il diritto, a nostra discrezione, di modificare, modificare, aggiungere o rimuovere parti della presente Informativa sulla privacy in qualsiasi momento e per qualsiasi motivo.Ogni volta che WiseWorks esercita tale diritto, ti sarà richiesto di accettare in modo accettabile l'Informativa sulla privacy aggiornata, e se non lo farai, non sarai più in grado di accedere o utilizzare il Sito.",


    "privacypolicy_topic13": "Nota speciale per chi cerca i dettagli GDPR / CCPA",
    "privacypolicy_topic13ansp1": "Per favore ",
    "privacypolicy_topic13ansp2": "clicca qui",
    "privacypolicy_topic13ansp3": "per ulteriori informazioni sulla conformità al GDPR (Regolamento generale sulla protezione dei dati) e al CCPA (California Consumer Privacy Act).",


  "privacypolicy_addinfo" : "Informazioni aggiuntive",
	"privacypolicy_sentto": "Qualsiasi altra domanda riguardante questo dovrebbe essere inviata a",


    "privacypolicyext_para1": "SI PREGA DI LEGGERE ATTENTAMENTE QUESTA INFORMATIVA SULLA PRIVACY",
    "privacypolicyext_para2": "WiseWorks rispetta la privacy di ogni utente e ci impegniamo a essere trasparenti con te su come gestiamo i dati personali. La presente Informativa sulla privacy spiega come WiseWorks raccoglie, condivide e utilizza i dati personali che ci fornisci quando utilizzi il sito Web WiseWorks o interagisce in altro modo con noi. Abbiamo anche offerto alcune sezioni per apprendere come puoi esercitare i tuoi diritti e le tue scelte sulla privacy.",
    "privacypolicyext_para3": "Si prega di leggere attentamente la presente Informativa sulla privacy prima di accedere al nostro sito Web e utilizzare i servizi WiseWorks. In caso di domande, contattaci all'indirizzo ",

    "privacypolicyext_topic1": "Panoramica",
    "privacypolicyext_topic2": "Accettazione e utilizzo",
    "privacypolicyext_topic3": "Chi siamo",
    "privacypolicyext_topic4": "Riepilogo delle informazioni personali e dell'utilizzo",
    "privacypolicyext_topic5": "Finalità della raccolta dei dati",
    "privacypolicyext_topic6": "Principi sulla protezione dei dati",
    "privacypolicyext_topic7": "Tipi di dati che raccogliamo da te",
    "privacypolicyext_topic8": "Cookie e web beacon",
    "privacypolicyext_topic9": "Notificarti in merito a sondaggi e progetti di ricerca",
    "privacypolicyext_topic10": "Come utilizziamo i tuoi dati",
    "privacypolicyext_topic11": "Con chi condividiamo i tuoi dati",
    "privacypolicyext_topic12": "Soggetti interessati EEA e non EEA",
    "privacypolicyext_topic13": "I tuoi diritti sulla protezione dei dati",
    "privacypolicyext_topic14": "La nostra politica nei confronti dei bambini",
    "privacypolicyext_topic15": "La sicurezza dei dati",
    "privacypolicyext_topic16": "Termini e Condizioni",
    "privacypolicyext_topic17": "Giurisdizione",
    "privacypolicyext_topic18": "Modifiche alla nostra politica sulla privacy",
    "privacypolicyext_topic19": "Contattaci",

    "privacypolicyext_t1": "noialtri",
    "privacypolicyext_t2": "noi",
    "privacypolicyext_t3": "o",
    "privacypolicyext_t4": "il nostro ",

    "privacypolicyext_topic1ansp1": " rispetta i diritti alla privacy dei suoi utenti e comprende l'importanza di proteggere e gestire le informazioni raccolte su di te sia secondo la legge che secondo le migliori pratiche. La presente Informativa sulla privacy è stata scritta per conformarsi a",
    "privacypolicyext_topic1ansp1_1": "Articolo 13 e articolo 14 del regolamento generale sulla protezione dei dati dell'UE, ovvero il regolamento (UE) 2016/679 (&ldquo;GDPR&rdquo;) e il CCPA della California Consumer Privacy Acts.",
    "privacypolicyext_topic1ansp2": "La presente Informativa sulla privacy si applica alle informazioni personali che elaboriamo relative a persone che hanno:",
    "privacypolicyext_topic1ansp2_l1": "Registrati per un account con noi;",
    "privacypolicyext_topic1ansp2_l2": "accettato di ricevere informazioni di marketing da noi;",
    "privacypolicyext_topic1ansp2_l3": "partecipare a sondaggi;",
    "privacypolicyext_topic1ansp2_l4": "contattaci per richieste e supporto",
    "privacypolicyext_topic1ansp3": 'Questa informativa sulla privacy descrive anche quali diritti hai riguardo ai tuoi dati, come dai il tuo consenso e come puoi revocare il tuo consenso. WiseWorks (insieme alle sue affiliate, la "Società") possiede e gestisce diversi siti web, app mobili e servizi interattivi, inclusi, senza limitazioni, Wisesample.com, Flapbucks.com, WisePerks.com e altri (collettivamente, i "Siti WiseWorks "). La presente informativa sulla privacy ("Informativa sulla privacy") si applica ai Siti WiseWorks ea tutte le funzionalità, applicazioni mobili, e-mail, servizi online e altre funzionalità, accessibili tramite computer, dispositivo mobile o altro, e ai servizi disponibili su o attraverso i siti e le funzionalità di WiseWorks (i "Servizi"). La presente Informativa sulla privacy copre solo le informazioni raccolte dalla Società attraverso i Siti e le funzionalità WiseWorks ei nostri Servizi e non copre alcuna informazione ricevuta o raccolta offline dalla Società (se non espressamente indicato) o raccolta da siti Web o società non correlati a cui possiamo fornire collegamenti.',

    "privacypolicyext_topic2ansp1_1": "Questa politica sulla privacy copre ",
    "privacypolicyext_topic2ansp1_2": " e le sue controllate, tra cui",
    "privacypolicyext_topic2ansp1_3": "collettivamente ",
    "privacypolicyext_topic2ansp1_4": "Utilizzando il nostro sito Web, dichiari di accettare i termini della presente Informativa sulla privacy, che è considerata il tuo consenso ufficiale al trattamento delle tue informazioni personali, il tuo consenso alla raccolta, all'uso e alla divulgazione delle tue informazioni personali come descritto di seguito. Potremmo chiederti di fornire ulteriori divulgazioni o informazioni sulle pratiche di trattamento dei dati di Servizi specifici. Questi avvisi possono integrare o chiarire le nostre pratiche sulla privacy o fornirti altre scelte su come trattiamo i tuoi dati.",
    "privacypolicyext_topic2ansp2": "Se non sei a tuo agio con qualsiasi aspetto di questa Informativa sulla privacy. È necessario interrompere immediatamente qualsiasi accesso al sito Web WiseWorks e al nostro sito Web.",

    "privacypolicyext_topic3ansp1_1": " è una società costituita e registrata in Canada (# 10369675). La nostra sede legale è a ",
    "privacypolicyext_topic3ansp1_2": "Forniamo servizi di panel per le industrie di ricerche di mercato online. Consentiamo agli utenti o ai partecipanti che soddisfano i nostri requisiti di idoneità di registrarsi per un account di panel utente e di partecipare a sondaggi per ricevere punti o altri incentivi.",
    "privacypolicyext_topic3ansp2": "I nostri servizi sono disponibili in diversi paesi, così come in California (USA), Canada, Germania, India, ecc. I Servizi non sono destinati all'uso da parte di Utenti di età inferiore ai 18 anni; devi avere almeno 18 anni per utilizzare il nostro sito Web e i nostri servizi.",

    "privacypolicyext_topic4ansp1": "Possiamo raccogliere o ottenere Dati personali su di te e queste informazioni possono essere archiviate o elaborate all'interno della Regione degli Stati Uniti attraverso i seguenti metodi:",
    "privacypolicyext_topic4ansp1_l1": "Direttamente da te (ad esempio, dove ci contatti); nel corso del nostro rapporto con te (ad esempio, se utilizzi i nostri servizi); ",
    "privacypolicyext_topic4ansp1_l2": "quando visiti il nostro sito web; o utilizzare qualsiasi parte del sito web;",
    "privacypolicyext_topic4ansp2": "Potremmo anche ricevere informazioni personali su di te da terze parti.",

    "privacypolicyext_topic5ansp1": "I seguenti sono esempi di come utilizziamo le tue informazioni personali:",
    "privacypolicyext_topic5ansp1_l1": "Fornitura di servizi",
    "privacypolicyext_topic5ansp1_l2": "Offerta e miglioramento del sito web",
    "privacypolicyext_topic5ansp1_l3": "Sondaggi",
    "privacypolicyext_topic5ansp1_l4": "Comunicazione",
    "privacypolicyext_topic5ansp1_l5": "Coinvolgimento degli utenti",
    "privacypolicyext_topic5ansp1_l6": "Marketing",
    "privacypolicyext_topic5ansp1_l7": "Lead Generation",
    "privacypolicyext_topic5ansp1_l8": "Conformità legale",

    "privacypolicyext_topic6ansp1": "Raccogliamo, elaboriamo e utilizziamo i dati personali (secondo le definizioni dell'articolo 13 e dell'articolo 14 del regolamento generale sulla protezione dei dati dell'UE, ovvero il regolamento (UE) 2016/679 ('GDPR') e il CCPA della California Consumer Privacy Acts) per fornire i nostri servizi. Per dati personali si intendono tutte le informazioni che ci consentono di identificarti, direttamente o indirettamente, come nome, e-mail, indirizzo, informazioni sanitarie, qualsiasi forma di numero di identificazione o uno o più fattori specifici per te o per l'identità del tuo beneficiario.",
    "privacypolicyext_topic6ansp2": "Ci impegniamo a rispettare le leggi sulla protezione dei dati applicabili e garantiremo che i dati personali siano:",
    "privacypolicyext_topic6ansp2_l1": "Usato in modo lecito, equo e trasparente;",
    "privacypolicyext_topic6ansp2_l2": "Raccolti solo per scopi validi che ti abbiamo chiaramente spiegato e non utilizzati in alcun modo incompatibile con tali scopi;",
    "privacypolicyext_topic6ansp2_l3": "Rilevante per gli scopi di cui ti abbiamo parlato e limitato solo a tali scopi;",
    "privacypolicyext_topic6ansp2_l4": "Accurato e aggiornato;",
    "privacypolicyext_topic6ansp2_l5": "Conservato solo per il tempo necessario per gli scopi di cui ti abbiamo parlato;",

    "privacypolicyext_topic7ansp1": "Potremmo raccogliere ed elaborare le seguenti informazioni personali su di te:",
    "privacypolicyext_topic7ansp1_l1title": "Informazioni sul profilo personale.",
    "privacypolicyext_topic7ansp1_l1": "Se registri un account con noi, raccoglieremo il tuo nome e cognome, il tuo indirizzo e-mail e la password scelta.",
    "privacypolicyext_topic7ansp1_l2title": "Informazioni sui contatti.",
    "privacypolicyext_topic7ansp1_l2": "Se scegli di ricevere sondaggi tramite messaggio di testo o altri mezzi di messaggistica, raccogliamo anche il tuo numero di cellulare oi dettagli di contatto pertinenti.",
    "privacypolicyext_topic7ansp1_l3title": "Dati del questionario.",
    "privacypolicyext_topic7ansp1_l3": "Il questionario di profilazione iniziale raccoglie informazioni sul tuo background, istruzione, stato lavorativo, abitudini di consumo e, facoltativamente, sulla tua salute. Raccogliamo informazioni su eventuali aggiornamenti apportati alle informazioni di profilazione.",
    "privacypolicyext_topic7ansp1_l4title": "Dati di opinione.",
    "privacypolicyext_topic7ansp1_l4": "Durante le opportunità di ricerca, raccogliamo le informazioni fornite in relazione alle vostre opinioni, atteggiamenti, esperienze e utilizzo di determinati prodotti o servizi. Occasionalmente potremmo raccogliere contributi video, audio o fotografici da te effettuati durante un'opportunità di ricerca.",
    "privacypolicyext_topic7ansp1_l5title": "Dati sull'esperienza utente.",
    "privacypolicyext_topic7ansp1_l5": "Raccogliamo informazioni sui tuoi accessi, commenti su post di blog, valutazioni, sondaggi, pagine web visualizzate.",
    "privacypolicyext_topic7ansp1_l6title": "Informazioni sui premi.",
    "privacypolicyext_topic7ansp1_l6": "Qualsiasi informazione personale che potremmo richiedere per assegnarti un premio o ricompensa o monitorare il tuo utilizzo delle nostre attività di panel e sondaggio.",
    "privacypolicyext_topic7ansp1_l7title": "Dati di corrispondenza.",
    "privacypolicyext_topic7ansp1_l7": "Qualsiasi corrispondenza che ci invii.",
    "privacypolicyext_topic7ansp1_l8title": "Dati di utilizzo.",
    "privacypolicyext_topic7ansp1_l8": "Raccogliamo anche dati di utilizzo su di te ogni volta che interagisci con i nostri servizi. Ciò può includere quali pagine web visiti, su cosa fai clic quando hai eseguito tali azioni e così via. Inoltre, conserviamo file di registro digitali che registrano i dati ogni volta che un dispositivo accede ai nostri server. I file di registro contengono dati sulla natura di ogni accesso. Raccogliamo dati dal dispositivo e dall'applicazione che utilizzi per accedere ai nostri servizi, come indirizzo IP, tipo di browser, provider di servizi Internet (ISP), pagine di riferimento / uscita, sistema operativo, data / ora e dati clickstream.",
    "privacypolicyext_topic7ansp2": "Possiamo anche dedurre la tua posizione geografica in base al tuo indirizzo IP. Se si arriva a un servizio WiseWorks da una fonte esterna (come un collegamento su un altro sito Web, contenuto in un'e-mail o in un'app di messaggistica), potremmo utilizzare queste informazioni per analizzare le tendenze, amministrare il sito, monitorare gli utenti e raccogliere informazioni demografiche sulla nostra base di utenti nel suo complesso.",

    "privacypolicyext_topic8ansp1": "A cookie is a text file containing a small amount of information that is sent to your browser when you visit a website.The cookie is then sent back to the originating website on each subsequent visit, or to another website that recognises it.Cookies are an extremely useful technology and do lots of different jobs.A Web beacon is an often- transparent graphic image, usually no larger than 1 - pixel x 1 pixel, that is placed on a website or in an email that is used to monitor the behaviour of the user visiting the website or sending the email.It is often used in combination with cookies.",
    "privacypolicyext_topic8ansp2": "Possiamo raccogliere informazioni tramite l'uso di cookie, web beacon o tecnologie simili basate sull'analisi.",
    "privacypolicyext_topic8ansp3_title": "Quali cookies utilizziamo?",
    "privacypolicyext_topic8ansp3": "The cookies that we use fall into the following categories:",
    "privacypolicyext_topic8ansp3_l1title": "Cookie strettamente necessari.",
    "privacypolicyext_topic8ansp3_l1": "Si tratta di cookie necessari per il funzionamento del nostro sito Web. Includono, ad esempio, cookie che consentono di accedere ad aree protette del nostro sito Web.",
    "privacypolicyext_topic8ansp3_l2title": "Cookie analitici / prestazionali.",
    "privacypolicyext_topic8ansp3_l2": "Ci consentono di riconoscere e contare il numero di visitatori e di vedere come i visitatori si muovono nel nostro sito Web quando lo utilizzano. Questo ci aiuta a migliorare il modo in cui funziona il nostro sito web, ad esempio, assicurando che gli utenti trovino facilmente ciò che stanno cercando.",
    "privacypolicyext_topic8ansp3_l3title": "Cookie di funzionalità.",
    "privacypolicyext_topic8ansp3_l3": "Vengono utilizzati per riconoscerti quando torni sul nostro sito web. Questo ci consente di personalizzare i nostri contenuti per te, salutarti per nome e ricordare le tue preferenze.",
    "privacypolicyext_topic8ansp3_l4title": "Cookie mirati.",
    "privacypolicyext_topic8ansp3_l4": "Questi cookie registrano la tua visita al nostro sito web, le pagine che hai visitato ei link che hai seguito.Useremo queste informazioni per rendere il nostro sito web e la pubblicità mostrata su di esso più pertinenti ai tuoi interessi. Potremmo anche condividere queste informazioni con terze parti per questo scopo.",
    "privacypolicyext_topic8ansp4": "Utilizziamo vari tipi di cookie (inclusi alcuni che inseriamo e altri che consentiamo a terzi di inserire sul nostro sito) per diversi scopi. Le informazioni raccolte dai cookie ci consentono di monitorare quante persone stanno utilizzando i nostri servizi e le loro interazioni con altri servizi, quante persone aprono le nostre e-mail e per quali scopi vengono intraprese queste azioni. I cookie vengono utilizzati anche per facilitare l'accesso di un utente, come ausili per la navigazione e come timer di sessione e per limitare l'utilizzo da parte dei minorenni dei nostri strumenti e servizi. Non colleghiamo informazioni non personali dai cookie a informazioni di identificazione personale senza autorizzazione. Se per qualsiasi motivo non desideri che tracciamo il tuo utilizzo del nostro sito utilizzando i cookie, puoi disattivarlo utilizzando le impostazioni del browser e puoi anche selezionare l'opzione &ldquo;Do Not Track&rdquo; (per ulteriori informazioni, fai riferimento al tuo browser ). La maggior parte dei browser offre istruzioni su come reimpostare il browser per rifiutare i cookie nella sezione &ldquo;Guida&rdquo; della barra degli strumenti.",
    "privacypolicyext_topic8ansp5": "Utilizziamo i web beacon per raccogliere informazioni sull'utilizzo dei nostri servizi. Le informazioni raccolte dai web beacon ci consentono di monitorare quante persone stanno utilizzando i nostri servizi, quante persone aprono le nostre e-mail e determinare per quali scopi vengono intraprese queste azioni I nostri web beacon non vengono utilizzati per tracciare attività al di fuori dei nostri servizi.",
    "privacypolicyext_topic8ansp6_title": "Come posso modificare le mie impostazioni sui cookie?",
    "privacypolicyext_topic8ansp6_1": "La maggior parte dei browser Web consente un certo controllo della maggior parte dei cookie tramite le impostazioni del browser. Per saperne di più sui cookie, incluso come vedere quali cookie sono stati impostati, visitare www.aboutcookies.org o www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "Per scoprire come gestire i cookie sui browser più diffusi:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "Quando ti unisci a WiseWorks, potremmo comunicare con te tramite e-mail, telefono o servizio di messaggistica (a seconda delle tue preferenze) in merito alla tua iscrizione.Occasioni, quando possiamo comunicare con te includono quanto segue:",
    "privacypolicyext_topic9ansp1_l1": "Opportunità di ricerca (come sondaggi, sondaggi, focus group, ecc.)",
    "privacypolicyext_topic9ansp1_l2": "Risultati della ricerca",
    "privacypolicyext_topic9ansp1_l3": "Newsletter dei membri del panel",
    "privacypolicyext_topic9ansp2": "I nostri progetti possono concentrarsi su un'ampia gamma di argomenti. Normalmente ti inviteremo a partecipare ai progetti inviandoti un'e-mail sull'opportunità. A quel punto, spiegheremo l'argomento generale del sondaggio, la sua probabile durata o il coinvolgimento richiesto e la ricompensa offerta. Spetta quindi a te decidere se partecipare o meno. La partecipazione non è mai obbligatoria. Puoi sempre ritirarti in qualsiasi momento, anche se preferiamo che tu completi le attività una volta avviate e normalmente non paghiamo alcun incentivo per le attività incomplete.",
    "privacypolicyext_topic9ansp3": "Ci aspettiamo che tu fornisca sempre informazioni veritiere, accurate, aggiornate e complete come richiesto.",


    "privacypolicyext_topic10ansp1": "Utilizzeremo i tuoi dati personali solo quando la legge ce lo consente. Più comunemente, utilizzeremo i tuoi dati personali nelle seguenti circostanze, ma per ulteriori informazioni sui tipi di basi legali su cui faremo affidamento per elaborare i tuoi dati, fai clic su Qui.:",
    "privacypolicyext_topic10ansp1_l1": "Fornirti i nostri prodotti e servizi",
    "privacypolicyext_topic10ansp1_l2": "trovare le persone più adatte per ogni opportunità di ricerca;",
    "privacypolicyext_topic10ansp1_l3": "ridurre il numero di domande che dobbiamo porre nei sondaggi;",
    "privacypolicyext_topic10ansp1_l4": "condurre ulteriori analisi sui dati di ricerca che abbiamo già raccolto;",
    "privacypolicyext_topic10ansp1_l5": "in forma aggregata per aiutarci a spiegare le nostre capacità di ricerca ai nostri clienti.",
    "privacypolicyext_topic10ansp1_l6": "invitarti a cercare opportunità come sondaggi, focus group, comunità online, monitoraggio passivo dei siti web che visiti, ecc.",
    "privacypolicyext_topic10ansp1_l7": "inviarti premi incentivi che hai guadagnato,",
    "privacypolicyext_topic10ansp1_l8": "chiederti di aggiornare il tuo profilo,",
    "privacypolicyext_topic10ansp1_l9": "Invitarti a partecipare a sondaggi sui nostri prodotti o servizi",
    "privacypolicyext_topic10ansp1_l10": "Monitorare, rilevare e prevenire frodi, attività improprie e garantire la conformità alle policy di WiseWorks",
    "privacypolicyext_topic10ansp1_l11": "Condurre ricerche comprese le ricerche secondarie e di follow-on",
    "privacypolicyext_topic10ansp1_l12": "Valuta come tu e gli altri utenti interagite con i nostri servizi online",
    "privacypolicyext_topic10ansp1_l13": "Protect WiseWorks and our users",
    "privacypolicyext_topic10ansp1_l14": "Condurre audit relativi alle nostre attuali interazioni con te",
    "privacypolicyext_topic10ansp1_l15": "Eseguire il debug del sito e del servizio per identificare e riparare gli errori che compromettono la funzionalità prevista esistente",
    "privacypolicyext_topic10ansp1_l16": "Difendersi in contenziosi e indagini e perseguire contenziosi",
    "privacypolicyext_topic10ansp1_l17": "Per adempiere ad obblighi legali e regolamentari.",


    "privacypolicyext_topic11ansp1": "WiseWorks condivide le tue informazioni personali con terze parti solo nelle circostanze descritte di seguito.Non vendiamo o divulghiamo in altro modo le informazioni personali che raccogliamo su di te per un corrispettivo monetario o di altro valore.",
    "privacypolicyext_topic11ansp1_l1title": "Fornitori autorizzati:",
    "privacypolicyext_topic11ansp1_l1": "Potremmo condividere i tuoi dati con fornitori di terze parti che ci aiutano a far funzionare il nostro sito web, elaborare i pagamenti e fornire i nostri servizi e anche rispettare le tue istruzioni e i nostri obblighi contrattuali. Questi includono processori di pagamento, reti di distribuzione di contenuti (CDN), servizi di hosting basati su cloud, servizi di monitoraggio, fornitori di servizi di posta elettronica, fornitori di test e garanzia di qualità, fornitori di prevenzione di frodi e abusi, gestione delle relazioni con i clienti (CRM), fornitori",
    "privacypolicyext_topic11ansp1_l2title": "Partner pubblicitario e di analisi:",
    "privacypolicyext_topic11ansp1_l2": "Possiamo condividere i dati sull'utilizzo con inserzionisti e fornitori di analisi di terze parti tramite cookie e altre tecnologie simili.Queste terze parti possono raccogliere informazioni inviate dal tuo computer, browser o dispositivo mobile in risposta a una richiesta di contenuto, come identificatori univoci, tuo Indirizzo IP o altre informazioni sul tuo computer o dispositivo.",
    "privacypolicyext_topic11ansp1_l3title": "Soci in affari:",
    "privacypolicyext_topic11ansp1_l3": "A volte collaboriamo con terze parti per offrirti eventi, promozioni, concorsi e altri programmi. Per partecipare a queste offerte, ti potrebbe essere richiesto di fornire il tuo indirizzo e-mail e ulteriori informazioni personali. Condivideremo solo il tuo indirizzo e-mail e altre informazioni personali. con un partner commerciale quando ci autorizzi espressamente a farlo.",
    "privacypolicyext_topic11ansp1_l4title": "Certain legal obligations: ",
    "privacypolicyext_topic11ansp1_l4": "A WiseWorks potrebbe essere richiesto di divulgare informazioni personali se indicato da un tribunale o da altri enti governativi. Senza limitare quanto precede, ci riserviamo il diritto di divulgare tali informazioni laddove abbiamo una base in buona fede per ritenere che tale azione sia necessaria per: rispettare le leggi, i regolamenti, le ordinanze dei tribunali, le richieste del governo e delle forze dell'ordine, proteggere e difendere i diritti e la proprietà di WiseWorks o di terzi, o la sicurezza di WiseWorks, dei nostri utenti, dei nostri dipendenti o altri, prevenire, rilevare, indagare e adottare misure contro attività criminali, frodi e uso improprio o non autorizzato del nostro sito Web e per far rispettare i nostri Termini di utilizzo o altri accordi o politiche, nella misura consentita dalla legge, tenteremo di darti un preavviso prima di divulgare le tue informazioni in risposta a tale richiesta.",
    "privacypolicyext_topic11ansp1_l5title": "Con il tuo consenso:",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks può inviare le tue informazioni personali a terze parti quando acconsenti a farlo o nella tua direzione, ad esempio quando completiamo una transazione su tua richiesta.",
    "privacypolicyext_topic11ansp1_l6title": "Con i fornitori di servizi:",
    "privacypolicyext_topic11ansp1_l6": "Possiamo condividere le informazioni personali con fornitori, consulenti, processori di pagamento e altri fornitori di servizi che ci forniscono servizi specifici e informazioni di processo univoche per nostro conto. Questi servizi possono includere la fornitura di assistenza clienti, l'esecuzione di analisi aziendali e delle vendite, il supporto delle funzionalità del nostro sito Web, la facilitazione dell'elaborazione dei pagamenti, il supporto di concorsi, sondaggi e altre funzionalità offerte sul nostro sito Web. Questi fornitori di servizi non sono autorizzati a utilizzare le tue informazioni personali per nessun altro scopo. Il loro utilizzo di tali dati personali sarà soggetto ad adeguate misure di riservatezza e sicurezza.",
    "privacypolicyext_topic11ansp1_l7title": "Con affiliati:",
    "privacypolicyext_topic11ansp1_l7": "Possiamo condividere le informazioni personali con alcuni dei nostri partner, affiliati e altre società sotto controllo e proprietà comuni, soggetti ai termini della presente Informativa sulla privacy. Potremmo anche condividere le tue informazioni personali in relazione a una transazione aziendale sostanziale, come una vendita della nostra attività, una cessione, fusione, consolidamento o vendita di attività, in caso di fallimento o preparazione per uno di questi eventi. Qualsiasi altra entità che ci acquista o diventa parte della nostra attività avrà il diritto di continuare a utilizzare le tue informazioni personali, ma solo nel modo stabilito nella presente Informativa sulla privacy, a meno che tu non accetti diversamente.",

    "privacypolicyext_topic12ansp1_title": "Basi legali per l'elaborazione delle tue informazioni:",
    "privacypolicyext_topic12ansp1": "Per le persone che si trovano nello Spazio economico europeo, nel Regno Unito o in Svizzera (collettivamente &ldquo;Residenti SEE&rdquo;), i dati raccolti dall'utente possono essere trasferiti o elaborati negli Stati Uniti (collettivamente &ldquo;Soggetti interessati&rdquo;). Quando i loro dati vengono raccolti, ci basiamo su basi legali per l'elaborazione delle tue informazioni. Generalmente elaboriamo i tuoi dati solo dove ci è richiesto, laddove il trattamento è necessario per fornirti i nostri servizi (o per prendere provvedimenti su tua richiesta prima di stipulare un contratto con te).",
    "privacypolicyext_topic12ansp2_title": "Shine the Light (For California Citizens) CCPA",
    "privacypolicyext_topic12ansp2_1": "La legge &ldquo;Shine the Light&rdquo; della California consente ai clienti in California di richiedere dettagli specifici su come alcuni tipi di informazioni vengono condivise con terze parti e, in alcuni casi, affiliate per scopi di marketing diretto di tali terze parti e affiliate. Secondo la legge, un'azienda dovrebbe fornire ai clienti della California determinate informazioni su richiesta o consentire ai clienti della California di rinunciare a questo tipo di condivisione.",
    "privacypolicyext_topic12ansp2_2": 'Per esercitare una richiesta Shine the Light, contattaci. Devi inserire la dichiarazione "I tuoi diritti sulla privacy in California" nel corpo della richiesta, oltre al tuo nome, indirizzo, città, stato e codice postale. informazioni per determinare se ciò si applica a te nel corpo della tua richiesta.Tieni presente che non accetteremo richieste via telefono, e-mail e non siamo responsabili per avvisi che non sono etichettati o inviati correttamente o che non dispongono di informazioni complete.',
    "privacypolicyext_topic12ansp3_title": "I tuoi diritti sulla privacy del Nevada",
    "privacypolicyext_topic12ansp3": "Se risiedi in Nevada, hai il diritto di rinunciare alla vendita di Dati personali specifici a terzi che intendono concedere in licenza o vendere tali Dati personali.Puoi esercitare questo diritto contattandoci qui o inviandoci un'e- mail con l'oggetto riga &ldquo;Nevada Do Not Sell Request&rdquo; e fornendoci il tuo nome e l'indirizzo email associato al tuo account.",

    "privacypolicyext_topic13ansp1": "Ti consentiamo di fare numerose scelte sui tuoi dati: Di seguito sono riportati i seguenti diritti di protezione dei dati che hai quando usi i nostri servizi.",
    "privacypolicyext_topic13ansp1_l1": "Puoi scegliere di non fornirci determinate informazioni.",
    "privacypolicyext_topic13ansp1_l2": "Supponiamo che tu desideri accedere, correggere, aggiornare o richiedere la cancellazione delle tue informazioni personali.In tal caso, puoi farlo in qualsiasi momento contattandoci tramite il portale del servizio clienti o alle informazioni fornite in fondo alla presente Informativa sulla privacy.",
    "privacypolicyext_topic13ansp1_l3": "Se richiedi l'accesso ai tuoi dati, confermeremo se conserviamo le tue informazioni e te ne forniremo una copia, spiegando come le elaboriamo e perché, per quanto tempo le conserviamo ei tuoi diritti ad esse associate.",
    "privacypolicyext_topic13ansp1_l4": "Se richiedi la cancellazione delle tue informazioni personali, le cancelleremo.Si prega di notare che avremo bisogno di conservare tutte le informazioni di cui abbiamo bisogno per adempiere ai nostri obblighi legali o stabilire, esercitare o difendere un reclamo legale.",
    "privacypolicyext_topic13ansp1_l5": "Puoi opporti al trattamento dei tuoi dati personali, chiederci di limitare il trattamento dei tuoi dati personali o richiedere la portabilità dei tuoi dati personali. Anche in questo caso, puoi esercitare questi diritti contattandoci tramite il portale del servizio clienti o alle informazioni fornite in fondo alla presente Informativa sulla privacy.",
    "privacypolicyext_topic13ansp1_l6": 'Hai il diritto di rinunciare alle comunicazioni di marketing via e-mail che ti inviamo in qualsiasi momento. Puoi esercitare questo diritto facendo clic sul link "annulla iscrizione" o "rinuncia" nelle e-mail di marketing che ti inviamo.',
    "privacypolicyext_topic13ansp1_l7": "Disattivazione dei messaggi da parte nostra: per rinunciare a qualsiasi nostro messaggio promozionale futuro, è necessario annullare l'iscrizione nel corpo del messaggio promozionale inviato (che si trova in fondo all'e-mail) o inviare una richiesta di annullamento dell'iscrizione a noi. elaborerà la tua richiesta entro un termine ragionevole dal ricevimento.",
    "privacypolicyext_topic13ansp1_l8": "Allo stesso modo, se abbiamo raccolto ed elaborato le tue informazioni personali con il tuo consenso, puoi revocare il tuo consenso in qualsiasi momento. La revoca del consenso non pregiudicherà la liceità del trattamento che abbiamo condotto prima del ritiro, né influenzerà il trattamento dei tuoi dati personali condotto facendo affidamento su motivi di trattamento legittimi diversi dal consenso.",
    "privacypolicyext_topic13ansp1_l9": "Hai il diritto di presentare reclamo a un'autorità per la protezione dei dati in merito alla nostra raccolta e utilizzo delle tue informazioni personali. Contattaci per ulteriori informazioni e ti invieremo un link o l'indirizzo del nostro responsabile della protezione dei dati.",
    "privacypolicyext_topic13ansp1_l10": "Il modo più semplice per inviarci la tua richiesta è tramite la nostra e-mail di assistenza clienti che si trova in fondo alla presente Informativa sulla privacy. Rispondiamo a tutte le richieste che riceviamo da persone che desiderano esercitare i propri diritti di protezione dei dati ai sensi delle leggi sulla protezione dei dati applicabili.",

    "privacypolicyext_topic14ansp1": "Il sito Web ei servizi non sono rivolti a bambini di età inferiore ai tredici (18) anni. WiseWorks è conforme alla legge COPPA (Children's Online Privacy Protection). Non chiediamo consapevolmente di raccogliere informazioni personali da persone di età inferiore ai 18 anni. Si prega di avvisarci se si conoscono persone di età inferiore ai 18 anni che utilizzano il nostro sito Web per impedire loro di accedere ai nostri Servizi.",

    "privacypolicyext_topic15ansp1": "Ci impegniamo a mantenere la sicurezza dei tuoi dati, adottando misure per proteggerli dalla perdita, dall'uso improprio e dall'alterazione dei dati sotto il nostro controllo. Impieghiamo tecniche moderne e sicure per proteggere i nostri sistemi da intrusioni da parte di persone non autorizzate e aggiorniamo regolarmente la nostra sicurezza non appena saranno disponibili metodi migliori. I nostri data center ei nostri partner utilizzano nuove misure di sicurezza fisica per impedire l'accesso non autorizzato alla struttura. ",
    "privacypolicyext_topic15ansp2": "Tieni presente che nessuna misura di sicurezza è perfetta o impenetrabile, pertanto, sebbene utilizziamo pratiche standard del settore per proteggere la tua privacy, non possiamo (e non garantiamo) l'assoluta sicurezza dei dati personali.",


    "privacypolicyext_topic16ansp1": "Per saperne di più sui nostri termini e condizioni, visita la nostra pagina Termini di servizio, che stabilisce l'uso, la dichiarazione di non responsabilità e le limitazioni di responsabilità che regolano l'utilizzo del nostro sito web.",

    "privacypolicyext_topic17ansp1": "Le leggi degli Stati Uniti d'America regolano la presente informativa sulla privacy, i termini di servizio e altri servizi correlati.",

    "privacypolicyext_topic18ansp1": "WiseWorks si impegna costantemente a implementare nuove tecnologie e processi per proteggere meglio te, la tua privacy e il tuo utilizzo del servizio WiseWorks. Di conseguenza, WiseWorks potrebbe apportare modifiche alla presente informativa sulla privacy di volta in volta. comportare il degrado di qualsiasi misura di sicurezza progettata per proteggerti dalla divulgazione non autorizzata.",

    "privacypolicyext_topic19ansp1": "In caso di domande sulla presente informativa sulla privacy, contattaci all'indirizzo ",



  "faq_faq" : "Domande frequenti",
  "faq_getans" : "Ottieni risposte a tutte le tue domande.",

  "faq_ques1" : "Cos'è WisePerks?",
  "faq_ans1" : "Siamo contenti che tu abbia chiesto.WisePerks è un sito di sondaggi online.Un sacco di marchi e aziende stanno cercando gli intervistati per fornire le loro opinioni e ricevere ricompense in cambio.WisePerks è una di queste piattaforme in cui puoi dare la tua opinione e guadagnare anche dei premi.",

  "faq_ques2" : "È gratuito aderire?",
  "faq_ans2" : "Sì, è gratuito aderire.In effetti, se qualcuno ti sta chiedendo una tassa di adesione, vorremmo che tu ce lo segnalassi.",

  "faq_ques3" : "Posso riferire i miei amici?",
  "faq_ans3" : "Sì, puoi invitare i tuoi amici e colleghi a partecipare e anche a ricevere premi per questo. Fai clic sul link 'Segnala amico' nella tua dashboard per ottenere tutti i dettagli.",

  "faq_ques4" : "Cosa guadagno?",
  "faq_ans4" : "Ora, nel nostro nuovo modello, è possibile guadagnare punti in molti modi diversi solo prendendo i sondaggi. Ottieni punti da: registrarti con noi, verificando il tuo account e-mail, per completare il tuo profilo, naturalmente per completare qualsiasi sondaggio e con tua sorpresa anche per un sondaggio squalificato. Quindi abbiamo tutte le ragioni per iniziare non è vero?",

  "faq_ques5" : "Come posso diventare un membro di WisePerks.com?",
  "faq_ans5" : "Apri WisePerks.com sul tuo browser.Crea un accesso come membro facendo clic sulla scheda 'Registrati'.Segui semplicemente i passaggi e ti guiderà attraverso il resto del processo.Dovrai confermare la tua intenzione di aderire facendo clic sull'e-mail di conferma inviata da noi alla tua email fornita.",

  "faq_ques6" : "Dov'è l'e-mail di conferma che hai menzionato?",
  "faq_ans6p1" : "Una e-mail di conferma viene inviata automaticamente all'indirizzo di posta elettronica fornito al momento dell'iscrizione. Se non Vedi quell'email nella tua casella di posta, controlla la tua cartella spam o junk. Per evitare eventuali email future da noi e sondaggi invita/messaggi di raggiungere la cartella spam, si prega di fare aggiungere",
  "faq_ans6p2" : "all'elenco dei mittenti approvati o come contatto nella cassetta postale. Se ancora non riesci a trovare quella e-mail di conferma, per favore accedi al mio account, conferma che il tuo indirizzo email con noi è corretto, e Richiedi un'altra email di conferma.",

  "faq_ques7" : "Come posso fare un sondaggio?",
  "faq_ans7" : "Una volta effettuato l'accesso dopo aver inserito il nome utente e la password, si raggiunge la dashboard e si possono vedere tutti i sondaggi che si possono intraprendere. Abbiamo anche inviare il sondaggio link al tuo ID e-mail registrate. Puoi prendere il link direttamente dalla tua casella di posta.",

  "faq_ques8" : "Quanto sono confidenziali le mie risposte, i miei dati?",
  "faq_ans8" : "Per WisePerks, mantenere la privacy e la riservatezza degli utenti sono della massima priorità.I nostri clienti sono interessati solo a ciò che pensi, come consumatore o come ti senti in qualcosa.Noi di WisePerks, non condividiamo / trasmettiamo alcuna informazione personale ai nostri clienti, e nessuno dei sondaggi WisePerks non vende o affitta alcuna informazione a terzi.",

  "faq_ques9" : "Quanto sono lunghi questi sondaggi?",
  "faq_ans9" : "La maggior parte dei sondaggi richiede solo da 10 a 15 minuti, o anche meno.Tuttavia, di tanto in tanto, potremmo avere sondaggi più lunghi.Con ogni sondaggio, ti diciamo in anticipo quanto tempo ci vorrà, ma tieni presente che anche i sondaggi più lunghi hanno premi più alti.",

  "faq_ques10" : "Qualcuno può prendere sondaggi WisePerks?",
  "faq_ans10" : "Devi avere almeno 13 anni per registrarti a WisePerks.Anche se a volte ti inviteremo in sondaggi per partecipare con i membri più giovani della tua famiglia.",

  "faq_ques11" : "Come sai che mi qualificherò per un sondaggio?",
  "faq_ans11" : "Il nostro algoritmo corrisponde alle informazioni del tuo profilo con i sondaggi disponibili per assicurarti di ottenere i sondaggi più appropriati.Quando inizia un sondaggio, solitamente le prime domande determinano se sei il rispondente che il nostro cliente sta cercando per quel particolare sondaggio.Abbiamo una vasta gamma di sondaggi alla ricerca di molti tipi diversi di persone (ad esempio, persone che guardano film regolarmente, svolgono uno sport particolare, donne con bambini, ecc.) E nuovi sondaggi vengono pubblicati ogni giorno!",

  "faq_ques12" : "Quali sono i miei obblighi verso WisePerks?",
  "faq_ans12" : "L'unica cosa che ci aspettiamo da te è fornire onestamente le tue opinioni e i tuoi pensieri, mentre i clienti cercano le tue risposte oneste e sincere per migliorare i loro prodotti e servizi.Fornisci informazioni corrette al momento della registrazione come membro e aggiorna le modifiche non appena si verificano.",

  "faq_ques13" : "Perché dovrei fornire informazioni sulla mia famiglia, tecnologia o prodotti che compro?",
  "faq_ans13" : "Per ogni sondaggio, stiamo cercando un determinato profilo di intervistati.Se sappiamo dalle informazioni del tuo profilo che le tue informazioni corrispondono ai requisiti del cliente, bingo!Possiamo quasi certamente assicurare che sarai in grado di completare il sondaggio con successo e guadagnare il premio per questo.Questo in sostanza aumenta le tue possibilità di essere selezionato per un sondaggio.",

  "faq_ques14" : "Quando riceverò le mie carte regalo dopo aver completato un sondaggio su WisePerks?",
  "faq_ans14" : "I punti che guadagni dopo aver completato con successo un sondaggio sono accumulati nel vostro secchio. Una volta che questi punti sono realizzati si può riscattare.",

  "faq_ques15" : "Qual è il mio account in realtà?",
  "faq_ans15" : "È semplicemente un modo semplice per vedere le tue informazioni personali, apportare le modifiche necessarie, vedere la tua attività di sondaggio, i premi guadagnati, tutto in un unico posto.",

  "faq_ques16" : "Come posso cancellare il mio account?",
  "faq_ans16" : "Con così tanto venendo il vostro senso, siamo sicuri che non vorreste fare questo. Tuttavia, nella Dashboard--> vai a Impostazioni--> Gestisci account-> disattiva account",

  "faq_ques17" : "Come funziona il sistema di punti?",
  "faq_ans17p1" : "Ogni sondaggio è per un certo numero di punti. Dopo aver completato con successo un sondaggio, questi punti vengono aggiunti ai tuoi guadagni. Puoi riscattarli una volta raggiunta la soglia minima.",
  "faq_ans17p2" : "In alcuni casi a causa di problemi imprevisti con la connessione o interruzioni, la tua risposta potrebbe non essere catturata dal cliente. Dobbiamo quindi invertire quei punti dal tuo account. Se hai già riscattato quei punti e il tuo account ha un saldo basso, le tue entrate saranno negative. Queste modifiche verranno apportate nel prossimo ciclo di riscatto.",

  "faq_ques18" : "In quanto tempo i punti per un sondaggio vengono aggiunti al saldo punti?",
  "faq_ans18" : "I tuoi punti devono essere aggiunti al saldo punti in 48 ore.",

  "faq_ques19" : "Qual è la soglia minima per la redenzione?",
  "faq_ans19" : "La soglia minima per la redenzione è di 25000 punti.In media, è necessario completare 8-12 sondaggi per poter beneficiare della redenzione.",

  "faq_ques20" : "In che modo i punti vengono convertiti in contante? C'è un rapporto?",
  "faq_ans20" : "Sì. A seconda del Paese, i punti vengono convertiti in contanti con buoni regalo. I rapporti di conversione sono come mostrato di seguito",
  "faq_ponits" : "Punti",
  "faq_point" : "Punto",
  "faq_cent" : "centesimo",

  "faq_ques21" : "Hai ancora più domande?",
  "faq_ans21p1" : "Sentitevi liberi di scriverci a",
  "faq_ans21p2" : "e torneremo a voi entro 48 ore.",

  "Profiling_profiledashboard" : "Cruscotto profilo", 
  "Profiling_completeprofile" : "Completa il tuo profilo e ottenere enorme",
  "Profiling_completepoints" : "Punti!",
  "Profiling_answercarefully" : "Il seguente insieme generale delle domande sarà usato per generare il vostro profilo personale per fornirgli le occasioni di indagine future migliori. Si prega di prendere il vostro tempo, rispondere con attenzione e sinceramente.",

  "Profiling_cat_general" : "Generale",
  "Profiling_cat_personal" : "Personale",
  "Profiling_cat_household" : "Famiglia",
  "Profiling_cat_profession" : "Professione",

  "Profiling_bar_completed" : "% Completato",
  
  "Profiling_update_ans_button" : "Aggiorna e continua", 
  "Profiling_save_ans_button" : "Salvare",
  "Profiling_sc_ans_button" : "Salva e continua", 

  "Category_thanku_msg1" : "Buona andare",
  "Category_thanku_msg2" : "pochi di più e si avrà",
  "Category_thanku_msg3" : "punti nel tuo account.",

  "Category_thanku_update_msg1" : "Grazie per l'aggiornamento delle informazioni!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "Continuare",

  "OverAll_thanku_msg1" : "Grazie per aver condiviso le tue informazioni. Hai guadagnato", 
  "OverAll_thanku_msg2" : "Punti.", 

  "Survey_history_surveyhistory" : "Storia dell'indagine",
  "Survey_history_fromthisscreen" : "Da questa schermata, è possibile visualizzare tutte le transazioni.",
  "Survey_history_show" : "Visualizza",
  "Survey_history_entries" : "Voci",
  "Survey_history_date" : "Data",
  "Survey_history_details" : "Dettagli",
  "Survey_history_surveys" : "Sondaggi",
  "Survey_history_pointsreceived" : "Punti ricevuti",
  "Survey_history_result" : "Risultato",
  "Survey_history_status" : "Stato",
  "Survey_history_status_complete" : "Completo", 
  "Survey_history_status_disqualified" : "Squalificato", 
  "Survey_history_keyword" : "Parola chiave",
  "Survey_history_search" : "Ricerca",
  "Survey_history_to" : "A",
  "Survey_history_selectoption" : "Selezionare l'opzione",
  "Survey_history_earned" : "Guadagnato",
  "Survey_history_redeemed" : "Redento",
  "Survey_history_norecord" : "Nessun record trovato",
  "Survey_history_Surveypts" : "Punti di rilievo",
  "Survey_history_Promocode" : "Codice promozionale",

  "My_transactions_mytransactions" : "Le mie transazioni",
  "My_transactions_fromthisscreen" : "Da questa schermata, è possibile visualizzare tutte le transazioni.",
  "My_transactions_show" : "Visualizza",
  "My_transactions_entries" : "Voci",
  "My_transactions_transactionid" : "ID transazione",
  "My_transactions_date" : "Data",
  "My_transactions_details" : "Dettagli",
  "My_transactions_points" : "Punti",
  "My_transactions_status" : "Stato",
  "My_transactions_keyword" : "Parola chiave",
  "My_transactions_search" : "Ricerca",
  "My_transactions_to" : "A",
  "My_transactions_selectoption" : "Selezionare l'opzione",
  "My_transactions_status_earned" : "Guadagnato",
  "My_transactions_status_redeemed" : "Redento",
  "My_transactions_status_pending" : "Pendenti",
  "My_transactions_status_reverted" : "Ripristinato",
  "My_transactions_status_declined" : "Rifiutato",
  "My_transactions_norecord" : "Nessun record trovato",
  "My_transactions_off_msg1" : "Dopo ",
  "My_transactions_off_msg2" : ", tutti i dettagli della transazione verranno pubblicati nella scheda del libro Punti",

  "Passbook_heading" : "Libro punti",
  "Passbook_show" : "Mostrare",
  "Passbook_entries" : "inserimenti",
  "Passbook_search" : "Ricerca",
  "Passbook_type" : "genere",
  "Passbook_surveyid" : "indagini",
  "Passbook_date" : "Data",
  "Passbook_selectoption" : "Selezionare l'opzione",
  "Passbook_credit" : "Credito",
  "Passbook_debit" : "Addebito",
  "Passbook_survey_id" : "ID sondaggio",
  "Passbook_survey_pts" : "Punti di rilevamento",
  "Passbook_earned_pts" : "Punti",
  "Passbook_pending_pts" : "Punti in sospeso",
  "Passbook_promo_code" : "Codice promozionale",
  "Passbook_transaction_type" : "Tipo di transazione",
  "Passbook_balance" : "Equilibrio",
  "Passbook_status" : "Stato",
  "Passbook_type_soi" : "Registrazione",
  "Passbook_type_doi" : "verifica email",
  "Passbook_type_profile" : "Completamento del profilo",
  "Passbook_type_survey" : "Sondaggio",
  "Passbook_type_doir" : "Verifica e-mail di",
  "Passbook_type_doirt" : "Verifica e-mail tramite referral",
  "Passbook_type_surveyr" : "Tentativo di indagine di",
  "Passbook_type_surveyrt" : "Tentativo di indagine per rinvio",
  "Passbook_type_reward" : "Ricompensa",
  "Passbook_type_pending" : "regolazioni",
  "Passbook_type_reverted" : "Punti ripristinati",
  "Passbook_type_declined" : "Richieste rifiutate",
  "Passbook_type_redeem" : "Riscattare",
  "Passbook_type_balance" : "Portare avanti",
  "Passbook_balance_vary" : "Il saldo effettivo può variare",
  "Passbook_survey_debit_reason" : "Apprezziamo i tuoi sforzi. Tuttavia, a causa di alcune interruzioni incontrollabili della connessione durante il trasferimento dei dati, la risposta non può essere registrata. Dovremmo invertire i punti che hai ricevuto in precedenza per questo sondaggio. Ci scusiamo per l'inconveniente causato.",

  "Redeem_redeemed" : "Riscattare",
  "Redeem_redeemhistory" : "Riscattare la storia",
  "Redeem_history_norecord" : "Nessun record trovato",
  "Redeem_date" : "Data",
  "Redeem_details" : "Dettagli",
  "Redeem_points" : "Punti",
  "Redeem_status" : "Stato",
  "Redeem_lookslike" : "Sembra che ti sei perso la verifica del tuo account e-mail.",
  "Redeem_hurry" : "Fretta! si ottiene 1000 punti una volta verificato.",
  "Redeem_pleasecheck" : "Controlla la tua casella di posta e clicca sul link nell'email di conferma inviata per attivare il tuo account.",
  "Redeem_kindlycheck" : "Gentilmente controllare la vostra cartella spam/junk pure per l'e-mail di conferma.",
  "Redeem_getconfirmationemail" : "Non hai ancora ricevuto email di conferma?",
  "Redeem_resendconfirmationemail" : "Invia email di conferma",
  "Redeem_rewardredemptionrule" : "Ricompensa redenzione regola",
  "Redeem_completesurvey" : "Sondaggio completo",
  "Redeem_earnpoints" : "Guadagnare punti",
  "Redeem_redeemrewards" : "Riscatta premi",
  "Redeem_note" : "Nota:",
  "Redeem_yourearnedpoints" : "I tuoi punti guadagnati diventeranno rimborsabili dopo aver qualificato il sondaggio. Per riscattare la ricompensa, i punti minimi rimborsabili nel tuo account devono essere",
  "Redeem__points" : "Punti.",
  "Redeem_availablebalance" : "Saldo disponibile :",
  "Redeem_historydetails" : "Clicca qui per visualizzare i dettagli della cronologia di rimborso.", 
  "Redeem_reqsucc" : "Congratulazioni! I tuoi punti sono stati riscattati. Si prega di controllare la cassetta postale registrata per ulteriori dettagli.", 
  "Redeem_wisecheck_youhave" : "Congratulazioni! Ti manca solo un clic dalla tua ricompensa.",
  "Redeem_wisecheck_gcagainst" : "Buono regalo per",
  "Redeem_wisecheck_points" : "Punti.",
  "Redeem_wisecheck_issue" : "Il sistema ha acquisito alcune attività non riconosciute nel tuo account, a causa della quale non sei in grado di inviare una richiesta di riscatto. Si prega di contattare",
  "Redeem_emailverify_issue" : "Per riscattare i tuoi punti, ti chiederemo di verificare il tuo account e-mail registrato.",

  "Redeem_unsubscribe_issue" : "Non è possibile inviare una richiesta di riscatto, poiché si è annullata l'iscrizione a WisePerks. Si prega di contattare support@wiseperks.com per iscriversi nuovamente.",
  "Redeem_wisecheck_message1" : "You are not able to redeem due to some security reasons. Please contact to support on",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Confermare",
  "Redeem_Cancel_button" : "Annulla",
  "Redeem_pending_hello" : "Ciao",
  "Redeem_pending" : "registrazione",
  "Redeem_pending_msg1" : "Il tuo account mostra aggiustamento di",
  "Redeem_pending_msg2" : "punti da eseguire. Conferma per iniziare la transazione.",

  "Dashboard_wisecheck_issue" : "System has captured some unrecognized activity in your account, due to which you are unable to see the surveys. Please contact support@wiseperks.com for further clarification.",
  "Dashboard_unsubscribe_issue" : "Non è possibile riscattare a causa di alcuni motivi di sicurezza. Si prega di contattare per il supporto su",


  "WC_DashboardMsg_1" : "Oops! Nessun sondaggio per abbinare il tuo profilo al momento.",
  "WC_DashboardSubMsg_1" : "Non preoccuparti, stiamo rimescolando il nostro inventario e presto invieremo nuovi sondaggi a modo tuo.",

  "WC_DashboardMsg_2" : "Stiamo aggiornando il tuo Dashboard. Si prega di controllare per nuovi sondaggi dopo qualche tempo.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Grazie per aver completato il sondaggio. Hai appena guadagnato response_points punti. Accedi al tuo dashboard per visualizzare i punti accumulati totali.",
  "Survey_success_msg2" : "Grazie per aver completato il sondaggio. Hai appena guadagnato response_points Punti.",

  "Survey_success_but_nomore_survey" : "Congratulazioni! Hai appena guadagnato response_points punti per completare l'indagine. Si prega di controllare per le indagini imminenti dopo qualche tempo.",
  
  "Survey_terminate_pnt_msg1" : "Sembra che alcune delle vostre informazioni è leggermente diverso dal requisito. Apprezziamo il vostro tempo e gli sforzi, sarà l'aggiunta di response_points punti al tuo account. Siamo concentrati sul portare voi sondaggi più lucrativi e intuitivi.",
  
  "Survey_terminate_nopnt_msg1" : "Sembra che alcune delle tue risposte siano leggermente diverse dal requisito. Invieremo più sondaggi sulla tua strada. Grazie per il tuo tempo ed il tuo sforzo.",

  "TermsConditions_t&c" : "Termini e condizioni",
  "TermsConditions_WhenRegister" : "Quando ti registri",
  "TermsConditions_WhenYouCreate" : "Quando crei un account personale, ti chiediamo alcune informazioni personali, come menzionato qui:",
  "TermsConditions_YouCanUpdate" : "È possibile aggiornare o modificare le informazioni del profilo di base in qualsiasi momento.",
  "TermsConditions_YouCanOptOut" : "Puoi disattivare o chiudere il tuo account in qualsiasi momento.",
  "TermsConditions_WeRespectYour" : "Rispettiamo la tua privacy e facciamo il miglior uso della tecnologia per proteggere le tue informazioni da accessi fisici ed elettronici non autorizzati.",
  "TermsConditions_WeShareYour" : "Condividiamo le tue informazioni solo con le organizzazioni, che sono necessarie per il funzionamento di WiseWorks. E quelle organizzazioni devono prendersi cura dei nostri impegni per la tua privacy e sicurezza.",
  "TermsConditions_WhatInformation" : "Quali informazioni raccogliamo da voi",
  "TermsConditions_DirectlyCollected" : "Raccolti direttamente",
  "TermsConditions_EmailAddressAndPassword" : "Indirizzo email e password del tuo account con noi.",
  "TermsConditions_YourResponse" : "La tua risposta alle domande di profilazione.",
  "TermsConditions_AnyCustomer" : "Qualsiasi corrispondenza del servizio clienti.",
  "TermsConditions_AnyPersonalDetails" : "Tutti i dettagli personali che condividi per la redenzione",
  "TermsConditions_AnyOtherInformation" : "Qualsiasi altra informazione direttamente fornita dall'utente.",
  "TermsConditions_UsageData" : "Dati di utilizzo",
  "TermsConditions_YourIPAddress" : "Il tuo indirizzo IP, posizione geografica e cookie",
  "TermsConditions_InformationAboutTheDevice" : "Informazioni sul dispositivo che si utilizza",
  "TermsConditions_YourSurveyActivity" : "La tua attività di sondaggio, i punti nel tuo account, i pagamenti che ricevi dopo aver riscattato.",
  "TermsConditions_HowDoWeUse" : "Come usiamo i tuoi dati",
  "TermsConditions_SendingYouInvitation" : "Inviando le tue e-mail di invito per raccogliere il sondaggio per cui sei idoneo.",
  "TermsConditions_DetectAndPrevent" : "Rileva e previene l'attività fraudolenta.",
  "TermsConditions_ImproveYourExperience" : "Migliora la tua esperienza sulla piattaforma WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Le tue risposte vengono trasferite alle nostre terze parti e partner affiliati in un formato aggregato e non identificato.",
  "TermsConditions_OurPartnersCanAsk" : "I nostri partner possono chiederti di fornire il tuo nome, indirizzo email e altre informazioni di contatto durante un sondaggio.",
  "TermsConditions_YouAreFreeToDecline" : "Siete liberi di rifiutare di fornire i vostri dettagli ai nostri partner, se siete d'accordo che non state fornendo queste informazioni direttamente a loro e non a WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Le informazioni dell'utente saranno trattate in conformità con la relativa Informativa sulla privacy aziendale e non con l'Informativa sulla privacy di WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "I nostri partner sono impegnati a rispettare e proteggere la tua privacy.",
  "TermsConditions_WhatWeDont" : "Quello che non facciamo con esso",
  "TermsConditions_WeDontSellOnThat" : "Non vendiamo su tali informazioni a nessuno e non lo usiamo per venderle nulla. Chiederemo sempre il permesso prima di condividere i tuoi dati.",
  "TermsConditions_YourRightToControl" : "Il tuo diritto di controllare i tuoi dati",
  "TermsConditions_YouHaveFullControl" : "Hai il pieno controllo dei tuoi dati.",
  "TermsConditions_YouCanAccess" : "Puoi accedere, aggiornare o modificare le informazioni di base del tuo profilo ogni volta che vuoi.",
  "TermsConditions_YouCanUnsubscribe" : "Puoi annullare l'iscrizione e disattivare il tuo account.",
  "TermsConditions_YouAreFreeToGetYour" : "Sei libero di cancellare il tuo profilo inviandoci una mail a support@wiseperks.com. Cercheremo di risolvere la tua preoccupazione e se desideri comunque che il tuo account venga eliminato, dovrai farlo entro 72 ore.",
  "TermsConditions_DataSecurity" : "La sicurezza dei dati",
  "TermsConditions_OurTechTeamWorks" : "Il nostro team tecnico lavora duramente per proteggere tutti i tuoi dati.",
  "TermsConditions_WeMakeUseOfLatest" : "Facciamo uso delle più recenti tecnologie e misure per evitare qualsiasi uso improprio o accesso non autorizzato dei dati. Tuttavia, a causa della natura dell'ambiente online, non possiamo garantire al 100% la sicurezza dei dati.",
  "TermsConditions_WeTryOurBestToRule" : "Facciamo del nostro meglio per escludere i rischi, tuttavia non possiamo garantire un ambiente privo di rischi. Accettando questi Termini e Condizioni, riconosci e accetti questi rischi.",
  "TermsConditions_AnyQuestions" : "Per tutte le domande, sentitevi liberi di contattarci",
  "TermsConditions_IfYouHaveAnyQueries" : "Se avete domande o reclami sulla nostra politica sulla privacy e le procedure. Inviaci un'e-mail all'indirizzo support@wiseperks.com e ti risponderemo entro 48 ore.",

  "Validation_PleaseEnterProperDate" : "Si prega di inserire la data corretta di nascita",
  "Validation_PleaseSelectOne" : "Si prega di selezionare un'opzione!",
  "Validation_PleaseSelectValidOptions" : "Si prega di selezionare le opzioni valide!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Si prega di selezionare almeno un'opzione",
  "Validation_PleaseInsertTheZip" : "Si prega di inserire il codice postale / postale.",

  "Releases_releases" : "Rilascia",
  "Releases_theUseOfThis" : "L'utilizzo di questo sito Web è soggetto alle seguenti condizioni d'uso.",
  "Releases_featuresOfWP" : "Caratteristiche di WP Release",
  "Releases_updatedOn" : "aggiornato il",
  "Releases_WisePerksNow" : "WisePerks ora supporta più lingue",
  "Releases_youCanWalkThourgh" : "Potete camminare thourgh il luogo con molto più facilità ora poichè WisePerks è disponibile nella vostra lingua di preffered.",
  "Releases_languagesAvailable" : "Lingue disponibili:",
  "Releases_EarnMore" : "Guadagna di più con il nostro nuovo sistema Point",
  "Releases_theNewWisePerksAccumulates" : "Il nuovo WisePerks accumula punti nel tuo account. Questi punti detengono un valore molto più elevato e sono facili da guadagnare e riscattare. Con più opzioni di riscatto da selezionare, siamo sicuri che godrete il sondaggio prendendo esperienza.",
  "Releases_accumulatePoints" : "Accumulare punti con Hassel libero redeemption",
  "Releases_youGetTheBenefit" : "Si ottiene il beneficio di guadagnare lo stesso No. di punti, indipendentemente dalla valuta che si desidera redimere in.",
  "Releases_surveyDisqualification" : "Disqualificazione sondaggio non è più una cattiva notizia",
  "Releases_youEarnPoints" : "Si guadagnano punti anche quando non si qualificano per un sondaggio, hai iniziato a rispondere.",
  "Releases_newAndImproved" : "Layout nuovo e migliorato",
  "Releases_theFreshLookOf" : "L'aspetto fresco di WisePerks vi farà innamorare di esso. Ora è molto più facile da usare e sarebbe una grande esperienza per voi.",
  "Releases_whatAvatarAreYouToday" : "Che Avatar sei oggi",
  "Releases_youCanNowCreate" : "È ora possibile creare il proprio avatar per funzionalità sul cruscotto.",
  "Releases_freshAndHotSurveys" : "Sondaggi freschi e caldi sul tuo cruscotto",
  "Releases_youDontHaveTo" : "Non dovete preoccuparvi di refresing la vostra dashboard per visualizzare nuovi sondaggi, terremo il vostro cruscotto popolato con le ultime indagini automaticamente.",
  "Releases_promoCode" : "Funzione codice promozionale",
  "Releases_promoCodeInfo" : "Su richiesta popolare, WisePerks porta i codici promozionali per i relatori ogni settimana. Modo rapido per afferrare più premi.",
  "Releases_referFriend" : "Segnala un amico",
  "Releases_referFriendInfo" : "Ottieni i tuoi amici associati a WisePerks e ottieni fantastici premi. Cerca l'opzione Segnala amico nel tuo account.",

  "Dashboard_Promocode_Usepromo" : "Usa codice promozionale",
  "Dashboard_Promocode_to" : "A",
  "Dashboard_Promocode_earnminimum_1" : "guadagnare minimo",
  "Dashboard_Promocode_earnminimum_2" : "punti extra",
  "Dashboard_Promocode_minimum" : "minimo",
  "Dashboard_Promocode_minimumextrapoints" : "punti extra su ogni sondaggio completato oggi",
  "Dashboard_Promocode_apply" : "Applicare",
  "Dashboard_Promocode_message1" : "Codice promozionale non valido!",
  "Dashboard_Promocode_message2" : "Congratulazioni! Otterrai il minimo",
  "Dashboard_Promocode_message3" : "extra su ogni sondaggio completato oggi!",

  "Dashboard_modal_Enjoythefestive" : "Goda lo spirito festivo guadagnando i punti minimi di",
  "Dashboard_modal_Extrapointson" : "supplementari su ogni indagine che completate oggi.",

  "refer_friend_title" : "Segnala un amico e ricevi un premio",
  "refer_friend_step_first": "Invita un amico a WisePerks",
  "refer_friend_step_second": "Friend successfully signs up on WisePerks",
  "refer_friend_step_third": "Ottieni each_referral punti",
  "refer_friend_link_title": "Invita un amico ora!",
  "refer_friend_share_title": "Condividi il link",
  "refer_friend_guideline_title": 'In che modo "fare riferimento ad un amico" ti sarà utile:',
  "refer_friend_guideline_one": "Si prega di condividere il collegamento fornito in precedenza con i tuoi referral.",
  "refer_friend_guideline_two": "Puoi segnalare quante persone vuoi, una volta che 5 dei tuoi referral verificano il loro account e iniziano l'attività di sondaggio, i tuoi punti di riferimento guadagnati verranno aggiunti al tuo account.",
  "refer_friend_guideline_three": "Ottieni each_referral punti ciascuno per il referral di successo (con account email verificato e attività di sondaggio).",
  "refer_friend_guideline_four": "I punti saranno guadagnati in due fasi, partial_referral sulla verifica e-mail e partial_second_referral sull'attività di sondaggio da parte del referente.",
  "refer_friend_guideline_five": "Dopo 5 referenze riuscite (con account email e attività di sondaggio verificati), il link di riferimento verrà disabilitato.",
	
  }