import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profiling',
  templateUrl: './profiling.component.html',
  styleUrls: ['./profiling.component.css']
})
export class ProfilingComponent implements OnInit {

  showProfilingLoader: boolean = true;

  @Input()banner_show_hide:boolean;

  sidebarloading:number = 0;
  prof_ques_loading:number = 0;
  ProfilingPts:number;
    
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle('WisePerks | Online Survey Respondents Profiling');
  }

  getPanelistInfo(getevent){
    this.ProfilingPts = getevent['profiling_bonus_point'];
    }

  StopPageLoader(getJson){
    
    if(getJson['comp'] == 'leftbar' && getJson['flag'] == 1){
      this.sidebarloading = 1;
    }

    if(getJson['comp'] == "questioning" && getJson['flag'] == 1){
      this.prof_ques_loading = 1;
    }

    if(this.sidebarloading == 1 && this.prof_ques_loading == 1){
      this.showProfilingLoader = false;
    }
  }

  hidebanner(getevent){
    if(getevent.hide_banner == 0){
      this.banner_show_hide = false;
    } else {
      this.banner_show_hide = true;
    }
  }

}
