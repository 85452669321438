import { Component, OnInit } from '@angular/core';
import * as CommonJsFunc from '../../../../assets/js/common/common';
import { PanelistService } from '../../shared/panelist.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.css']
})
export class ReferFriendComponent implements OnInit {

	referral_code: string;
	eachReferral: number;
	partialReferral: number;
  showReferFriendLoader:boolean = true;

  	constructor(private _panelistService: PanelistService, private title: Title) { }

	ngOnInit() {
    this.title.setTitle('WisePerks | Respondent Refer Friend');
		//this.StopPageLoader({});
	}

  	// StopPageLoader(getJson){
  	// 	console.log("getJson : ",getJson);

   //  	// if(getJson['comp'] == 'leftbar' && getJson['flag'] == 1){
	  //   //   //this.showDashboardLoader = false;
	  //   //   this.GetLeftSideCompJson = true;
	  //   // }

	  //   // if(this.GetLeftSideCompJson == true){
	  //   //   this.showReferFriendLoader = false;
	  //   // }
  	// }

  	getPanelistInfo(getevent){
  		let encodedReferralCode = "ref!"+getevent['referral_code'];
  		this.referral_code = CommonJsFunc.encode64(encodedReferralCode);
  		
  		// let non_asian_country = [232,231,30,43,38,141,154,10,47,73,13,80,172,224,107,205,175];
  		
  		this._panelistService.ResetReferralPanelistPoints(); // reset panelist each referral points
      this._panelistService.ResetReferralPartialPanelistPoints(); // reset panelist partial referral points
  		this._panelistService.ResetReferralSecPartialPanelistPoints(); // reset panelist partial referral points
  		// if(non_asian_country.indexOf(getevent['country_id']) > -1){
  			this.eachReferral = 1000;
  			this.partialReferral = 500;
  			this._panelistService.SetReferralPanelistPoints(this.eachReferral);
        this._panelistService.SetReferralPartialPanelistPoints(this.partialReferral);
  			this._panelistService.SetReferralSecPartialPanelistPoints(this.partialReferral);
  		// } else {
  		// 	this.eachReferral = 500;
  		// 	this.partialReferral = 250;
  		// 	this._panelistService.SetReferralPanelistPoints(this.eachReferral);
  		// 	this._panelistService.SetReferralPartialPanelistPoints(this.partialReferral);
    //     this._panelistService.SetReferralSecPartialPanelistPoints(this.partialReferral);
  		// }
      this.showReferFriendLoader = false;
  	}

  	// function is used to auto copy refer link from refer friend refer link text box by clicking on copy icon
  	copyLink(referLink){
  		let referLinkValue = referLink+this.referral_code;
  		let selBox = document.createElement('textarea');
	    selBox.style.position = 'fixed';
	    selBox.style.left = '0';
	    selBox.style.top = '0';
	    selBox.style.opacity = '0';
	    selBox.value = referLinkValue;
	    document.body.appendChild(selBox);
	    selBox.focus();
	    selBox.select();
	    document.execCommand('copy');
	    document.body.removeChild(selBox);
  	}

}
