// Pourtegue
export const TRANSLATIONS_PT = {
  "home_header_selectlang": "Selecione o idioma",
  "home_header_loginbutton": "Entrar",
  "home_header_signupbutton": "Inscrever-se",
  "home_header_homebutton": "Casa", 
  
  "home_register_bannertxt1": "Aqui o seu",
  "home_register_bannertxt2": "pensamentos",
  "home_register_bannertxt3": "pode buscar você",
  "home_register_bannertxt4": "recompensa$",
  "home_register_bannertxt5": "Obter bônus",
  "home_register_bannertxt6": "No SignUp",
  "home_register_fbsignup": "CADASTRE-SE COM O FACEBOOK",
  "home_register_fblogin": "ENTRAR COM O FACEBOOK",
  "home_register_or": "OU",
  "home_register_emailsignup": "Inscreva-se com o e-mail",
  "home_register_emaillogin": "Login com e-mail",
  "home_register_fpass": "Esqueceu a senha?",
  "home_register_fname": "Primeiro nome",
  "home_register_lname": "Último nome",
  "home_register_email": "Endereço de e-mail",
  "home_register_pass": "Senha",
  "home_register_cpass": "Confirme a Senha",
  "home_register_tcpp": "Ao aderir você concorda com o nosso",
  "home_register_tcpptandcond": "termos e Condições",
  "home_register_tcppand": "e",
  "home_register_tcppprivacy": "Política de Privacidade",
  "home_register_join": "Junte-se a nós. É grátis",
  "home_register_login": "Entrar",
  "home_register_name_required" : "Nome é obrigatório.",
  "home_register_email_required" : "E-mail é obrigatório.",
  "home_register_invalid_email" : "Formato de email inválido",
  "home_register_password_required" : "Senha requerida.",
  "home_register_password_lower_digit" : "A senha deve ser de 6 dígitos é necessária",
  "home_register_password_higher_digit" : "A senha deve ser usada 15 dígitos é necessário",
  "home_register_cpassword_required" : "Confirme a senha é necessária",
  "home_register_cpassword_lower_digit" : "Confirme a senha deve ser 6 dígitos exigidos",
  "home_register_cpassword_higher_digit" : "Confirme a senha deve ser usado 15 dígitos exigidos",
  "home_register_cpassword_not_match" : "Confirme a senha não corresponde",
  
  "home_register_fp_not_worry" : "Por favor introduza o seu ID de e-mail registado.", 
  "home_register_fp_resetpass_msg" : "Para redefinir sua senha, digite seu ID de e-mail registrado.", 
  "home_register_fp_enter_email_msg" : "Introduza o seu e-mail aqui", 
  "home_register_fp_submit_button" : "Enviar", 
  "home_register_fp_temporary_sent_pass_msg" : "Um link de senha de reset foi enviado para o seu ID de e-mail registrado. Por favor, atualize sua senha no mínimo.", 

  "home_works_howitworks": "Como funciona?",
  "home_works_howitworkst1": "Apresente-se", 
  "home_works_howitworksd1": "Torne-se um membro, Inscreva-se",
  "home_works_howitworkst2": "Receba pesquisas",
  "home_works_howitworksd2": "Comece a receber pesquisas intuitivas e simples",
  "home_works_howitworkst3": "Ganhe pontos",
  "home_works_howitworksd3": "Dê suas melhores respostas e acumule pontos",
  "home_works_howitworkst4": "Resgatar",
  "home_works_howitworksd4": "Resgate seus pontos por muitas recompensas emocionantes",
  
  "home_aboutus_whoweare": "Quem nós somos?",
  "home_aboutus_whowearep1": "Na WisePerks, trabalhamos muito para garantir que o tempo gasto online seja uma experiência gratificante. Trabalhamos com centenas de empresas que estão ansiosas para receber seus comentários, saber como você se sente sobre seus produtos ou serviços. Você fornece sua opinião sobre vários serviços, mas nenhum desses serviços realmente lhe recompensa pelo seu tempo, ao contrário da WisePerks - onde cada pesquisa que você participa lhe dá grandes recompensas em dinheiro pelo seu tempo.",
  "home_aboutus_whowearep2": "Valorizamos seu feedback e queremos que você seja recompensado pelo seu tempo. Temos pesquisas sobre vários tópicos que correspondem aos seus interesses e facilitam a resposta. Você pode facilmente fazer cerca de US $ 50 - $ 100 por mês, tendo várias pesquisas. Então, vamos trabalhar juntos para que isso aconteça.",
  
  "home_earn_more_surabt": "Que pesquisas perguntam?",
  "home_earn_more_bp": "Especialistas em negócios",
  "home_earn_more_consumers": "Consumidor",
  "home_earn_more_hc": "Saúde e fitness",
  "home_earn_more_entertainment": "Entretenimento e diversão",
  "home_earn_more_gaming": "Freaks Gaming",
  "home_earn_more_tech": "Tech savvy",
  "home_earn_more_bonus_survey": "Levantamento de bónus", 
  
  "home_reward_whatuearn": "O que você ganha?",
  "home_reward_whatuearnp1": "Nós gostamos de manter as coisas simples. Você ganha pontos por todas as pesquisas concluídas com sucesso. Quanto mais pesquisas você concluir, mais pontos você ganha. Os pontos podem ser trocados por vales de oferta de sua escolha depois de cruzar um certo limite.",
  "home_reward_whatuearnp2": "Depois de pronto para resgatar, escolha a opção que você gosta - Amazon, Starbucks, iTunes e muitos outros. Nós também damos bônus e agradecemos a Deus por suas ofertas de sexta-feira de tempos em tempos.",
  
  "home_counter_earnedover": "Membros ganharam mais",
  
  "home_contact_getintouch": "Entrar em contato",
  "home_contact_emailus": "Para qualquer dúvida, envie um email para",
  
  "home_footer_faq": "FAQs",
  "home_footer_pp": "Política de Privacidade",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Próximos recursos", 
  "home_footer_copyright": "Copyright © current_year WisePerks. Todos os direitos reservados.",

  "home_header_home": "Casa", 
  "home_footer_toknowmore": "Para saber mais",
  "home_footer_createacc": "Crie uma conta gratuitamente e junte-se aos nossos milhares de membros felizes.",
  "home_footer_registernow": "Registrar agora",

  "home_reset_password" : "Redefinir senha", 
  "home_reset_pass_content" : "Solicitou a redefinição da palavra-passe para", 

  "dashboard_sidebar_selectavatar" : "Selecione seu avatar", 
  "dashboard_sidebar_uploadavatar" : "Clique aqui para carregar a sua imagem", 
  "dashboard_sidebar_pbank" : "Banco de pontos",
  "dashboard_sidebar_accumulated" : "Ganhou", 
  "dashboard_sidebar_realized" : "Percebi",
  "dashboard_sidebar_pending" : "Ajustes", 
  "dashboard_sidebar_earned_tooltip" : "Pontos que você coletou até agora",
  "dashboard_sidebar_pending_tooltip" : "Pontos que precisam ser revertidos da sua conta.",
  "dashboard_sidebar_main" : "a Principal",
  "dashboard_sidebar_dashboard" : "painel de controle",
  "dashboard_sidebar_profile" : "Perfil",
  "dashboard_sidebar_updateprofile" : "Atualizar perfil",
  "dashboard_sidebar_shistory" : "História da pesquisa",
  "dashboard_sidebar_mtrans" : "Minha transação",
  "dashboard_sidebar_passbook" : "Livro de Pontos",
  "dashboard_sidebar_redeem" : "Resgatar",
  "dashboard_sidebar_refer_friend" : "Indique amigo",
  "dashboard_sidebar_logout" : "Sair",
  

  "dashboard_sidebar_tutorial1" : "Mostra-lhe todos os inquéritos disponíveis e que você pode ganhar com eles.",
  "dashboard_sidebar_tutorial2" : "Responda a algumas perguntas aqui para nos deixar conhecê-lo melhor e nos ajudar a enviar-lhe inquéritos de seu interesse. Por favor, note que para algumas perguntas você não seria capaz de atualizar suas respostas após a submissão.",
  "dashboard_sidebar_tutorial2update" : "Você pode atualizar suas respostas aqui, porém poucas perguntas não estarão disponíveis para updation.",
  "dashboard_sidebar_tutorial3" : "Encontre aqui todas as pesquisas que você tentou.",
  "dashboard_sidebar_tutorial4" : "Ajuda a manter o controle de todas as suas transações em WisePerks.",
  "dashboard_sidebar_tutorial5" : "Poucos passos rápidos para converter seus pontos realizados para recompensas.",
  "dashboard_sidebar_tutorial6" : "Veja todo o histórico de transações dos pontos debitados / creditados em sua conta.",
  "dashboard_sidebar_gotit" : "Peguei",

  "dashboard_sidebar_note" : "Observe",
  "dashboard_sidebar_note1" : "Todas as respostas devem ser respondidas honestamente.",
  "dashboard_sidebar_note2" : "Por favor, seja específico e preciso com respostas abertas.",
  "dashboard_sidebar_note3" : "Não acelere! Os tempos mínimos de conclusão da pesquisa estão definidos.",
  "dashboard_sidebar_note4" : "Por favor, forneça respostas consistentes quando as mesmas perguntas forem feitas.",
  "dashboard_sidebar_note5" : "Novos inquéritos serão adicionados e os fechados serão removidos automaticamente do seu Dashboard.",
  "dashboard_sidebar_note6" : "Ganhe alguns pontos rápidos apenas por referências.",

  "dashboard_setting_hi" : "Oi",
  "dashboard_setting_minfo" : "Informações sobre associação",
  "dashboard_setting_macc" : "Gerenciar conta",
  "dashboard_setting_upass" : "Atualizar senha",
  "dashboard_setting_yourname" : "Seu nome é",
  "dashboard_setting_fname" : "Primeiro nome",
  "dashboard_setting_lname" : "Último nome",
  "dashboard_setting_save" : "Salvar alterações",
  "dashboard_setting_bornon" : "Você nasceu em",
  "dashboard_setting_youare" : "Você é um",
  "dashboard_setting_postal" : "Seu código postal é",
  "dashboard_setting_emailus" : "Para qualquer assistência ou consulta, por favor envie um e-mail para",
  "dashboard_setting_membersince" : "Você é um membro desde",
  "dashboard_setting_taken" : "Você pegou",
  "dashboard_setting_completed" : "pesquisas e concluído",
  "dashboard_setting_ofthem" : "deles.",
  "dashboard_setting_earned" : "Você ganhou",
  "dashboard_setting_pts" : "pontos",
  "dashboard_setting_started" : "desde que você começou.",
  "dashboard_register_email" : "Você está registrado conosco a partir de",
  "dashboard_setting_preferences" : "Gerencie suas preferências",
  "dashboard_setting_emailoptout" : "Desativar e-mail",
  "dashboard_setting_futureemails" : "Desative o recebimento de futuros e-mails do WisePerks.",
  "dashboard_setting_futureemailsflash" : "Nossos e-mails mantê-lo informado sobre todas as pesquisas lucrativas disponíveis para você. Tem certeza que não quer recebê-los?",
  "dashboard_setting_nrecemail" : "Nossos e-mails mantê-lo informado sobre todos os inquéritos lucrativos disponíveis para você. Tem certeza que não quer detê-los?",
  "dashboard_setting_emailout_modalmsg" : "Tem certeza de que deseja optar por não receber e-mails futuros de WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Tem certeza de começar a receber e-mails futuros de WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Seu com sucesso pára de receber e-mails de WisePerks",
  "dashboard_setting_emailin_succmsg" : "O seu sucesso começar a receber e-mails de WisePerks",
  "dashboard_setting_deactivateacc" : "Desativar conta",
  "dashboard_setting_disableprofile" : "Desativar sua conta desativará seu perfil do WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Tem a certeza de que pretende desactivar a sua conta no WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Se você tem quaisquer queixas ou feedback, então por favor Compartilhe conosco. Vamos abordá-lo com prioridade. Nos dê uma chance. Tem a certeza de que pretende eliminar a sua conta connosco.",
  "dashboard_setting_changelang" : "Mudar idioma",
  "dashboard_setting_preferredlang" : "Selecione seu idioma preferido",
  "dashboard_setting_updatepass" : "Atualizar senha",
  "dashboard_setting_plsupdate" : "Por favor, atualize sua senha abaixo.",
  "dashboard_setting_oldpass" : "Senha Antiga",
  "dashboard_setting_enteroldpass" : "Digite a senha antiga",
  "dashboard_setting_newpass" : "Nova senha",
  "dashboard_setting_enternewpass" : "Insira a nova senha",
  "dashboard_setting_confpass" : "Confirme a Senha",
  "dashboard_setting_enternewpassagain" : "Digite a nova senha novamente",
  "dashboard_setting_update" : "Atualizar",

  "dashboard_doi_welcome" : "Bem-vindo",
  "dashboard_doi_welbck" : "Bem vindo de volta",
  "dashboard_doi_accntverified" : "Parece que você perdeu a verificação de sua conta de e-mail.",
  "dashboard_doi_getaccess" : "Pressa! Você começa",
  "dashboard_doi_getaccess2" : "pontos depois de verificar.",
  "dashboard_doi_activateacc" : "Por favor, verifique a sua caixa de entrada e clique no link fornecido no e-mail de verificação de conta enviado para sua conta de e-mail registrado.",
  "dashboard_doi_chckspam" : "Amavelmente, verific sua pasta do spam/sucata caso que você não pode encontrar o email da verificação.",
  "dashboard_doi_didntgetconf" : "Não conseguiu e-mail de verificação ainda?",
  "dashboard_doi_resendconf" : "Reenviar e-mail de verificação",
  "dashboard_doi_resendconfsucc" : "O email da verificação foi emitido a seu email registado.", 

  "dashboard_survey_availsurveys" : "Pesquisas Disponíveis",
  "dashboard_survey_wisechecksurveys1" : "Você não é capaz de ver inquéritos devido a algumas razões de segurança. Entre em contato para suporte em",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Pesquisas não disponíveis para cancelar a razão",
  "dashboard_featured" : "Destaque",
  "dashboard_profile_survey" : "Levantamento de perfil",
  "dashboard_survey_start" : "Começar",
  "dashboard_survey_on" : "Em",
  "dashboard_survey_off" : "Fora",
  "dashboard_survey_point": "Ponto",
  "dashboard_survey_points" : "Pontos",
  "dashboard_survey_minute" : "Minuto",
  "dashboard_survey_minutes" : "Minutos",

  "demography_hi" : "Oi",
  "demography_welcome" : "Bem-vindo ao WisePerks. Você acabou de ganhar a si mesmo",
  "demography_points" : "pontos registrando conosco.",
  "demography_share" : "Por favor, compartilhe mais alguns detalhes no formulário abaixo, para nos ajudar a trazer inquéritos relevantes para você.",

  "demography_gender" : "eu sou um",
  "demography_male" : "Masculino",
  "demography_female" : "Fêmea",
  "demography_birthday" : "Aniversário",
  "demography_month" : "Mês", 
  "demography_day" : "Dia", 
  "demography_year" : "Ano", 
  "demography_jan" : "janeiro",
  "demography_feb" : "fevereiro",
  "demography_mar" : "marcha",
  "demography_apr" : "abril",
  "demography_may" : "Pode",
  "demography_jun" : "Junho",
  "demography_jul" : "Julho",
  "demography_aug" : "agosto",
  "demography_sep" : "setembro",
  "demography_oct" : "Outubro",
  "demography_nov" : "novembro",
  "demography_dec" : "dezembro",
  "demography_hearabout" : "Onde você ficou sabendo sobre o WisePerks?",
  "demography_opt1" : "Selecione o mais próximo que se aplica",
  "demography_opt2" : "Noticiário por e-mail",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Fórum ou Blog",
  "demography_opt5" : "Amigos",
  "demography_opt6" : "LinkedIn",
  "demography_opt7" : "Motor de busca",
  "demography_opt8" : "Comercial de TV",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Site",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Outros",
  "demography_zipcode": "Código postal",
  "demography_submit" : "Enviar",

  "privacypolicy_privacypolicy" : "Política de Privacidade",
  "privacypolicy_privacyisimp" : "Sua privacidade é importante para nós.",
  "privacypolicy_lastupdated" : "Última atualização em",

  "privacypolicy_para1" : 'A WiseWorks (juntamente com suas afiliadas, a "Empresa") possui e opera diversos sites, aplicativos móveis e serviços interativos, incluindo, sem limitação, Wisesample.com, Flapbucks.com, WisePerks.com e outros (coletivamente, o " Sites do WiseWorks ").Esta política de privacidade ("Política de Privacidade") aplica-se aos Sites WiseWorks e a todos os recursos, aplicativos móveis, e-mails, serviços on-line e outras funcionalidades, acessadas por meio de um computador, dispositivo móvel ou outros serviços disponíveis em ou por meio de Sites e Recursos do WiseWorks (os "Serviços").Esta Política de Privacidade cobre apenas as informações coletadas pela Empresa através dos Sites e Recursos da WiseWorks e de nossos Serviços, e não cobre quaisquer informações recebidas ou coletadas off-line pela Empresa (a menos que especificamente indicado) ou coletadas por qualquer site ou empresa não relacionados. fornecer links.',

  "privacypolicy_para2" : "Espera-se que o painelista / respondente preencha seu perfil de forma honesta e sincera e não apresente informações erradas ou incorretas simplesmente para se qualificar para mais pesquisas.Espera-se também que o painelista / respondente não manipule suas respostas ou use outros meios injustos (mudança de IP, contas múltiplas, etc.) durante o preenchimento da pesquisa para obter incentivos.O WisePerks tem salvaguardas suficientes para detectar tais anomalias e, se tal comportamento for observado, o membro do painel será banido / bloqueado do WisePerks e todos / quaisquer de seus pedidos de resgate serão cancelados.",

  "privacypolicy_para3" : "A WiseWorks está fortemente comprometida em proteger sua privacidade conosco.A seguinte política de privacidade explica como suas informações pessoais são mantidas e tratadas à medida que você explora nosso site e nossos serviços.",

  "privacypolicy_topic1" : "Quais informações pessoalmente identificáveis ​​o WisePerks.com coleta de mim?",
  "privacypolicy_topic1ans" : "WisePerks.com coleta informações de várias maneiras, de diferentes partes do nosso site e rede.Algumas informações são coletadas quando você se inscreve.Durante o registro de membros, o WisePerks.com solicita seu nome, endereço de e-mail, código postal e informações sobre a família.Quanto mais informações você fornecer voluntariamente, melhor poderemos oferecer serviços e recompensas.Além disso, se você entrar em contato com a WisePerks.com por qualquer meio, poderemos manter um registro dessa correspondência.Sempre que o WisePerks.com coleta tais informações, pretendemos incluir um link para esta Política de Privacidade nessa página.",

  "privacypolicy_topic2" : "O que são cookies e como o WisePerks.com os utiliza?",
  "privacypolicy_topic2ans" : "Um cookie é um pacote de dados eletrônicos enviados para o seu navegador, de um servidor da Web e armazenados no disco rígido do seu computador.Como parte de oferecer pesquisas específicas, informações e serviços personalizados, a WisePerks.com pode usar cookies para armazenar e, às vezes, rastrear informações sobre você.WisePerks.com pode exigir que você aceite cookies para preparar tal conteúdo específico para você.Isso também evita que você tenha que inserir suas informações de login todas as vezes.",

  "privacypolicy_topic3" : "Qual é a política do WisePerks.com para permitir que eu atualize, edite, altere ou exclua as informações da minha conta pessoal?",
  "privacypolicy_topic3ans" : "Você pode editar suas informações de conta do WisePerks.com a qualquer momento, usando seu login e senha do WisePerks.com.Entre em contato com",
  "privacypolicy_topic3ans2" : "para obter mais instruções sobre como desativar sua conta do WisePerks.com.",

  "privacypolicy_topic4" : "Quem está coletando informações e com qual finalidade?",
  "privacypolicy_topic4ansp1" : "A WisePerks.com coleta várias informações por meio de pesquisas, registro e outros meios, o que ajuda seus clientes de pesquisa a desenvolver novos produtos e serviços para os consumidores.Você está compartilhando essas informações somente com o WisePerks.com, a menos que seja especificamente indicado de outra forma em qualquer pesquisa que você participar.Se os dados estiverem sendo coletados e / ou mantidos por qualquer empresa que não seja o WiseWorks - ou sites da WiseWorks, você será notificado antes do momento da coleta ou transferência de dados.Se você não quiser que seus dados sejam compartilhados, você pode optar por não permitir a transferência, não usando essa pesquisa ou produto específico.",
  "privacypolicy_topic4ansp2" : "Recompensas e promoções que oferecemos no WisePerks.com podem ser patrocinadas por outras empresas de forma independente, ou podem ser co-patrocinadas pela WisePerks.com e outra empresa.Alguns dados coletados durante essa promoção podem ser compartilhados com o patrocinador.No entanto, você será notificado antes do momento da coleta ou transferência de dados.Você pode decidir não participar dessa promoção em particular se não quiser compartilhar seus dados.",
  "privacypolicy_topic4ansp3" : "Qualquer Informação Sensível específica (como informações que especifiquem condições médicas ou de saúde, origem racial ou étnica, opiniões políticas, crenças religiosas ou espirituais, negócios e filiação sindical ou informação que especifique a vida sexual do indivíduo) será tratada com cuidado adicional.Se WisePerks.com pretende compartilhar tais informações para outra empresa para um propósito diferente do originalmente coletado, então você será expressamente solicitada permissão antes de qualquer divulgação.",

  "privacypolicy_topic5" : "Com quem o WisePerks.com compartilha minhas informações?",
  "privacypolicy_topic5ansp1" : "Nós levamos a privacidade muito a sério.WisePerks.com não divulgará nenhuma de suas informações pessoalmente identificáveis, exceto quando tivermos sua permissão explícita ou sob quaisquer circunstâncias especiais, que a lei exigir ou nas circunstâncias especificadas em detalhes abaixo.A seguir, descrevemos algumas das maneiras pelas quais suas informações podem ser divulgadas.Por favor, consulte os Termos de Serviço de nossos serviços para obter informações mais detalhadas sobre como suas informações pessoais podem ser compartilhadas.",
  "privacypolicy_topic5ansp2" : "Parceiros de negócios, clientes e patrocinadores:",
  "privacypolicy_topic5ansp3" : "WisePerks.com pode divulgar suas informações pessoais para nossos parceiros, clientes ou patrocinadores.No entanto, se algum dado pessoal sobre você fosse compartilhado, nós lhe diremos antes de coletar ou transferir os dados.Muitas atividades que oferecemos solicitam informações adicionais de patrocinadores.Ao solicitar mais informações, você concede permissão ao WisePerks.com para compartilhar suas informações com o patrocinador para que elas possam atender à sua solicitação.Em muitos casos, apenas o seu endereço de e-mail será compartilhado.Se mais informações forem compartilhadas com nossos parceiros, você será notificado com antecedência.De tempos em tempos, você poderá receber a oportunidade de receber materiais de teste ou outros produtos ou ofertas especiais de nossos parceiros.Se você optar por receber informações desses terceiros, o WisePerks.com (com sua permissão) compartilhará seu nome e endereço de e-mail com eles.",
  "privacypolicy_topic5ansp4" : "WisePerks.com também pode divulgar informações pessoais em alguns casos especiais, quando a divulgação dessas informações é necessária para identificar, entrar em contato ou intentar uma ação legal contra alguém que possa estar violando as políticas e Termos de Serviço da WisePerks.com ou estar causando qualquer dano ou interferência com (intencionalmente ou não) os produtos, serviços, direitos ou propriedade da WisePerks.com, outros membros e usuários da WisePerks.com, ou qualquer outra pessoa que possa ser afetada por tais atividades.WisePerks.com pode divulgar ou acessar informações de conta quando acreditamos de boa fé que a lei exige e para fins administrativos e outros que consideramos necessários para manter, atender e melhorar nossos produtos e serviços.",

  "privacypolicy_topic6" : "Quais são as bases legais que usamos para processar suas informações?",
  "privacypolicy_topic6ans" : 'A WiseWorks está comprometida com a privacidade e proteção de dados.Nossa política de privacidade adota os princípios da Regulamentação Geral de Proteção de Dados emitida pela União Européia ("GDPR") como uma referência a qual nós, nossos funcionários e fornecedores aderimos.',

  "privacypolicy_topic7" : "Quais são as minhas escolhas em relação à coleta, uso e distribuição de minhas informações?",
  "privacypolicy_topic7ans" : "Somente a WisePerks.com (ou parceiros que trabalham com a WisePerks.com sob acordos de confidencialidade) lhe enviarão qualquer mala direta, somente se você indicar que não se opõe a eles.Você também tem opções em relação a como os cookies funcionam.Ao alterar as configurações do navegador da Internet, você tem a opção de aceitar todos os cookies, notificar quando um cookie é definido ou rejeitar todas as solicitações de cookies.Se você optar por rejeitar todos os cookies, não poderá usar os serviços do WisePerks.com que exigem registro prévio para participar.WisePerks.com não vende ou aluga qualquer informação de usuário para ninguém.Sempre o notificaremos no momento da coleta ou transferência de dados se os seus dados forem compartilhados com terceiros e você sempre terá a opção de não permitir que eles sejam compartilhados.",

  "privacypolicy_topic8" : "Quais precauções de segurança estão em vigor no WisePerks.com para proteger qualquer perda, uso indevido ou alteração das informações da minha conta?",
  "privacypolicy_topic8ansp1" : "Suas informações de conta do WisePerks.com são protegidas por senha para que você e somente você tenha acesso a essas informações.",
  "privacypolicy_topic8ansp2" : "É altamente recomendável que você não compartilhe sua senha com ninguém.Nunca solicitaremos sua senha em um telefonema ou e-mail não solicitado.Por favor, saia da sua conta WisePerks.com e feche a janela do seu navegador quando terminar sua atividade.Isso garante que outras pessoas não possam acessar suas informações pessoais e conta, se você estiver usando um computador público ou compartilhar seu computador com outra pessoa.Sempre que o WisePerks.com lida com informações pessoais como descrito acima, independentemente de onde isso ocorra, o WisePerks.com toma medidas para garantir que suas informações sejam tratadas com segurança e de acordo com os Termos de Serviço relevantes e esta Política de Privacidade.Infelizmente, nenhuma transmissão de dados pela Internet pode ser garantida como 100% segura.Como resultado, enquanto nos esforçamos para proteger suas informações pessoais, a WisePerks.com não pode garantir ou garantir a segurança de qualquer informação que você nos transmita ou de nossos produtos ou serviços on-line, e você o faz por sua conta e risco.Assim que recebermos os seus dados, esforçamo-nos ao máximo para garantir a sua segurança nos nossos sistemas.",

  "privacypolicy_topic9" : "O que mais eu deveria saber sobre minha privacidade?",
  "privacypolicy_topic9ans" : "Lembre-se de que sempre que você escolher divulgar informações pessoais on-line, por exemplo, por meio de e-mails, sites de redes sociais, blogs ou em áreas de bate-papo, essas informações poderão ser coletadas e usadas por qualquer outro usuário.Se você publicar informações pessoais on-line acessíveis ao público, poderá receber mensagens não solicitadas de outras partes em troca.Em última análise, você é o único responsável por manter o sigilo de suas senhas e / ou qualquer informação de conta.Por favor, seja cuidadoso e responsável sempre que estiver online.",

  "privacypolicy_topic10" : "Como o WisePerks.com protege a privacidade de meus filhos?",
  "privacypolicy_topic10ans" : "O WisePerks.com obtém o consentimento prévio dos pais, onde as pesquisas exigem que as crianças participem, e a supervisão dos pais também é necessária para essas pesquisas com crianças de 6 a 15 anos.Nós tomamos um cuidado extra especial para proteger a segurança e privacidade de usuários jovens em nosso site, e nós encorajamos você a participar da experiência do seu filho online.Não permitimos qualquer marketing ou pesquisa de crianças e certos tópicos são completamente proibidos em pesquisa entre crianças: Nome / nome da conta / E-mail ou endereço físico, Número de telefone, Raça ou Etnia, Religião ou Elenco, Renda familiar, Doença familiar , Estado civil dos pais ou outros membros da família, Assuntos de relacionamento, Graus na escola ou faculdade, Álcool, Questões Sexuais, Tabaco e Armas de Fogo.",

  "privacypolicy_topic11" : "Nota especial para usuários internacionais",
  "privacypolicy_topic11ansp1" : "O site está hospedado nos Estados Unidos.Se você for um Usuário acessando o Site da União Europeia, Ásia ou qualquer outra região com leis ou regulamentos que regem a coleta, uso e divulgação de dados pessoais, que diferem das leis dos Estados Unidos, observe que você está transferindo seus dados pessoais para o Site. Estados Unidos que não possuem as mesmas leis de proteção de dados que a UE e outras regiões, e ao fornecer seus dados pessoais, você concorda com:",
  "privacypolicy_topic11ansp2" : "O uso de seus dados pessoais para os usos identificados acima de acordo com a Política de Privacidade; e a transferência de seus dados pessoais para os Estados Unidos, conforme indicado acima.VOCÊ RECONHECE QUE AS LEIS DOS ESTADOS UNIDOS TRATAM SUAS INFORMAÇÕES DE MANEIRA QUE PODEM SER SUBSTANCIALMENTE DIFERENTES E MENOS PROTETIVAS QUE O TRATAMENTO REQUERIDO SOB AS LEIS DE OUTROS PAÍSES E JURISDIÇÕES.SE VOCÊ NÃO QUER SUAS INFORMAÇÕES TRANSFERIDAS PARA OS ESTADOS UNIDOS, VOCÊ NÃO DEVE COMPARTILHAR SUA INFORMAÇÃO CONOSCO OU FAZER O USO DO SITE.NA MEDIDA PERMITIDA PELA LEGISLAÇÃO DO PAÍS ONDE VOCÊ ESTÁ LOCALIZADA, VOCÊ EXPRIME EXPRESSAMENTE QUALQUER DIREITO DE PODER TER NECESSITADO DE TRATAMENTO DE SUAS INFORMAÇÕES IDENTIFICADAS DE ACORDO COM AS LEIS DE QUALQUER PAÍS OU JURISDIÇÃO QUE NÃO SEJAM OS ESTADOS UNIDOS.Todavia, a renúncia à renúncia não poderá ser juridicamente vinculativa em alguns países, como os Estados-membros da União Europeia.NA MEDIDA EM QUE NÃO ESTÁ LEGALMENTE VINCULANTE NO PAÍS ONDE VOCÊ ESTÁ LOCALIZADA, ESTA DISPENSA ANTERIOR NÃO SE APLICA A VOCÊ.",

  "privacypolicy_topic12" : "Alterações e Atualizações desta Política de Privacidade",
  "privacypolicy_topic12ans" : "A WiseWorks se reserva o direito, a nosso critério, de alterar, modificar, adicionar ou remover partes desta Política de Privacidade a qualquer momento e por qualquer motivo.Toda vez que a WiseWorks exercer tal direito, você deverá aceitar afirmativamente a Política de Privacidade atualizada e, se não o fizer, não poderá mais acessar ou usar o Site.",

  "privacypolicy_addinfo" : "Informação adicional",
  "privacypolicy_sentto" : "Quaisquer outras perguntas sobre isso devem ser enviadas para",

  "faq_faq" : "perguntas frequentes",
  "faq_getans" : "Obtenha respostas para todas as suas perguntas.",

  "faq_ques1" : "O que é o WisePerks?",
  "faq_ans1" : "Nós estamos felizes que você tenha perguntado.WisePerks é um site de pesquisa online.Muitas marcas e empresas estão procurando os entrevistados para fornecer suas opiniões e receber recompensas em troca.WisePerks é uma dessas plataformas onde você pode dar sua opinião e ganhar recompensas por isso também.",

  "faq_ques2" : "É livre para participar?",
  "faq_ans2" : "Sim, é livre para participar.Na verdade, se alguém lhe pedir uma taxa de adesão, gostaríamos que nos informasse.",

  "faq_ques3" : "Posso indicar meus amigos?",
  "faq_ans3" : 'Sim, você pode convidar seus amigos e colegas para participar e também ser recompensado por isso. Clique no link "Indicar amigo" no seu painel para obter todos os detalhes.',

  "faq_ques4" : "O que eu ganho?",
  "faq_ans4" : "Agora, em nosso novo modelo, você pode ganhar pontos de várias maneiras que não apenas tomando as pesquisas. Você recebe pontos por: registar-se connosco, verificando a sua conta de e-mail, para completar o seu perfil, é claro para completar qualquer inquérito e para a sua surpresa, mesmo para uma pesquisa desqualificada. Então temos todas as razões para começar, não é?",

  "faq_ques5" : "Como me torno um membro do WisePerks.com?",
  "faq_ans5" : 'Abra o WisePerks.com no seu navegador.Crie um login de associação clicando na guia "Inscrever-se".Basta seguir os passos e irá guiá-lo pelo resto do processo.Você terá que confirmar sua intenção de entrar clicando no e-mail de confirmação enviado por nós para o seu e-mail fornecido.',

  "faq_ques6" : "Onde está esse e-mail de confirmação que você mencionou?",
  "faq_ans6p1" : "Um email de confirmação é enviado automaticamente para o endereço de e-mail que você fornece ao se inscrever. Se não vir esse e-mail na sua caixa de entrada, por favor verifique o seu spam ou pasta de lixo. Para evitar futuros e-mails de nós e inquérito convida/mensagens de chegar a sua pasta de spam, por favor, adicione",
  "faq_ans6p2" : "sua lista de Remetentes aprovados ou como um contato em sua caixa de correio. Se ainda não conseguir encontrar o e-mail de confirmação, por favor, faça login na minha conta, confirme que o seu endereço de e-mail está correcto e solicite outro e-mail de confirmação.",


  "faq_ques7" : "Como faço uma pesquisa?",
  "faq_ans7" : 'Uma vez que você está logado depois de digitar o seu nome de usuário e senha, você chegar ao seu painel e pode ver todas as pesquisas que você pode tomar. Nós igualmente emitimos as ligações da pesquisa a seus IDs registrados do email. Você pode pegar o link diretamente da sua caixa de correio.',

  "faq_ques8" : "Quão confidenciais são minhas respostas, meus dados?",
  "faq_ans8" : "Para a WisePerks, manter a privacidade e a confidencialidade do usuário são de extrema prioridade.Nossos clientes estão interessados ​​apenas no que você pensa, como consumidor ou como se sente em relação a algo.Nós da WisePerks, nunca compartilhamos / repassamos qualquer informação pessoal para nossos clientes, e nenhuma das pesquisas WisePerks também não vende ou aluga qualquer informação a terceiros.",

  "faq_ques9" : "Por quanto tempo essas pesquisas são feitas?",
  "faq_ans9" : "A maioria das pesquisas leva apenas cerca de 10 a 15 minutos, ou até menos.No entanto, de tempos em tempos, podemos ter pesquisas mais longas.Com cada pesquisa, informamos com antecedência quanto tempo levaria, mas tenha em mente que as pesquisas mais longas também têm maiores recompensas.",

  "faq_ques10" : "Alguém pode fazer pesquisas WisePerks?",
  "faq_ans10" : "Você deve ter 13 anos ou mais para se registrar no WisePerks.Embora, às vezes, possamos convidá-lo para participar de pesquisas com os membros mais jovens de sua família.",

  "faq_ques11" : "Como você sabe que eu vou me qualificar para uma pesquisa?",
  "faq_ans11" : "Nosso algoritmo corresponde às informações do seu perfil com as pesquisas disponíveis para garantir que você receba as pesquisas mais adequadas.Quando uma pesquisa começa, geralmente as primeiras perguntas determinam se você é o respondente que nosso cliente está procurando nessa pesquisa em particular.Temos uma vasta gama de pesquisas que procuram muitos tipos diferentes de pessoas (por exemplo, pessoas que assistem a filmes regularmente, praticam determinado esporte, mulheres com crianças etc.) e novas pesquisas surgem todos os dias!",

  "faq_ques12" : "Quais são minhas obrigações com o WisePerks?",
  "faq_ans12" : "A única coisa que esperamos de você é fornecer suas opiniões e pensamentos honestamente, já que os clientes estão procurando respostas honestas e verdadeiras para melhorar seus produtos e serviços.Forneça informações corretas ao se inscrever como membro e atualize as alterações à medida que elas ocorrem.",

  "faq_ques13" : "Por que devo fornecer informações sobre minha casa, tecnologia ou produtos que compro?",
  "faq_ans13" : "Para cada pesquisa, estamos procurando por um determinado perfil de respondentes.Se soubermos de suas informações de perfil que suas informações correspondem aos requisitos do cliente, bingo!Podemos quase certamente assegurar que você será capaz de completar a pesquisa com sucesso e ganhar a recompensa por isso.Isso basicamente aumenta suas chances de ser selecionado para uma pesquisa.",

  "faq_ques14" : "Quando receberei meus Gift Cards após concluir uma pesquisa da WisePerks?",
  "faq_ans14" : "Os pontos que você ganha após concluir com sucesso um levantamento são acumulados em seu balde. Uma vez que estes pontos são percebidos você pode resgatar.",

  "faq_ques15" : "Qual é a minha conta realmente?",
  "faq_ans15" : "É apenas uma maneira fácil de ver suas informações pessoais, fazer alterações conforme necessário, ver sua atividade de pesquisa, recompensas recebidas, tudo em um só lugar.",

  "faq_ques16" : "Como faço para cancelar minha conta?",
  "faq_ans16" : "Com tanta coisa vindo em sua direção, temos certeza que você não iria querer fazer isso. No entanto, no painel de controle--> vá para configurações--> gerenciar contas--> desativar conta",

  "faq_ques17" : "Como funciona o sistema de pontos?",
  "faq_ans17p1" : "Cada pesquisa é para certo número de pontos. Depois de concluir com sucesso uma pesquisa, esses pontos são adicionados aos seus ganhos. Você pode resgatá-los quando atingir o limite mínimo.",
  "faq_ans17p2" : "Em alguns casos, devido a alguns desafios imprevistos com a conexão ou falhas, sua resposta pode não ser capturada no final do cliente. Temos então que reverter esses pontos da sua conta. Se você já resgatou esses pontos e sua conta tem saldo baixo, seus ganhos serão negativos. Esses ajustes serão feitos no seu próximo ciclo de resgate.",

  "faq_ques18" : "Em quanto tempo os pontos de uma pesquisa são adicionados ao meu saldo de pontos?",
  "faq_ans18" : "Seus pontos serão adicionados ao seu saldo de pontos em 48 horas.",

  "faq_ques19" : "Qual é o limite mínimo para resgate?",
  "faq_ans19" : "O limite mínimo para resgate é de 25000 pontos.Em média, você precisaria concluir 8 a 12 pesquisas para ser elegível para resgate.",

  "faq_ques20" : "Como os pontos são convertidos em dinheiro do vale-presente? Existe uma relação?",
  "faq_ans20" : "Sim. Dependendo do seu país, os pontos são convertidos em dinheiro do vale-presente. As taxas de conversão são mostradas abaixo",
  "faq_ponits" : "Pontos",
  "faq_point" : "Ponto",
  "faq_cent" : "Cent",

  "faq_ques21" : "Ainda tem mais perguntas?",
  "faq_ans21p1" : "Sinta-se livre para enviar-nos",
  "faq_ans21p2" : "e nós retornaremos a você dentro de 48 horas.",

  "Profiling_profiledashboard" : "Painel de perfil", 
  "Profiling_completeprofile" : "Complete o seu perfil e obter gritante",
  "Profiling_completepoints" : "Pontos!",
  "Profiling_answercarefully" : "O seguinte conjunto geral de perguntas será usado para criar o seu perfil pessoal, a fim de fornecê-lo com as melhores oportunidades de pesquisa futuro. Por favor, tome o seu tempo, responder com cuidado e verdade.",

  "Profiling_cat_general" : "Geral",
  "Profiling_cat_personal" : "Pessoal",
  "Profiling_cat_household" : "Casa",
  "Profiling_cat_profession" : "Profissão",
  
  "Profiling_bar_completed" : "% Concluído",
  
  "Profiling_update_ans_button" : "Atualizar e continuar",
  "Profiling_save_ans_button" : "Salvar",
  "Profiling_sc_ans_button" : "Salvar e continuar",

  "Category_thanku_msg1" : "Bom ir",
  "Category_thanku_msg2" : "mais alguns e você terá",
  "Category_thanku_msg3" : "pontos em sua conta.",

  "Category_thanku_update_msg1" : "Obrigado por atualizar suas informações!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "Continuar",

  "OverAll_thanku_msg1" : "Obrigado por compartilhar suas informações. Você ganhou",
  "OverAll_thanku_msg2" : "Pontos.",

  "Survey_history_surveyhistory" : "Histórico de pesquisas",
  "Survey_history_fromthisscreen" : "A partir desta tela, você pode ver todas as suas transações.",
  "Survey_history_show" : "programa",
  "Survey_history_entries" : "Entradas",
  "Survey_history_date" : "Data",
  "Survey_history_details" : "Detalhes",
  "Survey_history_surveys" : "Pesquisas",
  "Survey_history_pointsreceived" : "Pontos recebidos",
  "Survey_history_result" : "Resultado",
  "Survey_history_status" : "Status",
  "Survey_history_status_complete" : "Completa",
  "Survey_history_status_disqualified" : "Desclassificado", 
  "Survey_history_keyword" : "Palavra",
  "Survey_history_search" : "Busca",
  "Survey_history_to" : "Para",
  "Survey_history_selectoption" : "Selecione a opção",
  "Survey_history_earned" : "Ganhou",
  "Survey_history_redeemed" : "Redimidos",
  "Survey_history_norecord" : "Nenhum registro encontrado",
  "Survey_history_Surveypts" : "Pontos de levantamento",
  "Survey_history_Promocode" : "Código promocional",

  "My_transactions_mytransactions" : "Minhas transações",
  "My_transactions_fromthisscreen" : "A partir desta tela, você pode ver todas as suas transações.",
  "My_transactions_show" : "programa",
  "My_transactions_entries" : "Entradas",
  "My_transactions_transactionid" : "ID de transação",
  "My_transactions_date" : "Data",
  "My_transactions_details" : "Detalhes",
  "My_transactions_points" : "Pontos",
  "My_transactions_status" : "Status",
  "My_transactions_keyword" : "Palavra",
  "My_transactions_search" : "Busca",
  "My_transactions_to" : "Para",
  "My_transactions_selectoption" : "Selecione a opção",
  "My_transactions_status_earned" : "Ganhou",
  "My_transactions_status_redeemed" : "Redimidos",
  "My_transactions_status_pending" : "Pendente",
  "My_transactions_status_reverted" : "Revertido",
  "My_transactions_status_declined" : "Recusou",
  "My_transactions_norecord" : "Nenhum registro encontrado",
  "My_transactions_off_msg1" : "Depois de ",
  "My_transactions_off_msg2" : ", todos os detalhes da sua transação serão publicados na guia Livro de pontos",

  "Passbook_heading" : "Livro de Pontos",
  "Passbook_show" : "mostrar",
  "Passbook_entries" : "entradas",
  "Passbook_search" : "Procurar",
  "Passbook_type" : "Tipo",
  "Passbook_surveyid" : "pesquisas",
  "Passbook_date" : "Encontro",
  "Passbook_selectoption" : "Selecione a opção",
  "Passbook_credit" : "Crédito",
  "Passbook_debit" : "Débito",
  "Passbook_survey_id" : "ID da pesquisa",
  "Passbook_survey_pts" : "Pontos de pesquisa",
  "Passbook_earned_pts" : "Pontos",
  "Passbook_pending_pts" : "Pontos pendentes",
  "Passbook_promo_code" : "Código promocional",
  "Passbook_transaction_type" : "Tipo de transação",
  "Passbook_balance" : "Saldo",
  "Passbook_status" : "Status",
  "Passbook_type_soi" : "Cadastro",
  "Passbook_type_doi" : "verificação de e-mail",
  "Passbook_type_profile" : "Conclusão do perfil",
  "Passbook_type_survey" : "Pesquisa",
  "Passbook_type_doir" : "Verificação de e-mail por",
  "Passbook_type_doirt" : "Verificação de e-mail por referência",
  "Passbook_type_surveyr" : "Tentativa de pesquisa por",
  "Passbook_type_surveyrt" : "Tentativa de pesquisa por referência",
  "Passbook_type_reward" : "Recompensa",
  "Passbook_type_pending" : "Ajustamento",
  "Passbook_type_reverted" : "Pontos revertidos",
  "Passbook_type_declined" : "Solicitações recusadas",
  "Passbook_type_redeem" : "Resgatar",
  "Passbook_type_balance" : "Transporte adiante",
  "Passbook_balance_vary" : "O saldo real pode variar",
  "Passbook_survey_debit_reason" : "Valorizamos seus esforços. No entanto, devido a uma queda incontrolável de conexão durante a transferência de dados, sua resposta não pôde ser registrada. Teríamos que reverter os pontos que você recebeu anteriormente para esta pesquisa. Desculpas pelo inconveniente causado.",

  "Redeem_redeemed" : "Resgatar",
  "Redeem_redeemhistory" : "Resgatar histórico",
  "Redeem_history_norecord" : "Nenhum registro encontrado",
  "Redeem_date" : "Data",
  "Redeem_details" : "Detalhes",
  "Redeem_points" : "Pontos",
  "Redeem_status" : "Status",
  "Redeem_lookslike" : "Parece que você perdeu a verificação de sua conta de e-mail.",
  "Redeem_hurry" : "Pressa! Você recebe 1000 pontos depois de verificar.",
  "Redeem_pleasecheck" : "Por favor, verifique a sua caixa de entrada e clique no link no e-mail de confirmação enviado para ativar sua conta.",
  "Redeem_kindlycheck" : "Por favor, verifique o seu spam/pasta lixo, bem como para o e-mail de confirmação.",
  "Redeem_getconfirmationemail" : "Não conseguiu e-mail de confirmação ainda?",
  "Redeem_resendconfirmationemail" : "Reenviar email de confirmação",
  "Redeem_rewardredemptionrule" : "Regra de redenção de recompensa",
  "Redeem_completesurvey" : "Pesquisa completa",
  "Redeem_earnpoints" : "Ganhe pontos",
  "Redeem_redeemrewards" : "Resgatar recompensas",
  "Redeem_note" : "Nota:",
  "Redeem_yourearnedpoints" : "Os seus pontos ganhos tornar-se-ão reembolsáveis após a qualificação do inquérito. Para resgatar a recompensa, os pontos mínimos resgatáveis em sua conta devem ser",
  "Redeem__points" : "Pontos.",
  "Redeem_availablebalance" : "Saldo disponível:",
  "Redeem_historydetails" : "Clique aqui para ver os detalhes do seu histórico de redenção.", 
  "Redeem_reqsucc" : "Parabéns! Seus pontos foram redimidos. Por favor, verifique sua caixa de correio registrada para mais detalhes.", 
  "Redeem_wisecheck_youhave" : "Parabéns! Você está a apenas um clique de sua recompensa.",
  "Redeem_wisecheck_gcagainst" : "Cartão Presente para",
  "Redeem_wisecheck_points" : "Pontos.",
  "Redeem_wisecheck_issue" : "O sistema capturou alguma atividade não reconhecida em sua conta, devido à qual você não consegue enviar uma solicitação de resgate. Entre em contato no",
  "Redeem_emailverify_issue" : "Para resgatar seus pontos, solicitamos que você verifique sua conta de e-mail registrada.",

"Redeem_unsubscribe_issue" : "Não é possível enviar uma solicitação de resgate, pois você cancelou a inscrição da WisePerks. Por favor, entre em contato com support@wiseperks.com para se inscrever novamente.",
  "Redeem_wisecheck_message1" : "Você não pode resgatar devido a alguns motivos de segurança. Por favor entre em contato para suporte em",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Confirmar",
  "Redeem_Cancel_button" : "Cancelar",
  "Redeem_pending_hello" : "Olá",
  "Redeem_pending" : "Ajustamento",
  "Redeem_pending_msg1" : "Sua conta mostra o ajuste de",
  "Redeem_pending_msg2" : "pontos a serem realizados. Confirme para iniciar a transação.",

  "Dashboard_wisecheck_issue" : "O sistema capturou algumas atividades não reconhecidas em sua conta, devido às quais você não consegue ver as pesquisas. Por favor, contate support@wiseperks.com para maiores esclarecimentos.",
  "Dashboard_unsubscribe_issue" : "Você não pode ver nenhuma pesquisa, pois você cancelou sua inscrição no WisePerks. Entre em contato com support@wiseperks.com para receber pesquisas novamente.",


  "WC_DashboardMsg_1" : "Opa! Não há pesquisas para corresponder ao seu perfil a partir de agora.",
  "WC_DashboardSubMsg_1" : "Não se preocupe, estamos embaralhando nosso inventário e em breve enviaremos novas pesquisas para você.",

  "WC_DashboardMsg_2" : "Estamos atualizando seu painel. Por favor, verifique se há novas pesquisas depois de algum tempo.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Obrigado por completar a pesquisa. Você acabou de ganhar response_points pontos. Acesse seu painel para ver seus pontos acumulados totais.",
  "Survey_success_msg2" : "Obrigado por completar a pesquisa. Você acabou de ganhar response_points Pontos.",

  "Survey_success_but_nomore_survey" : "Parabéns! Você acabou de ganhar response_points pontos para completar a pesquisa. Por favor, verifique para futuras pesquisas depois de algum tempo.",
  
  "Survey_terminate_pnt_msg1" : "Parece que algumas de suas informações são ligeiramente diferentes da exigência. Valorizamos o seu tempo e esforços, será acrescentando response_points pontos para a sua conta. Estamos focados em trazer-lhe inquéritos mais lucrativos e intuitiva.",
  
  "Survey_terminate_nopnt_msg1" : "Parece que algumas de suas respostas são um pouco diferentes da exigência. Enviaremos mais pesquisas para o seu caminho. Obrigado pelo seu tempo e esforço.",

  "TermsConditions_t&c" : "Termos e condições",
  "TermsConditions_WhenRegister" : "Quando você se registra",
  "TermsConditions_WhenYouCreate" : "Quando você cria uma conta pessoal, pedimos a você algumas informações pessoais, como mencionado aqui:",
  "TermsConditions_YouCanUpdate" : "Você pode atualizar ou alterar suas informações básicas de perfil a qualquer momento.",
  "TermsConditions_YouCanOptOut" : "Você pode desativar ou encerrar sua conta a qualquer momento.",
  "TermsConditions_WeRespectYour" : "Respeitamos sua privacidade e fazemos o melhor uso da tecnologia para proteger suas informações contra acesso físico e eletrônico não autorizado.",
  "TermsConditions_WeShareYour" : "Compartilhamos suas informações apenas com organizações, que são necessárias para operar o WiseWorks. E essas organizações precisam cuidar de nossos compromissos com sua privacidade e segurança.",
  "TermsConditions_WhatInformation" : "Quais informações coletamos de você",
  "TermsConditions_DirectlyCollected" : "Recolhido Diretamente",
  "TermsConditions_EmailAddressAndPassword" : "Endereço de e-mail e senha da sua conta conosco.",
  "TermsConditions_YourResponse" : "Sua resposta às perguntas de perfil.",
  "TermsConditions_AnyCustomer" : "Qualquer correspondência de atendimento ao cliente.",
  "TermsConditions_AnyPersonalDetails" : "Quaisquer detalhes pessoais que você compartilhe para resgate",
  "TermsConditions_AnyOtherInformation" : "Qualquer outra informação fornecida diretamente por você.",
  "TermsConditions_UsageData" : "Dados de uso",
  "TermsConditions_YourIPAddress" : "Seu endereço IP, localização geográfica e cookies",
  "TermsConditions_InformationAboutTheDevice" : "Informações sobre o dispositivo que você usa",
  "TermsConditions_YourSurveyActivity" : "Sua atividade de pesquisa, pontos em sua conta, os pagamentos recebidos depois de resgatar.",
  "TermsConditions_HowDoWeUse" : "Como usamos seus dados?",
  "TermsConditions_SendingYouInvitation" : "Enviando-lhe e-mails de convite para responder à pesquisa para a qual você se qualifica.",
  "TermsConditions_DetectAndPrevent" : "Detecte e evite atividades fraudulentas.",
  "TermsConditions_ImproveYourExperience" : "Melhore sua experiência na plataforma WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Suas respostas são transferidas para nossos terceiros e parceiros afiliados em um formato agregado e desidentificado.",
  "TermsConditions_OurPartnersCanAsk" : "Nossos parceiros podem solicitar que você forneça seu nome, endereço de e-mail e outras informações de contato durante uma pesquisa.",
  "TermsConditions_YouAreFreeToDecline" : "Você é livre para recusar o fornecimento de seus dados aos nossos parceiros, se você concordar que você está fornecendo essas informações diretamente para eles e não para o WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Suas informações serão tratadas de acordo com a Política de Privacidade comercial relevante e não com a Política de Privacidade da WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Nossos parceiros estão comprometidos em respeitar e proteger sua privacidade.",
  "TermsConditions_WhatWeDont" : "O que não fazemos com isso",
  "TermsConditions_WeDontSellOnThat" : "Não vendemos essas informações para ninguém e não as usamos para vender nada para você. Sempre pediremos permissão antes de compartilhar seus dados.",
  "TermsConditions_YourRightToControl" : "Seu direito de controlar seus dados",
  "TermsConditions_YouHaveFullControl" : "Você tem controle total dos seus dados.",
  "TermsConditions_YouCanAccess" : "Você pode acessar, atualizar ou alterar suas informações básicas de perfil sempre que quiser.",
  "TermsConditions_YouCanUnsubscribe" : "Você pode cancelar a inscrição e desativar sua conta.",
  "TermsConditions_YouAreFreeToGetYour" : "Você é livre para obter o seu perfil excluído, enviando-nos um e-mail para support@wiseperks.com. Tentaremos resolver sua preocupação e, se você ainda quiser excluir sua conta, ela deverá fazer isso em 72 horas.",
  "TermsConditions_DataSecurity" : "Segurança de dados",
  "TermsConditions_OurTechTeamWorks" : "Nossa equipe de tecnologia trabalha muito para manter todos os seus dados seguros.",
  "TermsConditions_WeMakeUseOfLatest" : "Fazemos uso das mais recentes tecnologias e medidas para evitar qualquer uso indevido ou acesso não autorizado de seus dados. No entanto, devido à natureza do ambiente on-line, não podemos garantir 100% de segurança de dados.",
  "TermsConditions_WeTryOurBestToRule" : "Nós tentamos o nosso melhor para descartar os riscos, no entanto, não podemos garantir um ambiente livre de risco. Ao aceitar estes Termos e Condições, você reconhece e aceita esses riscos.",
  "TermsConditions_AnyQuestions" : "Qualquer dúvida, por favor, não hesite em contactar-nos",
  "TermsConditions_IfYouHaveAnyQueries" : "Se você tiver alguma dúvida ou reclamação sobre nossa política e procedimentos de privacidade. Por favor envie-nos um email para support@wiseperks.com e entraremos em contato com você dentro de 48 horas.",

  "Validation_PleaseEnterProperDate" : "Por favor, insira a data de nascimento apropriada",
  "Validation_PleaseSelectOne" : "Por favor selecione uma opção!",
  "Validation_PleaseSelectValidOptions" : "Por favor, selecione opções válidas!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Por favor, selecione pelo menos uma opção",
  "Validation_PleaseInsertTheZip" : "Por favor, insira o código postal.",

  "Releases_releases" : "Libera",
  "Releases_theUseOfThis" : "O uso deste site está sujeito aos seguintes termos de uso.",
  "Releases_featuresOfWP" : "Características do WP Libera",
  "Releases_updatedOn" : "atualizado em",
  "Releases_WisePerksNow" : "Wiseregalias agora suporta vários idiomas",
  "Releases_youCanWalkThourgh" : "Você pode andar alivio o local com muito mais facilidade agora como wisebenefis está disponível em sua língua preffered.",
  "Releases_languagesAvailable" : "Línguas disponíveis:",
  "Releases_EarnMore" : "Ganhe mais com o nosso novo sistema de pontos",
  "Releases_theNewWisePerksAccumulates" : "Os novos Wiseregalias acumulam pontos na sua conta. Estes pontos têm um valor muito maior e são fáceis de ganhar e resgatar. Com várias opções de resgate para selecionar, temos certeza de que você vai desfrutar do inquérito tendo experiência.",
  "Releases_accumulatePoints" : "Acumule pontos com a redeemption livre do Hassel",
  "Releases_youGetTheBenefit" : "Você ganha o benefício de ganhar o mesmo não. de pontos, independentemente da moeda em que deseja resgatá-los.",
  "Releases_surveyDisqualification" : "Pesquisa desqualificação não é mais uma má notícia",
  "Releases_youEarnPoints" : "Você ganha pontos mesmo quando você não se qualifica para uma pesquisa, você começou a responder.",
  "Releases_newAndImproved" : "Layout novo e aprimorado",
  "Releases_theFreshLookOf" : "O olhar fresco de Wiseregalias fará com que você se apaixone por ele. Agora é muito mais fácil de usar e seria uma grande experiência para você.",
  "Releases_whatAvatarAreYouToday" : "Que Avatar você é hoje",
  "Releases_youCanNowCreate" : "Agora você pode criar seu próprio avatar para o recurso em seu painel.",
  "Releases_freshAndHotSurveys" : "Inquéritos frescos e quentes no seu Dashboard",
  "Releases_youDontHaveTo" : "Você não precisa se preocupar em atualizar seu painel para ver novas pesquisas, vamos manter seu painel preenchido com as pesquisas mais recentes automaticamente.",
	 "Releases_promoCode" : "Recurso Código Promocional",
  "Releases_promoCodeInfo" : "Na demanda popular, a WisePerks traz códigos promocionais para seus painelistas toda semana. Maneira rápida de pegar mais recompensas.",
  "Releases_referFriend" : "Indique amigo",
  "Releases_referFriendInfo" : "Receba seus amigos associados com WisePerks e pegue recompensas emocionantes. Fique atento à opção Recomendar amigo na sua conta.",

  "Dashboard_Promocode_Usepromo" : "Use o código promocional",
  "Dashboard_Promocode_to" : "Para",
  "Dashboard_Promocode_earnminimum_1" : "ganhar o mínimo",
  "Dashboard_Promocode_earnminimum_2" : "Pontos extras",
  "Dashboard_Promocode_minimum" : "mínima",
  "Dashboard_Promocode_minimumextrapoints" : "pontos extras em cada pesquisa concluída hoje",
  "Dashboard_Promocode_apply" : "Aplicar",
  "Dashboard_Promocode_message1" : "Código promocional inválido!",
  "Dashboard_Promocode_message2" : "Parabéns! Você receberá o mínimo",
  "Dashboard_Promocode_message3" : "extra em cada pesquisa concluída hoje!",

  "Dashboard_modal_Enjoythefestive" : "Aproveite o espírito festivo, ganhando pontos mínimos de",
  "Dashboard_modal_Extrapointson" : "a mais em cada pesquisa que você completar hoje.",

  "refer_friend_title" : "Indique um amigo e seja recompensado",
  "refer_friend_step_first": "Convide um amigo para o WisePerks",
  "refer_friend_step_second": "Amigo se inscreve com sucesso no WisePerks",
  "refer_friend_step_third": "Você ganha each_referral pontos",
  "refer_friend_link_title": "Convide um amigo agora!",
  "refer_friend_share_title": "Compartilhe o link",
  "refer_friend_guideline_title": 'Como "CONSULTE UM AMIGO" irá beneficiá-lo:',
  "refer_friend_guideline_one": "Por favor, compartilhe o link fornecido acima com seus indicados.",
  "refer_friend_guideline_two": "Você pode indicar quantas pessoas quiser. Quando cinco de suas referências confirmarem suas contas e começarem a realizar pesquisas, seus pontos de referência ganhos serão adicionados a sua conta.",
  "refer_friend_guideline_three": "Você recebe each_referral pontos cada por referência bem-sucedida (com conta de e-mail verificada e pesquisa).",
  "refer_friend_guideline_four": "Os pontos serão ganhos em duas etapas, partial_referral na verificação por e-mail e partial_second_referral na atividade da pesquisa por sua indicação.",
  "refer_friend_guideline_five": "Depois de 5 referências bem-sucedidas (com conta de e-mail verificada e pesquisa), o link de indicação será desativado.",

  }
  
