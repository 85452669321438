import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions} from '@angular/http';
import {HttpClient, HttpHeaders} from "@angular/common/http";

// All the RxJS stuff we need
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import * as myGlobals from '../../shared/globals';
import * as parameter from '../../../../assets/js/common/sv';
import { User } from './user.model';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HomeService {

  notAvailableWiseperk_Country: boolean = false;
  not_available_country_name:string = "";

  constructor(private _http: HttpClient, private http: Http, private toastr: ToastrService) { }

  /*Below API's for get Language at time home page load */

    getUserIp(){
      return this.http.get(myGlobals.rootPHPUrl+'/getip.php').map(x => x.json());
    }

    getCountryFromIp(userip){  //console.log(userip);
      var data = "Panelist_ip="+userip;
      return this._http.post(myGlobals.rootPHPUrl+'/getcountrycode.php',data);
    }


    getlanguageFromCountryCode(country_code){
      return this._http.get(myGlobals.rootUrl+'/'+country_code+'/languages');
    }

    getUserLangDetails(){
      return this._http.get(myGlobals.rootUrl+'/country/languages', { observe: 'response' });
    }
  /*Above API's for get Language at time home page load*/



  /*Below API's for Register Panelist via form and FB*/

    remainingParameter(){ 
      return this.http.get(myGlobals.rootPHPUrl+'/finalip.php')
      .map(x=>x.json())
      .catch(this.handleError);
    }


    registerUser(user, Regflag, getPrefferedLangId, referURL) {
      
     let system_parameter = parameter.getalljsondata(); // Get 48 Parameter
      
      var obj = JSON.parse(system_parameter);
      //console.log(extra_parameter);

      var AllParameterStr = obj;
      
        
        
        /* Below code for Wise Check from cookies */
         let PanelistWisecheck:number = 0;
        //let isCookiesAllow = localStorage.getItem("isAllowCookies");

          /*if(isCookiesAllow['isAllowCookies'] == 0){  // when cookies are disabled
            PanelistWisecheck = 1; 
          } else*/ if(localStorage.getItem('isPanelistAlreadyRegister') !== null){ //already reg from this system
            PanelistWisecheck = 1;
          }

        /*Above code for Wise Check from cookies */
        
        let user_device = "";

        if(obj[0] == 1){   // as suggest by nilesh
          user_device = "mobile";
        } else {
          user_device = "desktop";
        }
        
        if(Regflag == "formLogin"){
          var data = "first_name=" + user.fname + "&last_name=" + user.lname + "&email=" + user.email + "&password=" + user.password
                    +"&language=" + getPrefferedLangId + "&user_device="+ user_device +"&wise_check="+ PanelistWisecheck
                    +"&wise_check_parameter="+AllParameterStr+"&refer="+referURL;

        } else if(Regflag == "fbLogin"){

          var data = "first_name=" + user.name + "&email=" + user.email + "&facebook_id=" + user.id
                    +"&language=" + getPrefferedLangId + "&user_device="+ user_device +"&wise_check="+ PanelistWisecheck
                    +"&wise_check_parameter="+AllParameterStr+"&refer="+referURL; 
        }
        
        return this._http.post(myGlobals.rootUrl+'/panelist',data);
    }

  /*Above API's for Register Panelist via form & FB*/



  /* Below API's for Panelist Login(Form & FB) & update wisecheck via cookies */

    loginUser(user,loginflag) {
        
      /*Below code for Wise Check from cookies */
        //let isCookiesAllow = this.localstorageService.getFromLocal('isAllowCookies');

        let PanelistWisecheck = 0;
        /*if(isCookiesAllow['isAllowCookies'] == 0){  // when cookies are disabled
          PanelistWisecheck = 1; 
        }*/
      /*Above code for Wise Check from cookies */
     

      //for user device 
      let login_system_parameter = parameter.getalljsondata(); // Get 48 Parameter
    
      var login_obj = JSON.parse(login_system_parameter);
        let login_user_device = "";

      if(login_obj[0] == 1){
        login_user_device = "mobile";
      } else {
        login_user_device = "desktop"
      }

      if(loginflag == "formLogin"){
        var data = "email="+user.login_email+"&password="+user.login_pass
        +"&user_device="+ login_user_device +"&wise_check="+PanelistWisecheck;
      } else if(loginflag == "fbLogin") { 
        var data = "email="+user.email+"&auth="+user.auth
        + "&user_device="+ login_user_device +"&wise_check="+PanelistWisecheck;
      }
      
      return this._http.post(myGlobals.rootUrl+'/panelist/login',data);
    }


    /*updateWiseCheck(panelist_id): Observable<any>{
      var data = "wise_check=0&notes=Login: Already user Register via this system, issue get by cookies.";

      return this._http.post(myGlobals.rootUrl+'/panelist/'+panelist_id+'/wisecheck',data);
    }*/

    updateWiseCheck(panelist_id,current_login_panelist_wc): Observable<any>{
      var data = "wise_check="+current_login_panelist_wc+"&notes=Already user Register via this system, issue get by cookies.";

      return this._http.post(myGlobals.rootUrl+'/panelist/'+panelist_id+'/wisecheck',data);
    }

  /* Above API's for Panelist Login (Form & FB) & update wisecheck via cookies */


  /* Below API's is check Panelists Email is exists or not at time of fb registration */

  isPanelistExists(getemail){

    return this._http.get(myGlobals.rootUrl+'/panelists?email='+getemail);
  }
/* Above API's is check Panelists Email is exists or not at time of fb registration */


  /* Below Code is update User Profile via Second Registration Form */

      ProfileRegistration(panelistData,panelistId){
        
        var panelist_traffic_source = "";
        if(panelistData['traffic_source']){
          panelist_traffic_source = "&traffic_source="+panelistData.traffic_source;
        }
        var data = "gender="+panelistData.gender+"&dob="+panelistData.dob+"&zipcode="+panelistData.zipcode+"&step=1" + panelist_traffic_source;

        return this._http.put(myGlobals.rootUrl+'/panelist/'+panelistId,data);
      }
  /* Above Code is update User Profile via Second Registration Form */


  /* Below API's for forgot Password & Reset Password*/

  forgotPassword (data) {

    let string = "email="+data.forgot_email;
    
    return this._http.post(myGlobals.rootUrl+'/panelist/forgotpassword', string);
  }

  resetPassword(user) {
    
    var data = "email="+user.email+"&auth="+user.auth+"&new_password="+user.password+"&forgot_link=1";

    return this._http.post(myGlobals.rootUrl+'/panelist/resetpassword',data);
  }

/* Above API's for forgot Password & Reset Password*/


/* Below code for Verify email, Start Here */

VerifyPanelistApi(email,auth){

  return this._http.get(myGlobals.rootUrl+'/panelist/verifyemail/'+ email + '/' + auth);
}

PanelistGetDataViaEmail(email){
  return this._http.get(myGlobals.rootUrl+'/panelists?email='+email);
}
/* Above code for Verify email, End Here */

/* Below Code is for count points on home Page, Start Here*/
  getPanelistsLifetimePoints(){
    
    return this._http.get(myGlobals.rootUrl+"/panelists/points");
  }
/* Above Code is for count points on home Page, Start Here*/


/* Below Api is for unsubscribe panelist, Start Here */
  panelistUnsubscribe(postData){
    
    var datapost = "email="+postData['email']+"&auth="+postData['auth'];
    
    return this._http.post(myGlobals.rootUrl+"/panelist/unsubscribe",datapost);
  }
/* Below Api is for unsubscribe panelist, End Here */


/* Below code set WisePerk not available for country, start here */
    Set_notAvailable_country(){
      this.notAvailableWiseperk_Country = true;
    }

    SetNotAvailableCountryName(getcountryname) {
      this.not_available_country_name = getcountryname;
    }

    Get_notAvailable_country(){
      return this.notAvailableWiseperk_Country;
    }

    GetNotAvailableCountryName(){
      return this.not_available_country_name;
    }
/* Above code set WisePerk not available for country, end here */

  
  handleError(error: Response) { 
    //console.log(error);
    return Observable.throw(error);
  }
}
