import { Injectable } from '@angular/core';
import { TRANSLATIONS_DE } from './language/de/translate';
import { TRANSLATIONS_ES } from './language/es/translate';
import { TRANSLATIONS_FL } from './language/fl/translate';
import { TRANSLATIONS_FR } from './language/fr/translate';
import { TRANSLATIONS_ID } from './language/id/translate';
import { TRANSLATIONS_IT } from './language/it/translate';
import { TRANSLATIONS_JA } from './language/ja/translate';
import { TRANSLATIONS_KO } from './language/ko/translate';
import { TRANSLATIONS_MS } from './language/ms/translate';
import { TRANSLATIONS_NL } from './language/nl/translate';
import { TRANSLATIONS_PL } from './language/pl/translate';
import { TRANSLATIONS_PT } from './language/pt/translate';
import { TRANSLATIONS_TH } from './language/th/translate';
import { TRANSLATIONS_TR } from './language/tr/translate';
import { TRANSLATIONS_VI } from './language/vi/translate';
import { TRANSLATIONS_EN } from './language/en/translate';
import { TRANSLATIONS_CH } from './language/ch/translate';

@Injectable()
export class TranslateService {

  constructor() { }

  private currentLanguage = 'en';

  setLanguage(languagename){
    this.currentLanguage = languagename;
  }

  translation(const_val) {

    if (this.currentLanguage === "de") {
      return TRANSLATIONS_DE[const_val];
    } else if (this.currentLanguage === "es") {
      return TRANSLATIONS_ES[const_val];
    } else if (this.currentLanguage === "fl") {
      return TRANSLATIONS_FL[const_val];
    } else if (this.currentLanguage === "fr") {
      return TRANSLATIONS_FR[const_val];
    } else if (this.currentLanguage === "id") {
      return TRANSLATIONS_ID[const_val];
    } else if (this.currentLanguage === "it") {
      return TRANSLATIONS_IT[const_val];
    } else if (this.currentLanguage === "ja") {
      return TRANSLATIONS_JA[const_val];
    } else if (this.currentLanguage === "ko") {
      return TRANSLATIONS_KO[const_val];
    } else if (this.currentLanguage === "ms") {
      return TRANSLATIONS_MS[const_val];
    } else if (this.currentLanguage === "nl") {
      return TRANSLATIONS_NL[const_val];
    } else if (this.currentLanguage === "pl") {
      return TRANSLATIONS_PL[const_val];
    } else if (this.currentLanguage === "pt") {
      return TRANSLATIONS_PT[const_val];
    } else if (this.currentLanguage === "th") {
      return TRANSLATIONS_TH[const_val];
    } else if (this.currentLanguage === "tr") {
      return TRANSLATIONS_TR[const_val];
    } else if (this.currentLanguage === "vi") {
      return TRANSLATIONS_VI[const_val];
    } else if (this.currentLanguage === "zh") {
      return TRANSLATIONS_CH[const_val];
    } else { // default we use english
      return TRANSLATIONS_EN[const_val];
    }
  }
}
