import { Component, OnInit } from '@angular/core';
import { HomeService } from '../shared/home.service';
import { PanelistService } from '../../shared/panelist.service';
import { TranslateService } from '../../shared/translate.service';
import { NgForm } from '@angular/forms';
import * as myLangGlobal from '../../shared/globals';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  panelist = {
    gender : "",
    dob_month : "",
    dob_day : "",
    dob_year: "",
    trafic_source : "",
    source_ref : ""
  }

  years = Array<Years>();
  months = Array<Months>();
  days = Array<Days>();
  traficSource:TraficSource[];

  other_comments:boolean=false;
  comment_value:string = "";

  LedgerBalance:number;
  MainBalance:number;

  showDemographicLoader:boolean = true;

  public demoLoading: boolean = false;

  constructor( private _homeService:HomeService,
    private _panelistService: PanelistService,
    private translateService: TranslateService,
    private toastr: ToastrService,  
    private router: Router,
    private title: Title) {

      let currentPreferLang = localStorage.getItem('PreferredLanguage');

      if(currentPreferLang !== null && myLangGlobal.LangArr.indexOf(currentPreferLang) !== -1){
        this.translateService.setLanguage(currentPreferLang);
      }

      this.months = [{label:'demography_jan', value:"01"},{label:"demography_feb", value:"02"},{label:"demography_mar", value:"03"},{label:"demography_apr", value:"04"},
        {label:"demography_may", value:"05"}, {label:"demography_jun", value:"06"},{label:"demography_jul", value:"07"},{label:"demography_aug", value:"08"},{label:"demography_sep", value:"09"},
        {label:"demography_oct", value:"10"},{label:"demography_nov", value:"11"}, {label:"demography_dec", value:"12"}];

      
        this.traficSource = [{label:'demography_opt2', value:'email/newsletter'}, {label:'demography_opt3', value:'facebook'},
        {label:'demography_opt4', value:'forum or blog'},{label:"demography_opt5", value:"friend"},{label:'demography_opt6', value:'LinkedIn'},
        {label:'demography_opt7', value:'search engine'}, {label:"demography_opt8", value:"tv commercial"},
        {label:"demography_opt9", value:"twitter"}, {label:"demography_opt10", value:"website"}, {label:"demography_opt11", value:"youtube"},
        {label:'demography_opt12', value:'other'}];

    }

    joining_points:number = 0;
    PanelistName: string = "";
    PanelistZipcode: string = ""; 

  ngOnInit() {

    this.title.setTitle('WisePerks | Online Survey Respondents Profile');

    /* Call Api to get Panelists Info, so we can show him joining points */

    //let current_login_user = localStorage.getItem('currentLoginPanelist');
    
    /*if(current_login_user) {
      this._panelistService.getPanelistInfo(current_login_user).subscribe((resp:any) => {
        if(resp.auth == true && resp.success == true){
          this.StopPageLoader({});

          let currentPreferLanguage = localStorage.getItem('PreferredLanguage');

          if(currentPreferLanguage != resp.data.language){
            this.translateService.setLanguage(resp.data.language.toLowerCase());
          }

          if(resp.data.step == 1){
            this.router.navigate(['/dashboard']);
          }
          this.joining_points = resp.data.points;
          this.PanelistName = resp.data.first_name;
        } else {
          this.toastr.clear();
          this.toastr.error(resp.message, "WisePerks");
        }
      },
      (err:any)=>{
        this.StopPageLoader({});
        this.showNewFlashError(err,"WisePerks");
      });
    }*/

    let get_global_panelist_info = this._panelistService.GetGlobalPanelistInfo();

    if(get_global_panelist_info.auth == true && get_global_panelist_info.success == true){

      let currentPreferLanguage = localStorage.getItem('PreferredLanguage');

      if(currentPreferLanguage != get_global_panelist_info.data.language){
        this.translateService.setLanguage(get_global_panelist_info.data.language.toLowerCase());
      }

      if(get_global_panelist_info.data.step == 1){
        this.router.navigate(['/dashboard']);
      }

      this.joining_points = get_global_panelist_info.data.points;
      this.PanelistName = get_global_panelist_info.data.first_name;
      this.PanelistZipcode = get_global_panelist_info.data.zipcode;
      this.LedgerBalance = get_global_panelist_info.data.ledger_points;
      this.StopPageLoader({});
    }
  }

  onChange(newValue){
    if(newValue == "other"){
      this.other_comments = true;
    } else {
      this.comment_value = "";
      this.other_comments = false;
    }
  }


  onSubmit(form: NgForm) {
    this.demoLoading = true;

    let panelistId = localStorage.getItem('currentLoginPanelist');
    var panelistData = [];
    
    panelistData['gender'] = form.value.gender;
    panelistData['dob'] = form.value.dobYear+'-'+form.value.dobMonth+'-'+form.value.dobDay;
    panelistData['zipcode'] = form.value.zipcode;

    /*if(panelistData['traffic_source'] == 'other' && form.value.source_ref != ""){
      panelistData['traffic_source'] = form.value.source_ref;
    } else if(form.value.traffic_source !="" && form.value.traffic_source != null){
      panelistData['traffic_source'] = form.value.traffic_source;
    }*/

    if(form.value.traffic_source !="" && form.value.source_ref == null){
      panelistData['traffic_source'] = form.value.traffic_source;
    } else if(form.value.traffic_source == 'other' && form.value.source_ref != null){
      panelistData['traffic_source'] = form.value.source_ref;
    }
    

    if(!form.value.gender){
      this.toastr.clear();
      this.toastr.error("Please select gender!.","WisePerks");
      this.demoLoading = false;
      return false;
    }

    if(!form.value.dobMonth && !form.value.dobDay && !form.value.dobYear){
      this.toastr.clear();
      this.toastr.error("Please select age!.","WisePerks");
      this.demoLoading = false;
      return false;
    }

    if(!form.value.dobMonth || !form.value.dobDay || !form.value.dobYear){
      this.toastr.clear();
      this.toastr.error("Please select proper age format!.","WisePerks");
      this.demoLoading = false;
      return false;
    }
    
    let get_age = this.getAge(panelistData['dob']);
    
    if(get_age > 12){
     
      this._homeService.ProfileRegistration(panelistData,panelistId).subscribe((response:any) =>{
        if(response.auth == true && response.success == true && response.data.step ==1){
          this.router.navigate(['/dashboard']).then(()=>{
            this.toastr.clear();
            this.toastr.success("You have successfully updated your demographic information.","WisePerks");
          });
          this.demoLoading = false;
        } else{
          this.toastr.clear();
          this.demoLoading = false;
          this.toastr.error(response.message,"WisePerks");
        }
      },
      (error)=>{ 
        this.showNewFlashError(error, "WisePerks");
        this.demoLoading = false;
        return false;
      });
     
    } else {
      this.toastr.clear();
      this.toastr.error("Panelist should be above 13 age.","WisePerks");
      this.demoLoading = false;
      return false;
    }
  }

  showDates(getmonths){
    this.panelist.dob_day = "";
    this.days=[];
    this.panelist.dob_year = "";
    this.years = [];
    if(getmonths){
      let loopflow = 31;

      if(getmonths == "04" || getmonths == "06" || getmonths == "09" || getmonths == "11"){
        loopflow = 30;
      } else if (getmonths == "02") {
        loopflow = 29;
      }

      let j:string = "";

      for(var i=1; i<=loopflow; i++){

          if(i <= 9){
            j = "0"+i;
          } else {
            j = "" + i +"";
          }

        this.days.push(new Days(j,j));
      }
    }
  }


  showYears(getdate, getMonthId){
    this.panelist.dob_year = "";
    this.years = [];
    var date = new Date();
    var year = date.getFullYear();
    var start = year - 13;
    var count = year - 99;

    for (var i = start; i >= count; i--){

      if(getdate == 29 && getMonthId == '02'){
        var leap = !((i % 4) || (!(i % 100) && (i % 400)));
        
        if(leap === true){
          this.years.push(new Years(i));
        }
      } else {
        this.years.push(new Years(i));
      }
    }
  }


  activeEvent(getvalue){
    var elem = document.getElementById("bg1");
    elem.className = elem.className.replace(/\bactive\b/g, "");

    var element = document.getElementById("bg2");
    element.className = element.className.replace(/\bactive\b/g, "");

    if(getvalue == "male"){
      elem.classList.add("active");
    } else {
      element.classList.add("active");
    }
  }

  /* Below function is commented modify becoz not working on safari brwoser */
  /*getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
  
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }*/


  getAge(dateString) {
    var today = new Date();
    var birthDate = dateString.split("-");

    let birthdayYear = birthDate[0];
    let birthdayMonth = birthDate[1];
    let birthdayDay = birthDate[2];

    var age = today.getFullYear() - birthdayYear;
    var m = today.getMonth() - birthdayMonth;
    
    if (m < 0 || (m === 0 && today.getDate() < birthdayDay)) {
      age--;
    }
    
    return age;
  }


  StopPageLoader(getJson){
    this.showDemographicLoader = false;
  }


  showFlashError(err){
    var get_errors = JSON.parse(err._body);
      if(get_errors.errors){
        var firstkey = Object.keys(get_errors.errors)[0];
        var msg = get_errors.errors[firstkey];
        this.toastr.clear();
        this.toastr.error(msg,"WisePerks");
      } else if(get_errors.message){
        this.toastr.clear();
        this.toastr.error(get_errors.message,"WisePerks");
      }
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}

export class Years {
  constructor(label:number){
    this.label = label;
  }
  label:number;
}

export class Months {
  constructor(label:string,value:string){
    this.label = label;
    this.value = value;
  }
  label: string;
  value: string;
}

export class Days {
  constructor(label:string,value:string){
    this.label = label;
    this.value = value;
  }
  label: string;
  value: string;
}

export class TraficSource {
  label: string;
  value: string;
}

