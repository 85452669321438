import { Component, OnInit } from '@angular/core';
import { PanelistService } from './shared/panelist.service';
import { ToastrService } from 'ngx-toastr';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  constructor(private router: Router, private _panelistService:PanelistService, private toastr: ToastrService) {
    
    this.router.events.subscribe((routerEvent: Event) => {
      
    });

    /*
    if(localStorage.getItem('currentLoginPanelist') !== null){

      let currentLoginPanelistId = localStorage.getItem('currentLoginPanelist');

      this._panelistService.getPanelistInfo(currentLoginPanelistId).subscribe((resp:any)=>{
        if(resp.auth == true && resp.success == true){
          if(resp.data.step == 0){
            this.router.navigate(['/demographics']);
          } else if(resp.data.step == 1) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['']);
          }
        } else {
          this.toastr.error(resp.message,"Not Redirect!");
        }
      },
      (err:any)=>{
        var get_errors = JSON.parse(err._body);
        if(get_errors.errors){
          var firstkey = Object.keys(get_errors.errors)[0];
          var msg = get_errors.errors[firstkey];
          this.toastr.error(msg,"Wiseperks Registration");
        } else if(get_errors.message){
          this.toastr.error(get_errors.message,"Wiseperks Registration");
        }
      });
    }
    */
   }

  ngOnInit() {
    
  }

}
