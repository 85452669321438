import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PanelistService } from '../../shared/panelist.service';
import { trigger,state, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({opacity: 1, transform: 'translateX(0)'})),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)'
        }),
        animate('0.3s 0.1s ease-in')
      ]),
      transition('* => void', [
        animate('0.3s 0.1s ease-out', style({
          opacity: 0,
          transform: 'translateY(-100%)'
        }))
      ])
    ])
  ]
})
export class RedeemComponent implements OnInit {

  @ViewChild('closeBtn') closeBtn: ElementRef;

  showRedeemLoader:boolean = true;
  GetLeftSideCompJson:boolean = false;
  GetCardData:boolean = false;
  available_points:number;
  available_amount:number;
  pending_points:number;
  PanelistCountryId:number;
  cardArr=[];
  PanelistId:string;
  PanelistName:string;
  PanelistEmail:string;
  PanelistAuthToken:string;
  PanelistWithdrawPoint:number;
  PanelistWithdrawAmount:number =0;
  PanelistWithdrawCardId:string;
  PanelistWithdrawCardName:string;
  ChangMainBalance:number;
  ChangManiPendingBal:number;
  PanelistEmailVerify:number;
  PanelistWisecheck:number;
  EmailVerifyBoxShow:boolean;
  showModal:boolean;
  showPendingModal:boolean=false;
  modalloadstart:boolean=false;

  small_label_class:string;
  medium_label_class:string;
  large_label_classs:string;

  show_more_div:boolean = false;
  modal_card_img_src:string;
  amountToShowModal:number;
  amountIconToShowModal:any;

  modal_content_show:boolean;
  modal_wisecheck_show:boolean;
  modal_email_content_show:boolean;
  modal_unsubscribe_msg_show:boolean;

  ShowRedeemHistoryButton:boolean;
  redeemHistoryData:any = [];
  RedeemHistoryCall:number = 0;
  redeemHistoryDataLoader:boolean;
  RedeemHistoryCloseBtn:boolean;
  redeemThreshold:any = {};
  finalredeemThreshold:any = {};
  pointsToRedeem:any[] =[25000, 50000, 75000];


  StatusCashOut:boolean; // added by khushal as dated on 10-oct-18, this field is false when we do unsubscribe panelists

  public buttonloading:boolean = false;

  DOIPts:number;

  dynamic_modal_id:string = "redeemModal";

  constructor(private title: Title,
     private _panelistService: PanelistService,
     private toastr: ToastrService) {
      
      this.PanelistId = localStorage.getItem('currentLoginPanelist');
  }

  ngOnInit() {

    this.title.setTitle('WisePerks | Respondent Points Redeem Page');
   
    
  }
 
  RedeemHistoryShow(){

    var isdivalreadyOpen = document.getElementById("redeem_history_div");
    var existsClassName = isdivalreadyOpen.className;
    var ren = /history-slide-open-norecord/gi;

    if(this.RedeemHistoryCall == 0  && existsClassName.search(ren) == -1) { // this check was, if multiple time fast click  button on Redeem History

      this.RedeemHistoryCall = 1;
      this.RedeemHistoryCloseBtn = true;
      this.redeemHistoryDataLoader = true;
      isdivalreadyOpen.classList.add("history-slide-open-norecord");

      /* Below Code Call API, Start Here */
        var getData = [];
        getData['panelist_id'] = this.PanelistId;

        this._panelistService.RedeemHistoryData(getData).subscribe((resp:any) => {
          if(resp.auth == true && resp.success == true){
            this.RedeemHistoryCall = 0;
            this.redeemHistoryDataLoader = false;
            this.redeemHistoryData = resp.data;

            var elem = document.getElementById("redeem_history_div");
            //elem.className = elem.className.replace(/\brhistory-container\b/g, "");

            // we have not add for '0' & '1' length because already add "history-slide-open-norecord"
            if(this.redeemHistoryData.length == 2){
              elem.classList.add("history-slide-open_2");
            } else if(this.redeemHistoryData.length == 3) {
              elem.classList.add("history-slide-open_3");
            } else if(this.redeemHistoryData.length >= 4){
              elem.classList.add("history-slide-open_4");
            }

          }
        }, (err:any) => {
            this.RedeemHistoryCall = 0;
            this.redeemHistoryDataLoader = false;
          this.showNewFlashError(err, "WisePerks");
        });
      /* Above Code Call API, Start Here */

    }
    
  }


  RedeemHistoryHide(){
    var elem = document.getElementById("redeem_history_div");

    if(elem.className.search(/history-slide-open_2/gi) != -1){
      elem.className = elem.className.replace(/\bhistory-slide-open_2\b/g, "");
    }

    if(elem.className.search(/history-slide-open_3/gi) != -1){
      elem.className = elem.className.replace(/\bhistory-slide-open_3\b/g, "");
    }

    if(elem.className.search(/history-slide-open_4/gi) != -1){
      elem.className = elem.className.replace(/\bhistory-slide-open_4\b/g, "");
    }
    
    setTimeout(()=>{
      if(elem.className.search(/history-slide-open-norecord/gi) != -1){
        elem.className = elem.className.replace(/\bhistory-slide-open-norecord\b/g, "");
      }
      
    },500);

    setTimeout(()=>{
      this.RedeemHistoryCloseBtn = false;
    },900);
    //elem.classList.add("rhistory-container");
  }

  getPanelistInfo(getevent){ 
    this.available_points = getevent['available_points'];
    this.available_amount = getevent['amount'];
    this.pending_points = getevent['pending_pts'];
    this.PanelistName = getevent['name'];
    this.PanelistEmailVerify = getevent['email_verify'];
    this.PanelistWisecheck = getevent['wisecheck'];
    this.PanelistCountryId = getevent['country_id'];
    this.PanelistEmail = getevent['email'];
    this.PanelistAuthToken = getevent['authtoken'];
    this.StatusCashOut = getevent['status_cashout'];
    this.DOIPts = getevent['doi_point'];
    
    if(getevent['is_old_panelist'] == "old WP"){
      this.ShowRedeemHistoryButton = true;
    }
    
    if(this.PanelistCountryId != null && this.PanelistCountryId > 0){
      this.GetCountryCard(this.PanelistCountryId);
    }

    if(this.PanelistEmailVerify != 1 && this.PanelistWisecheck != 1 && getevent['status_email'] == true){
      this.EmailVerifyBoxShow = true;
    }

    if(this.pending_points > 0){
      this.dynamic_modal_id = "pendingModal";
    }

    this.DisabledPointButtons();
  }


  ShowHideResendEmail(getflag){
    if(this.PanelistEmailVerify != 1 && this.PanelistWisecheck != 1 && getflag == true){
      this.EmailVerifyBoxShow = true;
    } else {
      this.EmailVerifyBoxShow = false;
    }
  }


  GetCountryCard(getCountryId){
    //get points to show on each card starts here
     let i = 0;
      // for (let key of this.pointsToRedeem) {
        
      //   if(this.PanelistCountryId == Number(Object.keys(this.pointsToRedeem[i]))) {
      //     this.redeemThreshold = Object.values(this.pointsToRedeem[i]);
      //     for (let key1 of this.redeemThreshold) {
      //       this.finalredeemThreshold = Object.values(key1);
      //     }
      //   }
      //   i++;
      // }
      this.finalredeemThreshold = this.pointsToRedeem;
      //get points to show on each card ends here

    this._panelistService.getCountryCard(getCountryId).subscribe((resp:any)=>{
      if(resp.auth == true && resp.success == true){
        let arrlength = resp.data.length; 
        this.GetCardData = true;
        
        for(var i=0; i< arrlength; i++){
          this.cardArr[i] = resp.data[i];
        }
        //console.log(this.cardArr);
        this.StopPageLoader({});
      }
    },(err:any)=>{
      this.GetCardData = true;
      this.showNewFlashError(err,"WisePerks");
      this.StopPageLoader({});
    });
  }

  DisabledPointButtons(){

    //check points to show active on each card starts here 
     // let i = 0;
      // for (let key of this.pointsToRedeem) { //if redeem points vary for country
        
        // if(this.PanelistCountryId == Number(Object.keys(this.pointsToRedeem[i]))) {
          // let pointsToEnable = Object.values(this.pointsToRedeem[i]);
          let pointsToEnable = this.pointsToRedeem;
          // for (let pts1 of pointsToEnable) {
            // let enablePts = Object.values(pts1);
            if(this.available_points >= pointsToEnable[0]){
              this.small_label_class = "label-success";
            } else {
              this.small_label_class = "label-default disabled";
            }

            if(this.available_points >= pointsToEnable[1]){
              this.medium_label_class = "label-success";
            } else {
              this.medium_label_class = "label-default disabled";
            }

            if(this.available_points >= pointsToEnable[2]){
              this.large_label_classs = "label-success";
            } else {
              this.large_label_classs = "label-default disabled";
            }
          // }
        // }
        // i++;
      // }
      //check points to show active on each card ends here
  }

  OpenModal(getCardImageUrl, getPoint, getCardId, getCardName){
    this.modalloadstart = true;

    if(this.pending_points > 0){    // open pendig modal pop up
      
      this.RedeemHistoryHide();

      if(getPoint <= this.available_points){
        this.showPendingModal = true;
      } else {
        this.showPendingModal = false;
      }

    } else {      // open redeem modal pop up

      this._panelistService.getCardAmount(this.PanelistCountryId,getPoint).subscribe((resp:any)=>{
        if(resp.auth == true && resp.success == true){
          this.amountToShowModal = resp.data.currency_pts;
          this.amountIconToShowModal = resp.data.ascii;
        }
      },(err:any)=>{
        this.showNewFlashError(err,"WisePerks");
      });

      //console.log(getCardImageUrl); console.log(getPoint);
      //console.log(getCardId);
      this.RedeemHistoryHide();                 // first hide redeem History
      this.PanelistWithdrawPoint = getPoint;

      if(getPoint <= this.available_points){
        this.showModal = true;
      } else {
        this.showModal = false;
      }

      this.PanelistWithdrawCardId = "";
      //this.PanelistWisecheck = 0;
      //this.PanelistEmailVerify = 1;  


      if(this.PanelistWisecheck == 1){                                    //Panelist WiseCheck time
        this.modal_email_content_show = false;
        this.modal_unsubscribe_msg_show = false;
        this.modal_content_show  = false;
        this.modal_wisecheck_show = true;
      } else if(this.StatusCashOut == false){                             //Unsubscribe msg
        this.modal_content_show  = false;
        this.modal_wisecheck_show = false;
        this.modal_email_content_show = false;
        this.modal_unsubscribe_msg_show = true;
      } else if(this.PanelistEmailVerify != 1){                           //Panelist Verify Email
        this.modal_content_show  = false;
        this.modal_wisecheck_show = false;
        this.modal_unsubscribe_msg_show = false;
        this.modal_email_content_show = true;
      } else {                                                            // Reddem Req successfull
        this.modal_wisecheck_show = false;
        this.modal_email_content_show = false;
        this.modal_unsubscribe_msg_show = false;
        this.modal_content_show  = true;
        this.modal_card_img_src = getCardImageUrl;
        this.PanelistWithdrawCardId = getCardId;
        this.PanelistWithdrawCardName = getCardName;
      }

    }
    this.modalloadstart = false;
  }

  
  ModalConfirm(){
    this.buttonloading = true;
    if(this.PanelistWithdrawCardId !="" && this.PanelistWithdrawCardId !=null){
      var postData = [];

      postData['panelist_id'] = this.PanelistId;
      postData['giftcard_id'] = this.PanelistWithdrawCardId;
      postData['points'] = this.PanelistWithdrawPoint;
      postData['amount'] = this.amountToShowModal;
      postData['ascii'] = this.amountIconToShowModal;

      this._panelistService.RedeemPoints(postData).subscribe((resp:any) => {
        if(resp.auth == true && resp.success == true){
          //console.log("Redeem");
          //console.log(resp);

          this.available_points = resp.data.available_points;
          this.ChangMainBalance = resp.data.available_points;
          this.DisabledPointButtons();

          this.closeBtn.nativeElement.click();
          this.toastr.clear();
          this.toastr.success(resp.message,"WisePerks");
          this.buttonloading = false;
        }
      },(err:any) => {
        this.closeBtn.nativeElement.click();
        this.showNewFlashError(err,'WisePerks');
        this.buttonloading = false;
      });
    }
  }


  PendingModalConfirm(){
    this.buttonloading = true;

    var postData = [];

    postData['panelist_id'] = this.PanelistId;

    this._panelistService.SettlePendingPoints(postData).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true){
        // console.log("Response : ", resp);

        this.available_points = resp.data.available_points;
        this.ChangMainBalance = resp.data.available_points;
        this.ChangManiPendingBal = resp.data.pending_points;
        this.pending_points = resp.data.pending_points;
        
        if(this.pending_points > 0){
          this.dynamic_modal_id = "pendingModal";
          this.showPendingModal = true;
        } else {
          this.showPendingModal = false;
          this.dynamic_modal_id = "redeemModal";
        }
        this.DisabledPointButtons();

        this.closeBtn.nativeElement.click();
        this.toastr.clear();
        this.toastr.success(resp.message,"WisePerks");
        this.buttonloading = false;
      }
    },(err:any) => {
      this.closeBtn.nativeElement.click();
      this.showNewFlashError(err,'WisePerks');
      this.buttonloading = false;
    });
  }


  ModalCancel(){
    this.closeBtn.nativeElement.click();
  }


  ResendConfEmail(){
    this.buttonloading = true;
    var postData = [];

    postData['email'] = this.PanelistEmail;
    postData['auth'] = this.PanelistAuthToken;

    this._panelistService.ResendConfirmationMail(postData).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true){
        this.toastr.clear();
        this.toastr.success(resp.message,"WisePerks");
        this.buttonloading = false;
      }
    },(err:any) => {
      this.showNewFlashError(err, "WisePerks");
      this.buttonloading = false;
    });
  }

  StopPageLoader(getJson){

    if(getJson['comp'] == 'leftbar' && getJson['flag'] == 1){
      this.GetLeftSideCompJson = true;
    }
    
    if(this.GetLeftSideCompJson == true && this.GetCardData == true){
      setTimeout(()=>{    //<<<---    using ()=> syntax
        this.showRedeemLoader = false;
      }, 1000);
    }
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

}
