import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PanelistService } from '../shared/panelist.service';
import { TranslateService } from '../shared/translate.service';
import { TranslatePipe } from '../shared/translate.pipe';
import { ToastrService } from 'ngx-toastr';
import * as myLangGlobal from '../shared/globals';
import { Title } from '@angular/platform-browser';
import { trigger,state, style, animate, transition } from '@angular/animations';
import { ServeyService } from '../shared/servey.service';
import { HomeService } from '../home/shared/home.service';
import * as socketIo from 'socket.io-client';
import * as myParamfunc from '../../../assets/js/common/sv';
import * as CommonJsFunc from '../../../assets/js/common/common';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

declare var $;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({opacity: 1, transform: 'translateX(0)'})),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)'
        }),
        animate('0.5s 0.1s ease-in')
      ]),
      transition('* => void', [
        animate('0.5s 0.1s ease-out', style({
          opacity: 0,
          transform: 'translateY(-100%)'
        }))
      ])
    ])
  ],
  providers: [TranslatePipe]
})
export class DashboardComponent implements OnInit {

  promo_form: FormGroup;
 
  @ViewChild('startSurveyBtn') startSurveyBtn: ElementRef;
  @ViewChild('promoModal') promoModal:ElementRef;

  messageFromServer:string;
  ws: WebSocket; 

  PanelistName: string = "";
  PanelistEmail:string;
  PanelistAuthToken:string;
  countryflag: string = "assets/images/users/home/flags/US.png";
  PanelistPreferedLang:string;
  PanelistGender:string;
  Panelistdob:string;
  PanelistStep:number;
  PanelistWiseCheck:number;
  GetSurveyArr = [];
  SurveyLoader:boolean = false;
  GetAllOtherCompJson:boolean = false;
  showProfileSurvey:boolean = false;
  PanelistEmailVerify:number;
  EmailVerifyBoxShow:boolean;
  ProfilingPts:number;
  DOIPts:number;
  PromoCode = [];

  languages = Array<Language>();

  showDashboardLoader:boolean = true;

  show_more_div:boolean = false;
  show_survey:boolean;
  show_wisecheck_msg:boolean;
  show_unsubscribe_msg:boolean;

  StartSurveyLink:string = "";

  getWCMsgString:string = "";
  getWCSubMsgString:string = "";
  getWcRandomImg:string = "";

  Points_banner_show_hide:boolean = false;
  Points_success_msg:string;

  public buttonloading: boolean = false;
  IsUserComesAgain:boolean;
  IsPromoPopupClosed:any;
  showPromoCodeForm:boolean;
  checkPromoInArray:string;
  promocode_form:boolean = true;
  promoCodeValue:string;
  promoCodePercentageVal:number;

  @Output() panelistPromoCodeEvent = new EventEmitter(); // Create event emitter to hide promo form immediately after valid promo code entered
  
  constructor(private router: Router, 
    private _panelistService: PanelistService,
    private formBuilder: FormBuilder,
    private _serveyService: ServeyService,
    private _homeService: HomeService,
    private translateService: TranslateService,
    private translatePipe : TranslatePipe,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private title: Title,
    private _cookieService:CookieService) {
    let currentPreferLang = localStorage.getItem('PreferredLanguage');

    if(currentPreferLang !== null && myLangGlobal.LangArr.indexOf(currentPreferLang) !== -1){
      this.translateService.setLanguage(currentPreferLang);
    }
    
  }


  ngOnInit() {
    this.title.setTitle('WisePerks | Online Survey Respondents Dashboard');

    // For promo code form
    this.promo_form = this.formBuilder.group({
      promo_code : [null,[Validators.required]]
    });

    /* Below Code is for manage Ledger Balance move to Main Balance, Start Here */
        
        // var today = new Date();
        // var todayDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        // //console.log(todayDate);
        
        // const CurrentDatecookieExists: boolean = this._cookieService.check('CurrentDate');
        
        // let getCurrentDateCookie = this._cookieService.get('CurrentDate');

        // if(CurrentDatecookieExists == false || getCurrentDateCookie != todayDate){
        //   this._panelistService.ledgerToMainBalance().subscribe((response:any) =>{
        //     this._cookieService.set('CurrentDate', todayDate);
        //   },(error:any)=>{

        //   });
        // }
        
    /* Above Code is for manage Ledger Balance move to Main Balance, End Here */

    /* Below Code is for manage User check of visit again on dashboard, Start Here */
    this.IsUserComesAgain = this._panelistService.GetUserComesTwice();
    this._panelistService.ResetUserComesTwice();
    /* Above Code is for manage User check of visit again on dashboard, End Here */

    // this.IsPromoPopupClosed = this._panelistService.GetPanelistPromo();
    // this._panelistService.ResetPanelistPromo();
    // this.IsPromoPopupClosed = localStorage.getItem('isPromoClosed'); // manage show hide promo popup only once from local storage
    // Show popup only once if value is set to true in cookie
    // if(this.IsPromoPopupClosed == "true") { $(this.promoModal.nativeElement).modal('show'); }
    
    this.promoCode();
    
    
  }

  /**
   * Check if promo code is available or not today.
   * If available then check if panelist is already filled that promo code or not
   * If not filled by panelist then show promo code form and show one time popup for that particular popup
   */
  promoCode() {

    this._panelistService.getTodayPromoCode().subscribe((resp:any)=>{
      
      if(resp != null && resp.auth == true && resp.success == true){

          this._panelistService.SetTodayPromoCodeInfo(resp.data[0]);

          this.promoCodeValue = resp.data[0].cod;
          this.promoCodePercentageVal = resp.data[0].perc;

          // If panelist has not entered promo code then only show him the form to fill promo code
          if(this.PromoCode != undefined) {
            if(this.PromoCode.length == 0) {
              this.showPromoCodeForm = true;

            } else if(this.PromoCode.length > 0) {
              this.PromoCode.forEach(element => {
                if(element.cod == resp.data[0].cod) { // If promo code is already entered then set flag to not show promo form again
                  this.checkPromoInArray = "exists";
                }
              });
              
              if(this.checkPromoInArray == undefined) {
                this.showPromoCodeForm = true;
              }
            }
          } else {
            this.showPromoCodeForm = true;
          }

          this.IsPromoPopupClosed = localStorage.getItem('isPromoClosed');

          // If promo code is different from cookie popup means we need to show popup one time
          // Below code is commented by khushal dave because now we don't need pop up msg box
          // if( this.IsPromoPopupClosed != this._panelistService.GetTodayPromoCodeInfo().cod) { 
          //   $(this.promoModal.nativeElement).modal('show');
          // }

          this.toastr.clear();
          if( this.IsPromoPopupClosed != this._panelistService.GetTodayPromoCodeInfo().cod) {
            let promosucc1 = this.translatePipe.transform('Dashboard_Promocode_message2' , 'translate');
            let promosucc2 = this.translatePipe.transform('Dashboard_Promocode_message3' , 'translate');

            let promosucc = promosucc1+" "+this.promoCodePercentageVal+"% "+promosucc2;
            this.toastr.success(promosucc,"WisePerks"); // errors coming from API
          }
          this.buttonloading = false;
        } 
        // else {
        //   this.toastr.clear();
        //   this.toastr.success("Something goes Error!","WisePerks");
        //   this.buttonloading = false;
        // }
        
      },(err:any)=>{
        this.showNewFlashError(err,"WisePerks");
        this.buttonloading = false;
    });
  }



  getPanelistInfo(PanelistInfo){
    this.PanelistName = PanelistInfo.name;
    this.languages = PanelistInfo['lang_arr'];
    this.countryflag = "assets/images/users/home/flags/"+PanelistInfo['country_code']+".png";
    this.PanelistPreferedLang = PanelistInfo['currentLang'];
    this.Panelistdob = PanelistInfo['dob'];
    this.PanelistGender = PanelistInfo['gender'];
    this.PanelistStep = PanelistInfo['step'];
    this.PanelistEmailVerify = PanelistInfo['email_verify'];
    this.PanelistWiseCheck = PanelistInfo['wisecheck'];
    this.PanelistEmail = PanelistInfo['email'];
    this.PanelistAuthToken = PanelistInfo['authtoken'];
    this.ProfilingPts = PanelistInfo['profiling_bonus_point'];
    this.DOIPts = PanelistInfo['doi_point'];
    this.PromoCode = PanelistInfo['promo_code'];
    
    if(this.PanelistEmailVerify != 1 && this.PanelistWiseCheck != 1 && PanelistInfo['status_email'] == true){
      this.EmailVerifyBoxShow = true;
    }
    

    if(this.PanelistWiseCheck == 1 && this.PanelistStep == 2 && PanelistInfo['status_survey'] == false){  // wisecheck 
      this.show_survey = false;
      this.show_unsubscribe_msg = false;
      this.show_wisecheck_msg = true;
      this.SurveyLoader = true;
      this.WCRandomImgMsg(); 
      this.StopPageLoader({});
    } else if(PanelistInfo['status_survey'] == false && this.PanelistStep == 2) {  // unsubscribe
      this.show_survey = false;
      this.show_wisecheck_msg = false;
      this.show_unsubscribe_msg = true;
      this.SurveyLoader = true;
      this.WCRandomImgMsg();
      this.StopPageLoader({});
    } else if(PanelistInfo['status_survey'] == false && this.PanelistStep < 2) { // show survey with profile survey
      this.show_wisecheck_msg = false;
      this.show_unsubscribe_msg = false;
      this.show_survey = true;
      this.showProfileSurvey = true;
      this.SurveyLoader = true;
      this.StopPageLoader({});
    } else if (this.PanelistStep >= 1 && PanelistInfo['status_survey'] == true) { // show survey
      this.getSurvey();
      this.show_unsubscribe_msg = false;
      this.show_wisecheck_msg = false;
      this.show_survey = true;
      this.StopPageLoader({});
    } 
    
    
    /*if(this.PanelistStep >= 1 && this.PanelistWiseCheck != 1){
      this.getSurvey();
      this.show_wisecheck_msg = false;
      this.show_survey = true;
    } else {
      this.show_survey = false;
      this.show_wisecheck_msg = true;
      this.SurveyLoader = true;
        this.StopPageLoader({});
    }*/
  }

  /* Validation Start Here */
  isFieldValid(field: string) {
    return !this.promo_form.get(field).valid && this.promo_form.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  /* End Validation Here */
 
  /** Promo code functionality starts here **/
  closePromoPopup(){ // manage show hide promo popup only once from local storage
    this.IsPromoPopupClosed = "false";
    localStorage.setItem('isPromoClosed', this._panelistService.GetTodayPromoCodeInfo().cod);
  }
  // submit promo code
  promoSubmit(){
    this.buttonloading = true;
    if(this.promo_form.valid){

      var todayValidPromo = this._panelistService.GetTodayPromoCodeInfo();

      if(this.promo_form.value.promo_code == todayValidPromo.cod){
        this._panelistService.PanelistPromoCode(this.promo_form).subscribe((resp:any)=>{
          if(resp.auth == true && resp.success == true){
            //Set event emitter value to false to hide promo form immediately after valid promo code entered
            this.panelistPromoCodeEvent.emit(this.showPromoCodeForm=false);
            this.toastr.clear();
            let promosucc1 = this.translatePipe.transform('Dashboard_Promocode_message2' , 'translate');
            let promosucc2 = this.translatePipe.transform('Dashboard_Promocode_message3' , 'translate');

            let promosucc = promosucc1+" "+this.promoCodePercentageVal+"% "+promosucc2;
            this.toastr.success(promosucc,"WisePerks"); // errors coming from API
            localStorage.setItem('isPromoClosed', this._panelistService.GetTodayPromoCodeInfo().cod);
            this.buttonloading = false;
          } else {
            this.toastr.clear();
            this.toastr.success("Something goes Error!","WisePerks");
            this.buttonloading = false;
          }
          
        },(err:any)=>{
          this.showNewFlashError(err,"WisePerks");
          this.buttonloading = false;
        });
      }else{
          this.toastr.clear();
          let promoerr = this.translatePipe.transform('Dashboard_Promocode_message1' , 'translate');
          this.toastr.error(promoerr,"WisePerks");
          this.buttonloading = false;
      }

     
    } else {
      this.buttonloading = false;
      this.validateAllFormFields(this.promo_form); //{7}
    }
  }
  /** End Promo code functionality starts here **/


  ShowHideResendEmail(getflag){
    if(this.PanelistEmailVerify != 1 && this.PanelistWiseCheck != 1 && getflag == true){
      this.EmailVerifyBoxShow = true;
    } else {
      this.EmailVerifyBoxShow = false;
    }
  }


  getSurvey(){
    let panelistId = localStorage.getItem('currentLoginPanelist');
    
    this._panelistService.PanelistSurvey(panelistId).subscribe((resp:any)=>{
      //console.log(resp);
      if(resp.auth == true && resp.success == true){
        this.SurveyLoader = true;
        this.StopPageLoader({});
        let survey_length = resp.data.length;

        if(this.PanelistWiseCheck != 1){
          for(var i=0; i<survey_length; i++){
          
            if(this.PanelistStep < 2 && i == myLangGlobal.StepSurverShow){
              break;
            }
            
            this.GetSurveyArr[i] = resp.data[i];
          }
        }

        if(this.PanelistStep != 2){
          this.showProfileSurvey = true;
        }

        this.CreateManin_GetSurveyArr();

        const socket = socketIo(myLangGlobal.socketConnection, {secure : true});
        
        /* Below code is commented & modified by vivek as dated on 16 nov 18 as discussed with Khushal
        socket.on('getsurvey', (data)=>{
          //console.log(data);
          
          if(data.redirect == "postback") {         //for postback call
            //console.log("1111");
            var socket_surveyLength = data.data.length;

            this.GetSurveyArr = [];
            for(var j=0; j < socket_surveyLength; j++){
              if(this.PanelistStep < 2 && j == myLangGlobal.StepSurverShow){
                break;
              }
              this.GetSurveyArr[j] = data.data[j];
            }
            this.CreateManin_GetSurveyArr();

          } else if(data.data.length > 0 && data.data[0]['redirect'] == 'survey_pause'){  // pause survey from samplicity
            //console.log('22222');
            let getPauseSurveyId = data.data[0]['survey_id'];

            let survey_id_exists = false;

            survey_id_exists = this.GetSurveyArr.some(function (o) {
              //console.log(o);
                if(getPauseSurveyId == o['survey_id']){
                  return true;
                }
            });


            if(survey_id_exists == true){
              let GetSurveyArrNew = [];
              GetSurveyArrNew = this.GetSurveyArr;
              this.GetSurveyArr = [];

              var c=0;
              for(var k = 0; k < GetSurveyArrNew.length; k++){
                if(getPauseSurveyId != GetSurveyArrNew[k]['survey_id']) {
                  this.GetSurveyArr[c] = GetSurveyArrNew[k];
                  c++;
                }
              }
              //console.log(this.GetSurveyArr);
              this.CreateManin_GetSurveyArr();
            }

          } else if(data.data.length > 0 && Object.keys(data.data[0])[0] == 'survey_id') {   // pause quota frm samplicity
            //console.log('33333');
            let getPauseSurveyId = data.data[0]['survey_id'];

            let survey_id_exists = false;

            survey_id_exists = this.GetSurveyArr.some(function (o) {
              //console.log(o);
                if(getPauseSurveyId == o['survey_id']){
                  return true;
                }
            });

            /* also check panelists exists in response array or not, changes by nilesh on node side, 25-Oct-18 */

           /*k let get_current_login_panelistId = localStorage.getItem('currentLoginPanelist');

            let panelist_arr = [];

            if(data.data[0]['panelist_id']){
              panelist_arr = data.data[0]['panelist_id'];
            }

            let panelist_id_exists = false;

            panelist_id_exists = panelist_arr.some(function (p) {
             // console.log(p);
              if(p['pid'] == get_current_login_panelistId){
                return true;
              }
            });

            if(survey_id_exists == true && panelist_id_exists == true){
              let GetSurveyArrNew = [];
              GetSurveyArrNew = this.GetSurveyArr;
              this.GetSurveyArr = [];

              var c=0;
              for(var k = 0; k < GetSurveyArrNew.length; k++){
                if(getPauseSurveyId != GetSurveyArrNew[k]['survey_id']) {
                  this.GetSurveyArr[c] = GetSurveyArrNew[k];
                  c++;
                }
              }
              //console.log(this.GetSurveyArr);
              this.CreateManin_GetSurveyArr();
            }

          } else {            // for launch survey or quota add
            //console.log("44444");
            let panelist_id_exists = false;
            
            panelist_id_exists = data.data.some(function (o) {
              //console.log(o);
                if(panelistId == o['_id']){
                  return true;
                }
            });

            if(panelist_id_exists == true){
              if(this.PanelistStep < 2){
                if(this.GetSurveyArr.length < myLangGlobal.StepSurverShow+1){
                  this.GetSurveyArr[this.GetSurveyArr.length] = data.data[0];
                  this.CreateManin_GetSurveyArr();
                }
              } else {
                this.GetSurveyArr[this.GetSurveyArr.length] = data.data[0];
                this.CreateManin_GetSurveyArr();
              }
            }

          }
          
        });*/
		
		
		socket.on('getsurvey', (data)=>{
          //console.log(data);
          
          if(data.redirect == "postback") {         //for postback call
            //console.log("1111");
            let getPostbackPanelistId = localStorage.getItem('PostbackPanelistId');
            let currentLoginPanelist = localStorage.getItem('currentLoginPanelist');

            //console.log("inside");
            //console.log("systemPanelist = "+currentLoginPanelist);
            //console.log("postbackPanelists = "+getPostbackPanelistId);
            
            if(getPostbackPanelistId == currentLoginPanelist){
              var socket_surveyLength = data.data.length;

              this.GetSurveyArr = [];
              for(var j=0; j < socket_surveyLength; j++){
                if(this.PanelistStep < 2 && j == myLangGlobal.StepSurverShow){
                  break;
                }
                this.GetSurveyArr[j] = data.data[j];
              }
              this.CreateManin_GetSurveyArr();
            }

            localStorage.removeItem('PostbackPanelistId');

          } else if(data.data.length > 0 && data.data[0]['redirect'] == 'survey_pause'){  // pause survey from samplicity
            //console.log('22222');
            let getPauseSurveyId = data.data[0]['survey_id'];

            let survey_id_exists = false;

            survey_id_exists = this.GetSurveyArr.some(function (o) {
              //console.log(o);
                if(getPauseSurveyId == o['survey_id']){
                  return true;
                }
            });


            if(survey_id_exists == true){
              let GetSurveyArrNew = [];
              GetSurveyArrNew = this.GetSurveyArr;
              this.GetSurveyArr = [];

              var c=0;
              for(var k = 0; k < GetSurveyArrNew.length; k++){
                if(getPauseSurveyId != GetSurveyArrNew[k]['survey_id']) {
                  this.GetSurveyArr[c] = GetSurveyArrNew[k];
                  c++;
                }
              }
              //console.log(this.GetSurveyArr);
              this.CreateManin_GetSurveyArr();
            }

          } else if(data.data.length > 0 && Object.keys(data.data[0])[0] == 'survey_id') {   // pause quota frm samplicity
            //console.log('33333');
            let getPauseSurveyId = data.data[0]['survey_id'];

            let survey_id_exists = false;

            survey_id_exists = this.GetSurveyArr.some(function (o) {
              //console.log(o);
                if(getPauseSurveyId == o['survey_id']){
                  return true;
                }
            });

            /* also check panelists exists in response array or not, changes by nilesh on node side, 25-Oct-18 */

            let get_current_login_panelistId = localStorage.getItem('currentLoginPanelist');

            let panelist_arr = [];

            if(data.data[0]['panelist_id']){
              panelist_arr = data.data[0]['panelist_id'];
            }

            let panelist_id_exists = false;

            panelist_id_exists = panelist_arr.some(function (p) {
             // console.log(p);
              if(p['pid'] == get_current_login_panelistId){
                return true;
              }
            });

            if(survey_id_exists == true && panelist_id_exists == true){
              let GetSurveyArrNew = [];
              GetSurveyArrNew = this.GetSurveyArr;
              this.GetSurveyArr = [];

              var c=0;
              for(var k = 0; k < GetSurveyArrNew.length; k++){
                if(getPauseSurveyId != GetSurveyArrNew[k]['survey_id']) {
                  this.GetSurveyArr[c] = GetSurveyArrNew[k];
                  c++;
                }
              }
              //console.log(this.GetSurveyArr);
              this.CreateManin_GetSurveyArr();
            }

          } else {            // for launch survey or quota add
            //console.log("44444");
            let panelist_id_exists = false;
            
            panelist_id_exists = data.data.some(function (o) {
              //console.log(o);
                if(panelistId == o['_id']){
                  return true;
                }
            });

            if(panelist_id_exists == true){
              if(this.PanelistStep < 2){
                if(this.GetSurveyArr.length < myLangGlobal.StepSurverShow+1){
                  this.GetSurveyArr[this.GetSurveyArr.length] = data.data[0];
                  this.CreateManin_GetSurveyArr();
                }
              } else {
                this.GetSurveyArr[this.GetSurveyArr.length] = data.data[0];
                this.CreateManin_GetSurveyArr();
              }
            }

          }
          
        });

      //console.log(this.GetSurveyArr);
      }
    },(err:any)=>{
      this.SurveyLoader = true;
        this.StopPageLoader({});
      this.showNewFlashError(err,"WisePerks");
    });
  }

  CreateManin_GetSurveyArr(){
    /* Below code is for create smaplicity link, Start Here */
      /*now modify below code becoz sell api merge on this so we make different url for sell api, as dated on 24July,19*/

        //console.log(this.GetSurveyArr);
    for(var k=0; k < this.GetSurveyArr.length; k++){
      var get_suplier_relation_id = this.GetSurveyArr[k].supplier_relation_id;
      var get_survey_id = this.GetSurveyArr[k].survey_id;
      var get_quota_id = this.GetSurveyArr[k].quota_id;
      var get_survey_point = this.GetSurveyArr[k].currency_points;
      var panelistId = localStorage.getItem('currentLoginPanelist').trim();
      var newPanelistId = panelistId.replace(/\0/g, '');
      
      if(this.GetSurveyArr[k].client_id && (this.GetSurveyArr[k].client_id == 1)){ // survey link for samplicity 2.O
        var surveyHitLink = CommonJsFunc.encode64(get_suplier_relation_id+'/'+get_survey_id+'/'+get_quota_id+'/'+get_survey_point+'/'+newPanelistId+'/'+this.GetSurveyArr[k].client_id);
      } else if(this.GetSurveyArr[k].client_id) {  // survey link for sell api
        var surveyHitLink = CommonJsFunc.encode64(get_survey_id+'/'+newPanelistId+'/'+get_survey_point+'/'+this.GetSurveyArr[k].client_id);  
      } else {      // survey link for samplicity
        var surveyHitLink = CommonJsFunc.encode64(get_suplier_relation_id+'/'+get_survey_id+'/'+get_quota_id+'/'+get_survey_point+'/'+newPanelistId);
      }

      var CreateLink = "https://"+location.host+"/survey_link/"+surveyHitLink;
      this.GetSurveyArr[k]['survey_link'] = CreateLink;
    }


    /* Below Code is for Find Earn Point or Open Banner */
      var PostBackEarnPoint = this._panelistService.getPostbackVar();
      
      if(PostBackEarnPoint['show_dashboard_earn_point_banner']!=true && this.GetSurveyArr.length == 0){ // if not came from postback
        this.WCRandomImgMsg();
        
      } else if(PostBackEarnPoint['show_dashboard_earn_point_banner']==true){     // If came from postback call
        
        if(this.GetSurveyArr.length == 0){
          
          if(PostBackEarnPoint['success_msg'].substring(0,18) == "Survey_success_msg"){ 
            this.getWCMsgString = "Survey_success_but_nomore_survey";
            this.getWCSubMsgString = "";
          } else { 
            this.getWCMsgString = PostBackEarnPoint['success_msg'];
            this.getWCSubMsgString = "";
          }
          
          let wcrandomImgNumber = Math.floor((Math.random() * myLangGlobal.WisecheckDashboardImg.length) + 1);
          this.getWcRandomImg = myLangGlobal.WisecheckDashboardImg[wcrandomImgNumber-1];

        } else {
          // check after success message set to null
          if(PostBackEarnPoint['success_msg'] != ""){
            this.Points_banner_show_hide = true;
            this.Points_success_msg = PostBackEarnPoint['success_msg'];
          } 
        }
      }
      // here value of success message form panelist service reset to hide the success message from dashboard on page refresh or route change
      PostBackEarnPoint['success_msg'] = "";
    /* Above Code is for Find Earn Point or Open Banner */
    
    /*if(this.GetSurveyArr.length == 0) {
        this.WCRandomImgMsg();
    }*/
  /* Above code is for create smaplicity link, Start Here */
  }

  WCRandomImgMsg(){
    let wcrandomNumber = Math.floor((Math.random() * myLangGlobal.WisecheckDashboardMsg.length) + 1);
    this.getWCMsgString = myLangGlobal.WisecheckDashboardMsg[wcrandomNumber-1];
    this.getWCSubMsgString = myLangGlobal.WisecheckDashboardSubMsg[wcrandomNumber-1];
    
    let wcrandomImgNumber = Math.floor((Math.random() * myLangGlobal.WisecheckDashboardImg.length) + 1);
    this.getWcRandomImg = myLangGlobal.WisecheckDashboardImg[wcrandomImgNumber-1];
    //console.log(this.getWcRandomImg);
  }

  UpdatePanelistName(getPanelistName){
    this.PanelistName = getPanelistName;
  }

  PreferredLang(lang_code, event){
    localStorage.setItem('PreferredLanguage',lang_code);
    
    let selectIndex = event.target.selectedIndex;
    let getlanguageId = event.target[selectIndex]['attributes']['lang_id']['value'];
    
    //var data = {first_name:this.PanelistName, gender: this.PanelistGender, dob: this.Panelistdob, language: getlanguageId};
    var data = "gender="+this.PanelistGender+"&dob="+this.Panelistdob+"&language="+getlanguageId; 
    
    this._panelistService.PanelistUpdate(data).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true) {
        this.translateService.setLanguage(lang_code);
        //console.log(this.router.url);
        //location.reload();
        //this.router.navigate([this.router.url]);
        this.toastr.clear();
        this.toastr.success("You have successfully changed your preffered Language", "Wiseperks");
      }
    }, (err:any)=>{
      this.showNewFlashError(err,"WisePerks");
    });
  }

  

  SelectedLang(getLang){
    return this.PanelistPreferedLang == getLang;
  }


  ResendConfEmail(){
    this.buttonloading = true;
    var postData = [];

    postData['email'] = this.PanelistEmail;
    postData['auth'] = this.PanelistAuthToken;

    this._panelistService.ResendConfirmationMail(postData).subscribe((resp:any) => {
      if(resp.auth == true && resp.success == true){
        this.toastr.clear();
        this.toastr.success(resp.message,"WisePerks");
        this.buttonloading = false;
      }
    },(err:any) => {
      this.buttonloading = false;
      this.showNewFlashError(err, "WisePerks");
    });
  }


  onLogout() {
    localStorage.removeItem('currentLoginPanelist');
    this.router.navigate(['']);
  }

  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

  StopPageLoader(getJson){
    
    if(getJson['comp'] == 'leftbar' && getJson['flag'] == 1){
      this.GetAllOtherCompJson = true;
    }
    
    if(this.GetAllOtherCompJson == true && this.SurveyLoader == true){
      this.showDashboardLoader = false;
    }
  }

}

export class Language
{
    constructor(id:number, name:string, code:string) {
        this.id=id;
        this.name=name;
        this.code=code;
    }
    id:number;
    name:string;
    code: string;
}
