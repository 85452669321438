import { environment } from '../../../environments/environment';

 //export const rootUrl = '//staging.wiseperks.com:7000/api/v1/c342590d3324f0be522862360c9b94af'; // for staging
 //export const rootUrl = 'http://34.208.69.183:7000/api/v1/c342590d3324f0be52286
 //export const rootPHPUrl = '//staging.wiseperks.com:9001'; // for staging
 //export const socketConnection = "//staging.wiseperks.com:7000"; // for staging
//export const rootUrl = '//18.236.72.145:7000/api/v1/c342590d3324f0be522862360c9b94af';

// export const rootUrl = '//wiseperks.com:7000/api/v1/c342590d3324f0be522862360c9b94af'; // for live
// export const rootPHPUrl = '//wiseperks.com:9001';
// export const socketConnection = "//wiseperks.com:7000"; // for live

// export const rootUrl = 'http://localhost:9000/api/v1/c342590d3324f0be522862360c9b94af'; // for local
// export const rootPHPUrl = 'http://localhost::9001'; // for local
// export const socketConnection = "http://localhost:9000"; // for local

export const rootUrl = environment.rootUrl;
export const rootPHPUrl = environment.rootPHPUrl;
export const socketConnection = environment.socketConnection;

export let globalPanelistsInfo = {};

export const LangArr:Array<any> = ['de','en','es','fl','fr','id','it','ja','ko','ms','nl','pl','pt','th','tr','vi'];

export const StepSurverShow = 4; // show only 5 survey when user not profiling his questions

export const SamplicitySupplierId = 41;

export const SurveySuccessMsg1 = ["Survey_success_msg1", "Survey_success_msg2"];

export const SurveySuccessMsg2 = ["Survey_terminate_pnt_msg1"];

export const SurveySuccessMsg3 = ["Survey_terminate_nopnt_msg1"];

export const WisecheckDashboardMsg = ["WC_DashboardMsg_1", "WC_DashboardMsg_2"];
export const WisecheckDashboardSubMsg = ["WC_DashboardSubMsg_1", "WC_DashboardSubMsg_2"];

export const WisecheckDashboardImg = ["img1.png","img2.png", "img3.png"];

export const PassbookLiveDate = "2019-12-16";