// Spanish
export const TRANSLATIONS_ES ={
  "home_header_selectlang": "Seleccione el idioma",
  "home_header_loginbutton": "Iniciar sesión",
  "home_header_signupbutton": "Regístrate",
  "home_header_homebutton": "Casa",
  
  "home_register_bannertxt1": "Aquí tu",
  "home_register_bannertxt2": "pensamientos",
  "home_register_bannertxt3": "puedo traerte",
  "home_register_bannertxt4": "recompensa$",
  "home_register_bannertxt5": "Obtener bono",
  "home_register_bannertxt6": "En la suscripción",
  "home_register_fbsignup": "REGISTRATE CON FACEBOOK",
  "home_register_fblogin": "INICIAR SESIÓN CON FACEBOOK",
  "home_register_or": "O",
  "home_register_emailsignup": "Ingresa con e-mail",
  "home_register_emaillogin": "Iniciar sesión con correo electrónico",
  "home_register_fpass": "¿Se te olvidó tu contraseña?",
  "home_register_fname": "Nombre de pila",
  "home_register_lname": "Apellido",
  "home_register_email": "Dirección de correo electrónico",
  "home_register_pass": "Contraseña",
  "home_register_cpass": "Confirmar contraseña",
  "home_register_tcpp": "Al unirse a usted está de acuerdo con nuestra",
  "home_register_tcpptandcond": "Términos y condiciones",
  "home_register_tcppand": "Y",
  "home_register_tcppprivacy": "Política de privacidad",
  "home_register_join": "Únete a nosotros. Es gratis",
  "home_register_login": "Iniciar sesión",
  "home_register_name_required" : "Se requiere el nombre.",
  "home_register_email_required" : "Correo electronico es requerido.",
  "home_register_invalid_email" : "Formato de correo inválido",
  "home_register_password_required" : "Se requiere contraseña.",
  "home_register_password_lower_digit" : "La contraseña debe ser de 6 dígitos.",
  "home_register_password_higher_digit" : "La contraseña debe ser utilizada Se requieren 15 dígitos",
  "home_register_cpassword_required" : "Confirmar que la contraseña es obligatoria",
  "home_register_cpassword_lower_digit" : "Confirmar contraseña debe ser requerida de 6 dígitos",
  "home_register_cpassword_higher_digit" : "Confirmar contraseña debe usarse 15 dígitos requeridos",
  "home_register_cpassword_not_match" : "Confirmar que la contraseña no coincide",
  
  "home_register_fp_not_worry" : "Por favor ingrese su EMAILID registrado.", 
  "home_register_fp_resetpass_msg" : "Para restablecer su contraseña, por favor ingrese su ID de email registrado", 
  "home_register_fp_enter_email_msg" : "Ingrese su email aquí",
  "home_register_fp_submit_button" : "Enviar", 
  "home_register_fp_temporary_sent_pass_msg" : "Se ha enviado un enlace de restablecimiento de contraseña a su identificador de correo electrónico registrado. Por favor actualice su contraseña lo antes posible.", 

  "home_works_howitworks": "¿Cómo funciona?",
  "home_works_howitworkst1": "Presentarse", 
  "home_works_howitworksd1": "Hazte socio, Regístrate",
  "home_works_howitworkst2": "Recibir encuestas",
  "home_works_howitworksd2": "Comience a recibir encuestas intuitivas y sencillas",
  "home_works_howitworkst3": "Gane puntos",
  "home_works_howitworksd3": "Dé susDé s  mejores respuestas y acumule puntos",
  "home_works_howitworkst4": "Redimir",
  "home_works_howitworksd4": "Canjea tus puntos por muchas recompensas emocionantes",
  
  "home_aboutus_whoweare": "¿Quienes somos?",
  "home_aboutus_whowearep1": "En WisePerks trabajamos duro para asegurarnos de que el tiempo que pasa en línea sea una experiencia gratificante. Trabajamos con cientos de empresas que están ansiosas por recibir sus comentarios, saber cómo se sienten acerca de sus productos o servicios. Usted brinda su opinión sobre varios servicios, pero ninguno de esos servicios en realidad lo recompensa por su tiempo, a diferencia de WisePerks, donde cada encuesta en la que participa le otorga grandes recompensas en efectivo por su tiempo.",
  "home_aboutus_whowearep2": "Valoramos tus comentarios y queremos que seas recompensado por tu tiempo. Tenemos encuestas sobre diversos temas para que coincida con sus intereses y que sean fáciles de responder. Puede ganar fácilmente entre $ 50 y $ 100 por mes tomando varias encuestas. Así que trabajemos juntos para que esto suceda.",
  
  "home_earn_more_surabt": "¿Qué encuestas preguntan?",
  "home_earn_more_bp": "Expertos en negocios",
  "home_earn_more_consumers": "Consumidor",
  "home_earn_more_hc": "Salud y fitness",
  "home_earn_more_entertainment": "Entretenido y divertido",
  "home_earn_more_gaming": "Juegos Freaks",
  "home_earn_more_tech": "Conocimientos técnicos",
  "home_earn_more_bonus_survey": "Encuesta de bonificación", 
  
  "home_reward_whatuearn": "¿Qué ganas?",
  "home_reward_whatuearnp1": "Nos gusta mantener las cosas simples. Obtiene puntos por todas las encuestas completadas con éxito. Cuantas más encuestas complete, más puntos ganará. Los puntos se pueden canjear por tarjetas de regalo de su elección después de cruzar un cierto umbral.",
  "home_reward_whatuearnp2": "Una vez que esté listo para canjear, elija la opción que desee: Amazon, Starbucks, iTunes y muchos otros. También damos Bonificaciones y agradezco a Dios Sus ofertas de los viernes de vez en cuando.",
  
  "home_counter_earnedover": "Los miembros han ganado más de",
  
  "home_contact_getintouch": "Estar en contacto",
  "home_contact_emailus": "Para cualquier pregunta, envíenos un correo electrónico a",
  
  "home_footer_faq": "Preguntas frecuentes",
  "home_footer_pp": "Política de privacidad",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Próximas funciones",
  "home_footer_copyright": "Copyright © current_year WisePerks. Todos los derechos reservados.",

  "home_header_home": "Casa",
  "home_footer_toknowmore": "Para saber más",
  "home_footer_createacc": "Crea una cuenta gratis y Únete a nuestros miles de miembros felices.",
  "home_footer_registernow": "Regístrate ahora",

  "home_reset_password" : "Restablecer contraseña", 
  "home_reset_pass_content" : "Ha solicitado restablecer la contraseña para", 

  "dashboard_sidebar_selectavatar" : "Selecciona tu avatar", 
  "dashboard_sidebar_uploadavatar" : "Haga clic aquí para subir su imagen", 
  "dashboard_sidebar_pbank" : "Puntos del Banco",
  "dashboard_sidebar_accumulated" : "Ganado", 
  "dashboard_sidebar_realized" : "Di cuenta", 
  "dashboard_sidebar_pending" : "Ajustes", 
  "dashboard_sidebar_earned_tooltip" : "Puntos que has acumulado hasta ahora.",
  "dashboard_sidebar_pending_tooltip" : "Puntos que deben ser revertidos de su cuenta.",
  "dashboard_sidebar_main" : "Principal",
  "dashboard_sidebar_dashboard" : "Tablero",
  "dashboard_sidebar_profile" : "Perfil",
  "dashboard_sidebar_updateprofile" : "Actualizar perfil",
  "dashboard_sidebar_shistory" : "Historia de la encuesta",
  "dashboard_sidebar_mtrans" : "Mi transacción",
  "dashboard_sidebar_passbook" : "Libro de puntos",
  "dashboard_sidebar_redeem" : "Redimir",
  "dashboard_sidebar_refer_friend" : "Recomendar amigo",
  "dashboard_sidebar_logout" : "Cerrar sesión",

  "dashboard_sidebar_tutorial1" : "Te muestra todas las encuestas disponibles y lo que puedes ganar de ellos.",
  "dashboard_sidebar_tutorial2" : "Responda algunas preguntas aquí para dejarnos conocerle mejor y ayúdenos a enviarle encuestas de su interés. Tenga en cuenta para algunas preguntas que no sería capaz de actualizar sus respuestas después de la presentación.",
  "dashboard_sidebar_tutorial2update" : "Puede actualizar sus respuestas aquí, sin embargo pocas preguntas no estarán disponibles para la actualización.",
  "dashboard_sidebar_tutorial3" : "Encuentra aquí todas las encuestas que intentaste.",
  "dashboard_sidebar_tutorial4" : "Le ayuda a hacer un seguimiento de todas sus transacciones en WisePerks.",
  "dashboard_sidebar_tutorial5" : "Pocos pasos rápidos para convertir sus puntos realizados en recompensas.",
  "dashboard_sidebar_tutorial6" : "Vea el historial completo de transacciones de los puntos debitados / acreditados en su cuenta.",
  "dashboard_sidebar_gotit" : "Entiendo",

  "dashboard_sidebar_note" : "tenga en cuenta",
  "dashboard_sidebar_note1" : "Todas las respuestas deben responderse honestamente.",
  "dashboard_sidebar_note2" : "Sea específico y preciso con respuestas abiertas.",
  "dashboard_sidebar_note3" : "¡No aceleres! Se establecen los tiempos mínimos de finalización de la encuesta.",
  "dashboard_sidebar_note4" : "Proporcione respuestas coherentes cuando se formulen las mismas preguntas.",
  "dashboard_sidebar_note5" : "Se agregarán nuevas encuestas y las cerradas se eliminarán automáticamente del salpicadero.",
  "dashboard_sidebar_note6" : "Gana algunos puntos rápidos sólo por referencias.",

  "dashboard_setting_hi" : "Hola",
  "dashboard_setting_minfo" : "Información de membresía",
  "dashboard_setting_macc" : "Administrar cuenta",
  "dashboard_setting_upass" : "Actualiza contraseña",
  "dashboard_setting_yourname" : "Su nombre es",
  "dashboard_setting_fname" : "Nombre de pila",
  "dashboard_setting_lname" : "Apellido",
  "dashboard_setting_save" : "Guardar cambios",
  "dashboard_setting_bornon" : "Naciste en",
  "dashboard_setting_youare" : "Usted es un",
  "dashboard_setting_postal" : "Tu código postal es",
  "dashboard_setting_emailus" : "Para cualquier ayuda o consulta, por favor envíe un correo electrónico a",
  "dashboard_setting_membersince" : "Usted ha sido miembro desde entonces",
  "dashboard_setting_taken" : "Usted ha tomado",
  "dashboard_setting_completed" : "encuestas y completado",
  "dashboard_setting_ofthem" : "de ellos.",
  "dashboard_setting_earned" : "Te lo ganaste",
  "dashboard_setting_pts" : "puntos",
  "dashboard_setting_started" : "desde que comenzaste",
  "dashboard_register_email" : "Usted está registrado con nosotros de",
  "dashboard_setting_preferences" : "Administra tus preferencias",
  "dashboard_setting_emailoptout" : "Correo electrónico opt out",
  "dashboard_setting_futureemails" : "Opte por no recibir futuros correos electrónicos de WisePerks.",
  "dashboard_setting_futureemailsflash" : "Unsere e-Mails halten Sie auf dem Laufenden über alle lukrativen Umfragen, die für Sie verfügbar sind. Sind Sie sicher, dass Sie Sie nicht erhalten wollen.",
  "dashboard_setting_nrecemail" : "Nuestros correos electrónicos le mantienen publicado en todas las encuestas lucrativas disponibles para usted. ¿Seguro que no quieres detenerlos?",
   "dashboard_setting_emailout_modalmsg" : "¿Está seguro de que desea optar por no recibir correos electrónicos futuros de WisePerks",
  "dashboard_setting_emailin_modalmsg" : "¿está seguro de empezar a recibir correos electrónicos futuros de WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Su éxito ha cesado de recibir correos electrónicos de WisePerks",
  "dashboard_setting_emailin_succmsg" : "Su éxito ha empezado a recibir correos electrónicos de WisePerks",
  "dashboard_setting_deactivateacc" : "desactivar cuenta",
  "dashboard_setting_disableprofile" : "La desactivación de su cuenta deshabilitará su perfil de WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "¿Está seguro de que desea desactivar su cuenta en WisePerks",
  "dashboard_setting_deactivateacc_flash" : "Wenn Sie Beschwerden oder Feedback haben, dann teilen Sie uns bitte mit. Wir würden uns vorrangig damit befassen. Geben Sie uns eine Chance. Sind Sie sicher, dass Sie Ihr Konto bei uns löschen möchten.",
  "dashboard_setting_changelang" : "Cambiar idioma",
  "dashboard_setting_preferredlang" : "Seleccione su idioma preferido",
  "dashboard_setting_updatepass" : "Actualiza contraseña",
  "dashboard_setting_plsupdate" : "Bitte aktualisieren Sie Ihr Passwort unten.",
  "dashboard_setting_oldpass" : "Contraseña anterior",
  "dashboard_setting_enteroldpass" : "Ingrese la contraseña anterior",
  "dashboard_setting_newpass" : "Nueva contraseña",
  "dashboard_setting_enternewpass" : "Introduzca nueva contraseña",
  "dashboard_setting_confpass" : "Confirmar contraseña",
  "dashboard_setting_enternewpassagain" : "Ingrese Nueva Contraseña de Nuevo",
  "dashboard_setting_update" : "Actualizar",

  "dashboard_doi_welcome" : "Bienvenido",
  "dashboard_doi_welbck" : "Dar una buena acogida",
  "dashboard_doi_accntverified" : "Parece que te perdiste de verificar tu cuenta de correo electrónico.",
  "dashboard_doi_getaccess" : "¡Prisa! se obtiene",
  "dashboard_doi_getaccess2" : "puntos una vez que verifiques.",
  "dashboard_doi_activateacc" : "Por favor, compruebe su buzón de entrada y haga clic en el enlace dado en el correo electrónico de verificación de cuenta enviado a su cuenta de correo electrónico registrado.",
  "dashboard_doi_chckspam" : "Por favor, revise su carpeta de spam/basura en caso de que no pueda encontrar el correo electrónico de verificación.",
  "dashboard_doi_didntgetconf" : "¿no ha llegado el correo electrónico de verificación?",
  "dashboard_doi_resendconf" : "Reenviar correo electrónico de verificación",
  "dashboard_doi_resendconfsucc" : "El correo electrónico de verificación ha sido enviado a su correo electrónico registrado.", 

  "dashboard_survey_availsurveys" : "Encuestas disponibles",
  "dashboard_survey_wisechecksurveys1" : "Usted no puede ver encuestas debido a algunas razones de seguridad. Póngase en contacto con el apoyo en",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "No hay encuestas disponibles para la razón de anulación",
  "dashboard_featured" : "Recomendados",
  "dashboard_profile_survey" : "Encuesta de perfil",
  "dashboard_survey_start" : "Empezar",
  "dashboard_survey_on" : "En",
  "dashboard_survey_off" : "Apagado",
  "dashboard_survey_point" : "Punto",
  "dashboard_survey_points" : "Puntos",
  "dashboard_survey_minute" : "Minuto",
  "dashboard_survey_minutes" : "Minutos",

  "demography_hi" : "Hola",
  "demography_welcome" : "Bienvenido a WisePerks. Te acabas de ganar",
  "demography_points" : "puntos registrándose con nosotros.",
  "demography_share" : "Por favor, comparta algunos detalles más en el siguiente formulario, para ayudarnos a llevarle encuestas relevantes.",

  "demography_gender" : "soy un",
  "demography_male" : "Masculino",
  "demography_female" : "Hembra",
  "demography_birthday" : "Cumpleaños",
  "demography_month" : "Mes", 
  "demography_day" : "Día", 
  "demography_year" : "Yera",
  "demography_jan" : "enero",
  "demography_feb" : "febrero",
  "demography_mar" : "marzo",
  "demography_apr" : "abril",
  "demography_may" : "Mayo",
  "demography_jun" : "junio",
  "demography_jul" : "julio",
  "demography_aug" : "agosto",
  "demography_sep" : "septiembre",
  "demography_oct" : "octubre",
  "demography_nov" : "noviembre",
  "demography_dec" : "diciembre",
  "demography_hearabout" : "¿Dónde se enteró de WisePerks?",
  "demography_opt1" : "Seleccione lo más cercano que aplique",
  "demography_opt2" : "Boletín electrónico",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Foro o Blog",
  "demography_opt5" : "Amigo",
  "demography_opt6" : "Linkedin", 
  "demography_opt7" : "Motor de búsqueda",
  "demography_opt8" : "Comercial TV",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Sitio web",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Otros",
  "demography_zipcode": "Código postal",
  "demography_submit" : "Enviar",

  "privacypolicy_privacypolicy" : "Política de privacidad",
  "privacypolicy_privacyisimp" : "Su privacidad es importante para nosotros.",
  "privacypolicy_lastupdated" : "ultima actualizacion en",

  "privacypolicy_para1" : 'WiseWorks (junto con sus afiliadas, la "Compañía") posee y opera una serie de diferentes sitios web, aplicaciones móviles y servicios interactivos, que incluyen, entre otros, Wisesample.com, Flapbucks.com, WisePerks.com y otros (colectivamente, el " Sitios de WiseWorks ").Esta política de privacidad ("Política de privacidad") se aplica a los Sitios WiseWorks y a todas las características, aplicaciones móviles, correos electrónicos, servicios en línea y otras funcionalidades, ya sea que se acceda a través de una computadora, dispositivo móvil u otros servicios disponibles en o a través los Sitios y Características de WiseWorks (los "Servicios").Esta Política de Privacidad solo cubre la información recopilada por la Compañía a través de los Sitios y Características de WiseWorks y nuestros Servicios, y no cubre ninguna información recibida o recopilada fuera de línea por la Compañía (a menos que se establezca específicamente) o recopilada por sitios web o compañías no relacionadas a los cuales podamos proporcionar enlaces',

  "privacypolicy_para2" : "Se espera que el panelista / encuestado complete su perfil de manera honesta y veraz y no envíe información incorrecta o incorrecta simplemente para calificar para más encuestas.También se espera que el panelista / encuestado no manipule sus respuestas ni use otros medios injustos (cambio de IP, cuentas múltiples, etc.) durante el llenado de la encuesta para obtener incentivos.WisePerks tiene salvaguardas suficientes para detectar tales anomalías y, si se advierte tal comportamiento, el panelista será excluido / bloqueado de WisePerks y todas / cualquiera de sus solicitudes de canje serán canceladas.",

  "privacypolicy_para3" : "WiseWorks está firmemente comprometido a proteger su privacidad con nosotros.La siguiente política de privacidad explica cómo se conserva y trata su información personal mientras explora nuestro sitio web y nuestros servicios.",

  "privacypolicy_topic1" : "¿Qué información de identificación personal recopila WisePerks.com de mí?",
  "privacypolicy_topic1ans" : "WisePerks.com recopila información de varias maneras de diferentes partes de nuestro sitio web y nuestra red.Parte de la información se recopila cuando se registra.Durante el registro de los miembros, WisePerks.com le solicita su nombre, dirección de correo electrónico, código postal e información del hogar.Cuanta más información ofrezca de forma voluntaria, mejor podremos ofrecerle servicios y recompensas.Además, si alguna vez se comunica con WisePerks.com por cualquier medio, podemos mantener un registro de esa correspondencia.Siempre que WisePerks.com recopile dicha información, nuestro objetivo es incluir un enlace a esta Política de privacidad en esa página.",

  "privacypolicy_topic2" : "¿Qué son las cookies y cómo las usa WisePerks.com?",
  "privacypolicy_topic2ans" : "Una cookie es un paquete de datos electrónicos enviados a su navegador, desde un servidor web y almacenados en el disco duro de su computadora.Como parte de ofrecerle encuestas específicas, información y servicios personalizados, WisePerks.com puede usar cookies para almacenar y, a veces, rastrear información sobre usted.WisePerks.com puede requerir que acepte cookies para preparar dicho contenido específico para usted.Esto también evita tener que ingresar su información de inicio de sesión cada vez.",

  "privacypolicy_topic3" : "¿Cuál es la política de WisePerks.com al permitirme actualizar, editar, cambiar o eliminar la información de mi cuenta personal?",
  "privacypolicy_topic3ans" : "Puede editar su información de cuenta de WisePerks.com en cualquier momento utilizando su WisePerks.com Inicie sesión y contraseña.Por favor, póngase en contacto con",
  "privacypolicy_topic3ans2" : "para obtener más instrucciones sobre la desactivación de su cuenta WisePerks.com.",

  "privacypolicy_topic4" : "¿Quién está recopilando información y con qué propósito?",
  "privacypolicy_topic4ansp1" : "WisePerks.com recopila información mediante encuestas, registros y otros medios, lo que ayuda a sus clientes de investigación a desarrollar nuevos productos y servicios para los consumidores.Está compartiendo esa información con WisePerks.com únicamente, a menos que se especifique lo contrario en cualquier encuesta que participe.Si los datos son recopilados y / o mantenidos por cualquier empresa que no sea WiseWorks o sitios de WiseWorks, se le notificará antes del momento de la recopilación o transferencia de datos.Si no desea que se compartan sus datos, puede optar por no permitir la transferencia al no usar esa encuesta o producto en particular.",
  "privacypolicy_topic4ansp2" : "Las recompensas y promociones que ofrecemos en WisePerks.com pueden ser patrocinadas por otras compañías de manera independiente o pueden ser copatrocinadas por WisePerks.com y otra compañía.Algunos datos recopilados durante dicha promoción se pueden compartir con el patrocinador.Sin embargo, se le notificará antes del momento de la recopilación o transferencia de datos.Puede decidir no participar en esa promoción en particular si no desea compartir sus datos.",
  "privacypolicy_topic4ansp3" : "Cualquier información sensible específica (como información que especifique condiciones médicas o de salud, origen racial o étnico, opiniones políticas, creencias religiosas o espirituales, intercambios y afiliación sindical o información que especifique la vida sexual de la persona) será tratada con cuidado adicional.Si WisePerks.com tiene la intención de compartir dicha información con otra finalidad distinta a la que originalmente se recopiló, se le solicitará expresamente permiso antes de dicha divulgación.",

  "privacypolicy_topic5" : "¿Con quién comparte WisePerks.com mi información?",
  "privacypolicy_topic5ansp1" : "Nos tomamos la privacidad muy en serio.WisePerks.com no divulgará ninguna de su información de identificación personal, excepto cuando tengamos su permiso explícito o bajo circunstancias especiales, que la ley así lo requiera o en las circunstancias especificadas en detalle a continuación.A continuación, se describen algunas de las formas en que su información puede ser divulgada.Consulte los Términos del servicio de nuestros servicios para obtener información más detallada sobre cómo se puede compartir su información personal.",
  "privacypolicy_topic5ansp2" : "Socios comerciales, clientes y patrocinadores:",
  "privacypolicy_topic5ansp3" : "WisePerks.com puede divulgar su información personal a nuestros socios, clientes o patrocinadores.Sin embargo, si algún día se compartieran datos personales sobre usted, le informaremos antes de recopilar o transferir los datos.Muchas actividades que ofrecemos solicitan información adicional de los patrocinadores.Al solicitar más información, le otorga a WisePerks.com permiso para compartir su información con el patrocinador para que pueda cumplir con su solicitud.En muchos casos, solo se compartirá su dirección de correo electrónico.Si se comparte más información con nuestros socios, se le notificará con anticipación.De vez en cuando, se le puede ofrecer la oportunidad de recibir materiales de prueba u otros productos u ofertas especiales de nuestros socios.Si opta por recibir información de estos terceros, WisePerks.com (con su permiso) compartirá su nombre y dirección de correo electrónico con ellos.",
  "privacypolicy_topic5ansp4" : "WisePerks.com también puede divulgar información personal en algunos casos especiales cuando la divulgación de esta información es necesaria para identificar, contactar o emprender acciones legales contra alguien que pueda estar violando las políticas y los Términos de Servicio de WisePerks.com o que pueda estar causando una lesión o interferencia con (intencionalmente o no) los productos, servicios, derechos o propiedad de WisePerks.com, otros miembros y usuarios de WisePerks.com, o cualquier otra persona que pueda verse afectada por tales actividades.WisePerks.com puede divulgar o acceder a la información de la cuenta cuando creemos de buena fe que la ley lo exige y para fines administrativos y de otro tipo que consideramos necesarios para mantener, prestar servicios y mejorar nuestros productos y servicios.",

  "privacypolicy_topic6" : "¿Cuáles son las bases legales que utilizamos para procesar su información?",
  "privacypolicy_topic6ans" : 'WiseWorks está comprometido con la privacidad y la protección de datos.Nuestra política de privacidad adopta los principios del Reglamento General de Protección de Datos emitido por la Unión Europea ("GDPR") como un punto de referencia al cual nosotros, nuestros empleados y proveedores adherimos.',

  "privacypolicy_topic7" : "¿Cuáles son mis opciones con respecto a la recopilación, el uso y la distribución de mi información?",
  "privacypolicy_topic7ans" : "Solo WisePerks.com (o socios que trabajen con WisePerks.com bajo acuerdos de confidencialidad) le enviará correos directos, solo si usted indicó que no tiene objeciones.También tiene opciones con respecto a cómo funcionan las cookies.Al cambiar la configuración de su navegador de Internet, tiene la opción de aceptar todas las cookies, notificar cuando se establece una cookie o rechazar todas las solicitudes de cookies.Si elige rechazar todas las cookies, no podrá usar los servicios de WisePerks.com que sí requieren registro previo para poder participar.WisePerks.com no vende ni arrienda información de usuario a nadie.Siempre le notificaremos en el momento de la recopilación o transferencia de datos si sus datos serán compartidos con un tercero y siempre tendrá la opción de no permitir que se comparta.",

  "privacypolicy_topic8" : "¿Qué precauciones de seguridad existen en WisePerks.com para proteger cualquier pérdida, mal uso o alteración de la información de mi cuenta?",
  "privacypolicy_topic8ansp1" : "Su información de cuenta de WisePerks.com está protegida con contraseña para que usted y solo usted tenga acceso a esta información.",
  "privacypolicy_topic8ansp2" : "Le recomendamos encarecidamente que no comparta su contraseña con nadie.Nunca le pediremos su contraseña en una llamada telefónica o correo electrónico no solicitado.Por favor, cierre sesión en su cuenta de WisePerks.com y cierre la ventana de su navegador cuando haya terminado su actividad.Esto garantiza que otros no puedan acceder a su información y cuenta personal, si está usando una computadora pública o comparte su computadora con otra persona.Siempre que WisePerks.com maneje información personal como se describió anteriormente, independientemente de dónde ocurra esto, WisePerks.com toma medidas para garantizar que su información sea tratada de manera segura y de acuerdo con los Términos de Servicio relevantes y esta Política de Privacidad.Desafortunadamente, no se puede garantizar que la transmisión de datos a través de Internet sea 100% segura.Como resultado, aunque nos esforzamos por proteger su información personal, WisePerks.com no puede garantizar ni garantizar la seguridad de la información que nos transmita o de nuestros productos o servicios en línea, y lo hace bajo su propio riesgo.Una vez que recibamos sus datos, haremos nuestro mejor esfuerzo para garantizar su seguridad en nuestros sistemas.",

  "privacypolicy_topic9" : "¿Qué más debería saber sobre mi privacidad?",
  "privacypolicy_topic9ans" : "Tenga en cuenta que cuando quiera (y donde sea) que elija divulgar información personal en línea, por ejemplo a través de correos electrónicos, sitios web de redes sociales, blogs o en áreas de chat, esa información puede ser recopilada y utilizada por cualquier otro usuario.Si publica información personal en línea que es accesible al público, puede recibir a cambio mensajes no solicitados de otras partes.En última instancia, usted es el único responsable de mantener el secreto de sus contraseñas y / o cualquier información de cuenta.Tenga cuidado y sea responsable cada vez que esté en línea.",

  "privacypolicy_topic10" : "¿Cómo protege WisePerks.com la privacidad de mis hijos?",
  "privacypolicy_topic10ans" : "WisePerks.com obtiene el consentimiento previo de los padres cuando las encuestas requieren que los niños participen y también se requiere la supervisión de los padres para cualquier encuesta de ese tipo con niños de 6 a 15 años.Tomamos especial cuidado para proteger la seguridad y privacidad de los usuarios jóvenes en nuestro sitio, y lo alentamos a que participe en la experiencia de su hijo en línea.No permitimos ningún tipo de comercialización o investigación infantil y ciertos temas están completamente prohibidos en la investigación entre niños: Nombre / nombre de la cuenta / Correo electrónico o dirección física, Número de teléfono, Raza u origen étnico, Religión o Elenco, Ingreso familiar, Enfermedad familiar , Estado civil de los padres u otros miembros de la familia, Asuntos de relación, Grados en la escuela o la universidad, Alcohol, Problemas sexuales, Tabaco y Armas de fuego.",

  "privacypolicy_topic11" : "Nota especial para usuarios internacionales",
  "privacypolicy_topic11ansp1" : "El sitio está alojado en los Estados Unidos.Si usted es un Usuario que accede al Sitio desde la Unión Europea, Asia o cualquier otra región con leyes o reglamentos que rigen la recopilación, uso y divulgación de datos personales, que difieren de las leyes de los Estados Unidos, tenga en cuenta que está transfiriendo sus datos personales al Estados Unidos que no tiene las mismas leyes de protección de datos que la UE y otras regiones, y al proporcionar sus datos personales, usted acepta:",
  "privacypolicy_topic11ansp2" : "El uso de sus datos personales para los usos identificados anteriormente de acuerdo con la Política de privacidad; y la transferencia de sus datos personales a los Estados Unidos como se indicó anteriormente.USTED RECONOCE QUE LAS LEYES DE LOS ESTADOS UNIDOS TRATAN SU INFORMACIÓN DE UNA MANERA QUE PUEDE SER SUSTANCIALMENTE DIFERENTE Y MENOS PROTECTIVA QUE EL TRATAMIENTO REQUERIDO BAJO LAS LEYES DE OTROS PAÍSES Y JURISDICCIONES.SI NO DESEA QUE SU INFORMACIÓN SEA TRANSMITIDA A LOS ESTADOS UNIDOS, NO DEBERÁ COMPARTIR SU INFORMACIÓN CON NOSOTROS O HACER USO DEL SITIO.HASTA EL PUNTO PERMITIDO POR LA LEY DEL PAÍS EN EL QUE SE ENCUENTRE UBICADO, EXPRESAMENTE RENUNCIA A CUALQUIER DERECHO QUE PUEDA TENER PARA REQUERIR QUE TRATAMOS SU INFORMACIÓN DE IDENTIFICACIÓN DE ACUERDO CON LAS LEYES DE CUALQUIER PAÍS O JURISDICCIÓN DISTINTOS DE LOS ESTADOS UNIDOS.SIN EMBARGO, LA EXENCIÓN ANTERIOR PUEDE NO SER LEGALMENTE VINCULANTE EN ALGUNOS PAÍSES, COMO LOS ESTADOS MIEMBROS DE LA UNIÓN EUROPEA.EN LA MEDIDA EN QUE NO SEA LEGALMENTE VINCULANTE EN EL PAÍS EN EL QUE SE ENCUENTRA UBICADO, ESTA EXENCIÓN ANTERIOR NO SE APLICA A USTED.",

  "privacypolicy_topic12" : "Cambios y actualizaciones a esta política de privacidad",
  "privacypolicy_topic12ans" : "WiseWorks se reserva el derecho, a nuestra discreción, de cambiar, modificar, agregar o eliminar partes de esta Política de privacidad en cualquier momento y por cualquier motivo.Cada vez que WiseWorks ejercite tal derecho, se le solicitará que acepte afirmativamente la Política de privacidad actualizada, y si no lo hace, ya no podrá acceder o usar el Sitio.",


 "privacypolicy_topic13": "Nota especial para quienes buscan detalles de GDPR / CCPA",
 "privacypolicy_topic13ansp1": "Por favor ",
 "privacypolicy_topic13ansp2": "haga clic aquí ",
  "privacypolicy_topic13ansp3": "para obtener más información sobre los detalles de cumplimiento de GDPR (Reglamento general de protección de datos) y CCPA (Ley de privacidad del consumidor de California).",

"privacypolicy_addinfo": "Información Adicional",
"privacypolicy_sentto": "Cualquier otra pregunta con respecto a esto debe enviarse a",

    "privacypolicyext_para1": "POR FAVOR LEA ESTA DECLARACIÓN DE PRIVACIDAD DETENIDAMENTE",
    "privacypolicyext_para2": "WiseWorks respeta la privacidad de todos los usuarios y estamos comprometidos a ser transparentes con usted sobre cómo manejamos los datos personales. Esta Política de privacidad explica cómo WiseWorks recopila, comparte y usa los datos personales que usted nos proporciona cuando usa el sitio web de WiseWorks o interactúa con nosotros. También le ofrecemos algunas secciones para que aprenda cómo puede ejercer sus derechos y opciones de privacidad.",
    "privacypolicyext_para3": "Please read this Privacy Policy carefully before accessing our Website and using the WiseWorks Services. If you have any questions, please contact us at Lea esta Política de privacidad detenidamente antes de acceder a nuestro sitio web y utilizar los servicios de WiseWorks. Si tiene alguna pregunta, comuníquese con nosotros al",

    "privacypolicyext_topic1": "Visión general",
    "privacypolicyext_topic2": "Aceptación y Uso",
    "privacypolicyext_topic3": "Quienes somos",
    "privacypolicyext_topic4": "Resumen de información personal y uso",
    "privacypolicyext_topic5": "Finalidades de la recopilación de datos",
    "privacypolicyext_topic6": "Principios de protección de datos",
    "privacypolicyext_topic7": "Tipos de datos que recopilamos de usted",
    "privacypolicyext_topic8": "Cookies y balizas web",
    "privacypolicyext_topic9": "Notificarle sobre encuestas y proyectos de investigación",
    "privacypolicyext_topic10": "Cómo usamos sus datos",
    "privacypolicyext_topic11": "Con quién compartimos sus datos",
    "privacypolicyext_topic12": "EEA Sujetos de datos y fuera del EEA ",
    "privacypolicyext_topic13": "Sus derechos de protección de datos",
    "privacypolicyext_topic14": "Nuestra política hacia los niños",
    "privacypolicyext_topic15": "Seguridad de datos",
    "privacypolicyext_topic16": "Términos y condiciones",
    "privacypolicyext_topic17": "Jurisdicción",
    "privacypolicyext_topic18": "Cambios a nuestra política de privacidad",
    "privacypolicyext_topic19": "Contacta con nosotros",

    "privacypolicyext_t1": "Nosotras",
    "privacypolicyext_t2": "Nosotros",
    "privacypolicyext_t3": "o",
    "privacypolicyext_t4": "Nuestro",

    "privacypolicyext_topic1ansp1": " respeta los derechos de privacidad de sus usuarios y comprende la importancia de proteger y manejar la información recopilada sobre usted según la ley y las mejores prácticas. Este Aviso de privacidad está escrito para cumplir con",
    "privacypolicyext_topic1ansp1_1": "Artículo 13 y artículo 14 del Reglamento general de protección de datos de la UE, es decir, el Reglamento (UE) 2016/679 ('GDPR') y la Ley de privacidad del consumidor de California CCPA.",
    "privacypolicyext_topic1ansp2": "Este Aviso de privacidad se aplica a la información personal que procesamos relacionada con personas que tienen:",
    "privacypolicyext_topic1ansp2_l1": "Regístrese para una cuenta con nosotros;",
    "privacypolicyext_topic1ansp2_l2": "acordó recibir información de marketing de nuestra parte;",
    "privacypolicyext_topic1ansp2_l3": "participar en encuestas;",
    "privacypolicyext_topic1ansp2_l4": "contáctenos para consultas y soporte",
    "privacypolicyext_topic1ansp3": 'Esta política de privacidad también describe qué derechos tiene con respecto a sus datos, cómo da su consentimiento y cómo puede retirar su consentimiento. WiseWorks (junto con sus afiliadas, la "Compañía") posee y opera varios sitios web, aplicaciones móviles y servicios interactivos diferentes, incluidos, entre otros, Wisesample.com, Flapbucks.com, WisePerks.com y otros (en conjunto, los "Sitios de WiseWorks "). Esta política de privacidad ("Política de privacidad") se aplica a los Sitios de WiseWorks y a todas las funciones, aplicaciones móviles, correos electrónicos, servicios en línea y otras funcionalidades, ya sea que se acceda a través de una computadora, dispositivo móvil o de otro modo, y los servicios disponibles en o a través de los Sitios y Funciones de WiseWorks (los "Servicios"). Esta Política de privacidad solo cubre la información recopilada por la Compañía a través de los Sitios y características de WiseWorks y nuestros Servicios, y no cubre ninguna información recibida o recopilada fuera de línea por la Compañía (a menos que se indique específicamente) o recopilada por sitios web o compañías no relacionadas con las que podamos proporcionar enlaces.',

    "privacypolicyext_topic2ansp1_1": "Esta política de privacidad cubre",
    "privacypolicyext_topic2ansp1_2": " y sus subsidiarias, incluyendo",
    "privacypolicyext_topic2ansp1_3": "colectivamente",
    "privacypolicyext_topic2ansp1_4": "Al utilizar nuestro sitio web, usted manifiesta la aceptación de los términos de esta Política de privacidad, que se considera su consentimiento oficial para procesar su información personal, su consentimiento para la recopilación, uso y divulgación de su información personal como se describe más adelante. para proporcionar divulgaciones adicionales o información sobre las prácticas de procesamiento de datos de Servicios específicos. Estos avisos pueden complementar o aclarar nuestras prácticas de privacidad o brindarle otras opciones sobre cómo procesamos sus datos.",
    "privacypolicyext_topic2ansp2": "Si no se siente cómodo con algún aspecto de esta Política de privacidad, debe interrumpir inmediatamente cualquier acceso al sitio web de WiseWorks y a nuestro sitio web.",

    "privacypolicyext_topic3ansp1_1": " es una empresa constituida y registrada en Canadá (# 10369675) .Nuestro domicilio social se encuentra en",
    "privacypolicyext_topic3ansp1_2": "Brindamos servicios de panel, para industrias de investigación de mercado en línea. Permitimos que los usuarios o participantes que cumplan con nuestros requisitos de elegibilidad se registren para obtener una cuenta de panel de usuario y realicen encuestas para recibir puntos u otros incentivos.",
    "privacypolicyext_topic3ansp2": "Nuestros servicios están disponibles en varios países, así como en California (EE. UU.), Canadá, Alemania, India, etc. Los Servicios no están destinados a usuarios menores de 18 años; debe tener 18 años o más para utilizar nuestro sitio web y nuestros servicios.",

    "privacypolicyext_topic4ansp1": "Podemos recopilar u obtener datos personales sobre usted, y esta información puede almacenarse o procesarse dentro de la región de los Estados Unidos a través de los siguientes métodos:",
    "privacypolicyext_topic4ansp1_l1": "Directamente de usted (por ejemplo, donde se comunica con nosotros); en el curso de nuestra relación con usted (por ejemplo, si utiliza nuestros servicios);",
    "privacypolicyext_topic4ansp1_l2": "cuando visita nuestro sitio web; o utilizar cualquier parte del sitio web;",
    "privacypolicyext_topic4ansp2": "También podemos recibir información personal sobre usted de terceros.",

    "privacypolicyext_topic5ansp1": "Los siguientes son ejemplos de cómo usamos su información personal:",
    "privacypolicyext_topic5ansp1_l1": "Provisión de servicios",
    "privacypolicyext_topic5ansp1_l2": "Ofreciendo y mejorando el sitio web",
    "privacypolicyext_topic5ansp1_l3": "Encuestas",
    "privacypolicyext_topic5ansp1_l4": "Comunicación",
    "privacypolicyext_topic5ansp1_l5": "Compromiso del usuario",
    "privacypolicyext_topic5ansp1_l6": "Márketing",
    "privacypolicyext_topic5ansp1_l7": "Generación líder",
    "privacypolicyext_topic5ansp1_l8": "Cómplice legal",

    "privacypolicyext_topic6ansp1": "Recopilamos, procesamos y utilizamos datos personales (según las definiciones del artículo 13 y el artículo 14 del Reglamento general de protección de datos de la UE, es decir, el Reglamento (UE) 2016/679 ('GDPR') y la Ley de privacidad del consumidor de California CCPA) para Brindar nuestros servicios. Los datos personales son cualquier información que nos permita identificarlo, directa o indirectamente, como nombre, correo electrónico, dirección, información médica, cualquier forma de número de identificación o uno o más factores específicos para usted o la identidad de su beneficiario.",
    "privacypolicyext_topic6ansp2": "Nos comprometemos a cumplir con las leyes de protección de datos aplicables y nos aseguraremos de que los datos personales sean:",
    "privacypolicyext_topic6ansp2_l1": "Usado legal, justa y transparente;",
    "privacypolicyext_topic6ansp2_l2": "Recopilados solo para fines válidos que le hemos explicado claramente y no utilizados de ninguna manera que sea incompatible con esos fines",
    "privacypolicyext_topic6ansp2_l3": "Relevante para los fines que le hemos informado y limitado solo a esos fines;",
    "privacypolicyext_topic6ansp2_l4": "Preciso y actualizado;",
    "privacypolicyext_topic6ansp2_l5": "Conservada únicamente el tiempo que sea necesario para los fines que le hemos informado;",

    "privacypolicyext_topic7ansp1": "Podemos recopilar y procesar la siguiente información personal sobre usted:",
    "privacypolicyext_topic7ansp1_l1title": "Información de perfil personal.",
    "privacypolicyext_topic7ansp1_l1": "Si registra una cuenta con nosotros, recopilaremos su nombre y apellido, su dirección de correo electrónico y la contraseña elegida.",
    "privacypolicyext_topic7ansp1_l2title": "Información del contacto.",
    "privacypolicyext_topic7ansp1_l2": "Si opta por recibir encuestas a través de mensajes de texto u otros medios de mensajería, también recopilamos su número de teléfono móvil o datos de contacto relevantes.",
    "privacypolicyext_topic7ansp1_l3title": "Datos del cuestionario.",
    "privacypolicyext_topic7ansp1_l3": "El cuestionario de perfil inicial recopila información sobre sus antecedentes, educación, estado laboral, hábitos de consumo y, opcionalmente, su salud. Recopilamos información sobre cualquier actualización que realice a su información de perfil.",
    "privacypolicyext_topic7ansp1_l4title": "Datos de opinión. ",
    "privacypolicyext_topic7ansp1_l4": "Durante las oportunidades de investigación, recopilamos la información que proporciona en relación con sus opiniones, actitudes, experiencias y uso de ciertos productos o servicios. Ocasionalmente, podemos recopilar presentaciones de video, audio o fotografías que realice durante una oportunidad de investigación.",
    "privacypolicyext_topic7ansp1_l5title": "Datos de experiencia del usuario.",
    "privacypolicyext_topic7ansp1_l5": "Recopilamos información sobre sus inicios de sesión, comentarios en publicaciones de blogs, calificaciones, encuestas, páginas web visitadas.",
    "privacypolicyext_topic7ansp1_l6title": "Información sobre recompensas.",
    "privacypolicyext_topic7ansp1_l6": "Cualquier información personal que podamos requerir para otorgarle un premio o recompensa o monitorear su uso de nuestras actividades de Panel y Encuesta.",
    "privacypolicyext_topic7ansp1_l7title": "Datos de correspondencia.",
    "privacypolicyext_topic7ansp1_l7": "Cualquier correspondencia que nos envíe.",
    "privacypolicyext_topic7ansp1_l8title": "Datos de uso.",
    "privacypolicyext_topic7ansp1_l8": "También recopilamos datos de uso sobre usted cada vez que interactúa con nuestros servicios. Esto puede incluir qué páginas web visita, en qué hace clic cuando realiza esas acciones, etc. Además, guardamos archivos de registro digitales que registran datos cada vez que un dispositivo accede a nuestros servidores.Los archivos de registro contienen datos sobre la naturaleza de cada acceso.Recopilamos datos del dispositivo y la aplicación que utiliza para acceder a nuestros servicios, como su dirección IP, tipo de navegador, proveedor de servicios de Internet (ISP), referencia / salida páginas, sistema operativo, sello de fecha / hora y datos de flujo de clics.",
    "privacypolicyext_topic7ansp2": "También podemos inferir su ubicación geográfica en función de su dirección IP. Si llega a un servicio de WiseWorks desde una fuente externa (como un enlace en otro sitio web, contenido en una aplicación de correo electrónico o mensajería), podemos utilizar esta información para analizar tendencias, administrar el sitio, rastrear usuarios y recopilar información demográfica sobre nuestra base de usuarios en su conjunto.",

    "privacypolicyext_topic8ansp1": "Una cookie es un archivo de texto que contiene una pequeña cantidad de información que se envía a su navegador cuando visita un sitio web. Luego, la cookie se envía de vuelta al sitio web de origen en cada visita posterior, oa otro sitio web que lo reconozca. Las cookies son una tecnología extremadamente útil y realizan muchos trabajos diferentes.Una baliza web es una imagen gráfica a menudo transparente, generalmente de no más de 1 píxel x 1 píxel, que se coloca en un sitio web o en un correo electrónico que se utiliza para monitorear el comportamiento del usuario que visita el sitio web o envía el correo electrónico. A menudo se usa en combinación con cookies.",
    "privacypolicyext_topic8ansp2": "Podemos recopilar información mediante el uso de cookies, balizas web o tecnologías similares basadas en análisis.",
    "privacypolicyext_topic8ansp3_title": "¿Qué cookies utilizamos?",
    "privacypolicyext_topic8ansp3": "Las cookies que utilizamos se dividen en las siguientes categorías:",
    "privacypolicyext_topic8ansp3_l1title": "Cookies estrictamente necesarias.",
    "privacypolicyext_topic8ansp3_l1": "Se trata de cookies necesarias para el funcionamiento de nuestro sitio web. Incluyen, por ejemplo, cookies que le permiten iniciar sesión en áreas seguras de nuestro sitio web.",
    "privacypolicyext_topic8ansp3_l2title": "Cookies analíticas / de rendimiento.",
    "privacypolicyext_topic8ansp3_l2": "Nos permiten reconocer y contar el número de visitantes y ver cómo los visitantes se mueven por nuestro sitio web cuando lo utilizan. Esto nos ayuda a mejorar el funcionamiento de nuestro sitio web, por ejemplo, garantizando que los usuarios encuentren lo que buscan fácilmente.",
    "privacypolicyext_topic8ansp3_l3title": "Cookies de funcionalidad.",
    "privacypolicyext_topic8ansp3_l3": "Estos se utilizan para reconocerlo cuando regresa a nuestro sitio web. Esto nos permite personalizar nuestro contenido para usted, saludarlo por su nombre y recordar sus preferencias.",
    "privacypolicyext_topic8ansp3_l4title": "Orientación a las cookies.",
    "privacypolicyext_topic8ansp3_l4": "Estas cookies registran su visita a nuestro sitio web, las páginas que ha visitado y los enlaces que ha seguido. Usaremos esta información para hacer que nuestro sitio web y la publicidad que se muestra en él sean más relevantes para sus intereses. También podemos compartir esta información con terceros para este fin.",
    "privacypolicyext_topic8ansp4": 'Usamos varios tipos de cookies (incluidas algunas que colocamos y otras que permitimos que terceros coloquen en nuestro sitio) para varios propósitos. La información recopilada por las cookies nos permite monitorear cuántas personas están utilizando nuestros servicios y sus interacciones con otros servicios, cuántas personas abren nuestros correos electrónicos y con qué fines se toman estas acciones. Las cookies también se utilizan para facilitar el inicio de sesión del usuario, como ayudas a la navegación y como cronómetros de sesión, y para restringir el uso de nuestras herramientas y servicios por parte de menores de edad. No vinculamos información no personal de las cookies con información de identificación personal sin permiso. Si por alguna razón no desea que hagamos un seguimiento de su uso de nuestro sitio mediante cookies, puede optar por no hacerlo mediante la configuración de su navegador y también puede seleccionar la opción "No rastrear" (para obtener más información, consulte su navegador ). La mayoría de los navegadores ofrecen instrucciones sobre cómo restablecer el navegador para rechazar cookies en la sección "Ayuda" de la barra de herramientas.',
    "privacypolicyext_topic8ansp5": "Usamos balizas web para recopilar información sobre el uso de nuestros servicios. La información recopilada por balizas web nos permite monitorear cuántas personas están utilizando nuestros servicios, cuántas personas abren nuestros correos electrónicos y determinar con qué fines se están tomando estas acciones. Nuestras balizas web no se utilizan para rastrear actividades fuera de nuestros servicios.",
    "privacypolicyext_topic8ansp6_title": "¿Cómo cambio la configuración de mis cookies?",
    "privacypolicyext_topic8ansp6_1": "La mayoría de los navegadores web permiten cierto control de la mayoría de las cookies a través de la configuración del navegador. Para obtener más información sobre las cookies, incluido cómo ver qué cookies se han configurado, visite www.aboutcookies.org o www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "Para saber cómo administrar las cookies en navegadores populares:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "Cuando se une a WiseWorks, podemos comunicarnos con usted por correo electrónico, teléfono o servicio de mensajería (según su preferencia) con respecto a su membresía. Las ocasiones en las que podemos comunicarnos con usted incluyen las siguientes:",
    "privacypolicyext_topic9ansp1_l1": "Oportunidades de investigación (como encuestas, sondeos, grupos focales, etc.)",
    "privacypolicyext_topic9ansp1_l2": "Resultados de la investigacion",
    "privacypolicyext_topic9ansp1_l3": "Boletines de panelistas",
    "privacypolicyext_topic9ansp2": "Nuestros proyectos pueden enfocarse en una amplia gama de temas. Normalmente lo invitamos a participar en proyectos enviándole un correo electrónico sobre la oportunidad. En ese momento, explicaremos el tema general de la encuesta, su duración probable o la participación requerida y la recompensa que se ofrece. Depende de usted decidir si desea participar o no. La participación nunca es obligatoria. Siempre puede abandonar en cualquier momento, aunque preferimos que complete las tareas una vez que las comience y normalmente no pagamos ningún incentivo por tareas incompletas.",
    "privacypolicyext_topic9ansp3": "Esperamos que, en todo momento, proporcione información veraz, precisa, actual y completa según se le solicite.",


    "privacypolicyext_topic10ansp1": "Solo usaremos sus datos personales cuando la ley nos lo permita. Por lo general, utilizaremos sus datos personales en las siguientes circunstancias, pero para obtener más información sobre los tipos de bases legales en las que confiaremos para procesar sus datos, haga clic aquí:",
    "privacypolicyext_topic10ansp1_l1": "Proporcionarle nuestros productos y servicios",
    "privacypolicyext_topic10ansp1_l2": "encontrar las personas más adecuadas para cada oportunidad de investigación;",
    "privacypolicyext_topic10ansp1_l3": "reducir la cantidad de preguntas que debemos hacer en las encuestas;",
    "privacypolicyext_topic10ansp1_l4": "realizar análisis adicionales de los datos de investigación que ya hemos recopilado;",
    "privacypolicyext_topic10ansp1_l5": "en forma agregada para ayudarnos a explicar nuestras capacidades de investigación a nuestros clientes.",
    "privacypolicyext_topic10ansp1_l6": "invitarlo a buscar oportunidades como encuestas, grupos focales, comunidades en línea, monitoreo pasivo de los sitios web que visita, etc.",
    "privacypolicyext_topic10ansp1_l7": "enviarle recompensas de incentivo que haya ganado,",
    "privacypolicyext_topic10ansp1_l8": "solicitarle que actualice su perfil,",
    "privacypolicyext_topic10ansp1_l9": "Invitarlo a participar en encuestas sobre nuestros productos o servicios.",
    "privacypolicyext_topic10ansp1_l10": "Supervisar, detectar y prevenir fraudes, actividades indebidas y garantizar el cumplimiento de la política de WiseWorks ",
    "privacypolicyext_topic10ansp1_l11": "Llevar a cabo investigaciones, incluidas las secundarias y de seguimiento.",
    "privacypolicyext_topic10ansp1_l12": "Evalúe cómo usted y otros usuarios interactúan con nuestros servicios en línea.",
    "privacypolicyext_topic10ansp1_l13": "Protege a WiseWorks y a nuestros usuarios.",
    "privacypolicyext_topic10ansp1_l14": "Realizar auditorías relacionadas con nuestras interacciones actuales con usted.",
    "privacypolicyext_topic10ansp1_l15": "Depura el sitio y el servicio para identificar y reparar errores que perjudican la funcionalidad prevista existente.",
    "privacypolicyext_topic10ansp1_l16": "Defendernos en litigios e investigaciones y perseguir litigios",
    "privacypolicyext_topic10ansp1_l17": "Para cumplir con las obligaciones legales y reglamentarias.",


    "privacypolicyext_topic11ansp1": "WiseWorks solo comparte su información personal con terceros en las circunstancias que se describen a continuación. No vendemos ni divulgamos la información personal que recopilamos sobre usted por motivos monetarios o de otro tipo.",
    "privacypolicyext_topic11ansp1_l1title": "Vendedores autorizados:",
    "privacypolicyext_topic11ansp1_l1": "Podemos compartir sus datos con proveedores externos que nos ayudan a operar nuestro sitio web, procesar pagos y brindar nuestros servicios y también cumplir con sus instrucciones y nuestras obligaciones contractuales. Estos incluyen procesadores de pago, redes de entrega de contenido (CDN), servicios de alojamiento basados en la nube, servicios de monitoreo, proveedores de servicios de correo electrónico, proveedores de control y prueba de calidad, proveedores de prevención de fraude y abuso, gestión de relaciones con el cliente (CRM), proveedores",
    "privacypolicyext_topic11ansp1_l2title": "Socio de publicidad y análisis:",
    "privacypolicyext_topic11ansp1_l2": "Podemos compartir datos de uso con anunciantes externos y proveedores de análisis a través de cookies y otras tecnologías similares. Estos terceros pueden recopilar información enviada por su computadora, navegador o dispositivo móvil en respuesta a una solicitud de contenido, como identificadores únicos, su dirección IP u otra información sobre su computadora o dispositivo.",
    "privacypolicyext_topic11ansp1_l3title": "Compañeros de negocio:",
    "privacypolicyext_topic11ansp1_l3": "A veces nos asociamos con terceros para ofrecerle eventos, promociones, concursos y otros programas. Para participar en estas ofertas, es posible que deba proporcionar su dirección de correo electrónico e información personal adicional. Solo compartiremos su dirección de correo electrónico y otra información personal con un socio comercial cuando nos autorice expresamente a hacerlo.",
    "privacypolicyext_topic11ansp1_l4title": "Ciertas obligaciones legales: ",
    "privacypolicyext_topic11ansp1_l4": "Es posible que WiseWorks deba revelar información personal si así lo indica un tribunal de justicia u otras entidades gubernamentales. Sin limitar lo anterior, nos reservamos el derecho de divulgar dicha información cuando tengamos una base de buena fe para creer que dicha acción es necesaria para: cumplir con las leyes, reglamentos, órdenes judiciales, solicitudes del gobierno y de las agencias de aplicación de la ley, proteger y defender los derechos y la propiedad de WiseWorks o de un tercero, o la seguridad de WiseWorks, nuestros usuarios, nuestros empleados u otros, prevenir, detectar, investigar y tomar medidas contra la actividad delictiva, el fraude y el uso indebido o no autorizado de nuestro sitio web y hacer cumplir nuestros Términos de uso u otros acuerdos o políticas, en la medida en que lo permita la ley, intentaremos notificarle previamente antes de divulgar su información en respuesta a dicha solicitud.",
    "privacypolicyext_topic11ansp1_l5title": "Con su consentimiento:",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks puede enviar su información personal a un tercero cuando usted da su consentimiento para hacerlo o en su dirección. Por ejemplo, esto puede ocurrir cuando completamos una transacción a su solicitud.",
    "privacypolicyext_topic11ansp1_l6title": "Con proveedores de servicios:",
    "privacypolicyext_topic11ansp1_l6": "Podemos compartir información personal con proveedores, consultores, procesadores de pagos y otros proveedores de servicios que nos brindan servicios específicos e información de proceso única en nuestro nombre. Estos servicios pueden incluir brindar atención al cliente, realizar análisis comerciales y de ventas, respaldar la funcionalidad de nuestro sitio web, facilitar el procesamiento de pagos, respaldar concursos, encuestas y otras funciones que se ofrecen en nuestro sitio web. Estos proveedores de servicios no pueden usar su información personal para ningún otro propósito. Su uso de dichos datos personales estará sujeto a las medidas de seguridad y confidencialidad adecuadas.",
    "privacypolicyext_topic11ansp1_l7title": "Con afiliados:",
    "privacypolicyext_topic11ansp1_l7": "Podemos compartir información personal con algunos de nuestros socios, afiliados y otras empresas bajo control y propiedad comunes, sujeto a los términos de esta Política de privacidad. También podemos compartir su información personal en relación con una transacción corporativa sustancial, como una venta de nuestro negocio, una desinversión, fusión, consolidación o venta de activos, en caso de quiebra o preparación para cualquiera de estos eventos. Cualquier otra entidad que nos compre o se convierta en parte de nuestro negocio tendrá derecho a continuar utilizando su información personal, pero solo de la manera establecida en esta Política de privacidad, a menos que usted acuerde lo contrario.",

    "privacypolicyext_topic12ansp1_title": "Bases legales para procesar su información:",
    "privacypolicyext_topic12ansp1": 'Para las personas que se encuentran en el Espacio Económico Europeo, el Reino Unido o Suiza (colectivamente "Residentes del EEE"), los datos recopilados de usted pueden transferirse o procesarse en los Estados Unidos (colectivamente "Interesados"). Cuando se recopilan sus datos, nos basamos en bases legales para procesar su información. Por lo general, solo procesamos sus datos donde se nos solicita, cuando el procesamiento es necesario para brindarle nuestros servicios (o para tomar las medidas que usted solicite antes de celebrar un contrato con usted).',
    "privacypolicyext_topic12ansp2_title": "Brilla la luz (para ciudadanos de California) CCPA",
    "privacypolicyext_topic12ansp2_1": "La ley \"Shine the Light \" de California permite a los clientes en California solicitar detalles específicos sobre cómo ciertos tipos de su información se comparten con terceros y, en algunos casos, afiliados para fines de marketing directo de dichos terceros y afiliados. Según la ley, una empresa debe proporcionar a los clientes de California cierta información cuando la soliciten o permitir que los clientes de California opten por no participar en este tipo de intercambio. ",
    "privacypolicyext_topic12ansp2_2": 'Para ejercer una solicitud de Shine the Light, comuníquese con nosotros. Debe incluir la declaración "Sus derechos de privacidad en California" en el cuerpo de su solicitud, así como su nombre, dirección, ciudad, estado y código postal. Proporcione lo suficiente información para determinar si esto se aplica a usted en el cuerpo de su solicitud. Tenga en cuenta que no aceptaremos consultas por teléfono, correo electrónico, y no somos responsables de los avisos que no estén etiquetados o enviados correctamente o que no tengan información completa.',
    "privacypolicyext_topic12ansp3_title": "Sus derechos de privacidad en Nevada",
    "privacypolicyext_topic12ansp3": 'Si usted es un residente de Nevada, tiene derecho a optar por no participar en la venta de Datos personales específicos a terceros que tengan la intención de licenciar o vender esos Datos personales. Puede ejercer este derecho contactándonos aquí o enviándonos un correo electrónico con el asunto línea "Solicitud de No Vender en Nevada" y proporcionándonos su nombre y la dirección de correo electrónico asociada con su cuenta.',

    "privacypolicyext_topic13ansp1": "Le permitimos tomar numerosas decisiones sobre sus datos: A continuación, se muestran los siguientes derechos de protección de datos que tiene cuando utiliza nuestros servicios.",
    "privacypolicyext_topic13ansp1_l1": "Puede optar por no proporcionarnos cierta información.",
    "privacypolicyext_topic13ansp1_l2": "Suponga que desea acceder, corregir, actualizar o solicitar la eliminación de su información personal, en ese caso, puede hacerlo en cualquier momento comunicándose con nosotros a través del portal de atención al cliente o en la información que se proporciona al pie de esta Política de Privacidad.",
    "privacypolicyext_topic13ansp1_l3": "Si solicita acceso a sus datos, confirmaremos si conservamos su información y le proporcionaremos una copia, explicando cómo la procesamos y por qué, durante cuánto tiempo la almacenamos y sus derechos asociados.",
    "privacypolicyext_topic13ansp1_l4": "Si solicita la eliminación de su información personal, la borraremos.Tenga en cuenta que necesitaremos retener cualquier información que necesitemos para cumplir con nuestras obligaciones legales o establecer, ejercer o defender un reclamo legal.",
    "privacypolicyext_topic13ansp1_l5": "Puede oponerse al procesamiento de su información personal, solicitarnos que restrinjamos el procesamiento de su información personal o solicitar la portabilidad de su información personal. De nuevo, puede ejercer estos derechos comunicándose con nosotros a través del portal de atención al cliente o en la información proporcionada en el al final de esta Política de privacidad.",
    "privacypolicyext_topic13ansp1_l6": 'Tiene derecho a optar por no recibir las comunicaciones de marketing por correo electrónico que le enviamos en cualquier momento. Puede ejercer este derecho haciendo clic en el enlace "cancelar suscripción" o "optar por no participar" en los correos electrónicos de marketing que le enviamos.',
    "privacypolicyext_topic13ansp1_l7": "Optar por no recibir mensajes nuestros: Para optar por no recibir mensajes promocionales futuros de nosotros, debe cancelar la suscripción en el cuerpo del mensaje promocional que se le envió (que se encuentra en la parte inferior del correo electrónico) o enviarnos una solicitud de cancelación de suscripción. Procesaremos su solicitud dentro de un tiempo razonable después de recibirla.",
    "privacypolicyext_topic13ansp1_l8": "Del mismo modo, si hemos recopilado y procesado su información personal con su consentimiento, puede retirar su consentimiento en cualquier momento. Retirar su consentimiento no afectará la legalidad de ningún procesamiento que llevamos a cabo antes de su retiro, ni afectará el procesamiento de su información personal realizado en base a motivos de procesamiento legales distintos al consentimiento.",
    "privacypolicyext_topic13ansp1_l9": "You have the right to complain to a data protection authority about our collection and use of your personal information.Please contact us for more information, and we will send you a link or our data protection officer's address.",
    "privacypolicyext_topic13ansp1_l10": "La forma más fácil de enviarnos su solicitud es a través de nuestro correo electrónico de atención al cliente que se encuentra al final de esta Política de privacidad. Respondemos a todas las solicitudes que recibimos de personas que desean ejercer sus derechos de protección de datos según las leyes de protección de datos aplicables.",

    "privacypolicyext_topic14ansp1": "El sitio web y los servicios no están dirigidos a niños menores de trece (18) años. WiseWorks cumple con la Ley de Protección de la Privacidad Infantil en Línea (COPPA). No solicitamos a sabiendas recopilar información personal de ninguna persona menor de 18 años. Si sabe de alguna persona menor de 18 años que utilice nuestro sitio web, infórmenos para evitar que acceda a nuestros Servicios.",

    "privacypolicyext_topic15ansp1": "Estamos comprometidos a mantener la seguridad de sus datos, contando con medidas para proteger contra la pérdida, mal uso y alteración de los datos bajo nuestro control. Empleamos técnicas modernas y seguras para proteger nuestros sistemas de la intrusión de personas no autorizadas, y actualizamos nuestra seguridad periódicamente a medida que se dispone de mejores métodos. Nuestros centros de datos y nuestros socios utilizan nuevas medidas de seguridad física para evitar el acceso no autorizado a las instalaciones.",
    "privacypolicyext_topic15ansp2": "Tenga en cuenta que ninguna medida de seguridad es perfecta o impenetrable. Por lo tanto, aunque utilizamos prácticas estándar de la industria para proteger su privacidad, no podemos (y no lo hacemos) garantizar la seguridad absoluta de los datos personales.",


    "privacypolicyext_topic16ansp1": "Para obtener más información sobre nuestros términos y condiciones, visite nuestra página de Términos de servicio, que establece el uso, la exención de responsabilidad y las limitaciones de responsabilidad que rigen el uso de nuestro sitio web.",

    "privacypolicyext_topic17ansp1": "Las leyes de los Estados Unidos de América rigen esta política de privacidad y los términos de servicio y otros servicios relacionados.",

    "privacypolicyext_topic18ansp1": "WiseWorks se esfuerza continuamente por implementar nuevas tecnologías y procesos para protegerlo mejor, su privacidad y su uso del servicio WiseWorks. Como resultado, WiseWorks puede realizar cambios a esta política de privacidad de vez en cuando. En ningún caso estos cambios resultarán en la degradación de las medidas de seguridad diseñadas para protegerlo de la divulgación no autorizada.",

    "privacypolicyext_topic19ansp1": "Si tiene alguna pregunta sobre esta política de privacidad, contáctenos en",





  "faq_faq" : "Preguntas frecuentes",
  "faq_getans" : "Obtenga respuestas a todas sus preguntas.",

  "faq_ques1" : "¿Qué es WisePerks?",
  "faq_ans1" : "Nos complace que haya preguntado.WisePerks es un sitio de encuestas en línea.Muchas marcas y compañías buscan a los encuestados para que den sus opiniones y reciban recompensas a cambio.WisePerks es una de esas plataformas en la que puedes dar tu opinión y ganar recompensas por ella también.",

  "faq_ques2" : "¿Es gratis unirse?",
  "faq_ans2" : "Sí, es gratis unirse.De hecho, si alguien le solicita una tarifa de inscripción, nos gustaría que nos la informe.",

  "faq_ques3" : "¿Puedo recomendar a mis amigos?",
  "faq_ans3" : 'Sí, puedes invitar a tus amigos y colegas a unirse y también ser recompensado por ello. Haga clic en el enlace "Recomendar amigo" en su panel de control para obtener todos los detalles.',

  "faq_ques4" : "¿Qué gano?",
  "faq_ans4" : "Ahora en nuestro nuevo modelo, usted puede ganar puntos de muchas maneras que no sea simplemente tomando las encuestas. Usted obtiene puntos por: registrarse con nosotros, mediante la verificación de su cuenta de correo electrónico, para completar su perfil, por supuesto, para completar cualquier encuesta y para su sorpresa, incluso para una encuesta descalificada. Así que tenemos todas las razones para empezar ¿no?",

  "faq_ques5" : "¿Cómo me convierto en miembro de WisePerks.com?",
  "faq_ans5" : 'Abra WisePerks.com en su navegador.Cree un inicio de sesión de membresía haciendo clic en la pestaña "Registrarse".Simplemente siga los pasos y lo guiará a través del resto del proceso.Deberá confirmar su intención de unirse haciendo clic en el correo electrónico de confirmación enviado por nosotros a su correo electrónico proporcionado.',

  "faq_ques6" : "¿Dónde está ese correo electrónico de confirmación que mencionaste?",
  "faq_ans6p1" : "Un correo electrónico de confirmación se envía automáticamente a la dirección de correo electrónico que proporciona al registrarse. Si no ves ese email en tu buzón de entrada, por favor revisa tu carpeta de spam o basura. Para evitar cualquier correo electrónico futuro de nosotros y la encuesta invita/mensajes de llegar a su carpeta de spam, por favor, añadir",
  "faq_ans6p2" : "a su lista de remitentes aprobados o como un contacto en su buzón de correo. Si todavía no puede encontrar el correo electrónico de confirmación, por favor ingrese a mi cuenta, confirme que su dirección de correo electrónico con nosotros es correcta, y solicite otro correo electrónico de confirmación.",

  "faq_ques7" : "¿Cómo tomo una encuesta?",
  "faq_ans7" : 'Una vez que hayas iniciado sesión después de introducir tu nombre de usuario y contraseña, llegarás a tu Dashboard y podrás ver todas las encuestas que puedas tomar. También enviamos los enlaces de la encuesta a sus identificadores de correo electrónico registrados. Puede tomar el enlace directamente desde su buzón de correo.',

  "faq_ques8" : "¿Cuán confidenciales son mis respuestas, mis datos?",
  "faq_ans8" : "Para WisePerks, mantener la privacidad y confidencialidad del usuario es de máxima prioridad.Nuestros clientes solo están interesados ​​en lo que usted piensa, como consumidor, o cómo se siente acerca de algo.Nosotros en WisePerks, nunca compartimos / pasamos información personal a nuestros clientes, y ninguna de las encuestas WisePerks tampoco vende ni alquila información a terceros.",

  "faq_ques9" : "¿Cuánto duran estas encuestas de todos modos?",
  "faq_ans9" : "La mayoría de las encuestas toman solo entre 10 y 15 minutos, o incluso menos.Sin embargo, de vez en cuando, podemos tener encuestas más largas.Con cada encuesta, le informamos por adelantado cuánto tiempo sería, pero tenga en cuenta que las encuestas más largas también tienen recompensas más altas.",

  "faq_ques10" : "¿Alguien puede tomar encuestas de WisePerks?",
  "faq_ans10" : "Debe tener 13 años o más para registrarse en WisePerks.Aunque a veces lo invitaremos en encuestas para participar con los miembros más jóvenes de su familia.",

  "faq_ques11" : "¿Cómo sabes que calificaré para una encuesta?",
  "faq_ans11" : "Nuestro algoritmo coincide con su información de perfil con las encuestas disponibles para garantizar que obtenga las encuestas más adecuadas.Cuando comienza una encuesta, generalmente las primeras preguntas determinan si usted es el encuestado que nuestro cliente está buscando en esa encuesta en particular.Tenemos una amplia gama de encuestas que buscan diferentes tipos de personas (por ejemplo, personas que ven películas regularmente, juegan un deporte en particular, mujeres con niños, etc.) ¡y surgen nuevas encuestas todos los días!",

  "faq_ques12" : "¿Cuáles son mis obligaciones con WisePerks?",
  "faq_ans12" : "Lo único que esperamos de usted es brindarle sus opiniones y pensamientos honestamente, ya que los clientes están buscando respuestas honestas y verdaderas para mejorar sus productos y servicios.Proporcione la información correcta cuando se registre como miembro y actualice los cambios a medida que ocurren.",

  "faq_ques13" : "¿Por qué debería proporcionar información sobre mi hogar, tecnología o productos que compro?",
  "faq_ans13" : "Para cada encuesta, estamos buscando un cierto perfil de encuestados.Si sabemos por su información de perfil que su información coincide con los requisitos del cliente, ¡bingo!Es casi seguro que podemos asegurar que podrá completar la encuesta con éxito y ganar la recompensa por ella.Esto básicamente aumenta tus posibilidades de ser seleccionado para una encuesta.",

  "faq_ques14" : "¿Cuándo recibiré mis tarjetas de regalo después de completar una encuesta de WisePerks?",
  "faq_ans14" : "Los puntos que usted gana después de completar satisfactoriamente una encuesta se acumulan en su balde. Una vez que estos puntos se han dado cuenta se puede redimir.",

  "faq_ques15" : "¿Cuál es mi cuenta realmente?",
  "faq_ans15" : "Es solo una forma fácil de ver su información personal, hacer los cambios necesarios, ver la actividad de su encuesta, las recompensas obtenidas, todo en un solo lugar.",

  "faq_ques16" : "¿Cómo cancelo mi cuenta?",
  "faq_ans16" : "Con tanto que viene su manera, estamos seguros que usted no querría hacer esto. Sin embargo, en el salpicadero-> ir a configuración-> administrar cuentas-> desactivar cuenta",

  "faq_ques17" : "¿Cómo funciona el sistema de puntos?",
  "faq_ans17p1" : "Cada encuesta es por cierto número de puntos. Después de completar con éxito una encuesta, estos puntos se agregan a sus ganancias. Puedes canjearlos una vez que alcances el umbral mínimo.",
  "faq_ans17p2" : "En algunos casos, debido a algunos desafíos imprevistos con la conexión o fallas, su respuesta podría no ser capturada al final de nuestro cliente. Luego tenemos que revertir esos puntos de su cuenta. Si ya ha canjeado esos puntos y su cuenta tiene un saldo bajo, sus ganancias serán negativas. Estos ajustes se realizarán en su próximo ciclo de canje.",

  "faq_ques18" : "¿Qué tan pronto se agregan los puntos de una encuesta a mi saldo de puntos?",
  "faq_ans18" : "Sus puntos se agregarán a su saldo de puntos en 48 horas.",

  "faq_ques19" : "¿Cuál es el umbral mínimo para el canje?",
  "faq_ans19" : "El umbral mínimo para la redención es de 25000 puntos.En promedio, necesitaría completar 8-12 encuestas para ser elegible para el canje.",

  "faq_ques20" : "¿Cómo se convierten los puntos en efectivo de la Tarjeta de regalo? ¿Hay una relación?",
  "faq_ans20" : "Sí. Según su país, los puntos se convierten en efectivo de la Tarjeta de regalo. Las relaciones de conversión son las que se muestran a continuación",
  "faq_ponits" : "Puntos",
  "faq_point" : "Punto",
  "faq_cent" : "Centavo",

  "faq_ques21" : "Todavía tienes más preguntas?",
  "faq_ans21p1" : "No dude en enviarnos un correo electrónico a ",
  "faq_ans21p2" : "y le volveremos a usted dentro de 48 horas.",

  "Profiling_profiledashboard" : "Panel de control del perfil",
  "Profiling_completeprofile" : "Complete su perfil y consiga enorme",
  "Profiling_completepoints" : "puntos!",
  "Profiling_answercarefully" : "El siguiente conjunto general de preguntas se usará para crear su perfil personal a fin de brindarle las mejores oportunidades de encuestas futuras. Por favor tómese su tiempo, responda con cuidado y con sinceridad.",

  "Profiling_cat_general" : "General",
  "Profiling_cat_personal" : "Personal",
  "Profiling_cat_household" : "Haushalt",
  "Profiling_cat_profession" : "Profesión",

  "Profiling_bar_completed" : "% Completado",
  
  "Profiling_update_ans_button" : "Actualizar y continuar", 
  "Profiling_save_ans_button" : "Salvar", 
  "Profiling_sc_ans_button" : "Guardar y continuar", 

  "Category_thanku_msg1" : "Buen ir",
  "Category_thanku_msg2" : "pocos más y tendrá",
  "Category_thanku_msg3" : "puntos en su cuenta.",

  "Category_thanku_update_msg1" : "¡ Gracias por actualizar su información!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "Continuar",

  "OverAll_thanku_msg1" : "Gracias por compartir su información. Te has ganado", 
  "OverAll_thanku_msg2" : "Puntos.",
	
  "Survey_history_surveyhistory" : "Historial de encuestas",
  "Survey_history_fromthisscreen" : "Desde esta pantalla, podrá ver todas sus transacciones.",
  "Survey_history_show" : "Mostrar",
  "Survey_history_entries" : "Entradas",
  "Survey_history_date" : "Fecha",
  "Survey_history_details" : "Detalles",
  "Survey_history_surveys" : "Encuestas",
  "Survey_history_pointsreceived" : "Puntos recibidos",
  "Survey_history_result" : "Resultado",
  "Survey_history_status" : "Estado",
  "Survey_history_status_complete" : "Completa",
  "Survey_history_status_disqualified" : "Descalificado", 
  "Survey_history_keyword" : "Palabra clave",
  "Survey_history_search" : "Búsqueda",
  "Survey_history_to" : "Para",
  "Survey_history_selectoption" : "Seleccione la opción",
  "Survey_history_earned" : "Ganado",
  "Survey_history_redeemed" : "Redimido",
  "Survey_history_norecord" : "No se encontró ningún registro",
  "Survey_history_Surveypts" : "Puntos de levantamiento",
  "Survey_history_Promocode" : "Código promocional",

  "My_transactions_mytransactions" : "Mis transacciones",
  "My_transactions_fromthisscreen" : "Desde esta pantalla, podrá ver todas sus transacciones.",
  "My_transactions_show" : "Mostrar",
  "My_transactions_entries" : "Entradas",
  "My_transactions_transactionid" : "ID. de transacción",
  "My_transactions_date" : "Fecha",
  "My_transactions_details" : "Detalles",
  "My_transactions_points" : "Puntos",
  "My_transactions_status" : "Estado",
  "My_transactions_keyword" : "Palabra clave",
  "My_transactions_search" : "Búsqueda",
  "My_transactions_to" : "Para",
  "My_transactions_selectoption" : "Seleccione la opción",
  "My_transactions_status_earned" : "Ganado",
  "My_transactions_status_redeemed" : "Redimido",
  "My_transactions_status_pending" : "Pendiente",
  "My_transactions_status_reverted" : "Volvió",
  "My_transactions_status_declined" : "Disminuido",
  "My_transactions_norecord" : "No se encontró ningún registro",
  "My_transactions_off_msg1" : "Después ",
  "My_transactions_off_msg2" : ", todos los detalles de su transacción se publicarán en la pestaña Libro de puntos",

  "Passbook_heading" : "Libro de puntos",
  "Passbook_show" : "mostrar",
  "Passbook_entries" : "entradas",
  "Passbook_search" : "Buscar",
  "Passbook_type" : "Tipo",
  "Passbook_surveyid" : "Encuestas",
  "Passbook_date" : "Fecha",
  "Passbook_selectoption" : "Seleccionar opción",
  "Passbook_credit" : "Crédito",
  "Passbook_debit" : "Débito",
  "Passbook_survey_id" : "ID de encuesta",
  "Passbook_survey_pts" : "Puntos de encuesta",
  "Passbook_earned_pts" : "Puntos",
  "Passbook_pending_pts" : "Puntos pendientes",
  "Passbook_promo_code" : "Código promocional",
  "Passbook_transaction_type" : "Tipo de transacción",
  "Passbook_balance" : "Equilibrar",
  "Passbook_status" : "Estado",
  "Passbook_type_soi" : "Registro",
  "Passbook_type_doi" : "verificacion de email",
  "Passbook_type_profile" : "Perfil completado",
  "Passbook_type_survey" : "Encuesta",
  "Passbook_type_doir" : "Verificación de correo electrónico por",
  "Passbook_type_doirt" : "Verificación de correo electrónico por referencia",
  "Passbook_type_surveyr" : "Intento de encuesta por",
  "Passbook_type_surveyrt" : "Intento de encuesta por referencia",
  "Passbook_type_reward" : "Recompensa",
  "Passbook_type_pending" : "Ajustes",
  "Passbook_type_reverted" : "Puntos revertidos",
  "Passbook_type_declined" : "Solicitudes rechazadas",
  "Passbook_type_redeem" : "Redimir",
  "Passbook_type_balance" : "Llevar adelante",
  "Passbook_balance_vary" : "El saldo real puede variar",
  "Passbook_survey_debit_reason" : "Valoramos sus esfuerzos. Sin embargo, debido a algunas caídas de conexión incontrolables mientras se transfieren los datos, no se pudo registrar su respuesta. Tendríamos que revertir los puntos que recibió anteriormente para esta encuesta. Disculpas por los inconvenientes causados.",

  "Redeem_redeemed" : "Redimir",
  "Redeem_redeemhistory" : "Redimir la historia",
  "Redeem_history_norecord" : "No se encontró ningún registro",
  "Redeem_date" : "Fecha",
  "Redeem_details" : "Detalles",
  "Redeem_points" : "Puntos",
  "Redeem_status" : "Estado",
  "Redeem_lookslike" : "Parece que te perdiste la verificación de tu cuenta de correo electrónico.",
  "Redeem_hurry" : "¡Prisa! obtienes 1000 puntos una vez que lo verificas.",
  "Redeem_pleasecheck" : "Por favor, revise su buzón de entrada y haga clic en el enlace en el correo electrónico de confirmación enviado para activar su cuenta.",
  "Redeem_kindlycheck" : "Por favor, compruebe su carpeta de spam/basura también para el correo electrónico de confirmación.",
  "Redeem_getconfirmationemail" : "¿aún no recibiste el correo de confirmación?",
  "Redeem_resendconfirmationemail" : "Reenviar correo electrónico de confirmación",
  "Redeem_rewardredemptionrule" : "Recompensar regla de redención",
  "Redeem_completesurvey" : "Encuesta completa",
  "Redeem_earnpoints" : "Gane puntos",
  "Redeem_redeemrewards" : "Canjear recompensas",
  "Redeem_note" : "Nota:",
  "Redeem_yourearnedpoints" : "Los puntos ganados se canjearán después de calificar la encuesta. Para redimir la recompensa, los puntos mínimos canjeables en su cuenta deben ser",
  "Redeem__points" : "Puntos.",
  "Redeem_availablebalance" : "Saldo disponible:",
  "Redeem_historydetails" : "Haga clic aquí para ver los detalles de su historial de redención.", 
  "Redeem_reqsucc" : "¡Felicitaciones! Tus puntos han sido redimidos. Por favor revise su buzón registrado para más detalles.", 
  "Redeem_wisecheck_youhave" : "¡Felicidades! Estás a solo un clic de tu recompensa.",
  "Redeem_wisecheck_gcagainst" : "Tarjeta de regalo para",
  "Redeem_wisecheck_points" : "Punkte.",
  "Redeem_wisecheck_issue" : "Sistema ha capturado alguna actividad no reconocida en su cuenta, debido a que usted no puede enviar una solicitud de canje. Póngase en contacto con", 
  "Redeem_emailverify_issue" : "Para canjear sus puntos, le solicitaremos que verifique su cuenta de correo electrónico registrada.",

  "Redeem_unsubscribe_issue" : "No puede enviar una solicitud de canje, ya que se ha dado de baja de WisePerks. Póngase en contacto con support@wiseperks.com para suscribirse nuevamente.",
  "Redeem_wisecheck_message1" : "No puedes canjear debido a algunas razones de seguridad. Por favor, póngase en contacto con el soporte en",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Confirmar",
  "Redeem_Cancel_button" : "Cancelar",
  "Redeem_pending_hello" : "Hola",
  "Redeem_pending" : "Ajustamiento",
  "Redeem_pending_msg1" : "Su cuenta muestra el ajuste de",
  "Redeem_pending_msg2" : "puntos a realizar. Confirme para iniciar la transacción.",

  "Dashboard_wisecheck_issue" : "El sistema ha capturado alguna actividad no reconocida en su cuenta, debido a la cual no puede ver las encuestas. Póngase en contacto con support@wiseperks.com para obtener más información.",
  "Dashboard_unsubscribe_issue" : "No puede ver ninguna encuesta, ya que se ha dado de baja de WisePerks. Póngase en contacto con support@wiseperks.com para recibir las encuestas nuevamente.",


  "WC_DashboardMsg_1" : "Ups! No hay encuestas para que coincida con su perfil a partir de ahora.",
  "WC_DashboardSubMsg_1" : "No se preocupe, estamos barajando nuestro inventario y pronto le enviaremos nuevas encuestas a su manera.",

  "WC_DashboardMsg_2" : "Estamos actualizando su Dashboard. Por favor, compruebe si hay nuevas encuestas después de algún tiempo.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Gracias por completar la encuesta. Acabas de ganar response_points puntos. Inicie sesión en su Dashboard para ver los puntos acumulados totales.",
  "Survey_success_msg2" : "Gracias por completar la encuesta. Acabas de ganar response_points Puntos.",

  "Survey_success_but_nomore_survey" : "¡Felicitación! Acabas de ganar response_points puntos para completar la encuesta. Compruebe por favor para las encuestas próximas después de alguna vez.",
  
  "Survey_terminate_pnt_msg1" : "Parece que parte de su información es ligeramente diferente del requisito. Valoramos su tiempo y esfuerzos, le agregaremos response_points puntos a su cuenta. Estamos enfocados en traerle encuestas más lucrativas e intuitivas.",
  
  "Survey_terminate_nopnt_msg1" : "Parece que algunas de sus respuestas son ligeramente diferentes del requisito. Estaremos enviando más encuestas a su manera. Gracias por su tiempo y esfuerzo.",

"TermsConditions_t&c" : "Términos y condiciones",
  "TermsConditions_WhenRegister" : "Cuando te registras",
  "TermsConditions_WhenYouCreate" : "Cuando creas una cuenta personal, te pedimos información personal, como se menciona aquí:",
  "TermsConditions_YouCanUpdate" : "Puede actualizar o cambiar la información de su perfil básico en cualquier momento.",
  "TermsConditions_YouCanOptOut" : "Puede cancelar o cerrar su cuenta en cualquier momento.",
  "TermsConditions_WeRespectYour" : "Respetamos su privacidad y hacemos el mejor uso de la tecnología para proteger su información del acceso físico y electrónico no autorizado.",
  "TermsConditions_WeShareYour" : "Compartimos su información solo con organizaciones, que están obligadas a operar WiseWorks. Y esas organizaciones deben cumplir con nuestros compromisos con su privacidad y seguridad.",
  "TermsConditions_WhatInformation" : "Qué información recopilamos de usted",
  "TermsConditions_DirectlyCollected" : "Recogido directamente",
  "TermsConditions_EmailAddressAndPassword" : "Dirección de correo electrónico y contraseña de su cuenta con nosotros.",
  "TermsConditions_YourResponse" : "Su respuesta a las preguntas de perfil.",
  "TermsConditions_AnyCustomer" : "Cualquier correspondencia de servicio al cliente.",
  "TermsConditions_AnyPersonalDetails" : "Cualquier dato personal que compartas para la redención.",
  "TermsConditions_AnyOtherInformation" : "Cualquier otra información directamente proporcionada por usted.",
  "TermsConditions_UsageData" : "Datos de uso",
  "TermsConditions_YourIPAddress" : "Tu dirección IP, ubicación geográfica y cookies",
  "TermsConditions_InformationAboutTheDevice" : "Información sobre el dispositivo que utiliza",
  "TermsConditions_YourSurveyActivity" : "La actividad de su encuesta, los puntos en su cuenta, los pagos que recibe después del canje.",
  "TermsConditions_HowDoWeUse" : "¿Cómo utilizamos sus datos?",
  "TermsConditions_SendingYouInvitation" : "Le enviamos correos electrónicos de invitación para responder a la encuesta para la que califica.",
  "TermsConditions_DetectAndPrevent" : "Detectar y prevenir actividades fraudulentas.",
  "TermsConditions_ImproveYourExperience" : "Mejora tu experiencia en la plataforma WiseWorks.",
  "TermsConditions_YourResponseAreTransferred" : "Sus respuestas se transfieren a nuestros terceros afiliados y socios en un formato agregado y no identificado.",
  "TermsConditions_OurPartnersCanAsk" : "Nuestros socios pueden pedirle que proporcione su nombre, dirección de correo electrónico y otra información de contacto durante una encuesta.",
  "TermsConditions_YouAreFreeToDecline" : "Usted es libre de negarse a proporcionar sus datos a nuestros socios, si está de acuerdo con lo que está proporcionando directamente a ellos y no a WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Su información se tratará de acuerdo con la Política de privacidad comercial relevante y no con la Política de privacidad de WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Nuestros socios se comprometen a respetar y proteger su privacidad.",
  "TermsConditions_WhatWeDont" : "Lo que no hacemos con eso",
  "TermsConditions_WeDontSellOnThat" : "No vendemos esa información a nadie y no la usamos para venderle nada a usted. Siempre le pediremos permiso antes de compartir sus datos.",
  "TermsConditions_YourRightToControl" : "Su derecho a controlar sus datos.",
  "TermsConditions_YouHaveFullControl" : "Tienes el control total de tus datos.",
  "TermsConditions_YouCanAccess" : "Puede acceder, actualizar o cambiar la información básica de su perfil cuando lo desee.",
  "TermsConditions_YouCanUnsubscribe" : "Puede darse de baja y desactivar su cuenta.",
  "TermsConditions_YouAreFreeToGetYour" : "Usted es libre de eliminar su perfil enviándonos un correo electrónico a support@wiseperks.com. Intentaríamos resolver su inquietud y, si aún desea que se elimine su cuenta, lo haremos dentro de las 72 horas.",
  "TermsConditions_DataSecurity" : "Seguridad de datos",
  "TermsConditions_OurTechTeamWorks" : "Nuestro equipo técnico trabaja muy duro para mantener todos sus datos seguros.",
  "TermsConditions_WeMakeUseOfLatest" : "Utilizamos la última tecnología y medidas para evitar cualquier uso indebido o acceso no autorizado a sus datos. Sin embargo, debido a la naturaleza del entorno en línea, no podemos garantizar al 100% la seguridad de los datos.",
  "TermsConditions_WeTryOurBestToRule" : "Hacemos todo lo posible para descartar los riesgos, pero no podemos garantizar un entorno sin riesgos. Al aceptar estos Términos y Condiciones, usted reconoce y acepta estos riesgos.",
  "TermsConditions_AnyQuestions" : "Alguna pregunta, por favor sientase libre de contactarnos",
  "TermsConditions_IfYouHaveAnyQueries" : "Si tiene alguna pregunta o queja sobre nuestra política de privacidad y procedimientos. Envíenos un correo electrónico a support@wiseperks.com y le responderemos dentro de las 48 horas.",

  "Validation_PleaseEnterProperDate" : "Por favor ingrese la fecha de nacimiento apropiada",
  "Validation_PleaseSelectOne" : "¡Por favor selecciona una opcion!",
  "Validation_PleaseSelectValidOptions" : "Por favor, seleccione las opciones válidas!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Por favor seleccione al menos una opción",
  "Validation_PleaseInsertTheZip" : "Por favor, introduzca el código postal.",

  "Releases_releases" : "Libera",
  "Releases_theUseOfThis" : "El uso de este sitio web está sujeto a los siguientes términos de uso.",
  "Releases_featuresOfWP" : "Características de WP Release",
  "Releases_updatedOn" : "actualizado en",
  "Releases_WisePerksNow" : "WisePerks ahora soporta varios idiomas",
  "Releases_youCanWalkThourgh" : "Usted puede caminar THOURGH el sitio con mucho más facilidad ahora como WisePerks está disponible en su idioma preferida.",
  "Releases_languagesAvailable" : "Idiomas disponibles:",
  "Releases_EarnMore" : "Gane más con nuestro nuevo sistema de puntos",
  "Releases_theNewWisePerksAccumulates" : "El nuevo WisePerks acumula puntos en su cuenta. Estos puntos tienen un valor mucho más alto y son fáciles de ganar y canjear. Con múltiples opciones de canje para seleccionar de, estamos seguros de que usted disfrutará de la encuesta que toma experiencia.",
  "Releases_accumulatePoints" : "Acumula puntos con molestia redeemption libre",
  "Releases_youGetTheBenefit" : "Se obtiene el beneficio de ganar el mismo no. de puntos, independientemente de la moneda en la que desee canjearlos.",
  "Releases_surveyDisqualification" : "Descalificación de encuestas no es más una mala noticia",
  "Releases_youEarnPoints" : "Usted gana puntos incluso cuando usted no califica para una encuesta, usted comenzó a contestar.",
  "Releases_newAndImproved" : "Diseño nuevo y mejorado",
  "Releases_theFreshLookOf" : "El aspecto fresco de WisePerks hará que te enamores de él. Ahora es mucho más fácil de usar y sería una gran experiencia para usted.",
  "Releases_whatAvatarAreYouToday" : "¿Qué Avatar eres hoy",
  "Releases_youCanNowCreate" : "Ahora puede crear su propio Avatar para la función en su Dashboard.",
  "Releases_freshAndHotSurveys" : "Encuestas frescas y calientes en el salpicadero",
  "Releases_youDontHaveTo" : "Usted no tiene que preocuparse de refresing su tablero de instrumentos para ver nuevas encuestas, nosotros mantendremos su tablero de instrumentos poblado con las últimas encuestas automáticamente.",
  "Releases_promoCode" : "Característica del código de promoción",
  "Releases_promoCodeInfo" : "En la demanda popular, WisePerks trae códigos de promoción para sus panelistas cada semana. Manera rápida de agarrar más recompensas.",
  "Releases_referFriend" : "Recomendar amigo",
  "Releases_referFriendInfo" : "Consigue que tus amigos se asocien con WisePerks y obtén emocionantes recompensas. Busque la opción Recomendar amigo en su cuenta.",

  "Dashboard_Promocode_Usepromo" : "Usar el código promocional",
  "Dashboard_Promocode_to" : "Para",
  "Dashboard_Promocode_earnminimum_1" : "ganar mínimo",
  "Dashboard_Promocode_earnminimum_2" : "puntos extra",
  "Dashboard_Promocode_minimum" : "mínima",
  "Dashboard_Promocode_minimumextrapoints" : "puntos extra en cada encuesta completada hoy",
  "Dashboard_Promocode_apply" : "Aplicar",
  "Dashboard_Promocode_message1" : "¡Código de promoción no válido!",
  "Dashboard_Promocode_message2" : "¡Felicidades! Obtendrás mínimo",
  "Dashboard_Promocode_message3" : "extra en cada encuesta completada hoy!",

  "Dashboard_modal_Enjoythefestive" : "Disfruta del espíritu festivo ganando un mínimo de",
  "Dashboard_modal_Extrapointson" : "de puntos extra en cada encuesta que completes hoy.",

  "refer_friend_title" : "Recomienda a un amigo y obtenga recompensas",
  "refer_friend_step_first": "Invitar a un amigo a WisePerks",
  "refer_friend_step_second": "Un amigo se registra con éxito en WisePerks",
  "refer_friend_step_third": "Obtienes each_referral puntos",
  "refer_friend_link_title": "¡Invita a un amigo ahora!",
  "refer_friend_share_title": "Comparte el enlace",
  "refer_friend_guideline_title": 'Cómo "REFERIRSE A UN AMIGO" lo beneficiará:',
  "refer_friend_guideline_one": "Por favor, comparta el enlace proporcionado anteriormente con sus referencias.",
  "refer_friend_guideline_two": "Puede referir a tantas personas como desee, una vez que 5 de sus referidos verifiquen su cuenta y comiencen la actividad de la encuesta, sus puntos de referido obtenidos se agregarán a su cuenta.",
  "refer_friend_guideline_three": "You get each_referral points each for successful (with verified email account and survey activity) referral.",
  "refer_friend_guideline_four": "Los puntos se obtendrán en dos etapas, partial_referral en la verificación por correo electrónico y partial_second_referral en la actividad de la encuesta por su referencia.",
  "refer_friend_guideline_five": "Después de 5 referencias exitosas (con la cuenta de correo electrónico verificada y la actividad de la encuesta), el enlace de referencia se desactivará.",

  }
  
