import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mailto'
})
export class MailtoPipe implements PipeTransform {

	emails: any = /(\S+@\S+\.\S+)/gim; // Find/Replace email addresses in text
	
  transform(text: string) {
    return this.parseUrl(text);
  }
  
  private parseUrl(text: string) {
	  
	   // Find/Replace email addresses in text
    if (text.match(this.emails)) {
      var parser = new DOMParser();

        text = text.replace(this.emails, '<a href=\"mailto:$1\">$1</a>');
        var el = parser.parseFromString(text, "text/xml");

    }
    console.log(el);
	return text;
  }

}
