import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-profiling-category',
  templateUrl: './profiling-category.component.html',
  styleUrls: ['./profiling-category.component.css']
})
export class ProfilingCategoryComponent implements OnInit {

  selectedItem:string;
  @Output() changeCategoryEvent = new EventEmitter();

  CompleteQuestion:string = "Profiling_bar_completed";
  ProgressBarIncrease:number = 0;
  //categories = Array<Category>();
  categories: Array<{id:string, name:string, img_url:string, comp_ques:number, right_sign:boolean, percent_block:boolean, disabled_class:string}> = [
    {id : 'general', name : 'Profiling_cat_general', img_url : 'assets/images/users/dashboard/profiling/general.jpg', comp_ques : 0, right_sign: false, percent_block :false, disabled_class: ''},
    {id : 'personal', name : 'Profiling_cat_personal', img_url : 'assets/images/users/dashboard/profiling/basic.jpg', comp_ques : 0, right_sign: false, percent_block :false, disabled_class: ''},
    {id : 'household', name : 'Profiling_cat_household', img_url : 'assets/images/users/dashboard/profiling/household.jpg', comp_ques : 0, right_sign: false, percent_block :false, disabled_class: ''},
    {id : 'profession', name : 'Profiling_cat_profession', img_url : 'assets/images/users/dashboard/profiling/profession.jpg', comp_ques : 0, right_sign: false, percent_block :false, disabled_class: ''}
  ];


  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  TabChange(category_name){
    this.selectedItem = category_name;
    this.changeCategoryEvent.emit(category_name);
  }

  /*getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }*/

  ReqChangeCat(nxtcatname){ 
    this.selectedItem = nxtcatname.toLowerCase();
    this.TabChange(nxtcatname.toLowerCase());
  }

  CategoryPercentage(catArr){

    //console.log(catArr);
    let arrindex = 0;
    let total_no_of_ques = 0;
    let total_no_of_ans = 0;

   for(let categ_arr in catArr){

      arrindex = this.ReturnCategoryIndexNo(categ_arr);

      if(('no_of_ques' in catArr[categ_arr]) && ('no_of_ans' in catArr[categ_arr])){
        
        if(catArr[categ_arr]['no_of_ques'] == catArr[categ_arr]['no_of_ans']){
          this.categories[arrindex]['comp_ques'] = 100;
          this.categories[arrindex]['percent_block'] = false;
          this.categories[arrindex]['right_sign'] = true;
        } else {
          let num_of_ques = catArr[categ_arr]['no_of_ques'];
          let num_of_ans = catArr[categ_arr]['no_of_ans'];

          this.categories[arrindex]['right_sign'] = false;
          this.categories[arrindex]['percent_block'] = true;

          let divide_res = (num_of_ans / num_of_ques) * 100;
          this.categories[arrindex]['comp_ques'] = Math.round(divide_res);
        }
      } else {
        this.categories[arrindex]['right_sign'] = false;
          this.categories[arrindex]['percent_block'] = true;
        this.categories[arrindex]['comp_ques'] = 0;
      }

      /* now find total no of ques, start here */

      if('no_of_ques' in catArr[categ_arr]){
        total_no_of_ques += catArr[categ_arr]['no_of_ques'];
      }

      if('no_of_ans' in catArr[categ_arr]){
        total_no_of_ans += catArr[categ_arr]['no_of_ans'];
      }
    }

    /*Progress Bar, Start Here */
      let overall_prog_bar = (total_no_of_ans / total_no_of_ques) * 100;
      let progress_bar_count = Math.round(overall_prog_bar);

      if(progress_bar_count > 100){
        progress_bar_count = 100;
      }

      this.ProgressBarIncrease = progress_bar_count;
    /*Progress Bar, End Here */
  }

  DoDisabledCategory(GetDisabledCatArr){
    let DisabledCatArr = GetDisabledCatArr['arr'];
    let flag = GetDisabledCatArr['flag'];
    //flag = 1 : intially loaded time & flag = 2 : after get response from api

    for(var z=0; z<DisabledCatArr.length; z++){
      let category_indexno = this.ReturnCategoryIndexNo(DisabledCatArr[z]);
      
      this.categories[category_indexno]['disabled_class'] = 'disabled';
      this.categories[category_indexno]['percent_block'] = false;
      this.categories[category_indexno]['right_sign'] = true;
    }
    
    /* Initally Active Tab, Start Here */
    
        if(DisabledCatArr.length > 0 && flag == 1){
          for(var n=0; n < this.categories.length; n++){
            var catg_name = this.categories[n]['id'].charAt(0).toUpperCase() + this.categories[n]['id'].substr(1);
              
            if(DisabledCatArr.indexOf(catg_name) !== -1){ 
              continue;
            } else {
              this.selectedItem = this.categories[n]['id'];
              this.TabChange(this.categories[n]['id']);
              break;
            }
          }
        } else if(flag == 1) {
          this.selectedItem = "general";
          this.TabChange('general');
        }
    /* Initally Active Tab, End Here */
  }

  ReturnCategoryIndexNo(getCatName){
    let arrindex = 0;

    if(getCatName == 'General'){
      arrindex = 0;
    } else if(getCatName == 'Personal'){
      arrindex = 1;
    } else if(getCatName == 'Household'){
      arrindex = 2;
    } else if(getCatName == 'Profession'){
      arrindex = 3;
    }
    return arrindex;
  }

}
