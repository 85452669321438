import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { HomeService } from '../shared/home.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.css']
})
export class HomeFooterComponent implements OnInit {

  member_block = true;
  register_block = false;
  header_scroll_url:string = '#header';
  header_scroll_show:boolean = false;
  i=0;
  MemberPoint:number=4000000;
  Math: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, 
    private _homeService:HomeService,
    private toastr: ToastrService) {

    this.Math = Math;
    let currentUrl = this.router.url;
    let resetUrl = "";

    if(activatedRoute.snapshot.url[0]){
     resetUrl = activatedRoute.snapshot.url[0].path;
    }
    
    if(currentUrl == '/faq' || currentUrl == '/privacy-policy' || resetUrl == "reset_password" || currentUrl == '/terms-and-conditions' || currentUrl == '/releases'){
      this.member_block = false;
      this.register_block = true;
      this.header_scroll_url = 'inner-header';
    }
    
    if(resetUrl == 'reset_password'){
      this.header_scroll_show = false;
    }

    this._homeService.getPanelistsLifetimePoints().subscribe((resp:any)=>{
      if(resp.auth == true && resp.success == true){
        this.MemberPoint += this.Math.round(resp.data[0]['points']/1000);
      }
   },(err:any)=>{
      this.showNewFlashError(err,'WisePerks');
   });

  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  GetAllPanelistPoints(){
    return this.MemberPoint; 
  }

  ScrollTop(event){
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
  }


  showNewFlashError(err,heading_msg){
    this.toastr.clear();
    if(err.error.errors){
      var firsterrkey = Object.keys(err.error.errors)[0];
      var msg = err.error.errors[firsterrkey];
      this.toastr.error(msg,heading_msg);
    } else if(err.error.message){
      this.toastr.error(err.error.message, heading_msg);
    }
  }

  // scroll to top button will be active on window scroll on homepage
  scroll = (): void => {
    this.header_scroll_show = true;
  };
}
