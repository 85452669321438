import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';

/*import { AppRoutingModule } from './app-routing.module';*/
import {ModuleRouting} from './app-routing.module';

import { UserModule } from './user/user.module';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { NotAvailableComponent } from './shared/not-available/not-available.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    UserComponent,
    PageNotFoundComponent,
    NotAvailableComponent
  ],
  imports: [
    BrowserModule,
    ModuleRouting,
    UserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
