// Malaysiya
export const TRANSLATIONS_MS = {
  "home_header_selectlang": "Pilih Bahasa",
  "home_header_loginbutton": "Log masuk",
  "home_header_signupbutton": "Signup",
  "home_header_homebutton": "Rumah", 
  
  "home_register_bannertxt1": "Di sini anda",
  "home_register_bannertxt2": "pemikiran",
  "home_register_bannertxt3": "boleh menjemput anda",
  "home_register_bannertxt4": "ganjaran$",
  "home_register_bannertxt5": "Dapatkan bonus",
  "home_register_bannertxt6": "Pada Signup",
  "home_register_fbsignup": "DAFTAR DENGAN FACEBOOK",
  "home_register_fblogin": "LOG MASUK MELALUI FACEBOOK",
  "home_register_or": "ATAU",
  "home_register_emailsignup": "Daftar dengan E-mel",
  "home_register_emaillogin": "Masuk dengan E-mel",
  "home_register_fpass": "Lupa kata laluan?",
  "home_register_fname": "Nama pertama",
  "home_register_lname": "Nama terakhir",
  "home_register_email": "Alamat emel",
  "home_register_pass": "Kata laluan",
  "home_register_cpass": "Sahkan Kata Laluan",
  "home_register_tcpp": "Dengan menyertai anda bersetuju dengan kami",
  "home_register_tcpptandcond": "Terma & Syarat",
  "home_register_tcppand": "dan",
  "home_register_tcppprivacy": "Dasar Privasi",
  "home_register_join": "Sertai kami. Ianya percuma",
  "home_register_login": "log masuk",
  "home_register_name_required" : "Nama diperlukan.",
  "home_register_email_required" : "E-mel diperlukan.",
  "home_register_invalid_email" : "Format E-mel tidak sah",
  "home_register_password_required" : "Kata laluan diperlukan.",
  "home_register_password_lower_digit" : "Kata laluan mesti 6 digit diperlukan",
  "home_register_password_higher_digit" : "Kata laluan mesti digunakan 15 digit diperlukan",
  "home_register_cpassword_required" : "Sahkan kata laluan diperlukan",
  "home_register_cpassword_lower_digit" : "Sahkan Kata Laluan mesti diperlukan 6 digit",
  "home_register_cpassword_higher_digit" : "Sahkan Kata Laluan mesti digunakan 15 digit yang diperlukan",
  "home_register_cpassword_not_match" : "Sahkan Kata Laluan tidak sepadan",

  "home_register_fp_not_worry" : "Sila masukkan id emel yang didaftarkan.", 
  "home_register_fp_resetpass_msg" : "Untuk mengeset semula kata laluan anda, sila masukkan id emel yang didaftarkan.", 
  "home_register_fp_enter_email_msg" : "Masukkan email anda di sini", 
  "home_register_fp_submit_button" : "Mengemukakan",
  "home_register_fp_temporary_sent_pass_msg" : "Set semula kata laluan pautan telah dihantar ke id emel yang didaftarkan. Sila Kemaskini kata laluan anda di awal.",
  
  "home_works_howitworks": "Bagaimana ia berfungsi?",
  "home_works_howitworkst1": "Perkenalkan diri anda", 
  "home_works_howitworksd1": "Menjadi ahli, Daftar",
  "home_works_howitworkst2": "Terima tinjauan",
  "home_works_howitworksd2": "Mulakan menerima tinjauan intuitif dan mudah",
  "home_works_howitworkst3": "Dapatkan Mata",
  "home_works_howitworksd3": "Berikan tanggapan terbaik anda dan kumpulkan mata",
  "home_works_howitworkst4": "Tebus",
  "home_works_howitworksd4": "Tebus mata anda untuk banyak hadiah yang menarik",
  
  "home_aboutus_whoweare": "Siapa kita?",
  "home_aboutus_whowearep1": "Di WisePerks kami berusaha keras untuk memastikan bahawa masa yang anda habiskan dalam talian adalah pengalaman yang memuaskan. Kami bekerja dengan beratus-ratus syarikat yang ingin mendapatkan maklum balas anda, tahu bagaimana perasaan anda tentang produk atau perkhidmatan mereka. Anda memberikan pendapat anda mengenai pelbagai perkhidmatan, tetapi tiada satu pun daripada perkhidmatan tersebut yang memberi ganjaran kepada anda untuk masa anda, tidak seperti WisePerks - di mana setiap kaji selidik yang anda ambil dalam memberikan ganjaran tunai yang besar untuk masa anda.",
  "home_aboutus_whowearep2": "Kami menghargai maklum balas anda dan ingin anda mendapat ganjaran untuk masa anda. Kami mempunyai kaji selidik mengenai pelbagai topik untuk dipadankan dengan minat anda dan menjadikannya mudah dijawab. Anda boleh dengan mudah membuat sekitar $ 50- $ 100 setiap bulan dengan mengambil pelbagai tinjauan. Jadi mari kita bekerjasama untuk membuatnya berlaku.",
  
  "home_earn_more_surabt": "Ukur apa tanya?",
  "home_earn_more_bp": "Pakar-pakar perniagaan",
  "home_earn_more_consumers": "Pengguna",
  "home_earn_more_hc": "Kesihatan & Kecergasan",
  "home_earn_more_entertainment": "Menghiburkan & menyeronokkan",
  "home_earn_more_gaming": "Aneh perjudian",
  "home_earn_more_tech": "Celik teknologi", 
  "home_earn_more_bonus_survey": "Kaji selidik bonus",
  
  "home_reward_whatuearn": "Apa yang anda peroleh?",
  "home_reward_whatuearnp1": "Kami suka menjaga perkara yang mudah. Anda mendapat mata untuk semua tinjauan yang berjaya diselesaikan. Semakin banyak tinjauan anda selesai, semakin banyak mata yang anda peroleh. Mata boleh ditebus untuk Kad Hadiah pilihan anda selepas anda menyeberangi ambang tertentu.",
  "home_reward_whatuearnp2": "Setelah bersedia untuk menebus, pilih pilihan yang anda suka - Amazon, Starbucks, iTunes dan lain-lain lagi. Kami juga memberi Bonus dan Alhamdulillah menawarkan Hari Jumaat dari semasa ke semasa.",
  
  "home_counter_earnedover": "Ahli telah berjaya",
  
  "home_contact_getintouch": "Berhubung",
  "home_contact_emailus": "Untuk sebarang soalan, sila e-mel kepada kami di",
  
  "home_footer_faq": "Soalan Lazim",
  "home_footer_pp": "Dasar Privasi",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Ciri-ciri yang akan datang", 
  "home_footer_copyright": "Hak Cipta © current_year WisePerks. Hak cipta terpelihara.",

  "home_header_home": "Rumah", 
  "home_footer_toknowmore": "Untuk mengetahui lebih lanjut",
  "home_footer_createacc": "Buat akaun secara percuma dan sertai ribuan anggota gembira kami.",
  "home_footer_registernow": "Daftar sekarang",

  "home_reset_password" : "Set semula kata laluan", 
  "home_reset_pass_content" : "Anda telah meminta untuk set semula kata laluan untuk", 

  "dashboard_sidebar_selectavatar" : "Pilih avatar anda", 
  "dashboard_sidebar_uploadavatar" : "Klik di sini untuk muat naik imej anda", 
  "dashboard_sidebar_pbank" : "Poin Bank",
  "dashboard_sidebar_accumulated" : "Diperolehi", 
  "dashboard_sidebar_realized" : "Menyedari", 
  "dashboard_sidebar_pending" : "Pelarasan",
  "dashboard_sidebar_earned_tooltip" : "Mata yang anda kumpulkan setakat ini.",
  "dashboard_sidebar_pending_tooltip" : "Mata yang perlu dibalikkan dari akaun anda.",
  "dashboard_sidebar_main" : "Main",
  "dashboard_sidebar_dashboard" : "Papan Pemuka",
  "dashboard_sidebar_profile" : "Profil",
  "dashboard_sidebar_updateprofile" : "Mengemaskini profil",
  "dashboard_sidebar_shistory" : "Sejarah Ukur",
  "dashboard_sidebar_mtrans" : "Urus Niaga saya",
  "dashboard_sidebar_passbook" : "Mata Buku",
  "dashboard_sidebar_redeem" : "Tebus",
  "dashboard_sidebar_refer_friend" : "Rujuk Rakan",
  "dashboard_sidebar_logout" : "Log keluar",
  
  "dashboard_sidebar_tutorial1" : "Menunjukkan kepada anda semua dalam disediakan Ukur dan apa yang anda perolehi daripada mereka.",
  "dashboard_sidebar_tutorial2" : "Jawab beberapa soalan di sini untuk memaklumkan kami tahu anda lebih baik dan membantu kami dalam menghantar tinjauan minat anda. Sila ambil perhatian bagi beberapa soalan yang anda tidak akan dapat mengemaskini jawapan anda selepas menyerahkan.",
  "dashboard_sidebar_tutorial2update" : "Anda boleh mengemaskini jawapan anda di sini, namun beberapa soalan tidak akan disediakan untuk updation.",
  "dashboard_sidebar_tutorial3" : "Mencari di sini semua kaji-selidik yang anda cuba.",
  "dashboard_sidebar_tutorial4" : "Membantu anda mengikuti perkembangan semua transaksi anda di WisePerks.",
  "dashboard_sidebar_tutorial5" : "Beberapa langkah pantas untuk menukar mata ganjaran anda menyedari ganjaran.",
  "dashboard_sidebar_tutorial6" : "Lihat keseluruhan sejarah transaksi mata yang didebitkan / dikreditkan ke akaun anda.",
  "dashboard_sidebar_gotit" : "dapat",

  "dashboard_sidebar_note" : "Sila ambil perhatian",
  "dashboard_sidebar_note1" : "Semua jawapan mesti dijawab dengan jujur.",
  "dashboard_sidebar_note2" : "Sila spesifik dan tepat dengan respons terbuka yang terbuka.",
  "dashboard_sidebar_note3" : "Jangan laju! Masa penyiapan survei minimum ditetapkan.",
  "dashboard_sidebar_note4" : "Sila berikan respons yang konsisten apabila soalan yang sama diminta.",
  "dashboard_sidebar_note5" : "Kaji selidik baru akan ditambah dan yang tertutup akan dipadam secara automatik daripada papan pemuka anda.",
  "dashboard_sidebar_note6" : "Dapatkan beberapa mata cepat hanya dengan rujukan.",

  "dashboard_setting_hi" : "Hi",
  "dashboard_setting_minfo" : "Maklumat Keahlian",
  "dashboard_setting_macc" : "Urus Akaun",
  "dashboard_setting_upass" : "Kemas kini Kata Laluan",
  "dashboard_setting_yourname" : "Nama anda",
  "dashboard_setting_fname" : "Nama pertama",
  "dashboard_setting_lname" : "Nama terakhir",
  "dashboard_setting_save" : "Simpan Perubahan",
  "dashboard_setting_bornon" : "Anda dilahirkan",
  "dashboard_setting_youare" : "Anda seorang",
  "dashboard_setting_postal" : "Kod pos anda ialah",
  "dashboard_setting_emailus" : "Untuk sebarang bantuan atau pertanyaan, sila hantar email ke",
  "dashboard_setting_membersince" : "Anda telah menjadi ahli sejak",
  "dashboard_setting_taken" : "Anda telah mengambil",
  "dashboard_setting_completed" : "kaji selidik dan selesai",
  "dashboard_setting_ofthem" : "daripada mereka.",
  "dashboard_setting_earned" : "Anda telah berjaya",
  "dashboard_setting_pts" : "mata",
  "dashboard_setting_started" : "sejak anda mula.",
  "dashboard_register_email" : "Anda telah berdaftar dengan kami dari",
  "dashboard_setting_preferences" : "Uruskan pilihan anda",
  "dashboard_setting_emailoptout" : "Opt Keluar E-mel",
  "dashboard_setting_futureemails" : "Pilih daripada menerima e-mel masa depan dari WisePerks.",
  "dashboard_setting_futureemailsflash" : "E-mel kami pastikan anda hantar ke atas semua lumayan kaji selidik disediakan untuk anda. Adakah anda pasti anda tidak mahu menerima mereka.",
  "dashboard_setting_nrecemail" : "E-mel kami pastikan anda hantar ke atas semua lumayan kaji selidik disediakan untuk anda. Adakah anda pasti anda tidak mahu menghalang mereka.",
  "dashboard_setting_emailout_modalmsg" : "Adakah anda pasti hendak menghentikan pelangganan menerima e-mel pada masa hadapan dari WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Adakah anda pasti hendak mula menerima e-mel pada masa hadapan dari WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Anda telah berjaya berhenti menerima emel dari WisePerks",
  "dashboard_setting_emailin_succmsg" : "Anda telah berjaya mula menerima emel dari WisePerks",
  "dashboard_setting_deactivateacc" : "Nyahaktifkan Akaun",
  "dashboard_setting_disableprofile" : "Menyahaktifkan akaun anda akan melumpuhkan profil anda dari WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Adakah anda pasti anda ingin menyahaktifkan akaun anda pada WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Jika anda mempunyai sebarang aduan atau maklum balas, maka sila kongsi bersama. Kami akan menangani keutamaan. Memberi kita peluang. Adakah anda pasti hendak memadam akaun anda dengan kami.",
  "dashboard_setting_changelang" : "Tukar bahasa",
  "dashboard_setting_preferredlang" : "Pilih bahasa pilihan anda",
  "dashboard_setting_updatepass" : "Kemas kini Kata Laluan",
  "dashboard_setting_plsupdate" : "Sila Kemaskini kata laluan anda di bawah.",
  "dashboard_setting_oldpass" : "Kata laluan lama",
  "dashboard_setting_enteroldpass" : "Masukkan Kata Laluan Lama",
  "dashboard_setting_newpass" : "Kata laluan baharu",
  "dashboard_setting_enternewpass" : "Masukkan kata laluan baru",
  "dashboard_setting_confpass" : "Sahkan kata laluan",
  "dashboard_setting_enternewpassagain" : "Masukkan Kata Laluan Baru Lagi",
  "dashboard_setting_update" : "Kemas kini",

  "dashboard_doi_welcome" : "Mengalu-alukan",
  "dashboard_doi_welbck" : "Selamat kembali",
  "dashboard_doi_accntverified" : "Kelihatan seperti anda terlepas mengesahkan akaun e-mel anda.",
  "dashboard_doi_getaccess" : "Terburu-buru! anda akan mendapat",
  "dashboard_doi_getaccess2" : "Mata sebaik sahaja anda mengesahkan.",
  "dashboard_doi_activateacc" : "Sila semak Peti Masuk anda dan klik pada pautan yang diberikan di dalam email pengesahan akaun yang dihantar ke akaun e-mel berdaftar anda.",
  "dashboard_doi_chckspam" : "Sila, semak folder Spam/Junk anda sekiranya anda tidak mampu untuk mencari e-mel pengesahan.",
  "dashboard_doi_didntgetconf" : "Tidak mendapat e-mel pengesahan lagi?",
  "dashboard_doi_resendconf" : "Hantar e-mel pengesahan",
  "dashboard_doi_resendconfsucc" : "Emel pengesahan telah dihantar ke email anda berdaftar.", 
  
  "dashboard_survey_availsurveys" : "Survei yang tersedia",
  "dashboard_survey_wisechecksurveys1" : "Anda tidak dapat melihat Ukur kerana beberapa sebab-sebab keselamatan. Sila hubungi untuk menyokong",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Tidak terdapat Ukur sebab berhenti melanggan",
  "dashboard_featured" : "AGAR",
  "dashboard_profile_survey" : "Kaji selidik profil",
  "dashboard_survey_start" : "Mulakan",
  "dashboard_survey_on" : "Pada",
  "dashboard_survey_off" : "Off",
  "dashboard_survey_point": "Titik",
  "dashboard_survey_points" : "Mata",
  "dashboard_survey_minute" : "Minit",
  "dashboard_survey_minutes" : "Minit",

  "demography_hi" : "Hi",
  "demography_welcome" : "Selamat datang ke WisePerks. Anda hanya perolehi sendiri",
  "demography_points" : "mata dengan mendaftar dengan kami.",
  "demography_share" : "Sila berkongsi beberapa maklumat lanjut dalam borang di bawah, untuk membantu kami dalam membawa Ukur yang berkaitan kepada anda.",

  "demography_gender" : "saya adalah",
  "demography_male" : "Lelaki",
  "demography_female" : "Perempuan",
  "demography_birthday" : "Hari Lahir",
  "demography_month" : "Bulan", 
  "demography_day" : "Hari", 
  "demography_year" : "Tahun", 
  "demography_jan" : "Januari",
  "demography_feb" : "Februari",
  "demography_mar" : "Mac",
  "demography_apr" : "April",
  "demography_may" : "Mungkin",
  "demography_jun" : "Jun",
  "demography_jul" : "Julai",
  "demography_aug" : "Ogos",
  "demography_sep" : "September",
  "demography_oct" : "Oktober",
  "demography_nov" : "November",
  "demography_dec" : "Disember",
  "demography_hearabout" : "Di manakah anda mendengar tentang WisePerks?",
  "demography_opt1" : "Pilih yang terdekat yang terpakai",
  "demography_opt2" : "E-mel / Newsletter",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum atau Blog",
  "demography_opt5" : "Kawan",
  "demography_opt6" : "LinkedIn",
  "demography_opt7" : "Enjin carian",
  "demography_opt8" : "Komersial TV",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Laman web",
  "demography_opt11" : "YouTube",
  "demography_opt12" : "Lain-lain",
  "demography_zipcode": "Kod zip",
  "demography_submit" : "Mengemukakan",

  "privacypolicy_privacypolicy" : "Dasar Privasi",
  "privacypolicy_privacyisimp" : "Privasi anda adalah penting kepada kami.",
  "privacypolicy_lastupdated" : "Terkini diperbaharui",

  "privacypolicy_para1" : 'WiseWorks (bersama-sama dengan sekutunya, "Syarikat") memiliki dan menjalankan beberapa laman web, aplikasi mudah alih dan perkhidmatan interaktif yang berbeza, termasuk tanpa had Wisesample.com, Flapbucks.com, WisePerks.com, dan lain-lain (secara kolektif, WiseWorks Sites ").Dasar privasi ini ("Dasar Privasi") terpakai kepada Laman WiseWorks dan semua ciri, aplikasi mudah alih, e-mel, perkhidmatan dalam talian dan fungsi lain, sama ada diakses menerusi komputer, peranti mudah alih atau sebaliknya dan perkhidmatan yang tersedia pada atau melalui Tapak dan Ciri WiseWorks ("Perkhidmatan").Dasar Privasi ini hanya meliputi maklumat yang dikumpulkan oleh Syarikat melalui Tapak dan Ciri WiseWorks dan Perkhidmatan kami, dan tidak melindungi sebarang maklumat yang diterima atau dikumpulkan di luar talian oleh Syarikat (kecuali dinyatakan secara khusus) atau dikumpulkan oleh mana-mana laman web atau syarikat yang tidak berkaitan menyediakan pautan.',

  "privacypolicy_para2" : "Diharapkan panelis / responden akan mengisi profilnya secara jujur ​​dan jujur ​​dan tidak mengemukakan maklumat yang salah atau salah hanya untuk melayakkan lebih banyak tinjauan.Ia juga dijangka bahawa panelis / responden tidak akan memanipulasi jawapannya atau menggunakan cara lain yang tidak adil (menukar IP, berbilang akaun dan lain-lain) semasa pengisian tinjauan untuk memperoleh insentif.WisePerks mempunyai perlindungan yang mencukupi untuk mengesan anomali sedemikian dan jika perilaku sedemikian dilihat, panelis akan diharamkan / disekat dari WisePerks dan semua / mana-mana permintaan penebusannya akan dibatalkan.",

  "privacypolicy_para3" : "WiseWorks sangat komited untuk melindungi privasi anda dengan kami.Dasar privasi berikut menerangkan bagaimana maklumat peribadi anda dipegang dan dirawat semasa anda meneroka laman web dan perkhidmatan kami.",

  "privacypolicy_topic1" : "Maklumat peribadi apa yang dilakukan oleh WisePerks.com dari saya?",
  "privacypolicy_topic1ans" : "WisePerks.com mengumpulkan maklumat dalam beberapa adab dari berbagai bahagian laman web dan rangkaian kami.Sesetengah maklumat dikumpulkan apabila anda mendaftar.Semasa pendaftaran anggota, WisePerks.com meminta nama anda, alamat e-mel, kod pos dan maklumat isi rumah.Lebih banyak maklumat yang anda berikan secara sukarela, lebih baik kami dapat menawarkan perkhidmatan dan ganjaran kepada anda.Selain itu, jika anda pernah menghubungi WisePerks.com melalui apa jua cara, kami boleh menyimpan rekod surat itu.Apabila WisePerks.com mengumpul apa-apa maklumat sedemikian, kami berhasrat untuk memasukkan pautan ke Dasar Privasi ini pada halaman itu.",

  "privacypolicy_topic2" : "Apakah cookies dan bagaimana WisePerks.com menggunakannya?",
  "privacypolicy_topic2ans" : "Kuki adalah satu paket data elektronik yang dihantar ke pelayar anda, dari pelayan web dan disimpan pada pemacu keras komputer anda.Sebagai sebahagian daripada menawarkan kaji selidik, maklumat, dan perkhidmatan yang khusus, WisePerks.com boleh menggunakan kuki untuk menyimpan dan kadang-kadang menjejaki maklumat mengenai anda.WisePerks.com mungkin menghendaki anda menerima kuki untuk menyediakan kandungan khusus untuk anda.Ini juga mengelakkan anda memasukkan maklumat log masuk anda setiap masa.",

  "privacypolicy_topic3" : "Apakah dasar WisePerks.com yang membolehkan saya mengemas kini, mengedit, menukar atau memadam maklumat akaun peribadi saya?",
  "privacypolicy_topic3ans" : "Anda boleh mengedit Maklumat Akaun WisePerks.com anda bila-bila masa dengan menggunakan WisePerks.com Log masuk dan kata laluan anda.Sila hubungi",
  "privacypolicy_topic3ans2" : "untuk mendapatkan arahan lanjut mengenai menyahaktifkan akaun WisePerks.com anda.",

  "privacypolicy_topic4" : "Siapa yang mengumpul maklumat dan untuk tujuan apa?",
  "privacypolicy_topic4ansp1" : "WisePerks.com mengumpulkan beberapa maklumat melalui kaji selidik dan pendaftaran dan cara lain, yang membantu pelanggan penyelidikannya mengembangkan produk dan perkhidmatan baru untuk pengguna.Anda berkongsi maklumat itu dengan WisePerks.com sahaja, melainkan jika dinyatakan secara khusus sebaliknya pada mana-mana kaji selidik yang anda ikuti.Sekiranya data dikumpulkan dan / atau diselenggarakan oleh mana-mana syarikat selain daripada WiseWorks - atau laman WiseWorks, anda akan dimaklumkan sebelum masa pengumpulan atau pemindahan data.Jika anda tidak mahu data anda dikongsi, anda boleh memilih untuk tidak membenarkan pemindahan dengan tidak menggunakan kaji selidik atau produk tertentu.",
  "privacypolicy_topic4ansp2" : "Ganjaran dan promosi yang kami tawarkan di WisePerks.com boleh sama ada ditaja oleh syarikat lain secara bebas, atau mungkin ditaja bersama oleh WisePerks.com dan syarikat lain.Sesetengah data yang dikumpul semasa promosi itu boleh dikongsi dengan penaja.Walau bagaimanapun, anda akan dimaklumkan sebelum masa pengumpulan atau pemindahan data.Anda boleh memutuskan untuk tidak mengambil bahagian dalam promosi tertentu jika anda tidak mahu berkongsi data anda.",
  "privacypolicy_topic4ansp3" : "Sebarang Maklumat Sensitif tertentu (seperti maklumat yang menyatakan keadaan perubatan atau kesihatan, asal perkauman atau etnik, pandangan politik, kepercayaan agama atau kerohanian, dagangan dan keahlian kesatuan atau maklumat yang menyatakan kehidupan seks individu) akan diperlakukan dengan penjagaan tambahan.Jika WisePerks.com berhasrat untuk berkongsi maklumat tersebut kepada syarikat lain untuk tujuan yang lain daripada yang asalnya dikumpulkan, maka anda akan mendapat izin secara nyata sebelum pendedahan tersebut.",

  "privacypolicy_topic5" : "Dengan siapa WisePerks.com berkongsi maklumat saya?",
  "privacypolicy_topic5ansp1" : "Kami mengambil serius terhadap privasi.WisePerks.com tidak akan mendedahkan apa-apa maklumat peribadi anda kecuali apabila kami mempunyai kebenaran tersurat atau di bawah sebarang keadaan khas, bahawa undang-undang memerlukannya atau di bawah keadaan yang dinyatakan secara terperinci di bawah.Berikut ini menerangkan beberapa cara maklumat anda boleh didedahkan.Sila lihat Syarat Perkhidmatan untuk perkhidmatan kami untuk maklumat lebih terperinci tentang bagaimana maklumat peribadi anda boleh dikongsi.",
  "privacypolicy_topic5ansp2" : "Rakan Perniagaan, Pelanggan & Penaja:",
  "privacypolicy_topic5ansp3" : "WisePerks.com boleh mendedahkan maklumat peribadi anda kepada rakan kongsi, pelanggan atau penaja kami.Walau bagaimanapun, jika ada data peribadi tentang anda yang pernah dikongsi, kami akan memberitahu anda sebelum kami mengumpul atau memindahkan data.Banyak aktiviti yang kami tawarkan memohon maklumat tambahan daripada penaja.Dengan meminta lebih banyak maklumat, anda memberikan kebenaran WisePerks.com untuk berkongsi maklumat anda dengan penaja supaya mereka dapat memenuhi permintaan anda.Dalam banyak keadaan, hanya alamat e-mel anda yang akan dikongsi.Jika lebih banyak maklumat akan dikongsi dengan rakan kongsi kami, anda akan diberitahu terlebih dahulu.Dari semasa ke semasa, anda mungkin ditawarkan peluang untuk menerima bahan ujian, atau produk lain atau tawaran istimewa dari rakan kongsi kami.Jika anda memilih untuk menerima maklumat daripada pihak ketiga ini, WisePerks.com akan (dengan kebenaran anda) berkongsi nama dan alamat e-mel anda dengan mereka.",
  "privacypolicy_topic5ansp4" : "WisePerks.com juga boleh mendedahkan maklumat peribadi dalam beberapa kes khas apabila mendedahkan maklumat ini adalah perlu untuk mengenal pasti, menghubungi atau membawa tindakan undang-undang terhadap seseorang yang mungkin melanggar dasar dan Syarat Perkhidmatan WisePerks.com atau mungkin menyebabkan sebarang kecederaan atau gangguan (sama ada sengaja atau tidak sengaja) Produk, perkhidmatan, hak atau harta WisePerks.com, ahli dan pengguna WisePerks.com yang lain, atau orang lain yang boleh dipengaruhi oleh sebarang aktiviti sedemikian.WisePerks.com boleh mendedahkan atau mengakses maklumat akaun apabila kami mempercayai dengan suci hati bahawa undang-undang mengharuskannya dan bagi tujuan pentadbiran dan lain-lain yang kami anggap perlu untuk mengekalkan, memberi perkhidmatan, dan meningkatkan produk dan perkhidmatan kami.",

  "privacypolicy_topic6" : "Apakah asas undang-undang yang kami gunakan untuk memproses maklumat anda?",
  "privacypolicy_topic6ans" : 'WiseWorks berkomitmen untuk perlindungan Privasi dan Data.Dasar privasi kami mengamalkan prinsip Peraturan Perlindungan Data Am yang dikeluarkan oleh Kesatuan Eropah ("GDPR") sebagai penanda aras yang mana kami, pekerja dan pembekal kami mematuhi.',

  "privacypolicy_topic7" : "Apakah pilihan saya mengenai pengumpulan, penggunaan, dan pengedaran maklumat saya?",
  "privacypolicy_topic7ans" : "Hanya WisePerks.com (atau rakan kongsi yang bekerja dengan WisePerks.com di bawah perjanjian kerahasiaan) akan menghantar kepada anda mana-mana e-mel langsung, hanya jika anda menyatakan bahawa anda tidak membantahnya.Anda juga mempunyai pilihan berkenaan dengan bagaimana cookies berfungsi.Dengan menukar tetapan penyemak imbas internet anda, anda mempunyai pilihan untuk menerima semua kuki, diberitahu apabila kuki ditetapkan, atau menolak semua permintaan kuki.Jika anda memilih untuk menolak semua kuki, anda tidak dapat menggunakan perkhidmatan WisePerks.com yang memerlukan pendaftaran terdahulu untuk menyertai.WisePerks.com tidak menjual atau memajak sebarang maklumat pengguna kepada sesiapa sahaja.Kami akan sentiasa memberitahu anda pada masa pengumpulan atau pemindahan data jika data anda akan dikongsi dengan mana-mana pihak ketiga dan anda akan sentiasa mempunyai pilihan untuk tidak membenarkan ia dikongsi.",

  "privacypolicy_topic8" : "Apakah langkah berjaga-jaga keselamatan yang ada di WisePerks.com untuk melindungi sebarang kerugian, penyalahgunaan, atau perubahan maklumat akaun saya?",
  "privacypolicy_topic8ansp1" : "Maklumat Akaun WisePerks.com anda dilindungi kata laluan supaya anda dan anda hanya mempunyai akses kepada maklumat ini.",
  "privacypolicy_topic8ansp2" : "Kami sangat mengesyorkan agar anda tidak berkongsi kata laluan dengan sesiapa pun.Kami tidak akan meminta anda kata laluan anda dalam panggilan telefon atau e-mel yang tidak diminta.Sila log keluar dari akaun WisePerks.com anda dan tutup tetingkap penyemak imbas anda apabila anda telah selesai aktiviti anda.Ini memastikan orang lain tidak boleh mengakses maklumat dan akaun peribadi anda, jika anda menggunakan komputer awam atau berkongsi komputer anda dengan orang lain.Setiap kali WisePerks.com mengendalikan maklumat peribadi seperti yang dijelaskan di atas, tidak kira di mana ini berlaku, WisePerks.com mengambil langkah untuk memastikan maklumat anda dirawat dengan selamat dan mengikut Syarat Perkhidmatan dan Dasar Privasi yang berkaitan.Malangnya, tiada penghantaran data melalui Internet boleh dijamin 100% selamat.Akibatnya, semasa kami berusaha untuk melindungi maklumat peribadi anda, WisePerks.com tidak dapat memastikan atau menjamin keamanan maklumat yang anda kirimkan kepada kami atau dari produk atau perkhidmatan kami dalam talian, dan anda melakukannya dengan risiko anda sendiri.Sebaik sahaja kami menerima data anda, kami berusaha sebaik mungkin untuk memastikan keselamatannya dalam sistem kami.",

  "privacypolicy_topic9" : "Apa lagi yang perlu saya ketahui tentang privasi saya?",
  "privacypolicy_topic9ans" : "Perlu diingatkan bahawa bila-bila masa (dan di mana sahaja) anda memilih untuk mendedahkan maklumat peribadi dalam talian - contohnya melalui e-mel, laman web rangkaian sosial, blog, atau di kawasan sembang - maklumat itu boleh dikumpulkan dan digunakan oleh mana-mana pengguna lain.Jika anda menyiarkan maklumat peribadi secara dalam talian yang boleh diakses oleh orang ramai, anda mungkin menerima mesej yang tidak diminta dari pihak lain sebagai balasannya.Pada akhirnya, anda bertanggungjawab sepenuhnya untuk mengekalkan kerahsiaan kata laluan dan / atau sebarang maklumat akaun anda.Sila berhati-hati dan bertanggungjawab apabila anda berada dalam talian.",

  "privacypolicy_topic10" : "Bagaimanakah WisePerks.com melindungi privasi anak-anak saya?",
  "privacypolicy_topic10ans" : "WisePerks.com memperoleh kebenaran ibu bapa yang terdahulu di mana tinjauan memerlukan kanak-kanak untuk mengambil bahagian dan penyeliaan ibu bapa juga diperlukan untuk sebarang kaji selidik sedemikian dengan kanak-kanak berusia 6-15 tahun.Kami mengambil penjagaan khas untuk melindungi keselamatan dan privasi pengguna muda di laman web kami, dan kami menggalakkan anda untuk mengambil bahagian dalam pengalaman anak anda dalam talian.Kami tidak membenarkan sebarang pemasaran atau penyelidikan kanak-kanak dan topik-topik tertentu dilarang sama sekali dalam penyelidikan di kalangan kanak-kanak: Nama / nama akaun / E-mel atau alamat fizikal, Nombor telefon, Bangsa atau Etnik, Agama atau Peluang, Pendapatan isi rumah, , Status perkahwinan ibu bapa atau ahli keluarga lain, Perkara hubungan, Gred di sekolah atau kolej, Alkohol, Isu Seksual, Tembakau dan Senjata api.",

  "privacypolicy_topic11" : "Nota Khas kepada Pengguna Antarabangsa",
  "privacypolicy_topic11ansp1" : "Laman ini dihoskan di Amerika Syarikat.Sekiranya anda adalah Pengguna yang mengakses Laman ini dari Kesatuan Eropah, Asia, atau mana-mana rantau lain dengan undang-undang atau peraturan yang mengawal pengumpulan, penggunaan dan pendedahan data peribadi, yang berbeza dari undang-undang Amerika Syarikat, sila ambil perhatian bahawa anda memindahkan data peribadi anda kepada Amerika Syarikat yang tidak mempunyai undang-undang perlindungan data yang sama seperti EU dan kawasan lain, dan dengan menyediakan data peribadi anda yang anda berikan kepada:",
  "privacypolicy_topic11ansp2" : "Penggunaan data peribadi anda untuk kegunaan yang dikenal pasti di atas selaras dengan Dasar Privasi; dan pemindahan data peribadi anda ke Amerika Syarikat seperti yang dinyatakan di atas.ANDA MENGAKUKKAN BAHAWA UNDANG-UNDANG NEGARA UNIT BERSAMA MAKLUMAT ANDA DENGAN BANYAK YANG MUNGKIN SEBAGAI BERBEZA DARIPADA, DAN KURANG PERLINDUNGAN DARIPADA, RAWATAN YANG DIPERLUKAN DI BAWAH UNDANG-UNDANG NEGARA-NEGARA LAIN DAN JURISDIKAT.JIKA ANDA TIDAK INGINKAN MAKLUMAT ANDA YANG DIBENARKAN DENGAN AMERIKA SYARIKAT, ANDA TIDAK AKAN MENERBITKAN MAKLUMAT ANDA DENGAN US, ATAU MENGGUNAKAN LAMANYA.SEPENUHNYA YANG DISEDIAKAN OLEH UNDANG-UNDANG NEGARA DALAM YANG ANDA TERDAPATKAN, ANDA DENGAN MENGHARGAI KEPADA SEBARANG KANAN ANDA MUNGKIN MEMENUHI KAMI UNTUK MENGENALKAN MAKLUMAT PENGENALAN ANDA BERSETUJU DENGAN UNDANG-UNDANG NEGARA ATAU JURISDIKSI LAINNYA DENGAN AMERIKA SERIKAT.BAGAIMANA, PEMANTAUAN MUNGKIN TIDAK BOLEH MENJADI SELURUH DALAM NEGARA NEGARA, SEPERTI NEGARA-NEGARA UNDANG-UNDANG EROPA.SELANJUTNYA TIDAK SELESAI BERDASARKAN DI NEGARA YANG ANDA TERDAPATKAN, PENYAMPAIAN INI TIDAK AKAN BERLAKU KEPADA ANDA.",

  "privacypolicy_topic12" : "Perubahan dan Kemas Kini kepada Dasar Privasi ini",
  "privacypolicy_topic12ans" : "WiseWorks berhak, mengikut budi bicara kami, untuk mengubah, mengubah suai, menambah, atau mengalih keluar bahagian Dasar Privasi ini pada bila-bila masa untuk sebarang sebab.Setiap kali WiseWorks menjalankan hak semacam itu, anda akan diminta untuk menerima Dasar Privasi yang dikemas kini, dan jika anda gagal berbuat demikian, anda tidak lagi dapat mengakses atau menggunakan Laman ini.",

	"privacypolicy_topic13": "Nota Khas kepada mereka yang mencari butiran GDPR / CCPA",
	"privacypolicy_topic13ansp1": "Tolonglah",
	"privacypolicy_topic13ansp2": "tekan di sini",
	"privacypolicy_topic13ansp3": "untuk mengetahui lebih lanjut mengenai perincian pematuhan GDPR (Peraturan Perlindungan Data Umum) dan CCPA (California Consumer Privacy Act).",


  "privacypolicy_addinfo" : "Maklumat tambahan",
	"privacypolicy_sentto": "Sebarang pertanyaan lain mengenai perkara ini perlu dihantar kepada",



    "privacypolicyext_para1": "PLEASE READ THIS PRIVACY STATEMENT CAREFULLY",
    "privacypolicyext_para2": "WiseWorks menghormati privasi setiap pengguna, dan kami komited untuk bersikap telus dengan anda mengenai bagaimana kami menangani data peribadi. Dasar Privasi ini menerangkan bagaimana WiseWorks mengumpulkan, berkongsi, dan menggunakan data peribadi yang anda berikan kepada kami semasa menggunakan Laman Web WiseWorks atau berinteraksi dengan kami. Kami juga menawarkan beberapa bahagian untuk anda pelajari bagaimana anda dapat menggunakan hak dan pilihan privasi anda.",
    "privacypolicyext_para3": "Sila baca Dasar Privasi ini dengan teliti sebelum mengakses Laman Web kami dan menggunakan Perkhidmatan WiseWorks. Sekiranya anda mempunyai pertanyaan, sila hubungi kami di ",

    "privacypolicyext_topic1": "Gambaran keseluruhan",
    "privacypolicyext_topic2": "Penerimaan Dan Penggunaan",
    "privacypolicyext_topic3": "Siapa kita",
    "privacypolicyext_topic4": "Ringkasan Maklumat dan Penggunaan Peribadi",
    "privacypolicyext_topic5": "Tujuan Pengumpulan Data",
    "privacypolicyext_topic6": "Prinsip Perlindungan Data",
    "privacypolicyext_topic7": "Jenis Data Yang Kami Kumpulkan Dari Anda",
    "privacypolicyext_topic8": "Kuki dan suar web",
    "privacypolicyext_topic9": "Memberitahu Anda Mengenai Tinjauan Dan Projek Penyelidikan",
    "privacypolicyext_topic10": "Cara Kami Menggunakan Data Anda",
    "privacypolicyext_topic11": "Dengan Siapa Kami Berkongsi Data Anda",
    "privacypolicyext_topic12": "Subjek Data EEA dan Bukan EEA",
    "privacypolicyext_topic13": "Hak Perlindungan Data Anda",
    "privacypolicyext_topic14": "Dasar Kami Menuju Anak",
    "privacypolicyext_topic15": "Keselamatan Data",
    "privacypolicyext_topic16": "Terma dan syarat",
    "privacypolicyext_topic17": "Bidang kuasa",
    "privacypolicyext_topic18": "Perubahan pada Dasar Privasi kami",
    "privacypolicyext_topic19": "Hubungi Kami",

    "privacypolicyext_t1": "we",
    "privacypolicyext_t2": "us",
    "privacypolicyext_t3": "or",
    "privacypolicyext_t4": "our",

    "privacypolicyext_topic1ansp1": " menghormati hak privasi penggunanya dan memahami pentingnya melindungi dan menangani maklumat yang dikumpulkan mengenai anda berdasarkan undang-undang dan amalan terbaik. Notis Privasi ini ditulis untuk dipatuhi",
    "privacypolicyext_topic1ansp1_1": "Pasal 13 dan Pasal 14 Peraturan Perlindungan Data Umum UE, yaitu, Peraturan (EU) 2016/679 ('GDPR'), dan CCPA Akta Pengguna Privasi California.",
    "privacypolicyext_topic1ansp2": "Pemberitahuan Privasi ini berlaku untuk maklumat peribadi yang kami proses berkaitan dengan orang yang mempunyai:",
    "privacypolicyext_topic1ansp2_l1": "Daftar untuk akaun dengan kami;",
    "privacypolicyext_topic1ansp2_l2": "bersetuju untuk menerima maklumat pemasaran dari kami;",
    "privacypolicyext_topic1ansp2_l3": "mengambil bahagian dalam tinjauan;",
    "privacypolicyext_topic1ansp2_l4": "hubungi kami untuk pertanyaan dan sokongan",
    "privacypolicyext_topic1ansp3": 'Dasar Privasi ini juga menjelaskan hak apa yang anda miliki mengenai data anda, bagaimana anda memberikan persetujuan anda, dan bagaimana anda dapat menarik balik persetujuan anda. WiseWorks (bersama dengan gabungannya, "Syarikat") memiliki dan mengendalikan beberapa laman web, aplikasi mudah alih, dan perkhidmatan interaktif yang berbeza, termasuk tanpa batasan Wisesample.com, Flapbucks.com, WisePerks.com, dan lain-lain (secara kolektif, "Laman WiseWorks" "). Dasar privasi ini ("Kebijakan Privasi") berlaku untuk Situs WiseWorks dan semua fitur, aplikasi mudah alih, e-mel, perkhidmatan dalam talian, dan fungsi lain, baik diakses melalui komputer, peranti mudah alih, atau sebaliknya, dan perkhidmatan yang tersedia di atau melalui Laman dan Ciri WiseWorks ("Perkhidmatan"). Dasar Privasi ini hanya merangkumi maklumat yang dikumpulkan oleh Syarikat melalui Laman dan Ciri WiseWorks dan Perkhidmatan kami, dan tidak merangkumi maklumat yang diterima atau dikumpulkan di luar talian oleh Syarikat (kecuali dinyatakan secara khusus) atau dikumpulkan oleh laman web atau syarikat yang tidak berkaitan dengan mana kami mungkin sediakan pautan.',

    "privacypolicyext_topic2ansp1_1": "Dasar Privasi ini merangkumi",
    "privacypolicyext_topic2ansp1_2": " dan anak syarikatnya, termasuk",
    "privacypolicyext_topic2ansp1_3": "secara kolektif ",
    "privacypolicyext_topic2ansp1_4": "Dengan menggunakan laman web kami, anda menandakan penerimaan terhadap syarat-syarat Dasar Privasi ini, yang dianggap sebagai persetujuan rasmi anda untuk memproses maklumat peribadi anda, persetujuan anda untuk pengumpulan, penggunaan, dan pendedahan maklumat peribadi anda seperti yang dijelaskan lebih lanjut di bawah ini. Kami mungkin meminta anda memberikan pendedahan atau maklumat tambahan mengenai amalan pemprosesan data Perkhidmatan tertentu. Pemberitahuan ini dapat melengkapkan atau memperjelas amalan privasi kami atau memberi anda pilihan lain tentang bagaimana kami memproses data anda.",
    "privacypolicyext_topic2ansp2": "Sekiranya anda tidak selesa dengan aspek Dasar Privasi ini. Anda harus segera menghentikan sebarang akses ke laman web WiseWorks dan laman web kami.",

    "privacypolicyext_topic3ansp1_1": " adalah syarikat yang diperbadankan dan didaftarkan di Kanada (# 10369675). Pejabat berdaftar kami adalah di ",
    "privacypolicyext_topic3ansp1_2": "Kami menyediakan perkhidmatan panel, untuk industri penyelidikan pasaran dalam talian. Kami membolehkan pengguna atau peserta yang memenuhi syarat kelayakan kami untuk mendaftar untuk akaun panel pengguna dan mengambil tinjauan untuk mendapat mata atau insentif lain.",
    "privacypolicyext_topic3ansp2": "Perkhidmatan kami tersedia di beberapa negara, serta di California (AS), Kanada, Jerman, India, dll. Perkhidmatan ini tidak bertujuan untuk digunakan oleh Pengguna di bawah usia 18 tahun; anda mesti berumur 18 tahun ke atas untuk menggunakan laman web dan perkhidmatan kami.",

    "privacypolicyext_topic4ansp1": "We may collect or obtain Personal Data about you, and this information may be stored or processed within the United States Region through the following methods: ",
    "privacypolicyext_topic4ansp1_l1": "Terus dari anda (mis., Di mana anda menghubungi kami); semasa hubungan kami dengan anda (mis., jika anda menggunakan perkhidmatan kami);",
    "privacypolicyext_topic4ansp1_l2": "semasa anda melayari laman web kami; atau menggunakan mana-mana bahagian laman web; ",
    "privacypolicyext_topic4ansp2": "Kami juga mungkin menerima Maklumat Peribadi mengenai anda dari pihak ketiga.",

    "privacypolicyext_topic5ansp1": "Berikut adalah contoh bagaimana kami menggunakan Maklumat Peribadi anda:",
    "privacypolicyext_topic5ansp1_l1": "Penyediaan Perkhidmatan",
    "privacypolicyext_topic5ansp1_l2": "Menawarkan dan Memperbaiki laman web",
    "privacypolicyext_topic5ansp1_l3": "Tinjauan",
    "privacypolicyext_topic5ansp1_l4": "Komunikasi",
    "privacypolicyext_topic5ansp1_l5": "Penglibatan Pengguna",
    "privacypolicyext_topic5ansp1_l6": "Pemasaran",
    "privacypolicyext_topic5ansp1_l7": "Penjanaan plumbum",
    "privacypolicyext_topic5ansp1_l8": "Pematuhan Undang-Undang",

    "privacypolicyext_topic6ansp1": "Kami mengumpulkan, memproses, dan menggunakan data pribadi (berdasarkan definisi Artikel 13 dan Pasal 14 Peraturan Perlindungan Data Umum UE, yaitu, Peraturan (EU) 2016/679 ('GDPR'), dan Akta Privasi Pengguna California CCPA) untuk berikan perkhidmatan kami. Data peribadi bermaksud sebarang maklumat yang membolehkan kami mengenali anda, secara langsung atau tidak langsung, seperti nama, e-mel, alamat, maklumat kesihatan, sebarang bentuk nombor pengenalan, atau satu atau lebih faktor yang khusus untuk anda atau identiti penerima anda.",
    "privacypolicyext_topic6ansp2": "Kami berkomitmen untuk mematuhi undang-undang perlindungan data yang berlaku dan akan memastikan bahawa data peribadi adalah:",
    "privacypolicyext_topic6ansp2_l1": "Digunakan secara sah, adil, dan telus;",
    "privacypolicyext_topic6ansp2_l2": "Dikumpulkan hanya untuk tujuan yang sah yang telah kami jelaskan kepada anda dan tidak digunakan dengan cara yang tidak sesuai dengan tujuan tersebut;",
    "privacypolicyext_topic6ansp2_l3": "Berkaitan dengan tujuan yang telah kami sampaikan kepada anda dan hanya terhad kepada tujuan tersebut;",
    "privacypolicyext_topic6ansp2_l4": "Tepat dan terkini;",
    "privacypolicyext_topic6ansp2_l5": "Hanya disimpan selama yang diperlukan untuk tujuan yang telah kami maklumkan kepada anda;",

    "privacypolicyext_topic7ansp1": "Kami mungkin mengumpulkan dan memproses maklumat peribadi berikut mengenai anda:",
    "privacypolicyext_topic7ansp1_l1title": "Maklumat Profil Peribadi.",
    "privacypolicyext_topic7ansp1_l1": "Sekiranya anda mendaftar akaun dengan kami, kami akan mengumpulkan nama depan dan belakang anda, alamat e-mel anda, dan kata laluan yang anda pilih.",
    "privacypolicyext_topic7ansp1_l2title": "Maklumat perhubungan.",
    "privacypolicyext_topic7ansp1_l2": "Sekiranya anda memilih untuk menerima tinjauan melalui pesanan teks atau media pesanan lain, kami juga akan mengumpulkan nombor telefon bimbit anda atau maklumat hubungan yang berkaitan.",
    "privacypolicyext_topic7ansp1_l3title": "Data Soal Selidik.",
    "privacypolicyext_topic7ansp1_l3": "Soal selidik profil awal mengumpulkan maklumat mengenai latar belakang, pendidikan, status kerja, tabiat pengguna, dan, secara pilihan, kesihatan anda. Kami mengumpulkan maklumat mengenai sebarang kemas kini yang anda buat pada maklumat profil anda.",
    "privacypolicyext_topic7ansp1_l4title": "Data Pendapat.",
    "privacypolicyext_topic7ansp1_l4": "Semasa peluang penyelidikan, kami mengumpulkan maklumat yang anda berikan berkaitan dengan pendapat, sikap, pengalaman, dan penggunaan produk atau perkhidmatan tertentu. Kadang-kadang kami mungkin mengumpulkan penyerahan video, audio atau fotografi yang anda buat semasa peluang penyelidikan.",
    "privacypolicyext_topic7ansp1_l5title": "Data Pengalaman Pengguna.",
    "privacypolicyext_topic7ansp1_l5": "Kami mengumpulkan maklumat mengenai log masuk anda, komen mengenai catatan blog, penilaian, tinjauan pendapat, halaman web yang dilihat.",
    "privacypolicyext_topic7ansp1_l6title": "Maklumat Ganjaran.",
    "privacypolicyext_topic7ansp1_l6": "Sebarang maklumat peribadi yang mungkin kami perlukan untuk memberi anda hadiah atau hadiah atau memantau penggunaan aktiviti Panel dan Tinjauan kami.",
    "privacypolicyext_topic7ansp1_l7title": "Data Surat-menyurat.",
    "privacypolicyext_topic7ansp1_l7": "Sebarang surat-menyurat yang anda hantarkan kepada kami.",
    "privacypolicyext_topic7ansp1_l8title": "Data Penggunaan.",
    "privacypolicyext_topic7ansp1_l8": "Kami juga mengumpulkan data penggunaan mengenai anda setiap kali anda berinteraksi dengan perkhidmatan kami. Ini mungkin merangkumi laman web mana yang anda lawati, apa yang anda klik semasa melakukan tindakan tersebut, dan sebagainya. Selain itu, kami menyimpan fail log digital yang merakam data setiap kali peranti mengakses pelayan kami. Fail log mengandungi data mengenai sifat setiap akses. Kami mengumpulkan data dari perangkat dan aplikasi yang Anda gunakan untuk mengakses perkhidmatan kami, seperti alamat IP Anda, jenis penyemak imbas, penyedia perkhidmatan internet (ISP), halaman rujukan / keluar, sistem operasi, cap tarikh / waktu, dan data aliran klik.",
    "privacypolicyext_topic7ansp2": "Kami juga dapat menyimpulkan lokasi geografi anda berdasarkan alamat IP anda. Sekiranya anda tiba di perkhidmatan WiseWorks dari sumber luaran (seperti pautan di laman web lain, yang terdapat dalam aplikasi e-mel atau pemesejan), kami mungkin menggunakan maklumat ini, untuk menganalisis tren, mentadbir laman web, mengesan pengguna, dan mengumpulkan maklumat demografi mengenai pangkalan pengguna kami secara keseluruhan.",

    "privacypolicyext_topic8ansp1": "Kuki adalah fail teks yang mengandungi sejumlah kecil maklumat yang dihantar ke penyemak imbas anda semasa anda melayari laman web. Kuki kemudian dihantar kembali ke laman web asal pada setiap lawatan berikutnya, atau ke laman web lain yang mengenalinya. Kuki adalah teknologi yang sangat berguna dan melakukan banyak pekerjaan yang berbeza. Web Beacon adalah gambar grafik yang telus, biasanya tidak lebih besar dari 1 - piksel x 1 piksel, yang diletakkan di laman web atau dalam e-mel yang digunakan untuk memantau tingkah laku pengguna yang mengunjungi laman web atau menghantar e-mel. Ia sering digabungkan dengan kuki",
    "privacypolicyext_topic8ansp2": "Kami mungkin mengumpulkan maklumat melalui penggunaan kuki, suar web, atau teknologi yang didorong oleh analisis yang serupa.",
    "privacypolicyext_topic8ansp3_title": "Kuki apa yang kita gunakan?",
    "privacypolicyext_topic8ansp3": "Kuki yang kami gunakan termasuk dalam kategori berikut:",
    "privacypolicyext_topic8ansp3_l1title": "Kuki yang kami gunakan termasuk dalam kategori berikut:",
    "privacypolicyext_topic8ansp3_l1": "Ini adalah kuki yang diperlukan untuk pengoperasian laman web kami. Mereka termasuk, misalnya, kuki yang membolehkan anda masuk ke kawasan selamat di laman web kami.",
    "privacypolicyext_topic8ansp3_l2title": "Kuki analisis / prestasi.",
    "privacypolicyext_topic8ansp3_l2": "Mereka membolehkan kita mengenali dan mengira jumlah pelawat dan untuk melihat bagaimana pengunjung bergerak di sekitar laman web kita semasa mereka menggunakannya. Ini membantu kita untuk memperbaiki cara laman web kita berfungsi, misalnya, dengan memastikan pengguna dapat mencari apa yang mereka cari dengan mudah.",
    "privacypolicyext_topic8ansp3_l3title": "Kuki fungsi.",
    "privacypolicyext_topic8ansp3_l3": "Ini digunakan untuk mengenali anda semasa anda kembali ke laman web kami. Ini membolehkan kami memperibadikan kandungan kami untuk anda, menyambut anda dengan nama, dan mengingati pilihan anda.",
    "privacypolicyext_topic8ansp3_l4title": "Kuki penyasaran.",
    "privacypolicyext_topic8ansp3_l4": "Kuki ini merekodkan lawatan anda ke laman web kami, halaman yang telah anda lawati, dan pautan yang anda ikuti. Kami akan menggunakan maklumat ini untuk menjadikan laman web kami dan iklan yang dipaparkan di atasnya lebih relevan dengan minat anda. Kami juga boleh berkongsi maklumat ini dengan pihak ketiga untuk tujuan ini.",
    "privacypolicyext_topic8ansp4": "Kami menggunakan pelbagai jenis kuki (termasuk beberapa yang kami letakkan dan yang lain yang kami izinkan pihak ketiga meletakkan di laman web kami) untuk beberapa tujuan. Maklumat yang dikumpulkan oleh kuki membolehkan kami memantau berapa banyak orang yang menggunakan perkhidmatan kami dan interaksi mereka dengan perkhidmatan lain, berapa banyak orang yang membuka e-mel kami, dan untuk tujuan apa tindakan ini diambil. Kuki juga digunakan untuk memudahkan log masuk pengguna, sebagai alat bantu navigasi, dan sebagai pemasa sesi, dan untuk menyekat penggunaan alat dan perkhidmatan kami di bawah umur. Kami tidak menghubungkan maklumat bukan peribadi dari kuki ke maklumat peribadi tanpa kebenaran. Sekiranya atas sebab apa pun anda tidak mahu kami melacak penggunaan laman web anda dengan menggunakan kuki, anda boleh memilih untuk tidak ikut menggunakan tetapan penyemak imbas anda dan juga dapat memilih pilihan &ldquo;Jangan Lacak&rdquo; (untuk maklumat lebih lanjut sila rujuk penyemak imbas anda ). Sebilangan besar penyemak imbas menawarkan arahan mengenai cara menetapkan semula penyemak imbas untuk menolak kuki di bahagian &ldquo;Bantuan&rdquo; pada bar alat.",
    "privacypolicyext_topic8ansp5": "Kami menggunakan suar web untuk mengumpulkan maklumat mengenai penggunaan perkhidmatan kami. Maklumat yang dikumpulkan oleh web beacon membolehkan kami memantau berapa banyak orang menggunakan perkhidmatan kami, berapa banyak orang yang membuka e-mel kami dan menentukan untuk tujuan apa tindakan ini diambil. Beacon web kami tidak digunakan untuk mengesan aktiviti di luar perkhidmatan kami.",
    "privacypolicyext_topic8ansp6_title": "Bagaimana saya menukar tetapan kuki saya?",
    "privacypolicyext_topic8ansp6_1": "Sebilangan besar penyemak imbas web membenarkan kawalan sebahagian besar kuki melalui tetapan penyemak imbas. Untuk mengetahui lebih lanjut mengenai kuki, termasuk cara melihat kuki apa yang telah ditetapkan, kunjungi www.aboutcookies.org atau www.allaboutcookies.org.",
    "privacypolicyext_topic8ansp6_2": "Untuk mengetahui cara menguruskan kuki pada penyemak imbas popular:",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "Apabila anda menyertai WiseWorks, kami mungkin berkomunikasi dengan anda melalui e-mel, telefon, atau perkhidmatan pesanan (bergantung pada pilihan anda) mengenai keahlian anda. Kadang-kadang, apabila kami dapat berkomunikasi dengan anda, termasuk yang berikut:",
    "privacypolicyext_topic9ansp1_l1": "Peluang penyelidikan (seperti tinjauan, tinjauan pendapat, kumpulan fokus, dll)",
    "privacypolicyext_topic9ansp1_l2": "Hasil kajian",
    "privacypolicyext_topic9ansp1_l3": "Surat berita panelis",
    "privacypolicyext_topic9ansp2": "Projek kami boleh memberi tumpuan kepada pelbagai topik. Biasanya kami akan menjemput anda untuk mengambil bahagian dalam projek dengan menghantar e-mel, dan anda mengenai peluang. Pada masa itu, kami akan menerangkan topik luas tinjauan, kemungkinan jangka masa atau penglibatan yang diperlukan, dan ganjaran yang ditawarkan. Maka terpulang kepada anda untuk memutuskan sama ada anda mahu mengambil bahagian atau tidak. Penyertaan tidak wajib dilakukan. Anda selalu boleh berhenti pada bila-bila masa walaupun kami lebih suka anda menyelesaikan tugas sebaik sahaja anda memulakannya dan kami tidak Biasanya tidak membayar insentif untuk tugas yang tidak lengkap.",
    "privacypolicyext_topic9ansp3": "Kami mengharapkan anda, setiap saat, memberikan maklumat yang benar, tepat, terkini dan lengkap seperti yang diminta.",


    "privacypolicyext_topic10ansp1": "We will only use your personal data when the law allows us to.Most commonly, we will use your personal data in the following circumstances, but for more information about the types of lawful basis that we will rely on to process your data, please click here.:",
    "privacypolicyext_topic10ansp1_l1": "Provide our products and services to you",
    "privacypolicyext_topic10ansp1_l2": "find the people who are most suitable for each research opportunity;",
    "privacypolicyext_topic10ansp1_l3": "reduce the number of questions we need to ask in surveys;",
    "privacypolicyext_topic10ansp1_l4": "conduct further analysis on research data we have already collected;",
    "privacypolicyext_topic10ansp1_l5": "in aggregated form to help us explain our research capabilities to our clients.",
    "privacypolicyext_topic10ansp1_l6": "invite you to research opportunities such as surveys, focus groups, online communities, passive monitoring of the websites you visit, etc.",
    "privacypolicyext_topic10ansp1_l7": "send you incentive rewards that you have earned,",
    "privacypolicyext_topic10ansp1_l8": "request you to update your profile,",
    "privacypolicyext_topic10ansp1_l9": "Invite you to participate in surveys about our products or services",
    "privacypolicyext_topic10ansp1_l10": "Monitor, detect and prevent fraud, improper activity and ensure WiseWorks's policy compliance",
    "privacypolicyext_topic10ansp1_l11": "Conduct research including secondary and follow - on research",
    "privacypolicyext_topic10ansp1_l12": "Evaluate how you and other users interact with our online services",
    "privacypolicyext_topic10ansp1_l13": "Protect WiseWorks and our users",
    "privacypolicyext_topic10ansp1_l14": "Conduct audits related to our current interactions with you",
    "privacypolicyext_topic10ansp1_l15": "Debug the site and service to identify and repair errors that impair existing intended functionality",
    "privacypolicyext_topic10ansp1_l16": "Defend ourselves in litigation and investigations and prosecute litigations",
    "privacypolicyext_topic10ansp1_l17": "To comply with legal and regulatory obligations.",


    "privacypolicyext_topic11ansp1": "WiseWorks only shares your personal information with third parties under the circumstances described below.We do not sell or otherwise disclose personal information we collect about you for monetary or other valuable consideration.",
    "privacypolicyext_topic11ansp1_l1title": "Authorized vendors:",
    "privacypolicyext_topic11ansp1_l1": "We may share your data with third - party vendors that help us operate our website, process payments, and deliver our services and also comply with your instructions and our contractual obligations.These include payment processors, content delivery networks(CDNs), cloud - based hosting services, monitoring services, email service providers, quality assurance and testing vendors, fraud and abuse prevention vendors, customer relations management(CRM), vendors",
    "privacypolicyext_topic11ansp1_l2title": "Advertising and Analytics Partner:",
    "privacypolicyext_topic11ansp1_l2": "We may share usage data with third - party advertisers and analytics providers through cookies and other similar technologies.These third parties may collect information sent by your computer, browser, or mobile device in response to a request for content, such as unique identifiers, your IP address, or other information about your computer or device.",
    "privacypolicyext_topic11ansp1_l3title": "Business Partners:",
    "privacypolicyext_topic11ansp1_l3": "We sometimes partner with third parties to offer you events, promotions, contests, and other programs.To participate in these offers, you may be required to provide your email address and additional personal information.We will only share your email address and other personal information with a business partner when you expressly authorize us to do so.",
    "privacypolicyext_topic11ansp1_l4title": "Certain legal obligations: ",
    "privacypolicyext_topic11ansp1_l4": "WiseWorks may be required to disclose personal information if directed by a court of law or other governmental entities.Without limiting the preceding, we reserve the right to disclose such information where we have a good faith basis to believe that such action is necessary to: comply with applicable laws, regulations, court orders, government, and law enforcement agencies' requests, protect and defend WiseWorks's or a third party's rights and property, or the safety of WiseWorks, our users, our employees, or others, prevent, detect, investigate and take measures against criminal activity, fraud, and misuse or unauthorized use of our Website and to enforce our Terms of Use or other agreements or policies, to the extent permitted by law, we will attempt to give you prior notice before disclosing your information in response to such a request.",
    "privacypolicyext_topic11ansp1_l5title": "With Your Consent:",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks may send your Personal Information to a third party when you consent to do so or in your direction.For example, this may occur when we complete a transaction at your request.",
    "privacypolicyext_topic11ansp1_l6title": "With Service Providers:",
    "privacypolicyext_topic11ansp1_l6": "We may share personal information with vendors, consultants, payment processors, and other service providers that provide us with specific services and unique process information on our behalf.These services may include providing customer support, performing business and sales analysis, supporting our website functionality, facilitating payment processing, supporting contests, surveys, and other features offered on our Website.These service providers are not permitted to use your personal information for any other purpose.Their use of such personal data will be subject to appropriate confidentiality and security measures.",
    "privacypolicyext_topic11ansp1_l7title": "With Affiliates:",
    "privacypolicyext_topic11ansp1_l7": "We may share personal information with some of our partners, affiliates, and other companies under common control and ownership, subject to the terms of this Privacy Policy.We may also share your personal information in connection with a substantial corporate transaction, such as a sale of our business, a divestiture, merger, consolidation, or asset sale, in the event of bankruptcy, or preparation for any of these events.Any other entity which buys us or becomes part of our business will have the right to continue to use your personal information, but only in the manner set out in this Privacy Policy unless you agree otherwise.",

    "privacypolicyext_topic12ansp1_title": "Legal Bases for Processing your Information:",
    "privacypolicyext_topic12ansp1": "For individuals who are located in the European Economic Area, United Kingdom, or Switzerland (collectively “EEA Residents”), data collected from you may be transferred or processed in the United States (collectively “Data Subjects”). When their data is collected, we rely on legal bases for processing your information. We generally only process your data where we are required to, where the processing is necessary to provide our services to you (or to take steps at your request before entering into a contract with you). ",
    "privacypolicyext_topic12ansp2_title": "Shine the Light (For California Citizens) CCPA",
    "privacypolicyext_topic12ansp2_1": "California's \"Shine the Light\" law permits customers in California to request specific details about how certain types of their information are shared with third parties and, in some cases, affiliates for those third parties' and affiliates' direct marketing purposes. Under the law, a business should either provide California customers certain information upon request or permit California customers to opt-out of this type of sharing.",
    "privacypolicyext_topic12ansp2_2": 'To exercise a Shine the Light request, please contact us.You must put the statement "Your California Privacy Rights" in the body of your request, as well as your name, street address, city, state, and zip code.Please provide enough information to determine if this applies to you in the body of your request.Please note that we will not accept inquiries via telephone, email, and we are not responsible for notices that are not labeled or sent properly or that do not have complete information.',
    "privacypolicyext_topic12ansp3_title": "Your Nevada Privacy Rights",
    "privacypolicyext_topic12ansp3": 'If you are a Nevada resident, you have the right to opt- out of the sale of specific Personal Data to third parties who intend to license or sell that Personal Data.You can exercise this right by contacting us here or email us with the subject line "Nevada Do Not Sell Request" and providing us with your name and the email address associated with your account.',

    "privacypolicyext_topic13ansp1": "We enable you to make numerous choices about your data: Below is the following data protection rights you have when you use our services.",
    "privacypolicyext_topic13ansp1_l1": "You may choose not to provide us with certain information.",
    "privacypolicyext_topic13ansp1_l2": "Suppose you wish to access, correct, update or request deletion of your personal information.In that case, you can do so at any time by contacting us through the customer services portal or at the information provided at the bottom of this Privacy Policy.",
    "privacypolicyext_topic13ansp1_l3": "If you request access to your data, we will confirm whether we hold your information and provide you with a copy, explaining how we process it and why, how long we store it for, and your rights associated with it.",
    "privacypolicyext_topic13ansp1_l4": "If you request the deletion of your personal information, we will erase it.Please note that we will need to retain any information that we require to fulfill our legal obligations or establish, exercise, or defend a legal claim.",
    "privacypolicyext_topic13ansp1_l5": "You can object to your personal information processing, ask us to restrict the processing of your personal information, or request portability of your personal information.Again, you can exercise these rights by contacting us through the customer services portal or at the information provided at the bottom of this Privacy Policy.",
    "privacypolicyext_topic13ansp1_l6": 'You have the right to opt - out of the email marketing communications we send you at any time.You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails we send you.',
    "privacypolicyext_topic13ansp1_l7": "Opting Out Of Messages from Us: To opt - out of any future promotional messages from us, you should unsubscribe in the body of the promotional message sent to you(found at the bottom of the email) or send an unsubscribe request to us.We will process your request within a reasonable time after receipt.",
    "privacypolicyext_topic13ansp1_l8": "Similarly, if we have collected and processed your personal information with your consent, then you can withdraw your consent at any time.Withdrawing your consent will not affect the lawfulness of any processing we conducted before your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.",
    "privacypolicyext_topic13ansp1_l9": "You have the right to complain to a data protection authority about our collection and use of your personal information.Please contact us for more information, and we will send you a link or our data protection officer's address.",
    "privacypolicyext_topic13ansp1_l10": "The easiest way to submit your request to us is through our Customer support email found at the bottom of this Privacy Policy.We respond to all requests we receive from individuals wishing to exercise their data protection rights under applicable data protection laws.",

    "privacypolicyext_topic14ansp1": "The website and services are not directed to children under thirteen(18) years of age.WiseWorks complies with the Children's Online Privacy Protection (COPPA) Act. We do not knowingly request to collect personal information from any person under the age of 18.  Please notify us if you know of any individuals under the age of 18 using our website to prevent them access to our Services.",

    "privacypolicyext_topic15ansp1": "We are committed to maintaining your data's security, having measures in place to protect against the loss, misuse, and alteration of the data under our control. We employ modern and secure techniques to protect our systems from intrusion by unauthorized individuals, and we regularly upgrade our security as better methods become available. Our data centers and our partners utilize new physical security measures to prevent unauthorized access to the facility. ",
    "privacypolicyext_topic15ansp2": "Please be aware that no security measures are perfect or impenetrable.Therefore, although we use industry- standard practices to protect your privacy, we cannot (and do not) guarantee the absolute security of personal data.",


    "privacypolicyext_topic16ansp1": "To learn more about our terms and conditions, please visit our Terms of Service page, which establishes the use, disclaimer, and limitations of liability governing our website's usage.",

    "privacypolicyext_topic17ansp1": "The United States of America laws govern this privacy policy and terms of service and other related services.",

    "privacypolicyext_topic18ansp1": "WiseWorks continually strives to implement new technologies and processes to protect you better, your privacy, and your use of the WiseWorks service.As a result, changes to this privacy policy may be made by WiseWorks from time to time.In no event will these changes result in the degradation of any security measures designed to protect you from unauthorized disclosure.",

    "privacypolicyext_topic19ansp1": "If you have any questions about this privacy policy, please contact us at ",






  "faq_faq" : "Soalan Lazim",
  "faq_getans" : "Dapatkan jawapan kepada semua soalan anda.",

  "faq_ques1" : "Apa itu WisePerks?",
  "faq_ans1" : "Kami gembira anda bertanya.WisePerks adalah tapak tinjauan dalam talian.Banyak jenama dan syarikat sedang mencari responden untuk memberi pendapat dan menerima hadiah sebagai balasan.WisePerks adalah satu platform sedemikian di mana anda boleh memberikan pendapat anda dan mendapat ganjaran untuknya juga.",

  "faq_ques2" : "Adakah ia percuma untuk menyertai?",
  "faq_ans2" : "Ya, ia bebas untuk menyertai.Sebenarnya, jika seseorang meminta anda untuk menyertai bayaran, kami ingin anda melaporkannya kepada kami.",

  "faq_ques3" : "Bolehkah saya merujuk kawan saya?",
  "faq_ans3" : 'Ya, anda boleh menjemput rakan dan rakan sekerja untuk menyertai dan juga mendapat ganjaran untuknya. Sila klik pautan "Rujuk Rakan" di papan pemuka anda untuk mendapatkan semua butiran.',

  "faq_ques4" : "Apa yang saya peroleh?",
  "faq_ans4" : "Sekarang dalam model baru kami, anda boleh mendapat mata dalam pelbagai cara selain daripada hanya dengan mengambil kaji selidik. Anda mendapat mata: mendaftar dengan kami, dengan mengesahkan akaun e-mel anda, untuk melengkapkan profil anda, sudah tentu untuk melengkapkan mana-mana kajian dan untuk mengejutkan anda bahkan bagi kaji selidik yang tidak layak/terbatal. Jadi kita mempunyai semua sebab-sebab untuk anda bermula bukan?",

  "faq_ques5" : "Bagaimanakah saya menjadi ahli WisePerks.com?",
  "faq_ans5" : 'Buka WisePerks.com pada pelayar anda.Buat log masuk keahlian dengan mengklik tab "Daftar".Cukup ikuti langkah-langkah dan ia akan membimbing anda sepanjang proses.Anda perlu mengesahkan niat anda untuk menyertai dengan mengklik pada e-mel pengesahan yang dihantar oleh kami kepada e-mel anda yang disediakan.',

  "faq_ques6" : "Di manakah e-mel pengesahan yang anda sebutkan tadi?",
  "faq_ans6p1" : "E-mel pengesahan akan dihantar secara automatik kepada alamat e-mel yang anda berikan semasa mendaftar. Sekiranya anda tidak melihat bahawa e-mel dalam Peti Masuk anda, Sila periksa spam atau ringan folder. Untuk mengelakkan sebarang e-mail pada masa hadapan daripada kami dan kajian menjemput/mesej daripada mencapai folder spam anda, Sila tambah",
  "faq_ans6p2" : "ke senarai penghantar anda diluluskan atau sebagai orang hubungan dalam peti mel anda. Jika anda masih tidak menjumpai bahawa e-mel pengesahan, sila log masuk ke akaun saya, mengesahkan bahawa alamat e-mel anda dengan kami adalah betul, dan meminta e-mel pengesahan yang lain.",

  "faq_ques7" : "Bagaimanakah saya mengambil kaji selidik?",
  "faq_ans7" : 'Sebaik sahaja anda telah log masuk selepas memasukkan nama pengguna dan kata laluan anda, anda sampai ke papan pemuka anda dan boleh melihat semua kaji-selidik yang anda boleh. Kami juga menghantar pautan Ukur ke ID emel berdaftar anda. Anda boleh menggunakan pautan terus daripada peti mel anda.',

  "faq_ques8" : "Bagaimana rahsia adalah respons saya, data saya?",
  "faq_ans8" : "Bagi WisePerks, mengekalkan privasi dan kerahsiaan pengguna adalah keutamaan.Pelanggan kami hanya berminat dengan apa yang anda fikirkan, sebagai pengguna, atau bagaimana perasaan anda tentang sesuatu.Kami di WisePerks, tidak pernah berkongsi / menyampaikan apa-apa maklumat peribadi kepada pelanggan kami, dan tidak ada tinjauan WisePerks juga tidak menjual atau menyewa sebarang maklumat kepada pihak ketiga.",

  "faq_ques9" : "Berapa lamakah tinjauan ini?",
  "faq_ans9" : "Kebanyakan tinjauan memerlukan hanya 10 hingga 15 minit, atau lebih rendah.Walau bagaimanapun, dari semasa ke semasa, kami mungkin mempunyai tinjauan lebih lama.Dengan setiap kaji selidik, kami memberitahu anda terlebih dahulu berapa lama, tetapi perlu diingat, tinjauan lebih lama mempunyai ganjaran yang lebih tinggi juga.",

  "faq_ques10" : "Bolehkah sesiapa mengambil tinjauan WisePerks?",
  "faq_ans10" : "Anda mesti berusia 13 tahun ke atas untuk mendaftar dengan WisePerks.Walaupun kadang-kadang kami akan menjemput anda dalam kaji selidik untuk menyertai ahli keluarga yang lebih muda.",

  "faq_ques11" : "Bagaimanakah anda tahu saya akan layak untuk membuat tinjauan?",
  "faq_ans11" : "Algoritma kami sepadan dengan maklumat profil anda dengan tinjauan yang tersedia untuk memastikan anda mendapat tinjauan yang paling sesuai.Apabila tinjauan bermula, biasanya beberapa soalan pertama menentukan sama ada anda adalah responden pelanggan kami sedang mencari kaji selidik tersebut.Kami mempunyai pelbagai survei yang luas yang mencari pelbagai jenis orang (contohnya, orang yang menonton filem secara teratur, bermain sukan tertentu, wanita dengan anak-anak, dan lain-lain) dan tinjauan baru datang setiap hari!",

  "faq_ques12" : "Apakah kewajipan saya untuk WisePerks?",
  "faq_ans12" : "Satu-satunya perkara yang kami harapkan daripada anda adalah untuk memberi pendapat dan fikiran anda dengan jujur, kerana pelanggan mencari jawapan yang jujur ​​dan benar untuk memperbaiki produk dan perkhidmatan mereka.Sila berikan maklumat yang betul semasa mendaftar sebagai ahli dan melakukan kemas kini apa-apa perubahan yang berlaku.",

  "faq_ques13" : "Mengapa saya perlu memberi maklumat mengenai isi rumah, teknologi atau produk saya yang saya beli?",
  "faq_ans13" : "Untuk setiap tinjauan, kami mencari profil responden tertentu.Jika kita tahu dari maklumat profil anda bahawa maklumat anda sepadan dengan keperluan pelanggan, bingo!Kami hampir pasti akan memastikan bahawa anda dapat menyelesaikan tinjauan dengan jayanya dan mendapat ganjaran untuknya.Ini pada dasarnya meningkatkan peluang anda untuk dipilih untuk tinjauan.",

  "faq_ques14" : "Bilakah saya akan menerima Kad Hadiah selepas melengkapkan kaji selidik WisePerks?",
  "faq_ans14" : "Perkara-perkara yang anda perolehi selepas berjaya menyiapkan satu kaji selidik yang terkumpul di dalam baldi anda. Apabila perkara ini direalisasikan anda boleh menebus.",

  "faq_ques15" : "Apakah Akaun Saya?",
  "faq_ans15" : "Hanya cara mudah untuk melihat maklumat peribadi anda, membuat perubahan seperti yang diperlukan, lihat aktiviti tinjauan anda, hadiah yang diperoleh, semuanya dalam satu tempat.",

  "faq_ques16" : "Bagaimanakah saya membatalkan akaun saya?",
  "faq_ans16" : "Dengan begitu banyak akan datang menemui anda, kami yakin anda tidak mahu melakukannya. Walau bagaimanapun, pada papan pemuka anda--> pergi ke seting--> urus akaun--> menyahaktifkan akaun",

  "faq_ques17" : "Bagaimana sistem mata berfungsi?",
  "faq_ans17p1" : "Setiap tinjauan adalah untuk bilangan mata tertentu. Setelah berjaya menyelesaikan satu tinjauan, perkara-perkara ini akan ditambah kepada pendapatan anda. Anda boleh menebusnya sebaik sahaja anda mencapai ambang minimum.",
  "faq_ans17p2" : "Dalam beberapa kes disebabkan oleh beberapa cabaran yang tidak diduga dengan sambungan atau kerosakan, respons anda mungkin tidak dapat ditangkap pada akhir pelanggan kami. Kami kemudian akan membalikkan mata tersebut dari akaun anda. Sekiranya anda telah menebus mata tersebut dan akaun anda mempunyai baki yang rendah maka pendapatan anda akan negatif. Pelarasan ini akan dibuat dalam kitaran penukaran berikutnya.",

  "faq_ques18" : "Berapa lama mata untuk tinjauan dapat ditambah kepada keseimbangan mata saya?",
  "faq_ans18" : "Mata anda akan ditambah ke baki mata anda dalam masa 48 jam.",

  "faq_ques19" : "Apakah ambang minimum untuk penebusan?",
  "faq_ans19" : "Ambang minimum untuk penebusan ialah 25000 mata.Rata-rata, anda perlu melengkapkan 8-12 kaji selidik untuk layak untuk penebusan.",

  "faq_ques20" : "Bagaimanakah mata boleh ditukarkan kepada kad Hadiah? Adakah nisbah?",
  "faq_ans20" : "Ya. Bergantung kepada negara anda, mata akan ditukarkan kepada wang Hadiah Kad.Nisbah penukaran adalah seperti yang ditunjukkan di bawah",
  "faq_ponits" : "Mata",
  "faq_point" : "Titik",
  "faq_cent" : "Cent",

  "faq_ques21" : "Masih ada banyak soalan lagi?",
  "faq_ans21p1" : "Merasa bebas untuk e-mel kami di",
  "faq_ans21p2" : "dan kita akan kembali kepada anda dalam tempoh 48 jam.",

  "Profiling_profiledashboard" : "Papan pemuka profil",
  "Profiling_completeprofile" : "Melengkapkan profil anda dan mendapatkan lebuhrayanya",
  "Profiling_completepoints" : "Mata!",
  "Profiling_answercarefully" : "Set soalan umum berikut akan digunakan untuk mencipta profil peribadi anda untuk memberikan anda peluang kajian masa depan yang terbaik. Sila ambil masa anda, menjawab dengan berhati-hati dan jujur.",

  "Profiling_cat_general" : "Am",
  "Profiling_cat_personal" : "Peribadi",
  "Profiling_cat_household" : "Isi rumah",
  "Profiling_cat_profession" : "Profesion",

  "Profiling_bar_completed" : "% Siap",
  
  "Profiling_update_ans_button" : "Kemas kini & Teruskan",
  "Profiling_save_ans_button" : "Simpan",
  "Profiling_sc_ans_button" : "Simpan & terus",

  "Category_thanku_msg1" : "Baik pergi",
  "Category_thanku_msg2" : "beberapa lagi dan anda akan mempunyai",
  "Category_thanku_msg3" : "mata dalam akaun anda.",

  "Category_thanku_update_msg1" : "Terima kasih untuk mengemaskini maklumat anda!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
   "Category_Continue_button" : "Terus",

  "OverAll_thanku_msg1" : "Terima kasih untuk berkongsi maklumat anda. Anda perolehi",
  "OverAll_thanku_msg2" : "Mata.",

  "Survey_history_surveyhistory" : "Kaji selidik sejarah",
  "Survey_history_fromthisscreen" : "Dari skrin ini, anda boleh melihat semua transaksi anda.",
  "Survey_history_show" : "Papar",
  "Survey_history_entries" : "penyertaan",
  "Survey_history_date" : "Tarikh",
  "Survey_history_details" : "Butir-butir",
  "Survey_history_surveys" : "Kaji selidik",
  "Survey_history_pointsreceived" : "Mata yang diterima",
  "Survey_history_result" : "Keputusan",
  "Survey_history_status" : "Status",
  "Survey_history_status_complete" : "Melengkapkan", 
  "Survey_history_status_disqualified" : "Hilang kelayakan", 
  "Survey_history_keyword" : "Kata kunci",
  "Survey_history_search" : "Cari",
  "Survey_history_to" : "untuk",
  "Survey_history_selectoption" : "Pilih opsyen",
  "Survey_history_earned" : "Diperolehi",
  "Survey_history_redeemed" : "Ditebus",
  "Survey_history_norecord" : "Tiada rekod yang dicari",
  "Survey_history_Surveypts" : "Mata kaji selidik",
  "Survey_history_Promocode" : "Kod promosi",

  "My_transactions_mytransactions" : "Urusniaga saya",
  "My_transactions_fromthisscreen" : "Dari skrin ini, anda boleh melihat semua transaksi anda.",
  "My_transactions_show" : "Papar",
  "My_transactions_entries" : "penyertaan",
  "My_transactions_transactionid" : "Id transaksi",
  "My_transactions_date" : "Tarikh",
  "My_transactions_details" : "Butir-butir",
  "My_transactions_points" : "Mata",
  "My_transactions_status" : "Status",
  "My_transactions_keyword" : "Kata kunci",
  "My_transactions_search" : "Cari",
  "My_transactions_to" : "untuk",
  "My_transactions_selectoption" : "Pilih opsyen",
  "My_transactions_status_earned" : "Diperolehi",
  "My_transactions_status_redeemed" : "Ditebus",
  "My_transactions_status_pending" : "Belum selesai",
  "My_transactions_status_reverted" : "Dikembalikan",
  "My_transactions_status_declined" : "Menurun",
  "My_transactions_norecord" : "Tiada rekod yang dicari",
  "My_transactions_off_msg1" : "Selepas itu ",
  "My_transactions_off_msg2" : ", semua butiran transaksi anda akan diterbitkan pada tab Buku Mata",

  "Passbook_heading" : "Mata Buku",
  "Passbook_show" : "Tunjukkan",
  "Passbook_entries" : "penyertaan",
  "Passbook_search" : "Carian",
  "Passbook_type" : "Taipkan",
  "Passbook_surveyid" : "Surveys",
  "Passbook_date" : "Tarikh",
  "Passbook_selectoption" : "Pilih Pilihan",
  "Passbook_credit" : "Kredit",
  "Passbook_debit" : "Debit",
  "Passbook_survey_id" : "Id Survey",
  "Passbook_survey_pts" : "Mata Penyiasatan",
  "Passbook_earned_pts" : "Mata",
  "Passbook_pending_pts" : "Mata Menunggu",
  "Passbook_promo_code" : "Kod promosi",
  "Passbook_transaction_type" : "Jenis urus niaga",
  "Passbook_balance" : "Seimbang",
  "Passbook_status" : "Status",
  "Passbook_type_soi" : "Pendaftaran",
  "Passbook_type_doi" : "pengesahan email",
  "Passbook_type_profile" : "Penyempurnaan Profil",
  "Passbook_type_survey" : "Ukur",
  "Passbook_type_doir" : "Pengesahan E-mel Oleh",
  "Passbook_type_doirt" : "Pengesahan E-mel oleh Rujukan",
  "Passbook_type_surveyr" : "Usaha Percubaan oleh",
  "Passbook_type_surveyrt" : "Percubaan Susulan Dengan Rujuk",
  "Passbook_type_reward" : "Ganjaran",
  "Passbook_type_pending" : "Pelarasan",
  "Passbook_type_reverted" : "Mata Kembali",
  "Passbook_type_declined" : "Permintaan ditolak",
  "Passbook_type_redeem" : "Tebus",
  "Passbook_type_balance" : "Dibawa ke hadapan",
  "Passbook_balance_vary" : "Baki sebenar mungkin berbeza-beza",
  "Passbook_survey_debit_reason" : "Kami menghargai usaha anda. Walau bagaimanapun, disebabkan beberapa sambungan tak terkawal sementara pemindahan data, respons anda tidak dapat didaftarkan. Kami perlu membalikkan mata yang anda terima sebelum ini untuk tinjauan ini. Memohon maaf atas ketidakselesaan itu.",

  "Redeem_redeemed" : "Menebus",
  "Redeem_redeemhistory" : "Menebus sejarah",
  "Redeem_history_norecord" : "Tiada rekod yang dicari",
  "Redeem_date" : "Tarikh",
  "Redeem_details" : "Butir-butir",
  "Redeem_points" : "Mata",
  "Redeem_status" : "Status",
  "Redeem_lookslike" : "Kelihatan seperti anda terlepas mengesahkan akaun e-mel anda.",
  "Redeem_hurry" : "Terburu-buru! anda akan mendapat 1000 mata sebaik sahaja anda mengesahkan.",
  "Redeem_pleasecheck" : "Sila semak Peti Masuk anda dan klik pautan dalam e-mel pengesahan yang dihantar untuk mengaktifkan akaun anda.",
  "Redeem_kindlycheck" : "Sila semak folder Spam/Junk juga untuk e-mel pengesahan.",
  "Redeem_getconfirmationemail" : "Tidak mendapat e-mel pengesahan lagi?",
  "Redeem_resendconfirmationemail" : "Hantar e-mel pengesahan",
  "Redeem_rewardredemptionrule" : "Kaedah penebusan ganjaran",
  "Redeem_completesurvey" : "Melengkapkan kaji selidik",
  "Redeem_earnpoints" : "Mendapat Mata",
  "Redeem_redeemrewards" : "Menebus ganjaran",
  "Redeem_note" : "Nota:",
  "Redeem_yourearnedpoints" : "Mata diperolehi anda akan menjadi tebus selepas memperolehi kelayakan kaji selidik itu. Untuk menebus ganjaran, mata ganjaran boleh tebus yang minimum dalam akaun anda mesti",
  "Redeem__points" : "mata.",
  "Redeem_availablebalance" : "Baki sedia ada:",
  "Redeem_historydetails" : "Klik di sini untuk melihat butiran sejarah penebusan.", 
  "Redeem_reqsucc" : "Tahniah! Mata Ganjaran anda telah ditebus. Sila semak mel berdaftar untuk maklumat lanjut.", 
  "Redeem_wisecheck_youhave" : "Tahniah! Anda hanya satu klik dari ganjaran anda.",
  "Redeem_wisecheck_gcagainst" : "Kad Hadiah untuk",
  "Redeem_wisecheck_points" : "mata.",
  "Redeem_wisecheck_issue" : "Sistem telah menguasai beberapa aktiviti yang tidak dikenali dalam akaun anda, kerana anda tidak dapat menghantar permintaan redeem. Sila hubungi di",
  "Redeem_emailverify_issue" : "Untuk menebus mata anda, kami akan meminta anda untuk mengesahkan akaun e-mel berdaftar anda.",

  "Redeem_unsubscribe_issue" : "Anda tidak dapat menghantar permintaan penebusan, kerana anda telah berhenti melanggan WisePerks. Sila hubungi support@wiseperks.com untuk melanggan lagi.",
  "Redeem_wisecheck_message1" : "Anda tidak dapat menebus kerana beberapa sebab keselamatan. Sila hubungi untuk menyokong",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Mengesahkan",
  "Redeem_Cancel_button" : "Batal",
  "Redeem_pending_hello" : "Hello",
  "Redeem_pending" : "Pelarasan",
  "Redeem_pending_msg1" : "Akaun anda menunjukkan pelarasan",
  "Redeem_pending_msg2" : "mata yang akan dijalankan. Sila sahkan untuk memulakan transaksi.",

  "Dashboard_wisecheck_issue" : "Sistem telah menangkap beberapa aktiviti yang tidak diiktiraf dalam akaun anda, yang mana anda tidak dapat melihat tinjauan. Sila hubungi support@wiseperks.com untuk penjelasan lanjut.",
  "Dashboard_unsubscribe_issue" : "Anda tidak dapat melihat sebarang tinjauan, seperti yang anda telah berhenti melanggan dari WisePerks. Sila hubungi support@wiseperks.com untuk menerima tinjauan lagi.",


  "WC_DashboardMsg_1" : "Oops! Tiada kaji selidik yang sepadan dengan profil anda sekarang.",
  "WC_DashboardSubMsg_1" : "Jangan risau, kami sedang mengimbangi inventori kami dan akan menghantar tinjauan baru dengan cara anda.",

  "WC_DashboardMsg_2" : "Kami menyegarkan Papan Pemuka anda. Sila semak tinjauan baru selepas beberapa waktu.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Terima kasih untuk melengkapkan kajian ini. Anda hanya memperolehi response_points mata. Log masuk ke papan pemuka anda untuk melihat anda jumlah mata terkumpul.",
  "Survey_success_msg2" : "Terima kasih untuk melengkapkan kajian ini. Anda hanya diperolehi response_points Mata.",

  "Survey_success_but_nomore_survey" : "Tahniah! Anda hanya diperolehi response_points mata untuk melengkapkan kajian ini. Sila semak untuk akan datang Ukur selepas kadang-kadang.",
  
  "Survey_terminate_pnt_msg1" : "Kelihatan seperti beberapa maklumat anda adalah sedikit berbeza daripada keperluan. Kami menghargai masa dan usaha anda, akan menambah response_points mata ke akaun anda. Kami memberi tumpuan membawa anda Ukur yang lebih lumayan dan intuitif.",
  
  "Survey_terminate_nopnt_msg1" : "Nampaknya beberapa jawapan anda sedikit berbeza dari keperluan. Kami akan menghantar lebih banyak tinjauan ke arah anda. Terima kasih kerana masa dan usaha anda.",

  "TermsConditions_t&c" : "Terma syarat &",
  "TermsConditions_WhenRegister" : "Apabila anda mendaftar",
  "TermsConditions_WhenYouCreate" : "Apabila anda membuat akaun peribadi, kami meminta anda beberapa maklumat peribadi, seperti yang dinyatakan di sini:",
  "TermsConditions_YouCanUpdate" : "Anda boleh mengemas kini atau menukar maklumat profil Asas anda pada bila-bila masa.",
  "TermsConditions_YouCanOptOut" : "Anda boleh menyisih atau menutup akaun anda pada bila-bila masa.",
  "TermsConditions_WeRespectYour" : "Kami menghormati privasi anda dan memanfaatkan teknologi terbaik untuk menjaga maklumat anda daripada capaian fizikal dan elektronik yang tidak dibenarkan.",
  "TermsConditions_WeShareYour" : "Kami berkongsi maklumat anda hanya dengan organisasi, yang diperlukan untuk mengendalikan WiseWorks. Dan organisasi-organisasi tersebut perlu menjaga komitmen kami terhadap privasi dan keselamatan anda.",
  "TermsConditions_WhatInformation" : "Maklumat apa yang kami kumpulkan daripada anda",
  "TermsConditions_DirectlyCollected" : "Dikumpulkan terus",
  "TermsConditions_EmailAddressAndPassword" : "Alamat e-mel dan kata laluan akaun anda dengan kami.",
  "TermsConditions_YourResponse" : "Tanggapan anda terhadap soalan profil.",
  "TermsConditions_AnyCustomer" : "Sebarang korespondensi perkhidmatan pelanggan.",
  "TermsConditions_AnyPersonalDetails" : "Sebarang butiran peribadi yang anda kongsi untuk penebusan",
  "TermsConditions_AnyOtherInformation" : "Sebarang maklumat lain yang anda berikan secara langsung.",
  "TermsConditions_UsageData" : "Data penggunaan",
  "TermsConditions_YourIPAddress" : "Alamat IP anda, lokasi geo dan kuki",
  "TermsConditions_InformationAboutTheDevice" : "Maklumat mengenai peranti yang anda gunakan",
  "TermsConditions_YourSurveyActivity" : "Aktiviti tinjauan anda, menunjukkan dalam akaun anda, pembayaran yang anda terima selepas menebus.",
  "TermsConditions_HowDoWeUse" : "Bagaimanakah kita menggunakan data anda?",
  "TermsConditions_SendingYouInvitation" : "Menghantar e-mel jemputan untuk mengambil kaji selidik yang layak untuk anda.",
  "TermsConditions_DetectAndPrevent" : "Mengesan dan mencegah aktiviti penipuan.",
  "TermsConditions_ImproveYourExperience" : "Tingkatkan pengalaman anda pada platform WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Tanggapan anda dipindahkan ke pihak ketiga dan rakan gabungan kami dalam format agregat dan tidak dikenal pasti.",
  "TermsConditions_OurPartnersCanAsk" : "Rakan kongsi kami boleh meminta anda untuk memberikan nama, alamat e-mel dan maklumat hubungan lain semasa tinjauan.",
  "TermsConditions_YouAreFreeToDecline" : "Anda bebas untuk menolak memberikan butir-butir anda kepada rakan kongsi kami, jika anda setuju daripada anda memberikan maklumat ini secara langsung kepada mereka dan bukan kepada WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Maklumat anda akan ditangani selaras dengan Dasar Privasi perniagaan yang berkaitan dan bukan Dasar Privasi WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Rakan kongsi kami komited untuk menghormati dan melindungi privasi anda.",
  "TermsConditions_WhatWeDont" : "Apa yang kita tidak lakukan dengannya",
  "TermsConditions_WeDontSellOnThat" : "Kami tidak menjual maklumat itu kepada sesiapa sahaja, dan kami tidak menggunakannya untuk menjual apa-apa kepada anda. Kami akan sentiasa meminta izin sebelum berkongsi data anda.",
  "TermsConditions_YourRightToControl" : "Hak anda untuk mengawal data anda",
  "TermsConditions_YouHaveFullControl" : "Anda mempunyai kawalan penuh terhadap data anda.",
  "TermsConditions_YouCanAccess" : "Anda boleh mengakses, mengemaskini atau menukar maklumat profil asas anda bila-bila masa anda mahu.",
  "TermsConditions_YouCanUnsubscribe" : "Anda boleh berhenti melanggan dan menyahaktifkan akaun anda.",
  "TermsConditions_YouAreFreeToGetYour" : "Anda bebas untuk memadamkan profil anda dengan menghantar kami mel di support@wiseperks.com. Kami akan cuba menyelesaikan masalah anda dan jika anda tetap ingin menghapus akaun anda, maka akan melakukannya dalam masa 72 jam.",
  "TermsConditions_DataSecurity" : "Keselamatan Data",
  "TermsConditions_OurTechTeamWorks" : "Pasukan teknologi kami bekerja keras untuk memastikan semua data anda selamat.",
  "TermsConditions_WeMakeUseOfLatest" : "Kami menggunakan teknologi terkini dan langkah-langkah untuk mengelakkan sebarang penyalahgunaan atau akses tanpa izin data anda. Walau bagaimanapun, disebabkan sifat persekitaran dalam talian, kami tidak dapat memberi 100% jaminan keselamatan data.",
  "TermsConditions_WeTryOurBestToRule" : "Kami berusaha sebaik mungkin untuk menolak risiko, namun tidak dapat menjamin persekitaran bebas risiko. Dengan menerima Terma dan Syarat ini, anda mengakui dan menerima risiko ini.",
  "TermsConditions_AnyQuestions" : "Sebarang pertanyaan, sila hubungi kami",
  "TermsConditions_IfYouHaveAnyQueries" : "Sekiranya anda mempunyai sebarang pertanyaan atau aduan tentang dasar dan prosedur privasi kami. Sila hantarkan e-mel kepada support@wiseperks.com dan kami akan kembali kepada anda dalam masa 48 jam.",

  "Validation_PleaseEnterProperDate" : "Sila masukkan tarikh lahir yang betul",
  "Validation_PleaseSelectOne" : "Sila pilih satu pilihan!",
  "Validation_PleaseSelectValidOptions" : "Sila Pilih Pilihan Sah!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Sila Pilih sekurang-kurangnya Satu Pilihan",
  "Validation_PleaseInsertTheZip" : "Sila masukkan Zip / Postal Code.",

  "Releases_releases" : "Siaran Akhbar",
  "Releases_theUseOfThis" : "Penggunaan Laman web ini adalah tertakluk kepada penggunaan terma berikut.",
  "Releases_featuresOfWP" : "Ciri-ciri WP melepaskan",
  "Releases_updatedOn" : "dikemaskini pada",
  "Releases_WisePerksNow" : "WisePerks sekarang menyokong pelbagai bahasa",
  "Releases_youCanWalkThourgh" : "Anda boleh berjalan kaki thourgh Laman web dengan lebih mudah sekarang kerana WisePerks yang terdapat dalam bahasa pilihan anda.",
  "Releases_languagesAvailable" : "Bahasa yang disediakan:",
  "Releases_EarnMore" : "Mendapat pendapatan yang lebih dengan sistem point baru kami",
  "Releases_theNewWisePerksAccumulates" : "WisePerks baru ini berkumpul mata ke dalam akaun anda. Titik-titik ini memegang nilai yang jauh lebih tinggi dan mudah untuk menerima dan menebus. Dengan pelbagai pilihan untuk memilih daripada menebus, kami yakin anda akan menikmati kaji selidik itu mengambil pengalaman.",
  "Releases_accumulatePoints" : "Mengumpul mata dengan redeemption percuma hassel",
  "Releases_youGetTheBenefit" : "Anda akan mendapat manfaat daripada pendapatan yang tidak sama. mata, tanpa mengira Mata Wang hendak menebus mereka.",
  "Releases_surveyDisqualification" : "Tidak layak kaji selidik adalah tidak lebih satu berita buruk",
  "Releases_youEarnPoints" : "Anda mendapat mata walaupun anda tidak layak untuk kaji selidik, anda mula menjawab.",
  "Releases_newAndImproved" : "Susun atur yang baru dan lebih baik",
  "Releases_theFreshLookOf" : "Rupa WisePerks segar akan membuat anda jatuh cinta dengannya. Ia kini lebih mudah untuk digunakan dan akan menjadi satu pengalaman yang hebat untuk anda.",
  "Releases_whatAvatarAreYouToday" : "Apa Avatar khabar awak hari",
  "Releases_youCanNowCreate" : "Anda kini boleh membuat avatar anda sendiri ke ciri pada papan pemuka anda.",
  "Releases_freshAndHotSurveys" : "Segar dan panas Ukur pada papan pemuka anda",
  "Releases_youDontHaveTo" : "Anda tidak perlu bimbang tentang refresing papan pemuka anda untuk melihat Ukur baru, kami akan pastikan papan pemuka anda penduduk dengan tempahan Ukur secara automatik.",
  "Releases_promoCode" : "Ciri Kod Promo",
  "Releases_promoCodeInfo" : "Atas permintaan popular, WisePerks membawa Kod Promo untuk ahli panelnya setiap minggu. Cara cepat untuk merebut lebih banyak hadiah.",
  "Releases_referFriend" : "Rujuk Rakan",
  "Releases_referFriendInfo" : "Dapatkan kawan-kawan anda yang berkaitan dengan WisePerks dan dapatkan ganjaran yang menarik. Lihatlah untuk pilihan Rujuk Rakan dalam akaun anda.",
  
  "Dashboard_Promocode_Usepromo" : "Gunakan kod promosi",
  "Dashboard_Promocode_to" : "untuk",
  "Dashboard_Promocode_earnminimum_1" : "mendapat minimum",
  "Dashboard_Promocode_earnminimum_2" : "mata tambahan",
  "Dashboard_Promocode_minimum" : "minimum",
  "Dashboard_Promocode_minimumextrapoints" : "mata tambahan pada setiap tinjauan siap hari ini",
  "Dashboard_Promocode_apply" : "Memohon",
  "Dashboard_Promocode_message1" : "Kod Promo tidak sah!",
  "Dashboard_Promocode_message2" : "Tahniah! Anda akan mendapat minimum",
  "Dashboard_Promocode_message3" : "tambahan pada setiap tinjauan selesai hari ini!",

  "Dashboard_modal_Enjoythefestive" : "Nikmati semangat perayaan dengan memperolehi minimum",
  "Dashboard_modal_Extrapointson" : "tambahan mata pada setiap survey yang anda melengkapkan hari.",
	
  "refer_friend_title" : "Rujuk Rakan dan Dapatkan Ganjaran",
  "refer_friend_step_first": "Jemput rakan ke WisePerks",
  "refer_friend_step_second": "Rakan berjaya menandatangani WisePerks",
  "refer_friend_step_third": "Anda mendapat each_referral mata",
  "refer_friend_link_title": "Jemput rakan sekarang!",
  "refer_friend_share_title": "Kongsi pautan itu",
  "refer_friend_guideline_title": 'Bagaimana "merujuk rakan" akan memberi manfaat kepada anda:',
  "refer_friend_guideline_one": "Sila kongsi pautan yang disediakan di atas dengan rujukan anda.",
  "refer_friend_guideline_two": "Anda boleh merujuk seberapa banyak orang yang anda mahu, sekali 5 rujukan anda mengesahkan akaun mereka dan memulakan aktiviti tinjauan, mata rujukan anda yang diperoleh akan ditambah ke akaun anda.",
  "refer_friend_guideline_three": "Anda mendapat each_referral mata setiap satu untuk berjaya (dengan akaun e-mel disahkan dan aktiviti tinjauan) rujukan.",
  "refer_friend_guideline_four": "Titik akan diperolehi dalam dua peringkat, partial_referral pada pengesahan e-mel dan partial_second_referral pada aktiviti tinjauan oleh rujukan anda.",
  "refer_friend_guideline_five": "Setelah 5 berjaya (dengan akaun e-mel yang disahkan dan aktiviti tinjauan) rujukan, pautan rujukan akan dilumpuhkan.",

  }
  
