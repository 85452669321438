// Vietnam
export const TRANSLATIONS_VI = {
  "home_header_selectlang": "Chọn ngôn ngữ",
  "home_header_loginbutton": "Đăng nhập",
  "home_header_signupbutton": "Đăng ký",
  "home_header_homebutton": "Nhà", 
  
  "home_register_bannertxt1": "Ở đây bạn",
  "home_register_bannertxt2": "suy nghĩ",
  "home_register_bannertxt3": "có thể tìm nạp bạn",
  "home_register_bannertxt4": "phần thưởng$",
  "home_register_bannertxt5": "Nhận tiền thưởng",
  "home_register_bannertxt6": "Khi đăng ký",
  "home_register_fbsignup": "ĐĂNG KÝ VỚI FACEBOOK",
  "home_register_fblogin": "ĐĂNG NHẬP FACEBOOK",
  "home_register_or": "HOẶC LÀ",
  "home_register_emailsignup": "Đăng ký với email",
  "home_register_emaillogin": "Đăng nhập bằng Email",
  "home_register_fpass": "Quên mật khẩu?",
  "home_register_fname": "Tên đầu tiên",
  "home_register_lname": "Họ",
  "home_register_email": "Địa chỉ email",
  "home_register_pass": "Mật khẩu",
  "home_register_cpass": "Xác nhận mật khẩu",
  "home_register_tcpp": "Bằng cách tham gia, bạn đồng ý với",
  "home_register_tcpptandcond": "Điều khoản và điều kiện",
  "home_register_tcppand": "và",
  "home_register_tcppprivacy": "Chính sách bảo mật",
  "home_register_join": "Tham gia với chúng tôi. Nó miễn phí",
  "home_register_login": "đăng nhập",
  "home_register_name_required" : "Tên là bắt buộc.",
  "home_register_email_required" : "Email thì cần thiết.",
  "home_register_invalid_email" : "Định dạng email không hợp lệ",
  "home_register_password_required" : "Yêu cầu mật khẩu.",
  "home_register_password_lower_digit" : "Mật khẩu phải có 6 chữ số là bắt buộc",
  "home_register_password_higher_digit" : "Mật khẩu phải được sử dụng 15 chữ số là bắt buộc",
  "home_register_cpassword_required" : "Xác nhận mật khẩu là bắt buộc",
  "home_register_cpassword_lower_digit" : "Xác nhận mật khẩu phải có 6 chữ số bắt buộc",
  "home_register_cpassword_higher_digit" : "Xác nhận mật khẩu phải được sử dụng 15 chữ số bắt buộc",
  "home_register_cpassword_not_match" : "Xác nhận mật khẩu không khớp",

  "home_register_fp_not_worry" : "Vui lòng nhập ID email đã đăng ký của bạn.", 
  "home_register_fp_resetpass_msg" : "Để đặt lại mật khẩu của bạn, vui lòng nhập ID email đăng ký của bạn.", 
  "home_register_fp_enter_email_msg" : "Nhập email của bạn ở đây", 
  "home_register_fp_submit_button" : "Gửi", 
  "home_register_fp_temporary_sent_pass_msg" : "Một liên kết đặt lại mật khẩu đã được gửi đến id email đăng ký của bạn. Vui lòng cập nhật mật khẩu của bạn tại sớm nhất.",
  
  "home_works_howitworks": "Làm thế nào nó hoạt động?",
  "home_works_howitworkst1": "Giới thiệu bản thân", 
  "home_works_howitworksd1": "Trở thành thành viên, Đăng ký",
  "home_works_howitworkst2": "Nhận khảo sát",
  "home_works_howitworksd2": "Bắt đầu nhận cuộc khảo sát trực quan và đơn giản",
  "home_works_howitworkst3": "Kiếm điểm",
  "home_works_howitworksd3": "Cung cấp phản hồi tốt nhất và tích lũy điểm",
  "home_works_howitworkst4": "Đổi",
  "home_works_howitworksd4": "Đổi điểm cho nhiều phần thưởng thú vị",
  
  "home_aboutus_whoweare": "Chúng ta là ai?",
  "home_aboutus_whowearep1": "Tại WisePerks chúng tôi làm việc chăm chỉ để đảm bảo rằng thời gian bạn chi tiêu trực tuyến là một trải nghiệm bổ ích. Chúng tôi làm việc với hàng trăm công ty mong muốn nhận được phản hồi của bạn, biết cách bạn cảm nhận về sản phẩm hoặc dịch vụ của họ. Bạn cung cấp ý kiến ​​của bạn về các dịch vụ khác nhau, nhưng không có dịch vụ nào thực sự thưởng cho bạn trong thời gian của bạn, không giống như WisePerks - nơi mọi cuộc khảo sát bạn tham gia mang đến cho bạn phần thưởng tiền mặt tuyệt vời cho thời gian của bạn.",
  "home_aboutus_whowearep2": "Chúng tôi đánh giá cao phản hồi của bạn và muốn bạn nhận được phần thưởng cho thời gian của mình. Chúng tôi có các cuộc khảo sát về các chủ đề khác nhau để phù hợp với sở thích của bạn và giúp họ dễ dàng trả lời. Bạn có thể dễ dàng kiếm được khoảng $ 50- $ 100 mỗi tháng bằng cách thực hiện các cuộc khảo sát khác nhau. Vì vậy, chúng ta hãy làm việc cùng nhau để làm cho nó xảy ra.",
  
  "home_earn_more_surabt": "Điều gì khảo sát yêu cầu?",
  "home_earn_more_bp": "Chuyên gia kinh doanh",
  "home_earn_more_consumers": "Người tiêu dùng",
  "home_earn_more_hc": "Sức khỏe & Phòng tập thể dục",
  "home_earn_more_entertainment": "Giải trí & Vui vẻ",
  "home_earn_more_gaming": "Chơi Game freaks",
  "home_earn_more_tech": "Công nghệ savvy",
  "home_earn_more_bonus_survey": "Tiền thưởng khảo sát", 
  
  "home_reward_whatuearn": "Những gì bạn kiếm được?",
  "home_reward_whatuearnp1": "Chúng tôi muốn giữ mọi thứ đơn giản. Bạn nhận được điểm cho tất cả các cuộc khảo sát đã hoàn thành thành công. Bạn càng hoàn thành nhiều khảo sát, bạn càng kiếm được nhiều điểm hơn. Bạn có thể đổi điểm cho Thẻ quà tặng mà bạn chọn sau khi vượt qua một ngưỡng nhất định.",
  "home_reward_whatuearnp2": "Sau khi sẵn sàng đổi quà, hãy chọn tùy chọn bạn thích - Amazon, Starbucks, iTunes và nhiều tùy chọn khác. Chúng tôi cũng đưa ra phần thưởng và cảm ơn Thiên Chúa Thứ Sáu của nó cung cấp theo thời gian.",
  
  "home_counter_earnedover": "Các thành viên đã kiếm được hơn",
  
  "home_contact_getintouch": "Liên lạc",
  "home_contact_emailus": "Đối với bất kỳ câu hỏi nào, vui lòng gửi email cho chúng tôi theo số",
  
  "home_footer_faq": "Câu hỏi thường gặp",
  "home_footer_pp": "Chính sách bảo mật",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Tính năng sắp tới",
  "home_footer_copyright": "Bản quyền © current_year WisePerks. Đã đăng ký Bản quyền.",

  "home_header_home": "Nhà", 
  "home_footer_toknowmore": "Để biết thêm chi tiết",
  "home_footer_createacc": "Tạo một tài khoản miễn phí và tham gia cùng hàng ngàn thành viên hạnh phúc của chúng tôi.",
  "home_footer_registernow": "Đăng ký ngay",

  "home_reset_password" : "Đặt lại mật khẩu", 
  "home_reset_pass_content" : "Bạn đã yêu cầu đặt lại mật khẩu cho", 

  "dashboard_sidebar_selectavatar" : "Chọn Avatar của bạn", 
  "dashboard_sidebar_uploadavatar" : "Click vào đây để tải lên hình ảnh của bạn", 
  "dashboard_sidebar_pbank" : "Điểm ngân hàng",
  "dashboard_sidebar_accumulated" : "Kiếm được",
  "dashboard_sidebar_realized" : "Thực hiện",
  "dashboard_sidebar_pending" : "Điều chỉnh", 
  "dashboard_sidebar_earned_tooltip" : "Điểm bạn đã thu thập cho đến nay.",
  "dashboard_sidebar_pending_tooltip" : "Điểm cần phải được đảo ngược từ tài khoản của bạn.",
  "dashboard_sidebar_main" : "Chủ yếu",
  "dashboard_sidebar_dashboard" : "bảng điều khiển",
  "dashboard_sidebar_profile" : "Hồ sơ",
  "dashboard_sidebar_updateprofile" : "Cập nhật hồ sơ",
  "dashboard_sidebar_shistory" : "Lịch sử khảo sát",
  "dashboard_sidebar_mtrans" : "Giao dịch của tôi",
  "dashboard_sidebar_passbook" : "Sách điểm",
  "dashboard_sidebar_redeem" : "Đổi",
  "dashboard_sidebar_refer_friend" : "Giới thiệu bạn bè",
  "dashboard_sidebar_logout" : "Đăng xuất",

  "dashboard_sidebar_tutorial1" : "Cho bạn thấy tất cả các cuộc điều tra có sẵn và những gì bạn có thể kiếm được từ họ.",
  "dashboard_sidebar_tutorial2" : "Trả lời vài câu hỏi ở đây để cho chúng tôi biết bạn tốt hơn và giúp chúng tôi trong việc gửi cho bạn các cuộc điều tra quan tâm của bạn. Xin lưu ý cho một số câu hỏi bạn sẽ không thể Cập Nhật phản hồi của bạn sau khi gửi.",
  "dashboard_sidebar_tutorial2update" : "Bạn có thể cập nhật các phản hồi của bạn ở đây, Tuy nhiên vài câu hỏi sẽ không có sẵn cho sự chậm phát triển.",
  "dashboard_sidebar_tutorial3" : "Tìm thấy ở đây tất cả các cuộc điều tra bạn đã cố gắng.",
  "dashboard_sidebar_tutorial4" : "Giúp bạn theo dõi tất cả các giao dịch của bạn trên wiseperks.",
  "dashboard_sidebar_tutorial5" : "Vài bước nhanh chóng để chuyển đổi các điểm nhận ra của bạn để thưởng.",
  "dashboard_sidebar_tutorial6" : "Xem toàn bộ lịch sử giao dịch của các điểm được ghi nợ / ghi có vào tài khoản của bạn.",
  "dashboard_sidebar_gotit" : "Đã nhận được",

  "dashboard_sidebar_note" : "Xin lưu ý",
  "dashboard_sidebar_note1" : "Tất cả các câu trả lời phải được trả lời một cách trung thực.",
  "dashboard_sidebar_note2" : "Hãy cụ thể và chính xác với các câu trả lời mở đã kết thúc.",
  "dashboard_sidebar_note3" : "Đừng tốc độ! Thời gian hoàn thành khảo sát tối thiểu được đặt.",
  "dashboard_sidebar_note4" : "Vui lòng cung cấp câu trả lời nhất quán khi cùng một câu hỏi.",
  "dashboard_sidebar_note5" : "Các cuộc điều tra mới sẽ được thêm và đóng sẽ được loại bỏ tự động khỏi bảng điều khiển của bạn.",
  "dashboard_sidebar_note6" : "Kiếm một số điểm nhanh chóng chỉ bằng cách giới thiệu.",

  "dashboard_setting_hi" : "Chào",
  "dashboard_setting_minfo" : "Thông tin thành viên",
  "dashboard_setting_macc" : "Quản lý tài khoản",
  "dashboard_setting_upass" : "Cập nhật mật khẩu",
  "dashboard_setting_yourname" : "Tên bạn là",
  "dashboard_setting_fname" : "Tên đầu tiên",
  "dashboard_setting_lname" : "Họ",
  "dashboard_setting_save" : "Lưu thay đổi",
  "dashboard_setting_bornon" : "Bạn sinh ngày",
  "dashboard_setting_youare" : "Bạn là một",
  "dashboard_setting_postal" : "Mã bưu điện của bạn là",
  "dashboard_setting_emailus" : "Đối với bất kỳ sự trợ giúp hoặc truy vấn, xin vui lòng gửi email đến",
  "dashboard_setting_membersince" : "Bạn đã là thành viên từ",
  "dashboard_setting_taken" : "Bạn vừa cầm",
  "dashboard_setting_completed" : "khảo sát và hoàn thành",
  "dashboard_setting_ofthem" : "của họ.",
  "dashboard_setting_earned" : "Bạn đã kiếm được",
  "dashboard_setting_pts" : "điểm",
  "dashboard_setting_started" : "kể từ khi bạn bắt đầu.",
  "dashboard_register_email" : "Bạn được đăng ký với chúng tôi từ",
  "dashboard_setting_preferences" : "Quản lý tùy chọn của bạn",
  "dashboard_setting_emailoptout" : "Chọn không tham gia email",
  "dashboard_setting_futureemails" : "Chọn không nhận email trong tương lai từ WisePerks.",
  "dashboard_setting_futureemailsflash" : "Email của chúng tôi giữ cho bạn được đăng trên tất cả các cuộc khảo sát sinh lợi có sẵn cho bạn. Bạn có chắc chắn rằng bạn không muốn nhận chúng.",
  "dashboard_setting_nrecemail" : "Email của chúng tôi giữ cho bạn được đăng trên tất cả các cuộc khảo sát sinh lợi có sẵn cho bạn. Bạn có chắc chắn rằng bạn không muốn ngăn chặn chúng.",
  "dashboard_setting_emailout_modalmsg" : "Bạn có chắc bạn muốn chọn không tham gia nhận được email trong tương lai từ WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Bạn có chắc chắn để bắt đầu nhận email tương lai từ wiseperks?",
  "dashboard_setting_emailout_succmsg" : "Đã thành công của bạn ngừng nhận email từ wiseperks",
  "dashboard_setting_emailin_succmsg" : "Bạn đã bắt đầu thành công nhận được email từ wiseperks",
  "dashboard_setting_deactivateacc" : "Hủy kích hoạt tài khoản",
  "dashboard_setting_disableprofile" : "Việc vô hiệu hóa tài khoản của bạn sẽ vô hiệu hóa hồ sơ của bạn từ WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Bạn có chắc bạn muốn tắt tài khoản của mình trên WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Nếu bạn có bất cứ khiếu nại hoặc phản hồi, sau đó xin vui lòng chia sẻ với chúng tôi. Chúng tôi sẽ giải quyết nó trên ưu tiên. Cho chúng tôi một cơ hội. Bạn có chắc chắn muốn xoá tài khoản của mình với chúng tôi.",
  "dashboard_setting_changelang" : "Thay đổi ngôn ngữ",
  "dashboard_setting_preferredlang" : "Chọn ngôn ngữ ưa thích của bạn",
  "dashboard_setting_updatepass" : "Cập nhật mật khẩu",
  "dashboard_setting_plsupdate" : "Vui lòng cập nhật mật khẩu của bạn dưới đây.",
  "dashboard_setting_oldpass" : "Mật khẩu cũ",
  "dashboard_setting_enteroldpass" : "Nhập mật khẩu cũ",
  "dashboard_setting_newpass" : "Mật khẩu mới",
  "dashboard_setting_enternewpass" : "Nhập mật khẩu mới",
  "dashboard_setting_confpass" : "Xác nhận mật khẩu",
  "dashboard_setting_enternewpassagain" : "Nhập mật khẩu mới lần nữa",
  "dashboard_setting_update" : "Cập nhật",

  "dashboard_doi_welcome" : "Chào mừng",
  "dashboard_doi_welbck" : "Chào mừng trở lại",
  "dashboard_doi_accntverified" : "Hình như bạn bị mất xác minh tài khoản email của bạn.",
  "dashboard_doi_getaccess" : "Vội vàng! bạn sẽ có được",
  "dashboard_doi_getaccess2" : "điểm khi bạn xác minh.",
  "dashboard_doi_activateacc" : "Vui lòng kiểm tra hộp thư đến của bạn và nhấp vào liên kết được đưa ra trong email xác minh tài khoản được gửi đến tài khoản email đã đăng ký của bạn.",
  "dashboard_doi_chckspam" : "Vui lòng, kiểm tra thư mục spam/Junk trong trường hợp bạn không thể tìm thấy email xác minh.",
  "dashboard_doi_didntgetconf" : "Đã không nhận được email xác minh chưa?",
  "dashboard_doi_resendconf" : "Gửi lại email xác minh",
  "dashboard_doi_resendconfsucc" : "Email xác minh đã được gửi đến email đăng ký của bạn.", 
  
  "dashboard_survey_availsurveys" : "Khảo sát có sẵn",
  "dashboard_survey_wisechecksurveys1" : "Bạn không thể nhìn thấy các cuộc điều tra do một số lý do an ninh. Xin vui lòng liên hệ để hỗ trợ",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Không có các cuộc điều tra để bỏ đăng ký lý do",
  "dashboard_featured" : "Nổi bật",
  "dashboard_profile_survey" : "Khảo sát hồ sơ",
  "dashboard_survey_start" : "Khởi đầu",
  "dashboard_survey_on" : "Trên",
  "dashboard_survey_off" : "Tắt",
  "dashboard_survey_point": "Điểm",
  "dashboard_survey_points" : "Điểm",
  "dashboard_survey_minute" : "Phút",
  "dashboard_survey_minutes" : "Phút",

  "demography_hi" : "Chào bạn",
  "demography_welcome" : "Chào mừng bạn đến wiseperks. Bạn có chỉ kiếm được cho mình",
  "demography_points" : "điểm bằng việc đăng ký với chúng tôi.",
  "demography_share" : "Xin vui lòng chia sẻ vài chi tiết hơn trong mẫu dưới đây, để giúp chúng tôi trong việc đưa các cuộc điều tra có liên quan cho bạn.",

  "demography_gender" : "tôi là",
  "demography_male" : "Nam giới",
  "demography_female" : "Giống cái",
  "demography_birthday" : "Sinh nhật",
  "demography_month" : "Tháng",
  "demography_day" : "Ngày", 
  "demography_year" : "Năm", 
  "demography_jan" : "tháng Giêng",
  "demography_feb" : "Tháng hai",
  "demography_mar" : "tháng Ba",
  "demography_apr" : "Tháng tư",
  "demography_may" : "có thể",
  "demography_jun" : "Tháng sáu",
  "demography_jul" : "Tháng 7",
  "demography_aug" : "tháng Tám",
  "demography_sep" : "Tháng Chín",
  "demography_oct" : "Tháng Mười",
  "demography_nov" : "Tháng mười một",
  "demography_dec" : "Tháng mười hai",
  "demography_hearabout" : "Bạn đã nghe về WisePerks ở đâu?",
  "demography_opt1" : "Chọn gần nhất áp dụng",
  "demography_opt2" : "Bản tin email",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Diễn đàn hoặc Blog",
  "demography_opt5" : "Người bạn",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "Công cụ tìm kiếm",
  "demography_opt8" : "Truyền hình thương mại",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Trang web",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Khác",
  "demography_zipcode": "Mã Bưu Chính",
  "demography_submit" : "Gửi",

  "privacypolicy_privacypolicy" : "Chính sách bảo mật",
  "privacypolicy_privacyisimp" : "Quyền riêng tư của bạn rất quan trọng với chúng tôi.",
  "privacypolicy_lastupdated" : "Cập nhật lần cuối vào",

  "privacypolicy_para1" : 'WiseWorks (cùng với các chi nhánh của nó, "Công ty") sở hữu và điều hành một số trang web, ứng dụng di động và dịch vụ tương tác khác nhau, bao gồm Wisesample.com, Flapbucks.com, WisePerks.com và các trang web khác (gọi chung là " WiseWorks Sites ").Chính sách bảo mật ("Chính sách bảo mật") áp dụng cho các trang web WiseWorks và tất cả các tính năng, ứng dụng di động, email, dịch vụ trực tuyến và các chức năng khác, cho dù truy cập qua máy tính, thiết bị di động hay các dịch vụ khác có sẵn trên hoặc thông qua các trang web và tính năng của WiseWorks ("Dịch vụ").Chính sách bảo mật này chỉ bao gồm thông tin được Công ty thu thập thông qua các trang web và tính năng của WiseWorks và Dịch vụ của chúng tôi và không bao gồm bất kỳ thông tin nào đã nhận hoặc thu thập ngoại tuyến của Công ty (trừ khi được nêu cụ thể) hoặc thu thập bởi bất kỳ trang web hoặc công ty nào không liên quan mà chúng tôi có thể cung cấp liên kết.',

  "privacypolicy_para2" : "Dự kiến ​​người tham luận viên / người trả lời sẽ điền vào hồ sơ của mình một cách trung thực và trung thực và không gửi thông tin sai hoặc không chính xác chỉ để đủ điều kiện cho nhiều khảo sát hơn.Nó cũng được dự kiến ​​rằng tham luận viên / người trả lời sẽ không thao túng câu trả lời của mình hoặc sử dụng các phương tiện không công bằng khác (thay đổi IP, nhiều tài khoản vv) trong quá trình khảo sát để kiếm được ưu đãi.WisePerks có đủ các biện pháp bảo vệ để phát hiện các dị thường như vậy và nếu nhận thấy hành vi như vậy, người tham luận sẽ bị cấm / bị chặn khỏi WisePerks và tất cả / bất kỳ yêu cầu đổi quà nào của anh ta sẽ bị hủy bỏ.",

  "privacypolicy_para3" : "WiseWorks cam kết bảo vệ quyền riêng tư của bạn với chúng tôi.Chính sách bảo mật sau giải thích cách thông tin cá nhân của bạn được tổ chức và xử lý khi bạn khám phá trang web và dịch vụ của chúng tôi.",

  "privacypolicy_topic1" : "WisePerks.com thu thập thông tin nhận dạng cá nhân nào từ tôi?",
  "privacypolicy_topic1ans" : "WisePerks.com thu thập thông tin theo nhiều cách khác nhau từ các phần khác nhau của trang web và mạng của chúng tôi.Một số thông tin được thu thập khi bạn đăng ký.Trong quá trình đăng ký thành viên, WisePerks.com yêu cầu tên, địa chỉ email, mã zip và thông tin hộ gia đình của bạn.Bạn càng có nhiều thông tin để cung cấp chính xác, thì chúng tôi càng có thể cung cấp cho bạn các dịch vụ và phần thưởng tốt hơn.Ngoài ra, nếu bạn đã từng liên hệ với WisePerks.com thông qua bất kỳ phương tiện nào, chúng tôi có thể lưu giữ hồ sơ về thư từ đó.Bất cứ khi nào WisePerks.com thu thập bất kỳ thông tin nào như vậy, chúng tôi hướng đến việc bao gồm một liên kết đến Chính sách bảo mật này trên trang đó.",

  "privacypolicy_topic2" : "Cookie là gì và WisePerks.com sử dụng chúng như thế nào?",
  "privacypolicy_topic2ans" : "Cookie là gói dữ liệu điện tử được gửi tới trình duyệt của bạn, từ máy chủ web và được lưu trữ trên ổ cứng máy tính của bạn.Là một phần của việc cung cấp cho bạn các khảo sát, thông tin và dịch vụ được cá nhân hóa cụ thể, WisePerks.com có ​​thể sử dụng cookie để lưu trữ và đôi khi theo dõi thông tin về bạn.WisePerks.com có ​​thể yêu cầu bạn chấp nhận cookie để chuẩn bị nội dung cụ thể cho bạn.Điều này cũng tránh bạn phải nhập thông tin đăng nhập của bạn mỗi lần.",

  "privacypolicy_topic3" : "Chính sách WisePerks.com cho phép tôi cập nhật, chỉnh sửa, thay đổi hoặc xóa thông tin tài khoản cá nhân của tôi là gì?",
  "privacypolicy_topic3ans" : "Bạn có thể chỉnh sửa Thông tin Tài khoản WisePerks.com bất kỳ lúc nào bằng cách sử dụng Đăng nhập và mật khẩu của WisePerks.com.Vui lòng liên hệ với",
  "privacypolicy_topic3ans2" : "để được hướng dẫn thêm về việc hủy kích hoạt tài khoản WisePerks.com của bạn.",

  "privacypolicy_topic4" : "Ai đang thu thập thông tin và cho mục đích gì?",
  "privacypolicy_topic4ansp1" : "WisePerks.com thu thập một số thông tin thông qua khảo sát và đăng ký và các phương tiện khác, giúp khách hàng nghiên cứu của mình phát triển sản phẩm và dịch vụ mới cho người tiêu dùng.Bạn đang chia sẻ thông tin đó với WisePerks.com chỉ, trừ khi nó được quy định cụ thể khác trên bất kỳ khảo sát nào bạn tham gia.Nếu dữ liệu đang được thu thập và / hoặc duy trì bởi bất kỳ công ty nào ngoài WiseWorks - hoặc các trang web của WiseWorks, bạn sẽ được thông báo trước thời điểm thu thập hoặc chuyển dữ liệu.Nếu bạn không muốn chia sẻ dữ liệu của mình, bạn có thể chọn không cho phép chuyển bằng cách không sử dụng khảo sát hoặc sản phẩm cụ thể đó.",
  "privacypolicy_topic4ansp2" : "Phần thưởng và khuyến mãi mà chúng tôi cung cấp trên WisePerks.com có ​​thể được tài trợ bởi các công ty khác một cách độc lập hoặc có thể được đồng tài trợ bởi WisePerks.com và một công ty khác.Một số dữ liệu được thu thập trong chương trình khuyến mãi như vậy có thể được chia sẻ với nhà tài trợ.Tuy nhiên, bạn sẽ được thông báo trước khi thu thập hoặc chuyển dữ liệu.Bạn có thể quyết định không tham gia vào chương trình khuyến mãi cụ thể đó nếu bạn không muốn chia sẻ dữ liệu của mình.",
  "privacypolicy_topic4ansp3" : "Bất kỳ Thông tin Nhạy cảm cụ thể nào (như thông tin xác định các điều kiện y tế hoặc sức khỏe, nguồn gốc chủng tộc hoặc chủng tộc, quan điểm chính trị, tín ngưỡng tôn giáo hoặc tinh thần, giao dịch và thành viên công đoàn hoặc thông tin xác định đời sống tình dục của cá nhân) sẽ được điều trị cẩn thận.Nếu WisePerks.com dự định chia sẻ thông tin đó cho một công ty khác cho một mục đích khác với mục đích ban đầu được thu thập, thì bạn sẽ được yêu cầu rõ ràng trước khi có bất kỳ tiết lộ nào như vậy.",

  "privacypolicy_topic5" : "WisePerks.com chia sẻ thông tin của tôi với ai?",
  "privacypolicy_topic5ansp1" : "Chúng tôi rất coi trọng quyền riêng tư.WisePerks.com sẽ không tiết lộ bất kỳ thông tin nhận dạng cá nhân nào của bạn trừ khi chúng tôi có sự cho phép rõ ràng của bạn hoặc trong bất kỳ trường hợp đặc biệt nào, luật pháp yêu cầu hoặc trong các trường hợp được nêu chi tiết bên dưới.Phần sau mô tả một số cách mà thông tin của bạn có thể được tiết lộ.Vui lòng xem Điều khoản dịch vụ để biết thêm thông tin chi tiết về cách thông tin cá nhân của bạn có thể được chia sẻ.",
  "privacypolicy_topic5ansp2" : "Đối tác kinh doanh, Khách hàng & Nhà tài trợ:",
  "privacypolicy_topic5ansp3" : "WisePerks.com có ​​thể tiết lộ thông tin cá nhân của bạn cho các đối tác, khách hàng hoặc nhà tài trợ của chúng tôi.Tuy nhiên, nếu bất kỳ dữ liệu cá nhân nào về bạn đã từng được chia sẻ, chúng tôi sẽ cho bạn biết trước khi chúng tôi thu thập hoặc chuyển dữ liệu.Nhiều hoạt động chúng tôi cung cấp yêu cầu thông tin bổ sung từ các nhà tài trợ.Khi bạn yêu cầu thêm thông tin, bạn cho phép WisePerks.com chia sẻ thông tin của bạn với nhà tài trợ để họ có thể đáp ứng yêu cầu của bạn.Trong nhiều trường hợp, chỉ địa chỉ email của bạn sẽ được chia sẻ.Nếu có thêm thông tin sẽ được chia sẻ với các đối tác của chúng tôi, bạn sẽ được thông báo trước.Thỉnh thoảng, bạn có thể được cung cấp cơ hội nhận tài liệu kiểm tra hoặc các sản phẩm khác hoặc ưu đãi đặc biệt từ các đối tác của chúng tôi.Nếu bạn chọn nhận thông tin từ các bên thứ ba này, WisePerks.com sẽ (với sự cho phép của bạn) chia sẻ tên và địa chỉ email của bạn với họ.",
  "privacypolicy_topic5ansp4" : "WisePerks.com cũng có thể tiết lộ thông tin cá nhân trong một số trường hợp đặc biệt khi tiết lộ thông tin này là cần thiết để xác định, liên hệ hoặc thực hiện hành động pháp lý đối với người có thể vi phạm chính sách và Điều khoản dịch vụ của WisePerks.com hoặc có thể gây ra bất kỳ tổn thương nào hoặc can thiệp với (hoặc cố ý hoặc vô tình) các sản phẩm, dịch vụ, quyền hoặc tài sản của WisePerks.com, các thành viên và người dùng WisePerks.com khác hoặc bất kỳ ai khác có thể bị ảnh hưởng bởi bất kỳ hoạt động nào như vậy.WisePerks.com có ​​thể tiết lộ hoặc truy cập thông tin tài khoản khi chúng tôi tin rằng luật pháp yêu cầu và cho các mục đích hành chính và các mục đích khác mà chúng tôi cho là cần thiết để duy trì, dịch vụ và cải thiện các sản phẩm và dịch vụ của mình.",

  "privacypolicy_topic6" : "Các cơ sở pháp lý chúng tôi sử dụng để xử lý thông tin của bạn là gì?",
  "privacypolicy_topic6ans" : 'WiseWorks cam kết bảo mật và bảo vệ dữ liệu.Chính sách bảo mật của chúng tôi thông qua các nguyên tắc của Quy định bảo vệ dữ liệu chung do Liên minh châu Âu ("GDPR") ban hành làm điểm chuẩn mà chúng tôi, nhân viên và nhà cung cấp tuân thủ.',

  "privacypolicy_topic7" : "Tôi có những lựa chọn gì về việc thu thập, sử dụng và phân phối thông tin của tôi?",
  "privacypolicy_topic7ans" : "Chỉ WisePerks.com (hoặc các đối tác làm việc với WisePerks.com theo các thỏa thuận bảo mật) sẽ gửi cho bạn bất kỳ thư trực tiếp nào, chỉ khi bạn cho biết rằng bạn không phản đối các thư này.Bạn cũng có các lựa chọn liên quan đến cách hoạt động của cookie.Bằng cách thay đổi cài đặt trình duyệt internet của bạn, bạn có thể chọn chấp nhận tất cả cookie, được thông báo khi cookie được đặt hoặc từ chối tất cả yêu cầu cookie.Nếu bạn chọn từ chối tất cả cookie, bạn sẽ không thể sử dụng các dịch vụ WisePerks.com đó yêu cầu đăng ký trước để tham gia.WisePerks.com không bán hoặc cho thuê bất kỳ thông tin người dùng nào cho bất kỳ ai.Chúng tôi sẽ luôn thông báo cho bạn vào thời điểm thu thập hoặc chuyển dữ liệu nếu dữ liệu của bạn sẽ được chia sẻ với bất kỳ bên thứ ba nào và bạn sẽ luôn có tùy chọn không cho phép chia sẻ dữ liệu đó.",

  "privacypolicy_topic8" : "Các biện pháp phòng ngừa an ninh được đưa ra tại WisePerks.com để bảo vệ bất kỳ tổn thất, lạm dụng hoặc thay đổi thông tin tài khoản nào của tôi?",
  "privacypolicy_topic8ansp1" : "Thông tin tài khoản WisePerks.com của bạn được bảo vệ bằng mật khẩu để bạn và chỉ bạn mới có quyền truy cập vào thông tin này.",
  "privacypolicy_topic8ansp2" : "Chúng tôi khuyên bạn không nên chia sẻ mật khẩu của mình với bất kỳ ai.Chúng tôi sẽ không bao giờ yêu cầu bạn nhập mật khẩu trong một cuộc gọi hoặc email không mong muốn.Vui lòng đăng xuất khỏi tài khoản WisePerks.com của bạn và đóng cửa sổ trình duyệt của bạn khi bạn đã hoàn tất hoạt động của mình.Điều này đảm bảo rằng những người khác không thể truy cập thông tin cá nhân và tài khoản của bạn, nếu bạn đang sử dụng máy tính công cộng hoặc chia sẻ máy tính của bạn với người khác.Bất cứ khi nào WisePerks.com xử lý thông tin cá nhân như mô tả ở trên, bất kể điều này xảy ra ở đâu, WisePerks.com thực hiện các bước để đảm bảo rằng thông tin của bạn được xử lý an toàn và phù hợp với Điều khoản dịch vụ có liên quan và Chính sách bảo mật này.Thật không may, không có truyền dữ liệu qua Internet có thể được đảm bảo an toàn 100%.Do đó, trong khi chúng tôi cố gắng bảo vệ thông tin cá nhân của bạn, WisePerks.com không thể đảm bảo hoặc đảm bảo an toàn cho bất kỳ thông tin nào bạn truyền cho chúng tôi hoặc từ các sản phẩm hoặc dịch vụ trực tuyến của chúng tôi và bạn tự chịu rủi ro.Khi chúng tôi nhận được dữ liệu của bạn, chúng tôi nỗ lực hết sức để đảm bảo tính bảo mật trên hệ thống của chúng tôi.",

  "privacypolicy_topic9" : "Tôi nên biết gì khác về quyền riêng tư của mình?",
  "privacypolicy_topic9ans" : "Xin lưu ý rằng bất cứ khi nào (và bất cứ nơi nào) bạn chọn tiết lộ thông tin cá nhân trực tuyến - ví dụ qua email, trang web mạng xã hội, blog hoặc trong các khu vực trò chuyện - thông tin đó có thể được thu thập và sử dụng bởi bất kỳ người dùng nào khác.Nếu bạn đăng thông tin cá nhân trực tuyến mà mọi người có thể truy cập, bạn có thể nhận được các tin nhắn không mong muốn từ các bên khác.Cuối cùng, bạn hoàn toàn chịu trách nhiệm duy trì bí mật mật khẩu của mình và / hoặc bất kỳ thông tin tài khoản nào.Hãy cẩn thận và chịu trách nhiệm bất cứ khi nào bạn trực tuyến.",

  "privacypolicy_topic10" : "WisePerks.com bảo vệ quyền riêng tư của con tôi như thế nào?",
  "privacypolicy_topic10ans" : "WisePerks.com có ​​được sự đồng ý của cha mẹ trước khi các cuộc điều tra yêu cầu trẻ em tham gia và giám sát của cha mẹ cũng được yêu cầu cho bất kỳ cuộc điều tra nào như vậy với trẻ em từ 6-15 tuổi.Chúng tôi đặc biệt chú ý đến việc bảo vệ sự an toàn và quyền riêng tư của những người dùng trẻ tuổi trên trang web của chúng tôi, và chúng tôi khuyến khích bạn tham gia vào trải nghiệm trực tuyến của con bạn.Chúng tôi không cho phép tiếp thị hoặc nghiên cứu trẻ em và các chủ đề nhất định bị cấm hoàn toàn trong nghiên cứu trẻ em: Tên / tên tài khoản / E-mail hoặc địa chỉ thực, Số điện thoại, Chủng tộc hoặc Dân tộc, Tôn giáo hoặc Diễn viên, Thu nhập hộ gia đình, Bệnh gia đình , Tình trạng hôn nhân của cha mẹ hoặc các thành viên khác trong gia đình, Các vấn đề về mối quan hệ, Các lớp ở trường hoặc cao đẳng, Rượu, Các vấn đề tình dục, Thuốc lá và Súng cầm tay.",

  "privacypolicy_topic11" : "Ghi chú đặc biệt cho người dùng quốc tế",
  "privacypolicy_topic11ansp1" : "Trang web được lưu trữ tại Hoa Kỳ.Nếu bạn là Người dùng truy cập Trang web từ Liên minh Châu Âu, Châu Á hoặc bất kỳ khu vực nào khác có luật hoặc quy định về thu thập, sử dụng và tiết lộ dữ liệu cá nhân khác với luật pháp Hoa Kỳ, xin lưu ý rằng bạn đang chuyển dữ liệu cá nhân của mình sang Hoa Kỳ không có luật bảo vệ dữ liệu giống như EU và các khu vực khác, đồng thời cung cấp dữ liệu cá nhân của bạn mà bạn đồng ý:",
  "privacypolicy_topic11ansp2" : "Việc sử dụng dữ liệu cá nhân của bạn cho các mục đích sử dụng được xác định ở trên phù hợp với Chính sách bảo mật; và chuyển dữ liệu cá nhân của bạn sang Hoa Kỳ như đã nêu ở trên.BẠN LỜI CẢM ƠN R THENG LUẬT CỦA HOA K TRE ĐIỀU TRỊ THÔNG TIN CỦA BẠN TRONG MỘT NGƯỜI LÁ CÂY CÓ THỂ CÓ THỂ KHÁC BIỆT TỪ, VÀ BẢO VỆ BẤT K TH NÀY, ĐIỀU TRỊ BẮT BUỘC THEO LUẬT CỦA CÁC NƯỚC KHÁC VÀ CÁC ĐIỀU KHOẢN KHÁC.NẾU BẠN KHÔNG MUỐN THÔNG TIN CỦA BẠN ĐƯỢC CHUYỂN ĐỔI CHO HOA K UN, BẠN KHÔNG NÊN CHIA SẺ THÔNG TIN CỦA BẠN VỚI CHÚNG TÔI, HOẶC SỬ DỤNG TRANG WEB.ĐỐI VỚI PHỤ HUYNH ĐƯỢC PHÉP PHÁP LUẬT PHÁP LUẬT CỦA BẠN Ở ĐÂU, BẠN CÓ THỂ ĐƯA RA BẤT K R QUYỀN BẠN CÓ THỂ ĐỂ YÊU CẦU XỬ LÝ THÔNG TIN XÁC ĐỊNH CỦA BẠN THEO LUẬT CỦA BẤT K COUNT NƯỚC NÀO HOẶC THẨM QUYỀN KHÁC NHỮNG NGƯỜI HOA K UN.HÃY BAO GIỜ, NGƯỜI TIÊU CHUẨN CÓ THỂ KHÔNG BẮT BUỘC PHÁP LÝ TRONG MỘT SỐ QUỐC GIA, NHƯ LÀ NHỮNG THÀNH VIÊN CHÂU ÂU CỦA ĐOÀN CHÂU ÂU.ĐỐI VỚI MỞ RỘNG KHÔNG PHẢI LÀ BẤT HỢP PHÁP LÝ TRONG NƯỚC Ở NHỮNG KHI BẠN ĐANG Ở ĐÂY, RỦI RO KHUYẾN MÃI NÀY KHÔNG ÁP DỤNG CHO BẠN.",

  "privacypolicy_topic12" : "Thay đổi và cập nhật cho Chính sách bảo mật này",
  "privacypolicy_topic12ans" : "WiseWorks bảo lưu quyền, theo quyết định của chúng tôi, thay đổi, sửa đổi, thêm hoặc xóa các phần của Chính sách bảo mật này bất kỳ lúc nào vì bất kỳ lý do gì.Mỗi lần WiseWorks thực hiện quyền như vậy, bạn sẽ được yêu cầu xác nhận chấp nhận Chính sách bảo mật đã cập nhật và nếu bạn không làm như vậy, bạn sẽ không còn có thể truy cập hoặc sử dụng Trang web nữa.",

  "privacypolicy_addinfo" : "Thông tin thêm",
  "privacypolicy_sentto" : "Bất kỳ câu hỏi nào khác liên quan đến vấn đề này sẽ được gửi đến",

  "faq_faq" : "Các câu hỏi thường gặp",
  "faq_getans" : "Nhận câu trả lời cho tất cả các câu hỏi của bạn.",

  "faq_ques1" : "WisePerks là gì?",
  "faq_ans1" : "Chúng tôi rất vui vì bạn đã hỏi.WisePerks là một trang khảo sát trực tuyến.Rất nhiều thương hiệu và công ty đang tìm kiếm người trả lời để cung cấp ý kiến ​​của họ và nhận lại phần thưởng.WisePerks là một trong những nền tảng mà bạn có thể đưa ra ý kiến ​​của bạn và kiếm được phần thưởng cho nó.",

  "faq_ques2" : "Có tham gia miễn phí không?",
  "faq_ans2" : "Có, nó là miễn phí để tham gia.Trên thực tế, nếu ai đó yêu cầu bạn một khoản phí tham gia, chúng tôi muốn bạn báo cáo cho chúng tôi.",

  "faq_ques3" : "Tôi có thể giới thiệu bạn bè của tôi không?",
  "faq_ans3" : 'Có, bạn có thể mời bạn bè và đồng nghiệp của mình tham gia và cũng nhận được phần thưởng cho nó. Vui lòng nhấp vào liên kết "Giới thiệu bạn bè" trên bảng điều khiển của bạn để có được tất cả các chi tiết.',

  "faq_ques4" : "Tôi kiếm được gì?",
  "faq_ans4" : "Bây giờ trong mô hình mới của chúng tôi, bạn có thể kiếm được điểm trong nhiều cách khác hơn là chỉ bằng cách tham gia các cuộc điều tra. Bạn nhận được điểm bởi: đăng ký với chúng tôi, bằng cách xác minh tài khoản email của bạn, để hoàn thành hồ sơ của bạn, tất nhiên để hoàn thành bất kỳ cuộc khảo sát và bất ngờ của bạn ngay cả đối với một cuộc khảo sát bị loại. Vì vậy, chúng tôi có tất cả những lý do để giúp bạn bắt đầu không phải là nó?",

  "faq_ques5" : "Làm cách nào để trở thành thành viên của WisePerks.com?",
  "faq_ans5" : 'Mở WisePerks.com trên trình duyệt của bạn.Tạo thông tin đăng nhập thành viên bằng cách nhấp vào tab "Đăng ký".Chỉ cần làm theo các bước và nó sẽ hướng dẫn bạn qua phần còn lại của quá trình.Bạn sẽ phải xác nhận ý định tham gia của mình bằng cách nhấp vào email xác nhận do chúng tôi gửi đến email của bạn được cung cấp.',

  "faq_ques6" : "Email xác nhận bạn đã đề cập ở đâu?",
  "faq_ans6p1" : "Một e-mail xác nhận được tự động gửi đến địa chỉ email bạn cung cấp khi đăng ký. Nếu bạn không thấy rằng email trong hộp thư đến của bạn, hãy kiểm tra thư mục spam hoặc Junk của bạn. Để tránh bất kỳ email trong tương lai từ chúng tôi và khảo sát mời/tin nhắn từ đạt thư mục thư rác của bạn, xin vui lòng thêm",
  "faq_ans6p2" : "vào danh sách người gửi được chấp thuận của bạn hoặc như một số liên lạc trong hộp thư của bạn. Nếu bạn vẫn không thể tìm thấy e-mail xác nhận, vui lòng đăng nhập vào tài khoản của tôi, xác nhận rằng địa chỉ email của bạn với chúng tôi là chính xác và yêu cầu email xác nhận khác.",

  "faq_ques7" : "Làm cách nào để thực hiện khảo sát?",
  "faq_ans7" : 'Một khi bạn đã đăng nhập sau khi nhập tên người dùng và mật khẩu của bạn, bạn đạt được bảng điều khiển của bạn và có thể xem tất cả các cuộc điều tra bạn có thể mất. Chúng tôi cũng gửi các liên kết khảo sát đến các ID email đã đăng ký của bạn. Bạn có thể lấy liên kết trực tiếp từ hộp thư của bạn.',

  "faq_ques8" : "Câu trả lời của tôi là bí mật, dữ liệu của tôi như thế nào?",
  "faq_ans8" : "Đối với WisePerks, việc duy trì quyền riêng tư và bảo mật của người dùng là ưu tiên hàng đầu.Khách hàng của chúng tôi chỉ quan tâm đến những gì bạn nghĩ, với tư cách là người tiêu dùng hoặc cách bạn cảm nhận về điều gì đó.Chúng tôi tại WisePerks, không bao giờ chia sẻ / chuyển bất kỳ thông tin cá nhân nào cho khách hàng của chúng tôi và không có cuộc khảo sát nào của WisePerks cũng không bán hoặc cho thuê bất kỳ thông tin nào cho bên thứ ba.",

  "faq_ques9" : "Cuộc khảo sát này kéo dài bao lâu?",
  "faq_ans9" : "Hầu hết các cuộc khảo sát chỉ mất khoảng 10 đến 15 phút, hoặc thậm chí ít hơn.Tuy nhiên, theo thời gian, chúng tôi có thể có các khảo sát dài hơn.Với mỗi khảo sát, chúng tôi sẽ cho bạn biết trước bao lâu, nhưng hãy ghi nhớ, các khảo sát dài hơn cũng có phần thưởng cao hơn.",

  "faq_ques10" : "Có ai có thể tham gia các cuộc khảo sát của WisePerks không?",
  "faq_ans10" : "Bạn phải đủ 13 tuổi trở lên mới được đăng ký với WisePerks.Mặc dù đôi khi chúng tôi sẽ mời bạn trong các cuộc khảo sát để tham gia với các thành viên trẻ hơn trong gia đình của bạn.",

  "faq_ques11" : "Làm thế nào để bạn biết tôi sẽ đủ điều kiện cho một cuộc khảo sát?",
  "faq_ans11" : "Thuật toán của chúng tôi khớp với thông tin tiểu sử của bạn với các khảo sát có sẵn để đảm bảo bạn có được các cuộc khảo sát thích hợp nhất.Khi một cuộc khảo sát bắt đầu, thường là một vài câu hỏi đầu tiên xác định xem bạn có phải là người trả lời mà khách hàng của chúng tôi đang tìm kiếm cuộc khảo sát cụ thể đó hay không.Chúng tôi có một loạt các cuộc khảo sát tìm kiếm nhiều loại người khác nhau (ví dụ: những người xem phim thường xuyên, chơi một môn thể thao cụ thể, phụ nữ có trẻ em, v.v) và các cuộc khảo sát mới xuất hiện mỗi ngày!",

  "faq_ques12" : "Nghĩa vụ của tôi đối với WisePerks là gì?",
  "faq_ans12" : "Điều duy nhất chúng tôi mong đợi từ bạn là cung cấp ý kiến ​​và suy nghĩ của bạn một cách trung thực, vì khách hàng đang tìm kiếm câu trả lời trung thực và thực sự của bạn để cải thiện sản phẩm và dịch vụ của họ.Vui lòng cung cấp thông tin chính xác khi đăng ký làm thành viên và cập nhật bất kỳ thay đổi nào khi chúng xảy ra.",

  "faq_ques13" : "Tại sao tôi nên cung cấp thông tin về hộ gia đình, công nghệ hoặc sản phẩm tôi mua?",
  "faq_ans13" : "Đối với mỗi khảo sát, chúng tôi đang tìm kiếm một hồ sơ nhất định của người trả lời.Nếu chúng tôi biết từ thông tin tiểu sử của bạn rằng thông tin của bạn khớp với các yêu cầu của khách hàng, bingo!Chúng tôi gần như chắc chắn có thể đảm bảo rằng bạn sẽ có thể hoàn thành khảo sát thành công và kiếm được phần thưởng cho nó.Điều này về cơ bản làm tăng cơ hội của bạn được lựa chọn cho một cuộc khảo sát.",

  "faq_ques14" : "Khi nào tôi sẽ nhận được Thẻ quà tặng sau khi hoàn thành khảo sát WisePerks?",
  "faq_ans14" : "Những điểm bạn kiếm được sau khi hoàn thành một cuộc khảo sát được tích lũy trong thùng của bạn. Một khi những điểm này được nhận ra bạn có thể chuộc lại.",

  "faq_ques15" : "Tài khoản của tôi thực sự là gì?",
  "faq_ans15" : "Đây chỉ là một cách dễ dàng để bạn xem thông tin cá nhân của mình, thực hiện bất kỳ thay đổi nào theo yêu cầu, xem hoạt động khảo sát, phần thưởng kiếm được, mọi thứ ở một nơi.",

  "faq_ques16" : "Làm cách nào để huỷ tài khoản của tôi?",
  "faq_ans16" : "Với rất nhiều đến cách của bạn, chúng tôi chắc chắn bạn sẽ không muốn làm điều này. Tuy nhiên, trên trang tổng quan của bạn--> đi đến Settings--> quản lý tài khoản--> tắt tài khoản",

  "faq_ques17" : "Hệ thống điểm hoạt động như thế nào?",
  "faq_ans17p1" : "Mỗi khảo sát là cho số điểm nhất định. Sau khi hoàn thành khảo sát, những điểm này được thêm vào thu nhập của bạn. Bạn có thể đổi chúng khi bạn đạt đến ngưỡng tối thiểu.",
  "faq_ans17p2" : "Trong một số trường hợp do một số thách thức không lường trước được với kết nối hoặc sự cố, phản hồi của bạn có thể không được ghi lại ở cuối ứng dụng khách của chúng tôi. Sau đó chúng tôi phải đảo ngược những điểm đó từ tài khoản của bạn. Nếu bạn đã đổi các điểm đó và tài khoản của bạn có số dư thấp thì thu nhập của bạn sẽ ở mức âm. Những điều chỉnh này sẽ được thực hiện trong chu kỳ đổi quà tiếp theo của bạn.",

  "faq_ques18" : "Các điểm cho một cuộc khảo sát sẽ sớm được thêm vào số dư điểm của tôi trong bao lâu?",
  "faq_ans18" : "Điểm của bạn sẽ được cộng vào số dư điểm của bạn sau 48 giờ.",

  "faq_ques19" : "Ngưỡng tối thiểu để mua lại là gì?",
  "faq_ans19" : "Ngưỡng tối thiểu để quy đổi là 25000 điểm.Trung bình, bạn cần phải hoàn thành 8-12 khảo sát để đủ điều kiện được quy đổi.",

  "faq_ques20" : "Làm cách nào để chuyển đổi tiền thành thẻ quà tặng?Có tỷ lệ nào không?",
  "faq_ans20" : "Vâng. Tùy thuộc vào quốc gia của bạn, điểm được chuyển đổi thành tiền mặt Thẻ quà tặng.Tỷ lệ chuyển đổi như sau",
  "faq_ponits" : "Điểm",
  "faq_point" : "Điểm",
  "faq_cent" : "Cent",

  "faq_ques21" : "Bạn vẫn có thêm câu hỏi?",
  "faq_ans21p1" : "Hãy gửi email cho chúng tôi tại",
  "faq_ans21p2" : "và chúng tôi sẽ nhận lại cho bạn trong vòng 48 giờ.",

  "Profiling_profiledashboard" : "Bảng điều khiển hồ sơ", 
  "Profiling_completeprofile" : "Hoàn thành hồ sơ của bạn và nhận được whopping",
  "Profiling_completepoints" : "Điểm!",
  "Profiling_answercarefully" : "Các câu hỏi chung sau đây sẽ được sử dụng để tạo ra hồ sơ cá nhân của bạn để cung cấp cho bạn những cơ hội khảo sát tương lai tốt nhất. Xin vui lòng mất thời gian của bạn, trả lời một cách cẩn thận và truthfully.",

  "Profiling_cat_general" : "Chung",
  "Profiling_cat_personal" : "Cá nhân",
  "Profiling_cat_household" : "Hộ gia đình",
  "Profiling_cat_profession" : "Nghề nghiệp",

  "Profiling_bar_completed" : "% Hoàn thành",
  
  "Profiling_update_ans_button" : "Cập nhật và tiếp tục",
  "Profiling_save_ans_button" : "Lưu",
  "Profiling_sc_ans_button" : "Lưu & tiếp tục",

  "Category_thanku_msg1" : "Tốt đi",
  "Category_thanku_msg2" : "vài chi tiết và bạn sẽ có",
  "Category_thanku_msg3" : "điểm trong tài khoản của bạn.",

  "Category_thanku_update_msg1" : "Cảm ơn bạn đã cập nhật thông tin của bạn!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
   "Category_Continue_button" : "Tiếp tục",

  "OverAll_thanku_msg1" : "Cảm ơn bạn đã chia sẻ thông tin của bạn. Bạn đã kiếm được",
  "OverAll_thanku_msg2" : "Điểm.",

  "Survey_history_surveyhistory" : "Lịch sử khảo sát",
  "Survey_history_fromthisscreen" : "Từ màn hình này, bạn có thể xem tất cả các giao dịch của bạn.",
  "Survey_history_show" : "Hiển thị",
  "Survey_history_entries" : "Mục",
  "Survey_history_date" : "Ngày",
  "Survey_history_details" : "Chi tiết",
  "Survey_history_surveys" : "Cuộc điều tra",
  "Survey_history_pointsreceived" : "Điểm nhận được",
  "Survey_history_result" : "Kết quả",
  "Survey_history_status" : "Tình trạng",
  "Survey_history_status_complete" : "Hoàn thành",
  "Survey_history_status_disqualified" : "Loại", 
  "Survey_history_keyword" : "Từ khoá",
  "Survey_history_search" : "Tìm",
  "Survey_history_to" : "Để",
  "Survey_history_selectoption" : "Chọn tùy chọn",
  "Survey_history_earned" : "Kiếm được",
  "Survey_history_redeemed" : "Chuộc",
  "Survey_history_norecord" : "Không tìm thấy bản ghi",
  "Survey_history_Surveypts" : "Điểm khảo sát",
  "Survey_history_Promocode" : "Promo Mã",

  "My_transactions_mytransactions" : "Giao dịch của tôi",
  "My_transactions_fromthisscreen" : "Từ màn hình này, bạn có thể xem tất cả các giao dịch của bạn.",
  "My_transactions_show" : "Hiển thị",
  "My_transactions_entries" : "Mục",
  "My_transactions_transactionid" : "ID giao dịch",
  "My_transactions_date" : "Ngày",
  "My_transactions_details" : "Chi tiết",
  "My_transactions_points" : "Điểm",
  "My_transactions_status" : "Tình trạng",
  "My_transactions_keyword" : "Từ khoá",
  "My_transactions_search" : "Tìm",
  "My_transactions_to" : "Để",
  "My_transactions_selectoption" : "Chọn tùy chọn",
  "My_transactions_status_earned" : "Kiếm được",
  "My_transactions_status_redeemed" : "Chuộc",
  "My_transactions_status_pending" : "Chờ",
  "My_transactions_status_reverted" : "Trở lại",
  "My_transactions_status_declined" : "Từ chối",
  "My_transactions_norecord" : "Không tìm thấy bản ghi",
  "My_transactions_off_msg1" : "Sau ",
  "My_transactions_off_msg2" : ", tất cả các chi tiết giao dịch của bạn sẽ được xuất bản trên tab sách Điểm",

  "Passbook_heading" : "Sách điểm",
  "Passbook_show" : "Chỉ",
  "Passbook_entries" : "mục",
  "Passbook_search" : "Tìm kiếm",
  "Passbook_type" : "Thể loại",
  "Passbook_surveyid" : "Khảo sát",
  "Passbook_date" : "Ngày",
  "Passbook_selectoption" : "Chọn tùy chọn",
  "Passbook_credit" : "tín dụng",
  "Passbook_debit" : "Ghi nợ",
  "Passbook_survey_id" : "Khảo sát Id",
  "Passbook_survey_pts" : "Điểm khảo sát",
  "Passbook_earned_pts" : "Điểm",
  "Passbook_pending_pts" : "Điểm đang chờ giải quyết",
  "Passbook_promo_code" : "Mã khuyến mại",
  "Passbook_transaction_type" : "Loại giao dịch",
  "Passbook_balance" : "Thăng bằng",
  "Passbook_status" : "Trạng thái",
  "Passbook_type_soi" : "Đăng ký",
  "Passbook_type_doi" : "Email xác thực",
  "Passbook_type_profile" : "Hoàn thành hồ sơ",
  "Passbook_type_survey" : "Khảo sát",
  "Passbook_type_doir" : "Xác minh qua email",
  "Passbook_type_doirt" : "Xác minh email bằng cách giới thiệu",
  "Passbook_type_surveyr" : "Khảo sát cố gắng bằng",
  "Passbook_type_surveyrt" : "Khảo sát cố gắng bằng cách giới thiệu",
  "Passbook_type_reward" : "Phần thưởng",
  "Passbook_type_pending" : "Điều chỉnh",
  "Passbook_type_reverted" : "Điểm hoàn nguyên",
  "Passbook_type_declined" : "Yêu cầu từ chối",
  "Passbook_type_redeem" : "Chuộc lại",
  "Passbook_type_balance" : "Tiến về phía trước",
  "Passbook_balance_vary" : "Số dư thực tế có thể thay đổi",
  "Passbook_survey_debit_reason" : "Chúng tôi đánh giá cao những nỗ lực của bạn. Tuy nhiên do một số kết nối không kiểm soát được trong khi truyền dữ liệu, phản hồi của bạn không thể được đăng ký. Chúng tôi sẽ phải đảo ngược các điểm bạn nhận được trước đó cho khảo sát này. Xin lỗi vì sự bất tiện gây ra.",

  "Redeem_redeemed" : "Chuộc",
  "Redeem_redeemhistory" : "Chuộc lịch sử",
  "Redeem_history_norecord" : "Không tìm thấy bản ghi",
  "Redeem_date" : "Ngày",
  "Redeem_details" : "Chi tiết",
  "Redeem_points" : "Điểm",
  "Redeem_status" : "Tình trạng",
  "Redeem_lookslike" : "Hình như bạn bị mất xác minh tài khoản email của bạn.",
  "Redeem_hurry" : "Vội vàng! bạn nhận được 1000 điểm khi bạn xác minh.",
  "Redeem_pleasecheck" : "Vui lòng kiểm tra hộp thư đến của bạn và nhấp vào liên kết trong email xác nhận được gửi để kích hoạt tài khoản của bạn.",
  "Redeem_kindlycheck" : "Vui lòng kiểm tra thư mục spam/Junk của bạn cũng như cho email xác nhận.",
  "Redeem_getconfirmationemail" : "Đã không nhận được email xác nhận chưa?",
  "Redeem_resendconfirmationemail" : "Gửi lại email xác nhận",
  "Redeem_rewardredemptionrule" : "Phần thưởng Redemption Rule",
  "Redeem_completesurvey" : "Khảo sát hoàn chỉnh",
  "Redeem_earnpoints" : "Kiếm được điểm",
  "Redeem_redeemrewards" : "Chuộc lại phần thưởng",
  "Redeem_note" : "Lưu ý:",
  "Redeem_yourearnedpoints" : "Điểm kiếm được của bạn sẽ trở nên Redeemable sau khi điều tra vòng loại. Để chuộc lại phần thưởng, các điểm Redeemable tối thiểu trong tài khoản của bạn phải",
  "Redeem__points" : "Điểm.",
  "Redeem_availablebalance" : "Số dư có sẵn:",
  "Redeem_historydetails" : "Nhấn vào đây để xem chi tiết lịch sử cứu chuộc của bạn.", 
  "Redeem_reqsucc" : "Chúc mừng! Điểm của bạn đã được cứu chuộc. Vui lòng kiểm tra hộp thư đã đăng ký của bạn để biết thêm chi tiết.", 
  "Redeem_wisecheck_youhave" : "Xin chúc mừng! Bạn chỉ cần một cú nhấp chuột từ phần thưởng của bạn.",
  "Redeem_wisecheck_gcagainst" : "Thẻ quà tặng cho",
  "Redeem_wisecheck_points" : "Điểm.",
  "Redeem_wisecheck_issue" : "Hệ thống đã nắm bắt một số hoạt động không được công nhận trong tài khoản của bạn, do đó bạn không thể gửi yêu cầu chuộc lại. Xin vui lòng liên hệ tại",
  "Redeem_emailverify_issue" : "Để đổi điểm của bạn, chúng tôi sẽ yêu cầu bạn xác minh tài khoản email đã đăng ký của mình.",

  "Redeem_unsubscribe_issue" : "Bạn không thể gửi yêu cầu đổi quà vì bạn đã hủy đăng ký WisePerks. Vui lòng liên hệ với support@wiseperks.com để đăng ký lại.",
  "Redeem_wisecheck_message1" : "Bạn không thể đổi quà vì một số lý do bảo mật. Vui lòng liên hệ với bộ phận hỗ trợ",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Xác nhận",
  "Redeem_Cancel_button" : "Hủy bỏ",
  "Redeem_pending_hello" : "xin chào",
  "Redeem_pending" : "Điều chỉnh",
  "Redeem_pending_msg1" : "Tài khoản của bạn hiển thị điều chỉnh",
  "Redeem_pending_msg2" : "điểm sẽ được thực hiện. Vui lòng xác nhận để bắt đầu giao dịch.",

  "Dashboard_wisecheck_issue" : "Hệ thống đã ghi lại một số hoạt động không được công nhận trong tài khoản của bạn, do đó bạn không thể xem các bản khảo sát. Vui lòng liên hệ với support@wiseperks.com để biết thêm chi tiết.",
  "Dashboard_unsubscribe_issue" : "Bạn không thể xem bất kỳ cuộc khảo sát nào vì bạn đã hủy đăng ký WisePerks. Vui lòng liên hệ với support@wiseperks.com để nhận khảo sát lại.",


  "WC_DashboardMsg_1" : "Rất tiếc! Không có khảo sát nào phù hợp với hồ sơ của bạn kể từ bây giờ.",
  "WC_DashboardSubMsg_1" : "Đừng lo lắng, chúng tôi đang xáo trộn khoảng không quảng cáo của mình và sẽ sớm gửi khảo sát mới theo cách của bạn.",

  "WC_DashboardMsg_2" : "Chúng tôi đang làm mới Trang tổng quan của bạn. Vui lòng kiểm tra khảo sát mới sau một thời gian.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Cảm ơn bạn đã hoàn thành cuộc khảo sát. Bạn chỉ kiếm được response_points điểm. Đăng nhập vào trang tổng quan của bạn để xem tổng số điểm tích lũy của bạn.",
   "Survey_success_msg2" : "Cảm ơn bạn đã hoàn thành cuộc khảo sát. Bạn chỉ kiếm được response_points Điểm.",

  "Survey_success_but_nomore_survey" : "Chúc mừng! Bạn chỉ kiếm được response_points điểm để hoàn thành cuộc khảo sát. Xin vui lòng kiểm tra các cuộc điều tra sắp tới sau khi sometime.",
  
  "Survey_terminate_pnt_msg1" : "Hình như một số thông tin của bạn là hơi khác với yêu cầu. Chúng tôi đánh giá thời gian và nỗ lực của bạn, sẽ được thêm response_points điểm vào tài khoản của bạn. Chúng tôi đang tập trung vào việc mang lại cho bạn nhiều sinh lợi và trực quan khảo sát.",
  
  "Survey_terminate_nopnt_msg1" : "Có vẻ như một số câu trả lời của bạn hơi khác so với yêu cầu. Chúng tôi sẽ gửi nhiều bản khảo sát hơn cho bạn. Cảm ơn thời gian và công sức của bạn.",

  "TermsConditions_t&c" : "Điều khoản",
  "TermsConditions_WhenRegister" : "Khi bạn đăng ký",
  "TermsConditions_WhenYouCreate" : "Khi bạn tạo tài khoản cá nhân, chúng tôi sẽ hỏi bạn một số thông tin cá nhân, như đã đề cập ở đây:",
  "TermsConditions_YouCanUpdate" : "Bạn có thể cập nhật hoặc thay đổi thông tin Tiểu sử cơ bản của mình bất kỳ lúc nào.",
  "TermsConditions_YouCanOptOut" : "Bạn có thể chọn không tham gia hoặc đóng tài khoản của mình bất kỳ lúc nào.",
  "TermsConditions_WeRespectYour" : "Chúng tôi tôn trọng sự riêng tư của bạn và tận dụng tối đa công nghệ để bảo vệ an toàn thông tin của bạn khỏi việc truy cập vật lý và điện tử trái phép.",
  "TermsConditions_WeShareYour" : "Chúng tôi chỉ chia sẻ thông tin của bạn với các tổ chức, được yêu cầu để vận hành WiseWorks. Và những tổ chức đó cần phải chăm sóc các cam kết của chúng tôi đối với quyền riêng tư và bảo mật của bạn.",
  "TermsConditions_WhatInformation" : "Thông tin nào chúng tôi thu thập từ bạn",
  "TermsConditions_DirectlyCollected" : "Thu trực tiếp",
  "TermsConditions_EmailAddressAndPassword" : "Địa chỉ email và mật khẩu của tài khoản của bạn với chúng tôi.",
  "TermsConditions_YourResponse" : "Câu trả lời của bạn cho các câu hỏi về lược tả.",
  "TermsConditions_AnyCustomer" : "Bất kỳ thư từ dịch vụ khách hàng nào.",
  "TermsConditions_AnyPersonalDetails" : "Mọi chi tiết cá nhân bạn chia sẻ để đổi",
  "TermsConditions_AnyOtherInformation" : "Bất kỳ thông tin nào khác do bạn cung cấp trực tiếp.",
  "TermsConditions_UsageData" : "Dữ liệu sử dụng",
  "TermsConditions_YourIPAddress" : "Địa chỉ IP, vị trí địa lý và cookie của bạn",
  "TermsConditions_InformationAboutTheDevice" : "Thông tin về thiết bị bạn sử dụng",
  "TermsConditions_YourSurveyActivity" : "Hoạt động khảo sát của bạn, các điểm trong tài khoản của bạn, các khoản thanh toán bạn nhận được sau khi đổi.",
  "TermsConditions_HowDoWeUse" : "Cách chúng tôi sử dụng dữ liệu của bạn",
  "TermsConditions_SendingYouInvitation" : "Gửi email mời bạn tham gia cuộc khảo sát mà bạn đủ điều kiện.",
  "TermsConditions_DetectAndPrevent" : "Phát hiện và ngăn chặn hoạt động gian lận.",
  "TermsConditions_ImproveYourExperience" : "Cải thiện trải nghiệm của bạn trên nền tảng WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Câu trả lời của bạn được chuyển giao cho các bên thứ ba và đối tác liên kết của chúng tôi theo định dạng tổng hợp và không được xác định.",
  "TermsConditions_OurPartnersCanAsk" : "Các đối tác của chúng tôi có thể yêu cầu bạn cung cấp tên, địa chỉ email và thông tin liên hệ khác của bạn trong một cuộc khảo sát.",
  "TermsConditions_YouAreFreeToDecline" : "Bạn được tự do từ chối cung cấp chi tiết của bạn cho các đối tác của chúng tôi, nếu bạn đồng ý hơn là bạn cung cấp thông tin này trực tiếp cho họ và không cung cấp cho WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Thông tin của bạn sẽ được xử lý theo Chính sách bảo mật của doanh nghiệp có liên quan chứ không phải Chính sách bảo mật của WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Các đối tác của chúng tôi cam kết tôn trọng và bảo vệ quyền riêng tư của bạn.",
  "TermsConditions_WhatWeDont" : "Những gì chúng ta không làm với nó",
  "TermsConditions_WeDontSellOnThat" : "Chúng tôi không bán thông tin đó cho bất kỳ ai và chúng tôi không sử dụng thông tin đó để bán bất cứ thứ gì cho bạn. Chúng tôi sẽ luôn xin phép trước khi chia sẻ dữ liệu của bạn.",
  "TermsConditions_YourRightToControl" : "Quyền kiểm soát dữ liệu của bạn",
  "TermsConditions_YouHaveFullControl" : "Bạn có toàn quyền kiểm soát dữ liệu của mình.",
  "TermsConditions_YouCanAccess" : "Bạn có thể truy cập, cập nhật hoặc thay đổi thông tin hồ sơ cơ bản của mình bất cứ khi nào bạn muốn.",
  "TermsConditions_YouCanUnsubscribe" : "Bạn có thể hủy đăng ký và hủy kích hoạt tài khoản của mình.",
  "TermsConditions_YouAreFreeToGetYour" : "Bạn được tự do xóa hồ sơ của mình bằng cách gửi thư cho chúng tôi theo địa chỉ support@wiseperks.com. Chúng tôi sẽ cố gắng giải quyết mối quan ngại của bạn và nếu bạn vẫn muốn xóa tài khoản của mình, thì sẽ thực hiện trong vòng 72 giờ.",
  "TermsConditions_DataSecurity" : "Bảo mật dữ liệu",
  "TermsConditions_OurTechTeamWorks" : "Nhóm công nghệ của chúng tôi hoạt động thực sự khó khăn để giữ cho tất cả dữ liệu của bạn được an toàn.",
  "TermsConditions_WeMakeUseOfLatest" : "Chúng tôi sử dụng công nghệ và các biện pháp mới nhất để tránh bất kỳ việc lạm dụng hoặc truy cập trái phép dữ liệu của bạn. Tuy nhiên, do tính chất của môi trường trực tuyến, chúng tôi không thể đảm bảo 100% bảo mật dữ liệu.",
  "TermsConditions_WeTryOurBestToRule" : "Chúng tôi cố gắng hết sức để loại trừ các rủi ro, tuy nhiên không thể đảm bảo môi trường không có rủi ro. Bằng việc chấp nhận các Điều khoản và Điều kiện này, bạn thừa nhận và chấp nhận những rủi ro này.",
  "TermsConditions_AnyQuestions" : "nếu có thắc mắc gì, vui lòng liên hệ với chúng tôi",
  "TermsConditions_IfYouHaveAnyQueries" : "Nếu bạn có bất kỳ thắc mắc hoặc khiếu nại nào về chính sách bảo mật và thủ tục của chúng tôi. Vui lòng gửi email cho chúng tôi tại support@wiseperks.com và chúng tôi sẽ liên hệ lại với bạn trong vòng 48 giờ.",

  "Validation_PleaseEnterProperDate" : "Vui lòng nhập ngày sinh thích hợp",
  "Validation_PleaseSelectOne" : "Làm ơn chọn một giải pháp!",
  "Validation_PleaseSelectValidOptions" : "Vui lòng chọn Tùy chọn hợp lệ!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Vui lòng chọn ít nhất một tùy chọn",
  "Validation_PleaseInsertTheZip" : "Vui lòng Chèn Mã Zip / Mã Bưu điện.",

  "Releases_releases" : "Chí",
  "Releases_theUseOfThis" : "Việc sử dụng trang web này là tùy thuộc vào các điều khoản sau đây sử dụng.",
  "Releases_featuresOfWP" : "Tính năng của WP Chí",
  "Releases_updatedOn" : "Cập Nhật trên",
  "Releases_WisePerksNow" : "WisePerks bây giờ hỗ trợ nhiều ngôn ngữ",
  "Releases_youCanWalkThourgh" : "Bạn có thể đi bộ thourgh các trang web với dễ dàng hơn nhiều bây giờ như WisePerks có sẵn trong ngôn ngữ Preffered của bạn.",
  "Releases_languagesAvailable" : "Ngôn ngữ có sẵn:",
  "Releases_EarnMore" : "Kiếm được nhiều hơn với hệ thống điểm mới của chúng tôi",
  "Releases_theNewWisePerksAccumulates" : "Các WisePerks mới tích lũy điểm vào tài khoản của bạn. Những điểm này giữ một giá trị cao hơn nhiều và rất dễ kiếm và chuộc lại. Với nhiều lựa chọn chuộc để chọn từ, chúng tôi chắc chắn bạn sẽ thưởng thức các cuộc khảo sát lấy kinh nghiệm.",
  "Releases_accumulatePoints" : "Tích lũy điểm với Hassel miễn phí redeemption",
  "Releases_youGetTheBenefit" : "Bạn sẽ có được lợi ích của việc thu nhập như nhau không. của các điểm, không phân biệt các loại tiền tệ bạn muốn chuộc chúng in",
  "Releases_surveyDisqualification" : "Khảo sát là không có thêm một tin xấu",
  "Releases_youEarnPoints" : "Bạn kiếm được điểm ngay cả khi bạn không đủ điều kiện để khảo sát một, bạn bắt đầu trả lời.",
  "Releases_newAndImproved" : "Mới và cải thiện bố trí",
  "Releases_theFreshLookOf" : "Nhìn tươi của WisePerks sẽ làm cho bạn rơi vào tình yêu với nó. Nó bây giờ là dễ dàng hơn nhiều để sử dụng và sẽ là một kinh nghiệm tuyệt vời cho bạn.",
  "Releases_whatAvatarAreYouToday" : "Hôm nay bạn là avatar nào",
  "Releases_youCanNowCreate" : "Bây giờ bạn có thể tạo avatar của riêng bạn để tính năng trên bảng điều khiển của bạn.",
  "Releases_freshAndHotSurveys" : "Cuộc điều tra tươi và nóng trên bảng điều khiển của bạn",
  "Releases_youDontHaveTo" : "Bạn không phải lo lắng về refresing trang tổng quan của bạn để xem các cuộc điều tra mới, chúng tôi sẽ giữ cho bảng điều khiển của bạn dân cư với các cuộc điều tra mới nhất tự động.",
  "Releases_promoCode" : "Tính năng mã khuyến mãi",
  "Releases_promoCodeInfo" : "Theo yêu cầu phổ biến, WisePerks mang đến Mã khuyến mại cho những người tham gia hội thảo mỗi tuần. Cách nhanh chóng để lấy phần thưởng nhiều hơn.",
  "Releases_referFriend" : "Giới thiệu bạn bè",
  "Releases_referFriendInfo" : "Nhận bạn bè của bạn liên kết với WisePerks và nhận phần thưởng thú vị. Xem ra cho tùy chọn Giới thiệu bạn bè trong tài khoản của bạn.",

  "Dashboard_Promocode_Usepromo" : "Sử dụng Promo Mã",
  "Dashboard_Promocode_to" : "Để",
  "Dashboard_Promocode_earnminimum_1" : "kiếm tối thiểu",
  "Dashboard_Promocode_earnminimum_2" : "nhưng điểm cộng",
  "Dashboard_Promocode_minimum" : "tối thiểu",
  "Dashboard_Promocode_minimumextrapoints" : "thêm điểm trên mỗi khảo sát hoàn thành ngày hôm nay",
  "Dashboard_Promocode_apply" : "Áp dụng",
  "Dashboard_Promocode_message1" : "Mã khuyến mãi không hợp lệ!",
  "Dashboard_Promocode_message2" : "Xin chúc mừng! Bạn sẽ nhận được tối thiểu",
  "Dashboard_Promocode_message3" : "thêm vào mỗi cuộc khảo sát hoàn thành ngày hôm nay!",

  "Dashboard_modal_Enjoythefestive" : "Thưởng thức tinh thần lễ hội bằng việc thu nhập tối thiểu",
  "Dashboard_modal_Extrapointson" : "thêm điểm trên mỗi cuộc khảo sát bạn hoàn thành ngày hôm nay.",

  "refer_friend_title" : "Giới thiệu bạn bè và nhận thưởng",
  "refer_friend_step_first": "Mời một người bạn đến WisePerks",
  "refer_friend_step_second": "Bạn bè đăng ký thành công trên WisePerks",
  "refer_friend_step_third": "Bạn nhận được each_referral điểm",
  "refer_friend_link_title": "Mời một người bạn bây giờ!",
  "refer_friend_share_title": "Chia sẻ liên kết",
  "refer_friend_guideline_title": 'Làm thế nào "THAM KHẢO MỘT BẠN B" "sẽ có lợi cho bạn như thế nào:',
  "refer_friend_guideline_one": "Vui lòng chia sẻ liên kết được cung cấp ở trên với giới thiệu của bạn.",
  "refer_friend_guideline_two": "Bạn có thể giới thiệu nhiều người như bạn muốn, sau khi 5 người giới thiệu của bạn xác minh tài khoản của họ và bắt đầu hoạt động khảo sát, điểm giới thiệu kiếm được của bạn sẽ được thêm vào tài khoản của bạn.",
  "refer_friend_guideline_three": "Bạn nhận được each_referral điểm cho mỗi lượt giới thiệu thành công (với tài khoản email được xác minh và hoạt động khảo sát).",
  "refer_friend_guideline_four": "Điểm sẽ được kiếm trong hai giai đoạn, partial_referral khi xác minh email và partial_second_referral cho hoạt động khảo sát bởi người giới thiệu của bạn.",
  "refer_friend_guideline_five": "Sau 5 lần giới thiệu thành công (với tài khoản email được xác minh và hoạt động khảo sát), liên kết giới thiệu sẽ bị vô hiệu hóa.",
	
  }
  
