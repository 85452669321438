import { NgModule } from '@angular/core';

@NgModule({
  imports: [
  ],
  declarations: [],
  exports:[
  ]
})
export class DashboardModule { }
