import { Component, OnInit } from '@angular/core';
import * as myLangGlobal from '../../shared/globals';
import { TranslateService } from '../../shared/translate.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

 showPPLoader:boolean = true;

  constructor(private translateService: TranslateService, private title: Title) { 
    let currentPreferLang = localStorage.getItem('PreferredLanguage');

    if(currentPreferLang !== null && myLangGlobal.LangArr.indexOf(currentPreferLang) !== -1){
      this.translateService.setLanguage(currentPreferLang);
    }
  }

  ngOnInit() {
    this.title.setTitle('WisePerks | Terms and Conditions');
  }

  StopLoading(flag){
    if(flag == 1){
      this.showPPLoader = false;
    }
  }

} 