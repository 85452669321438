import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  background_img = "background1.png";
  header_style:any="";

  showhomeLoader = true;
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle('WisePerks | Paid Online Surveys | Earn Money Online');
  }


  HomeBannerIamge(response){
    this.background_img = response.home_banner_img;
    
    this.header_style = {
      'background-size':'auto 80%',
      'background':'#354467 url(../../../assets/images/users/home/'+this.background_img+') 50% 100% no-repeat'
    }
  }

  StopLoading(flag){
    if(flag == 1){
      this.showhomeLoader = false;
    }
  }
} 
