import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PanelistService } from '../shared/panelist.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router : Router, private _panelistService : PanelistService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(localStorage.getItem('currentLoginPanelist') !==null){
        //return true;
        let panelistId = localStorage.getItem('currentLoginPanelist');
        return this._panelistService.getPanelistInfo(panelistId).map((resp:any)=>{
          if(resp.auth == true && resp.success == true){
            this._panelistService.SetGlobalPanelistInfo(resp);
            if(resp.data['step']>=1){
              return true;
            } else {
              this.router.navigate(['/demographics']);
              return false;
            }
          } else {
            this.router.navigate(['']);
            return false;
          }
      }).catch(()=>{
        localStorage.removeItem('currentLoginPanelist');
        //this.router.navigate(['']);
        return Observable.of(false);
      });

      } else {
        this.router.navigate(['']);
        return false;
      }
  }
}
