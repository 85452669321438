import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angular5-social-login";

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';

import { TranslateService } from './shared/translate.service';
import { TranslatePipe } from './shared/translate.pipe';
import { ReplcePointPipe } from './shared/replce-point.pipe';
import { PanelistService } from './shared/panelist.service';
import { ServeyService } from './shared/servey.service';
import { CookieService } from 'ngx-cookie-service';

import { HomeModule } from './home/home.module';
import { DashboardModule } from './dashboard/dashboard.module';
/*import { UserRoutingModule } from './user-routing.module';*/
import {ModuleRouting} from './user-routing.module';

import { HomeComponent } from './home/home.component';
import { HomeHeaderComponent } from './home/home-header/home-header.component';
import { HomeRegisterComponent } from './home/home-register/home-register.component';
import { HomeContentComponent } from './home/home-content/home-content.component';
import { HomeTestimonialComponent } from './home/home-testimonial/home-testimonial.component';
import { HomeFooterComponent } from './home/home-footer/home-footer.component';
import { ProfileComponent } from './home/profile/profile.component';
import { FaqComponent } from './home/faq/faq.component';
import { PrivacyPolicyComponent } from './home/privacy-policy/privacy-policy.component';
import { PrivacyPolicyExtendedComponent } from './home/privacy-policy-extended/privacy-policy-extended.component';
import { FieldErrorDisplayComponent } from './home/field-error-display/field-error-display.component';
import { ResetPasswordComponent } from './home/reset-password/reset-password.component';
import { HomeVerifyEmailComponent } from './home/home-verify-email/home-verify-email.component';
import { UnsubscribeComponent } from './home/unsubscribe/unsubscribe.component';
import { TermsConditionsComponent } from './home/terms-conditions/terms-conditions.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardLeftPanelComponent } from './dashboard/dashboard-left-panel/dashboard-left-panel.component';
import { ProfilingComponent } from './dashboard/profiling/profiling.component';
import { ProfilingCategoryComponent } from './dashboard/profiling/profiling-category/profiling-category.component';
import { ProfilingQuestionsComponent } from './dashboard/profiling/profiling-questions/profiling-questions.component';
import { SurveyHistoryComponent } from './dashboard/survey-history/survey-history.component';
import { ReferFriendComponent } from './dashboard/refer-friend/refer-friend.component';
import { TransactionHistoryComponent } from './dashboard/transaction-history/transaction-history.component';
import { RedeemComponent } from './dashboard/redeem/redeem.component';
import { PostbackComponent } from './dashboard/postback/postback.component';
import { SurveyLinkComponent } from './dashboard/survey-link/survey-link.component';
import { ReleasesComponent } from './dashboard/releases/releases.component';
import { DashboardFooterComponent } from './dashboard/dashboard-footer/dashboard-footer.component';
import { PassbookComponent } from './dashboard/passbook/passbook.component';

import { AuthGuard } from './auth/auth.guard';
import { LoginGuard } from './auth/login.guard';
import { ProfileGuard } from './auth/profile.guard';

/* Service Start Here */
import {HomeService } from './home/shared/home.service';


import { CountoModule }  from 'angular2-counto';

import { MyDatePickerModule } from 'mydatepicker';
import {NgxPaginationModule} from 'ngx-pagination';
import { MailtoPipe } from './shared/mailto.pipe';
import { LinkifyPipe } from './shared/linkify.pipe';
import { ReferralPointPipe } from './shared/referral-point.pipe';
import { ReferralPartialPointPipe } from './shared/referral-partial-point.pipe';
import { ReferralSecondPartialPointPipe } from './shared/referral-second-partial-point.pipe';
import { DynamicYearPipe } from './shared/dynamic-year.pipe';


// Configs 
export function getAuthServiceConfigs() {
    let config = new AuthServiceConfig(
        [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("196177447786233")
          }
          /*{
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("874278469401139")
          }
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("Your-Google-Client-Id")
          },*/
        ]
    );
    return config;
  }

@NgModule({
    declarations: [
        HomeComponent,
        HomeHeaderComponent,
        HomeRegisterComponent,
        HomeContentComponent,
        HomeTestimonialComponent,
        HomeFooterComponent,
        ProfileComponent,
        FaqComponent,
        PrivacyPolicyComponent,
	PrivacyPolicyExtendedComponent,
        ResetPasswordComponent,
        UnsubscribeComponent,
        TermsConditionsComponent,
        HomeVerifyEmailComponent,
        FieldErrorDisplayComponent,
        DashboardComponent,
        DashboardLeftPanelComponent,
        ProfilingComponent,
        ProfilingCategoryComponent,
        ProfilingQuestionsComponent,
        SurveyHistoryComponent,
        ReferFriendComponent,
        TransactionHistoryComponent,
        RedeemComponent,
        PostbackComponent,
        SurveyLinkComponent,
        ReleasesComponent,
        DashboardFooterComponent,
        TranslatePipe,
        ReplcePointPipe,
        MailtoPipe,
        LinkifyPipe,
        ReferralPointPipe,
        ReferralPartialPointPipe,
        ReferralSecondPartialPointPipe,
        DynamicYearPipe,
        PassbookComponent
    ],
    imports: [
        BrowserModule,
        ModuleRouting,
        DashboardModule,
        HttpClientModule,
        CommonModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        SocialLoginModule,
        CountoModule,
        MyDatePickerModule,
        NgxPaginationModule
    ],
    providers: [ TranslateService, PanelistService, AuthGuard, LoginGuard, ProfileGuard, HomeService, ServeyService, CookieService,
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        },
        {
            provide : HTTP_INTERCEPTORS,
            useClass : AuthInterceptor,
            multi : true
        }
    ], 
    exports: [TranslatePipe,ReplcePointPipe,ReferralPointPipe,DynamicYearPipe],
    bootstrap: []
})
export class UserModule {}
