import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { HomeService } from '../home/shared/home.service';
import "rxjs/add/operator/do";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router : Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        if(req.headers.get('No-Auth') == "True")
            return next.handle(req.clone());


        if (!req.headers.has('Content-Type')) {
            var str = req.url;
            var n = str.lastIndexOf('/');
            var result = str.substring(n + 1);
            
            if(result != "image_upload"){ // we not use for image upload
                
                if(result == 'answers' && req.body !== null && req.body != ""){
                    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json;charset=utf-8') });
                } else {
                    req = req.clone({ headers: req.headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8') });
                }
            }
        }
        
        const clonedreq = req.clone({
            headers: req.headers.append('X-Access-Token','693fb8c319a11a547ba89b11b0e61081')
        });

        //console.log(clonedreq);
        return next.handle(clonedreq)
            .do(
                succ => { },
                err => { 
                    if(err.status === 401)
                        this.router.navigateByUrl('');
                }
            );
        
    }
}