// Dutch
export const TRANSLATIONS_NL = {
  "home_header_selectlang": "Selecteer taal",
  "home_header_loginbutton": "Log in",
  "home_header_signupbutton": "Aanmelden",
  "home_header_homebutton": "Huis", 
  
  "home_register_bannertxt1": "Hier jouw",
  "home_register_bannertxt2": "gedachten",
  "home_register_bannertxt3": "kan je halen",
  "home_register_bannertxt4": "beloning$",
  "home_register_bannertxt5": "Ontvang een bonus",
  "home_register_bannertxt6": "Op SignUp",
  "home_register_fbsignup": "AANMELDEN MET FACEBOOK",
  "home_register_fblogin": "INLOGGEN MET FACEBOOK",
  "home_register_or": "OF",
  "home_register_emailsignup": "Meld je aan met e-mail",
  "home_register_emaillogin": "Inloggen met e-mail",
  "home_register_fpass": "Wachtwoord vergeten?",
  "home_register_fname": "Voornaam",
  "home_register_lname": "Achternaam",
  "home_register_email": "E-mailadres",
  "home_register_pass": "Wachtwoord",
  "home_register_cpass": "bevestig wachtwoord",
  "home_register_tcpp": "Door mee te doen, gaat u akkoord met onze",
  "home_register_tcpptandcond": "algemene voorwaarden",
  "home_register_tcppand": "en",
  "home_register_tcppprivacy": "Privacybeleid",
  "home_register_join": "Doe met ons mee. Het is gratis",
  "home_register_login": "Log in",
  "home_register_name_required" : "Nama diperlukan.",
  "home_register_email_required" : "E-mel diperlukan.",
  "home_register_invalid_email" : "Format E-mel tidak sah",
  "home_register_password_required" : "Kata laluan diperlukan.",
  "home_register_password_lower_digit" : "Kata laluan mesti 6 digit diperlukan",
  "home_register_password_higher_digit" : "Kata laluan mesti digunakan 15 digit diperlukan",
  "home_register_cpassword_required" : "Sahkan kata laluan diperlukan",
  "home_register_cpassword_lower_digit" : "Sahkan Kata Laluan mesti diperlukan 6 digit",
  "home_register_cpassword_higher_digit" : "Sahkan Kata Laluan mesti digunakan 15 digit yang diperlukan",
  "home_register_cpassword_not_match" : "Sahkan Kata Laluan tidak sepadan",

  "home_register_fp_not_worry" : "Vul uw geregistreerde e-id in.", 
  "home_register_fp_resetpass_msg" : "Om uw wachtwoord te resetten, vul uw geregistreerde e-id in.", 
  "home_register_fp_enter_email_msg" : "Vul hier uw e-mail", 
  "home_register_fp_submit_button" : "Opslaan", 
  "home_register_fp_temporary_sent_pass_msg" : "Een Reset Password link is verstuurd naar uw geregistreerde e-id. Werk uw wachtwoord zo vroeg mogelijk bij.", 
  
  "home_works_howitworks": "Hoe het werkt?",
  "home_works_howitworkst1": "Stel jezelf voor", 
  "home_works_howitworksd1": "Word lid, meld je aan",
  "home_works_howitworkst2": "Ontvang enquêtes",
  "home_works_howitworksd2": "Begin met het ontvangen van intuïtieve en eenvoudige enquêtes",
  "home_works_howitworkst3": "Verdien punten",
  "home_works_howitworksd3": "Geef je beste antwoorden en verzamel punten",
  "home_works_howitworkst4": "verlossen",
  "home_works_howitworksd4": "Wissel je punten in voor vele opwindende beloningen",
  
  "home_aboutus_whoweare": "Wie we zijn?",
  "home_aboutus_whowearep1": "Bij WisePerks werken we hard om ervoor te zorgen dat de tijd die u online doorbrengt een lonende ervaring is. Wij werken met honderden bedrijven die graag uw feedback hebben, weten hoe u denkt over hun producten of diensten. U geeft uw mening over verschillende diensten, maar geen van die diensten beloont u daadwerkelijk voor uw tijd, in tegenstelling tot WisePerks - waarbij elke enquête waaraan u deelneemt u geweldige geldbeloningen voor uw tijd oplevert.",
  "home_aboutus_whowearep2": "We waarderen uw feedback en willen dat u wordt beloond voor uw tijd. We hebben enquêtes over verschillende onderwerpen om aan uw interesses te voldoen en ze gemakkelijk te beantwoorden. Je kunt gemakkelijk elke maand rond de $ 50- $ 100 verdienen door verschillende enquêtes in te vullen. Laten we samenwerken om het mogelijk te maken.",
  
  "home_earn_more_surabt": "Welke enquêtes vragen?",
  "home_earn_more_bp": "Business experts",
  "home_earn_more_consumers": "Consument",
  "home_earn_more_hc": "Gezondheid & fitness",
  "home_earn_more_entertainment": "Leuke & Fun",
  "home_earn_more_gaming": "Gaming freaks",
  "home_earn_more_tech": "Tech savvy",
  "home_earn_more_bonus_survey": "Bonus overzicht", 
  
  "home_reward_whatuearn": "Wat verdien je?",
  "home_reward_whatuearnp1": "We houden dingen graag simpel. U krijgt punten voor alle met succes voltooide enquêtes. Hoe meer enquêtes u voltooit, hoe meer punten u verdient. De punten kunnen worden ingewisseld voor Geschenkenkaarten naar keuze nadat je een bepaalde drempel hebt overschreden.",
  "home_reward_whatuearnp2": "Als u klaar bent om te verzilveren, kiest u de gewenste optie - Amazon, Starbucks, iTunes en vele anderen. We geven ook Bonussen uit en danken God Zijn aanbiedingen op vrijdag van tijd tot tijd.",
  
  "home_counter_earnedover": "Leden hebben verdiend",
  
  "home_contact_getintouch": "Neem contact op",
  "home_contact_emailus": "Voor vragen kunt u ons een e-mail sturen op",
  
  "home_footer_faq": "Veelgestelde vragen",
  "home_footer_pp": "Privacybeleid",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Aankomende functies", 
  "home_footer_copyright": "Copyright © current_year WisePerks. Alle rechten voorbehouden.",

  "home_header_home": "Huis", 
  "home_footer_toknowmore": "Om meer te weten",
  "home_footer_createacc": "Maak gratis een account aan en word lid van onze duizenden gelukkige leden.",
  "home_footer_registernow": "Registreer nu",

  "home_reset_password" : "Wachtwoord opnieuw instellen", 
  "home_reset_pass_content" : "U hebt gevraagd om wachtwoord opnieuw in te stellen voor", 

  "dashboard_sidebar_selectavatar" : "Selecteer je avatar", 
  "dashboard_sidebar_uploadavatar" : "Klik hier om uw afbeelding te uploaden",
  "dashboard_sidebar_pbank" : "Punten Bank",
  "dashboard_sidebar_accumulated" : "Verdiend",
  "dashboard_sidebar_realized" : "Realiseerde",
  "dashboard_sidebar_pending" : "aanpassingen",
  "dashboard_sidebar_earned_tooltip" : "Punten die u tot nu toe hebt verzameld.",
  "dashboard_sidebar_pending_tooltip" : "Punten die moeten worden teruggeboekt van uw account.",
  "dashboard_sidebar_main" : "Hoofd",
  "dashboard_sidebar_dashboard" : "Dashboard",
  "dashboard_sidebar_profile" : "Profiel",
  "dashboard_sidebar_updateprofile" : "Profiel bijwerken",
  "dashboard_sidebar_shistory" : "Enquêtegeschiedenis",
  "dashboard_sidebar_mtrans" : "Mijn transactie",
  "dashboard_sidebar_passbook" : "Puntenboek",
  "dashboard_sidebar_redeem" : "verlossen",
  "dashboard_sidebar_refer_friend" : "Verwijs vriend",
  "dashboard_sidebar_logout" : "Uitloggen",
  
  "dashboard_sidebar_tutorial1" : "Toont u alle beschikbare enquêtes en wat u verdienen van hen.",
  "dashboard_sidebar_tutorial2" : "Beantwoord enkele vragen hier om ons te laten weten dat u beter en ons helpen bij het sturen van je enquêtes van uw interesse. Let op voor sommige vragen zou je niet in staat zijn om uw antwoorden te updaten na het indienen.",
  "dashboard_sidebar_tutorial2update" : "U uw reacties hier, maar weinig vragen zullen niet beschikbaar zijn voor de actualisering.",
  "dashboard_sidebar_tutorial3" : "Hier vindt u alle enquêtes die u hebt geprobeerd.",
  "dashboard_sidebar_tutorial4" : "Helpt u bij het bijhouden van al uw transacties op WisePerks.",
  "dashboard_sidebar_tutorial5" : "Enkele snelle stappen om uw gerealiseerde punten om te zetten in beloningen.",
  "dashboard_sidebar_tutorial6" : "Bekijk de volledige transactiegeschiedenis van de punten die zijn gedebiteerd / bijgeschreven op uw account.",
  "dashboard_sidebar_gotit" : "Snap het",

  "dashboard_sidebar_note" : "Houd er rekening mee dat",
  "dashboard_sidebar_note1" : "Alle antwoorden moeten eerlijk worden beantwoord.",
  "dashboard_sidebar_note2" : "Wees alstublieft specifiek en nauwkeurig met open reacties.",
  "dashboard_sidebar_note3" : "Niet snel! Minimale afronding van de enquête is ingesteld.",
  "dashboard_sidebar_note4" : "Geef consistente antwoorden als dezelfde vragen worden gesteld.",
  "dashboard_sidebar_note5" : "Nieuwe enquêtes zullen worden toegevoegd en gesloten degenen zullen automatisch worden verwijderd uit uw dashboard.",
  "dashboard_sidebar_note6" : "Verdien enkele snelle punten alleen door verwijzingen.",

  "dashboard_setting_hi" : "Hoi",
  "dashboard_setting_minfo" : "Lidmaatschapsinfo",
  "dashboard_setting_macc" : "Beheer account",
  "dashboard_setting_upass" : "Vernieuw wachtwoord",
  "dashboard_setting_yourname" : "Jouw naam is",
  "dashboard_setting_fname" : "Voornaam",
  "dashboard_setting_lname" : "Achternaam",
  "dashboard_setting_save" : "Wijzigingen opslaan",
  "dashboard_setting_bornon" : "Je bent geboren op",
  "dashboard_setting_youare" : "Je bent een",
  "dashboard_setting_postal" : "Uw postcode is",
  "dashboard_setting_emailus" : "Voor alle hulp of vragen, stuur dan een e-mail naar",
  "dashboard_setting_membersince" : "U bent sindsdien lid",
  "dashboard_setting_taken" : "Jij hebt genomen",
  "dashboard_setting_completed" : "enquêtes en voltooid",
  "dashboard_setting_ofthem" : "van hen.",
  "dashboard_setting_earned" : "Je hebt verdiend",
  "dashboard_setting_pts" : "points",
  "dashboard_setting_started" : "sinds je begon.",
  "dashboard_register_email" : "U bent geregistreerd bij ons van",
  "dashboard_setting_preferences" : "Beheer uw voorkeuren",
  "dashboard_setting_emailoptout" : "Afmelden voor e-mail",
  "dashboard_setting_futureemails" : "Afmelden voor toekomstige e-mails van WisePerks.",
  "dashboard_setting_futureemailsflash" : "Onze e-mails houden u op de hoogte van alle lucratieve enquêtes voor u beschikbaar. Weet u zeker dat u ze niet wilt ontvangen.",
  "dashboard_setting_nrecemail" : "Onze e-mails houden u op de hoogte van alle lucratieve enquêtes voor u beschikbaar. Weet je zeker dat je niet wilt stoppen.",
  "dashboard_setting_emailout_modalmsg" : "Weet u zeker dat u wilt opt-out van het ontvangen van toekomstige e-mails van WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Bent u zeker om te beginnen met het ontvangen van toekomstige e-mails van WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Je hebt met succes stopt het ontvangen van e-mails van WisePerks",
  "dashboard_setting_emailin_succmsg" : "Je hebt met succes beginnen met het ontvangen van e-mails van WisePerks",
  "dashboard_setting_deactivateacc" : "account deactiveren",
  "dashboard_setting_disableprofile" : "Als u uw account deactiveert, wordt uw profiel uitgeschakeld door WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Weet u zeker dat u uw account wilt deactiveren op WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Als u klachten of feedback, dan u met ons delen. Wij zouden het op prioriteit aanpakken. Geef ons een kans. Weet u zeker dat u uw account bij ons wilt verwijderen.",
  "dashboard_setting_changelang" : "Wijzig taal",
  "dashboard_setting_preferredlang" : "Selecteer de taal van uw voorkeur",
  "dashboard_setting_updatepass" : "Vernieuw wachtwoord",
  "dashboard_setting_plsupdate" : "Gelieve uw wachtwoord hieronder te updaten.",
  "dashboard_setting_oldpass" : "Oud Wachtwoord",
  "dashboard_setting_enteroldpass" : "Voer het oude wachtwoord in",
  "dashboard_setting_newpass" : "Nieuw paswoord",
  "dashboard_setting_enternewpass" : "Voer een nieuw wachtwoord in",
  "dashboard_setting_confpass" : "Bevestig wachtwoord",
  "dashboard_setting_enternewpassagain" : "Voer opnieuw een nieuw wachtwoord in",
  "dashboard_setting_update" : "Bijwerken",

  "dashboard_doi_welcome" : "Welkom",
  "dashboard_doi_welbck" : "Welkom terug",
  "dashboard_doi_accntverified" : "Het lijkt erop dat je gemist verifiëren van uw e-mail account.",
  "dashboard_doi_getaccess" : "Haast! krijg je",
  "dashboard_doi_getaccess2" : "punten te controleren.",
  "dashboard_doi_activateacc" : "Controleer uw inbox en klik op de link in de accountverificatie e-mail verzonden naar uw geregistreerde e-mail account.",
  "dashboard_doi_chckspam" : "Vriendelijk, Controleer uw spam/junk map voor het geval u niet in staat zijn om de verificatie e-mail te vinden.",
  "dashboard_doi_didntgetconf" : "Heb je nog geen verificatie e-mail ontvangen?",
  "dashboard_doi_resendconf" : "E-mail verificatie opnieuw verzenden",
  "dashboard_doi_resendconfsucc" : "Verificatie e-mail is verzonden naar uw geregistreerde e-mail.", 
  
  "dashboard_survey_availsurveys" : "Beschikbare enquêtes",
  "dashboard_survey_wisechecksurveys1" : "U bent niet in staat om enquêtes te zien vanwege een aantal veiligheidsredenen. Neem contact op met ondersteuning op",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Niet beschikbaar enquêtes voor afmelden reden",
  "dashboard_featured" : "Aanbevolen",
  "dashboard_profile_survey" : "Profiel enquête",
  "dashboard_survey_start" : "Begin",
  "dashboard_survey_on" : "Op",
  "dashboard_survey_off" : "Uit",
  "dashboard_survey_point": "Punt",
  "dashboard_survey_points" : "Punten",
  "dashboard_survey_minute" : "Minuut",
  "dashboard_survey_minutes" : "Minuten",
  
  "demography_hi" : "Hallo",
  "demography_welcome" : "Welkom bij WisePerks. Je hebt net verdiend jezelf",
  "demography_points" : "punten door te registreren bij ons.",
  "demography_share" : "Gelieve te delen weinig meer details in het onderstaande formulier, om ons te helpen bij het brengen van relevante enquêtes aan jou.",

  "demography_gender" : "ik ben een",
  "demography_male" : "Mannetje",
  "demography_female" : "Vrouw",
  "demography_birthday" : "Verjaardag",
  "demography_month" : "Maand", 
  "demography_day" : "Dag", 
  "demography_year" : "Jaar",
  "demography_jan" : "januari-",
  "demography_feb" : "februari",
  "demography_mar" : "maart",
  "demography_apr" : "april",
  "demography_may" : "mei",
  "demography_jun" : "juni-",
  "demography_jul" : "juli-",
  "demography_aug" : "augustus",
  "demography_sep" : "september",
  "demography_oct" : "oktober",
  "demography_nov" : "november",
  "demography_dec" : "december",
  "demography_hearabout" : "Waar heb je WisePerks gehoord?",
  "demography_opt1" : "Selecteer het dichtstbijzijnde dat van toepassing is",
  "demography_opt2" : "Email nieuwsbrief",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "forum van blog",
  "demography_opt5" : "Vriend",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "Zoekmachine",
  "demography_opt8" : "TV-Commercial",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Website",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Andere",
  "demography_zipcode": "Postcode",
  "demography_submit" : "Opslaan",

  "privacypolicy_privacypolicy" : "Privacybeleid",
  "privacypolicy_privacyisimp" : "Uw privacy is belangrijk voor ons.",
  "privacypolicy_lastupdated" : "Laatst geupdate op",

  "privacypolicy_para1" : 'WiseWorks (samen met zijn dochterondernemingen, het "bedrijf") bezit en exploiteert een aantal verschillende websites, mobiele apps en interactieve services, waaronder WISEample.com, Flapbucks.com, WisePerks.com en anderen (samen de " WiseWorks-sites ").Dit privacybeleid ("Privacybeleid") is van toepassing op de WiseWorks-sites en op alle functies, mobiele toepassingen, e-mails, onlineservices en andere functionaliteiten, ongeacht of deze via een computer, mobiel apparaat of anderszins toegankelijk zijn en de services die op of via de WiseWorks-sites en -functies (de "Services").Dit privacybeleid heeft alleen betrekking op informatie die door het bedrijf is verzameld via de WiseWorks-sites en -functies en onze services en heeft geen betrekking op informatie die het bedrijf offline ontvangt of verzamelt (tenzij specifiek vermeld) of wordt verzameld door niet-gerelateerde websites of bedrijven waarmee wij mogelijk geef links.',

  "privacypolicy_para2" : "Van de panellid / respondent wordt verwacht dat hij zijn profiel eerlijk en waarheidsgetrouw invult en geen onjuiste of onjuiste informatie indient om in aanmerking te komen voor meer enquêtes.Er wordt ook verwacht dat de panellid / respondent zijn antwoorden niet zal manipuleren of andere oneerlijke middelen (veranderend IP, meerdere accounts, enz.) Zal gebruiken tijdens het invullen van enquêtes om prikkels te verdienen.WisePerks heeft voldoende waarborgen om dergelijke afwijkingen te detecteren en als dergelijk gedrag wordt opgemerkt, zal de panellid verbannen / geblokkeerd worden voor WisePerks en zullen alle / een van zijn terugkoopverzoeken worden geannuleerd.",

  "privacypolicy_para3" : "WiseWorks hecht veel waarde aan het beschermen van uw privacy bij ons.In het volgende privacybeleid wordt uitgelegd hoe uw persoonlijke gegevens worden bewaard en behandeld terwijl u onze website en services verkent.",

  "privacypolicy_topic1" : "Welke persoonlijk identificeerbare informatie verzamelt WisePerks.com van mij?",
  "privacypolicy_topic1ans" : "WisePerks.com verzamelt informatie op verschillende manieren van verschillende delen van onze website en ons netwerk.Sommige informatie wordt verzameld wanneer u zich aanmeldt.Tijdens lidregistratie vraagt ​​WisePerks.com om uw naam, e-mailadres, postcode en huisinformatie.Hoe meer informatie u vrijwillig verstrekt om nauwkeurig te bieden, hoe beter wij u diensten en beloningen kunnen aanbieden.Als u ooit op enige manier contact opneemt met WisePerks.com, kunnen we die correspondentie bijhouden.Telkens wanneer WisePerks.com dergelijke informatie verzamelt, proberen we een link naar dit Privacybeleid op die pagina op te nemen.",

  "privacypolicy_topic2" : "Wat zijn cookies en hoe gebruikt WisePerks.com ze?",
  "privacypolicy_topic2ans" : "Een cookie is een pakket met elektronische gegevens dat wordt verzonden naar uw browser, vanaf een webserver en opgeslagen op de harde schijf van uw computer.Als onderdeel van het aanbieden van specifieke enquêtes, informatie en gepersonaliseerde services, kan WisePerks.com cookies gebruiken om informatie over u op te slaan en soms te volgen.WisePerks.com kan vereisen dat u cookies accepteert om dergelijke specifieke inhoud voor u klaar te maken.Dit voorkomt ook dat u telkens uw aanmeldingsgegevens moet invoeren.",

  "privacypolicy_topic3" : "Wat is het beleid van WisePerks.com over het toestaan ​​dat ik mijn persoonlijke accountgegevens kan bijwerken, bewerken, wijzigen of verwijderen?",
  "privacypolicy_topic3ans" : "U kunt uw WisePerks.com-accountgegevens op elk gewenst moment wijzigen door uw WisePerks.com-gebruikersnaam en wachtwoord te gebruiken.Neem contact op met",
   "privacypolicy_topic3ans2" : "voor verdere instructies over het deactiveren van uw WisePerks.com-account.",

  "privacypolicy_topic4" : "Wie verzamelt informatie en met welk doel?",
  "privacypolicy_topic4ansp1" : "WisePerks.com verzamelt verschillende informatie door middel van enquêtes en registratie en andere middelen, waardoor haar onderzoeksklanten nieuwe producten en diensten voor consumenten kunnen ontwikkelen.U deelt deze informatie alleen met WisePerks.com, tenzij specifiek anders vermeld staat in elke enquête waaraan u deelneemt.Als gegevens worden verzameld en / of onderhouden door een ander bedrijf dan WiseWorks - of WiseWorks-sites, wordt u op de hoogte gebracht vóór het tijdstip van de gegevensverzameling of -overdracht.Als u niet wilt dat uw gegevens worden gedeeld, kunt u ervoor kiezen om de overdracht niet toe te staan ​​door die specifieke enquête of product niet te gebruiken.",
  "privacypolicy_topic4ansp2" : "Beloningen en promoties die we aanbieden op WisePerks.com kunnen onafhankelijk van elkaar worden gesponsord door andere bedrijven of kunnen worden gecofinancierd door WisePerks.com en een ander bedrijf.Sommige gegevens die tijdens een dergelijke promotie worden verzameld, kunnen met de sponsor worden gedeeld.U wordt echter wel op de hoogte gebracht voordat de gegevens worden verzameld of overgedragen.U kunt besluiten om niet deel te nemen aan die specifieke promotie als u uw gegevens niet wilt delen.",
  "privacypolicy_topic4ansp3" : "Alle specifieke gevoelige informatie (zoals informatie over medische of gezondheidsomstandigheden, raciale of etnische afkomst, politieke opvattingen, religieuze of spirituele overtuigingen, transacties en lidmaatschap van een vakbond of informatie die het seksleven van het individu specificeert) zal met extra zorg worden behandeld.Als WisePerks.com van plan is dergelijke informatie te delen met een ander bedrijf voor een ander doel dan oorspronkelijk is verzameld, dan wordt u uitdrukkelijk toestemming gevraagd voorafgaand aan een dergelijke openbaarmaking.",

  "privacypolicy_topic5" : "Met wie deelt WisePerks.com mijn informatie?",
  "privacypolicy_topic5ansp1" : "We nemen privacy zeer serieus.WisePerks.com zal uw persoonlijk identificeerbare informatie niet bekendmaken, behalve wanneer we uw uitdrukkelijke toestemming hebben of onder speciale omstandigheden, dat de wet dit vereist of onder de omstandigheden die hieronder in detail worden gespecificeerd.Het volgende beschrijft enkele manieren waarop uw informatie kan worden vrijgegeven.Raadpleeg de Servicevoorwaarden voor onze services voor meer informatie over hoe uw persoonlijke gegevens kunnen worden gedeeld.",
  "privacypolicy_topic5ansp2" : "Zakenpartners, klanten en sponsors:",
  "privacypolicy_topic5ansp3" : "WisePerks.com kan uw persoonlijke informatie bekendmaken aan onze partners, klanten of sponsors.Als persoonlijke gegevens over u echter ooit worden gedeeld, zullen we u dit vertellen voordat we de gegevens verzamelen of overdragen.Veel activiteiten die we aanbieden, vragen aanvullende informatie van sponsors.Door u om meer informatie te vragen, geeft u WisePerks.com toestemming om uw informatie met de sponsor te delen, zodat zij aan uw verzoek kunnen voldoen.In veel gevallen wordt alleen uw e-mailadres gedeeld.Als meer informatie met onze partners wordt gedeeld, wordt u van tevoren op de hoogte gesteld.Van tijd tot tijd wordt u de mogelijkheid geboden om testmateriaal of andere producten of speciale aanbiedingen van onze partners te ontvangen.Als u zich aanmeldt om informatie van deze derden te ontvangen, deelt WisePerks.com (met uw toestemming) uw naam en e-mailadres met hen.",
  "privacypolicy_topic5ansp4" : "WisePerks.com kan in sommige speciale gevallen ook persoonlijke informatie vrijgeven wanneer het vrijgeven van deze informatie noodzakelijk is om iemand te identificeren, te contacteren of juridische stappen te ondernemen tegen iemand die mogelijk het beleid en de Servicevoorwaarden van WisePerks.com schendt of mogelijk letsel of interferentie met (opzettelijk of onopzettelijk) de producten, diensten, rechten of eigendom van WisePerks.com, andere leden en gebruikers van WisePerks.com, of iemand anders die door dergelijke activiteiten zou kunnen worden beïnvloed.WisePerks.com kan accountinformatie vrijgeven of openen wanneer wij te goeder trouw geloven dat de wet dit vereist en voor administratieve en andere doeleinden die wij nodig achten om onze producten en diensten te onderhouden, te onderhouden en te verbeteren.",

  "privacypolicy_topic6" : "Wat zijn de wettelijke grondslagen die we gebruiken voor het verwerken van uw informatie?",
  "privacypolicy_topic6ans" : 'WiseWorks hecht veel waarde aan privacy en gegevensbescherming.Ons privacybeleid neemt de principes van de algemene gegevensbeschermingsverordening van de Europese Unie ("GDPR") over als een maatstaf waaraan wij, onze werknemers en leveranciers zich houden.',

  "privacypolicy_topic7" : "Wat zijn mijn keuzes met betrekking tot het verzamelen, gebruiken en verspreiden van mijn informatie?",
  "privacypolicy_topic7ans" : "Alleen WisePerks.com (of partners die werken met WisePerks.com onder geheimhoudingsovereenkomsten) zullen u direct mails sturen, alleen als u heeft aangegeven dat u hier geen bezwaar tegen hebt.Je hebt ook keuzes met betrekking tot hoe cookies werken.Door de instellingen van uw internetbrowser te wijzigen, hebt u de keuze om alle cookies te accepteren, op de hoogte te stellen wanneer een cookie wordt geplaatst of om alle cookies te weigeren.Als u ervoor kiest om alle cookies te weigeren, kunt u die WisePerks.com-services die vooraf moeten worden geregistreerd, niet gebruiken om deel te nemen.WisePerks.com verkoopt of least geen gebruikersinformatie aan wie dan ook.We zullen u altijd op de hoogte brengen op het moment van gegevensverzameling of -overdracht als uw gegevens met een derde partij worden gedeeld en u kunt altijd toestaan ​​dat deze niet wordt gedeeld.",

  "privacypolicy_topic8" : "Welke veiligheidsmaatregelen zijn er op WisePerks.com om enig verlies, misbruik of wijziging van mijn accountinformatie te beschermen?",
  "privacypolicy_topic8ansp1" : "Uw WisePerks.com-accountinformatie is met een wachtwoord beveiligd, zodat u en alleen u toegang hebt tot deze informatie.",
  "privacypolicy_topic8ansp2" : "We raden u ten zeerste aan om uw wachtwoord niet met iemand te delen.We zullen u nooit om uw wachtwoord vragen in een ongevraagd telefoontje of e-mail.Meld u af bij uw WisePerks.com-account en sluit uw browservenster wanneer u klaar bent met uw activiteit.Dit zorgt ervoor dat anderen geen toegang hebben tot uw persoonlijke gegevens en account, als u een openbare computer gebruikt of uw computer met iemand anders deelt.Wanneer WisePerks.com persoonlijke informatie verwerkt zoals hierboven beschreven, ongeacht waar dit zich voordoet, onderneemt WisePerks.com stappen om ervoor te zorgen dat uw informatie veilig en in overeenstemming met de relevante Servicevoorwaarden en dit Privacybeleid wordt behandeld.Helaas kan geen gegevensoverdracht via internet gegarandeerd 100% veilig zijn.Dientengevolge, terwijl wij ernaar streven om uw persoonlijke informatie te beschermen, kan WisePerks.com de veiligheid van informatie die u naar ons of onze online producten of diensten verzendt, niet garanderen en u doet dit op eigen risico.Zodra we uw gegevens hebben ontvangen, doen we ons best om de beveiliging van onze systemen te waarborgen.",

  "privacypolicy_topic9" : "Wat moet ik nog meer weten over mijn privacy?",
  "privacypolicy_topic9ans" : "Please keep in mind that whenever (and wherever) you choose to disclose personal information online - for example through emails, social networking websites, blogs, or in chat areas - that information can be collected and used by any other user.Als u online persoonlijke informatie plaatst die voor het publiek toegankelijk is, kunt u ongewenste berichten van andere partijen ontvangen.Uiteindelijk bent u als enige verantwoordelijk voor het geheimhouden van uw wachtwoorden en / of accountinformatie.Wees alsjeblieft voorzichtig en verantwoordelijk wanneer je online bent.",

  "privacypolicy_topic10" : "Hoe beschermt WisePerks.com de privacy van mijn kinderen?",
  "privacypolicy_topic10ans" : "WisePerks.com verkrijgt voorafgaande toestemming van de ouders wanneer enquêtes vereisen dat kinderen deelnemen en ouderlijk toezicht is ook vereist voor dergelijke onderzoeken met kinderen van 6-15 jaar.We besteden extra veel aandacht aan de bescherming van de veiligheid en privacy van jonge gebruikers op onze site en we moedigen u aan om online deel te nemen aan de ervaringen van uw kind.Wij staan ​​geen marketing of onderzoek bij kinderen toe en de bepaalde onderwerpen zijn volledig verboden bij onderzoek onder kinderen: Naam / accountnaam / E-mail of fysiek adres, Telefoonnummer, Ras of Etniciteit, Religie of Cast, Huishoudinkomen, Gezinsziekte , Burgerlijke staat van ouders of andere familieleden, Relatiezaken, Rangen op school of universiteit, Alcohol, Seksuele Problemen, Tabak en Vuurwapens.",

  "privacypolicy_topic11" : "Speciale opmerking voor internationale gebruikers",
  "privacypolicy_topic11ansp1" : "De site wordt gehost in de Verenigde Staten.Als u een gebruiker bent die de site bezoekt vanuit de Europese Unie, Azië of een andere regio met wetten of regels die het verzamelen, gebruiken en openbaar maken van persoonlijke gegevens regelen, die afwijken van de Amerikaanse wetgeving, houd er dan rekening mee dat u uw persoonlijke gegevens overdraagt ​​aan de Verenigde Staten die niet dezelfde wetten voor gegevensbescherming hebben als de EU en andere regio's, en door uw persoonsgegevens te verstrekken, stemt u ermee in:",
  "privacypolicy_topic11ansp2" : "Het gebruik van uw persoonlijke gegevens voor de hierboven geïdentificeerde toepassingen in overeenstemming met het Privacybeleid; en de overdracht van uw persoonlijke gegevens naar de Verenigde Staten zoals hierboven aangegeven.U ERKENT DAT DE WETTEN VAN DE VERENIGDE STATEN UW INFORMATIE BEHANDELEN OP EEN WIJZE DIE WEZENLIJK VERSCHILLEND IS VAN EN MINDER BESCHERMBAAR IS DAN DE BEHANDELING DIE IS VEREIST ONDER DE WETGEVING VAN ANDERE LANDEN EN JURISDICTIES.ALS U NIET WILT DAT UW INFORMATIE NAAR DE VERENIGDE STATEN WORDT OVERGEDRAGEN, DIENT U UW GEGEVENS NIET TE DELEN MET ONS OF GEBRUIK VAN DE SITE.VOOR ZOVER DIT IS TOEGESTAAN DOOR DE WET VAN HET LAND WAAROP U ZICH BEVINDT, ZIJN U UITDRUKKELIJK AFSTAND VAN ELK RECHT DAT U MOET KUNNEN VERLANGEN DAT ONS UW IDENTIFICERENDE INFORMATIE BEHANDELT IN OVEREENSTEMMING MET DE WETTEN VAN IEDER LAND OF JURISDICTIE ANDERS DAN DE VERENIGDE STATEN.EVENWEL, DE VOORAFGAANDE VRIJSTELLING KAN IN BEPAALDE LANDEN, ZOALS DE LIDSTATEN VAN DE EUROPESE UNIE, NIET JURIDISCH BINDEND ZIJN.VOOR ZOVER HET NIET JURIDISCH BINDEND IS IN HET LAND WAAROP U ZICH BEVINDT, IS DEZE VOORAFGAANDE WEDERVERKOOP NIET OP U VAN TOEPASSING.",

  "privacypolicy_topic12" : "Wijzigingen en updates van dit privacybeleid",
  "privacypolicy_topic12ans" : "WiseWorks behoudt zich het recht voor om, naar eigen goeddunken, delen van dit privacybeleid op elk moment en om welke reden dan ook te wijzigen, aan te passen, toe te voegen of te verwijderen.Telkens wanneer WiseWorks dit recht uitoefent, moet u het geactualiseerde privacybeleid affirmatief aanvaarden en als u dit nalaat, kunt u de website niet meer openen of gebruiken.",

  "privacypolicy_addinfo" : "Extra informatie",
  "privacypolicy_sentto" : "Alle andere vragen hierover moeten worden gestuurd naar",

  "faq_faq" : "Veel Gestelde Vragen",
  "faq_getans" : "Krijg antwoord op al uw vragen.",

  "faq_ques1" : "Wat is WisePerks?",
  "faq_ans1" : "We zijn blij dat je het vroeg.WisePerks is een online enquêtesite.Veel merken en bedrijven zoeken respondenten om hun mening te geven en in ruil daarvoor beloningen te ontvangen.WisePerks is zo'n platform waar je je mening kunt geven en er ook beloningen voor kunt verdienen.",

  "faq_ques2" : "Is het gratis om mee te doen?",
  "faq_ans2" : "Ja, het is gratis om mee te doen.Als iemand u een toetredingskosten vraagt, willen we dat u dit bij ons meldt.",

  "faq_ques3" : "Kan ik mijn vrienden verwijzen?",
  "faq_ans3" : "Ja, je kunt je vrienden en collega's uitnodigen om mee te doen en er ook voor worden beloond. Klik op de koppeling 'Vriend doorverwijzen' op uw dashboard voor meer informatie.",

  "faq_ques4" : "Wat verdien ik?",
  "faq_ans4" : "Nu in ons nieuwe model, u punten verdienen op vele andere manieren dan alleen door het nemen van de enquêtes. U krijgt punten door: registreren bij ons, door het verifiëren van uw e-mail account, voor het invullen van uw profiel, natuurlijk voor het invullen van een enquête en tot uw verbazing, zelfs voor een gediskwalificeerd onderzoek. Dus we hebben alle redenen om mee te beginnen is het niet?",

  "faq_ques5" : "Hoe word ik lid van WisePerks.com?",
  "faq_ans5" : "Open WisePerks.com in uw browser.Maak een lidmaatschapsinlog door op het tabblad 'Aanmelden' te klikken.Volg gewoon de stappen en het zal u door de rest van het proces leiden.U moet uw intentie om lid te worden bevestigen door op de bevestigings-e-mail te klikken die door ons is verzonden naar uw opgegeven e-mail.",

  "faq_ques6" : "Waar wordt die bevestigings-e-mail vermeld?",
  "faq_ans6p1" : "Een bevestigingsmail wordt automatisch verstuurd naar het e-mail adres dat u opgeeft bij het aanmelden. Als je niet ziet dat e-mail in je inbox, controleer dan uw spam of Junk map. Om te voorkomen dat eventuele toekomstige e-mails van ons en enquête nodigt/berichten van het bereiken van uw spam-map, u toevoegen",
  "faq_ans6p2" : "naar uw lijst met uw goedgekeurde afzenders of als contactpersoon in uw mailbox. Als u de bevestigingsmail nog steeds niet vinden, u inloggen op mijn account, bevestigen dat uw e-mail adres bij ons correct is en een andere bevestigingsmail aanvragen.",

  "faq_ques7" : "Hoe kan ik een enquête invullen?",
  "faq_ans7" : "Zodra u bent ingelogd nadat u uw gebruikersnaam en wachtwoord heeft ingevoerd, bereikt u uw dashboard en u alle enquêtes bekijken die u nemen. We sturen ook de enquête links naar uw geregistreerde e-mail-id's. U de link rechtstreeks vanuit uw mailbox nemen.",

  "faq_ques8" : "Hoe vertrouwelijk zijn mijn antwoorden, mijn gegevens?",
  "faq_ans8" : "Voor WisePerks zijn het handhaven van de privacy van gebruikers en vertrouwelijkheid van het grootste belang.Onze klanten zijn alleen geïnteresseerd in wat u denkt, als consument, of hoe u over iets denkt.Wij van WisePerks delen en / of geven nooit persoonlijke informatie door aan onze klanten, en geen van de enquêtes die WisePerks verkoopt, verkoopt of verhuurt ook geen informatie aan derden.",

  "faq_ques9" : "Hoe lang zijn deze enquêtes eigenlijk?",
  "faq_ans9" : "De meeste onderzoeken duren slechts ongeveer 10 tot 15 minuten, of zelfs minder.Van tijd tot tijd kunnen we echter langere enquêtes hebben.Bij elke enquête vertellen we u van tevoren hoe lang het zou duren, maar houd er rekening mee dat langere enquêtes ook hogere beloningen ontvangen.",

  "faq_ques10" : "Kan iemand WisePerks-enquêtes nemen?",
  "faq_ans10" : "Je moet 13 jaar of ouder zijn om je bij WisePerks te registreren.Hoewel we u soms in enquêtes uitnodigen om deel te nemen met jongere leden van uw gezin.",

  "faq_ques11" : "Hoe weet je dat ik me kwalificeer voor een enquête?",
  "faq_ans11" : "Ons algoritme stemt uw profielinformatie af op de beschikbare enquêtes om ervoor te zorgen dat u de meest geschikte enquêtes krijgt.Wanneer een enquête begint, bepalen meestal de eerste paar vragen of u de respondent bent die onze klant zoekt voor die specifieke enquête.We hebben een breed scala aan enquêtes die op zoek zijn naar veel verschillende soorten mensen (bijvoorbeeld mensen die regelmatig films kijken, een bepaalde sport spelen, vrouwen met kinderen, enz.) En elke dag nieuwe enquêtes verschijnen!",

  "faq_ques12" : "Wat zijn mijn verplichtingen tegenover WisePerks?",
  "faq_ans12" : "Het enige wat we van u verwachten, is om uw meningen en gedachten eerlijk te geven, omdat klanten op zoek zijn naar uw eerlijke en waarachtige antwoorden om hun producten en diensten te verbeteren.Geef de juiste informatie op wanneer u zich aanmeldt als lid en wijzig alle wijzigingen zodra ze zich voordoen.",

  "faq_ques13" : "Waarom zou ik informatie verstrekken over mijn huishouden, technologie of producten die ik koop?",
  "faq_ans13" : "Voor elke enquête zijn we op zoek naar een bepaald profiel van respondenten.Als we uit uw profielinformatie weten dat uw informatie overeenkomt met de vereisten van de klant, bingo!We kunnen vrijwel zeker verzekeren dat u de enquête met succes kunt voltooien en de beloning ervoor kunt verdienen.Dit verhoogt in feite uw kansen om te worden geselecteerd voor een enquête.",

  "faq_ques14" : "Wanneer ontvang ik mijn cadeaubonnen na het invullen van een WisePerks-enquête?",
  "faq_ans14" : "De punten die je verdient na het succesvol invullen van een enquête worden verzameld in je emmer. Zodra deze punten zijn gerealiseerd u inwisselen.",

  "faq_ques15" : "Wat is mijn account eigenlijk?",
  "faq_ans15" : "Het is een eenvoudige manier om uw persoonlijke gegevens te bekijken, indien nodig wijzigingen aan te brengen, uw enquête-activiteit te bekijken, verdiende beloningen, alles op één plek.",

  "faq_ques16" : "Hoe kan ik mijn account annuleren?",
  "faq_ans16" : "Met zo veel komen je weg, we zijn zeker dat je niet zou willen om dit te doen. Echter, op uw dashboard-> ga naar instellingen-> accounts beheren--> deactiveren account",

  "faq_ques17" : "Hoe werkt het puntensysteem?",
  "faq_ans17p1" : "Elke enquête is voor een bepaald aantal punten. Na het succesvol invullen van een enquête worden deze punten toegevoegd aan uw inkomsten. Je kunt ze verzilveren zodra je de minimumdrempel hebt bereikt.",
  "faq_ans17p2" : "In enkele gevallen vanwege een aantal onvoorziene problemen met de verbinding of storingen, wordt uw reactie mogelijk niet vastgelegd aan het einde van onze klant. We moeten deze punten vervolgens van uw account terugdraaien. Als je die punten al hebt ingewisseld en je account heeft een laag saldo, dan zijn je inkomsten negatief. Deze aanpassingen worden doorgevoerd in uw volgende inwisselcyclus.",

  "faq_ques18" : "Hoe snel worden de punten voor een enquête toegevoegd aan mijn puntensaldo?",
  "faq_ans18" : "Je punten worden binnen 48 uur toegevoegd aan je puntensaldo.",

  "faq_ques19" : "Wat is de minimumdrempel voor inlossing?",
  "faq_ans19" : "De minimumdrempel voor inlossing is 25000 punten.Gemiddeld zou u 8-12 enquêtes moeten invullen om in aanmerking te komen voor inwisseling.",

  "faq_ques20" : "Hoe worden punten geconverteerd naar kadokaartcontanten? Is er een verhouding?",
  "faq_ans20" : "Ja. Afhankelijk van uw land worden punten omgezet in contant geld met een cadeaukaart. De conversieratio's zijn zoals hieronder weergegeven",
  "faq_ponits" : "punten",
  "faq_point" : "Punt",
  "faq_cent" : "Cent",

  "faq_ques21" : "Heeft u nog meer vragen?",
  "faq_ans21p1" : "Voel je vrij om ons te mailen op",
  "faq_ans21p2" : "en wij zullen u binnen 48 uur weer terugkrijgen.",

  "Profiling_profiledashboard" : "Profiel dashboard",
  "Profiling_completeprofile" : "Vul je profiel en krijg maar liefst",
  "Profiling_completepoints" : "Punten!",
  "Profiling_answercarefully" : "De volgende algemene set van vragen zal worden gebruikt om uw persoonlijke profiel te creëren om u te voorzien van de beste toekomstige enquête mogelijkheden. Neem uw tijd, antwoord zorgvuldig en naar waarheid.",

  "Profiling_cat_general" : "Algemene",
  "Profiling_cat_personal" : "Persoonlijke",
  "Profiling_cat_household" : "Huishoudelijke",
  "Profiling_cat_profession" : "Beroep",

  "Profiling_bar_completed" : "% Voltooid",
  
  "Profiling_update_ans_button" : "Update en doorgaan",
  "Profiling_save_ans_button" : "Opslaan",
  "Profiling_sc_ans_button" : "Bewaar & doorgaan",

  "Category_thanku_msg1" : "Goede gaan",
  "Category_thanku_msg2" : "paar meer en je zal",
  "Category_thanku_msg3" : "punten hebben in uw account.",

  "Category_thanku_update_msg1" : "Bedankt voor het updaten van uw informatie!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
   "Category_Continue_button" : "Blijven",

  "OverAll_thanku_msg1" : "Bedankt voor het delen van uw informatie. Je hebt verdiend",
  "OverAll_thanku_msg2" : "Punten.",

  "Survey_history_surveyhistory" : "Enquête geschiedenis",
  "Survey_history_fromthisscreen" : "Vanuit dit scherm u al uw transacties bekijken.",
  "Survey_history_show" : "Toon",
  "Survey_history_entries" : "Posten",
  "Survey_history_date" : "Datum",
  "Survey_history_details" : "Details",
  "Survey_history_surveys" : "Enquêtes",
  "Survey_history_pointsreceived" : "Punten ontvangen",
  "Survey_history_result" : "Resultaat",
  "Survey_history_status" : "Status",
  "Survey_history_status_complete" : "Volledige", 
  "Survey_history_status_disqualified" : "Gediskwalificeerd", 
  "Survey_history_keyword" : "Trefwoord",
  "Survey_history_search" : "Zoek",
  "Survey_history_to" : "Aan",
  "Survey_history_selectoption" : "Selecteer optie",
  "Survey_history_earned" : "Verdiend",
  "Survey_history_redeemed" : "Verlost",
  "Survey_history_norecord" : "Geen record gevonden",
  "Survey_history_Surveypts" : "Enquête punten",
  "Survey_history_Promocode" : "Promo-code",

  "My_transactions_mytransactions" : "Mijn transacties",
  "My_transactions_fromthisscreen" : "Vanuit dit scherm u al uw transacties bekijken.",
  "My_transactions_show" : "Toon",
  "My_transactions_entries" : "Posten",
  "My_transactions_transactionid" : "Transactie-id",
  "My_transactions_date" : "Datum",
  "My_transactions_details" : "Details",
  "My_transactions_points" : "Punten",
  "My_transactions_status" : "Status",
  "My_transactions_keyword" : "Trefwoord",
  "My_transactions_search" : "Zoek",
  "My_transactions_to" : "Aan",
  "My_transactions_selectoption" : "Selecteer optie",
  "My_transactions_status_earned" : "Verdiend",
  "My_transactions_status_redeemed" : "Verlost",
  "My_transactions_status_pending" : "Hangende",
  "My_transactions_status_reverted" : "Teruggekeerd",
  "My_transactions_status_declined" : "Daalde",
  "My_transactions_norecord" : "Geen record gevonden",
  "My_transactions_off_msg1" : "Na ",
  "My_transactions_off_msg2" : ", al uw transactiegegevens worden gepubliceerd op het tabblad Puntenboek",

  "Passbook_heading" : "Puntenboek",
  "Passbook_show" : "Laten zien",
  "Passbook_entries" : "entries",
  "Passbook_search" : "Zoeken",
  "Passbook_type" : "Type",
  "Passbook_surveyid" : "onderzoeken",
  "Passbook_date" : "Datum",
  "Passbook_selectoption" : "Selecteer optie",
  "Passbook_credit" : "Credit",
  "Passbook_debit" : "Debiteren",
  "Passbook_survey_id" : "Enquête-ID",
  "Passbook_survey_pts" : "Onderzoekspunten",
  "Passbook_earned_pts" : "punten",
  "Passbook_pending_pts" : "In afwachting van punten",
  "Passbook_promo_code" : "Promotiecode",
  "Passbook_transaction_type" : "Transactie Type",
  "Passbook_balance" : "Balans",
  "Passbook_status" : "staat",
  "Passbook_type_soi" : "registratie",
  "Passbook_type_doi" : "email verificatie",
  "Passbook_type_profile" : "Profiel voltooien",
  "Passbook_type_survey" : "Enquête",
  "Passbook_type_doir" : "E-mailverificatie door",
  "Passbook_type_doirt" : "E-mailverificatie op verwijzing",
  "Passbook_type_surveyr" : "Onderzoekspoging door",
  "Passbook_type_surveyrt" : "Onderzoekspoging door verwijzing",
  "Passbook_type_reward" : "Beloning",
  "Passbook_type_pending" : "aanpassingen",
  "Passbook_type_reverted" : "Punten teruggezet",
  "Passbook_type_declined" : "Verzoeken afgewezen",
  "Passbook_type_redeem" : "verlossen",
  "Passbook_type_balance" : "Vooruit dragen",
  "Passbook_balance_vary" : "Het feitelijke saldo kan variëren",
  "Passbook_survey_debit_reason" : "Wij waarderen uw inspanningen. Vanwege enkele oncontroleerbare verbindingsdalingen tijdens gegevensoverdracht kon uw reactie echter niet worden geregistreerd. We moeten de punten die u eerder voor deze enquête hebt ontvangen omdraaien. Excuses voor het ongemak.",

  "Redeem_redeemed" : "Inwisselen",
  "Redeem_redeemhistory" : "Geschiedenis inwisselen",
  "Redeem_history_norecord" : "Geen record gevonden",
  "Redeem_date" : "Datum",
  "Redeem_details" : "Details",
  "Redeem_points" : "Punten",
  "Redeem_status" : "Status",
  "Redeem_lookslike" : "Het lijkt erop dat je gemist verifiëren van uw e-mail account.",
  "Redeem_hurry" : "Haast! je krijgt 1000 punten als je eenmaal te verifiëren.",
  "Redeem_pleasecheck" : "Controleer je inbox en klik op de link in de bevestigingsmail die je hebt gestuurd om je account te activeren.",
  "Redeem_kindlycheck" : "Gelieve te controleren uw spam/troep omslag eveneens voor de bevestigings e-mail.",
  "Redeem_getconfirmationemail" : "Heeft u nog geen bevestigingsmail ontvangen?",
  "Redeem_resendconfirmationemail" : "Bevestigingsmail opnieuw verzenden",
  "Redeem_rewardredemptionrule" : "Regel voor belonings aflossing",
  "Redeem_completesurvey" : "Volledige enquête",
  "Redeem_earnpoints" : "Verdien punten",
  "Redeem_redeemrewards" : "Beloningen inwisselen",
  "Redeem_note" : "Opmerking:",
  "Redeem_yourearnedpoints" : "Uw verdiende punten worden inwisselbaar na de kwalificatie van de enquête. Om de beloning te verzilveren, moeten de minimale inwisselbare punten in uw account",
  "Redeem__points" : "Punten.",
  "Redeem_availablebalance" : "Beschikbaar saldo:",
  "Redeem_historydetails" : "Klik hier om de details van uw aflossings geschiedenis te bekijken.", 
  "Redeem_reqsucc" : "Gefeliciteerd! Uw punten zijn verlost. Raadpleeg uw geregistreerde mailbox voor meer informatie.", 
  "Redeem_wisecheck_youhave" : "Gefeliciteerd! U bent slechts één klik verwijderd van uw beloning.",
  "Redeem_wisecheck_gcagainst" : "Cadeaukaart voor",
  "Redeem_wisecheck_points" : "Punten.",
  "Redeem_wisecheck_issue" : "Het systeem heeft een aantal niet-herkende activiteiten in uw account vastgelegd, waardoor u geen aanvraag voor inwisselen verzenden. Neem contact op met",
  "Redeem_emailverify_issue" : "Om uw punten in te wisselen, verzoeken wij u om uw geregistreerde e-mailaccount te verifiëren.",

  "Redeem_unsubscribe_issue" : "U kunt geen verzilveringsverzoek verzenden omdat u zich hebt afgemeld bij WisePerks. Neem contact op met support@wiseperks.com om u opnieuw te abonneren.",
  "Redeem_wisecheck_message1" : "Je kunt om bepaalde veiligheidsredenen geen geld terugkrijgen. Neem contact op met voor ondersteuning op",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Bevestigen",
  "Redeem_Cancel_button" : "Annuleren",
  "Redeem_pending_hello" : "Hallo",
  "Redeem_pending" : "Aanpassing",
  "Redeem_pending_msg1" : "Uw account vertoont aanpassing van",
  "Redeem_pending_msg2" : "uit te voeren punten. Bevestig om de transactie te starten.",

  "Dashboard_wisecheck_issue" : "Systeem heeft een aantal niet-herkende activiteiten in uw account vastgelegd, waardoor u de enquêtes niet kunt zien. Neem contact op met support@wiseperks.com voor meer informatie.",
  "Dashboard_unsubscribe_issue" : "U kunt geen enquêtes bekijken, omdat u zich hebt afgemeld bij WisePerks. Neem contact op met support@wiseperks.com om opnieuw enquêtes te ontvangen.",


  "WC_DashboardMsg_1" : "Oops! Geen enquêtes om vanaf nu bij uw profiel te passen.",
  "WC_DashboardSubMsg_1" : "Maak je geen zorgen, we zijn onze voorraad aan het schuiven en zullen binnenkort nieuwe enquêtes sturen op jouw manier.",

  "WC_DashboardMsg_2" : "We zijn je dashboard aan het vernieuwen. Controleer na verloop van tijd op nieuwe enquêtes.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Bedankt voor het invullen van de enquête. Je hebt net verdiend response_points punten. Log in op uw DashBoard om uw totale verzamelde punten te zien.",
  "Survey_success_msg2" : "Bedankt voor het invullen van de enquête. Je hebt net verdiend response_points Punten.",

  "Survey_success_but_nomore_survey" : "Heilwens! Je hebt net verdiend response_points punten voor het invullen van de enquête. Gelieve te controleren vooraanstaande onderzoeken na ooit.",
  
  "Survey_terminate_pnt_msg1" : "Het lijkt erop dat sommige van uw informatie is iets anders dan de eis. Wij waarderen uw tijd en inspanningen, zal het toevoegen van response_points punten aan uw account. We zijn gericht op het brengen u meer lucratieve en intuïtieve enquêtes.",
  
  "Survey_terminate_nopnt_msg1" : "Lijkt erop dat sommige van uw antwoorden enigszins afwijken van de vereiste. We sturen meer enquêtes op uw manier. Bedankt voor je tijd en moeite.",

  "TermsConditions_t&c" : "VoorWaarden &",
  "TermsConditions_WhenRegister" : "Wanneer u zich registreert",
  "TermsConditions_WhenYouCreate" : "Wanneer u een persoonlijk account aanmaakt, vragen wij u wat persoonlijke informatie, zoals hier vermeld:",
  "TermsConditions_YouCanUpdate" : "U kunt uw basisprofielinformatie op elk moment bijwerken of wijzigen.",
  "TermsConditions_YouCanOptOut" : "U kunt op elk moment opt-out of uw account afsluiten.",
  "TermsConditions_WeRespectYour" : "Wij respecteren uw privacy en maken optimaal gebruik van technologie om uw informatie veilig te houden tegen ongeoorloofde fysieke en elektronische toegang.",
  "TermsConditions_WeShareYour" : "We delen uw informatie alleen met organisaties die nodig zijn om WiseWorks te gebruiken. En die organisaties moeten zorgen voor onze verplichtingen ten aanzien van uw privacy en veiligheid.",
  "TermsConditions_WhatInformation" : "Welke informatie we van u verzamelen",
  "TermsConditions_DirectlyCollected" : "Direct verzameld",
  "TermsConditions_EmailAddressAndPassword" : "E-mailadres en wachtwoord van uw account bij ons.",
  "TermsConditions_YourResponse" : "Uw antwoord op profileringsvragen.",
  "TermsConditions_AnyCustomer" : "Correspondentie met de klantenservice.",
  "TermsConditions_AnyPersonalDetails" : "Alle persoonlijke gegevens die u deelt voor inwisseling",
  "TermsConditions_AnyOtherInformation" : "Alle andere informatie die rechtstreeks door u wordt verstrekt.",
  "TermsConditions_UsageData" : "Gebruiksgegevens",
  "TermsConditions_YourIPAddress" : "Uw IP-adres, geolocatie en cookies",
  "TermsConditions_InformationAboutTheDevice" : "Informatie over het apparaat dat u gebruikt",
  "TermsConditions_YourSurveyActivity" : "Uw onderzoeksactiviteit, punten in uw account, de betalingen die u ontvangt na het inwisselen.",
  "TermsConditions_HowDoWeUse" : "Hoe gebruiken we uw gegevens?",
  "TermsConditions_SendingYouInvitation" : "Je uitnodigings-e-mails verzenden om de enquête in te nemen waarvoor je in aanmerking komt.",
  "TermsConditions_DetectAndPrevent" : "Detecteren en voorkomen van frauduleuze activiteiten.",
  "TermsConditions_ImproveYourExperience" : "Verbeter uw ervaring op het WiseWorks-platform",
  "TermsConditions_YourResponseAreTransferred" : "Uw antwoorden worden overgedragen aan onze gelieerde derde partijen en partners in een geaggregeerd en geanonimiseerd formaat.",
  "TermsConditions_OurPartnersCanAsk" : "Onze partners kunnen u tijdens een enquête om uw naam, e-mailadres en andere contactinformatie vragen.",
  "TermsConditions_YouAreFreeToDecline" : "Het staat u vrij om het verstrekken van uw gegevens aan onze partners te weigeren, als u hiermee instemt, dan geeft u deze informatie rechtstreeks aan hen en niet aan WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Uw informatie zal worden behandeld in overeenstemming met het relevante privacybeleid voor bedrijven en niet met het privacybeleid van WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Onze partners streven ernaar uw privacy te respecteren en te beschermen.",
  "TermsConditions_WhatWeDont" : "Wat we er niet mee doen",
  "TermsConditions_WeDontSellOnThat" : "Wij verkopen die informatie niet aan iemand en we gebruiken het niet om iets aan u te verkopen. We zullen altijd toestemming vragen voordat we uw gegevens delen.",
  "TermsConditions_YourRightToControl" : "Uw recht om uw gegevens te beheren",
  "TermsConditions_YouHaveFullControl" : "U hebt volledige controle over uw gegevens.",
  "TermsConditions_YouCanAccess" : "U kunt uw basisprofielen altijd bekijken, bijwerken of wijzigen wanneer u maar wilt.",
  "TermsConditions_YouCanUnsubscribe" : "U kunt uw abonnement opzeggen en uw account deactiveren.",
  "TermsConditions_YouAreFreeToGetYour" : "U bent vrij om uw profiel te laten verwijderen door een e-mail te sturen naar support@wiseperks.com. We proberen uw probleem op te lossen en als u nog steeds wilt dat uw account wordt verwijderd, moet u dit binnen 72 uur doen.",
  "TermsConditions_DataSecurity" : "Dataveiligheid",
  "TermsConditions_OurTechTeamWorks" : "Ons technische team werkt heel hard om al uw gegevens te beveiligen.",
  "TermsConditions_WeMakeUseOfLatest" : "We maken gebruik van de nieuwste technologie en maatregelen om misbruik of ongeoorloofde toegang tot uw gegevens te voorkomen. Vanwege de aard van de online-omgeving kunnen we echter geen 100% zekerheid bieden over gegevensbeveiliging.",
  "TermsConditions_WeTryOurBestToRule" : "We doen ons best om risico's uit te sluiten, maar kunnen geen risicovrije omgeving garanderen. Door deze voorwaarden te accepteren, erkent u deze risico's en accepteert u deze.",
  "TermsConditions_AnyQuestions" : "Heeft u vragen, neem dan gerust contact met ons op",
  "TermsConditions_IfYouHaveAnyQueries" : "Als u vragen of klachten hebt over ons privacybeleid en onze procedures. Stuur ons een e-mail op support@wiseperks.com en we nemen binnen 48 uur contact met u op.",

  "Validation_PleaseEnterProperDate" : "Voer de juiste geboortedatum in",
  "Validation_PleaseSelectOne" : "Selecteer alstublieft een optie!",
  "Validation_PleaseSelectValidOptions" : "Selecteer alstublieft geldige opties!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Selecteer alstublieft ten minste één optie",
  "Validation_PleaseInsertTheZip" : "Voer de postcode / postcode in.",

  "Releases_releases" : "Releases",
  "Releases_theUseOfThis" : "Het gebruik van deze website is onderworpen aan de volgende gebruiksvoorwaarden.",
  "Releases_featuresOfWP" : "Kenmerken van WP release",
  "Releases_updatedOn" : "bijgewerkt op",
  "Releases_WisePerksNow" : "WisePerks ondersteunt nu meerdere talen",
  "Releases_youCanWalkThourgh" : "U lopen kunnen zowel de site met veel meer gemak nu als WisePerks is beschikbaar in uw PREFFERED taal.",
  "Releases_languagesAvailable" : "BeSchikBare talen:",
  "Releases_EarnMore" : "Verdien meer met ons nieuwe punt systeem",
  "Releases_theNewWisePerksAccumulates" : "De nieuwe WisePerks accumuleert punten in uw account. Deze punten houden een veel hogere waarde en zijn gemakkelijk te verdienen en te verlossen. Met meerdere opties voor inwisselen om uit te kiezen, we zijn ervan overtuigd dat u zult genieten van de enquête te nemen ervaring.",
  "Releases_accumulatePoints" : "Accumuleren punten met Hassel vrije redeemption",
  "Releases_youGetTheBenefit" : "U krijgt het voordeel van het verdienen van het zelfde nr. van punten, ongeacht de valuta waarin u ze wilt inwisselen.",
  "Releases_surveyDisqualification" : "Enquête diskwalificatie is niet meer een slecht nieuws",
  "Releases_youEarnPoints" : "Je verdient punten, zelfs als je niet in aanmerking voor een enquête, je begon te beantwoorden.",
  "Releases_newAndImproved" : "Nieuwe en verbeterde layout",
  "Releases_theFreshLookOf" : "De frisse uitstraling van WisePerks zal je verliefd op. Het is nu veel gemakkelijker te gebruiken en zou een geweldige ervaring voor je zijn.",
  "Releases_whatAvatarAreYouToday" : "Welke avatar ben je vandaag",
  "Releases_youCanNowCreate" : "U nu uw eigen avatar te maken aan functie op uw dashboard.",
  "Releases_freshAndHotSurveys" : "Verse en hete enquêtes op uw dashboard",
  "Releases_youDontHaveTo" : "U hoeft zich geen zorgen te maken over refresing uw dashboard om nieuwe enquêtes te bekijken, we houden uw dashboard automatisch gevuld met de nieuwste enquêtes.",
  "Releases_promoCode" : "Promocode functie",
  "Releases_promoCodeInfo" : "Op populaire vraag brengt WisePerks promocodes voor zijn panelleden wekelijks. Snelle manier om meer beloningen te grijpen.",
  "Releases_referFriend" : "Verwijs vriend",
  "Releases_referFriendInfo" : "Zet je vrienden in verband met WisePerks en pak spannende beloningen. Let op de optie Vriend doorverwijzen in uw account.",

  "Dashboard_Promocode_Usepromo" : "Gebruik Promo code",
  "Dashboard_Promocode_to" : "Aan",
  "Dashboard_Promocode_earnminimum_1" : "minimum verdienen",
  "Dashboard_Promocode_earnminimum_2" : "extra punten",
  "Dashboard_Promocode_minimum" : "minimum",
  "Dashboard_Promocode_minimumextrapoints" : "extra punten op elke vandaag ingevulde enquête",
  "Dashboard_Promocode_apply" : "Toepassen",
  "Dashboard_Promocode_message1" : "Promotiecode niet geldig!",
  "Dashboard_Promocode_message2" : "Gefeliciteerd! Je krijgt minimum",
  "Dashboard_Promocode_message3" : "extra op elke enquête die vandaag is voltooid!",

  "Dashboard_modal_Enjoythefestive" : "Geniet van de feestelijke geest door het verdienen van minimaal",
  "Dashboard_modal_Extrapointson" : "extra punten op elke enquête die u voltooit vandaag.",
	 
  "refer_friend_title" : "Verwijs een vriend en word beloond",
  "refer_friend_step_first": "Nodig een vriend uit voor WisePerks",
  "refer_friend_step_second": "De vriend meldt zich succesvol aan bij WisePerks",
  "refer_friend_step_third": "Je krijgt each_referral punten",
  "refer_friend_link_title": "Nodig nu een vriend uit!",
  "refer_friend_share_title": "Deel de link",
  "refer_friend_guideline_title": 'Hoe "verwijs een vriend" zal je voordeel opleveren:',
  "refer_friend_guideline_one": "Deel alstublieft de bovenstaande link met uw verwijzingen.",
  "refer_friend_guideline_two": "U kunt zoveel mensen doorverwijzen als u wilt, zodra 5 van uw verwijzingen hun account verifiëren en enquêteactiviteit starten, worden uw verdiende verwijzingspunten toegevoegd aan uw account.",
  "refer_friend_guideline_three": "U krijgt elk each_referral punten voor doorverwijzing (met geverifieerde e-mailaccount en enquête-activiteit).",
  "refer_friend_guideline_four": "De punten worden verdiend in twee fasen, partial_referral op e-mailverificatie en partial_second_referral op enquête-activiteit door uw verwijzing.",
  "refer_friend_guideline_five": "Na 5 succesvolle (met geverifieerde e-mailaccount en enquête-activiteit) verwijzingen, wordt de verwijzingslink uitgeschakeld.", 

  }
  
