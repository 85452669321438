import { Pipe, PipeTransform } from '@angular/core';
import { PanelistService } from './panelist.service';

@Pipe({
  name: 'referralSecondPartialPoint'
})
export class ReferralSecondPartialPointPipe implements PipeTransform {

  constructor(private panelistService: PanelistService){}

  transform(value: any, args?: any): any {
    //return null;
    let partial_second_referral = this.panelistService.GetReferralSecPartialPanelistPoints();
    return value  ? value.replace('partial_second_referral', partial_second_referral) : value;
  }

}
