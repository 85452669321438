import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../user/home/shared/home.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.css']
})
export class NotAvailableComponent implements OnInit {

  setCountryName : string = "";
  constructor(private _homeService: HomeService, private router: Router) { 
    
    let getCountryAvailableStatus = this._homeService.Get_notAvailable_country();

    if(getCountryAvailableStatus == false){
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this.setCountryName = this._homeService.GetNotAvailableCountryName();
  }

}
