import { Pipe, PipeTransform } from '@angular/core';
import { PanelistService } from './panelist.service';

@Pipe({
  name: 'referralPoint'
})
export class ReferralPointPipe implements PipeTransform {

  constructor(private panelistService: PanelistService){}

  transform(value: any, args?: any): any {
    //return null;
    let each_referral = this.panelistService.GetReferralPanelistPoints();
    return value  ? value.replace('each_referral', each_referral) : value;
  }

}
