import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { PanelistService } from '../shared/panelist.service';
import { Observable } from 'rxjs/Observable';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private router : Router, private _panelistService : PanelistService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
      
      if(localStorage.getItem('currentLoginPanelist') !==null){
        let panelistId = localStorage.getItem('currentLoginPanelist');
        return this._panelistService.getPanelistInfo(panelistId).map((resp:any)=>{
          
            if(resp.auth == true && resp.success == true){ 
              if(resp.data['step']>=1){
                
                /*if(this.router.url == '/'){
                  this.router.navigate(['/dashboard']);
                }*/
                this.router.navigate(['/dashboard']);
                return true;
              } else {
                this.router.navigate(['/demographics']);
                return false;
              }
            } else {
              this.router.navigate(['']);
              return false;
            }
        }).catch(()=>{
          localStorage.removeItem('currentLoginPanelist');
          this.router.navigate(['']);
          return Observable.of(false);
        });
      } else {
        return true;
      }
  }
}
