// Korea
export const TRANSLATIONS_KO = {
  "home_header_selectlang": "언어 선택",
  "home_header_loginbutton": "로그인",
  "home_header_signupbutton": "가입",
  "home_header_homebutton": "집", 
  
  "home_register_bannertxt1": "여기에 네가있어.",
  "home_register_bannertxt2": "생각들",
  "home_register_bannertxt3": "너를 가져올 수있어.",
  "home_register_bannertxt4": "보상$",
  "home_register_bannertxt5": "보너스 받기",
  "home_register_bannertxt6": "SignUp에서",
  "home_register_fbsignup": "페이스 북으로 가입",
  "home_register_fblogin": "페이스 북으로 로그인하십시오.",
  "home_register_or": "또는",
  "home_register_emailsignup": "이메일 등록하기",
  "home_register_emaillogin": "이메일로 로그인",
  "home_register_fpass": "비밀번호를 잊으 셨나요?",
  "home_register_fname": "이름",
  "home_register_lname": "성",
  "home_register_email": "이메일 주소",
  "home_register_pass": "암호",
  "home_register_cpass": "비밀번호 확인",
  "home_register_tcpp": "가입하면 우리에게 동의합니다.",
  "home_register_tcpptandcond": "이용 약관",
  "home_register_tcppand": "과",
  "home_register_tcppprivacy": "개인 정보 정책",
  "home_register_join": "우리와 함께하십시오. 무료입니다.",
  "home_register_login": "로그인",
  "home_register_name_required" : "이름은 필수 항목입니다.",
  "home_register_email_required" : "이메일이 필요합니다.",
  "home_register_invalid_email" : "잘못된 이메일 형식",
  "home_register_password_required" : "암호는 필수 항목입니다.",
  "home_register_password_lower_digit" : "비밀번호는 6 자리가 필요합니다.",
  "home_register_password_higher_digit" : "비밀번호는 15 자리가 필요합니다.",
  "home_register_cpassword_required" : "비밀번호 확인 필요",
  "home_register_cpassword_lower_digit" : "비밀번호 확인은 6 자리 숫자 여야합니다.",
  "home_register_cpassword_higher_digit" : "비밀번호 확인은 15 자리 숫자를 사용해야합니다.",
  "home_register_cpassword_not_match" : "비밀번호 일치하지 않음 확인",

  "home_register_fp_not_worry" : "등록 된 이메일 id를 입력 하십시오.", 
  "home_register_fp_resetpass_msg" : "비밀 번호를 재설정 하려면 등록 된 이메일 id를 입력 하세요.", 
  "home_register_fp_enter_email_msg" : "여기에 귀하의 이메일을 입력",
  "home_register_fp_submit_button" : "전송", 
  "home_register_fp_temporary_sent_pass_msg" : "비밀 번호 재설정 링크가 등록 된 이메일 id로 전송 되었습니다. 가장 빠른 시일 내에 암호를 업데이트 하십시오.",
  
  "home_works_howitworks": "어떻게 작동합니까?",
  "home_works_howitworkst1": "자기 소개", 
  "home_works_howitworksd1": "가입하기, 가입하기",
  "home_works_howitworkst2": "설문 조사 받기",
  "home_works_howitworksd2": "직관적이고 간단한 설문 조사 시작",
  "home_works_howitworkst3": "포인트 적립",
  "home_works_howitworksd3": "최선의 답변을주고 점수를 모으십시오.",
  "home_works_howitworkst4": "도로 사다",
  "home_works_howitworksd4": "많은 흥미 진진한 보상을 받기 위해 포인트 사용",
  
  "home_aboutus_whoweare": "우리는 누구인가?",
  "home_aboutus_whowearep1": "WisePerks에서는 온라인에서 보내는 시간을 보람있는 경험으로 만들기 위해 열심히 노력하고 있습니다. 우리는 귀하의 피드백을 갖고 싶어하는 수백 개의 회사와 협력하고 제품이나 서비스에 대해 어떻게 느끼는지 알고 있습니다. 귀하는 다양한 서비스에 대한 귀하의 견해를 알려주지 만 WisePerks와는 달리 귀하가 실제로 제공하는 서비스는 귀하에게 보상을 제공하지 않습니다. 귀하가 참여하는 모든 설문 조사는 귀하에게 큰 현금 보상을 제공합니다.",
  "home_aboutus_whowearep2": "우리는 귀하의 의견을 소중하게 생각하며 귀하의 의견을 기다리고 있습니다. 우리는 귀하의 관심사에 부합하고 쉽게 대답 할 수 있도록 다양한 주제에 대한 설문 조사를 실시합니다. 다양한 설문 조사를 통해 매월 약 $ 50- $ 100 정도를 손쉽게 만들 수 있습니다. 그래서 그것을 실현하기 위해 함께 노력합시다.",
  
  "home_earn_more_surabt": "설문 조사는 무엇을 물어?",
  "home_earn_more_bp": "비즈니스 전문가",
  "home_earn_more_consumers": "소비자",
  "home_earn_more_hc": "헬스 & 피트 니스",
  "home_earn_more_entertainment": "오락/재미",
  "home_earn_more_gaming": "게임 괴물",
  "home_earn_more_tech": "기술에 정통한", 
  "home_earn_more_bonus_survey": "보너스 설문 조사",
  
  "home_reward_whatuearn": "당신이 얻는 것?",
  "home_reward_whatuearnp1": "우리는 일을 단순하게 유지하는 것을 좋아합니다. 성공적으로 완료된 모든 설문 조사에 대해 점수를 얻습니다. 설문 조사가 많을수록 더 많은 포인트를 얻을 수 있습니다. 포인트는 특정 기준을 초과 한 후 원하는 기프트 카드로 사용할 수 있습니다.",
  "home_reward_whatuearnp2": "사용할 준비가되면 Amazon, Starbucks, iTunes 및 기타 여러 옵션 중에서 원하는 옵션을 선택하십시오. 우리는 또한 보너스와 감사 금요일을 제공합니다 금요일 수시로 제공합니다.",
  
  "home_counter_earnedover": "회원들은",
  
  "home_contact_getintouch": "연락하기",
  "home_contact_emailus": "궁금한 점이 있으시면 언제든지 이메일을 보내 주시기 바랍니다.",
  
  "home_footer_faq": "FAQs",
  "home_footer_pp": "개인 정보 정책",
  "home_footer_tc": "T & C",
  "home_footer_uf": "다가오는 기능", 
  "home_footer_copyright": "저작권 © current_year WisePerks. 판권 소유.",

  "home_header_home": "집",
  "home_footer_toknowmore": "더 많은 것을 알기 위해서",
  "home_footer_createacc": "무료로 계정을 만들고 수천 명의 행복한 회원들과 함께하십시오.",
  "home_footer_registernow": "지금 등록하세요",

  "home_reset_password" : "암호 재설정",
  "home_reset_pass_content" : "암호 재설정을 요청 했습니다.", 

  "dashboard_sidebar_selectavatar" : "아바타 선택",
  "dashboard_sidebar_uploadavatar" : "이미지를 업로드 하려면 여기를 클릭 하십시오.", 
  "dashboard_sidebar_pbank" : "포인트 뱅크",
  "dashboard_sidebar_accumulated" : "적립",
  "dashboard_sidebar_realized" : "실현", 
  "dashboard_sidebar_pending" : "조정",
  "dashboard_sidebar_earned_tooltip" : "지금까지 수집 한 포인트.",
  "dashboard_sidebar_pending_tooltip" : "귀하의 계정에서 취소해야 할 포인트.",
  "dashboard_sidebar_main" : "본관",
  "dashboard_sidebar_dashboard" : "계기반",
  "dashboard_sidebar_profile" : "윤곽",
  "dashboard_sidebar_updateprofile" : "프로필 업데이트",
  "dashboard_sidebar_shistory" : "설문 조사 기록",
  "dashboard_sidebar_mtrans" : "내 거래",
  "dashboard_sidebar_passbook" : "포인트 북",
  "dashboard_sidebar_redeem" : "도로 사다",
  "dashboard_sidebar_refer_friend" : "추천 친구",
  "dashboard_sidebar_logout" : "로그 아웃",
  
  "dashboard_sidebar_tutorial1" : "모든 사용 가능한 설문 조사와 당신이 그들 로부터 얻을 수 있는 무엇을 보여줍니다.",
  "dashboard_sidebar_tutorial2" : "여기에 몇 가지 질문에 대답 하 여 우리가 당신을 더 잘 알 수 있도록 하 고 관심 있는 설문 조사를 보내는 데 도움을 주세요. 몇 가지 질문에 대해서는 제출 후 응답을 업데이트 할 수 없다는 점에 유의 하십시오.",
  "dashboard_sidebar_tutorial2update" : "여기에서 응답을 업데이트할 수 있지만 업데이트에는 몇 가지 질문을 사용할 수 없습니다.",
  "dashboard_sidebar_tutorial3" : "여기서 시도한 모든 설문 조사를 찾아보십시오.",
  "dashboard_sidebar_tutorial4" : "wiseperks에 대 한 모든 거래를 추적할 수 있습니다.",
  "dashboard_sidebar_tutorial5" : "몇 가지 빠른 단계를 보상으로 실현 포인트를 변환 합니다.",
  "dashboard_sidebar_tutorial6" : "귀하의 계정으로 인출 / 신용 된 포인트의 전체 거래 내역을 확인하십시오.",
  "dashboard_sidebar_gotit" : "알았어요",

  "dashboard_sidebar_note" : "주의 사항",
  "dashboard_sidebar_note1" : "모든 응답은 정직하게 대답해야합니다.",
  "dashboard_sidebar_note2" : "개방형 응답으로 구체적이고 정확하게 작성하십시오.",
  "dashboard_sidebar_note3" : "속도를 내지 마십시오! 최소 측량 완료 시간이 설정됩니다.",
  "dashboard_sidebar_note4" : "동일한 질문을 할 때 일관된 응답을 제공하십시오.",
  "dashboard_sidebar_note5" : "새 설문 조사가 추가 되 고 닫힌 사용자가 대시보드에서 자동으로 제거 됩니다.",
  "dashboard_sidebar_note6" : "추천을 통해 빠른 포인트를 적립하십시오.",

  "dashboard_setting_hi" : "안녕",
  "dashboard_setting_minfo" : "회원 정보",
  "dashboard_setting_macc" : "계정 관리",
  "dashboard_setting_upass" : "암호 업데이트",
  "dashboard_setting_yourname" : "당신의 이름입니다",
  "dashboard_setting_fname" : "이름",
  "dashboard_setting_lname" : "성",
  "dashboard_setting_save" : "변경 사항을 저장하다",
  "dashboard_setting_bornon" : "너는 태어났다.",
  "dashboard_setting_youare" : "당신이",
  "dashboard_setting_postal" : "우편 번호는입니다.",
  "dashboard_setting_emailus" : "지원 또는 문의 사항이 있을 경우 이메일을 보내 주시기 바랍니다.",
  "dashboard_setting_membersince" : "이후로 회원이되었습니다.",
  "dashboard_setting_taken" : "너 촬영 했어.",
  "dashboard_setting_completed" : "설문 조사 및 완료",
  "dashboard_setting_ofthem" : "그들의.",
  "dashboard_setting_earned" : "너는 벌었 어.",
  "dashboard_setting_pts" : "전철기",
  "dashboard_setting_started" : "네가 시작한 이래로.",
  "dashboard_register_email" : "에서 당사에 등록 되어 있습니다.",
  "dashboard_setting_preferences" : "환경 설정 관리",
  "dashboard_setting_emailoptout" : "이메일 수신 거부",
  "dashboard_setting_futureemails" : "WisePerks로부터 향후 이메일 수신을 거부합니다.",
  "dashboard_setting_futureemailsflash" : "우리의 이메일은 당신을 위해 사용할 수 있는 모든 수익성 설문 조사에 게시 유지. 당신이 그들을 받고 싶지 않아 확신 합니다.",
  "dashboard_setting_nrecemail" : "우리의 이메일은 당신을 위해 사용 가능한 모든 수익성 설문 조사에 게시 유지. 당신이 그들을 중지 하지 않으려는 확신 합니다.",
  "dashboard_setting_emailout_modalmsg" : "당신은 당신의 wiseperks에서 미래의 이메일을 수신 거부 하 시겠습니까?",
  "dashboard_setting_emailin_modalmsg" : "당신은 wiseperks 으로부터 미래의 이메일을 받기 시작 하 시겠습니까?",
  "dashboard_setting_emailout_succmsg" : "당신이 성공적으로 wise특권에서 이메일 수신을 중지 했습니다",
  "dashboard_setting_emailin_succmsg" : "당신이 성공적으로 wise특권에서 이메일 수신을 시작 했습니다",
  "dashboard_setting_deactivateacc" : "계정 비활성화",
  "dashboard_setting_disableprofile" : "계정을 비활성화하면 WisePerks에서 프로파일이 비활성화됩니다.",
  "dashboard_setting_deactivateacc_msg" : "wiseperks으로 계정을 비활성화 하 시겠습니까?",
  "dashboard_setting_deactivateacc_flash" : "당신은 어떤 불만이 나 의견이 있다면, 우리와 함께 공유 하시기 바랍니다. 우리는 우선권에 그것을 해결할 것입니다. 우리에 게 기회를 줘. 우리와 함께 귀하의 계정을 삭제 하 시겠습니까.",
  "dashboard_setting_changelang" : "언어 변경",
  "dashboard_setting_preferredlang" : "원하는 언어 선택",
  "dashboard_setting_updatepass" : "암호 업데이트",
  "dashboard_setting_plsupdate" : "아래에서 암호를 업데이트 하십시오.",
  "dashboard_setting_oldpass" : "기존 비밀번호",
  "dashboard_setting_enteroldpass" : "이전 암호 입력",
  "dashboard_setting_newpass" : "새 비밀번호",
  "dashboard_setting_enternewpass" : "새 비밀번호를 입력하십시오",
  "dashboard_setting_confpass" : "비밀번호 확인",
  "dashboard_setting_enternewpassagain" : "다시 새 암호 입력",
  "dashboard_setting_update" : "최신 정보",

  "dashboard_doi_welcome" : "환영",
  "dashboard_doi_welbck" : "다시 돌아온다.",
  "dashboard_doi_accntverified" : "귀하의 이메일 계정을 확인 놓친 것 같은 데.",
  "dashboard_doi_getaccess" : "서둘러! 당신이 얻을",
  "dashboard_doi_getaccess2" : "포인트를 확인 합니다.",
  "dashboard_doi_activateacc" : "받은 편지함을 확인 하 고 등록 된 이메일 계정으로 전송 된 계정 확인 이메일에 있는 링크를 클릭 하십시오.",
  "dashboard_doi_chckspam" : "만약 당신이 확인 이메일을 찾을 수 없습니다 친절 하 게, 귀하의 스팸 메일/정크 폴더를 확인 합니다.",
  "dashboard_doi_didntgetconf" : "아직 확인 이메일을 못 했나요?",
  "dashboard_doi_resendconf" : "확인 이메일 재전송",
  "dashboard_doi_resendconfsucc" : "확인 이메일이 등록 된 이메일로 전송 되었습니다.", 
  
  "dashboard_survey_availsurveys" : "사용 가능한 설문 조사",
  "dashboard_survey_wisechecksurveys1" : "보안상의 이유로 인해 설문 조사를 볼 수 없습니다. 지원 팀에 문의 하십시오.",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "구독 취소 사유에 대 한 설문 조사 사용 불가",
  "dashboard_featured" : "추천",
  "dashboard_profile_survey" : "프로필 설문 조사",
  "dashboard_survey_start" : "스타트",
  "dashboard_survey_on" : "에",
  "dashboard_survey_off" : "떨어져서",
  "dashboard_survey_point": "지점",
  "dashboard_survey_points" : "포인트",
  "dashboard_survey_minute" : "분",
  "dashboard_survey_minutes" : "분",

  "demography_hi" : "안녕",
  "demography_welcome" : "마법에 오신 것을 환영 합니다. 당신은 방금 자신을 얻었습니다",
  "demography_points" : "우리와 함께 등록 하 여 포인트.",
  "demography_share" : "제발, 당신에 게 관련성이 높은 설문 조사를 데 려 오 우리를 돕기 위해 아래의 양식에 몇 가지 자세한 내용을 공유할 수 있습니다.",

  "demography_gender" : "나는",
  "demography_male" : "남성",
  "demography_female" : "여자",
  "demography_birthday" : "생일",
  "demography_month" : "달", 
  "demography_day" : "하루", 
  "demography_year" : "년", 
  "demography_jan" : "일월",
  "demography_feb" : "이월",
  "demography_mar" : "행진",
  "demography_apr" : "4 월",
  "demography_may" : "할 수있다",
  "demography_jun" : "유월",
  "demography_jul" : "칠월",
  "demography_aug" : "팔월",
  "demography_sep" : "구월",
  "demography_oct" : "십월",
  "demography_nov" : "십일월",
  "demography_dec" : "12 월",
  "demography_hearabout" : "WisePerks에 대한 정보를 어디에서 들었습니까?",
  "demography_opt1" : "적용되는 가장 가까운 것을 선택하십시오.",
  "demography_opt2" : "이메일 / 뉴스 레터",
  "demography_opt3" : "페이스 북",
  "demography_opt4" : "포럼 또는 블로그",
  "demography_opt5" : "친구",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "검색 엔진",
  "demography_opt8" : "TV 광고",
  "demography_opt9" : "트위터",
  "demography_opt10" : "웹사이트",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "다른",
  "demography_zipcode": "우편 번호",
  "demography_submit" : "전송",

  "privacypolicy_privacypolicy" : "개인 정보 정책",
  "privacypolicy_privacyisimp" : "귀하의 개인 정보는 우리에게 중요합니다.",
  "privacypolicy_lastupdated" : "마지막 업데이트 날짜",

  "privacypolicy_para1" : 'WiseWorks (계열사 인 "회사"와 함께)는 Wisesample.com, Flapbucks.com, WisePerks.com 및 기타 서비스를 포함하여 다양한 웹 사이트, 모바일 응용 프로그램 및 대화 형 서비스를 소유하고 운영합니다 (통칭 " WiseWorks 사이트 ").이 개인 정보 보호 정책 ( "개인 정보 보호 정책")은 WiseWorks 사이트 및 모든 기능, 모바일 응용 프로그램, 전자 메일, 온라인 서비스 및 기타 기능 (컴퓨터, 모바일 장치 또는 기타 방법을 통해 액세스 여부와 관계없이)을 통해 사용 가능한 서비스에 적용됩니다 WiseWorks 사이트 및 기능 ( "서비스").이 개인 정보 보호 정책은 WiseWorks 사이트 및 기능 및 서비스를 통해 회사가 수집 한 정보만을 다루며, 명시 적으로 언급하지 않는 한 회사에서 오프라인으로 수령하거나 수집 한 정보는 포함하지 않으며 관련없는 웹 사이트 또는 회사가 수집 할 수있는 정보는 포함하지 않습니다. 링크를 제공하십시오.',

  "privacypolicy_para2" : "패널리스트 / 피청구인은 정직하고 진실되게 자신의 프로필을 작성하고 더 많은 설문 조사를 받기 위해 잘못되었거나 잘못된 정보를 제출하지 않아야합니다.또한 패널리스트 / 응답자는 설문 작성시 인센티브를 얻기 위해 자신의 답변을 조작하거나 기타 불공평 한 수단 (IP, 다중 계정 등 변경)을 사용하지 않아야합니다.WisePerks에는 그러한 예외를 탐지하기위한 충분한 보호 장치가 있으며 그러한 행위가 발견 된 경우 패널리스트는 WisePerks로부터 금지 / 차단되며 모든 / 그의 보너스 요청은 취소됩니다.",

  "privacypolicy_para3" : "WiseWorks는 귀하의 개인 정보를 안전하게 보호하기 위해 최선을 다하고 있습니다.다음 개인 정보 보호 정책은 귀하가 당사의 웹 사이트 및 서비스를 탐색 할 때 귀하의 개인 정보가 어떻게 유지되고 취급되는지를 설명합니다.",

  "privacypolicy_topic1" : "WisePerks.com은 어떤 개인 식별 정보를 수집합니까?",
  "privacypolicy_topic1ans" : "WisePerks.com은 웹 사이트와 네트워크의 여러 부분에서 여러 가지 방법으로 정보를 수집합니다.가입시 일부 정보가 수집됩니다.회원 등록시 WisePerks.com은 귀하의 이름, 이메일 주소, 우편 번호 및 세대 정보를 요청합니다.귀하가 정확하게 자원하여 제공하는 정보가 많을수록 귀하에게 서비스와 보상을 제공 할 수있게됩니다.또한 어떠한 방법으로도 WisePerks.com에 연락하면 해당 서신을 기록 할 수 있습니다.WisePerks.com이 이러한 정보를 수집 할 때마다 해당 개인 정보 취급 방침에 대한 링크를 해당 페이지에 포함시키는 것을 목표로합니다.",

  "privacypolicy_topic2" : "쿠키 란 무엇이며 WisePerks.com은 쿠키를 어떻게 사용합니까?",
  "privacypolicy_topic2ans" : "쿠키는 브라우저, 웹 서버 및 컴퓨터의 하드 드라이브에 저장된 전자 데이터 패킷입니다.특정 설문 조사, 정보 및 맞춤 서비스를 제공하는 과정에서 WisePerks.com은 쿠키를 사용하여 귀하의 정보를 저장하고 때로는 추적 할 수 있습니다.WisePerks.com은 그러한 특정 컨텐츠를 준비 할 때 쿠키를 수락해야 할 수도 있습니다.또한 매번 로그인 정보를 입력하지 않아도됩니다.",

  "privacypolicy_topic3" : "개인 계정 정보를 업데이트, 편집, 변경 또는 삭제할 수 있도록 허용하는 WisePerks.com 정책은 무엇입니까?",
  "privacypolicy_topic3ans" : "귀하는 WisePerks.com 로그인 및 암호를 사용하여 언제든지 귀하의 WisePerks.com 계정 정보를 편집 할 수 있습니다.WisePerks.com 계정을 비활성화하는 방법에 대한 자세한 내용은",
  "privacypolicy_topic3ans2" : "에 문의하십시오.",

  "privacypolicy_topic4" : "누가 정보를 수집하고 있으며 어떤 목적으로 사용하고 있습니까?",
  "privacypolicy_topic4ansp1" : "WisePerks.com은 설문 조사 및 등록 및 기타 방법을 통해 여러 가지 정보를 수집하여 연구 고객이 소비자에게 새로운 제품과 서비스를 개발할 수 있도록 지원합니다.귀하가 참여하는 설문 조사에 달리 명시되어 있지 않는 한 귀하는 WisePerks.com과 만 해당 정보를 공유합니다.WiseWorks 또는 WiseWorks 사이트가 아닌 다른 회사에서 데이터를 수집 및 / 또는 유지 관리하는 경우 데이터 수집 또는 이전 시점에 통보됩니다.데이터를 공유하지 않으려면 해당 설문 조사 또는 제품을 사용하지 않고 전송을 허용하지 않을 수도 있습니다.",
  "privacypolicy_topic4ansp2" : "WisePerks.com에서 제공하는 보상 및 프로모션은 다른 회사가 독립적으로 후원하거나 WisePerks.com 및 다른 회사가 공동 후원 할 수 있습니다.그러한 홍보 중에 수집 된 일부 데이터는 스폰서와 공유 될 수 있습니다.그러나 데이터 수집 또는 이전 시점에 통보됩니다.데이터를 공유하고 싶지 않으면 특정 프로모션에 참여하지 않기로 결정할 수 있습니다.",
  "privacypolicy_topic4ansp3" : "특정 민감 정보 (의료 또는 건강 상태, 인종 또는 민족 출신, 정치적 견해, 종교 또는 영적 신념, 무역 및 노동 조합 회원 또는 개인의 성 생활을 명시한 정보와 같은 정보)은 추가 치료로 처리됩니다.WisePerks.com이 원래 수집 한 목적과 다른 목적으로 다른 회사와 정보를 공유하고자하는 경우 그러한 공개 전에 귀하는 명시 적으로 허가를 요청 받게됩니다.",

  "privacypolicy_topic5" : "WisePerks.com은 누구에게 내 정보를 공유합니까?",
  "privacypolicy_topic5ansp1" : "우리는 프라이버시를 매우 중요하게 생각합니다.WisePerks.com은 귀하가 명시 적으로 허락을 받았거나 특별한 사정이있는 경우, 또는 법이 그것을 요구하거나 아래 명시된 상황에서 귀하의 개인 식별 정보를 공개하지 않습니다.다음은 귀하의 정보가 공개 될 수있는 몇 가지 방법을 설명합니다.개인 정보가 공유되는 방법에 대한 자세한 내용은 서비스에 대한 서비스 약관을 참조하십시오.",
  "privacypolicy_topic5ansp2" : "비즈니스 파트너, 고객 및 스폰서 :",
  "privacypolicy_topic5ansp3" : "WisePerks.com은 파트너, 고객 또는 스폰서에게 귀하의 개인 정보를 공개 할 수 있습니다.그러나 귀하에 관한 개인 데이터가 공유되어야한다면, 데이터를 수집하거나 전송하기 전에 알려드립니다.우리가 제공하는 많은 활동은 스폰서로부터 추가 정보를 요청합니다.더 많은 정보를 요청하면 WisePerks.com은 스폰서와 정보를 공유 할 수있는 권한을 부여하므로 귀하의 요청을 이행 할 수 있습니다.많은 경우 귀하의 이메일 주소 만 공유됩니다.파트너와 더 많은 정보가 공유되면 사전에 통보됩니다.때때로 파트너에게 테스트 자료 또는 기타 제품 또는 특별 혜택을 제공받을 수있는 기회가 제공 될 수 있습니다.이러한 제 3 자로부터 정보를 수신하도록 선택하면 WisePerks.com은 (귀하의 허락하에) 귀하의 이름과 이메일 주소를 그들과 공유하게됩니다.",
  "privacypolicy_topic5ansp4" : "WisePerks.com은 WisePerks.com의 정책 및 서비스 약관을 위반하거나 피해를 입을 수있는 사람을 식별, 연락 또는 법적 조치를 취하기 위해이 정보를 공개해야하는 특별한 경우에도 개인 정보를 공개 할 수 있습니다. WisePerks.com의 제품, 서비스, 권리 또는 재산, 다른 WisePerks.com 회원 및 사용자 또는 그러한 활동의 ​​영향을받을 수있는 다른 사람의 (의도적 또는 비 의도적) 간섭.WisePerks.com은 법이 요구하는 바를 믿고 상품 및 서비스를 유지, 서비스 및 개선하는 데 필요한 관리 및 기타 목적으로 계정 정보를 공개하거나 액세스 할 수 있습니다.",

  "privacypolicy_topic6" : "귀하의 정보 처리를 위해 사용하는 합법적 근거는 무엇입니까?",
  "privacypolicy_topic6ans" : 'WiseWorks는 개인 정보 보호 및 데이터 보호에 전념합니다.우리의 개인 정보 보호 정책은 우리, 우리 직원 및 공급 업체가 준수하는 벤치 마크로서 유럽 연합 (GDPR)이 발행 한 일반 데이터 보호 규정의 원칙을 채택합니다.',

  "privacypolicy_topic7" : "내 정보의 수집, 사용 및 배포에 관한 나의 선택은 무엇입니까?",
  "privacypolicy_topic7ans" : "WisePerks.com (또는 기밀 계약하에 WisePerks.com과 협력하는 파트너)만이 귀하가이 사실에 이의를 제기하지 않았다고 명시한 경우에만 귀하에게 모든 직접 우편을 보냅니다.또한 쿠키 작동 방식에 대한 선택권이 있습니다.인터넷 브라우저 설정을 변경하면 모든 쿠키를 허용하거나 쿠키가 설정 될 때 통보하거나 모든 쿠키 요청을 거부 할 수 있습니다.모든 쿠키를 거부하기로 선택하면 이전 등록이 필요한 WisePerks.com 서비스를 사용하여 참여할 수 없습니다.WisePerks.com은 모든 사용자 정보를 누구에게도 판매하거나 임대하지 않습니다.귀하의 데이터가 제 3 자와 공유되고 귀하가 항상 공유하도록 허용 할 수있는 옵션이 있다면 우리는 데이터 수집 또는 전송시 귀하에게 항상 통보 할 것입니다.",

  "privacypolicy_topic8" : "WisePerks.com에서 계정 정보의 손실, 오용 또는 변경을 보호하기 위해 어떤 보안 사전 조치가 있습니까?",
  "privacypolicy_topic8ansp1" : "귀하의 WisePerks.com 계정 정보는 귀하가 오직이 정보에 접근 할 수 있도록 암호로 보호되어 있습니다.",
  "privacypolicy_topic8ansp2" : "암호를 다른 사람과 공유하지 않는 것이 좋습니다.원하지 않는 전화 또는 이메일로 비밀번호를 묻지 않습니다.WisePerks.com 계정에서 로그 아웃하고 활동이 끝나면 브라우저 창을 닫으십시오.이렇게하면 공용 컴퓨터를 사용하거나 다른 사람과 컴퓨터를 공유하는 경우 다른 사람들이 귀하의 개인 정보와 계정에 액세스 할 수 없습니다.WisePerks.com이 위에 설명 된대로 개인 정보를 처리 할 때마다 WisePerks.com은 귀하의 정보가 관련 서비스 약관 및 개인 정보 보호 정책에 따라 안전하게 취급되도록 조치를 취합니다.안타깝게도 인터넷을 통한 데이터 전송은 100 % 안전 보장 할 수 없습니다.결과적으로 귀하의 개인 정보를 보호하기 위해 노력하는 동안 WisePerks.com은 귀하가 당사 또는 온라인 제품이나 서비스로부터 전송하는 정보의 보안을 보증하거나 보증 할 수 없으며, 귀하는 귀하의 책임하에 그렇게합니다.Google은 귀하의 데이터를 수신하면 Google 시스템에서 보안을 유지하기 위해 최선을 다합니다.",

  "privacypolicy_topic9" : "내 사생활에 대해 무엇을 알아야합니까?",
  "privacypolicy_topic9ans" : "이메일, 소셜 네트워크 웹 사이트, 블로그 또는 채팅 영역을 통해 온라인에서 개인 정보를 공개하기로 선택할 때마다 다른 사용자가 그 정보를 수집하고 사용할 수 있다는 점을 명심하십시오.일반인이 액세스 할 수있는 온라인 개인 정보를 게시하는 경우 귀하는 다른 당사자로부터 자발적이지 않은 메시지를 수령 할 수 있습니다.궁극적으로 귀하는 귀하의 암호 및 / 또는 계정 정보의 비밀 유지에 대한 전적인 책임이 있습니다.온라인 상태가 될 때마다 조심하고 책임을 져야합니다.",

  "privacypolicy_topic10" : "WisePerks.com은 어떻게 자녀들의 개인 정보를 보호합니까?",
  "privacypolicy_topic10ans" : "WisePerks.com은 설문 조사에 아동의 참여가 요구되며 6-15 세 아동의 설문 조사에 부모의 감독이 요구되는 경우 사전에 학부모 동의를받습니다.우리는 사이트의 청소년 사용자의 안전과 개인 정보를 보호하기 위해 특별한주의를 기울이고 온라인에서 자녀의 경험에 참여하도록 권장합니다.우리는 사이트의 청소년 사용자의 안전과 개인 정보를 보호하기 위해 특별한주의를 기울이고 온라인에서 자녀의 경험에 참여하도록 권장합니다.",

  "privacypolicy_topic11" : "국제 사용자를위한 특별 참고 사항",
  "privacypolicy_topic11ansp1" : "이 사이트는 미국에서 호스팅됩니다.귀하가 미국 법과 다른 개인 정보 수집, 사용 및 공개에 관한 법률이나 규정을 가진 유럽 연합, 아시아 또는 기타 지역에서 본 사이트에 액세스하는 사용자 인 경우 귀하의 개인 데이터를 EU 및 기타 지역과 동일한 데이터 보호법을 갖고 있지 않으며 귀하의 개인 데이터를 제공함으로써 귀하가 동의 한 미국 :",
  "privacypolicy_topic11ansp2" : "개인 정보 보호 정책에 따라 위에 명시된 용도로 개인 데이터를 사용하는 행위. 위에 명시된대로 귀하의 개인 정보를 미국으로 이관하는 것.귀하는 미국의 법률에 따라 귀하의 정보가 다른 국가 및 관할 법의 규정에 따라 필요한 것과 상당히 다를 수 있으며 귀하의 정보가 귀하의 정보를 보호한다는 보장을받을 수 있음을 인정합니다.귀하의 정보가 미국으로 이전되기를 원하지 않는다면, 귀하는 귀하의 정보를 미국과 공유하거나 사이트를 사용해서는 안됩니다.귀하가 위치한 국가의 법률이 허용하는 범위 내에서 귀하는 명백히 미국을 제외한 모든 국가 또는 관할 법에 따라 귀하의 신원 정보를 취급 할 권리가 있음을 명시 적으로 포기할 수 있습니다.그러나 전술 한 권리 포기자는 유럽 연합 회원국과 같은 일부 국가에서 합법적으로 구속력을 가질 수 없습니다.귀하가 거주하는 국가에 법적으로 구속력이있는 경우가 아니라면,이 전술 한 권리 포기는 귀하에게 적용되지 않습니다.",

  "privacypolicy_topic12" : "이 개인 정보 보호 정책의 변경 및 업데이트",
  "privacypolicy_topic12ans" : "WiseWorks는 재량에 따라 언제든지이 개인 정보 취급 방침의 일부를 변경, 수정, 추가 또는 제거 할 수있는 권리를 보유합니다.WiseWorks가 그러한 권리를 행사할 때마다 귀하는 업데이트 된 개인 정보 보호 정책을 적극적으로 수락해야하며, 그렇게하지 않으면 더 이상 해당 사이트에 액세스하거나 사용할 수 없게됩니다.",

    "privacypolicy_topic13": "GDPR / CCPA 세부 정보를 찾는 사람들을위한 특별 참고 사항",
    "privacypolicy_topic13ansp1": "부디",
    "privacypolicy_topic13ansp2": "여기를 클릭하세요",
    "privacypolicy_topic13ansp3": "GDPR (일반 데이터 보호 규정) 및 CCPA (캘리포니아 소비자 개인 정보 보호법) 준수 세부 정보에 대해 자세히 알아보십시오.",

  "privacypolicy_addinfo" : "추가 정보",
	"privacypolicy_sentto": "이에 관한 다른 질문은로 보내야합니다.",

    "privacypolicyext_para1": "이 개인 정보 보호 정책을주의 깊게 읽으십시오.",
    "privacypolicyext_para2": "WiseWorks는 모든 사용자의 개인 정보를 존중하며 개인 데이터를 처리하는 방법에 대해 투명하게하기 위해 최선을 다합니다. 이 개인 정보 보호 정책은 WiseWorks 웹 사이트를 사용하거나 당사와 상호 작용할 때 WiseWorks가 당사에 제공 한 개인 데이터를 수집, 공유 및 사용하는 방법을 설명합니다. 또한 개인 정보 보호 권리와 선택권을 행사할 수있는 방법을 배우기 위해 몇 가지 섹션을 제공했습니다.",
    "privacypolicyext_para3": "당사 웹 사이트에 액세스하고 WiseWorks 서비스를 사용하기 전에이 개인 정보 보호 정책을주의 깊게 읽으십시오. 질문이 있으시면 다음 주소로 문의하십시오. ",

    "privacypolicyext_topic1": "개요",
    "privacypolicyext_topic2": "수락 및 사용",
    "privacypolicyext_topic3": "우리는 누구인가",
    "privacypolicyext_topic4": "개인 정보 및 이용 요약",
    "privacypolicyext_topic5": "데이터 수집 목적",
    "privacypolicyext_topic6": "데이터 보호 원칙",
    "privacypolicyext_topic7": "귀하로부터 수집하는 데이터 유형",
    "privacypolicyext_topic8": "쿠키 및 웹 비콘",
    "privacypolicyext_topic9": "설문 조사 및 연구 프로젝트에 대해 알림",
    "privacypolicyext_topic10": "귀하의 데이터를 사용하는 방법",
    "privacypolicyext_topic11": "귀하의 데이터를 공유하는 사람과",
    "privacypolicyext_topic12": "EEA 데이터 주체 및 비 EEA",
    "privacypolicyext_topic13": "귀하의 데이터 보호 권리",
    "privacypolicyext_topic14": "어린이에 대한 우리의 정책",
    "privacypolicyext_topic15": "데이터 보안",
    "privacypolicyext_topic16": "이용 약관",
    "privacypolicyext_topic17": "Jurisdiction",
    "privacypolicyext_topic18": "개인 정보 보호 정책 변경",
    "privacypolicyext_topic19": "문의하기",

    "privacypolicyext_t1": "우리들",
    "privacypolicyext_t2": "우리 ",
    "privacypolicyext_t3": "또는 ",
    "privacypolicyext_t4": "우리의",

    "privacypolicyext_topic1ansp1": " 는 사용자의 개인 정보 보호 권리를 존중하고 법률 및 모범 사례에 따라 귀하에 대해 수집 된 정보를 보호하고 처리하는 것의 중요성을 이해합니다. 이 개인 정보 보호 정책은 다음 사항을 준수하기 위해 작성되었습니다.",
    "privacypolicyext_topic1ansp1_1": "EU 일반 데이터 보호 규정, 즉 Regulation (EU) 2016/679 ( 'GDPR') 및 캘리포니아 소비자 개인 정보 보호법 CCPA의 13 조 및 14 조.",
    "privacypolicyext_topic1ansp2": "이 개인 정보 보호 정책은 다음을 보유한 사람들과 관련하여 당사가 처리하는 개인 정보에 적용됩니다.",
    "privacypolicyext_topic1ansp2_l1": "당사에 계정을 등록하십시오.",
    "privacypolicyext_topic1ansp2_l2": "당사로부터 마케팅 정보 수신에 동의했습니다.",
    "privacypolicyext_topic1ansp2_l3": "설문 조사에 참여하십시오.",
    "privacypolicyext_topic1ansp2_l4": "설문 조사에 참여하십시오.",
    "privacypolicyext_topic1ansp3": '이 개인 정보 보호 정책은 또한 귀하의 데이터와 관련하여 귀하가 갖는 권리, 귀하가 동의하는 방법 및 동의를 철회 할 수있는 방법을 설명합니다. WiseWorks (계열사와 함께 "회사")는 Wisesample.com, Flapbucks.com, WisePerks.com 및 기타 (총칭하여 "WiseWorks 사이트 "). 이 개인 정보 보호 정책 ( "개인 정보 보호 정책")은 WiseWorks 사이트와 모든 기능, 모바일 애플리케이션, 이메일, 온라인 서비스 및 기타 기능에 적용되며, 컴퓨터, 모바일 장치 등을 통해 액세스하는지 여부와 WiseWorks 사이트 및 기능 ( "서비스")을 통해. 본 개인 정보 보호 정책은 WiseWorks 사이트 및 기능 및 당사 서비스를 통해 회사가 수집 한 정보에만 적용되며, 회사가 오프라인으로 수신하거나 수집 한 정보 (특별히 명시되지 않은 경우) 또는 관련없는 웹 사이트 또는 회사가 수집 한 정보에는 적용되지 않습니다. 링크를 제공하십시오.',

    "privacypolicyext_topic2ansp1_1": "이 개인 정보 보호 정책은 ",
    "privacypolicyext_topic2ansp1_2": " 및 그 자회사를 포함한",
    "privacypolicyext_topic2ansp1_3": "집합 적으로 ",
    "privacypolicyext_topic2ansp1_4": "당사 웹 사이트를 사용하는 것은 귀하의 개인 정보 처리에 대한 귀하의 공식 동의, 아래에 설명 된대로 귀하의 개인 정보 수집, 사용 및 공개에 대한 귀하의 동의로 간주되는 본 개인 정보 보호 정책의 조건에 동의 함을 의미합니다. 당사는 귀하에게 특정 서비스의 데이터 처리 관행에 대한 추가 공개 또는 정보를 제공하도록 요청할 수 있으며, 이러한 통지는 당사의 개인 정보 관행을 보완 또는 명확하게하거나 당사가 귀하의 데이터를 처리하는 방법에 대한 기타 선택 사항을 제공 할 수 있습니다.",
    "privacypolicyext_topic2ansp2": "이 개인 정보 보호 정책의 어떤 측면에 익숙하지 않은 경우. WiseWorks 웹 사이트 및 당사 웹 사이트에 대한 모든 액세스를 즉시 중단해야합니다.",

    "privacypolicyext_topic3ansp1_1": " 캐나다 (# 10369675)에 설립 및 등록 된 회사입니다. ",
    "privacypolicyext_topic3ansp1_2": "온라인 시장 조사 산업을위한 패널 서비스를 제공합니다. 자격 요건을 충족하는 사용자 또는 참가자는 사용자 패널 계정에 등록하고 설문 조사에 참여하여 포인트 또는 기타 인센티브를받을 수 있습니다.",
    "privacypolicyext_topic3ansp2": "당사의 서비스는 캘리포니아 (미국), 캐나다, 독일, 인도 등 여러 국가에서 사용할 수 있습니다. 서비스는 18 세 미만의 사용자가 사용할 수 없습니다. 당사 웹 사이트 및 서비스를 사용하려면 18 세 이상이어야합니다.",

    "privacypolicyext_topic4ansp1": "당사는 귀하에 대한 개인 데이터를 수집하거나 얻을 수 있으며이 정보는 다음 방법을 통해 미국 지역 내에서 저장 또는 처리 될 수 있습니다.",
    "privacypolicyext_topic4ansp1_l1": "귀하로부터 직접 (예 : 귀하가 당사에 연락하는 곳) 귀하와의 관계 과정에서 (예 : 귀하가 당사 서비스를 사용하는 경우)",
    "privacypolicyext_topic4ansp1_l2": "귀하가 당사 웹 사이트를 방문 할 때 또는 웹 사이트의 일부를 사용합니다.",
    "privacypolicyext_topic4ansp2": "또한 제 3 자로부터 귀하에 대한 개인 정보를받을 수도 있습니다.",

    "privacypolicyext_topic5ansp1": "다음은 당사가 귀하의 개인 정보를 사용하는 방법의 예입니다.",
    "privacypolicyext_topic5ansp1_l1": "서비스 제공",
    "privacypolicyext_topic5ansp1_l2": "웹 사이트 제공 및 개선",
    "privacypolicyext_topic5ansp1_l3": "설문 조사",
    "privacypolicyext_topic5ansp1_l4": "통신",
    "privacypolicyext_topic5ansp1_l5": "사용자 참여",
    "privacypolicyext_topic5ansp1_l6": "마케팅",
    "privacypolicyext_topic5ansp1_l7": "리드 생성",
    "privacypolicyext_topic5ansp1_l8": "법률 준수",

    "privacypolicyext_topic6ansp1": "당사는 개인 데이터를 수집, 처리 및 사용합니다 (EU 일반 데이터 보호 규정 제 13 조 및 14 조, 즉 Regulation (EU) 2016/679 ( 'GDPR') 및 캘리포니아 소비자 개인 정보 보호법 CCPA)의 정의에 따라). 우리의 서비스를 제공하십시오. 개인 데이터는 이름, 이메일, 주소, 건강 정보, 모든 형태의 식별 번호 또는 귀하 또는 귀하의 수혜자의 신원에 특정한 하나 이상의 요소와 같이 당사가 귀하를 직접 또는 간접적으로 식별 할 수있는 모든 정보를 의미합니다.",
    "privacypolicyext_topic6ansp2": "우리는 관련 데이터 보호법을 준수하기 위해 최선을 다하고 있으며 개인 데이터가 다음과 같은지 확인합니다.",
    "privacypolicyext_topic6ansp2_l1": "합법적이고 공정하며 투명하게 사용됩니다.",
    "privacypolicyext_topic6ansp2_l2": "당사가 귀하에게 명확하게 설명하고 그러한 목적과 호환되지 않는 방식으로 사용하지 않은 유효한 목적으로 만 수집됩니다.",
    "privacypolicyext_topic6ansp2_l3": "우리가 귀하에게 말한 목적과 관련이 있으며 그러한 목적으로 만 제한됩니다.",
    "privacypolicyext_topic6ansp2_l4": "정확하고 최신 상태로 유지됩니다.",
    "privacypolicyext_topic6ansp2_l5": "우리가 말한 목적을 위해 필요한 기간 동안 만 보관합니다.",

    "privacypolicyext_topic7ansp1": "당사는 귀하에 대해 다음과 같은 개인 정보를 수집하고 처리 할 수 있습니다.",
    "privacypolicyext_topic7ansp1_l1title": "개인 프로필 정보.",
    "privacypolicyext_topic7ansp1_l1": "귀하가 당사에 계정을 등록하면 귀하의 성과 이름, 이메일 주소 및 귀하가 선택한 비밀번호를 수집합니다. ",
    "privacypolicyext_topic7ansp1_l2title": "연락처 정보.",
    "privacypolicyext_topic7ansp1_l2": "문자 메시지 또는 기타 메시징 매체를 통해 설문 조사를 받기로 선택한 경우 당사는 귀하의 휴대폰 번호 또는 관련 연락처 정보도 수집합니다.",
    "privacypolicyext_topic7ansp1_l3title": "설문 데이터.",
    "privacypolicyext_topic7ansp1_l3": "초기 프로파일 링 설문지는 귀하의 배경, 교육, 근무 상태, 소비자 습관 및 선택적으로 귀하의 건강에 대한 정보를 수집합니다.",
    "privacypolicyext_topic7ansp1_l4title": "의견 데이터.",
    "privacypolicyext_topic7ansp1_l4": "연구 기회를 통해 귀하의 의견, 태도, 경험 및 특정 제품 또는 서비스 사용과 관련하여 귀하가 제공 한 정보를 수집하며, 때때로 조사 기회 동안 귀하가 제출 한 비디오, 오디오 또는 사진을 수집 할 수 있습니다.",
    "privacypolicyext_topic7ansp1_l5title": "사용자 경험 데이터.",
    "privacypolicyext_topic7ansp1_l5": "우리는 귀하의 로그인, 블로그 게시물에 대한 댓글, 평가, 설문 조사, 본 웹 페이지에 대한 정보를 수집합니다.",
    "privacypolicyext_topic7ansp1_l6title": "보상 정보.",
    "privacypolicyext_topic7ansp1_l6": "귀하에게 상품이나 보상을 제공하거나 귀하의 패널 및 설문 조사 활동 사용을 모니터링하는 데 필요한 모든 개인 정보.",
    "privacypolicyext_topic7ansp1_l7title": "통신 데이터.",
    "privacypolicyext_topic7ansp1_l7": "귀하가 당사에 보내는 모든 서신.",
    "privacypolicyext_topic7ansp1_l8title": "사용 데이터.",
    "privacypolicyext_topic7ansp1_l8": "또한 당사는 귀하가 당사 서비스와 상호 작용할 때마다 귀하에 대한 사용 데이터를 수집하며 여기에는 귀하가 방문하는 웹 페이지, 이러한 작업을 수행 할 때 클릭 한 항목 등이 포함될 수 있습니다. 또한 장치가 서버에 액세스 할 때마다 데이터를 기록하는 디지털 로그 파일을 보관합니다. 로그 파일에는 각 액세스의 특성에 대한 데이터가 포함됩니다. 당사는 귀하의 IP 주소, 브라우저 유형, 인터넷 서비스 제공 업체 (ISP), 참조 / 종료 페이지, 운영 체제, 날짜 / 시간 스탬프 및 클릭 스트림 데이터와 같이 귀하가 당사 서비스에 액세스하기 위해 사용하는 장치 및 애플리케이션에서 데이터를 수집합니다.",
    "privacypolicyext_topic7ansp2": "또한 귀하의 IP 주소를 기반으로 귀하의 지리적 위치를 추론 할 수도 있습니다. 외부 소스 (예 : 이메일 또는 메시징 앱에 포함 된 다른 웹 사이트의 링크)에서 WiseWorks 서비스에 도착하면이 정보를 사용하여 추세를 분석하고, 사이트를 관리하고, 사용자를 추적하고, 인구 통계 정보를 수집 할 수 있습니다. 전체 사용자 기반에 대해.",

    "privacypolicyext_topic8ansp1": "쿠키는 귀하가 웹 사이트를 방문 할 때 귀하의 브라우저로 전송되는 소량의 정보가 포함 된 텍스트 파일입니다. 그런 다음 쿠키는 이후 방문 할 때마다 원래 웹 사이트 또는이를 인식하는 다른 웹 사이트로 다시 전송됩니다. 쿠키는 매우 유용한 기술이며 다양한 작업을 수행합니다. 웹 비콘은 일반적으로 1 픽셀 x 1 픽셀 이하의 투명한 그래픽 이미지로, 웹 사이트를 방문하거나 이메일을 보내는 사용자의 행동을 모니터링하는 데 사용되는 웹 사이트 또는 이메일에 배치됩니다. 종종 쿠키와 함께 사용됩니다.",
    "privacypolicyext_topic8ansp2": "당사는 쿠키, 웹 비콘 또는 유사한 분석 기반 기술을 사용하여 정보를 수집 할 수 있습니다.",
    "privacypolicyext_topic8ansp3_title": "우리는 어떤 쿠키를 사용합니까?",
    "privacypolicyext_topic8ansp3": "우리가 사용하는 쿠키는 다음 범주에 속합니다.",
    "privacypolicyext_topic8ansp3_l1title": "꼭 필요한 쿠키.",
    "privacypolicyext_topic8ansp3_l1": "당사 웹 사이트 운영에 필요한 쿠키로, 예를 들어 당사 웹 사이트의 보안 영역에 로그인 할 수있는 쿠키가 포함됩니다.",
    "privacypolicyext_topic8ansp3_l2title": "분석 / 성능 쿠키.",
    "privacypolicyext_topic8ansp3_l2": "이를 통해 방문자 수를 인식하고 계산하고 방문자가 웹 사이트를 사용할 때 웹 사이트를 어떻게 이동하는지 확인할 수 있습니다. 쉽게.",
    "privacypolicyext_topic8ansp3_l3title": "기능성 쿠키.",
    "privacypolicyext_topic8ansp3_l3": "이는 귀하가 당사 웹 사이트로 돌아올 때 귀하를 인식하는 데 사용됩니다. 이를 통해 당사는 귀하를 위해 콘텐츠를 개인화하고, 이름으로 귀하를 맞이하고, 귀하의 선호도를 기억할 수 있습니다.",
    "privacypolicyext_topic8ansp3_l4title": "타겟팅 쿠키.",
    "privacypolicyext_topic8ansp3_l4": "이 쿠키는 귀하의 당사 웹 사이트 방문, 방문한 페이지 및 귀하가 팔로우 한 링크를 기록하며, 당사는이 정보를 사용하여 당사 웹 사이트 및 여기에 표시되는 광고가 귀하의 관심사와 더 관련이 있도록 할 것입니다. 또한이 목적을 위해이 정보를 제 3 자와 공유 할 수 있습니다.",
    "privacypolicyext_topic8ansp4": "당사는 여러 가지 목적으로 다양한 유형의 쿠키 (일부는 당사가 배치하고 제 3자가 당사 사이트에 배치 할 수 있도록 허용 한 쿠키 포함)를 사용합니다. 쿠키로 수집 된 정보를 통해 얼마나 많은 사람들이 당사 서비스를 사용하고 있으며 다른 사람들과의 상호 작용을 모니터링 할 수 있습니다. 서비스, 이메일을 여는 사람의 수, 이러한 조치가 취해지는 목적, 쿠키는 사용자의 로그인, 탐색 지원 및 세션 타이머를 촉진하고 미성년자의 도구 사용을 제한하는 데에도 사용됩니다. 당사는 쿠키의 비 개인 정보를 허가없이 개인 식별 정보에 연결하지 않습니다. 어떤 이유로 든 쿠키를 사용하여 당사 사이트 사용을 추적하는 것을 원하지 않는 경우 브라우저 설정을 사용하여이를 거부 할 수 있습니다. 또한 &ldquo;추적 금지&rdquo; 옵션을 선택할 수도 있습니다 (자세한 내용은 브라우저를 참조하십시오). 대부분의 브라우저는 도구 모음의 &ldquo;도움말&rdquo; 섹션에서 쿠키를 거부하도록 브라우저를 재설정하는 방법에 대한 지침을 제공합니다.",
    "privacypolicyext_topic8ansp5": "당사는 웹 비콘을 사용하여 서비스 사용에 대한 정보를 수집합니다. 웹 비콘이 수집 한 정보를 통해 당사 서비스를 사용하는 사람 수, 이메일을 여는 사람 수를 모니터링하고 이러한 조치가 수행되는 목적을 결정할 수 있습니다. . 당사의 웹 비콘은 당사 서비스 외부의 활동을 추적하는 데 사용되지 않습니다.당사는 웹 비콘을 사용하여 서비스 사용에 대한 정보를 수집합니다. 웹 비콘이 수집 한 정보를 통해 당사 서비스를 사용하는 사람 수, 이메일을 여는 사람 수를 모니터링하고 이러한 조치가 수행되는 목적을 결정할 수 있습니다. . 당사의 웹 비콘은 당사 서비스 외부의 활동을 추적하는 데 사용되지 않습니다.",
    "privacypolicyext_topic8ansp6_title": "내 쿠키 설정을 어떻게 변경합니까?",
    "privacypolicyext_topic8ansp6_1": "대부분의 웹 브라우저는 브라우저 설정을 통해 대부분의 쿠키를 제어 할 수 있습니다. 설정된 쿠키를 확인하는 방법을 포함하여 쿠키에 대해 자세히 알아 보려면 www.aboutcookies.org 또는 www.allaboutcookies.org를 방문하십시오.",
    "privacypolicyext_topic8ansp6_2": "인기있는 브라우저에서 쿠키를 관리하는 방법을 알아 보려면 :",
    "privacypolicyext_topic8ansp6_l1": "Google Chrome",
    "privacypolicyext_topic8ansp6_l2": "Microsoft Edge",
    "privacypolicyext_topic8ansp6_l3": "Mozilla Firefox",
    "privacypolicyext_topic8ansp6_l4": "Microsoft Internet Explorer",
    "privacypolicyext_topic8ansp6_l5": "Opera",
    "privacypolicyext_topic8ansp6_l6": "Apple Safari",

    "privacypolicyext_topic9ansp1": "WiseWorks에 가입하시면 귀하의 멤버십과 관련하여 이메일, 전화 또는 메시징 서비스 (귀하의 선호도에 따라 다름)를 통해 귀하와 연락 할 수 있습니다.",
    "privacypolicyext_topic9ansp1_l1": "조사 기회 (설문 조사, 여론 조사, 포커스 그룹 등)",
    "privacypolicyext_topic9ansp1_l2": "연구 결과",
    "privacypolicyext_topic9ansp1_l3": "패널리스트 뉴스 레터",
    "privacypolicyext_topic9ansp2": "우리의 프로젝트는 다양한 주제에 초점을 맞출 수 있으며 일반적으로 기회에 대해 이메일로 프로젝트에 참여하도록 초대합니다. 그 시점에서 설문 조사의 광범위한 주제, 가능한 기간 또는 필요한 참여, 제안에 대한 보상을 설명하고 참여 여부를 결정하는 것은 귀하에게 달려 있습니다. 참여는 필수 사항이 아닙니다. 일단 작업을 시작한 후 완료하는 것을 선호하고 일반적으로 완료되지 않은 작업에 대한 인센티브를 지급하지 않지만 언제든지 중퇴 할 수 있습니다.",
    "privacypolicyext_topic9ansp3": "우리는 귀하가 요청에 따라 항상 진실하고 정확하며 최신의 완전한 정보를 제공 할 것을 기대합니다.",


    "privacypolicyext_topic10ansp1": "법이 허용하는 경우에만 귀하의 개인 데이터를 사용합니다. 가장 일반적으로 당사는 다음과 같은 상황에서 귀하의 개인 데이터를 사용하지만 귀하의 데이터를 처리하는 데 사용할 합법적 근거 유형에 대한 자세한 내용을 보려면 여기를 클릭하십시오. :",
    "privacypolicyext_topic10ansp1_l1": "당신에게 우리의 제품과 서비스를 제공하십시오",
    "privacypolicyext_topic10ansp1_l2": "각 연구 기회에 가장 적합한 사람을 찾습니다.",
    "privacypolicyext_topic10ansp1_l3": "설문 조사에서 질문해야하는 질문의 수를 줄입니다.",
    "privacypolicyext_topic10ansp1_l4": "우리가 이미 수집 한 연구 데이터에 대한 추가 분석 수행",
    "privacypolicyext_topic10ansp1_l5": "고객에게 우리의 연구 능력을 설명하는 데 도움이되도록 집계 된 형태로.",
    "privacypolicyext_topic10ansp1_l6": "설문 조사, 포커스 그룹, 온라인 커뮤니티, 방문하는 웹 사이트의 수동 모니터링 등과 같은 연구 기회에 초대하십시오.",
    "privacypolicyext_topic10ansp1_l7": "당신이 얻은 인센티브 보상을 보내고,",
    "privacypolicyext_topic10ansp1_l8": "프로필 업데이트 요청,",
    "privacypolicyext_topic10ansp1_l9": "당사 제품 또는 서비스에 대한 설문 조사에 참여하도록 초대",
    "privacypolicyext_topic10ansp1_l10": "사기, 부적절한 활동을 모니터링, 감지 및 방지하고 WiseWorks의 정책 준수를 보장합니다.",
    "privacypolicyext_topic10ansp1_l11": "2 차 및 후속 연구를 포함한 연구 수행",
    "privacypolicyext_topic10ansp1_l12": "귀하와 다른 사용자가 당사의 온라인 서비스와 상호 작용하는 방식을 평가합니다.",
    "privacypolicyext_topic10ansp1_l13": "WiseWorks와 사용자 보호",
    "privacypolicyext_topic10ansp1_l14": "귀하와의 현재 상호 작용과 관련된 감사 수행",
    "privacypolicyext_topic10ansp1_l15": "사이트 및 서비스를 디버그하여 기존 기능을 손상시키는 오류를 식별하고 복구합니다.",
    "privacypolicyext_topic10ansp1_l16": "소송 및 조사에서 자신을 보호하고 소송을 기소합니다.",
    "privacypolicyext_topic10ansp1_l17": "법률 및 규제 의무를 준수합니다.",


    "privacypolicyext_topic11ansp1": "WiseWorks는 아래에 설명 된 상황에서만 귀하의 개인 정보를 제 3 자와 공유합니다. 우리는 금전적 또는 기타 귀중한 고려를 위해 수집 한 개인 정보를 판매하거나 공개하지 않습니다.",
    "privacypolicyext_topic11ansp1_l1title": "공인 공급 업체 :",
    "privacypolicyext_topic11ansp1_l1": "당사는 당사 웹 사이트 운영, 결제 처리, 서비스 제공을 지원하고 귀하의 지침 및 계약 의무를 준수하는 데 도움이되는 제 3 자 공급 업체와 귀하의 데이터를 공유 할 수 있습니다. 여기에는 결제 프로세서, 콘텐츠 전송 네트워크 (CDN), 클라우드 기반 호스팅 서비스, 모니터링 서비스, 이메일 서비스 제공 업체, 품질 보증 및 테스트 공급 업체, 사기 및 남용 방지 공급 업체, 고객 관계 관리 (CRM), 공급 업체가 포함됩니다.",
    "privacypolicyext_topic11ansp1_l2title": "광고 및 분석 파트너 :",
    "privacypolicyext_topic11ansp1_l2": "당사는 쿠키 및 기타 유사한 기술을 통해 사용 데이터를 제 3 자 광고주 및 분석 제공 업체와 공유 할 수 있습니다. 이러한 제 3자는 고유 식별자, 귀하의 IP 주소 또는 귀하의 컴퓨터 또는 장치에 대한 기타 정보와 같은 콘텐츠 요청에 대한 응답으로 귀하의 컴퓨터, 브라우저 또는 모바일 장치에서 전송 한 정보를 수집 할 수 있습니다.",
    "privacypolicyext_topic11ansp1_l3title": "비즈니스 파트너 :",
    "privacypolicyext_topic11ansp1_l3": "당사는 때때로 제 3 자와 파트너 관계를 맺어 이벤트, 프로모션, 콘테스트 및 기타 프로그램을 제공합니다. 이러한 제안에 참여하려면 이메일 주소와 추가 개인 정보를 제공해야 할 수 있습니다. 당사는 귀하가 명시 적으로 승인 한 경우에만 귀하의 이메일 주소 및 기타 개인 정보를 비즈니스 파트너와 공유합니다.",
    "privacypolicyext_topic11ansp1_l4title": "특정 법적 의무 :",
    "privacypolicyext_topic11ansp1_l4": "WiseWorks는 법원 또는 기타 정부 기관의 지시에 따라 개인 정보를 공개해야 할 수 있습니다. 앞서 언급 한 내용을 제한하지 않고, 당사는 해당 법률, 규정, 법원 명령, 정부 및 법 집행 기관의 요청을 준수하고, 보호하고, 보호하기 위해 그러한 조치가 필요하다고 믿는 선의의 근거가있는 경우 그러한 정보를 공개 할 권리를 보유합니다. WiseWorks 또는 제 3 자의 권리와 재산, 또는 WiseWorks, 사용자, 당사 직원 또는 타인의 안전을 보호하고, 당사 웹 사이트의 범죄 활동, 사기, 오용 또는 무단 사용을 방지, 탐지, 조사 및 조치하고 집행합니다. 당사의 이용 약관 또는 기타 계약 또는 정책은 법률이 허용하는 범위 내에서 그러한 요청에 대한 응답으로 귀하의 정보를 공개하기 전에 귀하에게 사전 통지를 제공하려고 시도 할 것입니다.",
    "privacypolicyext_topic11ansp1_l5title": "귀하의 동의하에 :",
    "privacypolicyext_topic11ansp1_l5": "WiseWorks는 귀하가 동의하거나 귀하의 지시에 따라 귀하의 개인 정보를 제 3 자에게 보낼 수 있습니다. 예를 들어, 귀하의 요청에 따라 거래를 완료 할 때 발생할 수 있습니다.",
    "privacypolicyext_topic11ansp1_l6title": "서비스 제공 업체 :",
    "privacypolicyext_topic11ansp1_l6": "당사는 당사를 대신하여 특정 서비스 및 고유 한 프로세스 정보를 당사에 제공하는 공급 업체, 컨설턴트, 결제 처리 업체 및 기타 서비스 제공 업체와 개인 정보를 공유 할 수 있습니다. 이러한 서비스에는 고객 지원 제공, 비즈니스 및 판매 분석 수행, 당사 웹 사이트 기능 지원, 결제 처리 촉진, 콘테스트 지원, 설문 조사 및 당사 웹 사이트에서 제공되는 기타 기능이 포함될 수 있습니다. 이러한 서비스 제공 업체는 다른 목적으로 귀하의 개인 정보를 사용할 수 없습니다. 이러한 개인 데이터의 사용에는 적절한 기밀 유지 및 보안 조치가 적용됩니다.",
    "privacypolicyext_topic11ansp1_l7title": "제휴사 :",
    "privacypolicyext_topic11ansp1_l7": "당사는 본 개인 정보 보호 정책의 조건에 따라 공동 통제 및 소유권하에있는 일부 파트너, 계열사 및 기타 회사와 개인 정보를 공유 할 수 있습니다. 또한 파산시 사업 매각, 매각, 합병, 통합 또는 자산 매각과 같은 실질적인 기업 거래와 관련하여 귀하의 개인 정보를 공유하거나 이러한 사건에 대한 준비를 할 수 있습니다. 당사를 매입하거나 당사 사업의 일부가되는 기타 법인은 귀하의 개인 정보를 계속 사용할 권리가 있지만 귀하가 달리 동의하지 않는 한 본 개인 정보 보호 정책에 명시된 방식으로 만 사용할 수 있습니다.",

    "privacypolicyext_topic12ansp1_title": "귀하의 정보 처리를위한 법적 근거 :",
    "privacypolicyext_topic12ansp1": "유럽 경제 지역, 영국 또는 스위스에 거주하는 개인 (통칭 &ldquo;EEA 거주자&rdquo;)의 경우 귀하로부터 수집 된 데이터가 미국에서 전송되거나 처리 될 수 있습니다 (통칭 &ldquo;데이터 주체&rdquo;). 데이터가 수집 될 때 당사는 귀하의 정보를 처리하기위한 법적 근거에 의존합니다. 당사는 일반적으로 귀하에게 서비스를 제공하기 위해 (또는 귀하와 계약을 체결하기 전에 귀하의 요청에 따라 조치를 취하기 위해) 처리가 필요한 경우에만 귀하의 데이터를 처리합니다.",
    "privacypolicyext_topic12ansp2_title": "빛을 비추다 (캘리포니아 시민 용) CCPA",
    "privacypolicyext_topic12ansp2_1": "캘리포니아의 &ldquo;빛을 비추는&rdquo; 법은 캘리포니아에 거주하는 고객이 특정 유형의 정보가 제 3 자 및 경우에 따라 해당 제 3 자 및 계열사의 직접 마케팅 목적을 위해 계열사와 공유되는 방법에 대한 구체적인 세부 정보를 요청할 수 있도록 허용합니다. 법률에 따라 기업은 캘리포니아 고객에게 요청시 특정 정보를 제공하거나 캘리포니아 고객이 이러한 유형의 공유를 거부 할 수 있도록 허용해야합니다.",
    "privacypolicyext_topic12ansp2_2": 'Shine the Light 요청을 실행하려면 당사에 문의하십시오. 요청 본문에 "Your California Privacy Rights"라는 문구와 이름, 주소, 도시, 주 및 우편 번호를 입력해야합니다. 당사는 전화, 이메일을 통한 문의를받지 않으며, 라벨이 제대로 표시되지 않았거나 제대로 발송되지 않았거나 완전한 정보가없는 통지에 대해 책임을지지 않습니다.',
    "privacypolicyext_topic12ansp3_title": "네바다 개인 정보 보호 권리",
    "privacypolicyext_topic12ansp3": '귀하가 네바다 거주자 인 경우 귀하는 해당 개인 데이터를 라이센스하거나 판매하려는 제 3 자에 대한 특정 개인 데이터의 판매를 거부 할 수있는 권리가 있습니다. 여기에서 당사에 연락하거나 해당 주제를 이메일로 보내이 권리를 행사할 수 있습니다. "Nevada Do Not Sell Request" 란에 귀하의 이름과 귀하의 계정과 관련된 이메일 주소를 제공합니다.',

    "privacypolicyext_topic13ansp1": "당사는 귀하가 귀하의 데이터에 대해 다양한 선택을 할 수 있도록합니다 : 다음은 귀하가 당사 서비스를 사용할 때 귀하가 갖는 데이터 보호 권리입니다.",
    "privacypolicyext_topic13ansp1_l1": "귀하는 특정 정보를 제공하지 않도록 선택할 수 있습니다.",
    "privacypolicyext_topic13ansp1_l2": "귀하가 귀하의 개인 정보에 대한 접근, 수정, 업데이트 또는 삭제를 요청하고자하는 경우, 고객 서비스 포털을 통해 또는 본 개인 정보 보호 정책 하단에 제공된 정보를 통해 언제든지 당사에 연락하여 그렇게 할 수 있습니다.",
    "privacypolicyext_topic13ansp1_l3": "귀하가 귀하의 데이터에 대한 액세스를 요청하는 경우, 당사는 귀하의 정보를 보유하고 있는지 여부를 확인하고 귀하에게 사본을 제공하여 당사가 정보를 처리하는 방법과 이유, 저장 기간 및 이와 관련된 귀하의 권리를 설명합니다.",
    "privacypolicyext_topic13ansp1_l4": "개인 정보의 삭제를 요청하시면 삭제되며, 법적 의무를 다하거나 법적 청구를 성립, 행사 또는 방어하기 위해 필요한 모든 정보를 보유해야합니다.",
    "privacypolicyext_topic13ansp1_l5": "귀하는 귀하의 개인 정보 처리에 이의를 제기하거나 당사에 귀하의 개인 정보 처리 제한을 요청하거나 개인 정보의 이동성을 요청할 수 있습니다. 다시 말하지만, 고객 서비스 포털을 통해 또는 본 개인 정보 보호 정책 하단에 제공된 정보를 통해 당사에 연락하여 이러한 권리를 행사할 수 있습니다.",
    "privacypolicyext_topic13ansp1_l6": '귀하는 당사가 귀하에게 보내는 이메일 마케팅 커뮤니케이션을 언제든지 거부 할 권리가 있습니다. 귀하는 당사가 귀하에게 보내는 마케팅 이메일에서 "수신 거부"또는 "수신 거부"링크를 클릭하여이 권리를 행사할 수 있습니다.',
    "privacypolicyext_topic13ansp1_l7": "당사의 메시지 수신 거부 : 향후 당사의 홍보 메시지를 수신 거부하려면 귀하에게 발송 된 홍보 메시지 본문 (이메일 하단에 있음)에서 수신을 거부하거나 당사에 수신 거부 요청을 보내야합니다. 접수 후 합리적인 시간 내에 귀하의 요청을 처리합니다.",
    "privacypolicyext_topic13ansp1_l8": "마찬가지로 당사가 귀하의 동의하에 귀하의 개인 정보를 수집 및 처리 한 경우 귀하는 언제든지 귀하의 동의를 철회 할 수 있습니다. 동의 철회는 철회 전에 수행 한 모든 처리의 적법성에 영향을 미치지 않으며 귀하의 처리에 영향을 미치지 않습니다. 동의 이외의 합법적 인 처리 근거에 의거하여 수행 된 개인 정보.",
    "privacypolicyext_topic13ansp1_l9": "귀하는 당사의 개인 정보 수집 및 사용에 대해 데이터 보호 기관에 불만을 제기 할 권리가 있습니다. 자세한 내용은 당사에 문의하시면 링크 또는 당사 데이터 보호 담당자의 주소를 보내드립니다.",
    "privacypolicyext_topic13ansp1_l10": "귀하의 요청을 당사에 제출하는 가장 쉬운 방법은 본 개인 정보 보호 정책 하단에있는 고객 지원 이메일을 이용하는 것입니다. 당사는 해당 데이터 보호법에 따라 데이터 보호 권리를 행사하고자하는 개인으로부터받은 모든 요청에 응답합니다.",

    "privacypolicyext_topic14ansp1": "웹 사이트와 서비스는 만 13 세 미만의 아동을 대상으로하지 않으며, 와이즈 웍스는 아동 온라인 개인 정보 보호법 (COPPA)을 준수합니다. 당사는 18 세 미만의 개인 정보 수집을 고의로 요청하지 않습니다. 당사 웹 사이트를 사용하는 18 세 미만의 개인을 알고있는 경우 당사의 서비스에 대한 액세스를 방지하기 위해 당사에 알려주십시오.",

    "privacypolicyext_topic15ansp1": "당사는 귀하의 데이터 보안을 유지하고 당사가 통제하는 데이터의 손실, 오용 및 변경을 방지하기위한 조치를 마련하기 위해 최선을 다하고 있습니다. 우리는 권한이없는 개인의 침입으로부터 시스템을 보호하기 위해 현대적이고 안전한 기술을 사용하며, 더 나은 방법을 사용할 수있게되면 정기적으로 보안을 업그레이드합니다. 데이터 센터와 파트너는 시설에 대한 무단 액세스를 방지하기 위해 새로운 물리적 보안 조치를 활용합니다.",
    "privacypolicyext_topic15ansp2": "완벽하거나 뚫을 수없는 보안 조치는 없습니다. 따라서 당사는 귀하의 개인 정보를 보호하기 위해 업계 표준 관행을 사용하지만 개인 데이터의 절대적인 보안을 보장 할 수 없으며 보장하지 않습니다.",


    "privacypolicyext_topic16ansp1": "당사의 이용 약관에 대해 자세히 알아 보려면 당사 웹 사이트 사용에 적용되는 사용, 면책 및 책임 제한을 설정하는 서비스 약관 페이지를 방문하십시오.",

    "privacypolicyext_topic17ansp1": "이 개인 정보 보호 정책과 서비스 약관 및 기타 관련 서비스는 미국 법률에 따릅니다.",

    "privacypolicyext_topic18ansp1": "WiseWorks는 귀하, 귀하의 개인 정보 및 WiseWorks 서비스 사용을 더 잘 보호하기 위해 새로운 기술과 프로세스를 구현하기 위해 지속적으로 노력하고 있습니다. 결과적으로 WiseWorks는이 개인 정보 보호 정책을 수시로 변경할 수 있습니다. 어떠한 경우에도 이러한 변경으로 인해 무단 공개로부터 귀하를 보호하도록 설계된 보안 조치가 저하되지 않습니다.",

    "privacypolicyext_topic19ansp1": "이 개인 정보 보호 정책에 대해 궁금한 점이 있으면 다음 주소로 문의하십시오. ",




  "faq_faq" : "자주 묻는 질문",
  "faq_getans" : "모든 질문에 대한 답변을 얻으십시오.",

  "faq_ques1" : "WisePerks 란 무엇입니까?",
  "faq_ans1" : "우리는 당신이 기쁘게 물었다.WisePerks는 온라인 설문 조사 사이트입니다.많은 브랜드와 회사가 응답자가 자신의 의견을 제공하고 보답으로 보상을 받기를 바랍니다.WisePerks는 의견을 제시하고 그에 대한 보상도 얻을 수있는 플랫폼입니다.",

  "faq_ques2" : "가입하는 것이 무료입니까?",
  "faq_ans2" : "네, 가입은 무료입니다.사실, 누군가가 귀하에게 가입비를 요청할 경우, 우리는 귀하가 그것을 우리에게보고하기를 원할 것입니다.",

  "faq_ques3" : "내 친구들을 추천 할 수 있을까요?",
  "faq_ans3" : '예, 친구 및 동료를 초대하고 그에 대한 보상을받을 수 있습니다. 모든 세부 사항을 확인하려면 대시 보드에서 "친구를 추천하십시오"링크를 클릭하십시오.',

  "faq_ques4" : "나는 무엇을 얻는가?",
  "faq_ans4" : "지금 우리의 새로운 모델에서, 당신은 단지 설문 조사를 복용 하 여 이외의 여러 가지 방법으로 포인트를 적립하실 수 있습니다. 당신은 포인트를 얻을: 우리와 함께 등록, 귀하의 이메일 계정을 확인 하 여, 프로필을 완료 하기 위한, 설문 조사를 완료 하기 위한 물론 심지어 실격 설문 조사에 대 한 귀하의 깜짝. 그래서 우리는 당신이 시작 하는 모든 이유가 그렇지 않나요?",

  "faq_ques5" : "WisePerks.com 회원이 되려면 어떻게해야합니까?",
  "faq_ans5" : '브라우저에서 WisePerks.com을여십시오."가입"탭을 클릭하여 회원 로그인을 만드십시오.단계를 따르기 만하면 나머지 과정을 안내해줍니다.단계를 따르기 만하면 나머지 과정을 안내해줍니다.',

  "faq_ques6" : "귀하가 언급 한 확인 이메일은 어디에 있습니까?",
  "faq_ans6p1" : "가입 시 제공 하는 이메일 주소로 확인 이메일이 자동으로 전송 됩니다. 받은 편지함에 이메일이 표시 되지 않으면 스팸 또는 정크 폴더를 확인 하세요. 귀하의 스팸 폴더에 도달에서 미국 및 설문 조사 초대/메시지에서 미래의 이메일을 피하기 위해, 추가 하십시오",
  "faq_ans6p2" : "승인 된 보낸 사람 목록 또는 사서함의 연락처로 그래도 확인 전자 메일을 찾을 수 없는 경우 내 계정에 로그인 하 여 이메일 주소가 올바른지 확인 하 고 다른 확인 이메일을 요청 하십시오.",

  "faq_ques7" : "설문 조사는 어떻게합니까?",
  "faq_ans7" : '사용자 이름과 비밀 번호를 입력 한 후 로그인 하면 대시보드에 도달 하 고 취할 수 있는 모든 설문 조사를 볼 수 있습니다. 또한 귀하의 등록 된 이메일 id로 설문 조사 링크를 보내드립니다. 당신은 당신의 우체통에서 연결을 직접가지고 갈 수 있다.',

  "faq_ques8" : "내 데이터가 얼마나 기밀입니까?",
  "faq_ans8" : "WisePerks의 경우 사용자 개인 정보 및 기밀 유지가 최우선 적입니다.우리의 고객은 당신이 생각하는 것, 소비자, 또는 당신이 무언가에 대해 어떻게 느끼는지에 대해서만 관심이 있습니다.우리는 WisePerks에서 어떠한 개인 정보도 고객과 공유 / 전달하지 않으며 WisePerks는 제 3 자에게 정보를 판매하거나 대여하지 않습니다.",

  "faq_ques9" : "이 조사는 언제까지 얼마나 걸립니까?",
  "faq_ans9" : "대부분의 설문 조사는 약 10-15 분 정도 걸립니다.그러나 수시로 더 긴 설문 조사가있을 수 있습니다.각 설문 조사에서 우리는 얼마나 오래 될지 미리 알려주지 만 장시간의 설문 조사는 더 많은 보상을받습니다.",

  "faq_ques10" : "누구든지 WisePerks 조사를 할 수 있습니까?",
  "faq_ans10" : "WisePerks에 등록하려면 13 세 이상이어야합니다.때로는 젊은 가족 구성원과 함께 설문 조사에 초대합니다.",

  "faq_ques11" : "설문 조사를받을 자격이 있다는 것을 어떻게 알 수 있습니까?",
  "faq_ans11" : "Google의 알고리즘은 가장 적절한 설문 조사를받을 수 있도록 설문 조사와 프로필 정보를 대조합니다.설문 조사가 시작되면 대개 처음 몇 가지 질문이 귀하가 고객이 특정 설문 조사를 찾고있는 응답자인지 여부를 결정합니다.Google은 다양한 유형의 사람들 (예 : 정기적으로 영화를보고 특정 스포츠를 즐기며 아이들이있는 여성 등)을 찾고 광범위한 설문 조사를 진행하고 있으며 매일 새로운 설문 조사가 진행됩니다.",

  "faq_ques12" : "WisePerks에 대한 나의 의무는 무엇입니까?",
  "faq_ans12" : "우리가 기대하는 유일한 것은 고객이 자신의 제품과 서비스를 개선하기위한 정직하고 진정한 답변을 찾고 있기 때문에 고객의 의견과 생각을 정직하게 제공하는 것입니다.회원 가입시 정확한 정보를 제공하고 변경 사항이있을 경우이를 업데이트하십시오.",

  "faq_ques13" : "구입 한 가구, 기술 또는 제품에 대한 정보를 왜 제공해야합니까?",
  "faq_ans13" : "각 설문 조사마다 특정 프로필의 응답자를 찾고 있습니다.귀하의 프로필 정보에서 귀하의 정보가 고객의 요구 사항과 일치한다는 것을 알고 있다면 빙고!설문 조사를 성공적으로 완료하고 그에 대한 보상을 얻을 수 있다는 것을 거의 확실히 확신 할 수 있습니다.이것은 기본적으로 설문 조사를 위해 선정 될 확률을 높입니다.",

  "faq_ques14" : "WisePerks 설문 조사를 마친 후 언제 기프트 카드를받을 수 있습니까?",
  "faq_ans14" : "성공적으로 설문 조사를 완료 한 후 적립 되는 포인트는 버킷에 누적 됩니다. 일단 이러한 포인트를 사용하실 수 있습니다 깨달았다.",

  "faq_ques15" : "내 계정은 실제로 무엇입니까?",
  "faq_ans15" : "이는 귀하가 귀하의 개인 정보를보고, 필요에 따라 변경하고, 설문 조사 활동, 획득 한 보상 및 모든 것을 한 곳에서 볼 수있는 쉬운 방법 일뿐입니다.",

  "faq_ques16" : "내 계정을 취소하려면 어떻게해야합니까?",
  "faq_ans16" : "너무 많은 방법으로 오고, 우리는 당신이이 일을 하 고 싶지 않을 것 이라고 확신 합니다. 그러나, 당신의 대시 보드에-> 설정으로 이동--> 계정 관리-> 계정 비활성화",

  "faq_ques17" : "포인트 시스템은 어떻게 작동합니까?",
  "faq_ans17p1" : "각 조사는 특정 수의 점수에 대한 것입니다. 설문 조사를 성공적으로 완료하면 이러한 포인트가 귀하의 수입에 추가됩니다. 최소 임계 값에 도달하면 사용할 수 있습니다.",
  "faq_ans17p2" : "연결 또는 고장과 관련하여 예상치 못한 문제로 인해 고객이 응답하지 않을 수 있습니다. 그런 다음 귀하의 계정에서 해당 포인트를 취소해야합니다. 해당 포인트를 이미 사용하고 계정 잔액이 부족하면 수입이 마이너스가됩니다. 이 조정은 다음 교환주기에서 이루어집니다.",

  "faq_ques18" : "설문 조사 포인트는 언제 내 포인트 잔액에 추가됩니까?",
  "faq_ans18" : "귀하의 포인트는 48 시간 이내에 포인트 잔액에 추가됩니다.",

  "faq_ques19" : "상환을위한 최소 기준 액은 얼마입니까?",
  "faq_ans19" : "사용 기준은 최소 25000 점입니다.평균적으로, 귀하는 구제 대상이되기 위해 8-12 개의 설문 조사를 완료해야합니다.",

  "faq_ques20" : "포인트는 기프트 카드로 어떻게 전환됩니까? 비율이 있습니까?",
  "faq_ans20" : "예. 귀하의 국가에 따라 포인트는 기프트 카드로 전환됩니다.전환율은 다음과 같습니다.",
  "faq_ponits" : "전철기",
  "faq_point" : "포인트",
  "faq_cent" : "센트",

  "faq_ques21" : "더 많은 질문이 있으십니까?",
  "faq_ans21p1" : "저희에 게 이메일을 보내 주시기 바랍니다",
  "faq_ans21p2" : "그리고 우리는 48 시간 이내에 다시 얻을 것 이다.",

  "Profiling_profiledashboard" : "프로필 대시보드", 
  "Profiling_completeprofile" : "귀하의 프로필을 완료 하 고 무려 얻을",
  "Profiling_completepoints" : "포인트!",
  "Profiling_answercarefully" : "다음의 일반적인 질문은 귀하의 개인 프로필을 작성 하는 데 사용 되어 최상의 미래 설문 조사 기회를 제공 합니다. 제발, 신중 하 고 진실 답변 시간을 가져가 라.",

  "Profiling_cat_general" : "일반",
  "Profiling_cat_personal" : "개인",
  "Profiling_cat_household" : "가정",
  "Profiling_cat_profession" : "직업",

  "Profiling_bar_completed" : "완료율",
  
  "Profiling_update_ans_button" : "업데이트 및 계속",
  "Profiling_save_ans_button" : "저장",
  "Profiling_sc_ans_button" : "저장 & 계속",

  "Category_thanku_msg1" : "좋은 거 야",
  "Category_thanku_msg2" : "몇 가지 더 그리고 당신은 당신의 계정에",
  "Category_thanku_msg3" : "포인트를 해야 합니다",

  "Category_thanku_update_msg1" : "귀하의 정보를 업데이트 주셔서 감사 합니다!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
  "Category_Continue_button" : "계속",

  "OverAll_thanku_msg1" : "귀하의 정보를 공유 주셔서 감사 합니다. 당신은 적립",
  "OverAll_thanku_msg2" : "포인트.",

  "Survey_history_surveyhistory" : "설문 조사 기록",
  "Survey_history_fromthisscreen" : "이 화면에서 모든 거래를 볼 수 있습니다.",
  "Survey_history_show" : "표시",
  "Survey_history_entries" : "항목",
  "Survey_history_date" : "날짜",
  "Survey_history_details" : "세부 정보",
  "Survey_history_surveys" : "설문 조사",
  "Survey_history_pointsreceived" : "받은 포인트",
  "Survey_history_result" : "결과",
  "Survey_history_status" : "상태",
  "Survey_history_status_complete" : "완료", 
  "Survey_history_status_disqualified" : "실격", 
  "Survey_history_keyword" : "키워드",
  "Survey_history_search" : "검색",
  "Survey_history_to" : "받는 사람",
  "Survey_history_selectoption" : "선택 옵션",
  "Survey_history_earned" : "적립",
  "Survey_history_redeemed" : "구속",
  "Survey_history_norecord" : "레코드를 찾을 수 없음",
  "Survey_history_Surveypts" : "측량 점",
  "Survey_history_Promocode" : "프로 모션 코드",

  "My_transactions_mytransactions" : "내 거래",
  "My_transactions_fromthisscreen" : "이 화면에서 모든 거래를 볼 수 있습니다.",
  "My_transactions_show" : "표시",
  "My_transactions_entries" : "항목",
  "My_transactions_transactionid" : "트랜잭션 Id",
  "My_transactions_date" : "날짜",
  "My_transactions_details" : "세부 정보",
  "My_transactions_points" : "포인트",
  "My_transactions_status" : "상태",
  "My_transactions_keyword" : "키워드",
  "My_transactions_search" : "검색",
  "My_transactions_to" : "받는 사람",
  "My_transactions_selectoption" : "선택 옵션",
  "My_transactions_status_earned" : "적립",
  "My_transactions_status_redeemed" : "구속",
  "My_transactions_status_pending" : "보류 중인",
  "My_transactions_status_reverted" : "되돌릴",
  "My_transactions_status_declined" : "거부",
  "My_transactions_norecord" : "레코드를 찾을 수 없음",
  "My_transactions_off_msg1" : "후 ",
  "My_transactions_off_msg2" : ", 모든 거래 세부 사항은 포인트 북 탭에 게시됩니다",

  "Passbook_heading" : "포인트 북",
  "Passbook_show" : "보여 주다",
  "Passbook_entries" : "출품작",
  "Passbook_search" : "검색",
  "Passbook_type" : "유형",
  "Passbook_surveyid" : "설문 조사",
  "Passbook_date" : "데이트",
  "Passbook_selectoption" : "옵션 선택",
  "Passbook_credit" : "신용",
  "Passbook_debit" : "직불",
  "Passbook_survey_id" : "설문 조사 ID",
  "Passbook_survey_pts" : "설문 포인트",
  "Passbook_earned_pts" : "포인트들",
  "Passbook_pending_pts" : "보류 포인트",
  "Passbook_promo_code" : "프로모션 코드",
  "Passbook_transaction_type" : "거래 유형",
  "Passbook_balance" : "균형",
  "Passbook_status" : "지위",
  "Passbook_type_soi" : "기재",
  "Passbook_type_doi" : "이메일 확인",
  "Passbook_type_profile" : "프로필 완성",
  "Passbook_type_survey" : "서베이",
  "Passbook_type_doir" : "이메일 확인자",
  "Passbook_type_doirt" : "추천에 의한 이메일 확인",
  "Passbook_type_surveyr" : "설문 조사 시도",
  "Passbook_type_surveyrt" : "추천에 의한 설문 조사 시도",
  "Passbook_type_reward" : "보상",
  "Passbook_type_pending" : "조정",
  "Passbook_type_reverted" : "되 돌린 포인트",
  "Passbook_type_declined" : "거부 된 요청",
  "Passbook_type_redeem" : "도로 사다",
  "Passbook_type_balance" : "이월하다",
  "Passbook_balance_vary" : "실제 잔액은 다를 수 있습니다",
  "Passbook_survey_debit_reason" : "우리는 당신의 노력을 소중하게 생각합니다. 그러나 데이터 전송 중에 제어 할 수없는 연결 끊김으로 인해 응답을 등록 할 수 없습니다. 이 설문 조사에서 이전에받은 포인트를 취소해야합니다. 불편을 드려 죄송합니다.",

  "Redeem_redeemed" : "사용",
  "Redeem_redeemhistory" : "사용 기록",
  "Redeem_history_norecord" : "레코드를 찾을 수 없음",
  "Redeem_date" : "날짜",
  "Redeem_details" : "세부 정보",
  "Redeem_points" : "포인트",
  "Redeem_status" : "상태",
  "Redeem_lookslike" : "귀하의 이메일 계정을 확인 놓친 것 같은 데.",
  "Redeem_hurry" : "서둘러! 확인 한 후 1000 포인트를 얻을.",
  "Redeem_pleasecheck" : "받은 편지함을 확인 하 고 계정 활성화를 위해 전송 된 확인 이메일에 있는 링크를 클릭 하십시오.",
  "Redeem_kindlycheck" : "확인 이메일 뿐만 아니라 스팸/정크 폴더를 확인 하시기 바랍니다.",
  "Redeem_getconfirmationemail" : "아직 확인 이메일이 도착 하지 않았나요?",
  "Redeem_resendconfirmationemail" : "확인 이메일 재전송",
  "Redeem_rewardredemptionrule" : "보상 보너스 규칙",
  "Redeem_completesurvey" : "전체 설문 조사",
  "Redeem_earnpoints" : "포인트 적립",
  "Redeem_redeemrewards" : "보상 신청",
  "Redeem_note" : "참고:",
  "Redeem_yourearnedpoints" : "적립 된 포인트는 설문 조사를 한 정하는 후 상환 될 것입니다. 보상을 사용 하려면 계정에서 최소 상환 포인트가 있어야 합니다.",
  "Redeem__points" : "포인트.",
  "Redeem_availablebalance" : "사용 가능한 잔액:",
  "Redeem_historydetails" : "교환 기록 세부 정보를 보려면 여기를 클릭 하십시오.", 
  "Redeem_reqsucc" : "축! 귀하의 포인트가 구속 되었습니다. 자세한 내용은 등록 된 사서함을 확인 하십시오.", 
  "Redeem_wisecheck_youhave" : "축하해! 보상을 클릭 한 번으로 클릭하면됩니다.",
  "Redeem_wisecheck_gcagainst" : "선물 카드",
  "Redeem_wisecheck_points" : "포인트.",
  "Redeem_wisecheck_issue" : "시스템에서 계정에 인식 되지 않는 활동을 캡처 했으며,이로 인해 상환 요청을 보낼 수 없습니다. 에 문의 하십시오",
  "Redeem_emailverify_issue" : "포인트를 사용하려면 등록 된 이메일 계정을 확인해야합니다.",

  "Redeem_unsubscribe_issue" : "WisePerks에서 탈퇴 했으므로 사용 신청서를 보낼 수 없습니다. 다시 구독하려면 support@wiseperks.com에 문의하십시오.",
  "Redeem_wisecheck_message1" : "보안상의 이유로 인해 사용할 수 없습니다. 지원팀에 문의하십시오.",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "확인",
  "Redeem_Cancel_button" : "취소",
  "Redeem_pending_hello" : "Hello",
  "Redeem_pending" : "조정",
  "Redeem_pending_msg1" : "귀하의 계정은",
  "Redeem_pending_msg2" : "수행 할 포인트. 거래 시작을 확인하십시오.",

  "Dashboard_wisecheck_issue" : "시스템에서 계정에서 알 수없는 활동을 캡처했습니다. 이로 인해 설문 조사를 볼 수 없기 때문입니다. 자세한 설명은 support@wiseperks.com에 문의하십시오.",
  "Dashboard_unsubscribe_issue" : "WisePerks의 구독을 취소 했으므로 설문 조사를 볼 수 없습니다. 설문 조사를 다시 받으려면 support@wiseperks.com에 문의하십시오.",


  "WC_DashboardMsg_1" : "죄송합니다. 현재 귀하의 프로필과 일치하는 설문 조사가 없습니다.",
  "WC_DashboardSubMsg_1" : "걱정하지 마십시오. 인벤토리를 뒤섞고 곧 새로운 설문 조사를 보내 드리겠습니다.",

  "WC_DashboardMsg_2" : "대시 보드를 새로 고칩니다. 언젠가 새로운 조사가 있는지 확인하십시오.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "설문 조사를 완료 해 주셔서 감사 합니다. 방금 response_points 포인트를 획득 했습니다. 총 누적 포인트를 보려면 대시보드에 로그인 하십시오.",
  "Survey_success_msg2" : "설문 조사를 완료 해 주셔서 감사 합니다. 방금 얻은 response_points 포인트.",

  "Survey_success_but_nomore_survey" : "축! 방금 얻은 response_points 설문 조사 완료를 위한 포인트. 언젠가 후에 예정 된 설문 조사를 확인 하십시오.",
  
  "Survey_terminate_pnt_msg1" : "귀하의 정보 중 일부는 요구 사항과 약간 다를 것 같습니다. 우리는 당신의 시간과 노력을 가치, 당신의 계정에 response_points 포인트를 추가 할 것입니다. 우리는 당신에 게 더 수익성이 직관적 인 설문 조사를 가져다에 초점을 맞추고 있습니다.",
  
  "Survey_terminate_nopnt_msg1" : "약간의 응답이 요구 사항과 약간 다른 것처럼 보입니다. 우리는 당신의 방식으로 더 많은 조사를 보낼 것입니다. 시간과 노력에 감사드립니다.",

  "TermsConditions_t&c" : "약관 조건",
  "TermsConditions_WhenRegister" : "등록 할 때",
  "TermsConditions_WhenYouCreate" : "개인 계정을 만들 때 여기에 언급 된 개인 정보가 필요합니다.",
  "TermsConditions_YouCanUpdate" : "기본 프로필 정보는 언제든지 업데이트하거나 변경할 수 있습니다.",
  "TermsConditions_YouCanOptOut" : "언제든지 계정을 선택 해제하거나 닫을 수 있습니다.",
  "TermsConditions_WeRespectYour" : "우리는 귀하의 프라이버시를 존중하며, 인증되지 않은 물리적 및 전자적 접근으로 귀하의 정보를 안전하게 지키는 기술을 최대한 활용합니다.",
  "TermsConditions_WeShareYour" : "우리는 WiseWorks를 운영해야하는 조직과 만 귀하의 정보를 공유합니다. 그리고 이러한 조직은 귀하의 개인 정보와 보안에 대한 우리의 약속을 지켜야합니다.",
  "TermsConditions_WhatInformation" : "우리가 수집하는 귀하의 정보",
  "TermsConditions_DirectlyCollected" : "직접 수집",
  "TermsConditions_EmailAddressAndPassword" : "귀하의 계정에 대한 이메일 주소와 비밀번호가 필요합니다.",
  "TermsConditions_YourResponse" : "프로파일 링 질문에 대한 귀하의 답변.",
  "TermsConditions_AnyCustomer" : "모든 고객 서비스 서신.",
  "TermsConditions_AnyPersonalDetails" : "구속을 위해 공유하는 모든 개인 정보",
  "TermsConditions_AnyOtherInformation" : "귀하가 직접 제공 한 기타 정보.",
  "TermsConditions_UsageData" : "사용 데이터",
  "TermsConditions_YourIPAddress" : "귀하의 IP 주소, 지역 위치 및 쿠키",
  "TermsConditions_InformationAboutTheDevice" : "사용하는 기기에 대한 정보",
  "TermsConditions_YourSurveyActivity" : "설문 조사 활동, 귀하의 계정을 가리키며, 사용 후 지불하십시오.",
  "TermsConditions_HowDoWeUse" : "데이터를 어떻게 사용합니까?",
  "TermsConditions_SendingYouInvitation" : "귀하가받을 수있는 설문 조사에 참여하도록 초대 이메일을 발송합니다.",
  "TermsConditions_DetectAndPrevent" : "사기 행위를 탐지하고 방지합니다.",
  "TermsConditions_ImproveYourExperience" : "WiseWorks 플랫폼에 대한 경험을 향상 시키십시오.",
  "TermsConditions_YourResponseAreTransferred" : "귀하의 응답은 합병되거나 탈퇴 된 형식으로 당사의 제 3 자 및 파트너에게 이전됩니다.",
  "TermsConditions_OurPartnersCanAsk" : "Google 파트너는 설문 조사 중에 귀하의 이름, 이메일 주소 및 기타 연락처 정보를 제공하도록 요청할 수 있습니다.",
  "TermsConditions_YouAreFreeToDecline" : "WiseWorks가 아니라 직접이 정보를 제공하는 것에 동의하는 경우 귀하는 파트너에게 세부 정보를 제공하는 것을 거절 할 수 있습니다.",
  "TermsConditions_YourInformationWillBe" : "귀하의 정보는 WiseWorks의 개인 정보 보호 정책이 아닌 관련 비즈니스 개인 정보 보호 정책에 따라 처리됩니다.",
  "TermsConditions_OurPartnersAreCommited" : "우리의 파트너는 귀하의 개인 정보를 존중하고 보호하기 위해 최선을 다합니다.",
  "TermsConditions_WhatWeDont" : "우리가 그걸로하지 않는 것",
  "TermsConditions_WeDontSellOnThat" : "우리는 누구에게도 그 정보를 팔지 않으며, 우리는 그 정보를 당신에게 팔지 않습니다. 우리는 귀하의 데이터를 공유하기 전에 항상 허가를 요청할 것입니다.",
  "TermsConditions_YourRightToControl" : "데이터를 관리 할 수있는 권리",
  "TermsConditions_YouHaveFullControl" : "데이터를 완벽하게 제어 할 수 있습니다.",
  "TermsConditions_YouCanAccess" : "언제든지 기본 프로파일 정보에 액세스, 갱신 또는 변경할 수 있습니다.",
  "TermsConditions_YouCanUnsubscribe" : "계정 탈퇴 및 비활성화가 가능합니다.",
  "TermsConditions_YouAreFreeToGetYour" : "귀하는 support@wiseperks.com으로 메일을 보내 귀하의 프로필을 삭제할 수 있습니다. 우리는 귀하의 우려를 해소하려고 노력할 것이며 귀하의 계정을 삭제하고자하는 경우, 72 시간 이내에 그것을해야합니다.",
  "TermsConditions_DataSecurity" : "데이터 보안",
  "TermsConditions_OurTechTeamWorks" : "Google의 기술 팀은 모든 데이터를 안전하게 유지하기 위해 열심히 노력합니다.",
  "TermsConditions_WeMakeUseOfLatest" : "우리는 최신 기술과 조치를 사용하여 데이터의 오용 또는 무단 액세스를 방지합니다. 그러나 온라인 환경의 특성상 데이터 보안을 100 % 보장 할 수는 없습니다.",
  "TermsConditions_WeTryOurBestToRule" : "우리는 위험을 배제하기 위해 최선을 다하지만 위험이없는 환경을 보장 할 수는 없습니다. 본 이용 약관에 동의함으로써 귀하는 이러한 위험을 인정하고 수락합니다.",
  "TermsConditions_AnyQuestions" : "궁금한 점이 있으시면 언제든지 문의 해주십시오.",
  "TermsConditions_IfYouHaveAnyQueries" : "개인 정보 보호 정책 및 절차에 대한 문의 나 불만 사항이있는 경우 support@wiseperks.com으로 이메일을 보내 주시면 48 시간 이내에 연락 드리겠습니다.",

  "Validation_PleaseEnterProperDate" : "생년월일을 입력하십시오.",
  "Validation_PleaseSelectOne" : "하나의 옵션을 선택하십시오!",
  "Validation_PleaseSelectValidOptions" : "유효한 옵션을 선택하십시오!",
  "Validation_PleaseSelectAtLeaseOneOption" : "적어도 하나의 옵션을 선택하십시오.",
  "Validation_PleaseInsertTheZip" : "우편 번호를 입력하십시오.",

  "Releases_releases" : "릴리스",
  "Releases_theUseOfThis" : "본 웹사이트의 사용은 다음의 이용 약관의 적용을 받습니다.",
  "Releases_featuresOfWP" : "WP 릴리스",
  "Releases_updatedOn" : "의 특징 업데이트 날짜",
  "Releases_WisePerksNow" : "wiseperks은 이제 여러 언어를 지원 합니다",
  "Releases_youCanWalkThourgh" : "당신은 훨씬 더 쉽게 지금 사이트를 산책 할 수 있는 wiseperks은 귀하의 사전 입력 된 언어에서 사용할 수 있습니다.",
  "Releases_languagesAvailable" : "사용 가능한 언어:",
  "Releases_EarnMore" : "새로운 포인트 시스템으로 더 많은 수익을 얻으세요",
  "Releases_theNewWisePerksAccumulates" : "새로운 wiseperks은 귀하의 계정으로 포인트를 누적 합니다. 이 포인트는 훨씬 더 높은 가치를가지고 있으며, 적립 및 사용이 용이 합니다. 다양 한 보너스 옵션을 선택 하 여 설문 조사를 즐길 수 있습니다.",
  "Releases_accumulatePoints" : "자유 사용을 통한 포인트 누적",
  "Releases_youGetTheBenefit" : "당신은 같은 것을 적립의 혜택을 받을 수 없습니다. 사용 하려는 통화와 관계 없이 포인트를 사용할 수 있습니다.",
  "Releases_surveyDisqualification" : "설문 조사 자격 박탈은 더 이상 나쁜 소식이 아닙니다",
  "Releases_youEarnPoints" : "당신은 당신이 설문 조사에 대 한 자격이 없는 경우에도 포인트를 적립, 당신은 응답 시작.",
  "Releases_newAndImproved" : "새롭고 향상 된 레이아웃",
  "Releases_theFreshLookOf" : "wiseperks의 신선한 모습을 당신이 그것으로 사랑에 빠지게 만들 것입니다. 이제 훨씬 사용 하기 쉽고 당신에 게 좋은 경험이 될 것입니다.",
  "Releases_whatAvatarAreYouToday" : "오늘 어떤 아바타가",
  "Releases_youCanNowCreate" : "이제 대시보드에서 기능에 나만의 아바타를 만들 수 있습니다.",
  "Releases_freshAndHotSurveys" : "대시보드에서 신선 하 고 뜨거운 설문 조사",
  "Releases_youDontHaveTo" : "새 설문 조사를 보기 위해 대시보드를 다시 구성 하는 것에 대해 걱정할 필요가 없으며, 대시보드를 최신 설문 조사로 자동으로 채울 것입니다.",
  "Releases_promoCode" : "프로모션 코드 기능",
  "Releases_promoCodeInfo" : "대중적인 요구에 따라 WisePerks는 매주 패널리스트에게 프로모션 코드를 제공합니다. 더 많은 보상을 얻는 빠른 방법.",
  "Releases_referFriend" : "추천 친구",
  "Releases_referFriendInfo" : "친구들이 WisePerks와 연결되어 흥미 진진한 보상을받습니다. 귀하의 계정에서 친구 추천 옵션을 찾으십시오.",
	
  "Dashboard_Promocode_Usepromo" : "프로 모션 코드 사용",
  "Dashboard_Promocode_to" : "받는 사람",
  "Dashboard_Promocode_earnminimum_1" : "최소 적립",
  "Dashboard_Promocode_earnminimum_2" : "추가 포인트",
  "Dashboard_Promocode_minimum" : "최저한의",
  "Dashboard_Promocode_minimumextrapoints" : "오늘 완료된 모든 설문 조사에서 추가 포인트",
  "Dashboard_Promocode_apply" : "적용",
  "Dashboard_Promocode_message1" : "프로모션 코드가 유효하지 않습니다!",
  "Dashboard_Promocode_message2" : "축하합니다! 당신은 최소한을 얻을 것이다",
  "Dashboard_Promocode_message3" : "오늘 완료된 각 설문 조사에 추가!",

  "Dashboard_modal_Enjoythefestive" : "오늘 완료 한 모든 설문 조사에 최소",
  "Dashboard_modal_Extrapointson" : "의 추가 포인트를 적립 하 여 축제 정신을 즐기십시오.",

  "refer_friend_title" : "친구를 추천하고 보상 받기",
  "refer_friend_step_first": "WisePerks에 친구 초대하기",
  "refer_friend_step_second": "친구가 WisePerks에 성공적으로 가입했습니다.",
  "refer_friend_step_third": "너는 each_referral 점을 얻는다.",
  "refer_friend_link_title": "지금 친구를 초대하십시오!",
  "refer_friend_share_title": "링크 공유",
  "refer_friend_guideline_title": '어떻게 "친구를 추천"하면 도움이 될까요?',
  "refer_friend_guideline_one": "위에 제공된 링크를 귀하의 추천과 공유하십시오.",
  "refer_friend_guideline_two": "당신이 원하는만큼 많은 사람들을 추천 할 수 있습니다. 귀하의 추천자 중 5 명이 자신의 계정을 확인하고 설문 활동을 시작하면 획득 한 추천 포인트가 귀하의 계정에 추가됩니다.",
  "refer_friend_guideline_three": "인증 된 이메일 계정 및 설문 활동을 통해 성공적으로 추천받을 때마다 each_referral 포인트 씩을받습니다.",
  "refer_friend_guideline_four": "포인트는 2 단계로 이메일 확인시 partial_referral 점, 추천에 의한 설문 활동 partial_second_referral 점으로 계산됩니다.",
  "refer_friend_guideline_five": "확인 된 이메일 계정 및 설문 활동으로 5 회 성공한 후 추천 링크가 사용 중지됩니다.",

  }
  
