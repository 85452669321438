// Polish
export const TRANSLATIONS_PL = {
  "home_header_selectlang": "Wybierz język",
  "home_header_loginbutton": "Zaloguj Się",
  "home_header_signupbutton": "Zapisz się",
  "home_header_homebutton": "Dom",
  
  "home_register_bannertxt1": "Tutaj twoje",
  "home_register_bannertxt2": "myśli",
  "home_register_bannertxt3": "może cię sprowadzić",
  "home_register_bannertxt4": "nagroda$",
  "home_register_bannertxt5": "Uzyskaj premię",
  "home_register_bannertxt6": "Przy rejestracji",
  "home_register_fbsignup": "ZAREJESTRUJ SIĘ Z FACEBOOK",
  "home_register_fblogin": "ZALOGUJ SIĘ PRZEZ FACEBOOK",
  "home_register_or": "LUB",
  "home_register_emailsignup": "Zarejestruj się, używając emaila",
  "home_register_emaillogin": "Zaloguj się przez e-mail",
  "home_register_fpass": "Zapomniałeś hasła?",
  "home_register_fname": "Imię",
  "home_register_lname": "Nazwisko",
  "home_register_email": "Adres e-mail",
  "home_register_pass": "Hasło",
  "home_register_cpass": "Potwierdź hasło",
  "home_register_tcpp": "Przystępując, wyrażasz zgodę na naszą",
  "home_register_tcpptandcond": "Zasady i Warunki",
  "home_register_tcppand": "i",
  "home_register_tcppprivacy": "Polityka prywatności",
  "home_register_join": "Dołącz do nas. Jest wolne",
  "home_register_login": "Zaloguj Się",
  "home_register_name_required" : "Imie jest wymagane.",
  "home_register_email_required" : "Email jest wymagany.",
  "home_register_invalid_email" : "Niewłaściwy format wiadomości email",
  "home_register_password_required" : "Wymagane jest hasło.",
  "home_register_password_lower_digit" : "Hasło musi mieć 6 cyfr",
  "home_register_password_higher_digit" : "Hasło musi być użyte 15 cyfr jest wymagane",
  "home_register_cpassword_required" : "Potwierdź hasło jest wymagane",
  "home_register_cpassword_lower_digit" : "Potwierdź Hasło musi mieć 6 cyfr",
  "home_register_cpassword_higher_digit" : "Potwierdź Hasło musi być użyte 15 cyfr wymaganych",
  "home_register_cpassword_not_match" : "Potwierdź Hasło nie pasuje",

  "home_register_fp_not_worry" : "Podaj swój zarejestrowany adres e-mail.", 
  "home_register_fp_resetpass_msg" : "Aby zresetować hasło, podaj swój zarejestrowany adres e-mail.", 
  "home_register_fp_enter_email_msg" : "Wpisz swój adres e-mail tutaj", 
  "home_register_fp_submit_button" : "Prześlij", 
  "home_register_fp_temporary_sent_pass_msg" : "Link do zresetowania hasła został wysłany na zarejestrowany adres e-mail. Proszę zaktualizować swoje hasło najwcześniej.", 
  
  "home_works_howitworks": "Jak to działa?",
  "home_works_howitworkst1": "Przedstaw się", 
  "home_works_howitworksd1": "Zostań członkiem, zarejestruj się",
  "home_works_howitworkst2": "Otrzymuj ankiety",
  "home_works_howitworksd2": "Zacznij otrzymywać intuicyjne i proste ankiety",
  "home_works_howitworkst3": "Zdobywać punkty",
  "home_works_howitworksd3": "Podawaj najlepsze odpowiedzi i zbieraj punkty",
  "home_works_howitworkst4": "Wykupić",
  "home_works_howitworksd4": "Wykorzystaj swoje punkty za wiele ekscytujących nagród",
  
  "home_aboutus_whoweare": "Kim jesteśmy?",
  "home_aboutus_whowearep1": "W WisePerks ciężko pracujemy, aby upewnić się, że czas spędzony online jest satysfakcjonującym doświadczeniem. Współpracujemy z setkami firm, które chcą uzyskać Twoją opinię, wiedzieć, co myślisz o swoich produktach lub usługach. Podajesz swoją opinię na temat różnych usług, ale żadna z tych usług nie nagradza Cię za Twój czas, w przeciwieństwie do WisePerks - gdzie każda ankieta, w której uczestniczysz, daje ci wspaniałe nagrody pieniężne za Twój czas.",
  "home_aboutus_whowearep2": "Cenimy Twoją opinię i chcemy, abyś otrzymał nagrodę za swój czas. Mamy ankiety na różne tematy, które pasują do Twoich zainteresowań i ułatwiają odpowiedź. Możesz łatwo zarobić około 50-100 $ miesięcznie, biorąc udział w różnych ankietach. Pracujmy razem, aby to się stało.",
  
  "home_earn_more_surabt": "Jakie ankiety zapytać?",
  "home_earn_more_bp": "Eksperci biznesowi",
  "home_earn_more_consumers": "Konsumentów",
  "home_earn_more_hc": "Zdrowie i Kondycja",
  "home_earn_more_entertainment": "Zabawne & zabawy",
  "home_earn_more_gaming": "Maniaków gier",
  "home_earn_more_tech": "Technika zrozumiały",
  "home_earn_more_bonus_survey": "Ankieta bonusowa", 
  
  "home_reward_whatuearn": "Co zarabiasz?",
  "home_reward_whatuearnp1": "Chcemy zachować prostotę. Otrzymujesz punkty za wszystkie pomyślnie zakończone ankiety. Im więcej wypełnisz ankiet, tym więcej zdobędziesz punktów. Punkty można wymienić na wybrane karty upominkowe po przekroczeniu określonego progu.",
  "home_reward_whatuearnp2": "Gdy już będziesz gotowy do realizacji, wybierz opcję, którą lubisz - Amazon, Starbucks, iTunes i wiele innych. Od czasu do czasu rozdajemy także bonusy i podziękowania dla Boga w piątek.",
  
  "home_counter_earnedover": "Członkowie zarobili ponad",
  
  "home_contact_getintouch": "Skontaktuj się",
  "home_contact_emailus": "W przypadku jakichkolwiek pytań, napisz do nas na adres",
  
  "home_footer_faq": "Najczęściej zadawane pytania",
  "home_footer_pp": "Polityka prywatności",
  "home_footer_tc": "T & C",
  "home_footer_uf": "Nadchodzące funkcje", 
  "home_footer_copyright": "Copyright © current_year WisePerks. Wszelkie prawa zastrzeżone.",

  "home_header_home": "Dom", 
  "home_footer_toknowmore": "Wiedzieć więcej",
  "home_footer_createacc": "Załóż konto za darmo i dołącz do tysięcy szczęśliwych członków.",
  "home_footer_registernow": "Zarejestruj się teraz",

  "home_reset_password" : "Resetuj hasło", 
  "home_reset_pass_content" : "Wymagane jest zresetowanie hasła dla", 

  "dashboard_sidebar_selectavatar" : "Wybierz swojego awatara", 
  "dashboard_sidebar_uploadavatar" : "Kliknij tutaj, aby przesłać obraz", 
  "dashboard_sidebar_pbank" : "Bank punktów",
  "dashboard_sidebar_accumulated" : "Zdobył", 
  "dashboard_sidebar_realized" : "Realizowane",
  "dashboard_sidebar_pending" : "Korekty",
  "dashboard_sidebar_earned_tooltip" : "Punkty, które zebrałeś do tej pory.",
  "dashboard_sidebar_pending_tooltip" : "Punkty, które należy wycofać z konta.", 
  "dashboard_sidebar_main" : "Główny",
  "dashboard_sidebar_dashboard" : "Deska rozdzielcza",
  "dashboard_sidebar_profile" : "Profil",
  "dashboard_sidebar_updateprofile" : "Aktualizuj profil",
  "dashboard_sidebar_shistory" : "Historia ankiety",
  "dashboard_sidebar_mtrans" : "Moja transakcja",
  "dashboard_sidebar_passbook" : "Książka punktów",
  "dashboard_sidebar_redeem" : "Wykupić",
  "dashboard_sidebar_refer_friend" : "Poleć znajomemu",
  "dashboard_sidebar_logout" : "Wyloguj",

  "dashboard_sidebar_tutorial1" : "Pokazuje wszystkie dostępne ankiety i co można zarobić od nich.",
  "dashboard_sidebar_tutorial2" : "OdPowiedz na kilka pytań tutaj, aby poinformować nas o tym lepiej i pomóc nam w wysyłaniu ankiety zainteresowanie. Proszę zwrócić uwagę na pewne pytania, które nie byłyby w stanie zaktualizować swoje odpowiedzi po przesłaniu.",
  "dashboard_sidebar_tutorial2update" : "Możesz zaktualizować swoje odpowiedzi tutaj, jednak kilka pytań nie będą dostępne dla aktualizacje.",
  "dashboard_sidebar_tutorial3" : "Tutaj znajdziesz wszystkie ankiety, które próbowano.",
  "dashboard_sidebar_tutorial4" : "Pomaga śledzić wszystkie transakcje na WisePerks.",
  "dashboard_sidebar_tutorial5" : "Kilka szybkich kroków, aby przekształcić swoje zrealizowane punkty na nagrody.",
  "dashboard_sidebar_tutorial6" : "Zobacz całą historię transakcji punktów obciążonych / zaksięgowanych na Twoim koncie.",
  "dashboard_sidebar_gotit" : "Mam",

  "dashboard_sidebar_note" : "Proszę zanotować",
  "dashboard_sidebar_note1" : "Na wszystkie odpowiedzi należy odpowiedzieć szczerze.",
  "dashboard_sidebar_note2" : "Dokładnie i precyzyjnie z otwartymi odpowiedziami.",
  "dashboard_sidebar_note3" : "Nie przyspieszaj! Minimalny czas ukończenia ankiety jest ustawiony.",
  "dashboard_sidebar_note4" : "Proszę podać spójne odpowiedzi, gdy zadawane są te same pytania.",
  "dashboard_sidebar_note5" : "Nowe ankiety zostaną dodane, a te zamknięte zostaną automatycznie usunięte z Twojego kokpitu.",
  "dashboard_sidebar_note6" : "Zdobądź kilka punktów szybkich po prostu przez poleconych.",

  "dashboard_setting_hi" : "cześć",
  "dashboard_setting_minfo" : "Informacje o członkostwie",
  "dashboard_setting_macc" : "Zarządzać kontem",
  "dashboard_setting_upass" : "Aktualizować hasło",
  "dashboard_setting_yourname" : "Twoje imię to",
  "dashboard_setting_fname" : "Imię",
  "dashboard_setting_lname" : "Nazwisko",
  "dashboard_setting_save" : "Zapisz zmiany",
  "dashboard_setting_bornon" : "Urodziłeś się na",
  "dashboard_setting_youare" : "Ty jesteś",
  "dashboard_setting_postal" : "Twój kod pocztowy jest",
  "dashboard_setting_emailus" : "W przypadku jakiejkolwiek pomocy lub zapytań prosimy o wysłanie e-maila na adres",
  "dashboard_setting_membersince" : "Odtąd jesteś członkiem",
  "dashboard_setting_taken" : "Wziąłeś",
  "dashboard_setting_completed" : "ankiety i zakończone",
  "dashboard_setting_ofthem" : "z nich.",
  "dashboard_setting_earned" : "zarobiłeś",
  "dashboard_setting_pts" : "zwrotnica",
  "dashboard_setting_started" : "odkąd zacząłeś.",
  "dashboard_register_email" : "Jesteś zarejestrowanym z nami od",
  "dashboard_setting_preferences" : "Zarządzaj swoimi preferencjami",
  "dashboard_setting_emailoptout" : "Rezygnacja z poczty e-mail",
  "dashboard_setting_futureemails" : "Zrezygnuj z otrzymywania przyszłych wiadomości e-mail od WisePerks.",
  "dashboard_setting_futureemailsflash" : "Nasze e-maile Cię napisali na wszystkie lukratywne ankiety dostępne dla Ciebie. Czy na pewno nie chcesz ich odbierać.",
  "dashboard_setting_nrecemail" : "Nasze e-maile Cię napisali na wszystkie lukratywne ankiety dostępne dla Ciebie. Czy na pewno nie chcesz ich powstrzymać.",
  "dashboard_setting_emailout_modalmsg" : "Czy na pewno chcesz zrezygnować z otrzymywania przyszłych e-maili od WisePerks?",
  "dashboard_setting_emailin_modalmsg" : "Czy na pewno chcesz rozpocząć odbieranie przyszłych e-maili od WisePerks?",
  "dashboard_setting_emailout_succmsg" : "Twój pomyślnie przestaje otrzymywać e-maile od WisePerks",
  "dashboard_setting_emailin_succmsg" : "Twój pomyślnie rozpocząć odbieranie e-maili od WisePerks",
  "dashboard_setting_deactivateacc" : "Dezaktywuj konto",
  "dashboard_setting_disableprofile" : "Dezaktywacja konta spowoduje wyłączenie Twojego profilu z WisePerks.",
  "dashboard_setting_deactivateacc_msg" : "Czy na pewno chcesz dezaktywować swoje konto na WisePerks?",
  "dashboard_setting_deactivateacc_flash" : "Jeśli masz jakieś zażalenia lub opinie, prosimy o podzielenie się z nami. Chcielibyśmy zająć się tym priorytetem. Daj nam szansę. Czy na pewno chcesz usunąć swoje konto u nas.",
  "dashboard_setting_changelang" : "Zmień język",
  "dashboard_setting_preferredlang" : "Wybierz preferowany język",
  "dashboard_setting_updatepass" : "Aktualizować hasło",
  "dashboard_setting_plsupdate" : "Proszę zaktualizować swoje hasło poniżej.",
  "dashboard_setting_oldpass" : "Stare hasło",
  "dashboard_setting_enteroldpass" : "Wprowadź stare hasło",
  "dashboard_setting_newpass" : "Nowe hasło",
  "dashboard_setting_enternewpass" : "Wprowadź nowe hasło",
  "dashboard_setting_confpass" : "Potwierdź hasło",
  "dashboard_setting_enternewpassagain" : "Wprowadź ponownie nowe hasło",
  "dashboard_setting_update" : "Aktualizacja",

  "dashboard_doi_welcome" : "Zapraszamy",
  "dashboard_doi_welbck" : "Witamy spowrotem",
  "dashboard_doi_accntverified" : "Wygląda na to, przegapiłeś weryfikacji konta e-mail.",
  "dashboard_doi_getaccess" : "Pośpiechu! ty dostać",
  "dashboard_doi_getaccess2" : "punkty po sprawdzeniu.",
  "dashboard_doi_activateacc" : "Sprawdź swoją skrzynkę odbiorczą i kliknij odnośnik podany w wiadomości e-mail weryfikującej konto wysłanej na zarejestrowane konto e-mail.",
  "dashboard_doi_chckspam" : "Uprzejmie, Sprawdź swój spam/folder wiadomości-śmieci w przypadku, gdy nie są w stanie znaleźć weryfikacyjny e-mail.",
  "dashboard_doi_didntgetconf" : "Nie dotarła jeszcze wiadomość weryfikacyjna?",
  "dashboard_doi_resendconf" : "Wyślij ponownie wiadomość weryfikacyjną",
  "dashboard_doi_resendconfsucc" : "Wiadomość weryfikacyjna została wysłana na zarejestrowany adres e-mail.",
  
  "dashboard_survey_availsurveys" : "Dostępne ankiety",
  "dashboard_survey_wisechecksurveys1" : "Nie możesz zobaczyć ankiet z powodów bezpieczeństwa. Prosimy o kontakt do supportu na",
  "dashboard_survey_wisechecksurveys2" : ".",
  "dashboard_survey_unsubscribesurveys" : "Niedostępne ankiety powodujące rezygnację z subskrypcji",
  "dashboard_featured" : "Rekomendowane",
  "dashboard_profile_survey" : "Ankieta profilu",
  "dashboard_survey_start" : "Początek",
  "dashboard_survey_on" : "Na",
  "dashboard_survey_off" : "Poza",
  "dashboard_survey_point": "Punkt",
  "dashboard_survey_points" : "Punktów",
  "dashboard_survey_minute" : "Minut",
  "dashboard_survey_minutes" : "Minut",

  "demography_hi" : "Cześć",
  "demography_welcome" : "Witamy na WisePerks. Właśnie zarobiłeś sobie",
  "demography_points" : "punkty rejestrując się u nas.",
  "demography_share" : "Proszę podzielić się kilkoma szczegółami w poniższy formularz, aby pomóc nam w przeprowadzeniu odpowiednich ankiet dla Ciebie.",

  "demography_gender" : "Jestem",
  "demography_male" : "Męski",
  "demography_female" : "Płeć żeńska",
  "demography_birthday" : "Urodziny",
  "demography_month" : "Miesiąc", 
  "demography_day" : "Dzień", 
  "demography_year" : "Roku",   
  "demography_jan" : "styczeń",
  "demography_feb" : "luty",
  "demography_mar" : "Marsz",
  "demography_apr" : "kwiecień",
  "demography_may" : "Może",
  "demography_jun" : "czerwiec",
  "demography_jul" : "lipiec",
  "demography_aug" : "sierpień",
  "demography_sep" : "wrzesień",
  "demography_oct" : "październik",
  "demography_nov" : "listopad",
  "demography_dec" : "grudzień",
  "demography_hearabout" : "Skąd dowiedziałeś się o WisePerks?",
  "demography_opt1" : "Wybierz najbliższy, który dotyczy",
  "demography_opt2" : "Biuletyn e-mailowy",
  "demography_opt3" : "Facebook",
  "demography_opt4" : "Forum lub blog",
  "demography_opt5" : "Przyjaciel",
  "demography_opt6" : "Linkedin",
  "demography_opt7" : "Wyszukiwarki",
  "demography_opt8" : "Reklama telewizyjna",
  "demography_opt9" : "Twitter",
  "demography_opt10" : "Stronie internetowej",
  "demography_opt11" : "Youtube",
  "demography_opt12" : "Innych",
  "demography_zipcode": "Kod pocztowy",
  "demography_submit" : "Prześlij",

  "privacypolicy_privacypolicy" : "Polityka prywatności",
  "privacypolicy_privacyisimp" : "Twoja prywatność jest dla nas ważna.",
  "privacypolicy_lastupdated" : "Ostatnia aktualizacja",

  "privacypolicy_para1" : 'WiseWorks (wraz z podmiotami stowarzyszonymi, "Firma") jest właścicielem i operatorem wielu różnych stron internetowych, aplikacji mobilnych i usług interaktywnych, w tym między innymi Wisesample.com, Flapbucks.com, WisePerks.com i innych (łącznie " Witryny WiseWorks ").Niniejsza polityka prywatności ("Polityka prywatności") odnosi się do Witryn WiseWorks i do wszystkich funkcji, aplikacji mobilnych, wiadomości e-mail, usług online i innych funkcjonalności, niezależnie od tego, czy są dostępne za pośrednictwem komputera, urządzenia mobilnego lub w inny sposób oraz usług dostępnych w lub za pośrednictwem Witryny i funkcje WiseWorks ("Usługi").Niniejsza Polityka prywatności obejmuje wyłącznie informacje zebrane przez Firmę za pośrednictwem Witryn i Funkcji WiseWorks oraz naszych Usług i nie obejmuje żadnych informacji otrzymanych lub zebranych w trybie offline przez Firmę (chyba że wyraźnie określono inaczej) lub zebranych przez jakiekolwiek niepowiązane witryny lub firmy, którym możemy podać linki.',

  "privacypolicy_para2" : "Oczekuje się, że panelista / respondent wypełni swój profil w sposób uczciwy i zgodny z prawdą i nie przedstawi złych lub błędnych informacji, aby kwalifikować się do kolejnych ankiet.Oczekuje się również, że panelista / respondent nie będzie manipulować odpowiedziami ani używać innych nieuczciwych środków (zmiana adresu IP, wielu kont itp.) Podczas wypełniania ankiety, aby uzyskać zachęty.WisePerks ma wystarczające zabezpieczenia, aby wykryć takie anomalie i jeśli takie zachowanie zostanie zauważone, panelista zostanie zablokowany / zablokowany przez WisePerks i wszystkie / którekolwiek z jego wniosków o umorzenie zostaną anulowane.",

  "privacypolicy_para3" : "WiseWorks dokłada wszelkich starań, aby chronić swoją prywatność.Poniższa polityka prywatności wyjaśnia, w jaki sposób Twoje dane osobowe są przechowywane i traktowane podczas przeglądania naszej witryny i usług.",

  "privacypolicy_topic1" : "Jakie dane personalne zbiera ode mnie WisePerks.com?",
  "privacypolicy_topic1ans" : "WisePerks.com zbiera informacje na kilka sposobów z różnych części naszej witryny i sieci.Niektóre informacje są gromadzone podczas rejestracji.Podczas rejestracji członka, WisePerks.com pyta o imię i nazwisko, adres e-mail, kod pocztowy i dane gospodarstwa domowego.Im więcej informacji zgłosisz na ochotnika, aby dostarczyć dokładnie, tym lepiej możemy zaoferować Ci usługi i nagrody.Dodatkowo, jeśli kiedykolwiek skontaktujesz się z WisePerks.com w jakikolwiek sposób, możemy zachować zapis tej korespondencji.Ilekroć WisePerks.com zbiera takie informacje, naszym celem jest zamieszczenie na tej stronie linku do niniejszej Polityki prywatności.",

  "privacypolicy_topic2" : "Czym są pliki cookie i jak korzysta z nich WisePerks.com?",
  "privacypolicy_topic2ans" : "Cookie to pakiet danych elektronicznych wysyłanych do przeglądarki, z serwera WWW i przechowywanych na dysku twardym komputera.W ramach oferowania konkretnych ankiet, informacji i spersonalizowanych usług, WisePerks.com może używać plików cookie do przechowywania, a czasem do śledzenia informacji o Tobie.WisePerks.com mogą wymagać, aby zaakceptować ciasteczka wytwarzania takich konkretnych treści dla Ciebie.Zapobiega to również konieczności wpisywania danych logowania za każdym razem.",

  "privacypolicy_topic3" : "Co to jest polityka WisePerks.com zezwalająca na aktualizowanie, edytowanie, zmienianie lub usuwanie informacji o moim koncie osobistym?",
  "privacypolicy_topic3ans" : "W każdej chwili możesz edytować Informacje o koncie WisePerks.com, korzystając z loginu WisePerks.com i hasła.Skontaktuj się z",
  "privacypolicy_topic3ans2" : ", aby uzyskać dalsze instrukcje dotyczące dezaktywacji konta WisePerks.com.",

  "privacypolicy_topic4" : "Kto zbiera informacje i do jakiego celu?",
  "privacypolicy_topic4ansp1" : "WisePerks.com zbiera kilka informacji poprzez ankiety i rejestrację oraz inne środki, co pomaga jej klientom badawczym w opracowaniu nowych produktów i usług dla konsumentów.Udostępniasz te informacje tylko przez WisePerks.com, chyba że wyraźnie zaznaczono inaczej w ankietach, w których uczestniczysz.Jeśli dane są gromadzone i / lub utrzymywane przez jakąkolwiek firmę inną niż WiseWorks - lub witryny WiseWorks, zostaniesz o tym powiadomiony przed momentem zbierania lub przesyłania danych.Jeśli nie chcesz, aby Twoje dane były udostępniane, możesz nie zezwalać na przeniesienie, nie używając tej konkretnej ankiety lub produktu.",
  "privacypolicy_topic4ansp2" : "Nagrody i promocje, które oferujemy na WisePerks.com mogą być sponsorowane przez inne firmy niezależnie lub mogą być współfinansowane przez WisePerks.com i inną firmę.Niektóre dane zebrane podczas takiej promocji mogą być udostępniane sponsorowi.Zostaniesz jednak powiadomiony przed momentem zbierania lub przesyłania danych.Możesz zdecydować, że nie będziesz uczestniczyć w danej promocji, jeśli nie chcesz udostępniać swoich danych.",
  "privacypolicy_topic4ansp3" : "Wszelkie szczególne informacje wrażliwe (takie jak informacje określające warunki medyczne lub zdrowotne, pochodzenie rasowe lub etniczne, poglądy polityczne, przekonania religijne lub duchowe, zawody i członkostwo w związku lub informacje określające życie seksualne danej osoby) będą traktowane z dodatkową ostrożnością.Jeśli WisePerks.com zamierza udostępnić takie informacje innej firmie w innym celu niż pierwotnie zebrane, przed takim ujawnieniem zostaniesz wyraźnie poproszony o pozwolenie.",

  "privacypolicy_topic5" : "Z kim WisePerks.com udostępnia moje informacje?",
  "privacypolicy_topic5ansp1" : "Traktujemy prywatność bardzo poważnie.WisePerks.com nie ujawni żadnych informacji umożliwiających identyfikację użytkownika, z wyjątkiem sytuacji, gdy otrzymamy Twoją wyraźną zgodę lub w szczególnych okolicznościach, że wymaga tego prawo lub w okolicznościach szczegółowo określonych poniżej.Poniżej opisano niektóre sposoby ujawniania informacji.Zapoznaj się z Warunkami korzystania z naszych usług, aby uzyskać bardziej szczegółowe informacje na temat sposobu udostępniania danych osobowych.",
  "privacypolicy_topic5ansp2" : "Partnerzy biznesowi, klienci i sponsorzy:",
  "privacypolicy_topic5ansp3" : "WisePerks.com może ujawniać dane osobowe naszym partnerom, klientom lub sponsorom.Jeśli jednak dane osobowe o Tobie kiedykolwiek zostały udostępnione, poinformujemy Cię, zanim zbieramy lub przesyłamy dane.Wiele działań, które oferujemy, wymaga dodatkowych informacji od sponsorów.Jeśli poprosisz o więcej informacji, dajesz WisePerks.com pozwolenie na udostępnianie informacji sponsorowi, aby mogli spełnić Twoją prośbę.W wielu przypadkach tylko Twój adres e-mail zostanie udostępniony.Jeśli więcej informacji zostanie przekazanych naszym partnerom, otrzymasz powiadomienie z wyprzedzeniem.Od czasu do czasu możesz otrzymać możliwość otrzymywania materiałów testowych lub innych produktów lub ofert specjalnych od naszych partnerów.Jeśli wyrażasz zgodę na otrzymywanie informacji od tych stron trzecich, WisePerks.com (za twoją zgodą) udostępni im twoje imię i adres e-mail.",
  "privacypolicy_topic5ansp4" : "WisePerks.com może również ujawnić dane osobowe w niektórych szczególnych przypadkach, gdy ujawnienie tych informacji jest niezbędne do zidentyfikowania, skontaktowania się lub wszczęcia postępowania sądowego przeciwko osobie, która może naruszać zasady WisePerks.com i Warunki świadczenia usług lub może powodować obrażenia lub zakłócać (umyślnie lub nieumyślnie) produktów, usług, praw lub własności WisePerks.com, innych członków i użytkowników WisePerks.com lub kogokolwiek, na kogo mogą mieć wpływ takie działania.WisePerks.com może ujawnić lub uzyskać dostęp do informacji o koncie, jeśli wierzymy w dobrej wierze, że wymaga tego prawo oraz w celach administracyjnych i innych, które uważamy za niezbędne w celu utrzymania, obsługi i ulepszania naszych produktów i usług.",

  "privacypolicy_topic6" : "Jakie są podstawy prawne, którymi posługujemy się przy przetwarzaniu twoich informacji?",
  "privacypolicy_topic6ans" : 'WiseWorks zobowiązuje się do ochrony prywatności i danych.Nasza polityka prywatności przyjmuje zasady ogólnego rozporządzenia o ochronie danych wydane przez Unię Europejską ("GDPR") jako punkt odniesienia, do którego przynależymy my, nasi pracownicy i dostawcy.',

  "privacypolicy_topic7" : "Jakie są moje wybory dotyczące gromadzenia, wykorzystywania i rozpowszechniania moich informacji?",
  "privacypolicy_topic7ans" : "Tylko WisePerks.com (lub partnerzy współpracujący z WisePerks.com w ramach umów o poufności) prześlą Ci bezpośrednie wiadomości, tylko jeśli zaznaczysz, że nie sprzeciwiasz się tym.Możesz także wybrać sposób działania plików cookie.Zmieniając ustawienia przeglądarki internetowej, możesz zaakceptować wszystkie pliki cookie, powiadomić o ustawieniu pliku cookie lub odrzucić wszystkie żądania plików cookie.Jeśli zdecydujesz się na odrzucenie wszystkich plików cookie, nie będziesz mógł korzystać z tych usług WisePerks.com, które wymagają wcześniejszej rejestracji w celu wzięcia udziału.WisePerks.com nie sprzedaje ani nie dzierżawi żadnych informacji o użytkowniku nikomu.Zawsze będziemy Cię powiadamiać w momencie zbierania danych lub przesyłania danych, jeśli Twoje dane będą udostępniane stronom trzecim, a Ty zawsze będziesz mieć możliwość odmowy ich udostępnienia.",

  "privacypolicy_topic8" : "Jakie środki bezpieczeństwa obowiązują na stronie WisePerks.com w celu ochrony przed utratą, niewłaściwym użyciem lub zmianą danych mojego konta?",
  "privacypolicy_topic8ansp1" : "Informacje o Twoim koncie WisePerks.com są chronione hasłem, dzięki czemu Ty i tylko Ty masz dostęp do tych informacji.",
  "privacypolicy_topic8ansp2" : "Zdecydowanie zalecamy, aby nikomu nie udostępniać swojego hasła.Nigdy nie poprosimy Cię o podanie hasła w niezapowiedzianej rozmowie telefonicznej lub e-mailu.Wyloguj się z konta WisePerks.com i zamknij okno przeglądarki po zakończeniu aktywności.Dzięki temu inni użytkownicy nie będą mieli dostępu do twoich danych osobowych i konta, jeśli korzystasz z publicznego komputera lub udostępniasz komputer komuś innemu.Ilekroć WisePerks.com obsługuje dane osobowe w opisany powyżej sposób, WisePerks.com podejmuje kroki w celu zapewnienia, że ​​twoje dane są traktowane w bezpieczny sposób i zgodnie z odpowiednimi Warunkami korzystania z usługi i niniejszą Polityką prywatności.Niestety, żadna transmisja danych przez Internet nie może być zagwarantowana w 100%.W efekcie, podczas gdy staramy się chronić twoje dane osobowe, WisePerks.com nie może zapewnić ani zagwarantować bezpieczeństwa jakichkolwiek informacji, które przesyłasz do nas lub naszych produktów lub usług online, i robisz to na własne ryzyko.Po otrzymaniu danych dokładamy wszelkich starań, aby zapewnić bezpieczeństwo naszych systemów.",

  "privacypolicy_topic9" : "Co jeszcze powinienem wiedzieć o mojej prywatności?",
  "privacypolicy_topic9ans" : "Należy pamiętać, że zawsze (i gdziekolwiek) decydujesz się ujawnić dane osobowe w Internecie - na przykład za pośrednictwem e-maili, serwisów społecznościowych, blogów lub czatów - informacje te mogą być gromadzone i wykorzystywane przez każdego innego użytkownika.Jeśli publikujesz dane osobowe w Internecie, które są publicznie dostępne, możesz otrzymać w zamian niezamawiane wiadomości od innych podmiotów.Ostatecznie ponosisz wyłączną odpowiedzialność za utrzymanie w tajemnicy swoich haseł i / lub informacji o koncie.Bądź ostrożny i odpowiedzialny za każdym razem, gdy jesteś online.",

  "privacypolicy_topic10" : "W jaki sposób WisePerks.com chroni prywatność moich dzieci?",
  "privacypolicy_topic10ans" : "WisePerks.com uzyskuje uprzednią zgodę rodziców, gdy ankiety wymagają udziału dzieci, a nadzór rodzicielski jest również wymagany w przypadku takich badań z udziałem dzieci w wieku 6-15 lat.Szczególną uwagę przykładamy do ochrony bezpieczeństwa i prywatności młodych użytkowników naszej witryny i zachęcamy do udziału w doświadczeniu dziecka w Internecie.Nie zezwalamy na jakiekolwiek działania marketingowe lub badania dla dzieci, a niektóre tematy są całkowicie zabronione w przypadku badań wśród dzieci: nazwa / nazwa konta / adres e-mail lub adres fizyczny, numer telefonu, rasa lub pochodzenie etniczne, religia lub obsada, dochód gospodarstwa domowego, choroba rodzinna , Stan cywilny rodziców lub innych członków rodziny, Kwestie dotyczące związku, Stopnie w szkole lub na uczelni, Alkohol, Problemy seksualne, Tytoń i broń palna.",

  "privacypolicy_topic11" : "Specjalna uwaga dla użytkowników międzynarodowych",
  "privacypolicy_topic11ansp1" : "Witryna jest hostowana w Stanach Zjednoczonych.Jeśli jesteś Użytkownikiem uzyskującym dostęp do Witryny z Unii Europejskiej, Azji lub jakiegokolwiek innego regionu z prawami lub przepisami regulującymi gromadzenie, wykorzystanie i ujawnianie danych osobowych, które różnią się od przepisów prawa Stanów Zjednoczonych, pamiętaj, że przekazujesz swoje dane osobowe do Stany Zjednoczone, które nie mają takich samych przepisów o ochronie danych jak UE i inne regiony, a także udostępniając swoje dane osobowe, wyrażają zgodę na:",
  "privacypolicy_topic11ansp2" : "Wykorzystanie twoich danych osobowych do wyżej wymienionych zastosowań zgodnie z Polityką Prywatności; oraz przekazanie twoich danych osobowych do Stanów Zjednoczonych, jak wskazano powyżej.UŻYTKOWNIK PRZYJMUJE DO WIADOMOŚCI, ŻE PRAWA STANÓW ZJEDNOCZONYCH DOTYCZĄ TWOICH INFORMACJI W SPOSÓB, W KTÓRYM MOGĄ BYĆ ZNACZNIE INNE I MNIEJSZE OCHRONNE NIŻ ZABIEGI WYMAGANE W RAMACH PRAWA ZAWARTYCH W INNYCH KRAJACH I JURYSDYKCJACH.JEŚLI NIE CHCESZ, ŻE SWOJE INFORMACJE PRZEKAZYWANE DO STANÓW ZJEDNOCZONYCH, NIE POWINIEN PODZIELIĆ SWOICH INFORMACJI Z NAMI, ANI ZASŁUGIĆ UŻYTKOWANIA STRONY.W ZAKRESIE DOZWOLONYM PRZEZ PRAWO PAŃSTWA, W KTÓRYM ZNAJDUJESZ SIĘ, UŻYTKOWNIK WYRAŹNIE OTRZYMA SWOJE PRAWO, MOGĄC MUSI MIEĆ WYMAGANIA, ŻE UDZIELIĆ TWOICH IDENTYFIKACYJNYCH INFORMACJI ZGODNIE Z PRAWEM KAŻDEGO PAŃSTWA LUB JURYSDYKCJI INNYCH NIŻ STANY ZJEDNOCZONE.JEDNAK POWYŻSZA WAŻNOŚĆ NIE MOŻE BYĆ PRAWNIE WIĄŻĄCA W NIEKTÓRYCH KRAJACH, TAKICH JAK PAŃSTWA CZŁONKOWSKIE UNII EUROPEJSKIEJ.W ZAKRESIE, W JAKIM ZOSTAŁO ZLOKALIZOWANE, NINIEJSZA POWYŻSZA ODPOWIEDZIALNOŚĆ NIE DOTYCZY UŻYTKOWNIKA.",

  "privacypolicy_topic12" : "Zmiany i aktualizacje niniejszej Polityki prywatności",
  "privacypolicy_topic12ans" : "Firma WiseWorks zastrzega sobie prawo do zmiany, modyfikacji, dodawania lub usuwania części niniejszych Zasad prywatności w dowolnym czasie i bez podania przyczyny.Za każdym razem, gdy WiseWorks skorzysta z tego prawa, będziesz musiał koniecznie zaakceptować zaktualizowaną Politykę prywatności, a jeśli tego nie zrobisz, utracisz dostęp do Witryny i będziesz mógł z niej korzystać.",

  "privacypolicy_addinfo" : "Dodatkowe informacje",
  "privacypolicy_sentto" : "Wszelkie inne pytania dotyczące tego należy przesłać na adres",

  "faq_faq" : "Często Zadawane Pytania",
  "faq_getans" : "Uzyskaj odpowiedzi na wszystkie swoje pytania.",

  "faq_ques1" : "Co to jest WisePerks?",
  "faq_ans1" : "Cieszymy się, że zapytałeś.WisePerks to witryna ankiety online.Wiele marek i firm poszukuje respondentów, aby przedstawić swoje opinie i otrzymać nagrody w zamian.WisePerks to jedna z takich platform, na których możesz wyrazić swoją opinię i zdobyć nagrody.",

  "faq_ques2" : "Czy możesz dołączyć?",
  "faq_ans2" : "Tak, dołączenie jest bezpłatne.W rzeczywistości, jeśli ktoś prosi o uiszczenie opłaty, chcielibyśmy, abyś zgłosił to nam.",

  "faq_ques3" : "Czy mogę polecić moich przyjaciół?",
  "faq_ans3" : 'Tak, możesz zaprosić swoich znajomych i kolegów do przyłączenia się, a także otrzymać nagrodę za to. Kliknij link "Poleć znajomemu" na pulpicie nawigacyjnym, aby uzyskać wszystkie szczegóły.',

  "faq_ques4" : "Co mam zarobić?",
  "faq_ans4" : "Teraz w naszym nowym modelu, można zbierać punkty na wiele sposobów, innych niż tylko poprzez ankiety. Otrzymujesz punkty: rejestrując się u nas, weryfikując swoje konto e-mail, za ukończenie swojego profilu, oczywiście za ukończenie każdej ankiety i do zaskoczenia nawet dla zdyskwalifikowany ankiety. Więc mamy wszystkie powody, aby zacząć się nie jest?",

  "faq_ques5" : "Jak zostać członkiem WisePerks.com?",
  "faq_ans5" : 'Otwórz WisePerks.com w przeglądarce.Utwórz login członkostwa, klikając kartę "Zarejestruj się".Po prostu postępuj zgodnie z instrukcjami i poprowadzi Cię przez resztę procesu.Musisz potwierdzić zamiar przystąpienia, klikając wiadomość e-mail z potwierdzeniem wysłaną przez nas na podany adres e-mail.',

  "faq_ques6" : "Gdzie jest wspomniana wiadomość e-mail z potwierdzeniem?",
  "faq_ans6p1" : "Wiadomość e-mail z potwierdzeniem jest automatycznie wysyłana na adres e-mail podany podczas rejestracji. Jeśli nie widzisz tej wiadomości w skrzynce odbiorczej, sprawdź folder spam lub wiadomości-śmieci. Aby uniknąć przyszłych e-maili od nas i ankiety zaprasza/wiadomości z dotarciem do folderu spam, proszę dodać",
  "faq_ans6p2" : "do listy zatwierdzonych nadawców lub jako kontakt w skrzynce pocztowej. Jeśli nadal nie możesz znaleźć tej wiadomości e-mail z potwierdzeniem, zaloguj się na stronie Moje konto, potwierdź, że Twój adres e-mail jest poprawny, a następnie poproś o inny e-mail z potwierdzeniem.",

  "faq_ques7" : "Jak mogę wykonać ankietę?",
  "faq_ans7" : 'Po zalogowaniu się po wpisaniu nazwy użytkownika i hasła, można dotrzeć do deski rozdzielczej i zobaczyć wszystkie ankiety, które można podjąć. Przesyłamy również linki ankiety do zarejestrowanych identyfikatorów e-mail. Możesz wziąć ten ogniwo bezpośrednio z twój skrzynka pocztowa.',

  "faq_ques8" : "Jak poufne są moje odpowiedzi, moje dane?",
  "faq_ans8" : "W przypadku WisePerks najwyższy priorytet ma zachowanie prywatności i poufności użytkowników.Nasi klienci interesują się tylko tym, co myślisz, jako konsument lub jak się czujesz.My w WisePerks, nigdy nie udostępniamy / przekazujemy żadnych danych osobowych naszym klientom, a żadna z ankiet WisePerks również nie sprzedaje ani nie wypożycza żadnych informacji stronom trzecim.",

  "faq_ques9" : "Jak długo trwa te ankiety?",
  "faq_ans9" : "Większość ankiet zajmuje tylko około 10 do 15 minut, a nawet mniej.Jednak od czasu do czasu możemy mieć dłuższe ankiety.W każdej ankiecie informujemy z wyprzedzeniem, ile czasu to potrwa, ale pamiętaj, że dłuższe ankiety również mają wyższą nagrodę.",

  "faq_ques10" : "Czy każdy może wziąć udział w ankiecie WisePerks?",
  "faq_ans10" : "Aby zarejestrować się w WisePerks, musisz mieć co najmniej 13 lat.Chociaż czasami będziemy zapraszać Cię do ankiet, aby uczestniczyć z młodszymi członkami twojej rodziny.",

  "faq_ques11" : "Skąd wiesz, że kwalifikuję się do ankiety?",
  "faq_ans11" : "Nasz algorytm dopasowuje informacje z Twojego profilu do dostępnych ankiet, aby zapewnić najbardziej odpowiednie ankiety.Kiedy rozpoczyna się ankieta, zwykle pierwsze kilka pytań określa, czy jesteś respondentem, którego nasz klient szuka w danej ankiecie.Dysponujemy szeroką gamą ankiet szukających wielu różnych typów ludzi (np. Osoby, które regularnie oglądają filmy, grają w konkretny sport, kobiety z dziećmi itd.) I codziennie pojawiają się nowe ankiety!",

  "faq_ques12" : "Jakie są moje obowiązki wobec WisePerks?",
  "faq_ans12" : "Jedyne, czego oczekujemy od Ciebie, to uczciwe przedstawianie swoich opinii i myśli, ponieważ klienci szukają uczciwych i prawdziwych odpowiedzi, aby ulepszyć swoje produkty i usługi.Podaj prawidłowe informacje podczas rejestracji jako członek i aktualizuj wszelkie wprowadzone zmiany.",

  "faq_ques13" : "Dlaczego powinienem podawać informacje dotyczące mojego gospodarstwa domowego, technologii lub produktów, które kupuję?",
  "faq_ans13" : "W przypadku każdej ankiety szukamy określonego profilu respondentów.Jeśli wiemy z Twojego profilu informacje, że Twoje informacje pasują do wymagań klienta, bingo!Możemy prawie na pewno zapewnić, że będziesz w stanie ukończyć ankietę i zdobyć nagrodę za to.Zasadniczo zwiększa to twoje szanse na wybranie do ankiety.",

  "faq_ques14" : "Kiedy otrzymam swoją Kartę Podarunkową po wypełnieniu ankiety WisePerks?",
  "faq_ans14" : "Punkty zdobyte po pomyślnym ukończeniu ankiety są gromadzone w wiadrze. Po tych punktów są realizowane można zrealizować.",

  "faq_ques15" : "Czym naprawdę jest moje konto?",
  "faq_ans15" : "To po prostu łatwy sposób, aby zobaczyć swoje dane osobowe, wprowadzić dowolne zmiany, zobaczyć aktywność w ankiecie, zarobione nagrody, wszystko w jednym miejscu.",

  "faq_ques16" : "Jak mogę anulować moje konto?",
  "faq_ans16" : "Z tak wiele najbliższych na swój sposób, jesteśmy pewni, że nie chcesz to zrobić. Jednak na desce rozdzielczej--> przejdź do ustawienia--> zarządzanie kontami--> dezaktywować konto",

  "faq_ques17" : "Jak działa system punktowy?",
  "faq_ans17p1" : "Każda ankieta ma określoną liczbę punktów. Po pomyślnym zakończeniu ankiety punkty te są dodawane do zarobków. Możesz je wymienić, gdy osiągniesz minimalny próg.",
  "faq_ans17p2" : "W kilku przypadkach z powodu nieprzewidzianych problemów z połączeniem lub awarii, Twoja odpowiedź może nie zostać uchwycona na końcu naszego klienta. Następnie musimy cofnąć te punkty z twojego konta. Jeśli już wykorzystałeś te punkty, a Twoje konto ma niskie saldo, wówczas Twoje zarobki będą ujemne. Korekty zostaną wprowadzone w następnym cyklu wymiany.",

  "faq_ques18" : "Jak szybko punkty za ankietę zostaną dodane do mojego salda punktów?",
  "faq_ans18" : "Twoje punkty zostaną dodane do twojego salda punktów w ciągu 48 godzin.",

  "faq_ques19" : "Jaki jest minimalny próg na odkupienie?",
  "faq_ans19" : "Minimalny próg do umorzenia wynosi 25000 punktów.Aby zakwalifikować się do umorzenia, musisz ukończyć 8-12 ankiet.",

  "faq_ques20" : "W jaki sposób punkty są konwertowane na gotówkę z karty upominkowej?Czy istnieje stosunek?",
  "faq_ans20" : "Tak. W zależności od kraju punkty są konwertowane na gotówkę z karty upominkowej.Współczynniki konwersji są pokazane poniżej",
  "faq_ponits" : "Zwrotnica",
  "faq_point" : "Punkt",
  "faq_cent" : "Cent",

  "faq_ques21" : "Masz więcej pytań?",
  "faq_ans21p1" : "Zapraszam do nas na e-mail",
  "faq_ans21p2" : "a my wrócimy do Ciebie w ciągu 48 godzin.",

  "Profiling_profiledashboard" : "Pulpit nawigacyjny profilu", 
  "Profiling_completeprofile" : "Uzupełnij swój profil i uzyskać solidny",
  "Profiling_completepoints" : "Punktów!",
  "Profiling_answercarefully" : "Poniższy ogólny zestaw pytań zostanie wykorzystany do stworzenia Twojego osobistego profilu, aby zapewnić Ci najlepsze przyszłe możliwości ankiety. Proszę wziąć swój czas, odpowiedzieć uważnie i prawdę.",

  "Profiling_cat_general" : "Ogólne",
  "Profiling_cat_personal" : "Osobiste",
  "Profiling_cat_household" : "Gospodarstwa domowego",
  "Profiling_cat_profession" : "Zawód",

  "Profiling_bar_completed" : "ukOńczono%",
  
  "Profiling_update_ans_button" : "Aktualizuj i kontynuuj",
  "Profiling_save_ans_button" : "Zapisz",
  "Profiling_sc_ans_button" : "Zapisz & dalej",

  "Category_thanku_msg1" : "Dobry będzie",
  "Category_thanku_msg2" : "kilka więcej i będziesz miał",
  "Category_thanku_msg3" : "punktów na koncie.",

  "Category_thanku_update_msg1" : "Dziękujemy za aktualizację informacji!",
  "Category_thanku_update_msg2" : "",
  "Category_thanku_update_msg3" : "",
   "Category_Continue_button" : "Kontynuować",

  "OverAll_thanku_msg1" : "Dziękujemy za udostępnienie informacji. Zarobiłeś",
  "OverAll_thanku_msg2" : "Punktów.",

  "Survey_history_surveyhistory" : "Historia ankiety",
  "Survey_history_fromthisscreen" : "Na tym ekranie widoczne są wszystkie transakcje.",
  "Survey_history_show" : "Pokaż",
  "Survey_history_entries" : "Wpisy",
  "Survey_history_date" : "Data",
  "Survey_history_details" : "Szczegóły",
  "Survey_history_surveys" : "Badania",
  "Survey_history_pointsreceived" : "Otrzymane Punkty",
  "Survey_history_result" : "Wynik",
  "Survey_history_status" : "Stan",
  "Survey_history_status_complete" : "Kompletny", 
  "Survey_history_status_disqualified" : "Zdyskwalifikowany",
  "Survey_history_keyword" : "Słowa kluczowego",
  "Survey_history_search" : "Szukaj",
  "Survey_history_to" : "do",
  "Survey_history_selectoption" : "Wybierz opcję",
  "Survey_history_earned" : "Zdobył",
  "Survey_history_redeemed" : "Odkupiony",
  "Survey_history_norecord" : "Nie znaleziono rekordu",
  "Survey_history_Surveypts" : "Punkty pomiarowe",
  "Survey_history_Promocode" : "Kod promocyjny",

  "My_transactions_mytransactions" : "Moje transakcje",
  "My_transactions_fromthisscreen" : "Na tym ekranie widoczne są wszystkie transakcje.",
  "My_transactions_show" : "Pokaż",
  "My_transactions_entries" : "Wpisy",
  "My_transactions_transactionid" : "Identyfikator transakcji",
  "My_transactions_date" : "Data",
  "My_transactions_details" : "Szczegóły",
  "My_transactions_points" : "Punktów",
  "My_transactions_status" : "Stan",
  "My_transactions_keyword" : "Słowa kluczowego",
  "My_transactions_search" : "Szukaj",
  "My_transactions_to" : "do",
  "My_transactions_selectoption" : "Wybierz opcję",
  "My_transactions_status_earned" : "Zdobył",
  "My_transactions_status_redeemed" : "Odkupiony",
  "My_transactions_status_pending" : "Oczekujące",
  "My_transactions_status_reverted" : "Przywrócone",
  "My_transactions_status_declined" : "Odrzucona",
  "My_transactions_norecord" : "Nie znaleziono rekordu",
  "My_transactions_off_msg1" : "Po ",
  "My_transactions_off_msg2" : ", wszystkie szczegóły transakcji zostaną opublikowane w zakładce Książka punktów",

  "Passbook_heading" : "Książka punktów",
  "Passbook_show" : "Pokazać",
  "Passbook_entries" : "wpisy",
  "Passbook_search" : "Szukaj",
  "Passbook_type" : "Rodzaj",
  "Passbook_surveyid" : "Ankiety",
  "Passbook_date" : "Data",
  "Passbook_selectoption" : "Wybierz opcję",
  "Passbook_credit" : "Kredyt",
  "Passbook_debit" : "Obciążyć",
  "Passbook_survey_id" : "Identyfikator ankiety",
  "Passbook_survey_pts" : "Punkty ankiety",
  "Passbook_earned_pts" : "Zwrotnica",
  "Passbook_pending_pts" : "Oczekujące punkty",
  "Passbook_promo_code" : "Kod promocyjny",
  "Passbook_transaction_type" : "Typ transakcji",
  "Passbook_balance" : "Saldo",
  "Passbook_status" : "Status",
  "Passbook_type_soi" : "Rejestracja",
  "Passbook_type_doi" : "Weryfikacja adresu e-mail",
  "Passbook_type_profile" : "Uzupełnienie profilu",
  "Passbook_type_survey" : "Ankieta",
  "Passbook_type_doir" : "Weryfikacja adresu e-mail przez",
  "Passbook_type_doirt" : "Weryfikacja adresu e-mail przez polecenie",
  "Passbook_type_surveyr" : "Próba przeprowadzenia badania przez",
  "Passbook_type_surveyrt" : "Próba badania przez skierowanie",
  "Passbook_type_reward" : "Nagroda",
  "Passbook_type_pending" : "Dostosowanie",
  "Passbook_type_reverted" : "Punkty cofnięte",
  "Passbook_type_declined" : "Wnioski odrzucone",
  "Passbook_type_redeem" : "Wykupić",
  "Passbook_type_balance" : "Kontynuuj",
  "Passbook_balance_vary" : "Rzeczywiste saldo może się różnić",
  "Passbook_survey_debit_reason" : "Cenimy twój wysiłek. Jednak z powodu niekontrolowanego zerwania połączenia podczas przesyłania danych Twoja odpowiedź nie mogła zostać zarejestrowana. Musielibyśmy cofnąć punkty otrzymane wcześniej za tę ankietę. Przepraszamy za powstałe niedogodności.",

  "Redeem_redeemed" : "Zrealizuj",
  "Redeem_redeemhistory" : "Zrealizuj historię",
  "Redeem_history_norecord" : "Nie znaleziono rekordu",
  "Redeem_date" : "Data",
  "Redeem_details" : "Szczegóły",
  "Redeem_points" : "Punktów",
  "Redeem_status" : "Stan",
  "Redeem_lookslike" : "Wygląda na to, przegapiłeś weryfikacji konta e-mail.",
  "Redeem_hurry" : "Pośpiechu! otrzymasz 1000 punktów po sprawdzeniu.",
  "Redeem_pleasecheck" : "Sprawdź swoją skrzynkę odbiorczą i kliknij odnośnik w e-mailu potwierdzającym wysłanie, aby aktywować konto.",
  "Redeem_kindlycheck" : "Uprzejmie Sprawdź swój spam/folder wiadomości-śmieci, jak również na e-mail z potwierdzeniem.",
  "Redeem_getconfirmationemail" : "Nie otrzymasz jeszcze e-maila potwierdzającego?",
  "Redeem_resendconfirmationemail" : "Wyślij ponownie wiadomość e-mail z potwierdzeniem",
  "Redeem_rewardredemptionrule" : "Reguła wykupu nagród",
  "Redeem_completesurvey" : "Pełna ankieta",
  "Redeem_earnpoints" : "Zdobywaj punkty",
  "Redeem_redeemrewards" : "Wykorzystaj nagrody",
  "Redeem_note" : "Uwaga:",
  "Redeem_yourearnedpoints" : "Twoje zdobyte punkty staną się wymienialne po zakwalifikowaniu ankiety. Aby zrealizować nagrodę, minimalne punkty wymiany na koncie muszą być",
  "Redeem__points" : "Punktów.",
  "Redeem_availablebalance" : "Dostępne saldo:",
  "Redeem_historydetails" : "Kliknij tutaj, aby wyświetlić szczegóły historii odkupienia.", 
  "Redeem_reqsucc" : "Gratulacje! Twoje punkty zostały zrealizowane. Aby uzyskać więcej informacji, sprawdź zarejestrowaną skrzynkę pocztową.",
  "Redeem_wisecheck_youhave" : "Gratulacje! Jesteś tylko jedno kliknięcie od swojej nagrody.",
  "Redeem_wisecheck_gcagainst" : "Karta podarunkowa dla",
  "Redeem_wisecheck_points" : "Punktów.",
  "Redeem_wisecheck_issue" : "System zdobył pewne nierozpoznane działania na koncie, z powodu których nie można wysłać żądanie Zrealizuj. Prosimy o kontakt w",
  "Redeem_emailverify_issue" : "Aby wymienić swoje punkty, poprosimy Cię o zweryfikowanie zarejestrowanego konta e-mail.",

  "Redeem_unsubscribe_issue" : "Nie możesz wysłać prośby o odkupienie, ponieważ zrezygnowałeś z subskrypcji WisePerks. Skontaktuj się z support@wiseperks.com, aby ponownie zasubskrybować.",
  "Redeem_wisecheck_message1" : "Nie możesz dokonać wymiany z powodu pewnych względów bezpieczeństwa. Skontaktuj się z pomocą techniczną",
  "Redeem_wisecheck_message2" : ".",
  "Redeem_Confirm_button" : "Potwierdzić",
  "Redeem_Cancel_button" : "Anuluj",
  "Redeem_pending_hello" : "cześć",
  "Redeem_pending" : "Dostosowanie",
  "Redeem_pending_msg1" : "Twoje konto pokazuje dostosowanie",
  "Redeem_pending_msg2" : "punkty do przeprowadzenia. Potwierdź, aby zainicjować transakcję.",

  "Dashboard_wisecheck_issue" : "System przechwycił pewną nierozpoznaną aktywność na Twoim koncie, dzięki czemu nie możesz zobaczyć ankiet. Skontaktuj się z support@wiseperks.com, aby uzyskać dalsze wyjaśnienia.",
  "Dashboard_unsubscribe_issue" : "Nie możesz wyświetlić żadnych ankiet, ponieważ zrezygnowałeś z subskrypcji WisePerks. Skontaktuj się z support@wiseperks.com, aby otrzymać ankiety ponownie.",


  "WC_DashboardMsg_1" : "Ups! Brak ankiet pasujących do Twojego profilu od teraz.",
  "WC_DashboardSubMsg_1" : "Nie martw się, tasujemy nasz ekwipunek i wkrótce wyślemy nowe ankiety na swój sposób.",

  "WC_DashboardMsg_2" : "Odświeżamy Twój pulpit nawigacyjny. Sprawdź nowe ankiety po pewnym czasie.",
  "WC_DashboardSubMsg_2" : "",

  "Survey_success_msg1" : "Dziękujemy za wypełnienie ankiety. Po prostu zdobył response_points punktów. Zaloguj się na desce rozdzielczej, aby zobaczyć całkowite zgromadzone punkty.",
  "Survey_success_msg2" : "Dziękujemy za wypełnienie ankiety. Właśnie zarobiłeś response_points Punktów.",

  "Survey_success_but_nomore_survey" : "Gratulacje! Właśnie zarobiłeś response_points punkty za wypełnienie ankiety. Proszę sprawdzić nadchodzące ankiety po jakimś terminie.",
  
  "Survey_terminate_pnt_msg1" : "Wygląda na to, niektóre informacje są nieco inne niż wymóg. Cenimy Twój czas i wysiłki, będzie dodanie response_points punktów na koncie. Skupiamy się na przynosząc Ci bardziej lukratywne i intuicyjne ankiety.",
  
  "Survey_terminate_nopnt_msg1" : "Wygląda na to, że niektóre z twoich odpowiedzi nieznacznie różnią się od wymagań. Będziemy wysyłać więcej ankiet na swój sposób. Dziękuję za poświęcony czas i wysiłek.",

  "TermsConditions_t&c" : "Warunki & warunki",
  "TermsConditions_WhenRegister" : "Kiedy się rejestrujesz",
  "TermsConditions_WhenYouCreate" : "Podczas tworzenia konta osobistego prosimy o podanie niektórych danych osobowych, o których tu wspomniano:",
  "TermsConditions_YouCanUpdate" : "Możesz zaktualizować lub zmienić swoje podstawowe informacje profilowe w dowolnym momencie.",
  "TermsConditions_YouCanOptOut" : "Możesz zrezygnować lub zamknąć konto w dowolnym momencie.",
  "TermsConditions_WeRespectYour" : "Szanujemy Twoją prywatność i optymalnie wykorzystujemy technologię, aby chronić twoje dane przed nieautoryzowanym dostępem fizycznym i elektronicznym.",
  "TermsConditions_WeShareYour" : "Udostępniamy Twoje dane tylko organizacjom, które są wymagane do obsługi WiseWorks. A te organizacje muszą zadbać o nasze zobowiązania dotyczące prywatności i bezpieczeństwa.",
  "TermsConditions_WhatInformation" : "Jakie informacje od Ciebie zbieramy",
  "TermsConditions_DirectlyCollected" : "Bezpośrednio zebrane",
  "TermsConditions_EmailAddressAndPassword" : "Wpisz adres e-mail i hasło do swojego konta.",
  "TermsConditions_YourResponse" : "Twoja odpowiedź na pytania profilujące.",
  "TermsConditions_AnyCustomer" : "Wszelka korespondencja związana z obsługą klienta.",
  "TermsConditions_AnyPersonalDetails" : "Wszelkie dane osobowe, które udostępniasz do odkupienia",
  "TermsConditions_AnyOtherInformation" : "Wszelkie inne informacje bezpośrednio dostarczone przez Ciebie.",
  "TermsConditions_UsageData" : "Dane dotyczące użytkowania",
  "TermsConditions_YourIPAddress" : "Twój adres IP, lokalizacja geograficzna i pliki cookie",
  "TermsConditions_InformationAboutTheDevice" : "Informacje o używanym urządzeniu",
  "TermsConditions_YourSurveyActivity" : "Twoja aktywność w ankiecie, punkty na koncie, płatności, które otrzymasz po odkupieniu.",
  "TermsConditions_HowDoWeUse" : "Jak wykorzystujemy Twoje dane",
  "TermsConditions_SendingYouInvitation" : "Przesyłam Ci e-maile z zaproszeniem, aby podjąć ankietę, do której się kwalifikujesz.",
  "TermsConditions_DetectAndPrevent" : "Wykrywaj i zapobiegaj nieuczciwym działaniom.",
  "TermsConditions_ImproveYourExperience" : "Popraw swoje wrażenia na platformie WiseWorks",
  "TermsConditions_YourResponseAreTransferred" : "Twoje odpowiedzi są przekazywane do naszych powiązanych stron trzecich i partnerów w postaci zagregowanej i zdeklarowanej.",
  "TermsConditions_OurPartnersCanAsk" : "Nasi partnerzy mogą poprosić Cię o podanie nazwiska, adresu e-mail i innych danych kontaktowych podczas ankiety.",
  "TermsConditions_YouAreFreeToDecline" : "Możesz odmówić podania swoich danych partnerom, jeśli wyrazisz zgodę, niż przekazujesz te informacje bezpośrednio do nich, a nie do WiseWorks.",
  "TermsConditions_YourInformationWillBe" : "Twoje informacje będą przetwarzane zgodnie z odpowiednią polityką prywatności firmy, a nie polityką prywatności firmy WiseWorks.",
  "TermsConditions_OurPartnersAreCommited" : "Nasi partnerzy są zobowiązani do poszanowania i ochrony Twojej prywatności.",
  "TermsConditions_WhatWeDont" : "Czego nie robimy z tym",
  "TermsConditions_WeDontSellOnThat" : "Nikomu nie sprzedajemy tych informacji i nie używamy ich do sprzedaży niczego Tobie. Zawsze będziemy prosić o pozwolenie przed udostępnieniem twoich danych.",
  "TermsConditions_YourRightToControl" : "Twoje prawo do kontrolowania swoich danych",
  "TermsConditions_YouHaveFullControl" : "Masz pełną kontrolę nad swoimi danymi.",
  "TermsConditions_YouCanAccess" : "Możesz uzyskać dostęp, aktualizować lub zmieniać swoje podstawowe informacje o profilu, kiedy tylko chcesz.",
  "TermsConditions_YouCanUnsubscribe" : "Możesz zrezygnować z subskrypcji i dezaktywować swoje konto.",
  "TermsConditions_YouAreFreeToGetYour" : "Możesz usunąć swój profil, wysyłając nam wiadomość na adres support@wiseperks.com. Postaramy się rozwiązać problem i jeśli nadal chcesz usunąć swoje konto, wykonaj to w ciągu 72 godzin.",
  "TermsConditions_DataSecurity" : "Ochrona danych",
  "TermsConditions_OurTechTeamWorks" : "Nasz zespół techniczny ciężko pracuje, aby wszystkie twoje dane były bezpieczne.",
  "TermsConditions_WeMakeUseOfLatest" : "Korzystamy z najnowszych technologii i środków, aby uniknąć niewłaściwego użycia lub nieautoryzowanego dostępu do twoich danych. Jednak ze względu na charakter środowiska online nie możemy zapewnić 100% bezpieczeństwa danych.",
  "TermsConditions_WeTryOurBestToRule" : "Dokładamy wszelkich starań, aby wykluczyć ryzyko, jednak nie możemy zagwarantować, że nie powstanie ryzyko. Akceptując niniejsze Warunki, użytkownik przyjmuje do wiadomości i akceptuje te zagrożenia.",
  "TermsConditions_AnyQuestions" : "Wszelkie pytania, prosimy o kontakt z nami",
  "TermsConditions_IfYouHaveAnyQueries" : "Jeśli masz jakiekolwiek pytania lub skargi dotyczące naszej polityki prywatności i procedur. Prześlij nam wiadomość e-mail na adres support@wiseperks.com, a my skontaktujemy się z Tobą w ciągu 48 godzin.",

  "Validation_PleaseEnterProperDate" : "Proszę podać właściwą datę urodzenia",
  "Validation_PleaseSelectOne" : "Proszę wybrać jedną opcję!",
  "Validation_PleaseSelectValidOptions" : "Wybierz ważne opcje!",
  "Validation_PleaseSelectAtLeaseOneOption" : "Wybierz co najmniej jedną opcję",
  "Validation_PleaseInsertTheZip" : "Proszę wprowadzić kod pocztowy.",

  "Releases_releases" : "Zwalnia",
  "Releases_theUseOfThis" : "Korzystanie z niniejszej strony internetowej podlega następującym warunkom korzystania.",
  "Releases_featuresOfWP" : "Rysy twarzy od WP zwolnienie z",
  "Releases_updatedOn" : "zaktualizować u",
  "Releases_WisePerksNow" : "WisePerks obsługuje teraz wiele języków",
  "Releases_youCanWalkThourgh" : "Możesz chodzić thourgh ten umiejscowienie rezygnować dużo liczniejszy ulżyć teraz równie WisePerks jest rozporządzalny w twój preferowany język.",
  "Releases_languagesAvailable" : "Dostępne języki:",
  "Releases_EarnMore" : "Zyskaj więcej dzięki nowemu systemowi punktów",
  "Releases_theNewWisePerksAccumulates" : "Nowy WisePerks gromadzi punkty na Twoim koncie. Punkty te posiadają znacznie wyższą wartość i są łatwe do zdobycia i odkupienia. Z wielu opcji Zrealizuj, aby wybrać z, jesteśmy pewni, że będziesz cieszyć się badaniem biorąc doświadczenie.",
  "Releases_accumulatePoints" : "Gromadzić punkty z Hassel wolna redeemption",
  "Releases_youGetTheBenefit" : "Otrzymujesz korzyści płynące z zdobycia tego samego No. punktów, bez względu na walutę, w jakiej chcesz je wymienić.",
  "Releases_surveyDisqualification" : "Dyskwalifikacja ankiety nie ma więcej złych wieści",
  "Releases_youEarnPoints" : "Zdobywasz punkty, nawet jeśli nie kwalifikujesz się do ankiety, zacząłeś odpowiadać.",
  "Releases_newAndImproved" : "Nowy i ulepszony układ",
  "Releases_theFreshLookOf" : "Świeży wygląd WisePerks sprawi, że zakochasz się w nim. To jest teraz dużo łatwiejszy wobec używać i byłby być wielki przeżycie pod kątem ty.",
  "Releases_whatAvatarAreYouToday" : "Jaki avatar jesteś dzisiaj",
  "Releases_youCanNowCreate" : "Możesz teraz utworzyć swój własny awatar do funkcji na desce rozdzielczej.",
  "Releases_freshAndHotSurveys" : "Świeże i gorące ankiety na desce rozdzielczej",
  "Releases_youDontHaveTo" : "Nie musisz martwić się o refresing na desce rozdzielczej, aby zobaczyć nowe ankiety, będziemy trzymać desce rozdzielczej wypełnione najnowsze ankiety automatycznie.",
  "Releases_promoCode" : "Funkcja kodu promocyjnego",
  "Releases_promoCodeInfo" : "Na popularne żądanie WisePerks co tydzień przynosi kody promocyjne swoim panelistom. Szybki sposób na zdobycie większej ilości nagród.",
  "Releases_referFriend" : "Poleć znajomemu",
  "Releases_referFriendInfo" : "Zaproś znajomych związanych z WisePerks i zgarnij ekscytujące nagrody. Zwróć uwagę na opcję Poleć znajomemu na swoim koncie.",

  "Dashboard_Promocode_Usepromo" : "Użyj kodu promocyjnego",
  "Dashboard_Promocode_to" : "do",
  "Dashboard_Promocode_earnminimum_1" : "zarobić minimum",
  "Dashboard_Promocode_earnminimum_2" : "dodatkowe punkty",
  "Dashboard_Promocode_minimum" : "minimum",
  "Dashboard_Promocode_minimumextrapoints" : "dodatkowe punkty za każdą ankietę ukończoną dzisiaj",
  "Dashboard_Promocode_apply" : "Zastosować",
  "Dashboard_Promocode_message1" : "Kod promocyjny jest nieprawidłowy!",
  "Dashboard_Promocode_message2" : "Gratulacje! Dostaniesz minimum",
  "Dashboard_Promocode_message3" : "dodatkowe za każdą ankietę ukończoną dzisiaj!",

  "Dashboard_modal_Enjoythefestive" : "Ciesz się świątecznym duchem, zdobywając minimum",
  "Dashboard_modal_Extrapointson" : "dodatkowych punktów za każdą ankietę, którą wypełnisz dzisiaj.",

  "refer_friend_title" : "Poleć znajomemu i otrzymaj nagrodę",
  "refer_friend_step_first": "Zaproś znajomego do WisePerks",
  "refer_friend_step_second": "Friend pomyślnie rejestruje się w usłudze WisePerks",
  "refer_friend_step_third": "Otrzymujesz each_referral punktów",
  "refer_friend_link_title": "Zaproś znajomego już teraz!",
  "refer_friend_share_title": "Udostępnij link",
  "refer_friend_guideline_title": 'Jak "REFER A FRIEND" przyniesie ci korzyści:',
  "refer_friend_guideline_one": "Udostępnij link podany powyżej swoim poleceniom.",
  "refer_friend_guideline_two": "Możesz skierować dowolną liczbę osób, gdy tylko 5 z Twoich poleconych zweryfikuje swoje konto i rozpocznie aktywność ankietową, Twoje zarobione punkty polecone zostaną dodane do Twojego konta.",
  "refer_friend_guideline_three": "Otrzymasz each_referral punktów za pomyślne (ze zweryfikowanym kontem e-mail i działaniami ankietowymi) skierowanie.",
  "refer_friend_guideline_four": "Punkty będą zdobyte w dwóch etapach, partial_referral na weryfikację e-mail i partial_second_referral na aktywności ankiet przez Twój polecony.",
  "refer_friend_guideline_five": "Po 5 pozytywnych (ze zweryfikowanym kontem e-mail i działaniami ankietowymi) odsyłacze do odwołań zostaną wyłączone.",
	
  }
  
