import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { PanelistService } from '../shared/panelist.service';
import {Location} from '@angular/common';

@Injectable()
export class ProfileGuard implements CanActivate {
  constructor(private router : Router, private _panelistService: PanelistService, private _location: Location){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      if(localStorage.getItem('currentLoginPanelist') !==null){
        let panelistId = localStorage.getItem('currentLoginPanelist');
        
        
        return this._panelistService.getPanelistInfo(panelistId).map((resp:any)=>{
          if(resp.success == true && resp.auth == true){
            if(resp.data.step == 0){
              this._panelistService.SetGlobalPanelistInfo(resp);
              return true;
            } else {
              this._location.back();
              return false;
            }
          }
        }).catch(()=>{
          //localStorage.removeItem('currentLoginPanelist');
          //this.router.navigate(['']);
          return Observable.of(false);
        });
        
      } else {
        this.router.navigate(['']);
        return false;
      }
  }
}
