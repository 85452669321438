import { Component, OnInit } from '@angular/core';
import {Image} from '../shared/testimonial.interface';

@Component({
  selector: 'app-home-testimonial',
  templateUrl: './home-testimonial.component.html',
  styleUrls: ['./home-testimonial.component.css']
})
export class HomeTestimonialComponent implements OnInit {

  public images = IMAGES;

  constructor() { }

  ngOnInit() {
  }

}

//IMAGES array implementing Image interface
var IMAGES: Image[] = [
  { 
    "title": '"I earned points even for the surveys I got disqualify at. Thanks to WisePerks."',
     "url": "assets/images/users/home/testimonial/timg4.jpg" 
  },
  { 
    "title": '"Been paid twice. They have a quick payout cycle, which makes them different from others."',
     "url": "assets/images/users/home/testimonial/timg3.jpg" 
  },
  { 
    "title": '"I like WisePerks for the variety of surveys they send and the perks associated with them."',
     "url": "assets/images/users/home/testimonial/timg7.jpg" 
  },
  { 
    "title": '"WisePerks is genuine. They respect my privacy and have never spammed my mail box."',
     "url": "assets/images/users/home/testimonial/timg5.jpg" 
  }
];
