import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkifyjs/string';
import * as hashtag from 'linkifyjs/plugins/hashtag';
import * as mention from 'linkifyjs/plugins/mention';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  /*transform(value: any, args?: any): any {
    return null;
  }*/

  transform(str: string): string {
    //return str ? linkifyStr(str) : str;
    console.log(str);
    let khush = linkifyStr(str);
    return "";
  }

}
